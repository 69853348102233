import BlockSpinner from "components/common/BlockSpinner";
import Modal from "components/common/Modal";
import React, { useState } from "react";
import { FC } from "react";
import removeUserIcon from "media/icons/dls/close.svg";
import SearchTechContentUser from "components/user/management/SearchTechContentUser";
import "./ManageTCCModal.scoped.scss";

import {
  ConfidentialAccessRoles,
  IConfidentialSWUser,
} from "interfaces/user/UserInterfaces";

interface ManageTCCModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onAddUser: (userId: string, role: ConfidentialAccessRoles) => void;
  onRemoveUser: (userId: string, role: ConfidentialAccessRoles) => void;
  isLoading: boolean;
  currentUsers: IConfidentialSWUser[];
}

export const ManageTCCModal: FC<ManageTCCModalProps> = ({
  isOpen,
  onClose,
  onAddUser,
  onRemoveUser,
  isLoading,
  currentUsers,
}) => {
  const [tccUserToRemove, setTccUserToRemove] = useState<{
    userId: string;
    role: ConfidentialAccessRoles;
  }>();

  const [tccUserToAdd, setTccUserToAdd] = useState<string>();

  return (
    <Modal
      isOpen={isOpen}
      header="Manage Confidential TCC SW"
      controls={
        <>
          {!isLoading && !tccUserToAdd && (
            <button className="primary-button" onClick={onClose}>
              Done
            </button>
          )}
        </>
      }
    >
      {isLoading && <BlockSpinner />}

      {!isLoading && !tccUserToAdd && (
        <>
          <h3>Users with access enabled:</h3>
          {currentUsers.map((user) => (
            <div className="user" key={user.email}>
              {!(
                tccUserToRemove?.userId === user.email &&
                tccUserToRemove.role === user.role
              ) && (
                <>
                  <span>
                    {user.name} ({user.email}, {user.role})
                  </span>
                  <img
                    src={removeUserIcon}
                    className="remove-button icon-medium hover-gray-bg"
                    onClick={() =>
                      setTccUserToRemove({
                        userId: user.email,
                        role: user.role,
                      })
                    }
                    alt="Remove Access"
                  />
                </>
              )}

              {tccUserToRemove?.userId === user.email &&
                tccUserToRemove.role === user.role && (
                  <>
                    <span>
                      Confirm you want to remove {tccUserToRemove.role} access
                      for {tccUserToRemove.userId}?
                    </span>
                    <button
                      className="primary-button confirm-button"
                      onClick={() => {
                        onRemoveUser(user.email, user.role);
                        setTccUserToRemove(undefined);
                      }}
                    >
                      Confirm
                    </button>
                    <button
                      className="secondary-button cancel-button"
                      onClick={() => setTccUserToRemove(undefined)}
                    >
                      Cancel
                    </button>
                  </>
                )}
            </div>
          ))}
          <SearchTechContentUser
            setSelectedUserFunc={(userId) => setTccUserToAdd(userId)}
            optional={false}
            isFilteredList={false}
            isLDAPSearch={true}
            title={
              <p style={{ color: "darkred" }}>
                You are going to provide access to TCC sensitive content.
                Proceed with caution.
              </p>
            }
          />
        </>
      )}

      {tccUserToAdd && (
        <>
          <div className="user">
            <span>
              Confirm you want to add access for {tccUserToAdd}?
              <p className="access-alert">
                NOTE: You are going to provide access to TCC sensitive content .
                Are you sure?
              </p>
            </span>
          </div>
          <div className="user">
            <div className="row">
              {Object.keys(ConfidentialAccessRoles).map((role) => (
                <button
                  className="primary-button confirm-button"
                  onClick={() => {
                    onAddUser(tccUserToAdd, role as ConfidentialAccessRoles);
                    setTccUserToAdd(undefined);
                  }}
                >
                  Add as {role}
                </button>
              ))}
              <button
                className="secondary-button cancel-button"
                onClick={() => setTccUserToAdd(undefined)}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ManageTCCModal;
