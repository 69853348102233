import React from "react";
import { Link } from "react-router-dom";
import Banner, { BannerType } from "./common/Banner";

const NotFound: React.FC = () =>
  <>
    <h2 className="page-title">Page Not Found</h2>
    <Banner type={BannerType.Error}>
      The page you requested was not found.
    </Banner>
    <p>
      <Link to="/">Go to Standard Work</Link>
    </p>
  </>;

export default NotFound;