import SWMetadata from "components/common/SWMetadata";
import "./MFGRCAttributes.scoped.scss";
import React from "react";
import useSelector from "store/useSelector";
import { DataClassificationTypes } from "interfaces/sw/SWInterfaces";

interface IMFGRCAttributesProps {
    isDisabled: boolean,
}

const MFGRCAttributes: React.FC<IMFGRCAttributesProps> = ({ isDisabled }) => {
    const {
        manageSW: {
            SW,
        },
    } = useSelector(store => store);

    return (
        <div className="inputs">
            <div className="type-row">
                <SWMetadata
                    metadataName="dataClassification"
                    isDisabled={isDisabled}
                />
                {(SW.dataClassification && SW.dataClassification === DataClassificationTypes.Confidential) && 
                    <SWMetadata
                        metadataName="tcc"
                        isDisabled={isDisabled}
                    />
                }
                <SWMetadata
                    metadataName="languages"
                    isDisabled={isDisabled}
                />
            </div>
            <SWMetadata
                metadataName="title"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="description"
                isDisabled={isDisabled}
            />
            {(SW.language
                && SW.language.value !== "English") &&
                <SWMetadata
                    metadataName="equivalentSWs"
                    isDisabled={isDisabled}
                />
            }
            <SWMetadata
                metadataName="owingPlants"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="performingPlants"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="workCenters"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="materials"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="gemsRevisionNumber"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="versionChanges"
                isDisabled={isDisabled}
            />
        </div>
    );
}

export default MFGRCAttributes;