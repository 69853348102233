import React, { useState, useEffect } from "react";
import { ITableComponent, SWTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import "./ComponentEditor.scss";
import "./TableEditor.scoped.scss";
import TableEditorCell from "./TableEditorCell";
import { setTableLabel, updateTable } from "store/manageSW/manageSWActions";
import TableModal from "components/common/TableModal";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";

interface ITableEditorProps {
  tableComponent: ITableComponent,
  allowEdit: boolean,
  stepGuid: string,
  swGuid: string,
  swVersion: number,
  imageDataDestination: ImageDataDestinations,
  swType: SWTypes,
}

const TableEditor: React.FC<ITableEditorProps> = ({
  tableComponent,
  allowEdit,
  stepGuid,
  swGuid,
  swVersion,
  imageDataDestination,
  swType
}) => {
  const dispatch = useDispatch();
  const [showPopUpForRow, setShowPopUpForRow] = useState(false);
  const [rowReduce, setRowReduce] = useState(tableComponent.rowCount);
  const [showPopUpForColumn, setShowPopUpForColumn] = useState(false);
  const [colReduce, setColReduce] = useState(tableComponent.colCount);

  let component: JSX.Element | undefined;

  useEffect(() => {
    if (rowReduce < tableComponent.rowCount && rowReduce > 0) {
      setShowPopUpForRow(true);
    }
    if (colReduce < tableComponent.colCount && colReduce > 0) {
      setShowPopUpForColumn(true);
    }
  }, [rowReduce, colReduce, tableComponent.rowCount, tableComponent.colCount]);

  let onColInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { valueAsNumber, min, max } = e.target;
    valueAsNumber = Math.max(Number(min), Math.min(Number(max), valueAsNumber));

    if (isNaN(valueAsNumber)) {
      valueAsNumber = Number(min);
    }
    if (valueAsNumber < tableComponent.colCount) {
      let cellsToDelete = tableComponent.cells.filter(x => x.colIndex === valueAsNumber);
      if (cellsToDelete.find(x => x.value !== undefined)) {
        // only show popup if column has data
        setColReduce(valueAsNumber);
      } else {
        setColReduce(valueAsNumber);
        updateColumn(valueAsNumber);
      } 
    }
    else {
      setColReduce(valueAsNumber);
      updateColumn(valueAsNumber);
    }
  }

  let onRowInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { valueAsNumber, min, max } = e.target;
    valueAsNumber = Math.max(Number(min), Math.min(Number(max), valueAsNumber));

    if (isNaN(valueAsNumber)) {
      valueAsNumber = Number(min);
    }
    if (valueAsNumber < tableComponent.rowCount) {
      let cellsToDelete = tableComponent.cells.filter(x => x.rowIndex === valueAsNumber);
      if (cellsToDelete.find(x => x.value !== undefined)) {
        // only show popup if row has data.
        setRowReduce(valueAsNumber);
      }
      else {
        setRowReduce(valueAsNumber);
        updateRow(valueAsNumber);
      }
    }
    else {
      setRowReduce(valueAsNumber);
      updateRow(valueAsNumber);
    }
  }

  const updateRow = (rowCount: number) => {
    dispatch(updateTable({
      stepGuid,
      component: {
        ...tableComponent,
        rowCount: rowCount,
      },
    }))
  }

  const updateColumn = (colCount: number) => {
    dispatch(updateTable({
      stepGuid,
      component: {
        ...tableComponent,
        colCount: colCount
      },
    }))
  }

  const onRowModalClick = (isContinue: boolean) => {
    setShowPopUpForRow(false);
    if (isContinue) {
      updateRow(rowReduce);
    }
    else {
      setRowReduce(tableComponent.rowCount);
    }
  }

  const onColModalClick = (isContinue: boolean) => {
    setShowPopUpForColumn(false);
    if (isContinue) {
      updateColumn(colReduce);
    }
    else {
      setColReduce(tableComponent.colCount);
    }
  }

  let onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTableLabel({
      stepGuid,
      componenGuid: tableComponent.guid,
      label: e.target.value,
    }))
  }

  const renderTable = (disabled: boolean) => {
    //build a 2d array here and one by one build objects with colSpan, rowSpan, isDeleted
    let cellArray = new Array(tableComponent.rowCount);
    for (var r = 0; r < cellArray.length; r++) {
      cellArray[r] = new Array(tableComponent.colCount);
      for (var c = 0; c < cellArray[r].length; c++) {
        let cIx = c;
        let rIx = r;

        let cell = tableComponent.cells.filter(x => x.rowIndex === rIx && x.colIndex === cIx)[0];
        if (cell !== undefined) {
          cellArray[rIx][cIx] = { colSpan: cell.colSpan ? cell.colSpan : 1, rowSpan: cell.rowSpan ? cell.rowSpan : 1, deleted: false };
        } else {
          cellArray[rIx][cIx] = { colSpan: 1, rowSpan: 1, deleted: false };
        }
      }
    }

    // figure out which cells should be deleted to handle col spans and row spans
    for (var ra = 0; ra < tableComponent.rowCount; ra++) {
      for (var ca = 0; ca < tableComponent.colCount; ca++) {
        let cell = cellArray[ra][ca];
        if (cell.deleted) {
          continue;
        }

        if (cell.colSpan > 1 && cell.rowSpan > 1) {
          // disable columns and rows??
          for (var i = ca + 1; i < ca + cell.colSpan; i++) {
            let cellToDisable = cellArray[ra][i];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
          for (var j = ra + 1; j < ra + cell.rowSpan; j++) {
            let cellToDisable = cellArray[j][ca];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }

            for (var z = 1; z < cell.colSpan; z++) {
              let cellToDisable = cellArray[j][ca + z];
              if (cellToDisable) {
                cellToDisable.deleted = true;
                cellToDisable.rowSpan = 1;
                cellToDisable.colSpan = 1;
              }
            }
          }
        } else if (cell.colSpan > 1) {
          // disable columns
          for (var ii = ca + 1; ii < ca + cell.colSpan; ii++) {
            let cellToDisable = cellArray[ra][ii];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        } else if (cell.rowSpan > 1) {
          // disable rows
          for (var jj = ra + 1; jj < ra + cell.rowSpan; jj++) {
            let cellToDisable = cellArray[jj][ca];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        }
      }
    }

    const colWidth = 100 / tableComponent.colCount;
    return (
      <table>
        <colgroup>
          {
            [...new Array(tableComponent.colCount)].map((_, cIx) => {
              return <col key={cIx} style={{ width: colWidth + '%' }} />
            })
          }
        </colgroup>
        <tbody>
          {[...new Array(tableComponent.rowCount)].map((_, ixr) => (
            <tr key={"r" + ixr}>
              {
                [...new Array(tableComponent.colCount)].map((_, ixc) => {
                  let cellFormatter = cellArray[ixr][ixc]; // is this cell deleted?
                  if (!cellFormatter.deleted) {
                    let cell = tableComponent.cells.filter(x => x.rowIndex === ixr && x.colIndex === ixc)[0];
                    let cellColSpan = cell !== undefined ? cell.colSpan : 1;
                    let cellRowSpan = cell !== undefined ? cell.rowSpan : 1;

                    return (
                      <td key={"c" + ixc} colSpan={cellColSpan} rowSpan={cellRowSpan}>
                        <TableEditorCell
                          rowKey={ixr}
                          colKey={ixc}
                          stepGuid={stepGuid}
                          tableComponent={tableComponent}
                          disabled={disabled}
                          swGuid={swGuid}
                          swVersion={swVersion}
                          imageDataDestination={imageDataDestination}
                          swType={swType}
                        />
                      </td>);
                  } else {
                    return undefined;
                  }
                })
              }
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  if (allowEdit) {
    component = (
      <>
        <div>
          <label className="mandatory controlLabel">
            Label
          </label>
          <input
            type="text"
            className="controlWide"
            value={tableComponent.label}
            onChange={(e) => onLabelChange(e)}
          />
          <label className="controlLabel">Columns</label>
          <input
            className="control numberInput"
            type="number"
            value={tableComponent.colCount}
            onChange={(e) => onColInputChange(e)}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min="1"
            max="10"
          />
          <label className="controlLabel">Rows</label>
          <input
            className="control numberInput"
            type="number"
            value={tableComponent.rowCount}
            onChange={(e) => onRowInputChange(e)}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min="1"
            max="20"
          />
        </div>
        {renderTable(false)}
      </>
    );
  } else {
    component = (
      <>
        <div>
          <label className="mandatory controlLabel">
            Label
          </label>
          <input
            type="text"
            className="controlWide"
            defaultValue={tableComponent.label}
            disabled={true}
          />
          <label className="controlLabel">Columns</label>
          <input
            className="control"
            type="number"
            defaultValue={tableComponent.colCount}
            disabled={true}
          />
          <label className="controlLabel">Rows</label>
          <input
            className="control"
            type="number"
            defaultValue={tableComponent.rowCount}
            disabled={true}
          />
        </div>
        {renderTable(true)}
      </>
    );
  }

  return (
    <div className="component-editor">
      {showPopUpForRow
        && <TableModal
          onModalClick={onRowModalClick}
          header="Table Warning"
          message="Row has data and will be deleted. Do you want to continue?"
        />
      }
      {showPopUpForColumn
        && <TableModal
          onModalClick={onColModalClick}
          header="Table Warning"
          message="Column has data and will be deleted. Do you want to continue?"
        />
      }
      {component}
    </div>
  );
}

export default TableEditor;