import React from "react";
import "./SWStepCommentStatusIndicator.scoped.scss";

interface ISWStepCommentStatusIndicator {
    resolved: boolean,
    showText: boolean,
}

const SWStepCommentStatusIndicator: React.FC<ISWStepCommentStatusIndicator> = ({
    resolved,
    showText,
}) => {
    return (
        <span
            className="sw-step-comment-status"
        >
            <div
                title={resolved
                    ? "No Active Comments"
                    : "Unresolved Comments"
                }
                className={`orb ${resolved
                    ? "Resolved"
                    : "Unresolved"
                    }`
                }
            >
            </div>
            {showText &&
                <span
                    className="text"
                >
                    {resolved
                        ? "Resolved"
                        : "Unresolved"
                    }
                </span>
            }
        </span>);
}
export default SWStepCommentStatusIndicator;