import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { ISWSummaryItem } from "interfaces/sw/SWInterfaces";

export enum BatchUpdateCommandTypes {
  Add = "Add",
  Remove = "Remove",
}

export enum BatchUpdateMetaTypes {
  ActivityWorkflow = "ActivityWorkflow",
  OwningOrg = "OwningOrg",
  Task = "Task",
  PerformingOrg = "PerformingOrg",
  ServiceType = "ServiceType",
  OperatingEnv = "OperatingEnv",
  Equipment = "Equipment",
  Country = "Country",
  CompetencyElement = "CompetencyElement",
  SafetyCategory = "SafetyCategory",
  GlobalOrLocal = "GlobalOrLocal",
  Introduction = "Introduction",
  SubBusinessLine = "SubBusinessLine",
  ProductCenters = "ProductCenters",
  GeoUnit = "GeoUnit",
  EpicEquipment = "EpicEquipment",
  MaintenanceTaskId = "MaintenanceTaskId",
  CEMarking = "CEMarking",
  Foreword = "Foreword",
  VersionChanges = "VersionChanges",
  HARC = "HARC",
  ShowCLSignatureBlock = "ShowCLSignatureBlock",
  // HazardCategory = "HazardCategory",
}

export interface IBatchUpdateApproval {
  id: number,
  commands: IBatchUpdateCommand[],
  standardWorks: ISWSummaryItem[],
}

export interface IBatchUpdateCommand {
  sortOrder: number,
  command: BatchUpdateCommandTypes,
  metaType: BatchUpdateMetaTypes,
  masterDataItem: IMasterDataItem,
}

export interface IBatchUpdateSWMetaData {
  approvalId: number,
  swId: number,
  commands: IBatchUpdateSWMetaDataCommand[],
}

export interface IBatchUpdateSWMetaDataCommand {
  sortOrder: number,
  command?: BatchUpdateCommandTypes,
  metaType: BatchUpdateMetaTypes,
  masterDataItem: IMasterDataItem,
}

export interface IBatchUpdateResponse {
  success: boolean,
  approvalRequestId?: number,
  errors: IBatchUpdateError[],
}

export interface IBatchUpdateError {
  swId: number,
  errorType: BatchUpdateErrorTypes,
  message?: string,
}

export enum BatchUpdateErrorTypes {
  HasDraft = "HasDraft",
  HasLock = "HasLock",
}

export interface ISWWithBatchError {
  sw: ISWSummaryItem,
  error?: IBatchUpdateError,
}