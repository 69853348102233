import { ISwRepoSummaryItem } from "store/ManageSwReports/ManageSwReportsTypes";

export function formatGetSwRepoListResponse(response: any): ISwRepoSummaryItem[] {
    let sws = response?.StandardWorks;

    if (!Array.isArray(sws)) {
        return [];
    }

    return sws.map((item: any): ISwRepoSummaryItem => ({
        id: item.Id,
        UID: item.UID,
        version: item.Version,
        title: item.Title,
        description: item.Description,
        sourceSystem: item.SourceSystem,
        isNewestVersion: item.IsLatest,
        publishedDate: new Date(item.PublishedDate),
    }));
}