import React, { useEffect, useState, useRef, useCallback } from "react";
import Modal from "components/common/Modal";
import { IMasterDataSection } from "store/masterData/masterDataTypes";
import BlockSpinner from "components/common/BlockSpinner";
import Banner, { BannerType } from "components/common/Banner";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import "./MasterDataModal.scoped.scss";
import AddIcon from "media/icons/dls/add.svg";
import useDebounce from "utilities/useDebounce";

interface IMasterDataModal {
  label: string,
  masterDataSection: IMasterDataSection,
  selectedItems: IMasterDataItem[],
  allowMultiple?: boolean,
  isAsyncSearch?: boolean,
  onAddItem(item: IMasterDataItem): void,
  onLoad(searchTerm?: string, onlyActive?: boolean): void,
  onClose(): void,
  itemFormatter(item: IMasterDataItem): string | JSX.Element,
  itemFilter?(item: IMasterDataItem): boolean,
  showStatusFilter?: boolean,
  onlyActiveState?: boolean,
  onlyActiveStateChange?(value: boolean): void,
  showNoDataMessage?: boolean,
  showNoRoutingDataMsg?: boolean,
}

const MasterDataModal: React.FC<IMasterDataModal> = (props) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const searchBoxRef = useRef<HTMLInputElement | null>(null);
  const onlyActiveStatusCheckBoxRef = useRef<HTMLInputElement | null>(null);
  const {
    masterDataSection,
    isAsyncSearch,
    onLoad,
    onlyActiveState,
    onlyActiveStateChange,
    showStatusFilter,
    showNoRoutingDataMsg,
  } = props;

  const {
    loadOperation,
    items: {
      length: itemsLength,
    },
  } = masterDataSection;

  const loadData = useCallback(() => {
    //onLoad(debouncedSearchTerm.trim(), onlyActiveState);
    setTimeout(onLoad, 100, debouncedSearchTerm.trim(), onlyActiveState);
  }, [debouncedSearchTerm, onlyActiveState]);

  useEffect(() => {
    if (!isFirstLoad) {
      return;
    }

    if (isAsyncSearch
      && searchBoxRef.current) {
      searchBoxRef.current.focus();
      setIsFirstLoad(false);
      return;
    }

    if (!isAsyncSearch
      && !showStatusFilter) {
      loadData();
    }

    setIsFirstLoad(false);

    // Can't include onLoad dependency since it changes each rerender
    // because it is an anonymous function.
    // However, the only way it would change would be if the modal
    // is closed and reopened, which would mean it doesn't need to
    // be passed anyway.
  }, [
    loadOperation,
    itemsLength,
    isAsyncSearch,
    onlyActiveState,
    debouncedSearchTerm,
    isFirstLoad,
    setIsFirstLoad,
  ]);

  useEffect(() => {
    if (debouncedSearchTerm
      || showStatusFilter) {
      if (!debouncedSearchTerm
        && isAsyncSearch) {
        return;
      }
      onLoad(debouncedSearchTerm.trim(), onlyActiveState);
    }

    // Can't include onLoad dependency since it changes each rerender
    // because it is an anonymous function.
    // However, the only way it would change would be if the modal
    // is closed and reopened, which would mean it doesn't need to
    // be passed anyway.
  }, [
    showStatusFilter,
    onlyActiveState,
    debouncedSearchTerm,
    isAsyncSearch,
  ]);

  let component: JSX.Element | undefined;

  if (props.masterDataSection.loadOperation?.isWorking) {
    component = (
      <BlockSpinner />
    );
  } else if (props.masterDataSection.loadOperation?.errorMessage) {
    component = (
      <>
        <Banner
          type={BannerType.Error}
        >
          Load error: {props.masterDataSection.loadOperation.errorMessage}
        </Banner>
        <button
          className="primary-button"
          onClick={() => props.onLoad(props.isAsyncSearch ? searchTerm : undefined)}
        >
          Retry Loading
        </button>
      </>
    );
  } else {
    let data = props.masterDataSection.items
      .filter(i => !props.selectedItems.find(x => x.guid === i.guid)
        && (props.itemFilter
          ? props.itemFilter(i)
          : true
        ) && (((props.onlyActiveState === true
          || props.onlyActiveState === undefined)
          && (i.active
            || i.active === undefined
          )) || props.onlyActiveState === false
        ));

    component = (
      <ul>
        {data
          .sort((a, b) => props.itemFormatter(a) < props.itemFormatter(b)
            ? -1
            : 1)
          .map(i =>
            <li
              key={i.guid}
            >
              <span
                className={i.active === false
                  ? "inactive"
                  : undefined}
              >
                {props.itemFormatter(i)}
              </span>
              <img
                className="hover-gray-bg icon-small"
                alt="Add"
                onClick={() => {
                  if (props.selectedItems.length > 0 && !props.allowMultiple) {
                    props.onClose();
                  }
                  else {
                    props.onAddItem(i);
                  }
                }}
                src={AddIcon}
              />
            </li>
          )}
        {props.showNoDataMessage && data.length < 1 && //Display message if there is no data to show
          <label>{showNoRoutingDataMsg ? "No Routing Data Found" : "No Data found"}</label>}
      </ul>
    );
  }

  return (
    <Modal
      isOpen={true}
      header={props.label}
      controls={(
        <button
          className="primary-button"
          onClick={props.onClose}
        >
          Done
        </button>
      )}
    >
      <div
        className="md-body"
      >
        {props.isAsyncSearch &&
          <input
            type="text"
            placeholder={`Search for ${props.label.toLowerCase()}...`}
            className="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            ref={searchBoxRef}
          />
        }
        {showStatusFilter &&
          <>
            <label
              className="active-row"
            >
              <input
                type="checkbox"
                placeholder={`only Active`}
                checked={onlyActiveState}
                onChange={(e) => onlyActiveStateChange
                  && onlyActiveStateChange(e.target.checked)}
                ref={onlyActiveStatusCheckBoxRef}
              />
              Active Only
            </label>
          </>
        }

        <div
          className="body"
        >
          {component}
        </div>
      </div>
    </Modal>
  );
}

export default MasterDataModal;