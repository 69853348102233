import FlowLayout from "components/layout/FlowLayout";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setReusableCompSortable, updateReusableCompSteps } from "store/manageSW/manageSWActions";
import useSelector from "store/useSelector";
import "./CreateReusable.scoped.scss";
import SelectMetadata from "./SelectMetadata";
import SelectSteps from "./SelectSteps";
import { IStep, StepComponentTypes, StepTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import { findStep } from "store/manageSW/manageSWHelpers";
import { Item } from "react-nestable";
import { v4 as uuidv4 } from "uuid";
import { ReusableCompType } from "store/manageSW/manageSWTypes";

export enum CreateReusableContentTab {
  SelectMetadata = "SelectMetadata",
  SelectSteps = "SelectSteps",
}

const CreateReusableContent: React.FC = () => {
  const {
    SW: {
      steps,
      type
    },
    ReusableCompSortable,
    reusableCompSelectedType,
  } = useSelector(store => store.manageSW);
  const [currentTab, setCurrentTab] = useState<CreateReusableContentTab>(CreateReusableContentTab.SelectSteps);
  const dispatch = useDispatch();
  let mainComponent: JSX.Element | undefined;

  const updateStepsComponents = (steps: IStep | IStep[]) => {
    const stepsArray = Array.isArray(steps) ? steps : [steps];
    stepsArray.forEach((step) => {
      step.components = step.components
        .filter((component) => component.type !== StepComponentTypes.FormulaNumerical)
        .map((component) => ({
          ...component,
          immutableGuid: uuidv4(),
        }));
    });
  };

  useEffect(() => {
    dispatch(setReusableCompSortable(cloneDeep(steps.filter(r=> !r.rcContainer).map((item, pIndex) => ({
      id: item.guid,
      text: item.title,
      hasUnResolvedComments: item.hasUnResolvedComments,
      isTask: item.isTask,
      isInTask: item.isTask,
      hasMasterStep: item.masterStepId != null,
      checked: false,
      isEnabled: type !== SWTypes.MFGSWI,
      children: item.children.filter(r=> !r.rcContainer).map((child, cIndex) => ({
        id: child.guid,
        text: child.title,
        nestedIn: item.guid,
        hasUnResolvedComments: child.hasUnResolvedComments,
        isTask: false,
        isInTask: item.isTask,
        hasMasterStep: child.masterStepId != null,
        parentIndex: (pIndex + 1).toString() + ".",
        checked: false,
        isEnabled: true,
        children: child.children.filter(r=> !r.rcContainer).map((grandchild, gIndex) => ({
          id: grandchild.guid,
          text: grandchild.title,
          nestedIn: child.guid,
          hasUnResolvedComments: grandchild.hasUnResolvedComments,
          isTask: false,
          isInTask: item.isTask,
          hasMasterStep: grandchild.masterStepId != null,
          parentIndex: (pIndex + 1).toString() + "." + (cIndex + 1).toString() + ".",
          checked: false,
          isEnabled: true,
          children: grandchild.children.filter(r=> !r.rcContainer).map((gGrandChild) => ({
            id: gGrandChild.guid,
            text: gGrandChild.title,
            nestedIn: grandchild.guid,
            hasUnResolvedComments: gGrandChild.hasUnResolvedComments,
            isTask: false,
            isInTask: item.isTask,
            hasMasterStep: gGrandChild.masterStepId != null,
            parentIndex: (pIndex + 1).toString() + "." + (cIndex + 1).toString() + "." + (gIndex + 1).toString() + ".",
            isConditional: gGrandChild.isConditional,
            checked: false,
            isEnabled: false,
          })),
          isConditional: grandchild.isConditional,
        })),
        isConditional: child.isConditional,
      })),
      nestedIn: "",
      isConditional: item.isConditional,
    })))));
  }, [dispatch, steps]);

  const hasFormulas = useCallback(() => {
    return steps.some((step) =>
      step.components.some(
        (component) => component.type === StepComponentTypes.FormulaNumerical
      )
    );
  }, [steps]);

  const updateReusableSteps = () => {
    const items: Item[] = ReusableCompSortable;
    const newSteps: IStep[] = [];
    /* Task selections */
    if (reusableCompSelectedType === ReusableCompType.Task) {
      items.filter(r => r.checked).forEach((item, index) => {
        let [step] = findStep(item.id, steps, 0);
        const newStep = cloneDeep(step);

        if (newStep && item.checked) {
          const newChildrenList: IStep[] = [];
          newStep.guid = uuidv4();
          newStep.sortOrder = index + 1;
          newStep.parentGuid = null;
          newStep.immutableGuid = uuidv4();
          newStep.hasUnResolvedComments = item.hasUnResolvedComments;
          updateStepsComponents(newStep);
          item.children?.filter((r: any) => r.checked).forEach((citem: any, cindex: number) => {
            let [son] = findStep(citem.id, steps, 0);
            const newSon = cloneDeep(son);
            if (newSon && citem.checked) {
              newSon.guid = uuidv4();
              newSon.sortOrder = cindex + 1;
              newSon.parentGuid = newStep.guid || null;
              newSon.immutableGuid = uuidv4();
              newSon.hasUnResolvedComments = citem.hasUnResolvedComments;
              updateStepsComponents(newSon);
              const newGrandchildren: IStep[] = [];
              citem.children?.filter((r: any) => r.checked).forEach((gitem: any, gindex: number) => {
                let [grandSon] = findStep(gitem.id, steps, 0);
                const newgrandSon = cloneDeep(grandSon);
                if (newgrandSon && gitem.checked) {
                  newgrandSon.guid = uuidv4();
                  newgrandSon.sortOrder = gindex + 1;
                  newgrandSon.parentGuid = newSon.guid || null;
                  newgrandSon.hasUnResolvedComments = gitem.hasUnResolvedComments;
                  newgrandSon.immutableGuid = uuidv4();
                  updateStepsComponents(newgrandSon);
                  const newGreatGrandchildren: IStep[] = [];
                  gitem.children?.filter((r: any) => r.checked).forEach((gGItem: any, gGIndex: number) => {
                    let [greatGrandSon] = findStep(gGItem.id, steps, 0);
                    const newgreatGrandSon = cloneDeep(greatGrandSon);
                    if (newgreatGrandSon && gGItem.checked) {
                      newgreatGrandSon.guid = uuidv4();
                      newgreatGrandSon.sortOrder = gGIndex + 1;
                      newgreatGrandSon.parentGuid = newgrandSon.guid || null;
                      newgreatGrandSon.immutableGuid = uuidv4();
                      newgreatGrandSon.hasUnResolvedComments = gGItem.hasUnResolvedComments;
                      updateStepsComponents(newgreatGrandSon);
                      newGreatGrandchildren.push(newgreatGrandSon);
                    }
                  })

                  newgrandSon.children = newGreatGrandchildren;
                  newGrandchildren.push(newgrandSon);
                }
              })
              newSon.children = newGrandchildren;
              newChildrenList.push(newSon);
            }
          })

          newStep.children = newChildrenList;
          newSteps.push(newStep);
        }
      });
    }
    /* steps selections */
    else if(reusableCompSelectedType === ReusableCompType.Step) {
      var itemIndex = 0;
      items.forEach((item) => {
        let [step] = findStep(item.id, steps, 0);
        const newStep = cloneDeep(step);
        if (newStep) {
          item.children?.filter((r: any) => r.checked).forEach((citem: any, cindex: number) => {
            let [son] = findStep(citem.id, steps, 0);
            const newSon = cloneDeep(son);
            if (newSon && citem.checked) {
              newSon.guid = uuidv4();
              newSon.immutableGuid = uuidv4();
              newSon.sortOrder = itemIndex + 1;
              newSon.parentGuid = null;
              newSon.stepType = StepTypes.Step;
              newSon.hasUnResolvedComments = citem.hasUnResolvedComments;
              updateStepsComponents(newSon);
              const newGrandchildren: IStep[] = [];

              citem.children?.filter((r: any) => r.checked).forEach((gitem: any, gindex: number) => {
                let [grandSon] = findStep(gitem.id, steps, 0);
                const newgrandSon = cloneDeep(grandSon);
                if (newgrandSon && gitem.checked) {
                  newgrandSon.guid = uuidv4();
                  newgrandSon.immutableGuid = uuidv4();
                  newgrandSon.sortOrder = gindex + 1;
                  newgrandSon.parentGuid = gitem.nestedIn || null;
                  newgrandSon.hasUnResolvedComments = gitem.hasUnResolvedComments;
                  updateStepsComponents(newgrandSon);
                  const newGreatGrandchildren: IStep[] = [];
                  gitem.children?.filter((r: any) => r.checked).forEach((gGItem: any, gGIndex: number) => {
                    let [greatGrandSon] = findStep(gGItem.id, steps, 0);
                    const newgreatGrandSon = cloneDeep(greatGrandSon);
                    if (newgreatGrandSon && gGItem.checked) {
                      newgreatGrandSon.guid = uuidv4();
                      newgreatGrandSon.sortOrder = gGIndex + 1;
                      newgreatGrandSon.parentGuid = gGItem.nestedIn || null;
                      newgreatGrandSon.immutableGuid = uuidv4();
                      newgreatGrandSon.hasUnResolvedComments = gGItem.hasUnResolvedComments;
                      updateStepsComponents(newgreatGrandSon);
                      newGreatGrandchildren.push(newgreatGrandSon);
                    }
                  })

                  newgrandSon.children = newGreatGrandchildren;
                  newGrandchildren.push(newgrandSon);
                }
              })
              newSon.children = newGrandchildren;
              newSteps.push(newSon);
            }
            itemIndex++;
          })
        }
      });
    }
    /* sub steps selections */
    else if(reusableCompSelectedType === ReusableCompType.SubStep) {
      let subStepItemIndex = 0;
      items.forEach((item) => {
        let [step] = findStep(item.id, steps, 0);
        const newStep = cloneDeep(step);
        if (newStep) {
          item.children?.forEach((citem: any) => {
            let [son] = findStep(citem.id, steps, 0);
            const newSon = cloneDeep(son);
            if (newSon) {
              citem.children?.filter((r: any) => r.checked).forEach((gitem: any) => {
                let [grandSon] = findStep(gitem.id, steps, 0);
                const newgrandSon = cloneDeep(grandSon);
                if (newgrandSon && gitem.checked) {
                  newgrandSon.guid = uuidv4();
                  newgrandSon.immutableGuid = uuidv4();
                  newgrandSon.sortOrder = subStepItemIndex + 1;
                  newgrandSon.parentGuid = null;
                  newgrandSon.hasUnResolvedComments = gitem.hasUnResolvedComments;
                  newgrandSon.stepType = StepTypes.SubStep;
                  updateStepsComponents(newgrandSon);
                  const newGreatGrandchildren: IStep[] = [];
                  gitem.children?.filter((r: any) => r.checked).forEach((gGItem: any, gGIndex: number) => {
                    let [greatGrandSon] = findStep(gGItem.id, steps, 0);
                    const newgreatGrandSon = cloneDeep(greatGrandSon);
                    if (newgreatGrandSon && gGItem.checked) {
                      newgreatGrandSon.guid = uuidv4();
                      newgreatGrandSon.immutableGuid = uuidv4();
                      newgreatGrandSon.sortOrder = gGIndex + 1;
                      newgreatGrandSon.parentGuid = newgrandSon.guid || null;
                      newgreatGrandSon.hasUnResolvedComments = gGItem.hasUnResolvedComments;
                      updateStepsComponents(newgreatGrandSon);
                      newGreatGrandchildren.push(newgreatGrandSon);
                    }
                  })

                  newgrandSon.children = newGreatGrandchildren;
                  newSteps.push(newgrandSon);
                  subStepItemIndex++;
                }
              })
            }
          })
        }
      });
    }
    updateStepsComponents(newSteps);
    dispatch(updateReusableCompSteps(newSteps));
  };
  if (currentTab === CreateReusableContentTab.SelectMetadata) {
    mainComponent = (
      <div>
        <div className="panelHeader">
          Select Metadata
        </div>
        <div>
          <SelectMetadata
            setPreviousTab={() => setCurrentTab(CreateReusableContentTab.SelectSteps)}
            updateReusableSteps={() => updateReusableSteps()} />
        </div>
      </div>
    );
  } else if (currentTab === CreateReusableContentTab.SelectSteps) {
    mainComponent = (
      <div>
        <div className="panelHeader">
          {
           type === SWTypes.MFGSWI 
          ?<div>Select Steps/Sub Steps</div>
          :<div>Select Tasks/Steps/Sub Steps</div>          
          }
        </div>
        <div>
          {hasFormulas() && (
            <div className="formula-warning">
              <strong>Warning!</strong> This SW contains formulas. Formulas will
              not be copied to the generated RC.
            </div>
          )}
        </div>
        <div>
          <SelectSteps 
          isDisabled={true} 
          setNextTab={() => setCurrentTab(CreateReusableContentTab.SelectMetadata)} 
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <FlowLayout>
        {mainComponent}
      </FlowLayout>
    </>
  );
}

export default CreateReusableContent;


