import { current } from "@reduxjs/toolkit";
import { INoticeComponent, IStep, RCTypes, StepComponentTypes, StepTypes } from "interfaces/sw/SWInterfaces";
import { forEach } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { IManageSWState } from "./manageSWTypes";

export function findAndDeleteStep(stepGuid: string, stepList: IStep[]): boolean {
  for (let i = 0; i < stepList.length; i++) {
    if (stepList[i].guid === stepGuid) {
      stepList.splice(i, 1);
      return true;
    } else if (stepList[i].children.length) {
      if (findAndDeleteStep(stepGuid, stepList[i].children)) {
        return true;
      }
    }
  }

  return false;
}

export function findAndDeleteAllRCStep(rcId: number, stepList: IStep[]): boolean {
  let rcStepGuids: string[] = [];
  for (let index = 0; index < stepList.length; index++) {
    if (stepList[index].rcID === rcId) {
      rcStepGuids.push(stepList[index].guid);
    } else if (stepList[index].children.length) {
      findAndDeleteAllRCStep(rcId, stepList[index].children)
    }
  }
  for (let j = 0; j < rcStepGuids.length; j++) {
    findAndDeleteStep(rcStepGuids[j], stepList);
  }
  return true;
}

export function updateAllImportedRC(stepList: IStep[], rcID: number, rcTitle: string): IStep {
  for (let i = 0; i < stepList.length; i++) {
    stepList[i].masterStepId = stepList[i].id
    stepList[i].isRCStep = true;
    stepList[i].rcID = rcID;
    stepList[i].rcTitle = rcTitle;
    if (stepList[i].children.length) {
      updateAllImportedRC(stepList[i].children, rcID, rcTitle)
    }
  }
  const newGuid = uuidv4();
  let dummyStep: IStep = {
    guid: newGuid,
    parentGuid: null,
    title: rcTitle,
    sortOrder: 1,
    components: [],
    children: stepList,
    isMasterStep: false,
    isMemoryStep: false,
    isComprehensionRequired: false,
    isConditional: false,
    isCritComp: false,
    isCritStep: false,
    depth: 0,
    hasUnResolvedComments: false,
    stepComments: [],
    isTask: false,
    isRCStep: false,
    rcContainer: true,
    stepType: stepList[0].stepType,
    rcID: rcID,
    isDisabled: false,
    isStale: false,
    OOS: []
  };

  return dummyStep;
}

export function findNextSortOrder(stepList: IStep[]): number {
  let sortOrder: number = 1;
  for (let index = 0; index < stepList.length; index++) {
    const element = stepList[index];
    if (element.rcContainer) {
      for (let j = 0; j < element.children.length; j++) {
        sortOrder++;
      }
    } else {
      sortOrder++;
    }

  }
  return sortOrder;
}


export function applyStepSortOrder(stepList: IStep[]): IStep[] {
  let trcCounter: number = 0;
  for (let pIndex = 0; pIndex < stepList.length; pIndex++) {
    const parent = stepList[pIndex];
    if (parent.rcContainer) {

      for (let prcIndex = 0; prcIndex < parent.children.length; prcIndex++) {
        trcCounter++;
        parent.sortOrder = trcCounter;
        const prc = parent.children[prcIndex];
        prc.stepNumber = trcCounter.toString();

        // child
        if (prc.children.length) {
          for (let l = 0; l < prc.children.length; l++) {
            const ggChild = prc.children[l];
            const ggstepNo = (prc.stepNumber + "." + (l + 1)).toString();
            ggChild.stepNumber = ggstepNo;

            if (ggChild.children.length) {

              for (let m = 0; m < ggChild.children.length; m++) {
                const gggChild = ggChild.children[m];
                const gggstepNo = (ggstepNo + "." + (m + 1)).toString();
                gggChild.stepNumber = gggstepNo;

                if (gggChild.children.length) {

                  for (let m = 0; m < gggChild.children.length; m++) {
                    const ggggChild = gggChild.children[m];
                    const ggggstepNo = (gggstepNo + "." + (m + 1)).toString();
                    ggggChild.stepNumber = ggggstepNo;
                  }
                }
              }
            }
          }
        }

      }
    }
    else {
      trcCounter++;
      parent.stepNumber = (trcCounter).toString();
      parent.sortOrder = trcCounter;
      let rcCounter: number = 0;
      for (let cIndex = 0; cIndex < parent.children.length; cIndex++) {
        const child = parent.children[cIndex];
        if (child.rcContainer) {
          for (let gcIndex = 0; gcIndex < child.children.length; gcIndex++) {
            rcCounter++;
            const gChild = child.children[gcIndex];
            gChild.stepNumber = parent.stepNumber + '.' + (rcCounter).toString();

            // grand child and gg child
            if (gChild.children) {
              for (let l = 0; l < gChild.children.length; l++) {
                const ggChild = gChild.children[l];
                const ggstepNo = (gChild.stepNumber + "." + (l + 1)).toString();
                ggChild.stepNumber = ggstepNo;

                if (ggChild.children.length) {

                  for (let m = 0; m < ggChild.children.length; m++) {
                    const gggChild = ggChild.children[m];
                    const gggstepNo = (ggstepNo + "." + (m + 1)).toString();
                    gggChild.stepNumber = gggstepNo;
                  }
                }
              }
            }
          }
        } else {
          rcCounter++;
          child.stepNumber = parent.stepNumber + '.' + (rcCounter).toString();
          if (child.children) {
            let rcSubCounter: number = 0;
            for (let subChild = 0; subChild < child.children.length; subChild++) {

              const newSubChild = child.children[subChild];
              if (newSubChild.rcContainer) {
                for (let gnewSubChild = 0; gnewSubChild < newSubChild.children.length; gnewSubChild++) {
                  rcSubCounter++;
                  const subChildinContainer = newSubChild.children[gnewSubChild];
                  subChildinContainer.stepNumber = (child.stepNumber + "." + (rcSubCounter)).toString();

                  if (subChildinContainer.children.length) {
                    for (let m1 = 0; m1 < subChildinContainer.children.length; m1++) {
                      const gggChild = subChildinContainer.children[m1];
                      const gggstepNo = (subChildinContainer.stepNumber + "." + (m1 + 1)).toString();
                      gggChild.stepNumber = gggstepNo;
                    }
                  }
                }
              }
              else {
                rcSubCounter++;
                newSubChild.stepNumber = (child.stepNumber + "." + (rcSubCounter)).toString();

                if (newSubChild.children.length > 0) {
                  for (let m2 = 0; m2 < newSubChild.children.length; m2++) {
                    const ggChild = newSubChild.children[m2];
                    ggChild.stepNumber = (child.stepNumber + "." + (m2 + 1)).toString();
                  }
                }
              }
            }
          }
        }
      }
    }

  }
  return stepList;
}

export function recursiveApplySortOrderAfterRCContainerRemoval(stepList: IStep[], level: number): IStep[] {
  let steps: IStep[] = [];
  stepList.forEach(step => steps.push(Object.assign({}, step)));
  for (let index = 0; index < steps.length; index++) {
    const parent = steps[index];
    parent.sortOrder = index + 1;
    if (parent.children.length > 0 && level > 1) {
      parent.children = recursiveApplySortOrderAfterRCContainerRemoval(parent.children, level - 1);
    }
  }
  return steps;
}

// export function applySortOrderAfterRCContainerRemoval(stepList: IStep[]): IStep[] {
//   for (let index = 0; index < stepList.length; index++) {
//     const parent = stepList[index];
//     parent.sortOrder = index + 1;
//     for (let j = 0; j < parent.children.length; j++) {
//       const child = parent.children[j];
//       child.sortOrder = j + 1;
//       for (let k = 0; k < child.children.length; k++) {
//         const grandChild = child.children[k];
//         grandChild.sortOrder = k + 1;
//         for (let l = 0; l < grandChild.children.length; l++) {
//           const greatGrandChild = grandChild.children[l];
//           greatGrandChild.sortOrder = l + 1;
//           for (let m = 0; m < greatGrandChild.children.length; m++) {
//             const gggChild = greatGrandChild.children[m];
//             gggChild.sortOrder = m + 1;
//           }
//         }
//       }
//     }

//   }
//   return stepList;
// }
//Previous code now implemented in recursive
// if (element.children.length) {
//   let newElement: IStep = Object.assign({}, element);
//   newElement.children = [];
//   for (let k = 0; k < element.children.length; k++) {
//const childElement = element.children[k];
//if (childElement.rcContainer) {
//  newElement.children = newElement.children.concat(getStepsFromRCContainer(childElement));
//} else {
//  //check if any step is RCContainer then remove it.
//  if (childElement.children.length > 0 && childElement.children.filter(a => a.rcContainer).length > 0) {
//    let newChildElement: IStep = Object.assign({}, childElement);
//    newChildElement.children = [];
//    for (let t = 0; t < childElement.children.length; t++) {
// const childSubElement = childElement.children[t];
// if (childSubElement.rcContainer) {
//   newChildElement.children = newChildElement.children.concat(getStepsFromRCContainer(childSubElement));
// }
// else {
//   newChildElement.children.push(childSubElement);
// }
//    }
//    newElement.children.push(newChildElement);
//  }
//  else {
//    newElement.children.push(childElement);
//  }
//}
//   }
//   newStepList.push(newElement);
// } else {
//   newStepList.push(element);
// }



export function getStepsFromRCContainer(step: IStep): IStep[] {
  let newSteps: IStep[] = [];
  for (let index = 0; index < step.children.length; index++) {
    const element = step.children[index];
    newSteps.push(element);
  }
  return newSteps;
}

export function updateRCLinkBreakSteps(stepList: IStep[], rcID: number): IStep[] {

  for (let index = 0; index < stepList.length; index++) {
    const parent = stepList[index];
    //if this is a task level RC
    if (parent.rcContainer && parent.rcID === rcID) {
      let newSteps: IStep[] = [];
      for (let j = 0; j < parent.children.length; j++) {
        const child = parent.children[j];
        child.masterStepId = undefined;
        child.isRCStep = false;
        child.rcID = undefined;
        child.immutableGuid = undefined;
        child.components?.forEach(c => {
          c.immutableGuid = undefined;
        });
        for (let k = 0; k < child.children.length; k++) {
          const grandChild = child.children[k];
          grandChild.masterStepId = undefined;
          grandChild.isRCStep = false;
          grandChild.rcID = undefined;
          grandChild.immutableGuid = undefined;
          grandChild.components?.forEach(c => {
            c.immutableGuid = undefined;
          });
          for (let l = 0; l < grandChild.children.length; l++) {
            const ggChild = grandChild.children[l];
            ggChild.masterStepId = undefined;
            ggChild.isRCStep = false;
            ggChild.rcID = undefined;
            ggChild.immutableGuid = undefined;
            ggChild.components?.forEach(c => {
              c.immutableGuid = undefined;
            });
            for (let m = 0; m < ggChild.children.length; m++) {
              const gggChild = ggChild.children[m];
              gggChild.masterStepId = undefined;
              gggChild.isRCStep = false;
              gggChild.rcID = undefined;
              gggChild.immutableGuid = undefined;
              gggChild.components?.forEach(c => {
                c.immutableGuid = undefined;
              });
            }
          }
        }
        newSteps.push(child);
      }
      stepList.splice(index, 1, ...newSteps);

    } else {
      if (parent.children.length) {
        for (let k = 0; k < parent.children.length; k++) {
          const child = parent.children[k];
          //if this is a step level RC
          if (child.rcContainer && child.rcID === rcID) {
            let newCSteps: IStep[] = [];
            for (let l = 0; l < child.children.length; l++) {
              const gChild = child.children[l];
              gChild.masterStepId = undefined;
              gChild.isRCStep = false;
              gChild.rcID = undefined;
              gChild.immutableGuid = undefined;
              gChild.components?.forEach(c => {
                c.immutableGuid = undefined;
              });
              for (let m = 0; m < gChild.children.length; m++) {
                const ggrandChild = gChild.children[m];
                ggrandChild.masterStepId = undefined;
                ggrandChild.isRCStep = false;
                ggrandChild.rcID = undefined;
                ggrandChild.immutableGuid = undefined;
                ggrandChild.components?.forEach(c => {
                  c.immutableGuid = undefined;
                });
                for (let n = 0; n < ggrandChild.children.length; n++) {
                  const gggChild = ggrandChild.children[n];
                  gggChild.masterStepId = undefined;
                  gggChild.isRCStep = false;
                  gggChild.rcID = undefined;
                  gggChild.immutableGuid = undefined;
                  gggChild.components?.forEach(c => {
                    c.immutableGuid = undefined;
                  });
                }
              }
              newCSteps.push(gChild);
            }
            parent.children.splice(k, 1, ...newCSteps);
          } else {
            if (child.children.length) {
              for (let j = 0; j < child.children.length; j++) {
                const gChild = child.children[j];
                //if this is a substep level RC
                if (gChild.rcContainer && gChild.rcID === rcID) {
                  let newGCSteps: IStep[] = [];
                  for (let k = 0; k < gChild.children.length; k++) {
                    const ggrandChild = gChild.children[k];
                    ggrandChild.masterStepId = undefined;
                    ggrandChild.isRCStep = false;
                    ggrandChild.rcID = undefined;
                    ggrandChild.immutableGuid = undefined;
                    ggrandChild.components?.forEach(c => {
                      c.immutableGuid = undefined;
                    });
                    for (let l = 0; l < ggrandChild.children.length; l++) {
                      const gggChild = ggrandChild.children[l];
                      gggChild.masterStepId = undefined;
                      gggChild.isRCStep = false;
                      gggChild.rcID = undefined;
                      gggChild.immutableGuid = undefined;
                      gggChild.components?.forEach(c => {
                        c.immutableGuid = undefined;
                      });
                    }
                    newGCSteps.push(ggrandChild);
                  }
                  child.children.splice(j, 1, ...newGCSteps);
                }
              }
            }
          }
        }
      }
    }
  }

  return applyStepSortOrder(stepList);

}

export function recursiveRemoveRCContainerForChildren(element: IStep): IStep {
  let newElement: IStep = Object.assign({}, element);
  if (element.children.length) {
    newElement.children = [];
    for (let k = 0; k < element.children.length; k++) {
      const childElement: IStep = Object.assign({}, element.children[k])
      if (childElement.rcContainer) {
        newElement.children = newElement.children.concat(getStepsFromRCContainer(childElement))
      } else if (childElement.children.length > 0) {
        newElement.children.push(recursiveRemoveRCContainerForChildren(childElement));
      } else {
        newElement.children.push(childElement);
      }
    }
  }
  return newElement;
}

export function removeRCContainer(stepList: IStep[]): IStep[] {
  let newStepList: IStep[] = [];

  for (let index = 0; index < stepList.length; index++) {
    const element = stepList[index];
    if (element.rcContainer) {
      const rcContainerSteps = getStepsFromRCContainer(element);
      if (rcContainerSteps) {
        for (let j = 0; j < rcContainerSteps.length; j++) {
          const rcElement = rcContainerSteps[j];
          newStepList.push(rcElement);
        }
      }
    } else {
      newStepList.push(recursiveRemoveRCContainerForChildren(element));
    }
  }

  return recursiveApplySortOrderAfterRCContainerRemoval(newStepList, 3);
}

export function getDummyRCTaskContainer(rcTitle: string, stepList: IStep[], rcID: number): IStep {
  const newGuid = uuidv4();
  let dummyStep: IStep = {
    guid: newGuid,
    parentGuid: null,
    title: rcTitle,
    sortOrder: 1,
    components: [],
    children: stepList,
    isMasterStep: false,
    isMemoryStep: false,
    isComprehensionRequired: false,
    isConditional: false,
    isCritComp: false,
    isCritStep: false,
    depth: 0,
    hasUnResolvedComments: false,
    stepComments: [],
    isTask: false,
    isRCStep: false,
    rcContainer: true,
    stepType: stepList[0].stepType,
    rcID: rcID,
    isDisabled: false,
    isStale: false,
    OOS: []
  };

  return dummyStep;
}

export function getDummyRCStepContainer(rcTitle: string, stepList: IStep[], rcID: number, parentGuid: string): IStep {
  for (let index = 0; index < stepList.length; index++) {
    const element = stepList[index];
    element.rcID = rcID;
  }
  const newGuid = uuidv4();
  let dummyStep: IStep = {
    guid: newGuid,
    parentGuid: parentGuid,
    title: rcTitle,
    sortOrder: 1,
    components: [],
    children: stepList,
    isMasterStep: false,
    isMemoryStep: false,
    isComprehensionRequired: false,
    isConditional: false,
    isCritComp: false,
    isCritStep: false,
    depth: 0,
    hasUnResolvedComments: false,
    stepComments: [],
    isTask: false,
    isRCStep: false,
    rcContainer: true,
    stepType: stepList[0].stepType,
    rcID: rcID,
    isDisabled: false,
    isStale: false,
    OOS: []
  };
  return dummyStep;
}

export function AddContainerForRCSteps(stepList: IStep[]): IStep[] {
  let formattedSteps: IStep[] = [];
  while (stepList.length != 0) {
    const parent = stepList[0];
    if (parent.rcID && parent.rcTitle) {
      // filtering out tasks on the rcid basis
      const allRCSteps = stepList.filter(a => a.rcID === parent.rcID);
      //finding out unique tasks, avoid duplicates as they belongs to next rccontainer
      const output = filteredSteps(allRCSteps);
      const dummyStep = getDummyRCTaskContainer(parent.rcTitle, output, parent.rcID);
      dummyStep.isStale = parent.isStale;
      //removing processed tasks from list by id.
      output.forEach(x => {
        removeByAttr(stepList, 'id', x.id || 0);
      });
      formattedSteps.push(dummyStep);
    } else {
      if (parent.children.length) {
        let { ...newParent } = parent;
        newParent.children = [];
        while (parent.children.length != 0) {
          const child = parent.children[0];
          if (child.rcID && child.rcTitle) {
            //first filtering out steps on the rcid basis
            const allRCSteps = parent.children.filter(a => a.rcID === child.rcID);
            //finding out unique steps, avoid duplicates as they belongs to next rccontainer
            const output = filteredSteps(allRCSteps);
            const dummyChildStep = getDummyRCStepContainer(child.rcTitle, output, child.rcID, parent.guid);
            dummyChildStep.isStale = child.isStale;
            //removing processed steps from list by id.
            output.forEach(x => {
              removeByAttr(parent.children, 'id', x.id || 0);
            });
            newParent.children.push(dummyChildStep);
          } else {
            //check if any step is RCStep then add RCContainer to it.
            if (child.children.length > 0 && child.children.filter(a => a.isRCStep).length > 0) {
              let { ...newChild } = child;
              newChild.children = [];
              //for (let t = 0; t < child.children.length; t++) {
              while (child.children.length != 0) {
                const subChild = child.children[0];
                if (subChild.rcID && subChild.rcTitle) {
                  //first filtering out substeps on the rcid basis
                  const allRCSteps = child.children.filter(a => a.rcID === subChild.rcID);
                  //taking unique substeps, avoid duplicates as they belongs to next rccontainer
                  const output = filteredSteps(allRCSteps);
                  const dummySubChildStep = getDummyRCStepContainer(subChild.rcTitle, output, subChild.rcID, child.guid);
                  dummySubChildStep.isStale = subChild.isStale;
                  //removing processed substeps from list by id.
                  output.forEach(x => {
                    removeByAttr(child.children, 'id', x.id || 0);
                  });
                  newChild.children.push(dummySubChildStep);
                } else {
                  newChild.children.push(subChild);
                  if (subChild.id === undefined && child.children.length > 0) {
                    removeByAttrUndefined(child.children, 'id');
                  }
                  removeByAttr(child.children, 'id', subChild.id || 0);
                }
              }
              newParent.children.push(newChild);
            }
            else {
              newParent.children.push(child);
            }
            if (child.id === undefined && parent.children.length > 0) {
              removeByAttrUndefined(parent.children, 'id');
            }
            removeByAttr(parent.children, 'id', child.id || 0);
          }
        }
        formattedSteps.push(newParent);
      } else {
        formattedSteps.push(parent);
      }
      if (parent.id === undefined && stepList.length > 0) {
        removeByAttrUndefined(stepList, 'id');
      }
      removeByAttr(stepList, 'id', parent.id || 0);
    }

  }
  return applyStepSortOrder(formattedSteps);
}

export function removeByAttr(arr: any, attr: string, value: number) {
  var i = arr.length;
  while (i--) {
    if (arr[i]
      && arr[i].hasOwnProperty(attr)
      && (arguments.length > 0 && arr[i][attr] === value)) {
      arr.splice(i, 1);
    }
  }
  return arr;
}


export function removeByAttrUndefined(arr: any, attr: string) {
  if (arr[0]
    && (arguments.length > 0
      && ((!arr[0].hasOwnProperty(attr) && arr[0][attr] === undefined)
        || (arr[0].hasOwnProperty(attr) && arr[0][attr] !== 0)))) {
    arr.splice(0, 1);
  }
  return arr;
}


export function filteredSteps(allRCSteps: IStep[]) {
  const flags = [], output = [];
  for (let i = 0; i < allRCSteps.length; i++) {
    if (flags[allRCSteps[i].masterStepId ?? 0]) continue;
    flags[allRCSteps[i].masterStepId ?? 0] = true;
    output.push(allRCSteps[i]);
  }
  return output;
}
export function updateImportedRCStep(rcSteps: IStep[], parentStepGuid: string, allSteps: IStep[], rcID: number, rcTitle: string): IStep[] {

  for (let index = 0; index < rcSteps.length; index++) {
    const element = rcSteps[index];
    element.rcID = rcID;
    element.rcTitle = rcTitle;
  }
  const newGuid = uuidv4();
  let dummyStep: IStep = {
    guid: newGuid,
    parentGuid: parentStepGuid,
    title: rcTitle,
    sortOrder: 1,
    components: [],
    children: rcSteps,
    isMasterStep: false,
    isMemoryStep: false,
    isComprehensionRequired: false,
    isConditional: false,
    isCritComp: false,
    isCritStep: false,
    depth: 0,
    hasUnResolvedComments: false,
    stepComments: [],
    isTask: false,
    isRCStep: false,
    rcContainer: true,
    rcID: rcID,
    isDisabled: false,
    stepType: StepTypes.Step,
    isStale: false,
    OOS: []
  };

  for (let i = 0; i < allSteps.length; i++) {
    if (allSteps[i].guid === parentStepGuid) {
      allSteps[i].children.push(dummyStep);
    }
  }
  return allSteps;
}

export function updateImportedRCSubSteps(rcSteps: IStep[], parentStep: IStep, rcType: RCTypes | undefined, rcID: number, rcTitle: string) {

  for (let i = 0; i < rcSteps.length; i++) {
    let element = rcSteps[i];
    element.rcID = rcID;
    element.rcTitle = rcTitle;
    element.masterStepId = rcSteps[i].id
    element.isRCStep = true;
  }

  const newGuid = uuidv4();
  let dummySubStep: IStep = {
    guid: newGuid,
    parentGuid: parentStep.guid,
    title: rcTitle,
    sortOrder: 1,
    components: [],
    children: rcSteps,
    isMasterStep: false,
    isMemoryStep: false,
    isComprehensionRequired: false,
    isConditional: false,
    isCritComp: false,
    isCritStep: false,
    depth: 0,
    hasUnResolvedComments: false,
    stepComments: [],
    isTask: false,
    isRCStep: false,
    rcContainer: true,
    rcID: rcID,
    isDisabled: false,
    stepType: rcType === RCTypes.SubStep ? StepTypes.SubStep : undefined,
    isStale: false,
    OOS: []
  };

  parentStep.children.push(dummySubStep);
}

export function findStep(stepGuid: string, stepList: IStep[], depth: number = 0): [IStep | undefined, number] {
  if (stepList !== undefined) {
    for (let i = 0; i < stepList.length; i++) {
      if (stepList[i].guid === stepGuid) {
        return [stepList[i], depth];
      } else if (stepList[i].children.length) {
        let foundStep = findStep(stepGuid, stepList[i].children, depth + 1);
        if (foundStep[0]) {
          return foundStep;
        }
      }
    }
  }
  return [undefined, -1];
}

export function findNotice(noticeGuid: string, stepGuid: string | undefined, state: IManageSWState) {
  if (!stepGuid) {
    return findNoticeHelper(state.SW.notices, noticeGuid);
  }
  else {
    var [step, num] = findStep(stepGuid, state.SW.steps, 0);
    if (num !== -1) {
      if (step) {
        var stepNoticeComponents = step.components.filter(comp => comp.type === StepComponentTypes.Notice) as INoticeComponent[];
        return findNoticeHelper(stepNoticeComponents, noticeGuid);
      }
    }
  }
  return undefined;
}

export function findNoticeHelper(notices: INoticeComponent[], noticeGuid: string) {
  if (notices) {
    for (let i = 0; i < notices.length; i++) {
      if (notices[i].guid === noticeGuid) {
        return notices[i];
      }
    }
  }
}

export function findStepwithSWID(swId: number, stepList: IStep[], depth: number): [IStep | undefined, number] {
  if (stepList !== undefined) {
    for (let i = 0; i < stepList.length; i++) {
      if (stepList[i].rcID === swId) {
        return [stepList[i], depth];
      } else if (stepList[i].children.length) {
        let foundStep = findStepwithSWID(swId, stepList[i].children, depth + 1);
        if (foundStep[0]) {
          return foundStep;
        }
      }
    }
  }
  return [undefined, -1];
}
