import React, { useEffect } from "react";
import { ITableComponentCell } from "interfaces/sw/SWInterfaces";
import "./RefDocTableCell.scoped.scss";
import { showErrorToast } from "store/toast/toastActions";
import { useDispatch } from "react-redux";
import { getRefDocTableCellImageUrls } from "store/manageSW/manageSWActions";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";

interface IRefDocTableCellProps {
  rowKey: number,
  colKey: number,
  cells: ITableComponentCell[],
  refDocGuid: string,
  swGuid: string,
  swVersion: number,
  imageDataDestination: ImageDataDestinations,
}

const RefDocTableCell: React.FC<IRefDocTableCellProps> = ({
  rowKey,
  colKey,
  cells,
  refDocGuid,
  swGuid,
  swVersion,
  imageDataDestination,
}) => {
  const dispatch = useDispatch();
  let existingCell = cells.find(x => x.rowIndex === rowKey && x.colIndex === colKey);
  let filename = "";

  if (existingCell?.value) {
    let cellImage = getImage(existingCell.value);

    if (cellImage) {
      filename = cellImage;
    }
  }

  useEffect(() => {
    let aborted = false;
    const fetchImage = async () => {
      try {
        if (!aborted) {
          dispatch(getRefDocTableCellImageUrls({
            refDocGuid: refDocGuid,
            rowIndex: rowKey,
            colIndex: colKey,
            swGuid,
            swVersion,
            filename: filename,
            destination: imageDataDestination,
          }));
        }
      } catch (err: any) {
        if (!aborted) {
          showErrorToast(err?.message || err.toString())
        }
      }
    }

    if (filename !== "") {
      fetchImage();
    }

    return () => {
      aborted = true;
    };
  }, [existingCell, dispatch, filename, swGuid, swVersion, colKey, rowKey, refDocGuid, imageDataDestination]);

  if (!existingCell) {
    return null;
  }

  return (
    <div
      className="execution-preview-editor"
      dangerouslySetInnerHTML={createMarkup(existingCell.value)}
    />
  ); 
}

export default RefDocTableCell;

function createMarkup(data: string) {
  return { __html: data };
}

function getImage(html: string): string | undefined {
  const imgRex = /<img.*?alt="(.*?)"[^>]+>/g;
    let img;
    while ((img = imgRex.exec(html))) {
        return img[1];      
    }
  return undefined;
}
