import { SWStatus, SWTypes } from "interfaces/sw/SWInterfaces";
import React, { useCallback, useRef } from "react";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import useSelector from "store/useSelector";
import SWTypeIcon from "../search/SWTypeIcon";
import "./ExecutionPreview.scoped.scss";
import Notices from "./Notices";
import PPEList from "./PPEList";
import StepList from "./StepList";
import SWStatusIndicator from "./SWStatusIndicator";

const ExecutionPreview: React.FC = () => {
    const {
        manageSW: {
            SW,
        }
    } = useSelector(store => store);
    const swHeaderRef = useRef<HTMLDivElement>(null);
    const memoizedFocusOnHeader = useCallback(
        () => {
            swHeaderRef.current?.scrollIntoView();
        },
        []
    );

    return (
        <>
            <div
                className="header-container"
            >
                <div
                    className="sw-type"
                >
                    <SWTypeIcon
                        type={SW.type}
                    />
                </div>
                <div
                    className="sw-header"
                >
                    <div
                        className="sw-details no-text-select"
                        ref={swHeaderRef}
                    >
                        <div
                            className="status"
                        >
                            <SWStatusIndicator
                                status={SWStatus.Incomplete}
                            />
                        </div>
                        <div
                            className="header-row"
                        >
                            <div
                                className="sw-info"
                            >
                                <span
                                    className="sw-title"
                                >
                                    {SW.title}{SW.version > 0 ? ` (Instance ${SW.version})` : ""}
                                </span>
                                <span
                                    className="sw-desc"
                                >
                                    {SW.description}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="sw"
            >
                {SW.ppe.length > 0 &&
                    <PPEList
                        ppe={SW.ppe}
                    />
                }
                {SW.notices.length > 0 &&
                    <Notices
                        notices={SW.notices}
                        swGuid={SW.guid}
                        swType={SW.type}
                        swVersion={SW.version}
                        imageDataDestination={ImageDataDestinations.ManageSW}
                    />
                }

                <h4
                    className="steps-header"
                >
                    {SW.type === SWTypes.TLMSWI || SW.type === SWTypes.MFGSWI ? 'Tasks' : 'Steps'}
                </h4>

                <StepList
                    sw={SW}
                    focusSWHeader={memoizedFocusOnHeader}
                />
            </div>
        </>
    );
}

export default ExecutionPreview;