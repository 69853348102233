import { ISW, SWTypes, TaskAlignments, StepComponentTypes, IImageComponent, IMicrosoftStreamVideoComponent, ILinkComponent, IStep, IVideoComponent, IPPEComponent, IAudioComponent, ITIMEImageComponent } from "interfaces/sw/SWInterfaces";

export interface IValidationResult {
  isValid: boolean,
  errorMessage: string,
}

const getValidationError = (errorMessage: string): IValidationResult => ({
  isValid: false,
  errorMessage,
});

function validateStepRecursive(swStep: IStep, depth: number, ancestryPath: string): IValidationResult {

  let stepPath: string = !ancestryPath ? swStep.sortOrder.toString() : ancestryPath + "." + swStep.sortOrder.toString();
  // set stepPath in case swStep.stepNumber is undefined;  for rc wrapper scenario those are not having any stepNumber of their own.
  const stepNumber = swStep.stepNumber ?? stepPath;
  let validationError: string = "";
  for (let j = 0; j < swStep.components.length; j++) {
    if (swStep.components[j].type === StepComponentTypes.Image) {
      let imageComp = swStep.components[j] as IImageComponent;
      if (!imageComp.filename) {
        validationError = `${swStep.isTask ? "Task" : "Step"} ${stepNumber} cannot have a Image component without Image.`
        break;
      }
    } else if (swStep.components[j].type === StepComponentTypes.TimeImage) {
      let timeImageComp = swStep.components[j] as ITIMEImageComponent;
      if (!timeImageComp.filename) {
        validationError = `${swStep.isTask ? "Task" : "Step"} ${stepNumber} cannot have a TimeImage component without Image.`
        break;
      }
    } else if (swStep.components[j].type === StepComponentTypes.MicrosoftStreamVideo) {
      let streamComp = swStep.components[j] as IMicrosoftStreamVideoComponent;
      if (!streamComp.videoGuid) {
        validationError = `${swStep.isTask ? "Task" : "Step"} ${stepNumber} cannot have a Microsoft Stream Video component without VideoGuid.`
        break;
      }
    } else if (swStep.components[j].type === StepComponentTypes.Video) {
      let videoComp = swStep.components[j] as IVideoComponent;
      if (!videoComp.filename) {
        validationError = `${swStep.isTask ? "Task" : "Step"} ${stepNumber} cannot have a Video component without Video.`
        break;
      }
    } else if (swStep.components[j].type === StepComponentTypes.Audio) {
      let audioComp = swStep.components[j] as IAudioComponent;
      if (!audioComp.filename) {
        validationError = `${swStep.isTask ? "Task" : "Step"} ${stepNumber} cannot have a Audio component without Audio.`
        break;
      }
    } else if (swStep.components[j].type === StepComponentTypes.PPE) {
      let ppeComp = swStep.components[j] as IPPEComponent;
      if (ppeComp.ppe.length === 0) {
        validationError = `${swStep.isTask ? "Task" : "Step"} ${stepNumber} cannot have a PPE component without PPE.`
        break;
      }
    } else if (swStep.components[j].type === StepComponentTypes.Link) {
      let linkComp = swStep.components[j] as ILinkComponent;
      if ((linkComp.label === undefined || !linkComp.label)) {
        validationError = `${swStep.isTask ? "Task" : "Step"} ${stepNumber} cannot have a Link component without Label.`;
        break;
      }
      if (!linkComp.url) {
        validationError = `${swStep.isTask ? "Task" : "Step"} ${stepNumber} cannot have a Link component without URL.`;
        break;
      }

      let match = linkComp.url.match(/^https?:\/\/.+?\..+?$/);

      if (!match) {
        validationError = `${swStep.isTask ? "Task" : "Step"} ${stepNumber} contains an invalid Link url. The url must begin with 'http://' or 'https://' and be in the format 'somewhere.com' or 'www.somewhere.com'.`;
        break;
      }
    }
  }
  if (validationError === "" && swStep.children.length > 0) {
    for (let k = 0; k < swStep.children.length; k++) {
      let res = validateStepRecursive(swStep.children[k], depth + 1, stepPath);
      if (!res.isValid){
        return res;
      }        
    }
  }
  //}
  if (validationError !== "") {
    return getValidationError(validationError);
  } else {
    return {
      isValid: true,
      errorMessage: "",
    };
  }
}

export function validateRefDocURL(url: string): IValidationResult {
  let match = url.match(/^https?:\/\/.+?\..+?$/);
  let validationError: string = "";
  if (!url) {
    validationError = `ReferenceFile Link data is invalid.`;
  }
  else if (!match) {
    validationError = "Invalid Link url. The url must begin with 'http://' or 'https://' and be in the format 'somewhere.com' or 'www.somewhere.com'.";
  }

  if (validationError !== "") {
    return getValidationError(validationError);
  }
  else {
    return {
      isValid: true,
      errorMessage: "",
    };
  }
}

export default function validateSW(sw: ISW): IValidationResult {  
  if (!sw.type) {
    return getValidationError("Type is required.");
  }

  if (!sw.taskAlignment) {
    return getValidationError("Task Alignment is required.");
  }

  if (sw.type === SWTypes.SWI
    || sw.type === SWTypes.TLMSWI
    || sw.type === SWTypes.CL
    || sw.type === SWTypes.LCL) {
    if (sw.tasks.length > 1) {
      return getValidationError(`More than one task is not supported for ${sw.type}s.`);
    }
  }

  if (sw.type === SWTypes.SWI
    || sw.type === SWTypes.ECL
    || sw.type === SWTypes.CL
    || sw.type === SWTypes.LCL) {
    if (sw.countries.length === 0 && sw.customers.length > 0) {
      return getValidationError(`The customer can only be filled out if country is selected for ${sw.type}s.`);
    }
  }

  if (sw.type === SWTypes.SWI || sw.type === SWTypes.TLMSWI) {
    if (sw.taskAlignment !== TaskAlignments.At) {
      return getValidationError("SWIs require a Task Alignment of '" + TaskAlignments.At.toString() + "'.");
    }
  }

  if (sw.language) {
    if (sw.language.value !== "English") {
      if (!sw.title.trim()) {
        return getValidationError("Title is required.");
      }
    } else if ((sw.type === SWTypes.ECL
      || sw.type === SWTypes.TLMSWI)
      && !sw.title.trim()) {
      return getValidationError("Title is required.");
    } else if (sw.type !== SWTypes.ECL
      && sw.type !== SWTypes.TLMSWI
      && sw.type !== SWTypes.MFGSWI
      && sw.type !== SWTypes.TLMRC
      && sw.type !== SWTypes.MFGCL
      && sw.type !== SWTypes.MFGRC
      && sw.tasks.length === 0) {
      return getValidationError("Task is required.");
    }
  } else if (!sw.title.trim()) {
    return getValidationError("Title is required.");
  }

  if (!sw.owningOrg
    && sw.type === SWTypes.TLMRC) {
    return getValidationError("Owning Organization is required.");
  }

  for (let i = 0; i < sw.steps.length; i++) {
    const result: IValidationResult = validateStepRecursive(sw.steps[i], 0, "");
    if (result.errorMessage) {
      return getValidationError(result.errorMessage);
    }
  }

  let refDocLinkData = sw.referenceDocs.filter(x => x.type === "Link");
  for (let i = 0; i < refDocLinkData.length; i++) {
    const result: IValidationResult = validateRefDocURL(refDocLinkData[i].refDocData);
    if (result.errorMessage) {
      return getValidationError(result.errorMessage);
    }
  }

  return {
    isValid: true,
    errorMessage: "",
  };
}