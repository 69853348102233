import React from "react";
import { IMergeMenuItem } from "store/manageSW/manageSWTypes";
import "./MergeMenuDropdown.scoped.scss";
import MergeMenu from "./MergeMenuItem";

interface IMergeMenuDropdownProps {
  submenus: IMergeMenuItem[],
  dropdown: boolean,
  onMergeNumberClick: (value: string) => void,
  depthLevel: number,
  parentName: string,
}

const MergeMenuDropdown: React.FC<IMergeMenuDropdownProps> = ({submenus, dropdown, onMergeNumberClick, depthLevel, parentName}) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  
  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
     {submenus.map((submenu, index) => (
      <MergeMenu 
        item={submenu} 
        key={index} 
        onMergeNumberClick={onMergeNumberClick}
        depthLevel={depthLevel}
        parentName={parentName}
      />
     ))}
    </ul>
   );
  };
 
 export default MergeMenuDropdown;