import { Action } from "@reduxjs/toolkit";
import ProxyApproversApi from "apis/proxyApprovers/ProxyApproversApi";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { showErrorToast } from "store/toast/toastActions";

import {
  loadProxyApprover,
  setProxyApprover,
  setProxyOperation,
  submitDeleteProxyApprover,
  submitProxyApprover,
} from "./proxyApproversActions";

import { IProxyApprover } from "./proxyApproversTypes";

export default function* watchProxyApproverSagas() {
  yield all([
    watchRefreshProxyApprover(),
    watchSubmitProxyApprover(),
    watchSubmitDeleteProxyApprover(),
  ]);
}

function* watchRefreshProxyApprover() {
  yield takeLatest(loadProxyApprover, loadProxyApproverAsync);
}

function* watchSubmitProxyApprover() {
  yield takeLatest(submitProxyApprover, submitProxyApproverAsync);
}

function* watchSubmitDeleteProxyApprover() {
  yield takeLatest(submitDeleteProxyApprover, submitDeleteProxyApproverAsync);
}

function* loadProxyApproverAsync(action: Action) {
  if (!loadProxyApprover.match(action)) {
    return;
  }

  yield put(setProxyOperation({ isWorking: true }));

  try {
    const status: IProxyApprover | undefined = yield call(
      ProxyApproversApi.getProxyApprover
    );

    yield put(setProxyApprover(status));
  } catch (error) {
    const err = error as any;

    yield put(
      showErrorToast(
        "Failed to fetch Proxy Approver Status: \n" + (err?.message || err)
      )
    );
  } finally {
    yield put(setProxyOperation({ isWorking: false }));
  }
}

function* submitProxyApproverAsync(action: Action) {
  if (!submitProxyApprover.match(action)) {
    return;
  }

  yield put(setProxyOperation({ isWorking: true }));

  try {
    yield call(ProxyApproversApi.postProxyApprover, action.payload);
    yield put(loadProxyApprover());
  } catch (error) {
    const err = error as any;

    yield put(
      showErrorToast(
        "Failed to set your Proxy Approver: \n" + (err?.message || err)
      )
    );
  } finally {
    yield put(setProxyOperation({ isWorking: false }));
  }
}

function* submitDeleteProxyApproverAsync(action: Action) {
  if (!submitDeleteProxyApprover.match(action)) {
    return;
  }

  yield put(setProxyOperation({ isWorking: true }));

  try {
    yield call(ProxyApproversApi.deleteProxyApprover);
    yield put(loadProxyApprover());
  } catch (error) {
    const err = error as any;

    yield put(
      showErrorToast(
        "Failed to revoke Proxy Permissions: \n" + (err?.message || err)
      )
    );
  } finally {
    yield put(setProxyOperation({ isWorking: false }));
  }
}
