import React, { useEffect, useRef, useState } from "react";
import searchIcon from "media/icons/dls/search.svg";
import "./SearchBar.scoped.scss";
import { useDispatch } from "react-redux";
import { toggleAddUserModal, clearUserFilters, getFilteredUsersList } from "store/userManagement/userManagementActions";
import useSelector from "store/useSelector";
import useDebounce from "utilities/useDebounce";
import filterIcon from "media/icons/dls/filter.svg";
import UserFilterModal from "./UserFilterModal";

const SearchBar: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 2000);
  const manageUsersData = useSelector(store => store.userManagement.manageUsersData);
  const dispatch = useDispatch();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const inputBoxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputBoxRef.current){
        inputBoxRef.current.focus(); // set focus to search input box after rendering results
    }
  }, [manageUsersData]);

  useEffect(() => {
    dispatch(getFilteredUsersList({ searchTerm: debouncedSearchTerm.trim(), isListUserSearch: true }));
  }, [debouncedSearchTerm, dispatch]);

  const applyFilter = () => {
    dispatch(getFilteredUsersList({ searchTerm: debouncedSearchTerm.trim(), isListUserSearch: true }));
  }

  const clearFilter = () => {
    dispatch(clearUserFilters());
  }

  return (
    <div className="search-bar">
      <div className="input-holder">
        <input
          type="text"
          ref={inputBoxRef}
          id="sw-search"
          placeholder="Search by name or email..."
          disabled={manageUsersData.getUsersOperation?.isWorking}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <img
          src={searchIcon}
          alt="Search"
          className="icon-small"
        />
      </div>
      <img
        src={filterIcon}
        alt="Filters"
        className="icon-medium filter-button"
        title="Filters"
        onClick={() => setIsFilterModalOpen(true)}
      />
      <div className="add-holder">
        <button
          className="primary-button"
          onClick={() => dispatch(toggleAddUserModal(true))}
        >
          Add User
        </button>
      </div>
      {isFilterModalOpen &&
        <UserFilterModal
          setIsModalOpen={setIsFilterModalOpen}
          applyFilter={applyFilter}
          clearFilter={clearFilter}
        />
      }
    </div>
  );
}

export default SearchBar;