import { IFeedbackDocuments } from "store/feedback/feedbackTypes";

export interface IFeedbackSave {
  title: string;
  description: string;
  group: string;
  priority: string;
  documents: IFeedbackDocuments[];
}

export enum DataFeedbackPriorityTypes {
  Critical = "Critical",
  High = "High",
  Normal = "Normal",
  Low = "Low",
}

export interface IResponseSaveFeedback {
  id: number;
  guid: string;
}
