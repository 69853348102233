import { createAction } from "@reduxjs/toolkit";
import { PlantSource, SWTypes } from "interfaces/sw/SWInterfaces";
import {
  IMasterDataSection,
  IOperationTreeModel,
  MasterDataSectionNames,
} from "./masterDataTypes";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

export const loadLanguages = createAction<{
  onlyActive: boolean
}>("masterData/loadLanguages");
export const loadCountries = createAction<{
  onlyActive: boolean
}>("masterData/loadCountries");
export const loadGeoUnits = createAction<{
  onlyActive: boolean
}>("masterData/loadGeoUnits");
export const loadOrganizations = createAction<{
  onlyActive: boolean
}>("masterData/loadOrganizations");
export const loadSubBusinessLines = createAction<{
  onlyActive: boolean
}>("masterData/loadSubBusinessLines");
export const loadSafetyCategories = createAction<{
  onlyActive: boolean
}>("masterData/loadSafetyCategories");
export const loadOwningPlants = createAction<{
  onlyActive: boolean
}>("masterData/loadOwningPlants");
export const searchOwningPlants = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchOwningPlants");
export const getConfiguredOwningPlants = createAction<{
  onlyConfiguredPlants: boolean,
  onlyActive: boolean
}>("masterData/getConfiguredOwningPlants");
export const searchPerformingPlants = createAction<{
  searchTerm: string,
  onlyActive: boolean,
  plantSource?: PlantSource
}>("masterData/searchPerformingPlants");

export const loadPerformingPlants = createAction<{
  onlyActive: boolean
}>("masterData/loadPerformingPlants");
export const searchActivityWorkflows = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchActivityWorkflows");
export const loadHazardCategories = createAction<{
  onlyActive: boolean,
}>(
  "masterData/loadHazardCategories"
);
export const searchCompetencyElements = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchCompetencyElements");
export const searchEquipment = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchEquipment");
export const searchEpicEquipment = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchEpicEquipment");
export const searchBusinessRoles = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchBusinessRoles");
export const searchProductCenters = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchProductCenters");
export const searchCustomers = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchCustomers");
export const searchEquivalentSWs = createAction<{
  searchTerm: string,
  SWType: SWTypes,
}>("masterData/searchEquivalentSWs");
export const searchOperatingEnvironments = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchOperatingEnvironments");
export const searchServiceTypes = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchServiceTypes");
export const clearSearchedMasterDataItems = createAction("masterData/clearSearchedMasterDataItems");
export const searchTasks = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchTasks");

export const searchMaterials = createAction<{
  searchTerm: string,
  plantCode: string,
  onlyActive: boolean,
  plantSource?: PlantSource
}>("masterData/searchMaterials");

export const loadGemsRevisionNumbers = createAction<{
  plantCode?: string;
  plantSource?: PlantSource;
  materialCode?: string;
  onlyActive: boolean;
}>("masterData/loadGemsRevisionNumbers");

export const searchWorkCenters = createAction<{
  searchTerm: string,
  plantCode: string,
  onlyActive: boolean,
  plantSource?: PlantSource
}>("masterData/searchWorkCenters");
export const searchEvents = createAction<{
  searchTerm: string,
  onlyActive: boolean
}>("masterData/searchEvents");
export const setSection = createAction<{
  name: MasterDataSectionNames,
  section: IMasterDataSection
}>("masterData/setSection");
export const loadOperationTree = createAction<{
  plantCode: string;
  materialCode: string;
  group: string;
  groupCounter: number;
  plantSource?: PlantSource;
}>("masterData/loadOperationTree");
export const setOperationTree = createAction<IOperationTreeModel>(
  "masterData/setOperationTree"
);
export const clearOperationTree = createAction("masterData/clearOperationTree");
export const loadGroups = createAction<{
  plantCode?: string;
  materialCode?: string;
  plantSource?: PlantSource
}>("masterData/loadGroups");
export const loadGroupCounter = createAction<{
  plantCode?: string;
  materialCode?: string;
  group?: string;
  plantSource?: PlantSource
}>("masterData/loadGroupCounter");
export const loadCustomizedEvents = createAction<{
  onlyCusomizedEvents: boolean
}>("masterData/loadCustomizedEvents");
export const loadQTracOOS = createAction<{
  onlyActive: boolean;
}>("masterData/loadQTracOOS");
export const loadUoms = createAction<{
  onlyActive: boolean
}>("masterData/loadUoms");
export const loadMeters = createAction<{ onlyActive: boolean, equipmentCodes: IMasterDataItem[], location: string }>("masterData/loadMeters");
