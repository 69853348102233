import React, { useEffect, useState } from "react";
import { IRichTextInputComponent } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { updateRichTextInput } from "store/manageSW/manageSWActions";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "./RichTextEditor.scss";

interface IRichTextEditorProps {
  textComponent: IRichTextInputComponent,
  allowEdit: boolean,
  stepGuid: string,
}

const RichTextEditor: React.FC<IRichTextEditorProps> = ({
  textComponent,
  allowEdit,
  stepGuid,
}) => {
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  const [oldEditorState, setoldEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  let existingHtml = textComponent.html !== "" ? textComponent.html : undefined;

  useEffect(() => {
    if (existingHtml) {
      const contentBlock = htmlToDraft(existingHtml);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        setoldEditorState(editorState);
      }
    }
  }, [existingHtml]);

  useEffect(() => {
    if(editorState){
      linkCallback();
    }
  },[editorState])

  const handleEditorChange = (state: EditorState) => {
    setoldEditorState(editorState);
    setEditorState(state);
  }

  const numberOfLinks = (html : string) : number =>{
    var count = (html.match(/<a.+?href="(?<url>.+?)".*?>(?<text>.+?)<\/a>/g) || []).length;
    return count;
  }

  //onblur event is not triggered when link is added to richtext editor, linkCallback will call the handleEditorBlur when a new link is added.
  const linkCallback = () => {
    var editorStateLinks = numberOfLinks(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    var oldEditortateLinks = numberOfLinks(draftToHtml(convertToRaw(oldEditorState.getCurrentContent())));
    if(editorStateLinks > oldEditortateLinks){
      handleEditorBlur();
    }
  }

  const handleEditorBlur = () => {
    let contentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent())).replaceAll("&nbsp;","");
    dispatch(updateRichTextInput({
      stepGuid,
      component: {
        ...textComponent,
        html: contentAsHTML,
      },
    }));
  }
  
  return (
    <div className="component-editor">
      <Editor
        handlePastedText={() => false}
        stripPastedStyles={true}
        toolbarClassName={allowEdit ? "rte-toolbar-class":"rte-toolbar-class rte-toolbar-hide"}
        editorClassName="rte-editor-class"
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        onBlur={handleEditorBlur}
        readOnly={!allowEdit}
        toolbar={{
          options: ['list', 'inline', 'link'],
          list: {
            inDropdown: true,
          },
          inline:
          {
            inDropdown: true,
            options: ['bold', 'italic', 'underline']
          },
          link:
          {
            inDropdown: false,
          },
        }}
      />
    </div>
  );
}

export default RichTextEditor;