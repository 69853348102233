import { all, takeLatest, put, call, select, takeEvery } from "redux-saga/effects";
import { Action } from "@reduxjs/toolkit";
import {
  setSort,
  submitDraftForApproval,
  setSubmitDraftOperation,
  loadMyApprovals,
  setLoadMyApprovalsOperation,
  setMyApprovals,
  loadApproval,
  setLoadApprovalOperation,
  setCurrentApproval,
  submitResponse,
  setSubmitResponseOperation,
  loadApprovalHistory,
  setApprovalHistory,
  setSearchText,
  applyFilter,
  loadBatchApproval,
  setCurrentBatchApproval,
  submitBatchResponse,
  retractSwFromApprovalFlow,
  setRetractSwOperation,
  loadBatchUpdateSWMetaData,
  setLoadBatchUpdateSWMetaDataOperation,
  setCurrentBatchUpdateSWMetaData,
  saveSWStepCommentApproval,
  updateStepCommentsInfoApproval,
  resolveStepCommentApproval,
  launchUserSentimentSurvey,
  loadMoreSteps,
  loadRCUpdateApproval,
  setCurrentRCUpdateApproval,
  submitRCUpdateApproval,
  setSubmitRCUpdateApprovalOperation,
  showErrorPopupMessage
} from "./approvalsActions";
import { showErrorToast, showSuccessToast } from "store/toast/toastActions";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import ApprovalApi from "apis/approval/ApprovalApi";
import {
  setActiveApprovalRequestId,
  setActiveApprovalLevel,
  setStepComments,
  setActiveApprovalUserName,
  setSubmitForReviewFlag,
  setActiveApprovalType,
  setConfigParaMeterFlag
} from "store/manageSW/manageSWActions";
import { RootState } from "store/rootStore";
import { IStep, IStepsCommentInfo, ISW, SWTypes } from "interfaces/sw/SWInterfaces";
import { IBatchUpdateApproval, IBatchUpdateSWMetaData } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import {
  IApprovalRequest,
  ApprovalResponseTypes,
  ApprovalLevels,
  IHistoricalApprovalResponse,
  IMyApprovals,
  IApprovalHistoryData,
  HistoricalApprovalTypes
} from "interfaces/approvals/approvalInterfaces";
import SWApi from "apis/sw/SWApi";
import { IManageUserUser } from "interfaces/user/UserInterfaces";
import { acquireUSSAccessToken } from "msalConfig";
import config from 'config';
import { IRCUpdateApproval } from "./approvalsTypes";
import { IConfigParameters } from "interfaces/masterData/masterDataInterfaces";
import MasterDataApi from "apis/masterData/MasterDataApi";
import { formatRCAffectedSw } from "../../apis/approval/approvalFormatters";

export default function* watchApprovalSagas() {
  yield all([
    watchSubmitDraftForApproval(),
    watchRetractSwFromApprovalFlow(),
    watchLoadMyApprovals(),
    watchLoadApproval(),
    watchLoadBatchApproval(),
    watchLoadRCUpdateApproval(),
    watchLoadBatchUpdateSWMetaData(),
    watchSubmitResponse(),
    watchLoadApprovalHistory(),
    watchSubmitBatchResponse(),
    watchSaveSWStepCommentApproval(),
    watchresolveStepCommentApproval(),
    watchUserSentimentSurvey(),
    watchLoadMoreSteps(),
    watchSubmitRCUpdateApproval(),
  ]);
}

function* watchSaveSWStepCommentApproval() {
  yield takeLatest(saveSWStepCommentApproval, saveSWStepCommentApprovalAsync);
}

function* watchSubmitDraftForApproval() {
  yield takeLatest(submitDraftForApproval, submitDraftForApprovalAsync);
}

function* watchresolveStepCommentApproval() {
  yield takeLatest(resolveStepCommentApproval, resolveStepCommentApprovalAsync);
}

function* watchLoadMoreSteps() {
  yield takeLatest(loadMoreSteps, loadMoreStepsAsync);
}

function* watchUserSentimentSurvey() {
  yield takeLatest(launchUserSentimentSurvey, launchUserSentimentSurveyAsync);
}

function* resolveStepCommentApprovalAsync(action: Action) {
  if (!resolveStepCommentApproval.match(action)) {
    return;
  }
  let isTask: boolean | undefined = false;
  const sw: ISW = yield select((store: RootState) => store.approvals.currentApproval?.sw);

  try {

    yield put(
      setSubmitDraftOperation({
        isWorking: true,
      })
    );
    const stepCommentsInfo: IStepsCommentInfo = yield call(SWApi.resolveStepComment, action.payload.commentID, action.payload.stepID, sw.id);
    isTask = sw.steps.find(x => x.id === action.payload.stepID)?.isTask;

    yield put(updateStepCommentsInfoApproval({
      stepID: stepCommentsInfo.stepId,
      hasUnresolvedComments: stepCommentsInfo.hasUnresolvedComments,
      totalStepComments: stepCommentsInfo.totalStepComments,
      unResolvedStepComments: stepCommentsInfo.unResolvedStepComments,
    }));
    yield put(
      setSubmitDraftOperation({
        isWorking: false,
      })
    );

    yield put(setStepComments({
      loadOperation: undefined,
      comments: [],
    }));
    yield put(showSuccessToast((isTask ? "Task" :
      sw.steps.filter(a => a.rcContainer).some(({ children }) => children.some(x => x.id === action.payload.stepID &&
        x.isTask === true)) ? "Task" :
        "Step") + " Comment Resolved"));

  } catch (err: any) {
    yield put(
      showErrorToast(
        getResponseErrorMessage("Failed to resolve " + (isTask ? "task" :
          sw.steps.filter(a => a.rcContainer).some(({ children }) => children.some(x => x.id === action.payload.stepID &&
            x.isTask === true)) ? "Task" :
            "step") + " comment: " + err)
      )
    );
    yield put(setStepComments({
      loadOperation: undefined,
      comments: [],
    }));
    return;
  }
}

function* saveSWStepCommentApprovalAsync(action: Action) {
  if (!saveSWStepCommentApproval.match(action)) {
    return;
  }
  let isTask: boolean | undefined = false;
  const sw: ISW = yield select((store: RootState) => store.approvals.currentApproval?.sw);

  try {

    yield put(
      setSubmitDraftOperation({
        isWorking: true,
      })
    );
    isTask = sw.steps.find(x => x.id === action.payload.stepID)?.isTask;
    const stepCommentsInfo: IStepsCommentInfo = yield call(SWApi.saveSWStepComment, action.payload.stepID, action.payload.comment, sw.id);

    // update the step info
    yield put(updateStepCommentsInfoApproval({
      stepID: stepCommentsInfo.stepId,
      hasUnresolvedComments: stepCommentsInfo.hasUnresolvedComments,
      totalStepComments: stepCommentsInfo.totalStepComments,
      unResolvedStepComments: stepCommentsInfo.unResolvedStepComments,
    }));

    yield put(
      setSubmitDraftOperation({
        isWorking: false,
      })
    );
    yield put(showSuccessToast("Created " + (isTask ? "Task" :
      sw.steps.filter(a => a.rcContainer).some(({ children }) => children.some(x => x.id === action.payload.stepID &&
        x.isTask === true)) ? "Task" :
        "Step") + " Comment"));
  } catch (err: any) {
    yield put(
      showErrorToast(
        getResponseErrorMessage("Failed to save " + (isTask ? "task" :
          sw.steps.filter(a => a.rcContainer).some(({ children }) => children.some(x => x.id === action.payload.stepID &&
            x.isTask === true)) ? "Task" :
            "step") + " comment: " + err)
      )
    );
    yield put(
      setSubmitDraftOperation({
        isWorking: false,
      })
    );
    return;
  }

}

function* submitDraftForApprovalAsync(action: Action) {
  if (!submitDraftForApproval.match(action)) {
    return;
  }

  yield put(setSubmitDraftOperation({
    isWorking: true,
  }));

  try {
    let [approvalRequestId, approvalLevel, activeApprovalUserName]: [number, ApprovalLevels, string] = yield call(
      ApprovalApi.submitDraftForApproval,
      action.payload.swGuid,
      action.payload.swType);

    // Update the currently loaded managed SW if this approval
    // was created for it.
    let managedSW: ISW = yield select((store: RootState) => store.manageSW.SW);
    let currentUser: IManageUserUser = yield select((store: RootState) => store.auth.currentUser);
    if (managedSW.guid === action.payload.swGuid) {
      yield put(setActiveApprovalRequestId(approvalRequestId));
      yield put(setActiveApprovalLevel(approvalLevel));
      yield put(setActiveApprovalType(HistoricalApprovalTypes.Approval))
      yield put(setActiveApprovalUserName(activeApprovalUserName));
    }

    yield put(showSuccessToast("An approval request has been submitted."));
    yield put(setSubmitDraftOperation(undefined));
    yield put(launchUserSentimentSurvey({ currentUser: currentUser.email }));
    yield put(setSubmitForReviewFlag({ submitForReviewFlag: false }));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(setSubmitDraftOperation(undefined));
    yield put(setSubmitForReviewFlag({ submitForReviewFlag: false }));
  }
}

function* watchRetractSwFromApprovalFlow() {
  yield takeLatest(retractSwFromApprovalFlow, retractSwFromApprovalFlowAsync);
}

function* retractSwFromApprovalFlowAsync(action: Action) {
  if (!retractSwFromApprovalFlow.match(action)) {
    return;
  }

  yield put(setRetractSwOperation({
    isWorking: true,
  }));
  try {
    var approvalRequestId: number;
    if (!action.payload.approvalRequestId) {
      yield put(showErrorToast("No active ApprovalRequestId found with this standardwork"));
      yield put(setRetractSwOperation(undefined));
      return;
    }
    else {
      approvalRequestId = action.payload.approvalRequestId;
    }
    yield call(
      ApprovalApi.retractSwFromApproval,
      action.payload.swId,
      approvalRequestId
    );

    // Update the currently loaded managed SW if this approval
    // was created for it.
    let managedSW: ISW = yield select((store: RootState) => store.manageSW.SW);
    if (managedSW.id === action.payload.swId) {
      yield put(setActiveApprovalRequestId(undefined));
      yield put(setActiveApprovalLevel(undefined));
    }

    yield put(showSuccessToast("The standard work has been retracted."));
    yield put(setRetractSwOperation(undefined));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(setRetractSwOperation(undefined));
  }
}

function* watchLoadMyApprovals() {
  yield takeLatest([loadMyApprovals, setSearchText, setSort, applyFilter], loadMyApprovalsAsync);
}

function* loadMyApprovalsAsync() {
  yield put(setLoadMyApprovalsOperation({
    isWorking: true,
  }));

  const {
    approvalFilterFields,
    swFilterFields,
    sortBy,
    sortDir,
  } = yield select(
    (store: RootState) => store.approvals.filterData
  );

  try {
    let myApprovals: IMyApprovals = yield call(ApprovalApi.getMyApprovals,
      swFilterFields,
      approvalFilterFields,
      sortBy,
      sortDir);
      let configParameter: IConfigParameters[] = yield call(MasterDataApi.getConfigParameters);
      var enableMeters = configParameter.find(x => x.key.toLowerCase() === "enablemeters");
      var enableFormulaComponent = configParameter.find(x => x.key.toLowerCase() === "enableformulacomponent");
      var enableConfiguredMFGPlantsInTLM = configParameter.find(x => x.key.toLowerCase() === "enableConfiguredMFGPlantsInTLM");
      var disableMFGInputComponent = configParameter.find(x => x.key.toLowerCase() === "disablemfginputcomponent");
      var disableMFGContentComponent = configParameter.find(x => x.key.toLowerCase() === "disablemfgcontentcomponent");
      yield put(setConfigParaMeterFlag({keyName: "enableMeters", value: enableMeters ? enableMeters.value : "false" }))
      yield put(setConfigParaMeterFlag({keyName: "enableFormulaComponent", value: enableFormulaComponent ? enableFormulaComponent.value : "false" }))
      yield put(setConfigParaMeterFlag({keyName: "enableConfiguredMFGPlantsInTLM", value: enableConfiguredMFGPlantsInTLM ? enableConfiguredMFGPlantsInTLM.value : "false" }))
      yield put(setConfigParaMeterFlag({ keyName: "disableMFGInputComponent", value: disableMFGInputComponent ? disableMFGInputComponent.value : "" }))
      yield put(setConfigParaMeterFlag({ keyName: "disableMFGContentComponent", value: disableMFGContentComponent ? disableMFGContentComponent.value : "" }))

    yield put(setMyApprovals(myApprovals));
    yield put(setLoadMyApprovalsOperation(undefined));
  } catch (err: any) {
    yield put(setLoadMyApprovalsOperation({
      isWorking: false,
      wasSuccessful: false,
      errorMessage: getResponseErrorMessage(err),
    }));
  }
}

function* watchLoadApproval() {
  yield takeLatest(loadApproval, loadApprovalAsync);
}

function* loadApprovalAsync(action: Action) {
  if (!loadApproval.match(action)) {
    return;
  }

  yield put(setLoadApprovalOperation({
    isWorking: true,
  }));

  try {
    let data: [IApprovalRequest, ISW, IHistoricalApprovalResponse[], number, number] = yield call(
      ApprovalApi.getApproval,
      action.payload.approvalId,
      action.payload.stepIndex);

    yield put(setCurrentApproval({
      approval: data[0],
      sw: data[1],
      approvalHistories: data[2],
      totalSteps: data[3],
      skipStepIndex: action.payload.stepIndex,
      currenTotalSteps: data[4],
    }));
    yield put(setLoadApprovalOperation(undefined));
  } catch (err: any) {
    yield put(setLoadApprovalOperation({
      isWorking: false,
      wasSuccessful: false,
      errorMessage: getResponseErrorMessage(err),
    }));
  }
}

function* loadMoreStepsAsync(action: Action) {
  if (!loadMoreSteps.match(action)) {
    return;
  }

  yield put(loadApproval({
    approvalId: action.payload.approvalId,
    stepIndex: action.payload.stepIndex
  }));
}

function* watchLoadBatchApproval() {
  yield takeLatest(loadBatchApproval, loadBatchApprovalAsync);
}

function* watchLoadRCUpdateApproval() {
  yield takeLatest(loadRCUpdateApproval, loadRCUpdateApprovalAsync);
}

function* watchSubmitRCUpdateApproval() {
  yield takeLatest(submitRCUpdateApproval, submitRCUpdateApprovalAsync);
}

function* loadBatchApprovalAsync(action: Action) {
  if (!loadBatchApproval.match(action)) {
    return;
  }

  yield put(
    setLoadApprovalOperation({
      isWorking: true,
    })
  );

  try {
    let data: IBatchUpdateApproval = yield call(
      ApprovalApi.getBatchApproval,
      action.payload.approvalId
    );

    yield put(
      setCurrentBatchApproval({
        ...data,
        id: action.payload.approvalId,
      })
    );
    yield put(setLoadApprovalOperation(undefined));
  } catch (err: any) {
    yield put(
      setLoadApprovalOperation({
        isWorking: false,
        wasSuccessful: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* loadRCUpdateApprovalAsync(action: Action) {
  if (!loadRCUpdateApproval.match(action)) {
    return;
  }

  yield put(
    setLoadApprovalOperation({
      isWorking: true,
    })
  );

  try {
    let data: IRCUpdateApproval = yield call(
      ApprovalApi.getRCUpdateApproval,
      action.payload.approvalId
    );

    yield put(
      setCurrentRCUpdateApproval({
        ...data,
      })
    );
    yield put(setLoadApprovalOperation(undefined));
  } catch (err: any) {
    yield put(
      setLoadApprovalOperation({
        isWorking: false,
        wasSuccessful: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* watchLoadBatchUpdateSWMetaData() {
  yield takeLatest(loadBatchUpdateSWMetaData, loadBatchUpdateViewMetaDataAsync);
}

function* loadBatchUpdateViewMetaDataAsync(action: Action) {
  if (!loadBatchUpdateSWMetaData.match(action)) {
    return;
  }

  yield put(
    setLoadBatchUpdateSWMetaDataOperation({
      isWorking: true,
    })
  );

  try {
    let data: IBatchUpdateSWMetaData = yield call(
      ApprovalApi.getBatchUpdateSWMetaData,
      action.payload.approvalId,
      action.payload.swId
    );

    yield put(
      setCurrentBatchUpdateSWMetaData({
        ...data,
      })
    );
    yield put(
      setLoadBatchUpdateSWMetaDataOperation({
        wasSuccessful: true,
        isWorking: false,
      })
    );
  } catch (err: any) {
    var errorMsg = getResponseErrorMessage(err);
    yield put(
      setLoadBatchUpdateSWMetaDataOperation({
        isWorking: false,
        wasSuccessful: false,
        errorMessage: errorMsg,
      })
    );
    yield put(showErrorToast(errorMsg));
  }
}

function* submitRCUpdateApprovalAsync(action: Action) {
  if (!submitRCUpdateApproval.match(action)) {
    return;
  }

  yield put(
    setSubmitRCUpdateApprovalOperation({
      isWorking: true,
      errorMessage: undefined,
      wasSuccessful: undefined
    })
  );

  try {
    yield call(ApprovalApi.submitRCUpdateApproval, action.payload);
    yield put(loadRCUpdateApproval({ approvalId: action.payload.approvalId }));

    yield put(
      setSubmitRCUpdateApprovalOperation({
        isWorking: false,
        errorMessage: undefined,
        wasSuccessful: true
      })
    );

    yield put(showSuccessToast("Your approval responses were submitted correctly."));
  } catch (err: any) {
    const errorMsg = getResponseErrorMessage(err);
    yield put(showErrorToast(errorMsg));

    yield put(
      setSubmitRCUpdateApprovalOperation({
        isWorking: false,
        errorMessage: errorMsg,
        wasSuccessful: false
      })
    );
  }
}

function* watchSubmitResponse() {
  yield takeLatest(submitResponse, submitResponseAsync);
}

function* submitResponseAsync(action: Action) {
  if (!submitResponse.match(action)) {
    return;
  }

  yield put(setSubmitResponseOperation({
    isWorking: true,
  }));

  const actionType = action.payload.responseType === ApprovalResponseTypes.Approved
    ? "approval"
    : action.payload.responseType === ApprovalResponseTypes.Revert
      ? "revert"
      : "rejection";

  try {
    yield call(
      ApprovalApi.submitResponse,
      action.payload);

    yield put(setSubmitResponseOperation({
      wasSuccessful: true,
      isWorking: false,
    }));

    yield put(showSuccessToast(`Your ${actionType} was submitted successfully.`));
  } catch (err: any) {
      yield put(setSubmitResponseOperation(undefined));
      if (((action.payload.swType === SWTypes.TLMRC) || (action.payload.swType === SWTypes.MFGRC)) && err.affectedSws) {
          const errorMsg = `Failed to submit ${actionType}: ${getResponseErrorMessage(err)}`;
          const sws = formatRCAffectedSw(err.affectedSws);
          yield put(showErrorPopupMessage({ errMsg: errorMsg, affectedSws: sws }));
      }
      else {
          yield put(showErrorToast(`Failed to submit ${actionType}: ${getResponseErrorMessage(err)}`));
      }
   }
}

function* watchLoadApprovalHistory() {
  yield takeLatest(loadApprovalHistory, loadApprovalHistoryAsync);
}

function* loadApprovalHistoryAsync(action: Action) {
  if (!loadApprovalHistory.match(action)) {
    return;
  }

  yield put(setApprovalHistory({
    loadOperation: {
      isWorking: true,
    },
    approvals: [],
    batchApprovals: [],
    rcApprovals: [],
    swId: action.payload.swId,
  }));

  try {
    const approvals: IApprovalHistoryData = yield call(ApprovalApi.getApprovalHistory, action.payload.swId);

    yield put(setApprovalHistory({
      loadOperation: {
        isWorking: false,
        wasSuccessful: true,
      },
      approvals: approvals.approvals,
      batchApprovals: approvals.batchApprovals,
      swId: action.payload.swId,
      rcApprovals: approvals.rcUpdateApprovals,
    }));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(setApprovalHistory({
      loadOperation: undefined,
      approvals: [],
      batchApprovals: [],
      rcApprovals: [],
      swId: action.payload.swId,
    }));
  }
}

function* watchSubmitBatchResponse() {
  yield takeEvery(submitBatchResponse, submitBatchResponseAsync);
}

function* submitBatchResponseAsync(action: Action) {
  if (!submitBatchResponse.match(action)) {
    return;
  }

  yield put(setSubmitResponseOperation({
    isWorking: true,
  }));

  try {
    yield call(
      ApprovalApi.submitBatchResponse,
      action.payload);

    yield put(setSubmitResponseOperation({
      wasSuccessful: true,
      isWorking: false,
    }));

    yield put(showSuccessToast(`Your ${action.payload.responseType === ApprovalResponseTypes.Approved
      ? "approval"
      : "rejection"} was submitted successfully.`));
  } catch (err: any) {
    yield put(setSubmitResponseOperation(undefined));

    yield put(showErrorToast(`Failed to submit rejection: ${getResponseErrorMessage(err)}`));
  }
}

async function launchUserSentimentSurveyAsync(action: Action) {
  if (!launchUserSentimentSurvey.match(action)) {
    return;
  }

  try {
    var error = "";
    var token = await acquireUSSAccessToken();

    if (token.length > 0) {
      var url = config.endpoints.USS.validate
        .replace("{userID}",
          action.payload.currentUser
            .substr(0, action.payload.currentUser
              .lastIndexOf("@")))
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.setRequestHeader("x-apikey", config.authConfigUSS.apiKey);
      request.setRequestHeader("authorization", "Bearer " + token);
      request.send();

      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          var resData = JSON.parse(this.response);
          if (resData.isEligible) {
            window.open(resData.urlToRedirect,
              '_blank',
              'width=900,height=850,toolbar=0,menubar=0,location=0,top=10,left=25');
          }
        }
        else {
          error = 'Failed to validate user sentiment survey';
        }
      }
    }
    if (error.length > 0) {
      put(showErrorToast(error));
    }
  }
  catch (err: any) {
    put(showErrorToast(getResponseErrorMessage(err)));
    return;
  }
}