import React, { useEffect, useState } from "react";
import { ImageAlignments, ImageScale, ITIMEImageComponent } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import "./ComponentEditor.scss";
import "./TIMEImageEditor.scoped.scss";
import { getStepComponentTIMEImageUrl, getTimeMediaWhereUsedFiles, setTimeImageData, updateTIMEImage, uploadDownloadTimeImage } from "store/manageSW/manageSWActions";
import useSelector from "store/useSelector";
import { SWAttachmentTypes, ImageDataDestinations, ITimeImageData } from "store/manageSW/manageSWTypes";
import BlockSpinner from "components/common/BlockSpinner";
import AddTimeImageModal from "../AddTimeImageModal";
import searchIcon from "media/icons/dls/search.svg";
import { getMediaNumberFromLabel } from "utilities/fileUtilities";

interface ITIMEImageEditorProps {
    timeImage: ITIMEImageComponent,
    allowEdit: boolean,
    stepGuid: string,
    swGuid: string,
    swVersion: number,
    imageDataDestination: ImageDataDestinations,
}

const TIMEImageEditor: React.FC<ITIMEImageEditorProps> = ({
    timeImage,
    allowEdit,
    stepGuid,
    swGuid,
    swVersion,
    imageDataDestination,
}) => {
    const { fileUploadOperation, timeImageData } = useSelector(store => store.manageSW);
    const dispatch = useDispatch();
    const [openTimeModal, setOpenTimeModal] = useState(false);
    const [scale, setScale] = useState<ImageScale>();
    const newImage = { ...timeImage };
    newImage.scale = scale;
    const mediaId = getMediaNumberFromLabel(newImage.label || "");

    useEffect(() => {
        if (!timeImage.filename
            || timeImage.blobData?.isLoading
            || timeImage.filename === timeImage.blobData?.filename
            || fileUploadOperation?.isWorking) {
            // Blob url already loaded. Ignore.
            return;
        }

        dispatch(getStepComponentTIMEImageUrl({
            stepGuid,
            timeImageComponent: timeImage,
            swGuid,
            swVersion,
            filename: timeImage.filename,
            destination: imageDataDestination,
        }));
    }, [timeImage, stepGuid, swGuid, swVersion, imageDataDestination, fileUploadOperation, dispatch]);

    const openCloseModal = () => {
        setOpenTimeModal(prevState => !prevState);
        dispatch(setTimeImageData({
            imageNumber: 0,
            link: "",
            label: "",
        }));

    }

    const alignmentChange = (alignment: ImageAlignments) => {
        dispatch(updateTIMEImage({
            component: {
                ...timeImage,
                alignment,
            },
            stepGuid,
        }));
    }

    const uploadDownloadImage = (data: ITimeImageData) => {
        dispatch(uploadDownloadTimeImage({
            swGuid,
            swVersion: 0,
            label: data.label,
            link: data.link,
            imageNumber: data.imageNumber,
            type: SWAttachmentTypes.TIMEImageComponent,
            timeImageComponent: timeImage,
            stepGuid
        }));
    }

    const onCheckedChange = () => {
        if (timeImage.scale !== undefined) {
            dispatch(updateTIMEImage({
                component: {
                    ...timeImage,
                    scale: undefined,
                },
                stepGuid,
            }));
        } else {
            dispatch(updateTIMEImage({
                component: {
                    ...timeImage,
                    scale: ImageScale.Small,
                },
                stepGuid,
            }));
        }
    }

    const onScaleChange = (newScale: ImageScale) => {
        setScale(newScale);
        dispatch(updateTIMEImage({
            component: {
                ...timeImage,
                scale: newScale,
            },
            stepGuid,
        }));
    }

    const dispatchGetTimeMediaWhereUsedFiles = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        dispatch(getTimeMediaWhereUsedFiles({ imageNumber: mediaId }));
    }

    let img: JSX.Element | undefined;

    if (timeImage.blobData?.isLoading) {
        img = (
            <BlockSpinner />
        );
    } else if (timeImage.blobData?.errorMessage) {
        img = (
            <label className="error">
                {timeImage.blobData?.errorMessage}
            </label>
        );
    } else if (timeImage.blobData?.blobUrl) {
        img = (
            <img
                src={timeImage.blobData?.blobUrl}
                alt="Selected"
                className="chosen-image"
            />
        );
    }

    return (
        <>
            <div className="image-wrapper">
                {img}
            </div>
            {
                openTimeModal &&
                <AddTimeImageModal
                    onModalClick={openCloseModal}
                    timeImageData={timeImageData}
                    uploadDownloadImage={uploadDownloadImage}
                />
            }

            {allowEdit &&
                <>
                    <div
                        className="controls"
                    >
                        <button
                            className="primary-button choose-image"
                            onClick={openCloseModal}
                        >
                            {timeImage.filename ? "Change" : "Choose"} TIME Image
                        </button>
                        <div
                            className="align-holder"
                        >
                            <label>Alignment</label>
                            <select
                                value={timeImage.alignment}
                                onChange={(e) => alignmentChange(e.target.value as ImageAlignments)}
                            >
                                <option
                                    value={ImageAlignments.Right}
                                >
                                    {ImageAlignments.Right}
                                </option>
                                <option
                                    value={ImageAlignments.Bottom}
                                >
                                    {ImageAlignments.Bottom}
                                </option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <div>
                        <label>Scale</label>
                        <input
                            type="checkbox"
                            checked={timeImage.scale !== undefined
                                ? true
                                : false}
                            onChange={onCheckedChange}
                        />
                        {timeImage.scale !== undefined &&
                            <>
                                <br />
                                <label>Width</label>&nbsp;
                                <select
                                    onChange={(e) => onScaleChange(e.target.value as ImageScale)}
                                    value={timeImage.scale}
                                >
                                    {Object.keys(ImageScale).map(t =>
                                        <option
                                            key={t}
                                            value={t}
                                        >
                                            {t}
                                        </option>
                                    )}
                                </select>
                            </>}
                        <button
                            className="where-used-button"
                            onClick={(e) => dispatchGetTimeMediaWhereUsedFiles(e)}
                        >
                            <img
                                src={searchIcon}
                                alt="Where Used"
                                title="Where Used"
                                className={`icon-small`}
                            />
                        </button>
                    </div>
                </>
            }
        </>
    );
}

export default TIMEImageEditor;