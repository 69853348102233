import { ITableComponent, ITableComponentCell, SWTypes } from "interfaces/sw/SWInterfaces";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { downloadTableTimeImage, getTableCellImageUrls, updateTableCell, uploadTableImage } from "store/manageSW/manageSWActions";
import "./TableEditorCell.scoped.scss";

import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  ContentState,
  RichUtils,
  DraftEditorCommand,
  DraftHandleValue,
} from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import CustomMergeOption from "../../CustomMergeOption";
import { addImageNumberToLabel, getFileSizeError, isFilenameImage } from "utilities/fileUtilities";
import { showErrorToast } from "store/toast/toastActions";
import { ImageDataDestinations, ITableImageFile, ITableTimeImageAttachment, ITimeImageData } from "store/manageSW/manageSWTypes";
import TableTimeImageComponent from "../../TableTimeImageComponent";
import useSelector from "store/useSelector";

interface ITableEditorCellProps {
  rowKey: number,
  colKey: number,
  stepGuid: string,
  tableComponent: ITableComponent,
  disabled: boolean,
  swGuid: string,
  swVersion: number,
  imageDataDestination: ImageDataDestinations,
  swType: SWTypes,
}

const TableEditorCell: React.FC<ITableEditorCellProps> = ({
  rowKey,
  colKey,
  stepGuid,
  tableComponent,
  disabled,
  swGuid,
  swVersion,
  imageDataDestination,
  swType,
}) => {
  const dispatch = useDispatch();
  const { timeImageData } = useSelector(store => store.manageSW);
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const [backspaceMessageShown, setBackspaceMessageShown] = useState(false);
  const [deleteMessageShown, setDeleteMessageShown] = useState(false);

  let existingCell = tableComponent.cells.find(x => x.rowIndex === rowKey && x.colIndex === colKey);

  const editorRef = React.useRef<Editor>(null);

  useEffect(() => {
    if (existingCell?.value) {
      //if cell value has image tag hit azure to get signed url
      let cellImage = getImage(existingCell);

      if (cellImage) {
        dispatch(getTableCellImageUrls({
          stepGuid,
          componentGuid: tableComponent.guid,
          rowIndex: rowKey,
          colIndex: colKey,
          swGuid,
          swVersion,
          filename: cellImage,
          destination: imageDataDestination,
        }));
      }

      const contentBlock = htmlToDraft(existingCell.value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [existingCell, colKey, dispatch, rowKey, stepGuid, swGuid, swVersion, tableComponent.guid, imageDataDestination]);

  const handleEditorChange = (state: EditorState) => {
    const blocks = state.getCurrentContent().getBlocksAsArray();

    let contentAsHTML = draftToHtml(convertToRaw(state.getCurrentContent()));
    const htmlContainsImage = contentAsHTML.indexOf('<img') > -1;
    const blocksContainsImage = blocks.some(x => x.getType() === 'atomic');

    if (htmlContainsImage && !blocksContainsImage) {
      editorRef.current?.forceUpdate();
      return;
    }

    if (contentAsHTML && contentAsHTML.indexOf('<img') > -1) {
      if (contentAsHTML.split("<img").length > 2) {
        const alreadyImageErr = "There is already an image.";
        dispatch(showErrorToast(alreadyImageErr));
        return;
      }
    }

    // if there is a new link, updates table cell data. This is needed because Draft Editor's onblur event does not fire when a link is added
    if (contentAsHTML && contentAsHTML.indexOf('</a>') > -1) {
      let count = contentAsHTML.split("</a>").length;
      let previousContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      let oldCount = previousContent.split("</a>").length;
      if (count > oldCount)
        updateTableCellData(contentAsHTML);
    }

    setEditorState(state);
  }

  const updateTableCellData = (contentAsHTML: string) => {
    dispatch(dispatch(updateTableCell({
      componentGuid: tableComponent.guid,
      stepGuid,
      cell: {
        rowIndex: rowKey,
        colIndex: colKey,
        value: contentAsHTML,
        rowSpan: existingCell ? existingCell.rowSpan : 1,
        colSpan: existingCell ? existingCell.colSpan : 1,
        imageFileName: existingCell ? existingCell.imageFileName : undefined,
      }
    })));
  }

  const getImage = (cell: ITableComponentCell): string | undefined => {
    if (cell.imageFileName && cell.imageFileName.includes('blob.core.windows.net/authoring')) {
      return undefined;
    }

    const imgRex = /<img.*?alt="(.*?)"[^>]+>/g;
    let img;
    while ((img = imgRex.exec(cell.value))) {
      let val = img[1];
      // dont add it if its local image still
      if (val !== "undefined") {
        return img[1];
      }
    }
    return undefined;
  }

  const handleEditorBlur = () => {
    let contentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    updateTableCellData(contentAsHTML);
  }

  const uploadImageCallback = (file: File) => {
    return new Promise(
      (resolve, reject) => {
        if (!isFilenameImage(file.name)) {
          const validFileErr = "Please select a valid image.";
          dispatch(showErrorToast(validFileErr));
          reject(validFileErr);
        }

        const fileSizeError = getFileSizeError(file);
        if (fileSizeError) {
          dispatch(showErrorToast(fileSizeError));
          reject(fileSizeError);
        }

        if (existingCell && existingCell.value.indexOf('<img') > -1) {
          const alreadyImageErr = "There is already an image.";
          dispatch(showErrorToast(alreadyImageErr));
          reject(alreadyImageErr);
        }

        const imageObject: ITableImageFile = {
          file,
          localName: URL.createObjectURL(file),
        }

        dispatch(uploadTableImage({
          swGuid,
          swVersion: 0,
          imageObject: imageObject,
          componentGuid: tableComponent.guid,
          stepGuid,
          rowIndex: rowKey,
          colIndex: colKey,
        }));

        resolve({ data: { link: imageObject.localName } });
      }
    );
  }

  const onMergeClick = (value: string) => {
    let contentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let pathArray = value.split('|');
    let rSpan: number = 1;
    let cSpan: number = 1;

    if (pathArray.length === 3 && pathArray[0] === "Merge") {
      let direction = pathArray[1];
      let amount = parseInt(pathArray[2], 10);

      // figure out rowspan and colspan here
      if (existingCell) {
        rSpan = existingCell.rowSpan;
        cSpan = existingCell.colSpan;
      }

      if (direction === "Down") {
        rSpan = amount;
      } else {
        cSpan = amount;
      }
    }
    dispatch(dispatch(updateTableCell({
      componentGuid: tableComponent.guid,
      stepGuid,
      cell: {
        rowIndex: rowKey,
        colIndex: colKey,
        value: contentAsHTML,
        rowSpan: rSpan,
        colSpan: cSpan,
        imageFileName: existingCell ? existingCell.imageFileName : undefined,
      }
    })));

  }

  const imgTagRegEx = /<img\s+[^>]*>/gi;

  const countImages = (html: string) => {
    return (html.match(imgTagRegEx) || []).length;
  };

  const uploadDownloadTimeImage = (data: ITimeImageData) => {
    let cellAsHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    if (countImages(cellAsHtml) > 0) {
      const alreadyImageErr = "There is already an image.";
      dispatch(showErrorToast(alreadyImageErr));
      return;
    }
  
    const alt = addImageNumberToLabel(data.label, data.imageNumber)
      .replace(".tiff", ".png");

    let contentAsHTML = `${cellAsHtml}
      <img src="" alt="${alt}"/>
    <p/>
    `;

    let tableTimeImageAttachment: ITableTimeImageAttachment = {
      swGuid,
      swVersion,
      label: data.label,
      link: data.link,
      imageNumber: data.imageNumber,
      componentGuid: tableComponent.guid,
      stepGuid: stepGuid,
      rowIndex: rowKey,
      colIndex: colKey,
      value: contentAsHTML,
      altTagValue: alt
    }

    // download data from TimeImageApi
    dispatch(
      downloadTableTimeImage(tableTimeImageAttachment));
  }

  function handleKeyCommand(
    command: DraftEditorCommand,
    editorState: EditorState
  ): DraftHandleValue {
    if (command === "backspace") {
      if (
        !backspaceMessageShown &&
        editorState
          .getCurrentContent()
          .getBlocksAsArray()
          .some((b) => b.getType() === "atomic")
      ) {
        dispatch(
          showErrorToast(
            "You may need to hit backspace multiple times to delete an image."
          )
        );

        setBackspaceMessageShown(true);
      }
    }

    if(command === "delete") {
      if (
        !deleteMessageShown &&
        editorState
          .getCurrentContent()
          .getBlocksAsArray()
          .some((b) => b.getType() === "atomic")
      ) {
        dispatch(
          showErrorToast(
            "You may need to hit delete multiple times to delete an image."
          )
        );

        setDeleteMessageShown(true);
      }
    }

    return "not-handled";
  }

  return (
    <>
      <Editor
        handlePastedText={() => false}
        stripPastedStyles={true}
        toolbarOnFocus
        toolbarClassName="rte-toolbar-class-table"
        editorClassName="rte-editor-class-table"
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        onBlur={handleEditorBlur}
        readOnly={disabled}
        placeholder="Enter some text..."
        toolbarCustomButtons={[
          <CustomMergeOption
            onChange={handleEditorChange}
            editorState={editorState}
            onMergeClick={onMergeClick}
            colIndex={colKey}
            rowIndex={rowKey}
            tableColLen={tableComponent.colCount}
            tableRowLen={tableComponent.rowCount}
          />,
          <TableTimeImageComponent
            allowEdit={!disabled}
            uploadDownloadTimeImage={uploadDownloadTimeImage}
            timeImageData={timeImageData}
            swType={swType}
          />,
        ]}
        toolbar={{
          options: ["inline", "list", "image", "link"],
          inline: {
            inDropdown: true,
            options: ["bold", "italic", "underline"],
          },
          list: {
            inDropdown: true,
          },
          image: {
            alignmentEnabled: false,
            previewImage: true,
            uploadEnabled: true,
            urlEnabled: false,
            uploadCallback: uploadImageCallback,
            inputAccept:
              "image/gif, image/jpeg, image/jpg, image/png, image/svg+xml",
            defaultSize: {
              height: "160px",
              width: "208px",
            },
          },
        }}
        handleKeyCommand={handleKeyCommand}
        ref={editorRef}
      />
    </>
  );
}

export default TableEditorCell;