import { IRichTextInputComponent } from "interfaces/sw/SWInterfaces";
import React from "react";
import "./RichTextParagraph.scoped.scss";
import sanitizeHtml from 'sanitize-html-react';

interface IRichTextComponentProps {
  component: IRichTextInputComponent,
}

const RichTextParagraph: React.FC<IRichTextComponentProps> = ({component}) => {
  return (
    <div
      className="html-content"
      dangerouslySetInnerHTML={createMarkup(component.html)}
    />
  )
}

export default RichTextParagraph;

function createMarkup(data: string) {
  const cleanHtml = sanitizeHtml(data, {
    allowedTags: ['p', 'ul', 'li', 'ol', 'b', 'i', 'a', 'strong', 'em', 'ins'],
    allowedAttributes: {
      'a': ['href', 'name', 'target']
    },
  });

  return {__html:  cleanHtml};
}