import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import config from "./config";

export const authRequest = {
  scopes: [
    config.authConfig.appScope,
  ],
};

export const msalConfig: Configuration = {
  auth: {
    clientId: config.authConfig.clientId,
    authority: `https://login.microsoftonline.com/${config.authConfig.tenantId}`,
    redirectUri: config.authConfig.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

export const msalPublicClient = new PublicClientApplication(msalConfig);

export const acquireAccessToken = async () => {
  const activeAccount = msalPublicClient.getActiveAccount();
  const accounts = msalPublicClient.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    throw new Error("Network request aborted. User is not authenticated");
  }

  const request = {
    scopes: authRequest.scopes,
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalPublicClient.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch (err: any) {
    if (err?.errorCode === "login_required"
        || err?.errorCode === "consent_required"
        || err?.errorCode === "interaction_required"
        || err?.errorCode === "monitor_window_timeout") {
      // User must log in again. It has expired.
      msalPublicClient.loginRedirect();
    }
  }
}

export const acquireUSSAccessToken = async () => {

  const msalConfigUSS: Configuration = {
    auth: {
      clientId: config.authConfig.clientId,
      authority: `https://login.microsoftonline.com/${config.authConfigUSS.tenantId}`,
      redirectUri: config.authConfig.redirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  };

  const msalUSSPublicClient = new PublicClientApplication(msalConfigUSS);
  const activeAccount = msalUSSPublicClient.getActiveAccount();
  const accounts = msalUSSPublicClient.getAllAccounts();
  const OAuthSettings = {
    appId: config.authConfig.clientId,
    redirectUri: config.authConfig.redirectUri,
    authority: `https://login.microsoftonline.com/${config.authConfigUSS.tenantId}`,
    scopes: [
      config.authConfigUSS.appScope,
    ],
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalUSSPublicClient.acquireTokenSilent(OAuthSettings);
    return authResult.accessToken;
  } catch (err: any) {
    return "";
  }
}