import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import "./ManageNotices.scoped.scss";
import { addNewComponent, updateComponentSortOrders, setIsSearchRCNoticeOpen, fetchRCNotice, setRCNoticeStep } from "store/manageSW/manageSWActions";
import { StepComponentTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import StepComponent from "../components/StepComponent";
import { ImageDataDestinations, INoticeVisibility } from "store/manageSW/manageSWTypes";
import ReorderModal, { ISortableItem } from "components/common/reorderModal/ReorderModal";
import sortIcon from "media/icons/dls/sort.svg";
import AddSWModal from '../AddSWModal'
import { clearFilter, clearList } from "store/swList/swListActions";
import AddRCNoticeModal from "../notices/rcNotice/AddRCNoticeModal";

interface IManageNoticesProps {
    isDisabled: boolean,
    noticeVisibilities?: INoticeVisibility[],
    isDraftPage?: boolean,
    isDirty?: boolean,
}

const ManageNotices: React.FC<IManageNoticesProps> = ({
    isDisabled,
    noticeVisibilities,
    isDraftPage,
    isDirty,
}) => {
    const {
        notices,
        version,
        guid,
        type,
    } = useSelector(store => store.manageSW.SW);

    const {
        isSearchRCNoticeOpen, 
        rcNoticeStep
    } = useSelector(store => store.manageSW)

    const dispatch = useDispatch();
    const [isComponentSortModalOpen, setIsComponentSortModalOpen] = useState(false);
    const [isNoticeWarningOpen, setIsNoticeWarningOpen] = useState<boolean>(false);

    const newNotice = () => {
        if ((type === SWTypes.CL || type === SWTypes.LCL)
            && notices.length >= 3) {
            setIsNoticeWarningOpen(true);
        }

        dispatch(addNewComponent({
            stepGuid: undefined,
            type: StepComponentTypes.Notice,
        }));
    }

    const openRCNoticeSearchModal = () => {
        dispatch(setRCNoticeStep(""));
        dispatch(setIsSearchRCNoticeOpen(true));
        dispatch(clearList());
    }

    const AddRCNoticeToSw = (rcNoticeSWId: number, rcNoticeSWGuid: string, rcNoticeSWVersion: number, source: string) => {
        dispatch(fetchRCNotice({
          swGuid: guid,
          rcID: rcNoticeSWId,
          rcGuid: rcNoticeSWGuid,
          rcVersion: rcNoticeSWVersion,
          stepGuid: rcNoticeStep,
          source: source,
          currentNoticeGuid: "",
          isRCComponent: true,
        }));
        dispatch(setIsSearchRCNoticeOpen(false));
        dispatch(clearFilter());
    }

    return (
        <>
            {notices
                .slice()
                .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1)
                .map(notice =>
                    <StepComponent
                        key={notice.guid}
                        component={notice}
                        showWrapper={false}
                        isDisabled={isDisabled}
                        imageDataDestination={ImageDataDestinations.ManageSW}
                        swGuid={guid}
                        swVersion={version}
                        swType={type}
                        noticeVisibilities={noticeVisibilities}
                        isDraftPage={isDraftPage}
                        isVisibilityExternal={true}
                        isDirty={isDirty}
                    />
                )}

            {isNoticeWarningOpen
                && <AddSWModal
                    onModalClick={() => setIsNoticeWarningOpen(false)}
                    header="Notice Warning"
                    message="Checklists should have a maximum of three notices" />
            }

            {!isDisabled &&
                <div className="buttons">
                    <button
                        className="primary-button"
                        onClick={newNotice}
                    >
                        Add Notice
                    </button>
                    {type !== SWTypes.TLMRC && 
                        type !== SWTypes.SWI && 
                        type !== SWTypes.CL && 
                        type !== SWTypes.ECL &&  
                            <button
                                key={"RCNotice"}
                                className="primary-button"
                                onClick={function () { openRCNoticeSearchModal(); }}
                                >
                                Add Reusable Notice
                            </button>
                    }
                    {notices.length > 0 &&
                        <button
                            className="secondary-button"
                            title={"Reorder components"}
                            onClick={() => setIsComponentSortModalOpen(true)}
                        >
                            <img
                                alt="Sort"
                                title="Sort"
                                className="icon-small sort-icon"
                                src={sortIcon}
                            />
                            Reorder Notices
                        </button>
                    }
                </div>
            }
            {isComponentSortModalOpen &&
                <ReorderModal
                    title="Reorder Notices"
                    items={notices
                        .slice()
                        .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1)
                        .map(x => ({
                            label: x.label?.replaceAll(/(<([^>]+)>)/ig, '') || x.type,//this removes html tags from richtext when showing in reorder modal.
                            guid: x.guid,
                            sortOrder: x.sortOrder,
                        }))
                    }
                    onCancel={() => setIsComponentSortModalOpen(false)}
                    onSave={(sortData: ISortableItem[]) => {
                        dispatch(updateComponentSortOrders({
                            stepGuid: undefined,
                            items: sortData.map((x, ix) => ({
                                guid: x.guid,
                                newIndex: ix,
                            })),
                        }));
                        setIsComponentSortModalOpen(false);
                    }}
                />
            }
            {isSearchRCNoticeOpen && rcNoticeStep === "" &&
                <AddRCNoticeModal
                    onModalClick={() => (dispatch(setIsSearchRCNoticeOpen(false)), dispatch(clearFilter()))}
                    AddRCNoticeToSw={AddRCNoticeToSw}
                    swType={type}
                />
            }        
        </>
    );
}

export default ManageNotices;