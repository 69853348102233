import { createAction } from "@reduxjs/toolkit";
import { IBatchUpdateCommand, IBatchUpdateResponse } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { SortDirection } from "interfaces/misc/miscInterfaces";
import { IOperation } from "interfaces/operations/Operations";
import { ISWSummaryItem, RCTypes, SWSortFields } from "interfaces/sw/SWInterfaces";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import { ISWListData } from "store/swList/swListTypes";
import { BatchUpdatesTab, ICommandManagerData } from "./batchUpdatesTypes";

export const setCurrentTab = createAction<BatchUpdatesTab>("batchUpdates/setCurrentTab");

export const tryAddSWs = createAction<ISWSummaryItem[]>("batchUpdates/tryAddSWs");
export const addSWs = createAction<ISWSummaryItem[]>("batchUpdates/addSWs");
export const removeSWs = createAction<{
  guids: string[],
}>("batchUpdates/removeSWs");
export const addCommand = createAction<IBatchUpdateCommand>("batchUpdates/addCommand");
export const removeCommand = createAction<{
  commandSortOrder: number,
}>("batchUpdates/removeCommand");
export const setCommandManagerData = createAction<ICommandManagerData>("batchUpdates/setCommandManagerData");
export const submitBatchUpdates = createAction("batchUpdates/submitBatchUpdates");
export const setSubmitOperation = createAction<IOperation | undefined>("batchUpdates/setSubmitOperation");
export const setSubmissionErrorResponse = createAction<IBatchUpdateResponse | undefined>("batchUpdates/setSubmissionErrorResponse");

// Filter-related actions.
export const setAllowSearching = createAction<boolean>("batchUpdates/setAllowSearching");
export const resetAll = createAction("batchUpdates/resetAll");
export const setData = createAction<ISWListData>("batchUpdates/setData");
export const setSearchText = createAction<string>("batchUpdates/setSearchText");
export const toggleFilterModal = createAction<boolean>("batchUpdates/toggleFilterModal");
export const applyFilter = createAction("batchUpdates/applyFilter");
export const clearFilter = createAction("batchUpdates/clearFilter");
export const addFilterItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("batchUpdates/addFilterItem");
export const addFilterItems = createAction<{
  metaDataName: MetaDataNames,
  items: IMasterDataItem[],
}>("batchUpdates/addFilterItems");
export const removeFilterItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("batchUpdates/removeFilterItem");
export const setStringFilter = createAction<{
  attributeName: "maintenanceTaskIds",
  value: string
}>("batchUpdates/setStringFilter");
export const setShowOnlyTasks = createAction<boolean | undefined>(
  "batchUpdates/setShowOnlyTasks"
);
export const setSort = createAction<{
  sortBy: SWSortFields,
  sortDir: SortDirection
}>("batchUpdates/setSort");
export const setRCType = createAction<RCTypes | undefined>(
  "batchUpdates/setRCType"
);
