import React from "react";
import FlowLayout from "components/layout/FlowLayout";
import BatchUpdatesToolbar from "./BatchUpdatesToolbar";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { setCurrentTab, submitBatchUpdates } from "store/batchUpdates/batchUpdatesActions";
import { BatchUpdatesTab } from "store/batchUpdates/batchUpdatesTypes";
import "./BatchUpdatesCreator.scoped.scss";
import SWSearch from "./SWSearch/SWSearch";
import CommandsList from "./Commands/CommandsList";
import Review from "./Review/Review";
import ModalSpinner from "components/common/ModalSpinner";
import SubmissionErrorModal from "./ErrorModal/SubmissionErrorModal";

const BatchUpdatesCreator: React.FC = () => {
  const {
    currentTab,
    submitOperation,
    submitErrorResponse,
    commands,
  } = useSelector(store => store.batchUpdates);
  const dispatch = useDispatch();

  const onNextClick = () => {
    if (currentTab === BatchUpdatesTab.Review) {
      dispatch(submitBatchUpdates());
    } else {
      dispatch(setCurrentTab(
        currentTab === BatchUpdatesTab.SW
          ? BatchUpdatesTab.Commands
          : BatchUpdatesTab.Review
      ));
    }
  }

  return (
    <>
      <FlowLayout
        header={
          <>
            <h2 className="page-title">Batch Updates</h2>
            <BatchUpdatesToolbar currentTab={currentTab} />
          </>
        }
        footer={
          <div className="footer">
            <button className="primary-button" onClick={onNextClick}>
              {currentTab === BatchUpdatesTab.Review ? "Submit" : "Next"}
            </button>
          </div>
        }
      >
        {currentTab === BatchUpdatesTab.SW && <SWSearch />}
        {currentTab === BatchUpdatesTab.Commands && (
          <CommandsList commands={commands} isReadOnly={false} />
        )}
        {currentTab === BatchUpdatesTab.Review && <Review />}
        {submitOperation?.isWorking && <ModalSpinner />}
        {submitErrorResponse && <SubmissionErrorModal />}
      </FlowLayout>
    </>
  );
};

export default BatchUpdatesCreator;