import React from "react";
import Modal from '../../../common/Modal'

interface IAddSWModal {
    onModalClick?(): void,
    header: string,
    message: string
}

const AddSWModal: React.FC<IAddSWModal> = (props) => {
    return (
        <Modal
            header={props.header}
            controls={(
                <>
                    <button
                        className="primary-button ok-button"
                        onClick={props.onModalClick}
                    >
                        OK
                    </button>
                </>
            )}
            isOpen={true}
        >
            <p>
                {props.message}
            </p>
        </Modal>
    );
};

export default AddSWModal;