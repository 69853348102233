import { ITextInputComponent } from "interfaces/sw/SWInterfaces";
import React, { useState } from "react";
import TextOrNumberBox, { TextInputTypes } from "../controls/TextOrNumberBox";

interface ITextInputProps {
  component: ITextInputComponent,
  isDisabled: boolean,
}

const TextInput: React.FC<ITextInputProps> = ({ component, isDisabled }) => {
  const [value, setValue] = useState("")

  const onValueChange = (value: string) => {
    if (isDisabled) {
      return;
    }

    setValue(value);
  }

  return (
    <div>
      <label>{component.label}</label>
      <TextOrNumberBox
        disabled={isDisabled}
        value={value}
        onResponseChange={val => onValueChange(val)}
        inputType={TextInputTypes.Text}
      />
    </div>
  );
}

export default TextInput;