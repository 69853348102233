import React from "react";
import { useDispatch } from "react-redux";
import { closeBatchUpdateSWMetaData } from "store/approvals/approvalsActions";
import Modal from "../../common/Modal";
import useSelector from "store/useSelector";
import { BatchUpdateMetaTypes } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import "./ViewSWMetaData.scoped.scss";
import FlowLayout from "components/layout/FlowLayout";

const ViewSWMetaData: React.FC = () => {
  const { currentBatchUpdateSWMetaData, loadBatchUpdateSWMetaDataOperation } =
    useSelector((store) => store.approvals);

  const dispatch = useDispatch();

  const renderMetadataSection = (metaType: BatchUpdateMetaTypes) => {
    const serviceTypeMetaData = currentBatchUpdateSWMetaData?.commands.filter(
      (r) => r.metaType === metaType
    );

    if (!serviceTypeMetaData || serviceTypeMetaData.length === 0) {
      return <></>;
    }

    return (
      <div className="row">
        <label>{metaType}</label>
        <div className="value">
          {serviceTypeMetaData.map((swMetaData) => (
            <span
              key={swMetaData.masterDataItem.guid}
              className={swMetaData.command?.toLocaleLowerCase()}
            >
              {swMetaData.masterDataItem.value}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Modal
      header="View Standardwork Metadata Changes for Bulk Approval"
      controls={
        <>
          <button
            className="primary-button"
            onClick={() => dispatch(closeBatchUpdateSWMetaData())}
          >
            Close
          </button>
        </>
      }
      isOpen={true}
    >
      {!loadBatchUpdateSWMetaDataOperation?.isWorking && (
        <FlowLayout>
          {Object.keys(BatchUpdateMetaTypes).map((type) => (
            <div key={type}>
              {renderMetadataSection(type as BatchUpdateMetaTypes)}
            </div>
          ))}
        </FlowLayout>
      )}
    </Modal>
  );
};

export default ViewSWMetaData;
