import Modal from "components/common/Modal";
import React, { FC } from "react";
import "./UpdateGemsRevisionDialogModal.scoped.scss";

interface IAction {
  label: string;
  onClick: () => void;
}

interface IUpdateGemsRevisionDialogModalProps {
  isOpen: boolean;
  notice: React.ReactNode;
  primaryAction: IAction;
  secondaryAction: IAction;
}

const UpdateGemsRevisionDialogModal: FC<
  IUpdateGemsRevisionDialogModalProps
> = ({ isOpen, notice, primaryAction, secondaryAction }) => {
  return (
    <>
      <Modal isOpen={isOpen} header={"Material Revision changed"}>
        {notice}
        <div className="row actions-row">
          <button className="primary-button" onClick={primaryAction.onClick}>
            {primaryAction.label}
          </button>
          <button
            className="secondary-button"
            onClick={secondaryAction.onClick}
          >
            {secondaryAction.label}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default UpdateGemsRevisionDialogModal;
