export default function formatDate(date: Date | undefined,
  showTime: boolean = true,
  showDate: boolean = true,
  monthFormat: 'long' | 'short' = 'long'): string {
  let day = date?.getDate();
  let month = date?.toLocaleString('default', { month: monthFormat });
  let year = date?.getFullYear();
  let hour = date?.getHours();
  let minute = date?.getMinutes();
  let tt = date ? date?.getHours() >= 12 ? "PM" : "AM" : "";

  let dateStr = '';
  if (showDate) {
    dateStr += date ? `${month?.toString().padStart(2, '0')}-${day?.toString().padStart(2, '0')}, ${year}` : '';
  }
  if (showTime) {
    dateStr += date ? ` ${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')} ${tt}` : '';
  }

  return dateStr;
}

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getUtcDateString = (date: Date) => {
  return `${
    MONTHS[date.getUTCMonth()]
  } ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
};