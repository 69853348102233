import React from "react";
import { portalRoot } from "index";
import ReactDOM from "react-dom";

interface IPortalProps {
  children: any,
}

export default class Portal extends React.Component<IPortalProps> {
  element: HTMLDivElement;

  constructor(props: IPortalProps) {
    super(props);
    this.element = document.createElement("div");
  }

  componentDidMount = () => {
    portalRoot.appendChild(this.element);
  };

  componentWillUnmount = () => {
    portalRoot.removeChild(this.element);
  };

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.element);
  }
}