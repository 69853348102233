import { createReducer } from "@reduxjs/toolkit";
import { setProxyApprover, setProxyOperation } from "./proxyApproversActions";
import { IProxyApproversState } from "./proxyApproversTypes";

const initialState: IProxyApproversState = {
  proxyApprover: undefined,
  proxyOperation: { isWorking: false },
};

const proxyApproverReducer = createReducer(initialState, (builder) => {
  builder.addCase(setProxyApprover, (state, action) => {
    state.proxyApprover = action.payload;
  });

  builder.addCase(setProxyOperation, (state, action) => {
    state.proxyOperation = action.payload;
  });
});

export default proxyApproverReducer;
