import { IMasterDataSection } from "store/masterData/masterDataTypes";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import './SelectMeter.scss'
import useSelector from "store/useSelector";
import { loadMeters } from "store/masterData/masterDataActions";
import { IDateInputComponent, IMultiSelectInputComponent, INumberInputComponent, IPassFailInputComponent, ISelectInputComponent, ITextInputComponent, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import { updateSelectInput, updateMultiSelectInput, updateNumberInput, updateTextInput, updateDateInput, updatePassFailInput } from "store/manageSW/manageSWActions";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { filter } from "lodash";

interface ISelectMeter {
  inputComponent: ISelectInputComponent | IMultiSelectInputComponent | ITextInputComponent | IDateInputComponent | IPassFailInputComponent;
  stepGuid: string;
  meters: IMasterDataItem[];
}

const SelectMeter: React.FC<ISelectMeter> = ({
  inputComponent,
  stepGuid,
  meters
}) => {
  const dispatch = useDispatch();

  const onChangeMeter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const meterItem = meters.find(
      (m) => m.guid === e.target.value
    ) ?? {
      guid: e.target.value,
      value: e.target.value,
      active: true,
    };

    if (inputComponent.type === StepComponentTypes.SelectInput) {
      dispatch(updateSelectInput({
        stepGuid,
        component: {
          ...inputComponent,
          meterId: meterItem.guid,
          meterCode: meterItem.code,
        },
      }));
    } else if (inputComponent.type === StepComponentTypes.MultiSelectInput) {
      dispatch(updateMultiSelectInput({
        stepGuid,
        component: {
          ...inputComponent,
          meterId: meterItem.guid,
          meterCode: meterItem.code,
        },
      }));
    } else if (inputComponent.type === StepComponentTypes.TextInput) {
      dispatch(updateTextInput({
        stepGuid,
        component: {
          ...inputComponent,
          meterId: meterItem.guid,
          meterCode: meterItem.code,
        },
      }));
    } else if (inputComponent.type === StepComponentTypes.DateInput) {
      dispatch(updateDateInput({
        stepGuid,
        component: {
          ...inputComponent,
          meterId: meterItem.guid,
          meterCode: meterItem.code,
        },
      }));
    } else if (inputComponent.type === StepComponentTypes.PassFailInput) {
      dispatch(updatePassFailInput({
        stepGuid,
        component: {
          ...inputComponent,
          meterId: meterItem.guid,
          meterCode: meterItem.code,
        },
      }));
    }
  }

  return (
    <>
      <select
        className="meterDropdown"
        onChange={(e) => onChangeMeter(e)}
        value={inputComponent.meterId ?? ""}
      >
        <option value="" key=""></option>
        {meters.map((t) => (
          <option key={t.guid + "-" + t.code} value={t.guid}>
            {t.code}
          </option>
        ))}
      </select>
    </>
  );
}

export default SelectMeter;

