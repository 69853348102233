import Modal from "components/common/Modal";
import React, { useState } from "react";
import "./ValidationModal.scoped.scss";

interface IAddSWConfirmationModal {
    saveData(versionChanges: string): void,
    header: string,
    message: string
}

const ValidationModal: React.FC<IAddSWConfirmationModal> = (props) => {
    const [textBox, setTextBox] = useState("");

    return (
        <Modal
            header={props.header}
            controls={(
                <>
                    <div>
                        <input
                            type="text"
                            className="txtCls"
                            value={textBox}
                            onChange={(e) => { setTextBox(e.target.value) }}
                        />
                    </div>
                    <div>

                    </div>
                    <div className="btnCls">
                        <button
                            className="primary-button ok-button"
                            disabled={textBox === ""}
                            onClick={() => { props.saveData(textBox) }}
                        >
                            Continue to submit for review
                        </button>
                    </div>
                </>
            )}
            isOpen={true}
        >
            <p>
                {props.message}
            </p>
        </Modal>
    );
};

export default ValidationModal;