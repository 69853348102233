import React from "react";
import Modal from '../../common/Modal'
import { Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import { IRCErrorResponse } from "../../../interfaces/approvals/approvalInterfaces";
import "./ApprovalErrorModal.scoped.scss";

interface IApprovalErrorModal {
    onCloseErrPopUp(review: boolean): void,
    header: string;
    message: IRCErrorResponse;
}

const ApprovalErrorModal: React.FC<IApprovalErrorModal> = (props) => {
    return (
        <Modal
            header={props.header}
            controls={(
                <>
                    <button
                        className="primary-button ok-button"
                        onClick={() => props.onCloseErrPopUp(false)}
                    >
                        Cancel
                    </button>
                </>
            )}
            isOpen={true}
        >
            <p>
                {props?.message?.errMsg}
            </p>
            <ul className="sw-list">{props.message?.affectedSws.map((sw, index) => (
                <li key={index}>
                    <Link
                        target="_blank"
                        to={
                            sw.version === 0
                                ? Routes.EditStandardWork.replace(":guid", sw.guid).replace(
                                    ":version",
                                    sw.version.toString()
                                )
                                : Routes.ViewStandardWork.replace(":guid", sw.guid).replace(
                                    ":version",
                                    sw.version.toString()
                                )
                        }
                    ><p>{sw.title}</p>
                    </Link></li>
            ))}</ul>
        </Modal>
    );
};

export default ApprovalErrorModal;