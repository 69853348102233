import React, { useLayoutEffect } from "react";
import MetadataRow, { MetadataRowTypes } from "./MetadataRow";

import {
  IStep,
  SWTypes,
  StepBooleanMetaPropNames,
  StepStringMetaPropNames,
  ServiceLevel,
  Usage,
  StepTypes,
  TemplateTypes,
} from "interfaces/sw/SWInterfaces";

import { useDispatch } from "react-redux";

import {
  setOOS,
  setServiceLevel,
  setUsage,
  setSiteLocation,
  setStepBooleanValue,
  setStepStringValue,
} from "store/manageSW/manageSWActions";

import "./MetadataRow.scoped.scss";
import { getUserGroupSWTypes } from "utilities/sw/filterUtils";
import { UserGroups } from "interfaces/user/UserInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { loadMeters } from "store/masterData/masterDataActions";

const hasSomeText = (s: string | undefined) => !!s?.trim();

const criticalStepEnabledTypes = [
  SWTypes.SWI,
  SWTypes.TLMSWI,
  SWTypes.TLMRC,
  SWTypes.MFGRC,
  SWTypes.MFGSWI,
  SWTypes.MFGCL
];

const tlmSwTypes = getUserGroupSWTypes()[UserGroups.TLM];
const mfgSwTypes = getUserGroupSWTypes()[UserGroups.MFG];

interface IManageStepMetadata {
  step: IStep;
  isDisabled: boolean;
  isSWEditable: boolean;
  templateType: TemplateTypes | undefined;
  swType: SWTypes;
  onVisibleMetadataChanged?(hasVisibleMetadata: boolean): void;
  showClientReport: boolean;
  onClientReportChanged(hasClientReport: boolean): void;
  epicEquipment: IMasterDataItem[],
}

const ManageStepMetadata: React.FC<IManageStepMetadata> = ({
  step,
  isDisabled,
  isSWEditable,
  swType,
  onVisibleMetadataChanged,
  templateType,
  showClientReport,
  onClientReportChanged,
  epicEquipment
}) => {
  const dispatch = useDispatch();
  const dispatchBool = (value: string, propName: StepBooleanMetaPropNames) => {
    dispatch(
      setStepBooleanValue({
        stepGuid: step.guid,
        value: value === "true",
        propName,
      })
    );
  };

  const dispatchString = (value: string, propName: StepStringMetaPropNames) => {
    dispatch(
      setStepStringValue({
        stepGuid: step.guid,
        value,
        propName,
      })
    );
  };

  const dispatchServiceLevel = (value: string) => {
    dispatch(
      setServiceLevel({
        stepGuid: step.guid,
        value: value as ServiceLevel,
      })
    )
  }

  const dispatchUsage = (value: string) => {
    dispatch(
      setUsage({
        stepGuid: step.guid,
        value: value as Usage,
      })
    )
  }

  const dispatchSiteLocation = (value: string) => {
    dispatch(loadMeters({ onlyActive: true, equipmentCodes: epicEquipment, location: value ?? "Global" }));
    dispatch(
      setSiteLocation({
        stepGuid: step.guid,
        value: value,
      })
    )
  }

  function stringToServiceLevel(serviceLevel: string): ServiceLevel {
    return serviceLevel as ServiceLevel;
  }
  const dispatchServiceOOS = (item?: IMasterDataItem, isAdding?: boolean) => {
    dispatch(
      setOOS({
        stepGuid: step.guid,
        item: item,
        isAdding: isAdding
      })
    );
  }
  const editingIsEnabled = !isDisabled;

  const showIsCriticalStep: boolean =
    (editingIsEnabled && criticalStepEnabledTypes.includes(swType)) ||
    step.isCritStep;

  const showIsMemoryStep: boolean =
    (editingIsEnabled && swType === SWTypes.ECL) || step.isMemoryStep;

  const showTaskMetadataForMaximo: boolean =
    swType === SWTypes.TLMSWI && step.isTask;

  const showServiceLevel: boolean =
    showTaskMetadataForMaximo && templateType == TemplateTypes.Consolidated;

  const showOOS: boolean =
    (step.isTask && swType === SWTypes.TLMSWI);

  const hasComprehensionData =
    step.isComprehensionRequired ||
    step.isCritComp ||
    hasSomeText(step.critCompText) ||
    hasSomeText(step.critCompAnsNeedsImprov) ||
    hasSomeText(step.critCompAnsSufficient);

  const showComprehension: boolean = editingIsEnabled || hasComprehensionData;

  useLayoutEffect(() => {
    if (onVisibleMetadataChanged) {
      onVisibleMetadataChanged(
        showIsCriticalStep || showIsMemoryStep || showComprehension || showTaskMetadataForMaximo || showOOS
        || showServiceLevel
      );
    }
  }, [
    showTaskMetadataForMaximo,
    showServiceLevel,
    showIsCriticalStep,
    showIsMemoryStep,
    showComprehension,
    onVisibleMetadataChanged,
    showOOS,
  ]);

  return (
    <>
      {showIsMemoryStep && (
        <MetadataRow
          label="Memory step?"
          value={step.isMemoryStep.toString()}
          onChange={(val) => dispatchBool(val, "isMemoryStep")}
          type={MetadataRowTypes.YesNo}
          isDisabled={isDisabled}
        />
      )}

      {showIsCriticalStep && (
        <MetadataRow
          label={
            tlmSwTypes.includes(swType) ? "Mandatory?" : (mfgSwTypes.includes(swType) ? "Is Mandatory Step?" : "Is Critical Step?")
          }
          value={step.isCritStep.toString()}
          onChange={(val) => dispatchBool(val, "isCritStep")}
          type={MetadataRowTypes.YesNo}
          isDisabled={isDisabled}
        />
      )}

      {showServiceLevel && (
        <MetadataRow
          label="Level"
          value={
            step.serviceLevel === null ||
              step.serviceLevel === undefined
              ? ServiceLevel.SL_0
              : step.serviceLevel
          }
          type={MetadataRowTypes.ServiceLevel}
          onChange={(val) => dispatchServiceLevel(stringToServiceLevel(val))}
          isDisabled={
            (swType == SWTypes.TLMSWI && step.isRCStep && step.isTask) ? !isSWEditable : isDisabled
          }
        />
      )}

      {swType === SWTypes.TLMSWI && step.stepType === StepTypes.Step && (
        <MetadataRow
          label="Usage"
          value={
            step.usage === null ||
              step.usage === undefined
              ? Usage.Internal
              : step.usage
          }
          onChange={(val) => dispatchUsage(val)}
          type={MetadataRowTypes.Usage}
          isDisabled={isDisabled}
        />
      )}

      {showClientReport && (
        <div className="entry-row">
          <label>Client Report</label>
          <div>
            <input
              type="checkbox"
              checked={step.clientReport ?? false}
              onChange={(e) => onClientReportChanged(e.target.checked)}
              disabled={isDisabled}
            />
          </div>
        </div>
      )}

      {(showOOS || step.OOS.length > 0) && (
        <MetadataRow
          label="OOS"
          value={step.OOS.map(x => `${x.code} - ${x.value}`)}
          items={step.OOS}
          onChange={(val, item, isAdding) => dispatchServiceOOS(item, isAdding)}
          type={MetadataRowTypes.OOS}
          isDisabled={isDisabled}
        />
      )}

      {showTaskMetadataForMaximo && (
        <MetadataRow
          label="Location"
          value={
            step.siteLocation === null ||
              step.siteLocation === undefined
              ? "1:Global"
              : step.siteLocation
          }
          onChange={(val) => dispatchSiteLocation(val)}
          type={MetadataRowTypes.SiteLocation}
          isDisabled={isDisabled}
        />
      )}

      {showComprehension && (
        <fieldset>
          <legend className="legend">
            <div className="tooltip">
              Assessor View - Competency
              <span className="tooltiptext">
                As the technical expert you are required to review the questions
                and answers below which will auto create the Competency
                Practical Assessments (CPA) for SLB field usage
              </span>
            </div>
          </legend>
          <MetadataRow
            label="Comprehension required?"
            value={step.isComprehensionRequired.toString()}
            onChange={(val) => dispatchBool(val, "isComprehensionRequired")}
            type={MetadataRowTypes.YesNo}
            isDisabled={isDisabled}
          />
          <MetadataRow
            label="Critical comprehension"
            value={step.isCritComp.toString()}
            onChange={(val) => dispatchBool(val, "isCritComp")}
            type={MetadataRowTypes.YesNo}
            isDisabled={isDisabled}
          />
          <MetadataRow
            label="Critical comprehension question"
            value={step.critCompText || ""}
            onChange={(val) => dispatchString(val, "critCompText")}
            type={MetadataRowTypes.Text}
            isDisabled={isDisabled}
          />
          <MetadataRow
            label="Critical comprehension answer: Needs Improvement"
            value={step.critCompAnsNeedsImprov || ""}
            onChange={(val) => dispatchString(val, "critCompAnsNeedsImprov")}
            type={MetadataRowTypes.MultiLineText}
            isDisabled={isDisabled}
          />
          <MetadataRow
            label="Critical comprehension answer: Sufficient"
            value={step.critCompAnsSufficient || ""}
            onChange={(val) => dispatchString(val, "critCompAnsSufficient")}
            type={MetadataRowTypes.MultiLineText}
            isDisabled={isDisabled}
          />
        </fieldset>
      )}
    </>
  );
};

export default ManageStepMetadata;
