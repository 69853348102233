import React from "react";
// import * as XLSX from 'xlsx';
//import { ISWUserFeedbackExport } from "interfaces/sw/SWInterfaces";
//import useSelector from "store/useSelector";
import exportButtonIcon from "media/icons/dls/export.svg";
import "./UserFeedbacksExport.scoped.scss";

const UserFeedbacksExportToExcel: React.FC = () => {
  // const {
  //   userFeedbacksData: {
  //     swUserFeedbackDetails,
  //   }
  // } = useSelector(store => store.swList);

  // const exportToExcel= (userFeedbackData: ISWUserFeedbackExport[], fileName: string) => {
  //     var ws = XLSX.utils.json_to_sheet<ISWUserFeedbackExport>(userFeedbackData,
  //       {cellDates: true});
  //     if(!ws['!cols']) {
  //       ws['!cols'] = [];
  //     }
  //     // sets columns width in xls.
  //     ws['!cols'] =[{wch: 5 },
  //       {wch: 10 },
  //       {wch: 10 },
  //       { wch: 15 },
  //       { wch: 12 },
  //       { wch: 15 },
  //       { wch: 17 },
  //       { wch: 12 },
  //       { wch: 15 },
  //       { wch: 15 },
  //       { wch: 17 }
  //     ];

  //     var wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, 'userFeedbacks');
  //     XLSX.writeFile(wb, fileName + ".xlsx");
  // }

  return (
    <button
      className="secondary-button"
      disabled={true}
    //onClick={(e) => exportToExcel(swUserFeedbackDetails.userFeedbacksExport, "SWUserFeedbacks_" + swUserFeedbackDetails.swGuid)}
    >
      <img className="icon-small" src={exportButtonIcon}
        alt="Export to Excel" />
    </button>
  )
}

export default UserFeedbacksExportToExcel