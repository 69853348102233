import React, { useEffect, useState } from "react";
import useSelector from "../../store/useSelector";
import "./ProcessZipModal.scoped.scss";
import loaderGif from "media/gifs/loader.gif";
import { useDispatch } from "react-redux";
import { clearArchiveRequest, clearDownloadZip, clearZipSWList } from "store/swList/swListActions";

const ProcessZipModal: React.FC = () => {
    const { setDownloadZipData, setArchiveRequestData } = useSelector(store => store.swList);
    const [isClose, setIsClose] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (setDownloadZipData.length === 0 && setArchiveRequestData.length === 0) {
            dispatch(clearZipSWList());
            dispatch(clearDownloadZip());
            dispatch(clearArchiveRequest());
            setIsClose(false);
        }
    }, [dispatch, setDownloadZipData, setArchiveRequestData]);

    const closeModal = () => {
        setIsClose(true);
    }

    return (
        <>
            {
                ((setDownloadZipData.length > 0 || setArchiveRequestData.length > 0) && !isClose) &&
                <div className="modal">
                    <div className="modal-content">
                        <img src={loaderGif} alt="loading..." />
                        <div className="modal-data">
                            <div><b>Downloading Zip/Archiving files..</b></div>
                            <p>You can still browse website while we download/archive files</p>
                        </div>
                        <span className="close" onClick={closeModal}>&times;</span>
                    </div>
                </div>

            }
        </>
    );
};
export default ProcessZipModal;