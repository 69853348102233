import Modal, { ModalTheme } from "components/common/Modal";
import { ISWWithBatchError } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSubmissionErrorResponse } from "store/batchUpdates/batchUpdatesActions";
import useSelector from "store/useSelector";
import SWList, { SWListModes } from "../SWSearch/SWList";

const SubmissionErrorModal: React.FC = () => {
  const {
    submitErrorResponse,
    addedSWs,
  } = useSelector(store => store.batchUpdates);
  const dispatch = useDispatch();

  const errorSWs = !submitErrorResponse
    ? []
    : addedSWs
      .filter(x =>
        !!submitErrorResponse?.errors.find(z => z.swId === x.id)
      ).map((x): ISWWithBatchError => ({
        sw: x,
        error: submitErrorResponse.errors.find(z => z.swId === x.id),
      }));

  useEffect(() => {
    if (!errorSWs.length) {
      dispatch(setSubmissionErrorResponse(undefined));
    }
  }, [errorSWs, dispatch]);

  return (
    <Modal
      isOpen={true}
      header="Submission Failed"
      theme={ModalTheme.Error}
      width={"90%"}
      controls={(
        <button
          className="primary-button"
          onClick={() => dispatch(setSubmissionErrorResponse(undefined))}
        >
          Dismiss
        </button>
      )}
    >
      <p>
        The following Standard Works have one or more issues and cannot be bulk updated.
      </p>
      {errorSWs.length > 0 &&
        <SWList
          mode={SWListModes.Errors}
          swList={errorSWs}
        />
      }
    </Modal>
  );
}

export default SubmissionErrorModal;