import config from "config";
import { authPostFormData, authPostJson } from "utilities/msalFetches";
import { throwIfResponseError } from "apis/apiUtilities";
import { IFeedbackSave } from "interfaces/feedbacks/feedbackInterfaces";
import { formatSaveFeedback } from "./feedbackDataFormatters";

class FeedbackApi {
  public async createFeedback(feedback: IFeedbackSave): Promise<boolean> {
    var documents = feedback.documents;
    const formData = new FormData();
    documents.map((doc) => {
      if (doc.hasAttach && doc.file && doc.name.trim() != "") {
        formData.append("files", doc.file);
      }
    });

    let response = await authPostJson(
      config.endpoints.feedbacks.createFeedback,
      JSON.stringify(feedback)
    );

    await throwIfResponseError(response);
    let codeSaveFeedback = formatSaveFeedback(await response.json());

    if (documents.find(p=>p.hasAttach)) {
      await authPostFormData(
        config.endpoints.feedbacks.uploadDocumentsFeedback
          .replace("{guid}", codeSaveFeedback.guid)
          .replace("{id}", codeSaveFeedback.id.toString()),
        formData
      );
    }

    return true;
  }
}
export default new FeedbackApi();
