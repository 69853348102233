import { delay, put, takeEvery, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { addToastToState, removeToast, showErrorToast, showInfoToast, showSuccessToast } from './toastActions';
import { Action } from '@reduxjs/toolkit';
import { ToastStyle } from './toastTypes';

export default function* watchToastSagas() {
  yield all([
    watchShowToast(),
  ]);
}

function* watchShowToast() {
  yield takeEvery([
    showErrorToast.type,
    showInfoToast.type,
    showSuccessToast.type,
  ], showToastAsync);
}

function* showToastAsync(action: Action) {
  let style = ToastStyle.Info;  

  if (showErrorToast.match(action)) {
    style = ToastStyle.Error;
  } else if (showSuccessToast.match(action)) {
    style = ToastStyle.Success;
  } else if (showInfoToast.match(action)) {
    style = ToastStyle.Info;
  } else {
    return;
  }

  // Generate a unique id for this toast.
  let id = uuidv4();

  // Add the toast to the state.
  yield put(addToastToState({
    message: action.payload,
    id,
    style,
  }));

  // Wait 3 seconds.
  yield delay(3000);

  // Remove the toast.
  yield put(removeToast({ id }));
}