import React, { useEffect, useState } from "react";
import "./StepList.scoped.scss";
import Step from "./Step";
import { IStep, ISW, SWTypes } from "interfaces/sw/SWInterfaces";
import { cloneDeep } from "lodash";
import { applyStepSortOrder } from "store/manageSW/manageSWHelpers";

interface IStepListProps {
  sw: ISW,
  focusSWHeader(): void,
}

const StepList: React.FC<IStepListProps> = ({ sw, focusSWHeader }) => {

  let newSteps: IStep[] = [];
  const [updatedSteps, setUpdatedSteps] = useState<IStep[]>([]);

  // Get updated stepslist ignoring RC title 
  const removeRCTitlefromStepsList = (steps: IStep[], parentGuid: string | null) => {
    let newSteps = calculateRCTaskStepfromStepsList(steps, parentGuid);
    setUpdatedSteps(newSteps);
  }

  function calculateRCTaskStepfromStepsList(steps: IStep[], parentGuid: string | null): IStep[] {
    for (let i = 0; i < steps.length; i++) {
      let insertStep = { ...steps[i] };
      insertStep.children = [];

      //add in stepsList only if step number exists
      if (insertStep.stepNumber) {
        //if parent exists, add step as children to parent step
        if (parentGuid) {
          // find the parent step index
          let parentIndex = newSteps.findIndex(x => x.guid === parentGuid);

          //if parent not available in first lvl, check seconfd lvl
          if (parentIndex === -1) {
            for (let i = 0; i < newSteps.length; i++) {
              let newChildrenSteps = newSteps[i].children;
              for (let i = 0; i < newChildrenSteps.length; i++) {
                let newChildrenStep = newChildrenSteps[i];
                if (newChildrenStep.guid === parentGuid) {
                  newChildrenStep.children.push(insertStep);
                }
              }
            }
          }
          else if (parentIndex > -1) {
            newSteps[parentIndex].children.push(insertStep);
          }
        }
        // add first level step without parent
        else {
          newSteps.push(insertStep);
        }
      }

      //check if children exists
      if (steps[i].children.length > 0) {
        if (insertStep.stepNumber) {
          newSteps = calculateRCTaskStepfromStepsList(steps[i].children, insertStep.guid);
        }
        else {
          newSteps = calculateRCTaskStepfromStepsList(steps[i].children, insertStep.parentGuid);
        }
      }
    }

    return newSteps;
  }

  function newStepsTlm(steps: IStep[]): IStep[] {
    let result: IStep[] = [];
    let localSteps = cloneDeep(steps);
    for (let step of localSteps) {
      if (!step.isDisabled) {
        step.children = step.children.length ? newStepsTlm(step.children) : [];
        result.push(step);
      }
    }

    return result;
  }

  //render updated stepsList
  useEffect(() => {
    let steps: IStep[] = [];
    if (sw.type === SWTypes.TLMSWI 
      || sw.type === SWTypes.CL) {
      steps = newStepsTlm(sw.steps);
      steps = applyStepSortOrder(steps);
    }
    else {
      steps = sw.steps;
    }

    removeRCTitlefromStepsList(steps, null);
  }, [sw.steps, sw.type]);

  return (<ul>
    {
      updatedSteps.map(st =>
        <Step
          key={st.id}
          step={st}
          focusSWHeader={focusSWHeader}
        />
      )
    }
  </ul>
  );
}
export default StepList;