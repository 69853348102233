import React from "react";
import { IHistoricalApprovalResponse } from "interfaces/approvals/approvalInterfaces";
import "./Approvals.scoped.scss";
import formatDate from "utilities/formatDate";


interface IApprovalsProps {
  approvalHistories: IHistoricalApprovalResponse[] | undefined,
}

const Approvals: React.FC<IApprovalsProps> = ({ approvalHistories }) => {
  return (
    <>
      {
        approvalHistories?.map((history, index) =>
          <div className="flex-container" key={index}>
            <div>
              <label>Approval Level</label>
              <span>{history.level}</span>
            </div>
            <div>
              <label>{history.responseType === 'Revert' ? 'Reverted By' : 'Approved By'}</label>
              <span>{history.createdBy}</span>
            </div>
            <div>
              <label>{history.responseType === 'Revert' ? 'Reverted On' : 'Approved On'}</label>
              <span>{formatDate(history.createdOn, false, true, "short")}</span>
            </div>
            <div>
              <label>Comments</label>
              <span>{!history.comment ? "No Comments" : history.comment}</span>
            </div>
          </div>
        )
      }
    </>
  )
}
export default Approvals;