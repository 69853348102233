import { IProxyApprover } from "store/proxyApprovers/proxyApproversTypes";

export async function formatProxyApproverResponse(
  response: Response
): Promise<IProxyApprover | undefined> {
  const json = await response.json();

  if (!json.ProxyApprover) {
    return undefined;
  }

  return {
    proxyUserEmail: json.ProxyApprover.ProxyUserEmail,
    validUntil: new Date(json.ProxyApprover.ValidUntil),
  };
}

const getDateString = (s: Date) => {
  return `${s.getFullYear()}-${s.getMonth() + 1}-${s.getDate()}`;
};

export function formatProxyApproverRequest(request: IProxyApprover): {
  validUntil: string;
  proxyUserEmail: string;
} {
  const { validUntil, ..._request } = request;
  return { ..._request, validUntil: getDateString(validUntil) };
}
