import React, { useEffect, useRef, useState } from "react";
import ApprovalRow from "./ApprovalRow";
import "./MyApprovals.scoped.scss";
import ApprovalSort from './ApprovalSort';
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import { addFilterItem, removeFilterItem, applyFilter, clearFilter, setStringFilter, setSearchText, resetMyApprovals, setSort, setRCType } from "store/approvals/approvalsActions";
import SearchBar from "components/common/SearchBar";
import MasterDataInput from "components/sw/manage/attributes/MasterDataInput";
import FlowLayout from "components/layout/FlowLayout";
import { ApprovalSortFields, ApprovalTypes, IApprovalRequest, IBatchApprovalRequest, IRCUpdateApprovalRequest } from "interfaces/approvals/approvalInterfaces";
import { clearSearchedMasterDataItems } from "store/masterData/masterDataActions";
import { SortDirection } from "interfaces/misc/miscInterfaces";
import ApprovalTypeTab from "./ApprovalTypeTab";

const MyApprovals: React.FC = () => {
  const {
    approvals: {
      loadMyApprovalsOperation,
      myApprovals,
      filterData,
    },
    masterData: {
      approvalLevels,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  let sortDirSelectedCol = filterData.sortDir;
  let sortBySelectedCol = filterData.sortBy;
  const inputBoxRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputBoxRef.current){
        inputBoxRef.current.focus(); // set focus to search input box after rendering results
    }
  }, [myApprovals]);

  useEffect(() => {
    return () => {
      dispatch(resetMyApprovals());
    };
  }, [dispatch]);

  const [approvalTypeTab, setApprovalTypeTab] = useState(ApprovalTypes.SW);

  const numFilters = filterData.approvalFilterFields.approvalLevelsFilter.length ? 1 : 0;

  const sortByColumn = (sortBy: ApprovalSortFields) => {
    let sortDir;
    if (sortBySelectedCol !== sortBy) {
      sortDir = SortDirection.Asc;
    }
    else {
      if (sortDirSelectedCol === SortDirection.Asc) {
        sortDir = SortDirection.Desc;
      }
      else {
        sortDir = SortDirection.Asc;
      }
    }
    dispatch(
      setSort({
        sortBy: sortBy,
        sortDir: sortDir,
      })
    )
  }

  const onAddItem = (item: IMasterDataItem, name: MetaDataNames) => {
    dispatch(
      addFilterItem({
        metaDataName: name,
        item,
      })
    );
  }

  const onRemoveItem = (item: IMasterDataItem, name: MetaDataNames) => {
    dispatch(
      removeFilterItem({
        metaDataName: name,
        item,
      })
    );
  }

  const setApprovalStringFilter = (name: "maintenanceTaskIds", value: string) => {
    dispatch(setStringFilter({
      attributeName: name,
      value: value,
    }));
  }

  const applyDipatchFilter = () => {
    dispatch(applyFilter());
    dispatch(clearSearchedMasterDataItems());
  }

  let onSetSearchRef = useRef((value: string) => dispatch(setSearchText(value)));
  let component: JSX.Element | undefined;

  if (loadMyApprovalsOperation?.errorMessage) {
    component = (
      <label className="error">
        {loadMyApprovalsOperation.errorMessage}
      </label>
    );
  } else if (!myApprovals.swApprovals.length
    && !myApprovals.batchApprovals.length) {
    component = (
      <label>You have no active approvals that require your attention.</label>
    );
  } else {
    let allApprovals: (IApprovalRequest | IBatchApprovalRequest | IRCUpdateApprovalRequest)[] = [
      ...myApprovals.rcUpdateApprovals,
      ...myApprovals.batchApprovals,
      ...myApprovals.swApprovals,
    ];

    component = (
      <>
        <div className="row header">
          <div className="cell sw"
            onClick={() => sortByColumn(ApprovalSortFields.Title)}>
            Standard Work
            <ApprovalSort
              colName="Title"
            />
          </div>
          <div className="cell status"
            onClick={() => sortByColumn(ApprovalSortFields.ModifiedOn)}
          >
            Status
            <ApprovalSort
              colName="ModifiedOn"
            />
          </div>
          <div className="cell org"
            onClick={() => sortByColumn(ApprovalSortFields.Org)}
          >
            Org/Plant
            <ApprovalSort
              colName="Org"
            />
          </div>
        </div>
        <div className="list-body">
          {allApprovals.filter(x => x.type === approvalTypeTab).map(app =>
            <ApprovalRow
              approval={app}
              key={app.id + app.createdOn.toString()}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <FlowLayout
      header={(
        <>
          <h2 className="page-title">
            <ApprovalTypeTab
              label="Active SW Approvals"
              isActive={approvalTypeTab === ApprovalTypes.SW}
              onClick={() => setApprovalTypeTab(ApprovalTypes.SW)}
            />
            <ApprovalTypeTab
              label="Active Metadata Approvals"
              isActive={approvalTypeTab === ApprovalTypes.Batch}
              onClick={() => setApprovalTypeTab(ApprovalTypes.Batch)}
            />
            <ApprovalTypeTab
              label="Active RC Approvals"
              isActive={approvalTypeTab === ApprovalTypes.RCUpdate}
              onClick={() => setApprovalTypeTab(ApprovalTypes.RCUpdate)}
            />
          </h2>
          <SearchBar
            swFilterFields={filterData.swFilterFields}
            isDisabled={loadMyApprovalsOperation?.isWorking === true}
            setSearchText={onSetSearchRef.current}
            onAddSWItem={onAddItem}
            onRemoveSWItem={onRemoveItem}
            applyFilter={applyDipatchFilter}
            clearFilter={() => dispatch(clearFilter())}
            setSWStringFilter={setApprovalStringFilter}
            extraFilterCount={numFilters}
            extraFilterControls={
              <MasterDataInput
                label="Approval Levels"
                isMandatory={false}
                selectedItems={filterData.approvalFilterFields.approvalLevelsFilter}
                allowMultiple={true}
                onAddItem={item => onAddItem(item, "approvalLevels")}
                onRemoveItem={item => onRemoveItem(item, "approvalLevels")}
                loadItems={() => { }}
                masterDataSection={approvalLevels}
                itemFormatter={item => item.value}
              />
            }
            showStatusFilter={true}
            placeholder="Search by title, description, or guid..."
            setRCType={(value) => dispatch(setRCType(value))}
            inputBoxRef={inputBoxRef}
          />
        </>
      )}
    >
      {component}
    </FlowLayout>
  );
}

export default MyApprovals;