import React from "react";
import Modal from "components/common/Modal";
import "./MFGAddConnectedSWIModal.scoped.scss";
import MasterDataInput from "../attributes/MasterDataInput";
import { IMasterDataItem } from "../../../../interfaces/masterData/masterDataInterfaces";
import {
  addConnectedSWI,
  setConnectedSWIGroup,
  setConnectedSWIGroupCounter,
  setConnectedSWIMaterial,
  uploadConnectedSWIs
} from "../../../../store/manageSW/manageSWActions";
import { loadGroupCounter, loadGroups, searchMaterials } from "../../../../store/masterData/masterDataActions";
import useSelector from "../../../../store/useSelector";
import { useDispatch } from "react-redux";
import ModalSpinner from "../../../common/ModalSpinner";
import { getFileSizeError } from "utilities/fileUtilities";
import { showErrorToast } from "store/toast/toastActions";
import { SWAttachmentTypes } from "store/manageSW/manageSWTypes";

interface IMFGChildMaterialsModalProps {
  onClose: (itemAdded: boolean) => void
}

const MFGAddConnectedSWIModal: React.FC<IMFGChildMaterialsModalProps> = ({
  onClose
}) => {
  const dispatch = useDispatch();
  const {
    manageSW: {
      SW,
      connectedSWIData : {
        addOperation,
        draft
      }
    },
    masterData: {
      materials,
      groups,
      groupCounter
    }
  } = useSelector(store => store);

  const onRemoveMaterial = (item: IMasterDataItem) => {
    dispatch(setConnectedSWIGroup(undefined));
    dispatch(setConnectedSWIGroupCounter(undefined));
    dispatch(loadGroups({ plantCode: SW.owningPlant?.code, materialCode: undefined, plantSource:SW.owningPlant?.source }));
    dispatch(
      loadGroupCounter({
        plantCode: SW.owningPlant?.code,
        materialCode: undefined,
        group: undefined,
        plantSource: SW.owningPlant?.source
      })
    );
    dispatch(setConnectedSWIMaterial(undefined));
  }

  const onRemoveGroup = () => {
    dispatch(setConnectedSWIGroup(undefined));
    dispatch(setConnectedSWIGroupCounter(undefined));
    dispatch(
      loadGroupCounter({
        plantCode: SW.owningPlant?.code,
        materialCode: draft?.material?.code,
        group: undefined,
        plantSource: SW.owningPlant?.source,
      })
    );
  };
  
  const onAddConnectedChildSWI = () => {
    dispatch(addConnectedSWI({
      swGuid: SW.guid,
      swVersion: SW.version,
      materialCode: draft.material?.code ?? "",
      group: draft.group?.code ?? "",
      groupCounter: draft.groupCounter?.code ?? "",
      plantCode: SW.owningPlant?.code ?? "",
      plantSource: SW.plantSource ?? ""
    }));
  };

  
  const onFileChosen = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length !== 1) {
      return;
    }

    const file = event.target.files[0];

    const fileSizeError = getFileSizeError(file);
    if (fileSizeError) {
      dispatch(showErrorToast(fileSizeError));
      return;
    }

    dispatch(uploadConnectedSWIs({
      swGuid: SW.guid,
      swVersion: SW.version,
      file,
      type: SWAttachmentTypes.RefDoc,
    }));

    onClose(false);
  }
  
  return (
    <Modal
      isOpen={true}
      header="Add Connected Child SWI"
      controls={(
        <>
          <label className="custom-file-upload">
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => onFileChosen(e)}
            />
            Upload Excel Template
          </label>
          <button
            className="primary-button"
            onClick={() => onAddConnectedChildSWI()}
          >
            Add
          </button>
          <button
            className="secondary-button"
            onClick={() => onClose(false)}
          >
            Cancel
          </button>
        </>
      )}
    >
      <div className="form">
        <div className="input-container">
          <MasterDataInput
            label="Material"
            isMandatory={true}
            isAsyncSearch={true}
            disabled={false}
            selectedItems={draft.material ? [draft.material] : []}
            allowMultiple={false}
            onAddItem={(item: IMasterDataItem) => dispatch(setConnectedSWIMaterial(item))}
            onRemoveItem={(item: IMasterDataItem) => {onRemoveMaterial(item)}}
            loadItems={(searchTerm: string) => dispatch(searchMaterials({
              searchTerm: searchTerm,
              plantCode: SW.owningPlant?.code ? SW.owningPlant?.code : "",
              plantSource: SW.owningPlant?.source ? SW.owningPlant?.source : undefined,
              onlyActive: true
            }))}
            masterDataSection={materials}
            itemFormatter={(item: IMasterDataItem) => (
              <span>
            {item.code}{"-"}{item.value}
        </span>
            )}
          />
        </div>
        <div className="input-container">
          <MasterDataInput
            label="Group"
            isMandatory={true}
            disabled={false}
            selectedItems={draft.group ? [draft.group] : []}
            allowMultiple={false}
            onAddItem={(item: IMasterDataItem) => dispatch(setConnectedSWIGroup(item))}
            onRemoveItem={() => onRemoveGroup()}
            loadItems={() => dispatch(
              loadGroups({
                plantCode: SW.owningPlant?.code,
                materialCode: draft.material?.code,
                plantSource: SW.owningPlant?.source
              }))}
            masterDataSection={groups}
            itemFormatter={(item: IMasterDataItem) => (
              <span>
            {item.value}
        </span>
            )}
            isAsyncSearch={false}
            showNoDataMessage={true}
            showNoRoutingDataMsg={true}
          />
        </div>
        <div className="input-container">
          <MasterDataInput
            label="Group Counter"
            isMandatory={true}
            disabled={false}
            selectedItems={draft.groupCounter ? [draft.groupCounter] : []}
            allowMultiple={false}
            onAddItem={(item: IMasterDataItem) => dispatch(setConnectedSWIGroupCounter(item))}
            onRemoveItem={() => dispatch(setConnectedSWIGroupCounter(undefined))}
            loadItems={() =>
              dispatch(
                loadGroupCounter({
                  plantCode: SW.owningPlant?.code,
                  materialCode: draft.material?.code,
                  group: draft.group?.code,
                  plantSource: SW.owningPlant?.source,
                })
              )
            }
            masterDataSection={groupCounter}
            itemFormatter={(item: IMasterDataItem) => (
              <span>
            {item.value}
        </span>
            )}
            isAsyncSearch={false}
            showNoDataMessage={true}
            showNoRoutingDataMsg={true}
          />
        </div>
      </div>
      {addOperation?.isWorking && 
        <ModalSpinner/>
      }
    </Modal>
  );
}

export default MFGAddConnectedSWIModal;