import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom"
import Modal from "components/common/Modal";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import {
  setAddUserSearchText,
  toggleAddUserModal,
  setAddUserModalData,
  createUser,
  resetAddUserModalData, 
  setRoleAssignmentUsers, 
  resetRoleAssignmentsData
} from "store/userManagement/userManagementActions";
import BlockSpinner from "components/common/BlockSpinner";
import "./AddUserModal.scoped.scss";
import addIcon from "media/icons/dls/add.svg";
import { IAADUser } from "interfaces/user/UserInterfaces";
import useDebounce from "utilities/useDebounce";
import { Routes } from "../../routing/Routing";

const AddUserModal: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 750);
  const addUserState = useSelector(store => store.userManagement.addUserModalData);
  const dispatch = useDispatch();
  const history = useHistory();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    dispatch(setAddUserModalData({
      isOpen: true,
      loadOperation: undefined,
      createOperation: undefined,
      searchResults: [],
      searchText: "",
      addedUsers: []
    }));

    return () => {
      dispatch(resetAddUserModalData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setAddUserSearchText(debouncedSearchTerm.trim()));
  }, [debouncedSearchTerm, dispatch]);
  
  const addUser = (user: IAADUser) => {
    dispatch(createUser({
      user,
      refreshManageUsersList: true,
    }));
  }
    
  const navigateToUserRoleAssigment = () => {
    dispatch(resetRoleAssignmentsData())
    dispatch(setRoleAssignmentUsers(addUserState.addedUsers));
    history.push(Routes.NewUserRoleAssignments);
  }

  let results: JSX.Element | undefined;

  if (addUserState.loadOperation?.isWorking
    || addUserState.createOperation?.isWorking) {
    results = <BlockSpinner />;
  } else if (addUserState.loadOperation?.errorMessage) {
    results = <label className="error">{addUserState.loadOperation?.errorMessage}</label>;
  } else if (!addUserState.searchResults.length) {
    results = <label>No users found.</label>
  } else {
    results = (
      <>
        {addUserState.searchResults.map(r =>
          <div
            className="user"
            key={r.email}
          >
            <span>
              {r.name} ({r.email})
            </span>
            <img
              src={addIcon}
              className="add-button icon-medium hover-gray-bg"
              onClick={() => addUser(r)}
              alt="Add"
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Modal
        isOpen={true}
        header="Add User(s)"
        controls={(
          <>
            <button
              className="secondary-button"
              onClick={() => dispatch(toggleAddUserModal(false))}
              disabled={addUserState.loadOperation?.isWorking
                || addUserState.createOperation?.isWorking}
            >
              Done
            </button>
            <button
              className="primary-button"
              onClick={() => navigateToUserRoleAssigment() }
              disabled={addUserState.loadOperation?.isWorking
                || addUserState.createOperation?.isWorking || (!(addUserState.addedUsers) && addUserState.addedUsers == 0)}
            >
              Assign Roles
            </button>
          </>
        )}
      >
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={addUserState.loadOperation?.isWorking
            || addUserState.createOperation?.isWorking}
          placeholder="Search by name or email..."
          ref={inputRef}
        />
        <div
          className="results"
        >
          {results}
        </div>
        <div>
          <label>Added users:</label>
          {addUserState.addedUsers.length == 0 &&
            <p className="form-instructions">No users added. To add users, first search and add one or more users.</p>
          }
          <div className="added-users-list">
            {addUserState.addedUsers.map(r =>
              <div
                className="user"
                key={r.email}>
                <span>
                  {r.name} ({r.email})
                </span>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddUserModal;