import React from "react";
import { ILinkComponent } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import "./ComponentEditor.scss";
import { updateLink } from "store/manageSW/manageSWActions";
import "./LinkEditor.scoped.scss";

interface ILinkEditorProps {
  linkComponent: ILinkComponent,
  allowEdit: boolean,
  stepGuid: string,
}

const LinkEditor: React.FC<ILinkEditorProps> = ({
  linkComponent,
  allowEdit,
  stepGuid,
}) => {
  const dispatch = useDispatch();

  const updateUrl = (e: React.FocusEvent<HTMLInputElement>) => {
    dispatch(updateLink({
      stepGuid,
      component: {
        ...linkComponent,
        url: e.target.value,
      },
    }));
  }

  let component: JSX.Element | undefined;

  if (allowEdit) {
    component = (
      <>
        <label className="mandatory block">Label
          <span className="compliancelbl"> (Link should be in compliance with SLB security policy)</span>
        </label>

        <input
          type="text"
          defaultValue={linkComponent.label}
          onBlur={(e) => dispatch(updateLink({
            stepGuid,
            component: {
              ...linkComponent,
              label: e.target.value,
            },
          }))}
        />
        <label>
          URL
        </label>
        <input
          type="text"
          defaultValue={linkComponent.url}
          onBlur={updateUrl}
        />
        {!!linkComponent.url.trim()
          && !!linkComponent.label?.trim() &&
          <>
            <label className="block">Link Preview</label>
            <span>
              <a
                href={linkComponent.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkComponent.label}
              </a>
            </span>
          </>
        }
      </>
    );
  } else {
    component = (
      <>
        {!linkComponent.label?.trim() &&
          <span
            className="error"
          >
            No label specified!
          </span>
        }
        {!linkComponent.url.trim() &&
          <span
            className="error"
          >
            No url specified!
          </span>
        }
        {!!linkComponent.url.trim()
          && !!linkComponent.label?.trim() &&
          <span>
            <a
              href={linkComponent.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkComponent.label}
            </a>
          </span>
        }
      </>
    );
  }

  return (
    <div className="component-editor">
      {component}
    </div>
  );
}

export default LinkEditor;