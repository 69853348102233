import React, { useEffect, useState } from "react";
import useSelector from "../../store/useSelector";
import "./ProcessTimeRCModal.scoped.scss";
import loaderGif from "media/gifs/loader.gif";
import { useDispatch } from "react-redux";
import { getTimeRCProcessingRequestStatus } from "store/swList/swListActions";

const ProcessTimeRCModal: React.FC = () => {
    const { timeRCRequestData } = useSelector(store => store.swList);
    const [isClose, setIsClose] = useState(false);
    const dispatch = useDispatch();

    const getStatus = () => {
        let requestIds: string = "";
        timeRCRequestData.forEach(element => {
            requestIds = requestIds + element.requestId + ",";
        });
        // remove , character
        requestIds = requestIds.slice(0, -1);
        dispatch(getTimeRCProcessingRequestStatus({
            requestIds: requestIds
        }));
    }

    useEffect(() => {
        if ((timeRCRequestData.length > 0) && !isClose) {
            const timer = setInterval(getStatus, 15000);
            return () => clearInterval(timer);
        }
    }, [timeRCRequestData, isClose])

    const closeModal = () => {
        setIsClose(true);
    }

    return (
        <>
            {
                ((timeRCRequestData.length > 0) && !isClose) &&
                <div className="modal">
                    <div className="modal-content">
                        <img className="modal-loading-img" src={loaderGif} alt="loading..." />
                        <div className="modal-data">
                            <div className="modal-header"><b>Updating TIME RC data in SW ..</b></div>
                            <p className="modal-body">You can still browse website while we update the TIME RC data in SW</p>
                        </div>
                        <span className="close" onClick={closeModal}>&times;</span>
                    </div>
                </div>
            }
        </>
    );
};
export default ProcessTimeRCModal;