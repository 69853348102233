import React, { FC, FormEventHandler, useEffect, useState } from "react";

import { IProxyApprover } from "store/proxyApprovers/proxyApproversTypes";
import "./ProxySettings.scoped.scss";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { loadProxyApprover } from "store/proxyApprovers/proxyApproversActions";
import { getUtcDateString } from "utilities/formatDate";

interface IProxySettingsProps {
  onSubmitApprover(proxyApprover: IProxyApprover): void;
  onDeleteApprover?(): void;
  proxyApprover: IProxyApprover | undefined;
  disabled?: boolean;
}

const ProxySettings: FC<IProxySettingsProps> = function ({
  onSubmitApprover,
  onDeleteApprover,
  proxyApprover,
  disabled,
}) {
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProxyApprover());
  }, []);

  useEffect(() => {
    setShowForm(false);
  }, [proxyApprover]);

  const [newApprover, setNewApprover] = useState<IProxyApprover>({
    proxyUserEmail: "",
    validUntil: new Date(),
  });

  const _onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    onSubmitApprover(newApprover);
  };

  if (proxyApprover) {
    return (
      <div className={disabled ? "disabled" : ""}>
        <p>
          {proxyApprover.proxyUserEmail} is set as your proxy approver until{" "}
          {getUtcDateString(proxyApprover.validUntil)}
        </p>
        <p>
          <button
            className="secondary-button margin-right"
            type="button"
            onClick={onDeleteApprover}
          >
            Revoke Proxy Permissions
          </button>
        </p>
      </div>
    );
  }

  if (showForm) {
    return (
      <form onSubmit={_onSubmit} className={disabled ? "disabled" : ""}>
        <div className="form-row">
          <label>Proxy Approver Email</label>
          <input
            type="email"
            value={newApprover.proxyUserEmail}
            onChange={(e) =>
              setNewApprover({
                ...newApprover,
                proxyUserEmail: e.target.value,
              })
            }
            required
            disabled={disabled}
          />
        </div>
        <div className="form-row">
          <label>Valid Until</label>
          <DatePicker
            selected={newApprover.validUntil}
            onChange={(v) =>
              setNewApprover({ ...newApprover, validUntil: v || new Date() })
            }
            disabled={disabled}
          />
        </div>
        <div className="form-row justify-end">
          <button
            className="secondary-button margin-right"
            type="button"
            onClick={() => setShowForm(false)}
            disabled={disabled}
          >
            Cancel
          </button>
          <button className="primary-button" type="submit" disabled={disabled}>
            Set Proxy
          </button>
        </div>
      </form>
    );
  } else {
    return (
      <p>
        <button className="secondary-button" onClick={() => setShowForm(true)}>
          Set Proxy Approver
        </button>
      </p>
    );
  }
};

export default ProxySettings;
