import React from "react";
import downArrowIcon from "media/icons/dls/arrow-down-2.svg";
import upArrowIcon from "media/icons/dls/arrow-up-2.svg";
import "./CommonSort.scoped.scss";

interface ICommonSort {
    filterData: any,
    colName: string,
}

const CommonSort: React.FC<ICommonSort> = (props) => {
    let sortDirSelectedCol = props.filterData.sortDir;
    let sortBySelectedCol = props.filterData.sortBy;

    return (
        <>
            {
                sortBySelectedCol === props.colName &&
                    sortDirSelectedCol === "Asc"
                    ?
                    <button
                        className={sortBySelectedCol === props.colName ? "active" : "hide"}
                    ><img
                            src={upArrowIcon}
                            alt=""
                            className="icon-extra-small-sort"
                        />
                    </button>
                    :
                    <button className={sortBySelectedCol === props.colName ? "active" : "hide"}
                    >
                        <img
                            src={downArrowIcon}
                            alt=""
                            className="icon-extra-small-sort"
                        />
                    </button>
            }
        </>
    )
}

export default CommonSort;