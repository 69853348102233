import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ModalSpinner from "./ModalSpinner";
import Modal from "./Modal";
import "./TCCModal.scoped.scss";
import useSelector from "../../store/useSelector";
import {TCCComplianceStatuses} from "../../interfaces/user/UserInterfaces";
import { confirmTCC } from "store/auth/authActions";

const TCCModal: React.FC = () => {
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const tccInfo = useSelector(store => store.auth.currentUser.tccInfo);
  const dispatch = useDispatch();

  if (!tccInfo
    || tccInfo.status === TCCComplianceStatuses.Compliant) {
    return null;
  }

  const {
    tccId,
    countries,
    loadOperation,
  } = tccInfo;

  const certifyAnswer = (status: TCCComplianceStatuses) => {
    dispatch(
      confirmTCC({
        tccId,
        status,
      }));
  }

  return (
    <Modal
      header="Trade &amp; Customs Compliance"
      isOpen={true}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={() => {
              certifyAnswer(TCCComplianceStatuses.NotCompliant);
              setIsRejectModalVisible(true);
            }}
          >
            {"I do not Certify"}
          </button>
          <button
            className="primary-button"
            onClick={() => {
              certifyAnswer(TCCComplianceStatuses.Compliant);
            }}
          >
            {"I Certify"}
          </button>
        </>
      }
    >
      <div className="content">
        <h3>
          {"USER AGREEMENT - CERTIFICATION"}
        </h3>
        <div>
          {"PLEASE CERTIFY THAT YOU WILL NOT BE ACCESSING ANY "}
          {"INFORMATION OR DOWNLOADING ANY MATERIAL FROM WORKRIGHT "}
          {"FOR SUPPORT OF OPERATIONS IN:"}
        </div>
        <ul>
          {countries.map((country, ix) =>
            <li
              key={ix}
            >
              {country.name}
              {country.desc ? ' - ' : ''}
              {country.desc}
            </li>)
          }
        </ul>
        {isRejectModalVisible &&
          <Modal
            header={"Not Authorized"}
            isOpen={true}
            controls={
              <button
                className="primary-button"
                onClick={() => {
                  setIsRejectModalVisible(false);
                }}
              >
                {"Recertify My TCC Status"}
              </button>
            }
          >
            {"You are not authorized to use WorkRight."}
          </Modal>
        }
      </div>
      {loadOperation?.isWorking &&
        <ModalSpinner />
      }
    </Modal>
  );
};

export default TCCModal;