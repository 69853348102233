import React from "react";
import { AuthFilter, AuthCheck } from "auth/AuthFilter";
import { Route, RouteChildrenProps, RouteComponentProps } from "react-router-dom";

interface IAuthRouteProps {
  authFilter: AuthFilter,
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
  children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode,
  path?: string | string[],
  exact?: boolean,
  sensitive?: boolean,
  strict?: boolean,
}

const AuthRoute: React.FC<IAuthRouteProps> = ({
  authFilter,
  children,
  component,
  path,
  exact,
  sensitive,
  strict,
}) =>
  <Route
    path={path}
    exact={exact}
    sensitive={sensitive}
    strict={strict}
    render={() =>
      <AuthCheck
        authFilter={authFilter}
        show403OnFail={true}
      >
        {children}
        {component !== undefined &&
          React.createElement(component)
        }
      </AuthCheck>
    }
  />;

export default AuthRoute;