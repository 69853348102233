import React from "react";
import { ExecutionViews } from "./Execution";
import ExecutionToolbarButton from "./ExecutionToolbarButton";
import checklistIcon from "media/icons/dls/checklist.svg";
import documentsIcon from "media/icons/dls/documents.svg";
import useSelector from "store/useSelector";
import { SWTypes } from "interfaces/sw/SWInterfaces";

interface IExecutionToolbarProps {
  currentView: ExecutionViews,
  onViewButtonClick(view: ExecutionViews): void,
}

const ExecutionToolbar: React.FC<IExecutionToolbarProps> = ({ currentView, onViewButtonClick }) => {
  const {
    manageSW: {
      SW,
    }
  } = useSelector(store => store);

  return (
    <>
      <ExecutionToolbarButton
        onClick={() => onViewButtonClick(ExecutionViews.Steps)}
        iconUrl={checklistIcon}
        text={SW.type === SWTypes.TLMSWI || SW.type === SWTypes.MFGSWI || SW.type === SWTypes.TLMRC ? "Tasks" : "Steps"}
        isActive={currentView === ExecutionViews.Steps}
      />
      <ExecutionToolbarButton
        onClick={() => onViewButtonClick(ExecutionViews.RefDocs)}
        iconUrl={documentsIcon}
        text={"Ref Docs"}
        isActive={currentView === ExecutionViews.RefDocs}
      />
    </>);
}

export default ExecutionToolbar;