import React, { useState } from "react";
import { IDateInputComponent, IDateTimeInputComponent, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IDateInputProps {
  component: IDateInputComponent | IDateTimeInputComponent,
}

const DateInput: React.FC<IDateInputProps> = ({ component }) => {
  const [selectedDate, setselectedDate] = useState(new Date())

  const onDateValueChange = (value: Date | null) => {
    if (value === null) {
      setselectedDate(new Date());
    }
    else {
      setselectedDate(value);
    }
  }

  return (
    <div>
      <label>{component.label}</label>
      <DatePicker
        id={component.id?.toString()}
        selected={selectedDate}
        onChange={onDateValueChange}
        inline
        showTimeSelect={component.type === StepComponentTypes.DateTimeInput}
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
}

export default DateInput;