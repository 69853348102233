import React from "react";
import "./ImagePreview.scoped.css";

interface IImagePreviewProps {
  filename: string,
  dataUri: string,
  onRemove(): void,
  disabled: boolean,
}

const ImagePreview: React.FC<IImagePreviewProps> = ({ filename, dataUri, onRemove, disabled }) => {

  return (
    <div className="img-holder">
      {
        <img
          src={dataUri}
          alt={filename}
          className="selected-image" />
      }
      {!disabled &&
        <span
          onClick={onRemove}
          className="remove-img"
        >
          x
        </span>
      }
    </div>
  );
}

export default ImagePreview;