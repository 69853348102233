import React from "react";
import { useDispatch } from "react-redux";
import Modal from "components/common/Modal";
import { cancelArchive, confirmArchive } from "store/swList/swListActions";

interface IArchiveModal {
  swGuid: string,
}

const ArchiveModal: React.FC<IArchiveModal> = (props) => {
  const dispatch = useDispatch();

  return (
    <Modal
      header="Archive Standard Work"
      controls={(
        <>
          <button
            className="primary-button"
            onClick={() => dispatch(confirmArchive({
              swGuid: props.swGuid,
            }))}
          >
            Archive
            </button>
          <button
            className="secondary-button"
            onClick={() => dispatch(cancelArchive())}
          >
            Cancel
            </button>
        </>
      )}
      isOpen={true}
    >
      <p>
        Are you sure you want to archive this standard work?
        This will archive all versions of the standard work in the system.
        The standard work will be removed from the website and it will no longer be able to be updated.
      </p>
    </Modal>
  );
};

export default ArchiveModal;