import React from "react";
import { INoticeComponent, SWTypes } from "interfaces/sw/SWInterfaces";
import StepComponent from "components/sw/manage/steps/components/StepComponent";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";

interface INoticesProps {
    notices: INoticeComponent[],
    swGuid: string,
    swType: SWTypes,
    swVersion: number,
    imageDataDestination: ImageDataDestinations,
}

const Notices: React.FC<INoticesProps> = ({ notices, swGuid, swVersion, swType, imageDataDestination, }) => {
    return (
        <>
            {notices
                .slice()
                .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1)
                .map(notice =>
                    <StepComponent
                        key={notice.guid}
                        component={notice}
                        showWrapper={false}
                        isDisabled={true}
                        swGuid={swGuid}
                        isDraftPage={false}
                        swVersion={swVersion}
                        imageDataDestination={imageDataDestination}
                        swType={swType}
                        hideRCNoticeControls={true}
                    />
                )}
        </>
    )
}

export default Notices;