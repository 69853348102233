import { IPassFailInputComponent, SWTypes } from "interfaces/sw/SWInterfaces";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updatePassFailInput } from "store/manageSW/manageSWActions";
import useSelector from "store/useSelector";
import { loadMeters } from "store/masterData/masterDataActions";
import SelectMeter from "./SelectMeter"; 
import "./PassFailinputEditor.scoped.scss";
interface IPassFailInputEditorProps {
    passFailInput: IPassFailInputComponent,
    allowEdit: boolean,
    stepGuid: string,
    componentTypeLabel: string,
    swType: SWTypes,
    stepLocation?: string,
}
const PassFailInputEditor: React.FC<IPassFailInputEditorProps> = ({
    passFailInput,
    allowEdit,
    stepGuid,
    componentTypeLabel,
    swType,
    stepLocation
}) => {
const {
    masterData: {
      meters,
    },
    manageSW: {
      enableMetersValue,
      SW: {
      epicEquipment,
      }
      },   
    } = useSelector(store => store);
    
    useEffect(() => {
    if (epicEquipment.length > 0) {
      dispatch(loadMeters({ onlyActive: true, equipmentCodes: epicEquipment, location: "1:Global" }));
    }
    }, [epicEquipment]);

    const filterMeters = stepLocation == undefined
      || stepLocation == null
      || stepLocation === ""
      || stepLocation === "1:Global"
      ? meters.items
      : meters.items.filter(t => t.parentGuid === stepLocation);

  const dispatch = useDispatch();
  const nonConformInstructionRef = useRef<HTMLInputElement>(null);
  const isTLM_MFG_Type = (swType === SWTypes.TLMSWI
      || swType === SWTypes.TLMRC
      || swType === SWTypes.MFGCL
      || swType === SWTypes.MFGRC
      || swType === SWTypes.MFGSWI);
    
    const onLabelBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        dispatch(updatePassFailInput({
            component: {
                ...passFailInput,
                label: event.target.value,
            },
            stepGuid: stepGuid,
        }))
    }

    const onExpectedValueChange = (event: React.FocusEvent<HTMLSelectElement>) => {
        let isNonConformValueReset = false;
        if (isTLM_MFG_Type
          && (event.target.value === undefined
          || event.target.value === "")) {
            const nonConformInstruction = nonConformInstructionRef.current;
            if (nonConformInstruction) {
              nonConformInstruction.value = "";
              isNonConformValueReset = true;
            }
        }

        dispatch(updatePassFailInput({
            component: {
                ...passFailInput,
                expectedValue: event.target.value,
                nonConform: isNonConformValueReset
                ? ""
                : passFailInput.nonConform,
            },
            stepGuid: stepGuid,
        }))
    }

    const onNonConformBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        dispatch(updatePassFailInput({
            component: {
                ...passFailInput,
                nonConform: event.target.value,
            },
            stepGuid: stepGuid
        }))
    }

    const shouldConformInstructionDisabled = () => {
        return (
            isTLM_MFG_Type &&
            (passFailInput.expectedValue === undefined ||
            passFailInput.expectedValue === "")
        );
    }

    return (
        <div className="component-editor">
            <div className="row">
                <label className="mandatory block">{componentTypeLabel}</label>
                {((enableMetersValue)
                    && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC
                        || swType === SWTypes.MFGCL
                        || swType === SWTypes.MFGRC
                        || swType === SWTypes.MFGSWI))
                    &&
                   <label className="meter">Meter</label>}
            </div>
            <div className="row">
                {allowEdit 
                  && <>
                       <input
                         type="text"
                         defaultValue={passFailInput.label}
                         onBlur={onLabelBlur}
                       />
                       {((enableMetersValue)
                         && (swType === SWTypes.TLMSWI
                            || swType === SWTypes.TLMRC
                            || swType === SWTypes.MFGCL
                            || swType === SWTypes.MFGRC
                            || swType === SWTypes.MFGSWI))
                         && <div className="meter">
                              <SelectMeter
                                inputComponent={passFailInput}
                                stepGuid={stepGuid}
                                meters={filterMeters} />
                            </div>
                         }
                      </>
                }
                {!allowEdit
                    && <>
                        <span>{passFailInput.label}</span>
                        {(enableMetersValue)
                        && (swType === SWTypes.TLMSWI
                        || swType === SWTypes.TLMRC
                        || swType === SWTypes.MFGCL
                        || swType === SWTypes.MFGRC
                        || swType === SWTypes.MFGSWI)
                        &&
                        <span className="meter">{passFailInput.meterCode}</span>
                        }
                    </>
                }
            </div>
            <div className="component-editor">
                  <fieldset>
                    <legend>Conformance Options (optional)</legend>
                    <div className="row">
                        <label>Expected Value</label>
                    </div>
                    <div className="row component-editor">
                        {allowEdit &&
                            <select
                                onChange={onExpectedValueChange}
                                defaultValue={passFailInput.expectedValue}>
                                <option key="none">

                                </option>
                                <option key="pass">
                                    Pass
                                </option>
                                <option key="fail">
                                    Fail
                                </option>
                            </select>
                        }
                        {!allowEdit &&
                            <span className="expected-value">{passFailInput.expectedValue}</span>}
                    </div>
                    <div className="row">
                        <label>Non-Conformance Instructions</label>
                    </div>
                    <div className="row component-editor">
                        {allowEdit &&
                            <input
                                ref={nonConformInstructionRef} 
                                type="text"
                                defaultValue={passFailInput.nonConform || ""}
                                onBlur={onNonConformBlur}
                                disabled={shouldConformInstructionDisabled()}
                            />
                        }
                        {!allowEdit &&
                            <span className="expected-value">{passFailInput.nonConform}</span>}
                    </div>
                </fieldset>
            </div>
        </div>
    );
}

export default PassFailInputEditor;