import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "components/common/Modal";
import UserGroupIcon from "components/user/management/UserGroupIcon";
import { DataFeedbackPriorityTypes } from "interfaces/feedbacks/feedbackInterfaces";
import { UserGroups } from "interfaces/user/UserInterfaces";
import {
  saveFeedback,
  setDescription,
  setTitle,
  setPriority,
  resetFeedback,
  setGroup,
  setInitializeProccess,
} from "store/feedback/feedbackActions";
import useSelector from "store/useSelector";
import FeedbackDocuments from "./FeedbackDocuments";
import "./FeedbackSaveModal.scoped.scss";

interface ManageModalFeedback {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const userGroupDescriptions: Record<string, string> = {
  [UserGroups.PSD]: "PSD",
  [UserGroups.TLM]: "TLM",
  [UserGroups.MFG]: "MFG",
};

const FeedbackSaeModal: React.FC<ManageModalFeedback> = ({
  isOpen,
  onClose,
  title,
}) => {
  // Declarations
  const dispatch = useDispatch();
  const { saveOperation, feedback } = useSelector((store) => store.feedback);
  const [enabledSave, setEnabledSave] = useState(false);

  //Methods-events handler
  const save = () => {
    dispatch(setInitializeProccess());
    dispatch(saveFeedback(feedback));
  };

  useEffect(() => {
    if (saveOperation.wasSuccessful == true) {
      onClose();
    }
  }, [dispatch, saveOperation.wasSuccessful]);

  const closeModal = () => {
    dispatch(resetFeedback());
    onClose();
  };

  useEffect(() => {
    if (saveOperation.isWorking) {
      setEnabledSave(false);
    }
    if (
      feedback.description.trim().length > 0 &&
      feedback.title.trim().length > 0
    ) {
      setEnabledSave(true);
    } else {
      setEnabledSave(false);
    }
  }, [
    dispatch,
    feedback.title,
    feedback.group,
    feedback.description,
    saveOperation.isWorking,
  ]);

  // Render componentes
  const renderTitle = () => {
    return (
      <>
        <label className="mandatory">Title</label>
        <input
          type="text"
          value={feedback.title}
          maxLength={500}
          onChange={(e) => dispatch(setTitle(e.target.value))}
        />
      </>
    );
  };

  const renderDescription = () => {
    return (
      <>
        <label className="mandatory">Description</label>
        <input
          type="text"
          value={feedback.description}
          onChange={(e) => dispatch(setDescription(e.target.value))}
        />
      </>
    );
  };

  const renderPriority = () => {
    return (
      <>
        <label className="mandatory">Priority</label>
        <select
          value={feedback.priority}
          onChange={(e) =>
            dispatch(setPriority(e.target.value as DataFeedbackPriorityTypes))
          }
        >
          {Object.keys(DataFeedbackPriorityTypes).map((t) => (
            <option key={t}>{t}</option>
          ))}
        </select>
      </>
    );
  };

  const renderGroups = () => {
    return (
      <fieldset className="roles">
        <legend className="mandatory">Group Name</legend>
        <div className="userGroup-container">
          {Object.values(UserGroups).map((r) => (
            <div
              key={"div-" + r}
              className={`icon ${feedback.group === r ? "selected" : ""}`}
              title={userGroupDescriptions[r]}
              onClick={() => dispatch(setGroup(userGroupDescriptions[r]))}
            >
              <UserGroupIcon key={r} userGroup={r} />
            </div>
          ))}
        </div>
      </fieldset>
    );
  };
  const renderUploadDocuments = () => {
    return (
      <>
        <label>Documents</label>
        {feedback.documents.map((doc, index) => (
          <div className="document-feedback-wrapper" key={doc.guid}>
            <FeedbackDocuments document={doc} index={index} />
          </div>
        ))}
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      width={"20em"}
      header={title}
      maxHeight={"40em"}
      controls={
        <div>
          <button className="secondary-button" onClick={closeModal}>
            Cancel
          </button>
          <button
            className="primary-button"
            onClick={save}
            disabled={!enabledSave || saveOperation.isWorking}
          >
            Save
          </button>
        </div>
      }
    >
      <legend className="message-modal">
        Use this option to log any Enhancements to the Authoring application
      </legend>
      {renderGroups()}
      {renderTitle()}
      {renderDescription()}
      {renderPriority()}
      {renderUploadDocuments()}
    </Modal>
  );
};

export default FeedbackSaeModal;
