import React, { useEffect, useState } from "react";
import './NoticeRichTextEditor.scss'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { SWTypes } from "interfaces/sw/SWInterfaces";
import getStringBetween from "utilities/stringUtilities";

interface IRichTextEditorProps {
  html?: string,
  type: string,
  onLabelChange(lable: string): void,
  allowEdit?: boolean,
}

const NoticeRichTextEditor: React.FC<IRichTextEditorProps> = ({
  html,
  type,
  allowEdit,
  onLabelChange,
}) => {
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  const [oldEditorState, setoldEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  let existingHtml = html !== "" ? html : undefined;

  useEffect(() => {
    if (existingHtml) {
      if (!existingHtml.includes("<p>")) {
        existingHtml = "<p>" + existingHtml + "</p>"
      }
      const contentBlock = htmlToDraft(existingHtml);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        setoldEditorState(editorState);
      }
    }
  }, [existingHtml]);

  useEffect(() => {
    if (editorState) {
      linkCallback();
    }
  }, [editorState])

  const numberOfLinks = (html: string): number => {
    var count = (html.match(/<a.+?href="(?<url>.+?)".*?>(?<text>.+?)<\/a>/g) || []).length;
    return count;
  }

  //onblur event is not triggering when link is added to richtext editor, linkCallback will call the handleEditorBlur when a link is added.
  const linkCallback = () => {
    var editorStateLinks = numberOfLinks(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    var oldEditortateLinks = numberOfLinks(draftToHtml(convertToRaw(oldEditorState.getCurrentContent())));
    if (editorStateLinks > oldEditortateLinks) {
      handleEditorBlur();
    }
  }

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);
  }

  const handleEditorBlur = () => {
    let contentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent())).replaceAll("&nbsp;", "").replaceAll("&amp;", '&');
    let noticeLabel = getStringBetween(contentAsHTML, "<p>", "</p>");
    if (noticeLabel === "") {
      contentAsHTML = "";
    }
    onLabelChange(contentAsHTML);
  }

  return (
    allowEdit ?
      <fieldset className={"component-editor component-editor-bgwhite "}>
        <div className={"component-editor component-editor-bgwhite"}>
          <Editor
            toolbarHidden={!allowEdit}
            handlePastedText={() => false}
            stripPastedStyles={true}
            toolbarClassName="rte-toolbar-class richtext-toolbar"
            onBlur={handleEditorBlur}
            editorClassName="rte-editor-class editor"
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            readOnly={!allowEdit}
            toolbar={{
              options: ['link'],
              link:
              {
                inDropdown: false,
              },
            }}
          />
        </div>
      </fieldset> :
      <Editor
        toolbarHidden={!allowEdit}
        handlePastedText={() => false}
        stripPastedStyles={true}
        editorClassName="editor-width"
        wrapperClassName="editor-margin"
        editorState={editorState}
        readOnly={!allowEdit} />
  );
}

export default NoticeRichTextEditor;

export const RichTextNoticeSWTypes = [SWTypes.TLMSWI, SWTypes.TLMRC, SWTypes.MFGRC, SWTypes.MFGSWI];