import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { getPDF } from "store/swList/swListActions";
import { showErrorToast, showInfoToast } from "store/toast/toastActions";
import useSelector from "store/useSelector";

interface IDownloadLatestPdfVersion {
    swId: string,
}

const DownloadLatestPdfVersion: React.FC<RouteComponentProps<IDownloadLatestPdfVersion>> = (props) => {
    const swId = window.decodeURIComponent(props.match.params.swId);
    const dispatch = useDispatch();
    const {
        getPDFData: {
            getPdfOperation,
        }
    } = useSelector(store => store.swList);

    useEffect(() => {
        dispatch(
            getPDF({
                swId,
                generateDefault: true,
                getLatestVersion: true,
            })
        );
    }, [swId, dispatch]);

    if (getPdfOperation?.errorMessage) {
        dispatch(showErrorToast("Error getting PDF"));
    } else if (getPdfOperation?.wasSuccessful) {
        dispatch(showInfoToast("PDF generated & downloaded successfully"));
    }

    return <></>;
}

export default DownloadLatestPdfVersion;