import { IApprovalsState } from "./approvalsTypes";
import { createReducer } from "@reduxjs/toolkit";
import {
  setSort,
  resetMyApprovals,
  setSubmitDraftOperation,
  setMyApprovals,
  setLoadMyApprovalsOperation,
  setCurrentApproval,
  setLoadApprovalOperation,
  updateImageBlobData,
  setSubmitResponseOperation,
  resetCurrentApproval,
  setApprovalHistory,
  setSearchText,
  toggleFilterModal,
  applyFilter,
  clearFilter,
  setStringFilter,
  addFilterItem,
  addFilterItems,
  removeFilterItem,
  setCurrentBatchApproval,
  resetCurrentBatchApproval,
  setRetractSwOperation,
  setCurrentBatchUpdateSWMetaData,
  setLoadBatchUpdateSWMetaDataOperation,
  closeBatchUpdateSWMetaData,
  updateStepCommentsInfoApproval,
  updateApprovalNoticeImageBlobData,
  updateApprovalVideoBlobData,
  updateApprovalAudioBlobData,
  updateTableCellBlobData,
  updateRefDocTableCellBlobData,
  setCurrentRCUpdateApproval,
  setSubmitRCUpdateApprovalOperation,
  setRCType,
  showErrorPopupMessage,
} from "./approvalsActions";
import { findStep } from "store/manageSW/manageSWHelpers";
import { StepComponentTypes } from "interfaces/sw/SWInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { SortDirection } from "interfaces/misc/miscInterfaces";
import { ApprovalSortFields } from "interfaces/approvals/approvalInterfaces";

const initialState: IApprovalsState = {
  submitDraftOperation: undefined,
  retractSwOperation: undefined,
  loadMyApprovalsOperation: undefined,
  myApprovals: {
    swApprovals: [],
    batchApprovals: [],
    rcUpdateApprovals: [],
  },
  loadApprovalOperation: undefined,
  loadBatchUpdateSWMetaDataOperation: undefined,
  currentApproval: undefined,
  currentBatchUpdateSWMetaData: undefined,
  submitResponseOperation: undefined,
  approvalHistory: {
    swId: 0,
    approvals: [],
    batchApprovals: [],
    rcApprovals: [],
  },
  filterData: {
    isModalOpen: false,
    swFilterFields: {
      searchText: "",
      typesFilter: [],
      globalOrLocalTypesFilter: [],
      alignmentsFilter: [],
      languagesFilter: [],
      equivalentSWsFilter: [],
      tasksFilter: [],
      activityWorkflowsFilter: [],
      owningOrgsFilter: [],
      subBusinessLinesFilter: [],
      performingOrgsFilter: [],
      serviceTypesFilter: [],
      operatingEnvironmentsFilter: [],
      equipmentFilter: [],
      countriesFilter: [],
      competencyElementsFilter: [],
      safetyCategoriesFilter: [],
      businessRolesFilter: [],
      epicEquipmentFilter: [],
      productCentersFilter: [],
      geoUnitsFilter: [],
      customersFilter: [],
      maintenanceTaskIdsFilter: "",
      feedbackTypesFilter: [],
      owningPlantsFilter: [],
      performingPlantsFilter: [],
      materialsFilter: [],
      workCentersFilter: [],
      hazardCategoriesFilter: [],
      groupsFilter: [],
      groupCounterFilter: [],
      currentOwningPlantsFilter: [],
      connectedSWIsFilter: [],
    },
    sortBy: ApprovalSortFields.ModifiedOn,
    sortDir: SortDirection.Desc,
    approvalFilterFields: {
      approvalLevelsFilter: [],
    },
  },
  setErrorPopupMessage: {
    errMsg: "",
    affectedSws: [],
  },
};

const approvalsReducer = createReducer(initialState, (builder) => {
  // Reset My Approvals
  builder.addCase(resetMyApprovals, (state) => {
    state.myApprovals = initialState.myApprovals;
    state.loadMyApprovalsOperation = initialState.loadMyApprovalsOperation;
  });

  // Reset Current Approval
  builder.addCase(resetCurrentApproval, (state) => {
    state.currentApproval = initialState.currentApproval;
    state.loadApprovalOperation = initialState.loadApprovalOperation;
  });

  // Reset Current Approval
  builder.addCase(resetCurrentBatchApproval, (state) => {
    state.currentBatchApproval = initialState.currentBatchApproval;
    state.loadApprovalOperation = initialState.loadApprovalOperation;
  });

  // Set Submit Draft Operation
  builder.addCase(setSubmitDraftOperation, (state, action) => {
    state.submitDraftOperation = action.payload;
  });

  // Set Retract SW operation
  builder.addCase(setRetractSwOperation, (state, action) => {
    state.retractSwOperation = action.payload;
  });

  //sort
  builder.addCase(setSort, (state, action) => {
    state.filterData.sortBy = action.payload.sortBy;
    state.filterData.sortDir = action.payload.sortDir;
  });

  // Set My Approvals
  builder.addCase(setMyApprovals, (state, action) => {
    state.myApprovals = action.payload;
  });

  // Set Search Text
  builder.addCase(setSearchText, (state, action) => {
    state.filterData.swFilterFields.searchText = action.payload;
  });

  // Toggle Filter Modal Visibility
  builder.addCase(toggleFilterModal, (state, action) => {
    state.filterData.isModalOpen = action.payload;
  });

  // Apply Filter
  builder.addCase(applyFilter, (state) => {
    state.filterData.isModalOpen = false;
  });

  // Approval steps commentsInfo
  builder.addCase(updateStepCommentsInfoApproval, (state, action) => {
    state.currentApproval?.sw.steps.forEach((a) => {
      if (a.id === action.payload.stepID) {
        a.hasUnResolvedComments = action.payload.hasUnresolvedComments;
      }
      // child level
      a.children.forEach((b) => {
        if (b.id === action.payload.stepID) {
          b.hasUnResolvedComments = action.payload.hasUnresolvedComments;
        }

        // grand child level
        b.children.forEach((c) => {
          if (c.id === action.payload.stepID) {
            c.hasUnResolvedComments = action.payload.hasUnresolvedComments;
          }
        });
      });
    });
    if (state.currentApproval && state.currentApproval?.sw) {
      state.currentApproval.sw.totalStepComments =
        action.payload.totalStepComments;
      state.currentApproval.sw.unResolvedStepComments =
        action.payload.unResolvedStepComments;
    }
  });

  // Clear Filter
  builder.addCase(clearFilter, (state) => {
    state.filterData.swFilterFields = initialState.filterData.swFilterFields;
    state.filterData.approvalFilterFields =
      initialState.filterData.approvalFilterFields;
    state.filterData.isModalOpen = false;
  });

  // Add String Filter
  builder.addCase(setStringFilter, (state, action) => {
    switch (action.payload.attributeName) {
      case "maintenanceTaskIds": {
        state.filterData.swFilterFields.maintenanceTaskIdsFilter =
          action.payload.value;
        break;
      }
    }
  });

  // Add Filter Item
  builder.addCase(addFilterItem, (state, action) => {
    handleAddFilterItem(
      state,
      action.payload.metaDataName,
      action.payload.item
    );
  });

  // Add Filter Items
  builder.addCase(addFilterItems, (state, action) => {
    action.payload.items.forEach((item) => {
      handleAddFilterItem(state, action.payload.metaDataName, item);
    });
  });

  // Remove filter item.
  builder.addCase(removeFilterItem, (state, action) => {
    switch (action.payload.metaDataName) {
      case "activityWorkflows": {
        state.filterData.swFilterFields.activityWorkflowsFilter =
          state.filterData.swFilterFields.activityWorkflowsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "approvalLevels": {
        state.filterData.approvalFilterFields.approvalLevelsFilter =
          state.filterData.approvalFilterFields.approvalLevelsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "competencyElements": {
        state.filterData.swFilterFields.competencyElementsFilter =
          state.filterData.swFilterFields.competencyElementsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "countries": {
        state.filterData.swFilterFields.countriesFilter =
          state.filterData.swFilterFields.countriesFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "equipment": {
        state.filterData.swFilterFields.equipmentFilter =
          state.filterData.swFilterFields.equipmentFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "equivalentSWs": {
        state.filterData.swFilterFields.equivalentSWsFilter =
          state.filterData.swFilterFields.equivalentSWsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "languages": {
        state.filterData.swFilterFields.languagesFilter =
          state.filterData.swFilterFields.languagesFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "operatingEnvironments": {
        state.filterData.swFilterFields.operatingEnvironmentsFilter =
          state.filterData.swFilterFields.operatingEnvironmentsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "owningOrgs": {
        state.filterData.swFilterFields.owningOrgsFilter =
          state.filterData.swFilterFields.owningOrgsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "performingOrgs": {
        state.filterData.swFilterFields.performingOrgsFilter =
          state.filterData.swFilterFields.performingOrgsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "subBusinessLines": {
        state.filterData.swFilterFields.subBusinessLinesFilter =
          state.filterData.swFilterFields.subBusinessLinesFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "safetyCategories": {
        state.filterData.swFilterFields.safetyCategoriesFilter =
          state.filterData.swFilterFields.safetyCategoriesFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "serviceTypes": {
        state.filterData.swFilterFields.serviceTypesFilter =
          state.filterData.swFilterFields.serviceTypesFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "tasks": {
        state.filterData.swFilterFields.tasksFilter =
          state.filterData.swFilterFields.tasksFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "taskAlignments": {
        state.filterData.swFilterFields.alignmentsFilter =
          state.filterData.swFilterFields.alignmentsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "types": {
        state.filterData.swFilterFields.typesFilter =
          state.filterData.swFilterFields.typesFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "globalOrLocalTypes": {
        state.filterData.swFilterFields.globalOrLocalTypesFilter =
          state.filterData.swFilterFields.globalOrLocalTypesFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "feedbackTypes": {
        state.filterData.swFilterFields.feedbackTypesFilter =
          state.filterData.swFilterFields.feedbackTypesFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "businessRoles": {
        state.filterData.swFilterFields.businessRolesFilter =
          state.filterData.swFilterFields.businessRolesFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "epicEquipment": {
        state.filterData.swFilterFields.epicEquipmentFilter =
          state.filterData.swFilterFields.epicEquipmentFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "productCenters": {
        state.filterData.swFilterFields.productCentersFilter =
          state.filterData.swFilterFields.productCentersFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "geoUnits": {
        state.filterData.swFilterFields.geoUnitsFilter =
          state.filterData.swFilterFields.geoUnitsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "customers": {
        state.filterData.swFilterFields.customersFilter =
          state.filterData.swFilterFields.customersFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "owningPlants": {
        state.filterData.swFilterFields.owningPlantsFilter =
          state.filterData.swFilterFields.owningPlantsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "performingPlants": {
        state.filterData.swFilterFields.performingPlantsFilter =
          state.filterData.swFilterFields.performingPlantsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "materials": {
        state.filterData.swFilterFields.materialsFilter =
          state.filterData.swFilterFields.materialsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "workCenters": {
        state.filterData.swFilterFields.workCentersFilter =
          state.filterData.swFilterFields.workCentersFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "hazardCategories": {
        state.filterData.swFilterFields.hazardCategoriesFilter =
          state.filterData.swFilterFields.hazardCategoriesFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
      }
      case "groups": {
        state.filterData.swFilterFields.groupsFilter =
          state.filterData.swFilterFields.groupsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "groupCounter": {
        state.filterData.swFilterFields.groupCounterFilter =
          state.filterData.swFilterFields.groupCounterFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
      case "connectedSWIs": {
        state.filterData.swFilterFields.connectedSWIsFilter =
          state.filterData.swFilterFields.connectedSWIsFilter.filter(
            (x) => x.guid !== action.payload.item.guid
          );
        break;
      }
    }
  });

  function handleAddFilterItem(
    state: IApprovalsState,
    metaDataName: string,
    item: IMasterDataItem
  ) {
    let arr: IMasterDataItem[] = [];

    switch (metaDataName) {
      case "activityWorkflows": {
        arr = state.filterData.swFilterFields.activityWorkflowsFilter;
        break;
      }
      case "approvalLevels": {
        arr = state.filterData.approvalFilterFields.approvalLevelsFilter;
        break;
      }
      case "competencyElements": {
        arr = state.filterData.swFilterFields.competencyElementsFilter;
        break;
      }
      case "countries": {
        arr = state.filterData.swFilterFields.countriesFilter;
        break;
      }
      case "equipment": {
        arr = state.filterData.swFilterFields.equipmentFilter;
        break;
      }
      case "equivalentSWs": {
        arr = state.filterData.swFilterFields.equivalentSWsFilter;
        break;
      }
      case "languages": {
        arr = state.filterData.swFilterFields.languagesFilter;
        break;
      }
      case "operatingEnvironments": {
        arr = state.filterData.swFilterFields.operatingEnvironmentsFilter;
        break;
      }
      case "owningOrgs": {
        arr = state.filterData.swFilterFields.owningOrgsFilter;
        break;
      }
      case "performingOrgs": {
        arr = state.filterData.swFilterFields.performingOrgsFilter;
        break;
      }
      case "subBusinessLines": {
        arr = state.filterData.swFilterFields.subBusinessLinesFilter;
        break;
      }
      case "safetyCategories": {
        arr = state.filterData.swFilterFields.safetyCategoriesFilter;
        break;
      }
      case "serviceTypes": {
        arr = state.filterData.swFilterFields.serviceTypesFilter;
        break;
      }
      case "tasks": {
        arr = state.filterData.swFilterFields.tasksFilter;
        break;
      }
      case "taskAlignments": {
        arr = state.filterData.swFilterFields.alignmentsFilter;
        break;
      }
      case "types": {
        arr = state.filterData.swFilterFields.typesFilter;
        break;
      }
      case "globalOrLocalTypes": {
        arr = state.filterData.swFilterFields.globalOrLocalTypesFilter;
        break;
      }
      case "businessRoles": {
        arr = state.filterData.swFilterFields.businessRolesFilter;
        break;
      }
      case "epicEquipment": {
        arr = state.filterData.swFilterFields.epicEquipmentFilter;
        break;
      }
      case "productCenters": {
        arr = state.filterData.swFilterFields.productCentersFilter;
        break;
      }
      case "geoUnits": {
        arr = state.filterData.swFilterFields.geoUnitsFilter;
        break;
      }
      case "customers": {
        arr = state.filterData.swFilterFields.customersFilter;
        break;
      }
      case "feedbackTypes": {
        arr = state.filterData.swFilterFields.feedbackTypesFilter;
        break;
      }
      case "owningPlants": {
        arr = state.filterData.swFilterFields.owningPlantsFilter;
        break;
      }
      case "performingPlants": {
        arr = state.filterData.swFilterFields.performingPlantsFilter;
        break;
      }
      case "materials": {
        arr = state.filterData.swFilterFields.materialsFilter;
        break;
      }
      case "workCenters": {
        arr = state.filterData.swFilterFields.workCentersFilter;
        break;
      }
      case "hazardCategories": {
        arr = state.filterData.swFilterFields.hazardCategoriesFilter;
        break;
      }
      case "groups": {
        arr = state.filterData.swFilterFields.groupsFilter;
        break;
      }
      case "groupCounter": {
        arr = state.filterData.swFilterFields.groupCounterFilter;
        break;
      }
      case "connectedSWIs": {
        arr = state.filterData.swFilterFields.connectedSWIsFilter;
      }
    }

    if (!arr.find((x) => x.guid === item.guid)) {
      arr.push({
        ...item,
      });
    }
  }

  // Set Load My Approvals Operation
  builder.addCase(setLoadMyApprovalsOperation, (state, action) => {
    state.loadMyApprovalsOperation = action.payload;
  });

  // Set Current Approval
  builder.addCase(setCurrentApproval, (state, action) => {
    state.currentApproval = action.payload;
  });

  // Set Current BatchUpdateSWMetaData
  builder.addCase(setCurrentBatchUpdateSWMetaData, (state, action) => {
    state.currentBatchUpdateSWMetaData = action.payload;
  });

  // Set Current Batch Approval
  builder.addCase(setCurrentBatchApproval, (state, action) => {
    state.currentBatchApproval = action.payload;
  });

  // Set Current RC Update Approval
  builder.addCase(setCurrentRCUpdateApproval, (state, action) => {
    state.currentRCUpdateApproval = action.payload;
  });

  // Set Load Approval Operation
  builder.addCase(setLoadApprovalOperation, (state, action) => {
    state.loadApprovalOperation = action.payload;
  });

  builder.addCase(setSubmitRCUpdateApprovalOperation, (state, action) => {
    state.submitRCUpdateApprovalOperation = action.payload;
  });

  // Set Load Batch Update SW MetaData Operation
  builder.addCase(setLoadBatchUpdateSWMetaDataOperation, (state, action) => {
    state.loadBatchUpdateSWMetaDataOperation = action.payload;
  });

  // close BatchUpdateSWMetaData
  builder.addCase(closeBatchUpdateSWMetaData, (state, action) => {
    state.loadBatchUpdateSWMetaDataOperation = undefined;
  });

  // Update Image Blob Data
  builder.addCase(updateImageBlobData, (state, action) => {
    if (!state.currentApproval?.sw) {
      return;
    }

    let [step] = findStep(
      action.payload.stepGuid,
      state.currentApproval.sw.steps,
      0
    );
    if (!step) {
      return;
    }

    let existingComponent = step.components.find(
      (x) => x.guid === action.payload.componentGuid
    );

    if (
      !existingComponent ||
      (existingComponent.type !== StepComponentTypes.Image &&
        existingComponent.type !== StepComponentTypes.TimeImage)
    ) {
      return;
    }

    existingComponent.blobData = action.payload.blobData;
  });

  // Update Table Cell Blob Data
  builder.addCase(updateTableCellBlobData, (state, action) => {
    if (!state.currentApproval?.sw) {
      return;
    }

    let fileName = action.payload.fileName;
    // Find the step.
    let [step] = findStep(
      action.payload.stepGuid,
      state.currentApproval.sw.steps,
      0
    );
    if (!step) {
      return;
    }

    // Find Component.
    let existingComponent = step.components.find(
      (x) => x.guid === action.payload.componentGuid
    );
    if (
      !existingComponent ||
      existingComponent.type !== StepComponentTypes.Table
    ) {
      return;
    }

    // Find existing cell
    let existingCell = existingComponent.cells.find(
      (x) =>
        x.colIndex === action.payload.colIndex &&
        x.rowIndex === action.payload.rowIndex
    );

    if (!existingCell) {
      return;
    }

    existingCell.value = existingCell.value.replace(
      'src=""',
      'src="' + fileName + '"'
    );
    existingCell.imageFileName = fileName;
  });

  // Update Table Cell Blob Data
  builder.addCase(updateRefDocTableCellBlobData, (state, action) => {
    if (!state.currentApproval?.sw) {
      return;
    }

    let fileName = action.payload.fileName;
    // Find Component.
    const refDoc = state.currentApproval?.sw.referenceDocs.find(
      (x) => x.guid === action.payload.refDocGuid
    );
    if (!refDoc) {
      return;
    }

    let existingComponent = refDoc.tableData;
    if (!existingComponent) {
      return;
    }

    // Find existing cell
    let existingCell = existingComponent.cells.find(
      (x) =>
        x.colIndex === action.payload.colIndex &&
        x.rowIndex === action.payload.rowIndex
    );

    if (!existingCell) {
      return;
    }

    existingCell.value = existingCell.value.replace(
      'src=""',
      'src="' + fileName + '"'
    );
    existingCell.imageFileName = fileName;
  });

  // Set Submit Response Operation
  builder.addCase(setSubmitResponseOperation, (state, action) => {
    state.submitResponseOperation = action.payload;
  });

  // Set err msg
  builder.addCase(showErrorPopupMessage, (state, action) => {
    state.setErrorPopupMessage = action.payload;
  });
  // Set Approval History
  builder.addCase(setApprovalHistory, (state, action) => {
    state.approvalHistory = action.payload;
  });

  // Update notice image blob data
  builder.addCase(updateApprovalNoticeImageBlobData, (state, action) => {
    const notice = state.currentApproval?.sw.notices.find(
      (x) => x.guid === action.payload.noticeGuid
    );

    if (notice) {
      notice.image.blobData = action.payload.blob;
      notice.image.filename = action.payload.filename;
    } else {
      let [step] = findStep(
        action.payload.stepGuid!,
        state.currentApproval?.sw.steps!,
        0
      );
      if (!step) {
        return;
      }

      let existingComponent = step.components.find(
        (x) => x.guid === action.payload.noticeGuid
      );

      if (
        !existingComponent ||
        existingComponent.type !== StepComponentTypes.Notice
      ) {
        return;
      }

      existingComponent.image.blobData = action.payload.blob;
      existingComponent.image.filename = action.payload.filename;
    }
  });

  // Update video blob data
  builder.addCase(updateApprovalVideoBlobData, (state, action) => {
    let [step] = findStep(
      action.payload.stepGuid!,
      state.currentApproval?.sw.steps!,
      0
    );
    if (!step) {
      return;
    }

    let existingComponent = step.components.find(
      (x) => x.guid === action.payload.componentGuid
    );

    if (
      !existingComponent ||
      existingComponent.type !== StepComponentTypes.Video
    ) {
      return;
    }

    existingComponent.blobData = action.payload.blob;
    existingComponent.filename = action.payload.filename;
  });

  // Update audio blob data
  builder.addCase(updateApprovalAudioBlobData, (state, action) => {
    let [step] = findStep(
      action.payload.stepGuid!,
      state.currentApproval?.sw.steps!,
      0
    );
    if (!step) {
      return;
    }

    let existingComponent = step.components.find(
      (x) => x.guid === action.payload.componentGuid
    );

    if (
      !existingComponent ||
      existingComponent.type !== StepComponentTypes.Audio
    ) {
      return;
    }

    existingComponent.blobData = action.payload.blob;
    existingComponent.filename = action.payload.filename;
  });

  // Update RC notice type
  builder.addCase(setRCType, (state, action) => {
    state.filterData.swFilterFields.rcType = action.payload;
  });
});

export default approvalsReducer;
