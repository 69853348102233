import React, { useState } from "react";
import Modal from "../../common/Modal";
import FlowLayout from "../../../components/layout/FlowLayout";
import { useDispatch } from "react-redux";
import { OnChangeSWUserFeedback, OnChangeSWUserFeedbackAck, saveSWUserFeedbackAcks, setSWUserFeedbacks } from "store/swList/swListActions";
import "./SWUserFeedbacksModal.scoped.scss";
import useSelector from "store/useSelector";
import thumbUp from "media/icons/dls/thumbs-up.svg";
import thumbDown from "media/icons/dls/thumbs-down.svg";
import check from "media/icons/dls/check.svg";
import { ISWUserFeedback, ISWUserFeedbackAck } from "interfaces/sw/SWInterfaces";
import ModalSpinner from "components/common/ModalSpinner";
import Banner, { BannerType } from "components/common/Banner";
import UserFeedbacksExportToExcel from "../search/UserFeedbacksExport";

const SWUserFeedbacksModal = () => {
  const {
    userFeedbacksData: {
      swUserFeedbackDetails,
    },
    userFeedbacksAckData: {
      saveUserFeedbacksOperation,
    }
  } = useSelector(store => store.swList);
  var [showCommentsWithoutResponses, setshowCommentsWithoutResponses] = useState(false);
  var [showCurrentVersionOnly, setShowCurrentVersionOnly] = useState(false);
  var [showThumbsDownOnly, setShowThumbsDownOnly] = useState(false);
  const dispatch = useDispatch();

  const onResponseChange = (id: number,
    acknowledged: boolean,
    response: string) => {
    dispatch(OnChangeSWUserFeedback({
      id: id,
      acknowledge: acknowledged,
      response: response
    }));
  }

  function setNativeValue(input: HTMLTextAreaElement, value: string) {
    input.value = value;
    let event = new Event('input', { bubbles: true });
    input.dispatchEvent(event);
  }

  const SubmitMyFeedbacktoUsers = () => {
    var swUserFeedbacksWithAcks = swUserFeedbackDetails
      .userFeedbacks
      .filter(r => r.isUpdated);

    var swUserAcknowledgeResponses = swUserFeedbacksWithAcks.map((item: ISWUserFeedback): ISWUserFeedbackAck => ({
      id: item.id,
      swId: item.swId,
      version: item.version,
      acknowledged: item.acknowledged,
      response: item.response,
    }));

    dispatch(saveSWUserFeedbackAcks({
      swUserFeedbackAcks: swUserAcknowledgeResponses,
      swGuid: swUserFeedbackDetails.swGuid ? swUserFeedbackDetails.swGuid: "",
    }));    
  }

  const onAcknowledgeChange = (id: number,
    acknowledged: boolean) => {
    var input = document.getElementById("response_" + id) as HTMLTextAreaElement;
    if (input && !acknowledged) {
      setNativeValue(input, "");
    }
    dispatch(OnChangeSWUserFeedbackAck({
      id: id,
      acknowledge: acknowledged
    }));
  }
  let component: JSX.Element | undefined;
  
    if (saveUserFeedbacksOperation?.isWorking) {
      component = (
        <ModalSpinner />
      );
    } else if (saveUserFeedbacksOperation?.errorMessage) {
      component = (
        <Banner
          type={BannerType.Error}
        >
          {saveUserFeedbacksOperation?.errorMessage}
        </Banner>
      );
    }
    return (
      <Modal
        header={`Feedback: ${swUserFeedbackDetails.title}: All Components Individually`}
        controls={
          <>
            <button
              className="primary-button"
              onClick={SubmitMyFeedbacktoUsers}
            >
              Submit My Feedback to User
            </button>
            <button
              className="primary-button"
              onClick={() => dispatch(setSWUserFeedbacks({
                swUserFeedbackDetails: {
                  title: undefined,
                  swGuid: undefined,
                  currentVersion: undefined,
                  userFeedbacks: [],
                  userFeedbacksExport: [],
                },
                loadUserFeedbacksOperation: undefined,
              }))}
            >
              Close
            </button>
          </>
        }
        isOpen={true}
      >
        <FlowLayout>
        <div className="filterDiv">
            <span>
              <input type="checkbox"
                id="showCommentsWithResponses"
                onChange={e => setshowCommentsWithoutResponses(e.target.checked)} />
              <label>Show comments without responses</label>
            </span>
            <span>
              <input type="checkbox"
                id="showCurrentVersionOnly"
                onChange={e => setShowCurrentVersionOnly(e.target.checked)} />
              <label>Show current version only</label>
            </span>
            <span>
              <input type="checkbox"
                id="showThumbsDownOnly"
                onChange={e => setShowThumbsDownOnly(e.target.checked)} />
              <label>Show Thumbs down only</label>
            </span>
            <span className="exportToExcel">
              <UserFeedbacksExportToExcel />
            </span>
          </div>
          {swUserFeedbackDetails.userFeedbacks.length > 0 
          && swUserFeedbackDetails.userFeedbacks.filter(r =>
            (!showCommentsWithoutResponses || (showCommentsWithoutResponses && (!r.acknowledged || r.isUpdated)))
            && (!showCurrentVersionOnly || (showCurrentVersionOnly && r.version === swUserFeedbackDetails.currentVersion?.toString()))
            && (!showThumbsDownOnly || (showThumbsDownOnly && r.vote === false))).length === 0 ?
          <div>&nbsp;&nbsp;&nbsp;<b>The user Feedbacks are not found</b></div> :          
          <div className="row header">
            <div className="version">
              Version
            </div>
            <div className="rating">
              Rating
            </div>
            <div className="user">
              User
            </div>
            <div className="comment">
              Comment
            </div>
            <div className="acknowledge">
              Acknowledge
            </div>
            <div className="cell response">
              Response
            </div>
          </div>
          }
          {swUserFeedbackDetails.userFeedbacks.length > 0
            && swUserFeedbackDetails.userFeedbacks.filter(r =>
              (!showCommentsWithoutResponses || (showCommentsWithoutResponses && (!r.acknowledged || r.isUpdated)))
              && (!showCurrentVersionOnly || (showCurrentVersionOnly && r.version === swUserFeedbackDetails.currentVersion?.toString()))
              && (!showThumbsDownOnly || (showThumbsDownOnly && r.vote === false))).map(uf =>
          <div key={uf.id} className="row row-item">
                  <div className="version">
                    {uf.version}
                  </div>
                  <div className="rating">
                    <div className="icon">
                      {uf.vote ? 
                        <img className="icon-small"
                        src={thumbUp}
                        alt="like" /> : <img className="icon-small"
                          src={thumbDown}
                          alt="like" />
                      }
                    </div>
                  </div>
                  <div className="user">
                    {uf.createdBy}
                  </div>
                  <div className="comment">
                    {uf.feedback}
                  </div>
                  <div className="acknowledge">
                    {uf.acknowledged && !uf.isUpdated ?
                      <img className="icon-extra-small"
                        src={check}
                        alt="checked" /> :
                      <input
                        type="checkbox"
                        placeholder={`acknowledge`}
                        checked={uf.acknowledged}
                        onChange={e => onAcknowledgeChange(uf.id, e.target.checked)}
                      />
                    }
                  </div>
                  <div className="response">
                    {uf.acknowledged && !uf.isUpdated ?
                      uf.response :
                      <textarea
                        placeholder={`response`}
                        defaultValue={uf.response ? uf.response : ""}
                        disabled={!uf.acknowledged}
                        className="responseTextArea"
                        id={`response_${uf.id}`}
                        onBlur={e => onResponseChange(uf.id, true, e.target.value)}
                      />}
                  </div>
                </div>
              )}        
          {component}
        </FlowLayout>
      </Modal>
    );
  }
  export default SWUserFeedbacksModal;