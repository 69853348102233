import React from "react";
import "./UserRow.scoped.scss";
import { IManageUserUser } from "interfaces/user/UserInterfaces";
import { Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";

interface IUserRowProps {
  user: IManageUserUser,
}

const UserRow: React.FC<IUserRowProps> = ({ user }) =>
  <Link
    className="user row hover-gray-bg"
    to={Routes.UserDetail.replace(":email", window.encodeURIComponent(user.email))}
  >
    <div className="cell name">
      <span className="user-name">{user.name}</span>
      {user.email}
    </div>
    <div className="cell roles">
      {user.roles.join(", ")}
    </div>
  </Link>;

export default UserRow;