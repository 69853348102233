import Modal from "components/common/Modal";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import Nestable, { Item } from "react-nestable";

export interface ISortableItem {
  label: string,
  guid: string,
  sortOrder: number,
}

interface IReorderModalProps {
  title?: string,
  items: ISortableItem[],
  onCancel(): void,
  onSave(sortData: ISortableItem[]): void,
}

const ReorderModal: React.FC<IReorderModalProps> = ({
  title,
  items,
  onCancel,
  onSave,
}) => {
  const [sortables, setSortables] = useState(cloneDeep(items.map(item => ({
    id: item.guid,
    text: item.label,
  }))));

  const onReorder = (items: Item[]) => {
    const clonedItems = cloneDeep(items.map(item => ({
      id: item.id,
      text: item.text,
    })));
    setSortables(clonedItems);
  }

  const onClickSave = () => {
    const clonedItems = cloneDeep(items);
    sortables.forEach((item, index) => {
      const originalItem = clonedItems.find(x => x.guid === item.id);

      if (originalItem) {
        originalItem.sortOrder = index + 1;
      }

    });

    clonedItems.sort((a, b) => a.sortOrder < b.sortOrder
    ? -1
    : 1);
    onSave(clonedItems);
  };

  return (
    <>
      <Modal
        header={title || "Reorder"}
        controls={(
          <>
            <button
              className="secondary-button"
              onClick={onCancel}
            >
              Cancel
          </button>
            <button
              className="primary-button"
              onClick={onClickSave}
            >
              Save
          </button>
          </>
        )}
        isOpen={true}
      >
        <Nestable
          className="Nestable"
          items={sortables}
          renderItem={({ item }) => (
            <div
              className="Nestable "
            >
              {item.text}
            </div>
          )}
          maxDepth={1}
          onChange={(arg) => onReorder(arg.items)}
          confirmChange={() => true}
          collapsed={false}
        />
      </Modal>
    </>
  );
};

export default ReorderModal;