import "./ApprovalTooltip.scss";
import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";

interface ApprovalTooltipProps {
  children: ReactNode;
  tooltipContent: ReactNode;
}

interface ApprovalTooltipData {
  content: ReactNode;
  position: {
    x: number;
    y: number;
  };
}

interface ApprovalTooltipContextProps {
  tooltipData: ApprovalTooltipData | undefined;
  setTooltipData: (tooltipData: ApprovalTooltipData | undefined) => void;
  triggerHovered: boolean;
  setTriggerHovered: (hovered: boolean) => void;
  tooltipHovered: boolean;
  setTooltipHovered: (hovered: boolean) => void;
}

const ApprovalTooltipContext = createContext<ApprovalTooltipContextProps>({
  tooltipData: undefined,
  setTooltipData: () => {},
  triggerHovered: false,
  setTriggerHovered: () => {},
  tooltipHovered: false,
  setTooltipHovered: () => {},
});

export const ApprovalTooltipTrigger: FC<ApprovalTooltipProps> = ({
  children,
  tooltipContent,
}) => {
  const {
    setTooltipData,
    setTriggerHovered,
    triggerHovered,
    tooltipHovered,
  } = useContext(ApprovalTooltipContext);

  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (tooltipContent) {
      setTooltipData({
        content: tooltipContent,
        position: {
          x: e.clientX,
          y: e.clientY,
        },
      });
    }
    setTriggerHovered(true);
  };

  const onMouseLeave = () => {
    setTriggerHovered(false);
  };

  useEffect(() => {
    if (!triggerHovered && !tooltipHovered) {
      setTooltipData(undefined);
    }
  }, [triggerHovered, tooltipHovered, setTooltipData]);

  return (
    <div
      className="approvaltooltip-trigger-wrapper"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};

export const ApprovalTooltipContainer: FC = () => {
  const { tooltipData, setTooltipData, setTooltipHovered, tooltipHovered, triggerHovered } =
    useContext(ApprovalTooltipContext);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);


  useEffect(() => {
    if (tooltipData) {
      const { position } = tooltipData;
      const { x, y } = position;

      setX(x);
      setY(y-25);
    }
  }, [tooltipData]);

  const onMouseEnterTooltip = () => {
    setTooltipHovered(true);
  };

  const onMouseLeaveTooltip = () => {
    setTooltipHovered(false);
  };

  useEffect(() => {
    if (!tooltipHovered && !triggerHovered) {
      setTooltipData(undefined);
    }
  }, [tooltipHovered, triggerHovered, setTooltipData]);

  return tooltipData && tooltipData.content ? (
    <div
      className="approvaltooltip"
      style={{ top: y, left: x }}
      ref={tooltipRef}
      onMouseEnter={onMouseEnterTooltip}
      onMouseLeave={onMouseLeaveTooltip}
    >
      {tooltipData.content}
    </div>
  ) : null;
};

export const ApprovalTooltipArea: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [tooltipData, setTooltipData] = useState<ApprovalTooltipData>();
  const [triggerHovered, setTriggerHovered] = useState<boolean>(false);
  const [tooltipHovered, setTooltipHovered] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      tooltipData,
      setTooltipData,
      triggerHovered,
      setTriggerHovered,
      tooltipHovered,
      setTooltipHovered,
    }),
    [
      tooltipData,
      setTooltipData,
      triggerHovered,
      setTriggerHovered,
      tooltipHovered,
      setTooltipHovered,
    ]
  );

  return (
    <ApprovalTooltipContext.Provider value={value}>
      {children}
    </ApprovalTooltipContext.Provider>
  );
};
