import React, { useEffect, useRef } from "react";
import { IAudioComponent } from "../../../../../interfaces/sw/SWInterfaces";
import "./AudioEditor.scoped.scss";
import { useDispatch } from "react-redux";
import { ImageDataDestinations } from "../../../../../store/manageSW/manageSWTypes";
import BlockSpinner from "components/common/BlockSpinner";
import { getAudioUrl, uploadAudio } from "store/manageSW/manageSWActions";
import { showErrorToast } from "store/toast/toastActions";
import { isFilenameAudio, getAudioSizeError, addGuidToFilename, audioFilter } from "utilities/fileUtilities";
import useSelector from "store/useSelector";
import FileDropzone from "components/common/FileDropzone";


interface IAudioEditorProps {
    audio: IAudioComponent,
    allowEdit: boolean,
    swGuid: string,
    swVersion: number,
    stepGuid: string,
    imageDataDestination: ImageDataDestinations,
}

const AudioEditor: React.FC<IAudioEditorProps> = ({
    audio,
    allowEdit,
    swGuid,
    swVersion,
    stepGuid,
    imageDataDestination,
}) => {
    const { fileUploadOperation } = useSelector(store => store.manageSW);
    const dispatch = useDispatch();
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!audio.filename
            || audio.blobData?.isLoading
            || audio.filename === audio.blobData?.filename
            || fileUploadOperation?.isWorking) {
            // blobl url already loaded. ignore.
            return;
        }

        dispatch(getAudioUrl({
            swGuid,
            swVersion,
            stepGuid,
            componentGuid: audio.guid,
            filename: audio.filename,
            destination: imageDataDestination,
        }));
    }, [audio, stepGuid, swGuid, swVersion, imageDataDestination, fileUploadOperation, dispatch]);

    const handleFile = (file: File) => {
      if (!isFilenameAudio(file.name)) {
        dispatch(showErrorToast("Please select a valid audio."));
        return;
      }

      const fileSizeError = getAudioSizeError(file);
      if (fileSizeError) {
        dispatch(showErrorToast(fileSizeError));
        return;
      }

      try {
        addGuidToFilename(file.name);
      } catch (err: any) {
        dispatch(showErrorToast(err.message || err));
        return;
      }

      dispatch(
        uploadAudio({
          swGuid,
          swVersion: 0,
          file,
          stepGuid,
          componentGuid: audio.guid,
        })
      );
    };

    const onFileChosen = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) {
        return;
      }

      handleFile(event.target.files[0]);
    };

    let aud: JSX.Element | undefined;

    if (audio.blobData?.isLoading) {
        aud = (
            <BlockSpinner />
        );
    } else if (audio.blobData?.errorMessage) {
        aud = (
            <label className="error">
                {audio.blobData?.errorMessage}
            </label>
        );
    } else if (audio.blobData?.blobUrl) {
        aud = (
            <audio controls className="embed">
                <source src={audio.blobData?.blobUrl} />
            </audio>
        );
    }

    return (
        <>
            <div>
                {aud}
                {allowEdit &&
                    <>
                        <input
                            type="file"
                            accept="audio/mp4,audio/mp3,audio/wav"
                            ref={fileInputRef}
                            onChange={onFileChosen}
                        />
                        <div
                            className="controls"
                        >
                            <FileDropzone onFilesDropped={([file]) => handleFile(file)}>
                                <button
                                    className="secondary-button choose-audio"
                                    onClick={() => fileInputRef.current?.click()}
                                    title={audioFilter}
                                    >
                                    {audio.blobData?.blobUrl ? "Drop Audio here or click to replace" : "Drop Audio here or click to upload"}
                                </button>
                            </FileDropzone>
                        </div>
                    </>
                }
            </div>
        </>
    );
}


export default AudioEditor;
