import React, { useState } from "react";
import { IRefDocTableComponent, IReferenceDoc } from "interfaces/sw/SWInterfaces";
import Banner, { BannerType } from "components/common/Banner";
import "./JobRefDocsList.scoped.scss";
import RefDocTableCell from "./RefDocTableCell";
import useSelector from "store/useSelector";
import SWApi from "apis/sw/SWApi";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import ModalSpinner from "components/common/ModalSpinner";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";

const JobRefDocsList: React.FC = () => {
  const {
    manageSW: {
      SW,
    }
  } = useSelector(store => store);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const refDocCount = SW.referenceDocs.length;

  if (!refDocCount) {
    return (
      <Banner
        type={BannerType.Info}
      >
        {'There are no reference documents attached to any of the standard works in this job.'}
      </Banner>
    );
  }

  const downloadRefDoc = async (refDoc: IReferenceDoc) => {
    try {
      setIsLoading(true);
      const blobUri = await SWApi.getAttachmentUrl(SW.guid, SW.version, refDoc.refDocData);
      const link = document.createElement("a");
      link.download = refDoc.refDocData;
      link.target = "_blank";
      link.href = blobUri;
      link.click();
    } catch (err: any) {
      dispatch(showErrorToast(`Failed to load attachment uri: ${getResponseErrorMessage(err)}`));
    } finally {
      setIsLoading(false);
    }
  }

  const refDocSorter = (a: IReferenceDoc, b: IReferenceDoc) => {
    if (a.sortOrder
      && b.sortOrder) {
      return a.sortOrder < b.sortOrder
        ? -1
        : 1;
    } else if (a.sortOrder) {
      return -1;
    } else if (b.sortOrder) {
      return 1;
    } else {
      return a.label < b.label
        ? -1
        : 1;
    }
  }

  const renderTable = (tableData: IRefDocTableComponent, refDocGuid: string) => {
    //build a 2d array here and one by one build objects with colSpan, rowSpan, isDeleted
    let cellArray = new Array(tableData.rowCount);
    for (var r = 0; r < cellArray.length; r++) {
      cellArray[r] = new Array(tableData.colCount);
      for (var c = 0; c < cellArray[r].length; c++) {
        let cIx = c;
        let rIx = r;
        
        let cell = tableData.cells.filter(x => x.rowIndex === rIx && x.colIndex === cIx)[0];
        if (cell !== undefined) {
          cellArray[rIx][cIx] = {colSpan: cell.colSpan ? cell.colSpan : 1, rowSpan: cell.rowSpan ? cell.rowSpan : 1, deleted: false};
        } else {
          cellArray[rIx][cIx] = {colSpan: 1, rowSpan: 1, deleted: false};
        }
      }
    }

    // figure out which cells should be deleted to handle col spans and row spans
    for (var ra = 0; ra < tableData.rowCount; ra++) {
      for (var ca = 0; ca < tableData.colCount; ca++) {
        let cell = cellArray[ra][ca];
        if (cell.deleted) {
          continue;
        }

        if (cell.colSpan > 1 && cell.rowSpan > 1) {
          // disable columns and rows??
          for (var i = ca + 1; i < ca + cell.colSpan; i++) {
            let cellToDisable = cellArray[ra][i];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
          for (var j = ra + 1; j < ra + cell.rowSpan; j++) {
            let cellToDisable = cellArray[j][ca];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }

            for (var z = 1; z < cell.colSpan; z++) {
              let cellToDisable = cellArray[j][ca + z];
              if (cellToDisable) {
                cellToDisable.deleted = true;
                cellToDisable.rowSpan = 1;
                cellToDisable.colSpan = 1;
              }
            }
          }
        } else if (cell.colSpan > 1) {
          // disable columns
          for (var ii = ca + 1; ii < ca + cell.colSpan; ii++) {
            let cellToDisable = cellArray[ra][ii];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        } else if (cell.rowSpan > 1) {
          // disable rows
          for (var jj = ra + 1; jj < ra + cell.rowSpan; jj++) {
            let cellToDisable = cellArray[jj][ca];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        }
      }
    }

    const colWidth = 100 / tableData.colCount;

    return (
      <table>
        <colgroup>
          {
            [...new Array(tableData.colCount)].map((_, cIx) => {
              return <col key={cIx} style={{width: colWidth + '%'}}/>
            })
          }
        </colgroup>
        <tbody>
        {
          [...new Array(tableData.rowCount)].map((_, ixr) => (
            <tr key={"r" + ixr}>
              {
                [...new Array(tableData.colCount)].map((_, ixc) => {
                  let cellFormatter = cellArray[ixr][ixc]; // is this cell deleted?
                  if (!cellFormatter.deleted) {
                    let cell = tableData.cells.filter(x => x.rowIndex === ixr && x.colIndex === ixc)[0];
                    let cellColSpan = cell !== undefined ? cell.colSpan : 1;
                    let cellRowSpan = cell !== undefined ? cell.rowSpan : 1;

                    return (                
                      <td key={"c" + ixc} colSpan={cellColSpan} rowSpan={cellRowSpan}>
                         <RefDocTableCell
                            rowKey={ixr}
                            colKey={ixc}
                            cells={tableData.cells}
                            refDocGuid={refDocGuid}
                            swGuid={SW.guid}
                            swVersion={SW.version}
                            imageDataDestination={ImageDataDestinations.ManageSW}
                          />
                      </td>);
                  } else {
                    return undefined;
                  }
                })
              }
            </tr>
          ))
        }
        </tbody>
      </table>
    );
  }

  return (
    <>
      {<div
        className="sw-ref-docs"
      >
        <h4>{SW.title}</h4>
        {SW.referenceDocs
          .slice()
          .sort(refDocSorter)
          .map(rd => {
            if (rd.type === 'File' || rd.type === undefined)
              return (
                <button
                  key={rd.id}
                  onClick={() => downloadRefDoc(rd)}
                  className="tertiary-button ref-doc"
                >
                  {rd.label}
                </button>
              )
            else if (rd.type === 'Link')
              return (
                <div
                  className="row"
                  key={rd.id}
                >
                  <span>
                    <a
                      href={rd.refDocData}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {rd.label}
                    </a>
                  </span>
                </div>
              )
            else if (rd.tableData)
            return (
              <div
                className="tableRow"
                key={rd.id}
              >
                <label>{rd.label}</label>
                {renderTable(rd.tableData, rd.guid)}
              </div>
            )
            else
              return undefined;
          })
        }
      </div>}
      {isLoading &&
        <ModalSpinner />
      }
    </>
  );
}

export default JobRefDocsList;