import Modal from "components/common/Modal";
import FlowLayout from "components/layout/FlowLayout";
import { Routes } from "components/routing/Routing";
import SearchTechContentUser from "components/user/management/SearchTechContentUser";
import SearchUser from "components/user/management/SearchUser";

import {
  ApprovalLevels,
  ApprovalStatuses,
  IRCUpdateApprovalDetails,
} from "interfaces/approvals/approvalInterfaces";

import React, { FC, FormEventHandler, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IRCUpdateApproval } from "store/approvals/approvalsTypes";
import useSelector from "store/useSelector";
import RCUpdateApprovalDetailsTable from "./RCUpdateApprovalDetailsTable";
import "./ViewRCUpdateApprovalDetails.scoped.scss";

interface IViewRCBatchApprovalProps {
  rcUpdateApproval: IRCUpdateApproval;
  onSubmit(rcUpdateApproval: IRCUpdateApproval): void;
}

const ViewRCUpdateApprovalDetails: FC<IViewRCBatchApprovalProps> = function ({
  rcUpdateApproval,
  onSubmit,
}) {
  const {
    approvals: { submitRCUpdateApprovalOperation },
  } = useSelector((store) => store);

  const [approvalDetailsList, setApprovalDetailsList] = useState<
    IRCUpdateApprovalDetails[]
  >([]);

  useEffect(
    function copyDetailsAndResetState() {
      const details: IRCUpdateApprovalDetails[] = [];

      rcUpdateApproval.details.forEach((item) => {
        const { sMEApprover, techContRev, ...otherDetails } = item;
        details.push({ ...otherDetails, sMEApprover: "", techContRev: "" });
      });

      setApprovalDetailsList(details);
      setCommonTechContRev(undefined);
      setApproveAll(false);
    },
    [rcUpdateApproval]
  );

  const [approveAll, setApproveAll] = useState(false);

  useEffect(
    function updateDetailsWhenApproveAllIsSet() {
      if (
        approveAll &&
        approvalDetailsList.find((s) => s.status !== ApprovalStatuses.Approved)
      ) {
        setApprovalDetailsList((currentDetailsList) =>
          currentDetailsList.map((detailsItem) => ({
            ...detailsItem,
            status: ApprovalStatuses.Approved,
          }))
        );
      }
    },
    [approveAll, approvalDetailsList]
  );

  const [commonTechContRev, setCommonTechContRev] = useState<string>();
  const [showTechContRevModal, setShowTechContRevModal] = useState(false);

  useEffect(
    function updateDetailsWhenCommonTechContRevIsSet() {
      setShowTechContRevModal(false);

      if (!commonTechContRev) {
        return;
      }

      if (
        approvalDetailsList.find((s) => s.techContRev !== commonTechContRev)
      ) {
        setApprovalDetailsList((detailsList) =>
          detailsList.map((detailsItem) => ({
            ...detailsItem,
            techContRev: commonTechContRev,
          }))
        );
      }
    },
    [commonTechContRev, approvalDetailsList]
  );

  const [commonSMEApprover, setCommonSMEApprover] = useState<string>();
  const [showSMEModal, setShowSMEModal] = useState(false);

  useEffect(
    function updateDetailsWhenCommonSMEIsSet() {
      setShowSMEModal(false);

      if (!commonSMEApprover) {
        return;
      }

      if (
        approvalDetailsList.find((s) => s.sMEApprover !== commonSMEApprover)
      ) {
        setApprovalDetailsList((detailsList) =>
          detailsList.map((detailsItem) => ({
            ...detailsItem,
            sMEApprover: commonSMEApprover,
          }))
        );
      }
    },
    [commonSMEApprover, approvalDetailsList]
  );

  const handleApprovalStatusChange = (
    index: number,
    status: ApprovalStatuses
  ) => {
    setApprovalDetailsList((currentDetailsList) => {
      const newDetailsList = [...currentDetailsList];
      newDetailsList[index] = { ...newDetailsList[index], status };
      return newDetailsList;
    });
  };

  const handleTechContRevChange = (index: number, techContRev: string) => {
    setApprovalDetailsList((statuses) => {
      const newStatuses = [...statuses];
      newStatuses[index] = { ...newStatuses[index], techContRev };
      return newStatuses;
    });
  };

  const handleSMEChange = (index: number, sMEApprover: string) => {
    setApprovalDetailsList((statuses) => {
      const newStatuses = [...statuses];
      newStatuses[index] = { ...newStatuses[index], sMEApprover };
      return newStatuses;
    });
  };

  const _onSubmit: FormEventHandler = (evt) => {
    evt.preventDefault();
    onSubmit({ ...rcUpdateApproval, details: approvalDetailsList });
  };

  const disableSubmit =
    !!submitRCUpdateApprovalOperation?.isWorking ||
    approvalDetailsList.length === 0 ||
    approvalDetailsList.findIndex(
      (d) =>
        d.status !== ApprovalStatuses.Approved &&
        d.status !== ApprovalStatuses.Rejected
    ) >= 0;

  const showTechContRevColumn = !!rcUpdateApproval.details.find(
    (d) => d.currentLevel === ApprovalLevels.TechComm
  );

  const showSMEColumn = !!rcUpdateApproval.details.find(
    (d) => d.currentLevel === ApprovalLevels.TechConRev
  );

  const reusableContentLink = rcUpdateApproval.reusableContentSW && (
    <Link
      target="_blank"
      className="rc-link"
      to={Routes.ViewStandardWork.replace(
        ":guid",
        rcUpdateApproval.reusableContentSW.guid
      ).replace(
        ":version",
        rcUpdateApproval.reusableContentSW.version.toString()
      )}
    >
      <p className="rc-title">
        Updated Content: {rcUpdateApproval.reusableContentSW.title}
      </p>
      <p className="rc-description">
        {rcUpdateApproval.reusableContentSW.description}
      </p>
    </Link>
  );

  const timeMediaLinks = rcUpdateApproval.timeMedia && (
    <>
      <p className="rc-title">
          Updated TIME Media ID: {rcUpdateApproval.timeMedia.mediaId}
      </p>
      <p className="rc-description">
          Updated Contents:
          {rcUpdateApproval.timeMedia.fileNames?.map(x =>
              <div key={x}>
                  {x}
              </div>
          )}
      </p>
      <div className="row row-left">
        {rcUpdateApproval.timeMedia.originalVersionLink && (
          <a
            className="primary-button"
            href={rcUpdateApproval.timeMedia.originalVersionLink}
            target="_blank"
          >
            View Old Content
          </a>
        )}
        {rcUpdateApproval.timeMedia.updatedVersionLink && (
          <a
            className="primary-button"
            href={rcUpdateApproval.timeMedia.updatedVersionLink}
            target="_blank"
          >
            View Updated Content
          </a>
        )}
      </div>
    </>
  );

  return (
    <>
      <FlowLayout
        header={
          <>
            <div className="row">
              <h3>
                {reusableContentLink}
                {timeMediaLinks}
              </h3>
            </div>
          </>
        }
      >
        <form onSubmit={_onSubmit}>
          <div className="row header">
            <h4>Affected SWs:</h4>
            <div className="row">
              <div>
                <input
                  type="checkbox"
                  id="approveAll_checkbox"
                  onChange={(e) => setApproveAll(e.target.checked)}
                />
                <label htmlFor="approveAll_checkbox">Approve All</label>
              </div>
              {showTechContRevColumn && (
                <div>
                  <input
                    type="search"
                    name="tech_content_approver_all"
                    placeholder="Tech Content Approver (Set for All)"
                    value={
                      commonTechContRev
                        ? `Tech. Cont. Rev: ${commonTechContRev}`
                        : ""
                    }
                    onClick={() => setShowTechContRevModal(true)}
                    readOnly
                  />
                </div>
              )}
              {showSMEColumn && (
                <div>
                  <input
                    type="search"
                    name="sme_approver_all"
                    placeholder="SME Approver (Set for All)"
                    value={commonSMEApprover ? `SME: ${commonSMEApprover}` : ""}
                    onClick={() => setShowSMEModal(true)}
                    readOnly
                  />
                </div>
              )}
            </div>
          </div>
          <RCUpdateApprovalDetailsTable
            approvalDetailsList={approvalDetailsList}
            onApprovalStatusChanged={handleApprovalStatusChange}
            onTechContRevChanged={handleTechContRevChange}
            readOnlyApprovalStatus={approveAll}
            readOnlyTechContRev={!!commonTechContRev}
            onSMEChanged={handleSMEChange}
            readOnlySME={!!commonSMEApprover}
            showSMEColumn={showSMEColumn}
            showTechContRevColumn={showTechContRevColumn}
          />

          <div className="submit-row">
            <input
              type="submit"
              value="Submit"
              className="primary-button"
              disabled={disableSubmit}
            />
          </div>
        </form>
      </FlowLayout>

      <Modal
        isOpen={showTechContRevModal}
        header="Set Tech Content Reviewer for all SWs"
        controls={
          <>
            <button
              className="secondary-button"
              onClick={() => {
                setShowTechContRevModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="primary-button"
              onClick={() => {
                setCommonTechContRev(undefined);
                setShowTechContRevModal(false);
              }}
            >
              Clear
            </button>
          </>
        }
      >
        <SearchTechContentUser
          setSelectedUserFunc={setCommonTechContRev}
          optional={false}
          isFilteredList={false}
          isLDAPSearch={true}
        />
      </Modal>

      <Modal
        isOpen={showSMEModal}
        header="Set SME for all SWs"
        controls={
          <>
            <button
              className="secondary-button"
              onClick={() => {
                setShowSMEModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="primary-button"
              onClick={() => {
                setCommonSMEApprover(undefined);
                setShowSMEModal(false);
              }}
            >
              Clear
            </button>
          </>
        }
      >
        <SearchUser setSelectedUserFunc={setCommonSMEApprover} />
      </Modal>
    </>
  );
};

export default ViewRCUpdateApprovalDetails;
