interface ICopyToClipboard {
  value: string;
  errorMessage: string;
}

export async function copyToClipboard(copyVale: ICopyToClipboard) {
  let copyValue = "";
  if (!navigator.clipboard) {
    throw new Error(
      `${copyVale.errorMessage}, browser don't have support for native clipboard.`
    );
  }

  if (copyVale.value) {
    copyValue = copyVale.value;
  }
  await navigator.clipboard.writeText(copyValue);
}
