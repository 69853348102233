import React, { useEffect, useState } from "react";
import Modal from '../../../common/Modal'
import searchIcon from "media/icons/dls/search.svg";
import "./AddTimeImageModal.scoped.scss";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import useDebounce from "utilities/useDebounce";
import { searchTimeImageNumber, setTimeImageData } from "store/manageSW/manageSWActions";
import AddIcon from "media/icons/dls/add.svg";
import { ITimeImageData } from "store/manageSW/manageSWTypes";

interface IAddTimeImageModal {
    onModalClick?(): void,
    timeImageData: ITimeImageData,
    uploadDownloadImage(data: ITimeImageData): void,
}

const AddTimeImageModal: React.FC<IAddTimeImageModal> = (props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 750);
    const {
        swListData: {
            loadOperation,
        },
    } = useSelector(store => store.swList);
    const image = props.timeImageData;
    const dispatch = useDispatch();

    useEffect(() => {
        if (debouncedSearchTerm.trim() === "") {
            dispatch(setTimeImageData({
                imageNumber: 0,
                link: "",
                label: "",
            }));
        }
        else {
            dispatch(searchTimeImageNumber({
                searchText: debouncedSearchTerm.trim(),
            }));
        }
    }, [debouncedSearchTerm, dispatch]);


    return (
        <Modal
            header="Search TIME image with ImageNumber"
            width="50%"
            top="50%"
            controls={(
                <>
                    <button
                        className="primary-button ok-button"
                        onClick={props.onModalClick}
                    >
                        Close
                    </button>
                </>
            )}
            isOpen={true}
        >
            <div className="input-holder">
                <input
                    type="text"
                    className="searchBar"
                    id="sw-search"
                    placeholder={"Begin typing to search..."}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    disabled={loadOperation?.isWorking}
                />
                <img
                    src={searchIcon}
                    alt="Search"
                    title="Search"
                    className="icon-small search-button"
                />
            </div>
            {image.imageNumber !== 0
                &&
                <div>
                    <div className="row header">

                        <div className="cell width20">
                            Image Number
                        </div>
                        <div className="cell width20">
                            Image Name
                        </div>
                        <div className="cell buttons width20">
                        </div>
                    </div>
                    <div className="row" key={image.imageNumber}>
                        <div className="cell width20">{image.imageNumber}</div>
                        <div className="cell width20">{image.label}</div>
                        <div className="cell buttons icon-small width20">
                            <img
                                src={AddIcon}
                                alt="Add to SW"
                                title="Select"
                                className="icon-small"
                                onClick={() => props.uploadDownloadImage(image)}
                            />

                        </div>
                    </div>
                </div>
            }

        </Modal>
    );
};

export default AddTimeImageModal;