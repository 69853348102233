import React, { useCallback, useEffect, useState } from "react";
import "./Toolbar.scoped.scss";
import AddSWConfirmationModal from '../AddSWConfirmationModal'
import SWTab from "./SWTab";
import documentsIcon from "media/icons/dls/documents.svg";
import numberedListIcon from "media/icons/dls/numbered-list.svg";
import settingsIcon from "media/icons/dls/settings.svg";
import commentIcon from "media/icons/dls/comments.svg";
import revisionIcon from "media/icons/dls/log.svg";
import searchIcon from "media/icons/dls/search.svg";
import noticeSummaryIcon from "media/icons/dls/list.svg";
import { ManageSWTabs } from "store/manageSW/manageSWTypes";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { setCurrentTab, save, unlockSWCurrentEditor, loadComments, setCreateReusableContentPopup, setVersionChanges, loadConfidentialEnabledUsers, setConfidentialEnabledUserList, removeConfidentialAccess, addConfidentialAccess, setBackgroundUpdateOperation, backgroundUpdate, loadConnectedSWIConfiguration } from "store/manageSW/manageSWActions";
import { submitDraftForApproval, loadApprovalHistory, retractSwFromApprovalFlow } from "store/approvals/approvalsActions";
import EllipsesDropdown from "components/common/EllipsesDropdown";
import { getPDF, getRCWhereUsedItems, setCreateDraftData, showCreateDraftConfirmation } from "store/swList/swListActions";
import validateSW from "utilities/sw/validateSW";
import { showErrorToast, showSuccessToast } from "store/toast/toastActions";
import Banner, { BannerType } from "components/common/Banner";
import formatDate from "utilities/formatDate";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { DataClassificationTypes, IStep, ISW, RCTypes, StepTypes, SWTypes, TemplateTypes } from "interfaces/sw/SWInterfaces";
import Modal from "components/common/Modal";
import Execution from "components/sw/executionPreview/Execution";
import { Routes } from "components/routing/Routing";
import RCMappingModal from "components/sw/search/RCMappingModal";
import CreateReusableContent from "components/sw/reusableContentFromTechSwi/CreateReusable";
import CreateDraftModal from "components/sw/search/CreateDraftModal";
import TimeMediaMappingModal from "../TimeMediaMappingModal";
import SelectMetadata from "components/sw/reusableContentFromTechSwi/SelectMetadata";
import ValidationModal from "./PopUp/ValidationModal";
import { HistoricalApprovalTypes, LockReasonTypes } from "interfaces/approvals/approvalInterfaces";
import ManageTCCModal from "../ManageTCCModal";
import UpdateGemsRevisionDialogModal from "../UpdateGemsRevisionDialogModal";
import { copyToClipboard } from "utilities/clipboard";
import { PdfTypes } from "../../../../interfaces/misc/miscInterfaces";

interface IToolbarProps {
  isSavable: boolean,
  isSubmittable: boolean,
  isLoggedInUserNotSWCurrentEditorUser: boolean,
}

const Toolbar: React.FC<IToolbarProps> = ({ isSavable, isSubmittable, isLoggedInUserNotSWCurrentEditorUser }) => {
  const {
    manageSW: {
      currentTab,
      SW,
      isDirty,
      commentData: {
        comments,
      },
      publishOperation,
      isCreateReusableContentOpen,
      isCreateRCNoticeOpen,
      timeMediaFilesMappingData,
      submitForReviewFlag,
      confidentialSWData,
      backgroundUpdateOperation,
      updateOperation,
      enableConnectedSWI,
    },
    swList: {
      rcMappingData,
      swListData,
      createDraftData,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isPPEWarningOpen, setIsPPEWarningOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [isExecPrevModalOpen, setIsExecPrevModalOpen] = useState(false);
  const [isCharLimitModalOpen, setIsCharLimitModalOpen] = useState(false);
  const [longTitleSteps, setLongTitleSteps] = useState<IStep[]>([]);
  const [isVersionChangesOpen, setIsVersionChangesOpen] = useState(false);
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);
  const [autoSaveTrigger, setAutoSaveTrigger] = useState(0);
  const [lastSuccessfulSave, setLastSuccessfulSave] = useState<Date>();
  const [showAutosaveErrorModal, setShowAutosaveErrorModal] = useState(false);

  useEffect(() => {
    if (SW.type === SWTypes.MFGSWI) {
      dispatch(loadConnectedSWIConfiguration());
    }
  }, [dispatch, SW.type]);

  const {
    guid,
    id,
    version,
    ppe,
    type,
    activeApprovalRequestId,
    activeApprovalLevel,
    lockReasonType,
    approvalType,
    currentEditor,
    currentEditorLockedDate,
    owningOrg,
    owningPlant,
    activeApprovalUserName,
    rcType,
  } = SW;
  let history = useHistory();

  const [showUpdateGemsRevisionDialog, setShowUpdateGemsRevisionDialog] =
    useState(false);

  const swData = swListData.swList.find(x => x.id === id);
  const wasCreateDraftSuccessful = createDraftData.createDraftOperation?.wasSuccessful;
  const approvalMessageOnSWPage = approvalType == HistoricalApprovalTypes.BatchApproval ? ' for Batch Metadata Update' : ' for RC update approval workflow';

  useEffect(() => {
    if (wasCreateDraftSuccessful) {
      dispatch(
        setCreateDraftData({
          createDraftOperation: undefined,
          guid: "",
          version: -1,
          duplicate: false,
          isWaitingForConfirm: false,
          thumbsDownCount: 0,
          thumbsUpCount: 0,
          swType: SWTypes.Unspecified,
          newOwningOrgId: undefined,
          newOwningPlantId: undefined,
          updateGemsRevision: undefined,
        })
      );
    }
  }, [wasCreateDraftSuccessful, dispatch]);

  const confirmSubmittal = useCallback(() => {
    const confirm = window.confirm(
      "Are you sure you want to submit this standard work for review?"
    );

    if (confirm) {
      dispatch(
        submitDraftForApproval({
          swGuid: guid,
          swType: type,
        })
      );
    }
  }, [dispatch, guid]);

  const onSubmitForReviewClick = useCallback(() => {
    if (type === SWTypes.CL || type === SWTypes.LCL) {
      setIsContinue(false);
    }

    const _longTitleSteps = findLongTitleSteps(SW);
    setLongTitleSteps(_longTitleSteps);

    if (_longTitleSteps.length > 0) {
      setIsCharLimitModalOpen(true);
      return;
    }

    confirmSubmittal();
  }, [type, confirmSubmittal, SW]);

  useEffect(() => {
    if (location.search === "?showComments"
      && guid.length) {
      dispatch(loadComments({
        swGuid: guid,
        swVersion: version,
      }));
    }
  }, [location, dispatch, guid, version]);
  const unResolvedComments: boolean = SW.unResolvedStepComments
    ? SW.unResolvedStepComments > 0
      ? false
      : true
    : true;

  const unResolvedSWComments: boolean = comments
    ? comments.some(x => x.resolved === false)
      ? false
      : true
    : true;

  useEffect(() => {
    if (isContinue) {
      onSubmitForReviewClick();
    }
  }, [isContinue, onSubmitForReviewClick]);

  useEffect(() => {
    if (submitForReviewFlag) {
      dispatch(
        submitDraftForApproval({
          swGuid: guid,
          swType: type,
        })
      );
    }
  }, [submitForReviewFlag, dispatch, submitDraftForApproval]);

  const saveData = (versionChanges: string) => {
    dispatch(setVersionChanges(versionChanges));
    setIsVersionChangesOpen(false);
    saveSW(true);
  }

  const onModalClick = (isContinue: boolean) => {
    setIsPPEWarningOpen(false);
    setIsContinue(isContinue);
  }

  function checkVersionChangesRequired(): boolean {
    if (SW.versionChanges === null ||
      SW.versionChanges === undefined ||
      SW.versionChanges === "") {
      return true;
    }
    return false;
  }

  const checkPPEerror = () => {
    if (type === SWTypes.CL && ppe.length === 0 && !isContinue) {
      setIsPPEWarningOpen(true);
      return;
    }
    else if (checkVersionChangesRequired()) {
      setIsVersionChangesOpen(true);
    }
    else {
      onSubmitForReviewClick();
    }
  }

  const onRetractStandardWorkApprovalFlowClick = () => {
    if (window.confirm("Are you sure you want to retract this standard work from approval workflow?")) {
      dispatch(retractSwFromApprovalFlow({
        swId: id,
        approvalRequestId: activeApprovalRequestId,
      }))
    }
  }

  const onUnlockSWCurrentEditor = () => {
    if (window.confirm(`${currentEditor} has locked this content as of ${formatDate(currentEditorLockedDate)}. Do you want to unlock it and tranfer the lock?`)) {
      dispatch(unlockSWCurrentEditor({
        swGuid: guid,
        owningOrgId: owningOrg?.guid,
        owningPlantId: owningPlant?.guid,
      }));
    }
    return false;
  }

  const onApprovalHistoryClick = () => {
    dispatch(loadApprovalHistory({
      swId: id,
    }))
  }

  const onDownloadPDFClick = (pdfType: string) => {
    dispatch(
      getPDF({
        swId: id,
        pdfType
      })
    );
  }
  
  const onIsExecPrevClick = () => {
    setIsExecPrevModalOpen(true);
  }

  const onCreateReusableContentClick = () => {
    if (isDirty) {
      dispatch(showErrorToast('Please save the SW content before creating reusable content.'));
    }
    else {
      dispatch(setCreateReusableContentPopup({
        isCreateRCPopup: true,
        swType : SW.type,
      }));
    }
  }

  const [showTccDialog, setShowTccDialog] = useState(false);

  const onManageConfidentialTCCSWClick = () => {
    setShowTccDialog(true);
  }

  useEffect(() => {
    if (showTccDialog) {
      dispatch(loadConfidentialEnabledUsers({
        swId: id,
      }));
    } else {
      dispatch(setConfidentialEnabledUserList([]));
    }
  }, [showTccDialog]);

  const tccIsLoading =
    confidentialSWData.changeOperation.isWorking ||
    confidentialSWData.loadOperation.isWorking;

  const findLongTitleSteps = (sw: ISW) => {
    const longTitles: IStep[] = [];

    const checkStepAndAdd = (step: IStep) => {
      if (step.title.length > 250) {
        longTitles.push(step);

        for (const childStep of step.children) {
          checkStepAndAdd(childStep);
        }
      }
    };

    for (const step of sw.steps) {
      checkStepAndAdd(step);
    }

    return longTitles;
  }

  const autoSaveSW = () => {
    if(backgroundUpdateOperation?.isWorking) {
      return;
    }

    const validationResult = validateSW(SW);

    if (!validationResult.isValid) {
      dispatch(setBackgroundUpdateOperation({
        isWorking: false,
        wasSuccessful: false,
        errorMessage: validationResult.errorMessage,
      }));

      return;
    }

    dispatch(backgroundUpdate());
  }

  const saveSW = (isVersionChange: boolean) => {
    const validationResult = validateSW(SW);

    if (!validationResult.isValid) {
      dispatch(showErrorToast(validationResult.errorMessage));
      return;
    }

    dispatch(save({ isVersionChange: isVersionChange }));
  }
  const renderCommentBanner: boolean = FRenderCommentBanner();

  function FRenderCommentBanner(): boolean {
    let stepComments = SW.unResolvedStepComments
      ? SW.unResolvedStepComments > 0
        ? true
        : false
      : false;

    let swComments = comments
      ? comments.some(x => x.resolved === false)
        ? true
        : false
      : false;

    return (stepComments || swComments);
  }

  const onCommentsClicked = () => {
    dispatch(loadComments({
      swGuid: guid,
      swVersion: version,
    }));
  }

  const onVersionClick = (version: number) => {
    history.push(Routes.ViewStandardWork
      .replace(":guid", guid)
      .replace(":version", version.toString()));
  }

  const shortenTitle = (title: string) => title.substring(0, 40) + "...";

  const onCreateDraftClick = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    _e.preventDefault();
    dispatch(
      showCreateDraftConfirmation({
        guid: guid,
        version: null,
        duplicate: false,
        thumbsDownCount: swData?.thumbsDownCount,
        thumbsUpCount: swData?.thumbsUpCount,
        swType: SWTypes.Unspecified,
        newOwningOrgId: undefined,
        newOwningPlantId: undefined,
        updateGemsRevision: undefined,
      })
    );
  };

  const onCreateDraftWithUpdatedRevision = () => {
    dispatch(
      showCreateDraftConfirmation({
        guid: guid,
        version: null,
        duplicate: false,
        thumbsDownCount: swData?.thumbsDownCount,
        thumbsUpCount: swData?.thumbsUpCount,
        swType: SWTypes.Unspecified,
        newOwningOrgId: undefined,
        newOwningPlantId: undefined,
        updateGemsRevision: true,
      })
    );

    setShowUpdateGemsRevisionDialog(false);
  }

  const showCcqPdf = () => {
    if (rcType !== RCTypes.Notice) {
      return {
          key: SW.type === SWTypes.TLMSWI || SW.type === SWTypes.TLMRC || SW.type === SWTypes.MFGRC ? "Competency - CPA" : "CCQ PDF",
          label: SW.type === SWTypes.TLMSWI || SW.type === SWTypes.TLMRC || SW.type === SWTypes.MFGRC ? "Competency - CPA" : "CCQ PDF",
        onClick: () => (SW.type === SWTypes.TLMSWI || 
        SW.type === SWTypes.TLMRC || 
        SW.type === SWTypes.MFGRC) ? 
          onDownloadPDFClick(PdfTypes.CompetencyCPA):
          onDownloadPDFClick(PdfTypes.CCQ)
      }
    }
    return undefined;
  }

  const showCopyGuid=()=>
  {
    return {
      key: "Copy Workright GUID",
      label: "Copy Workright GUID",
      onClick: onCopyWRGUIDClick,
    };
  }

  const findLabelisTaskSteporNotice = (): string => {
    var compType = "";
    if (SW.type === SWTypes.TLMSWI || SW.type === SWTypes.MFGSWI) {
      return "Tasks";
    }

    if (SW.type === SWTypes.TLMRC || SW.type === SWTypes.MFGRC) {
      if (SW.steps.length === 0) {
        if (SW.notices.length > 0) {
          compType = "Notice";
        } else {
          if (SW.type === SWTypes.TLMRC) {
            compType = "Tasks/Steps/SubSteps/Notice";
          } else {
            compType = "Steps/SubSteps/Notice";
          }
        }
      }
      else {
        if (rcType === RCTypes.Task) {
          compType = "Tasks";
        }
        else if (rcType === RCTypes.Step) {
          compType = "Steps";
        }
        else {
          compType = "SubSteps";
        }
      }
    }
    else {
      compType = "Steps";
    }

    return compType;
  }

  const getLockReasonMessage = () => {
    const defaultMessage = <></>;
    switch (lockReasonType) {
      case LockReasonTypes.BatchUpdateProcessing:
        return (<>Document is being updated with Batch Update data</>);
      case LockReasonTypes.TimeRCProcessing:
        return (<>Document is being updated with TIME RC data</>);
      case LockReasonTypes.RCUpdateProcessing:
        return (<>Document is being updated with RC data</>);
      default:
        return defaultMessage;
    }
  }

  const showSaveButton = isSavable && !isLoggedInUserNotSWCurrentEditorUser;

  useEffect(() => {
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }

    const timeout = setTimeout(() => {
      setAutoSaveTrigger(n => n + 1);
    }, 10000);

    setSaveTimeout(timeout);

    return () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
  }, [isDirty]);

  useEffect(() => {
    if (
      backgroundUpdateOperation?.wasSuccessful ||
      updateOperation?.wasSuccessful
    ) {
      setLastSuccessfulSave(new Date());
    }
  }, [backgroundUpdateOperation, updateOperation]);

  useEffect(() => {
    if (updateOperation?.wasSuccessful) {
      dispatch(setBackgroundUpdateOperation(undefined));
    }
  }, [updateOperation]);

  useEffect(() => {
    if (showSaveButton && isDirty && autoSaveTrigger > 0) {
      autoSaveSW();
    }
  }, [autoSaveTrigger]);

  useEffect(() => {
    dispatch(setBackgroundUpdateOperation(undefined));
    setLastSuccessfulSave(undefined);
  }, [id, version]);

  const autoSaveButton = (
    <button
      className={
        "secondary-button " +
        (backgroundUpdateOperation?.errorMessage ? "autosave-error" : "")
      }
      onClick={() =>
        setShowAutosaveErrorModal(
          !!backgroundUpdateOperation?.errorMessage
        )
      }
    >
      {backgroundUpdateOperation?.errorMessage
        ? "Autosave Failed"
        : backgroundUpdateOperation?.isWorking
        ? "Saving..."
        : lastSuccessfulSave
        ? `Last saved ${formatDate(lastSuccessfulSave)}`
        : "Autosave Enabled"}
    </button>
  );

  const onCopyWRGUIDClick = () => {
    try {
     
      copyToClipboard({
          value: guid,
          errorMessage: "Unable to copy Workright GUID",
        })
      
      dispatch(showSuccessToast("Workright GUID was copied"));
    } catch (err: any) {
      dispatch(showErrorToast(err));
    }
  }; 

  return (
    <>
      <div className="toolbar">
        {SW.type !== SWTypes.TIMERC &&
          <div className="tabs">
            <SWTab
              isActive={currentTab === ManageSWTabs.Attributes}
              label="Metadata"
              icon={settingsIcon}
              onClick={() => dispatch(setCurrentTab(ManageSWTabs.Attributes))}
            />
            <SWTab
              isActive={currentTab === ManageSWTabs.Steps}
              label={findLabelisTaskSteporNotice()}
              icon={numberedListIcon}
              onClick={() => dispatch(setCurrentTab(ManageSWTabs.Steps))}
            />

            {SW.type !== SWTypes.TLMRC &&
              SW.type !== SWTypes.MFGRC &&
              <SWTab
                isActive={currentTab === ManageSWTabs.RefDocs}
                label="Ref Docs"
                icon={documentsIcon}
                onClick={() => dispatch(setCurrentTab(ManageSWTabs.RefDocs))}
              />}
            <SWTab
              isActive={currentTab === ManageSWTabs.Comments}
              label="Comments"
              icon={commentIcon}
              onClick={() => dispatch(setCurrentTab(ManageSWTabs.Comments))}
            />
            <SWTab
              isActive={currentTab === ManageSWTabs.RevisionHistory}
              label="Revision History"
              icon={revisionIcon}
              onClick={() => dispatch(setCurrentTab(ManageSWTabs.RevisionHistory))}
            />
            {type === SWTypes.MFGSWI && enableConnectedSWI &&
              <SWTab
                  isActive={currentTab === ManageSWTabs.ConnectedSWIs}
                  label="Connected SWIs"
                  icon={documentsIcon}
                  onClick={() => dispatch(setCurrentTab(ManageSWTabs.ConnectedSWIs))}
              />
            }
            {(type === SWTypes.TLMSWI || type === SWTypes.TLMRC) &&
                <SWTab
                    isActive={currentTab === ManageSWTabs.NoticeSummary}
                    label="Notice Summary"
                    icon={noticeSummaryIcon}
                    onClick={() => dispatch(setCurrentTab(ManageSWTabs.NoticeSummary))}
                />
            }
          </div>
        }

        {isPPEWarningOpen &&
          <AddSWConfirmationModal
            onModalClick={onModalClick}
            header="PPE Warning"
            message="You have not added PPE. If you are executing work or preparing tools and services that involve manipulation of equipment, please select PPE."
          />
        }

        {isVersionChangesOpen &&
          <ValidationModal
            saveData={saveData}
            header="Version Changes Required!"
            message="Please enter a summary of the changes you made to this version."
          />
        }

        {!isSubmittable && !(isSavable &&
          isLoggedInUserNotSWCurrentEditorUser) &&
          <Banner
            type={BannerType.Warn}
          >
            {approvalType == null &&
              getLockReasonMessage()
            }
            {approvalType != null &&
              <>Document is Pending <b>{activeApprovalLevel}</b> Review {activeApprovalUserName?.length ? ` by ${activeApprovalUserName}` : ""}
                {!activeApprovalRequestId && approvalType == HistoricalApprovalTypes.BatchApproval && ' for Batch Metadata Update'}
                {activeApprovalRequestId && approvalType == HistoricalApprovalTypes.RCUpdateApproval &&
                  <span> for&nbsp;
                    <Link
                      className="sw-link"
                      target="_blank"
                      to={Routes.ViewRCUpdateApproval.replace(
                        ":id",
                        String(activeApprovalRequestId)
                      )}>
                      RC Update Approval
                    </Link>
                  </span>
                }
              </>
            }
          </Banner>
        }
        {isSavable &&
          isLoggedInUserNotSWCurrentEditorUser &&
          <Banner
            type={BannerType.Warn}
          >
            {!isSubmittable &&
              <div>Document is Pending<b>{activeApprovalLevel}</b> Review {activeApprovalUserName?.length ? ` by ${activeApprovalUserName}` : ""}</div>
            }
            {currentEditor} has locked this content as of {formatDate(currentEditorLockedDate)}.
          </Banner>
        }
        {renderCommentBanner &&
          <Banner
            type={BannerType.Error}
          >
            You have &nbsp;
            {
              (SW.unResolvedStepComments
                ? SW.unResolvedStepComments
                : 0) +
              (comments
                ? comments.filter(x => x.resolved === false).length
                : 0)
            }
            &nbsp;out of&nbsp;
            {
              (SW.totalStepComments
                ? SW.totalStepComments
                : 0) +
              (comments
                ? comments.length
                : 0)
            }
            &nbsp;comments left to resolve
          </Banner>
        }
        {isExecPrevModalOpen &&
          <Modal
            header="Execution Preview"
            isOpen={isExecPrevModalOpen}
            controls={(
              <button
                className="primary-button"
                onClick={() => setIsExecPrevModalOpen(false)}
              >
                Close
              </button>
            )}
            width={"100%"}
            maxHeight={"100%"}
          >
            <Execution />
          </Modal>
        }
        {rcMappingData.loadRCMappingData?.wasSuccessful &&
          < RCMappingModal />
        }
        {timeMediaFilesMappingData.loadTimeImagesOperation?.wasSuccessful &&
          < TimeMediaMappingModal />
        }
        {isCreateReusableContentOpen &&
          <Modal
            header="Create Reusable Content"
            isOpen={isCreateReusableContentOpen}
            width={"65%"}
            maxHeight={"93%"}
          >
            <CreateReusableContent />
          </Modal>
        }
        {isCreateRCNoticeOpen &&
          <Modal
            header="Create RC Notice"
            isOpen={isCreateRCNoticeOpen}
            width={"65%"}
            maxHeight={"93%"}
          >
            <SelectMetadata
              rcType={RCTypes.Notice} />
          </Modal>
        }

        <Modal
          header="Warning: Long Titles"
          isOpen={isCharLimitModalOpen}
          controls={
            <>
              <button
                className="secondary-button"
                onClick={() => setIsCharLimitModalOpen(false)}
              >
                Go Back
              </button>
              <button
                className="primary-button"
                onClick={() => {
                  setIsCharLimitModalOpen(false);
                  setTimeout(() => confirmSubmittal());
                }}
              >
                Submit Anyway
              </button>
            </>
          }
        >
          <p>
            Some step/task titles exceed 250 character length. Do you wish to{" "}
            <b>Submit</b> anyway?
          </p>
          <div className="step-list">
            <ul>
              Exceeding titles are:
              {longTitleSteps.map((step, index) => (
                <li key={index}>
                  Step
                  {step.stepNumber ? " " + step.stepNumber + " - " : " "}
                  {shortenTitle(step.title)}
                </li>
              ))}
            </ul>
          </div>
        </Modal>

        {SW.type !== SWTypes.TIMERC &&
          <div className="buttons">
            {SW.type === SWTypes.TLMRC &&
              SW.versions?.find(x => x > 0) &&
              < button
                className="where-used-button"
                onClick={() => dispatch(
                  getRCWhereUsedItems({
                    rcID: SW.id,
                  })
                )}
              >
                <img
                  className="icon-small"
                  src={searchIcon}
                  alt="Where Used"
                  title="Where Used"
                />
              </button>
            }
            {isSavable &&
              !isLoggedInUserNotSWCurrentEditorUser &&
              <button
                className={`comments-button-${!unResolvedSWComments
                  ? "full"
                  : "empty"}`}
                onClick={() => onCommentsClicked()}
              >
                <img
                  className="icon-small"
                  src={commentIcon}
                  alt="Comments"
                />
              </button>
            }
            {isSavable &&
              isLoggedInUserNotSWCurrentEditorUser &&
              <button
                className="primary-button"
                onClick={() => onUnlockSWCurrentEditor()}
              >
                Unlock
              </button>
            }
            {createDraftData.isWaitingForConfirm &&
              <CreateDraftModal {...createDraftData} />
            }
            {
              SW.gemsRevisionNumber?.isOutdated &&
              <button
                className="secondary-button gems-revision-button"
                title="The associated Material Revision Number is outdated. Click to update."
                onClick={() => setShowUpdateGemsRevisionDialog(true)}
              >
                Update SWI Version
              </button>
            }
            {version !== 0 &&
              <button
                className="secondary-button"
                onClick={(e) => onCreateDraftClick(e)}
              >
                Create Draft
              </button>
            }
            {(createDraftData.createDraftOperation?.wasSuccessful) &&
              <Redirect
                to={Routes.EditStandardWork.replace(":guid", createDraftData.guid).replace(":version", "0")}
              />
            }
            {showSaveButton &&
              <button
                className="primary-button"
                onClick={() => saveSW(false)}
                disabled={!isDirty || backgroundUpdateOperation?.isWorking}
              >
                Save
              </button>
            }
            {isSavable &&
              !isLoggedInUserNotSWCurrentEditorUser &&
              <button
                className="secondary-button"
                disabled={!isSubmittable || !!isDirty || !unResolvedComments || !unResolvedSWComments}
                title={!isSubmittable
                  ? "An active approval request already exists."
                  : undefined
                }
                onClick={checkPPEerror}
              >
                Submit for Review
              </button>
            }
            {!isSubmittable &&
              approvalType == HistoricalApprovalTypes.Approval &&
              activeApprovalRequestId &&
              <button
                className="secondary-button"
                onClick={onRetractStandardWorkApprovalFlowClick}
              >
                Retract
              </button>
            }
            {(SW.versions &&
              SW.version > 0) &&
              <EllipsesDropdown
                label="Versions ▼"
                itemTextAlign="center"
                items={
                  SW.versions.map(x =>
                  ({
                    key: x.toString(),
                    label:
                      <label
                        className={`versionLabel ${x === version ? "Active" : ""}`}>
                        {x.toString()}
                      </label>,
                    onClick: () => onVersionClick(x),
                  }))
                }
              />
            }
            {type !== SWTypes.TLMRC &&
              <EllipsesDropdown
                items={[
                  {
                    key: "Approval History",
                    label: "Approval History",
                    onClick: onApprovalHistoryClick,
                  },
                  {
                    key: SW.type === SWTypes.SWI ? "Download Field PDF" : SW.type === SWTypes.TLMSWI ? "Full PDF" : SW.type === SWTypes.MFGSWI ? "Full training PDF" : "Download PDF",
                    label: SW.type === SWTypes.SWI ? "Download Field PDF" : SW.type === SWTypes.TLMSWI ? "Full PDF" : SW.type === SWTypes.MFGSWI ? "Full training PDF" : "Download PDF",
                    onClick: () => {
                      SW.type === SWTypes.SWI ? onDownloadPDFClick(PdfTypes.Field) :
                        SW.type === SWTypes.TLMSWI ? onDownloadPDFClick(PdfTypes.Full) :
                          SW.type === SWTypes.MFGSWI ? onDownloadPDFClick(PdfTypes.FullTraining) : 
                            onDownloadPDFClick(PdfTypes.Normal)
                    }
                  },
                  (SW.type === SWTypes.SWI) ?
                    {
                      key: "Download Full Training PDF",
                      label: "Download Full Training PDF",
                      onClick: () => onDownloadPDFClick(PdfTypes.FullTraining),
                    } : undefined,
                  (SW.type === SWTypes.TLMSWI) ?
                    ( 
                      SW.templateType === TemplateTypes.Consolidated ?
                      {
                        key: "Consolidated PDF",
                        label: "Consolidated PDF",
                        onClick: () => onDownloadPDFClick(PdfTypes.Consolidated)
                      }
                      :
                      {
                        key: "Standard PDF",
                        label: "Standard PDF",
                        onClick: () => onDownloadPDFClick(PdfTypes.Standard),
                      }) : undefined,
                  (SW.type === SWTypes.MFGSWI) ?
                    {
                      key: "Reference PDF",
                      label: "Reference PDF",
                      onClick: () => onDownloadPDFClick(PdfTypes.Reference),
                    } : undefined,
                  (SW.type === SWTypes.TLMSWI) ?
                    {
                      key: "Executable PDF",
                      label: "Executable PDF",
                      onClick: () => onDownloadPDFClick(PdfTypes.Executable),
                    } : undefined,
                  (SW.type === SWTypes.CL ||
                    SW.type === SWTypes.LCL ||
                    SW.type === SWTypes.SWI ||
                    SW.type === SWTypes.TLMRC ||
                    SW.type === SWTypes.TLMSWI ||
                    SW.type === SWTypes.MFGSWI ||
                    SW.type === SWTypes.MFGCL ||
                    SW.type === SWTypes.MFGRC) ?
                    showCcqPdf() : undefined,
                  (SW.type !== SWTypes.MFGRC) ?
                  {
                    key: "Execution Preview",
                    label: "Execution Preview",
                    onClick: onIsExecPrevClick,
                  }: undefined,
                  (SW.type === SWTypes.TLMSWI ||
                    SW.type === SWTypes.MFGSWI) ?
                    {
                      key: "Create Reusable Content",
                      label: "Create Reusable Content",
                      onClick: onCreateReusableContentClick,
                    } : undefined,
                  (SW.dataClassification ===
                    DataClassificationTypes.Confidential &&
                    SW.tcc &&
                    !isDirty && {
                    key: "Manage Confidential TCC SW",
                    label: "Manage Confidential TCC SW",
                    onClick: onManageConfidentialTCCSWClick,
                  }) ||
                  undefined,
                  showCopyGuid()
                ]}
              />}
            {type === SWTypes.TLMRC && <EllipsesDropdown
              items={[
                {
                  key: "Approval History",
                  label: "Approval History",
                  onClick: onApprovalHistoryClick,
                },
                {
                  key: "Download PDF",
                  label: "Download PDF",
                  onClick: () => onDownloadPDFClick(PdfTypes.Normal),
                },
                showCcqPdf(),
                showCopyGuid()
              ]}
            />}
            {publishOperation?.wasSuccessful &&
              <Redirect
                to={Routes.SearchStandardWork}
              />
            }
          </div>
        }
      </div>

      {SW.type !== SWTypes.TIMERC && showSaveButton && (
        <div className="buttons autosave-row">{autoSaveButton}</div>
      )}

      {SW.showMFGUsageWarning && !activeApprovalLevel && (
        <div className="banner">
          <Banner type={BannerType.Warn}>
            This RC is being used in an MFG SWI/RC. Any edit to this SW, may
            potentially not show this SW on ME POD
          </Banner>
        </div>
      )}

      <ManageTCCModal
        isOpen={showTccDialog}
        onAddUser={(userId, role) =>
          dispatch(addConfidentialAccess({ swId: id, userId, role }))
        }
        onRemoveUser={(userId, role) =>
          dispatch(removeConfidentialAccess({ swId: id, userId, role }))
        }
        isLoading={tccIsLoading}
        currentUsers={confidentialSWData.confidentialEnabledUsers}
        onClose={() => setShowTccDialog(false)}
      />

      {backgroundUpdateOperation?.errorMessage && (
        <Modal
          header="Autosave Error"
          isOpen={showAutosaveErrorModal}
          controls={
            <button
              className="primary-button"
              onClick={() => setShowAutosaveErrorModal(false)}
            >
              Close
            </button>
          }
        >
          <p>{backgroundUpdateOperation.errorMessage}</p>
        </Modal>
      )}

      {!activeApprovalRequestId && version !== 0 && (
        <UpdateGemsRevisionDialogModal
          isOpen={showUpdateGemsRevisionDialog}
          notice={
            <>
              <p>Current SW is associated with an outdated Revision Number.</p>
              <p>
                You can Create a Draft with an updated Material Revision or
                continue to use as is.
              </p>
            </>
          }
          primaryAction={{
            label: "Update Revision Number",
            onClick: onCreateDraftWithUpdatedRevision,
          }}
          secondaryAction={{
            label: "Use as is",
            onClick: () => setShowUpdateGemsRevisionDialog(false),
          }}
        />
      )}

      {!activeApprovalRequestId && version === 0 && (
        <UpdateGemsRevisionDialogModal
          isOpen={showUpdateGemsRevisionDialog}
          notice={
            <>
              <p>Current draft is contains an outdated Revision Number.</p>
              <p>You can change the Revision Number in the Metadata.</p>
            </>
          }
          primaryAction={{
            label: "Go to Metadata section",
            onClick: () => {
              dispatch(setCurrentTab(ManageSWTabs.Attributes));
              setShowUpdateGemsRevisionDialog(false);
            },
          }}
          secondaryAction={{
            label: "Change Later",
            onClick: () => setShowUpdateGemsRevisionDialog(false),
          }}
        />
      )}

      {activeApprovalRequestId && (
        <UpdateGemsRevisionDialogModal
          isOpen={showUpdateGemsRevisionDialog}
          notice={
            <>
              <p>Current SW is associated with an outdated Revision Number.</p>
              <p>
                You can Retract the SW from the Approval Flow or continue to use
                as is.
              </p>
            </>
          }
          primaryAction={{
            label: "Retract SW from Approval Flow",
            onClick: () => {
              onRetractStandardWorkApprovalFlowClick();
              setShowUpdateGemsRevisionDialog(false);
            },
          }}
          secondaryAction={{
            label: "Continue as is",
            onClick: () => setShowUpdateGemsRevisionDialog(false),
          }}
        />
      )}
    </>
  );
};

export default Toolbar;