import React from "react";
import {
  Roles,
  UserApprovalLevels,
  IManageUserUser,
} from "interfaces/user/UserInterfaces";
import useSelector from "store/useSelector";
import Error403 from "components/common/Error403";

export enum FilterType {
  Any,
  All,
  Only,
}

export class AuthFilter {
  public emails: string[] = [];
  public orgIds: string[] = [];
  public roles: Roles[] = [];
  public approvalLevels: UserApprovalLevels[] = [];
  public orgFilterType: FilterType = FilterType.Any;
  public roleFilterType: FilterType = FilterType.Any;
  public approvalLevelFilterType: FilterType = FilterType.Any;
  public bypassForSysAdmins: boolean = true;
  public lastMatchResult?: boolean;
  public lastMatchForbiddenReason?: string;

  private constructor() {}

  static isLoggedIn() {
    return new AuthFilter();
  }

  excludeSysAdmins() {
    this.bypassForSysAdmins = false;
    return this;
  }

  includeSysAdmins() {
    this.bypassForSysAdmins = true;
    return this;
  }

  hasEmailIn(emails: string[]) {
    this.emails = emails.map((x) => x.toLowerCase());
    return this;
  }

  hasEmail(email: string) {
    this.emails = [email.toLowerCase()];
    return true;
  }

  hasAnyOrgIn(orgIds: string[]) {
    this.orgIds = orgIds.map((x) => x.toLowerCase());
    this.orgFilterType = FilterType.Any;
    return this;
  }

  hasAnyRoleIn(roles: Roles[]) {
    this.roles = roles;
    this.roleFilterType = FilterType.Any;
    return this;
  }

  hasAnyApprovalLevelsIn(appLevels: UserApprovalLevels[]) {
    this.approvalLevels = appLevels;
    this.approvalLevelFilterType = FilterType.Any;
    return this;
  }

  hasAllOrgsIn(orgIds: string[]) {
    this.orgIds = orgIds;
    this.orgFilterType = FilterType.All;
    return this;
  }

  hasAllRolesIn(roles: Roles[]) {
    this.roles = roles;
    this.roleFilterType = FilterType.All;
    return this;
  }

  hasAllApprovalLevelsIn(appLevels: UserApprovalLevels[]) {
    this.approvalLevels = appLevels;
    this.approvalLevelFilterType = FilterType.All;
    return this;
  }

  hasOrg(orgId: string) {
    this.orgIds = [orgId.toLowerCase()];
    this.orgFilterType = FilterType.Any;
    return this;
  }

  hasRole(role: Roles) {
    this.roles = [role];
    this.roleFilterType = FilterType.Any;
    return this;
  }

  hasOnlyRole(role: Roles) {
    this.roles = [role];
    this.roleFilterType = FilterType.Only;
    return this;
  }

  hasApprovalLevel(appLevel: UserApprovalLevels) {
    this.approvalLevels = [appLevel];
    this.approvalLevelFilterType = FilterType.Any;
    return this;
  }

  isMatch(user: IManageUserUser): boolean {
    const { email, roles } = user;

    if (!email) {
      // User is not logged in.
      this.lastMatchResult = false;
      this.lastMatchForbiddenReason = "User is not authenticated";
      return false;
    }

    if (
      this.bypassForSysAdmins &&
      roles.indexOf(Roles.SysAdmin) > -1 &&
      this.roleFilterType !== FilterType.Only
    ) {
      return true;
    }

    if (this.emails.length) {
      if (this.emails.indexOf(email.toLowerCase()) === -1) {
        this.lastMatchResult = false;
        this.lastMatchForbiddenReason =
          "Users email does not match any specified emails.";
        return false;
      }
    }

    if (
      !this.orgIds.length &&
      !this.roles.length &&
      !this.approvalLevels.length
    ) {
      // There are no specific filters.
      // That means it's a match.
      this.lastMatchResult = true;
      this.lastMatchForbiddenReason = undefined;
      return true;
    }

    if (this.roles.length) {
      switch (this.roleFilterType) {
        case FilterType.Any:
          if (!roles.find((x) => this.roles.indexOf(x) > -1)) {
            this.lastMatchResult = false;
            this.lastMatchForbiddenReason =
              "User does not match any specified roles.";
            return false;
          }
          break;

        case FilterType.All:
          if (this.roles.find((x) => roles.indexOf(x) === -1)) {
            this.lastMatchResult = false;
            this.lastMatchForbiddenReason =
              "User does not match all specified roles.";
            return false;
          }
          break;

        case FilterType.Only:
          if (this.roles.find((x) => roles.indexOf(x) === -1)) {
            this.lastMatchResult = false;
            this.lastMatchForbiddenReason = "User does not match specified role.";
            return false;
          } else if (
            this.roles.find((x) => roles.indexOf(x) !== -1) &&
            roles.length > 1
          ) {
            this.lastMatchResult = false;
            this.lastMatchForbiddenReason = "User does not match only specified role.";
            return false;
          }
          break;
      }
    }
    this.lastMatchResult = true;
    this.lastMatchForbiddenReason = undefined;
    return true;
  }
}

interface IAuthCheckProps {
  authFilter: AuthFilter;
  or?: AuthFilter[];
  show403OnFail?: boolean;
  children: any;
}

export const AuthCheck: React.FC<IAuthCheckProps> = ({
  authFilter,
  or,
  children,
  show403OnFail = false,
}) => {
  const user = useSelector((store) => store.auth.currentUser);

  if (!authFilter.isMatch(user)) {
    return show403OnFail ? <Error403 /> : null;
  }

  return <>{children}</>;
};
