import { ISignatureInputComponent } from "interfaces/sw/SWInterfaces";
import React, { useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import "./SignatureInput.scoped.scss";

interface ISignatureInputProps {
  component: ISignatureInputComponent,
  isDisabled: boolean,
}

interface ISignatureInputState {
  isPadVisible: boolean,
  signatureDataUri?: string,
  initialDataUri?: string,
}

interface ISignaturePadRef {
  clear(): void,
  fromData(data: any): void,
  toData(): any,
  isEmpty(): boolean,
  toDataURL(): string,
  fromDataURL(uri: string): void,
}

const SignatureInput: React.FC<ISignatureInputProps> = ({ component, isDisabled }) => {
  const [state, setState] = useState<ISignatureInputState>({
    isPadVisible: false,
  });

  let signatureRef: ISignaturePadRef | null = null;
  const buttonText = state.isPadVisible
    ? "Done"
    : "Sign";

  const onTogglePadClick = async () => {
    if (!state.isPadVisible) {
      setState({
        ...state,
        isPadVisible: true,
      });
    }
  }

  const onResetClick = () => {
    if (signatureRef) {
      signatureRef.clear();
    }
  }

  return (
    <>
      <label>{component.label}</label>
      <div className="signature-input">
        <div className={"pad-wrapper " + (state.isPadVisible ? "pad-active" : "")}>
          {state.isPadVisible &&
            <SignaturePad
              ref={(ref: ISignaturePadRef | null) => signatureRef = ref}
            />
          }
          {!state.isPadVisible
            && (state.signatureDataUri || state.initialDataUri) &&
            <img
              className="signature-image"
              src={state.signatureDataUri || state.initialDataUri}
              alt="Signature"
            />
          }
        </div>

        <div className="buttons">
          {state.isPadVisible &&
            <button
              className="secondary-button"
              onClick={isDisabled ? undefined : onResetClick}
              disabled={isDisabled}
            >
              {'Start Over'}
            </button>
          }
          <button
            className="primary-button"
            onClick={isDisabled ? undefined : onTogglePadClick}
            disabled={isDisabled}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  );
}

export default SignatureInput;