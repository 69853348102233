import { IMicrosoftStreamVideoComponent } from "interfaces/sw/SWInterfaces";
import React from "react";
import "./MicrosoftStreamVideo.scoped.scss";

interface IMicrosoftStreamVideoProps {
  component: IMicrosoftStreamVideoComponent,
}

const MicrosoftStreamVideo: React.FC<IMicrosoftStreamVideoProps> = ({ component }) => {
  const videoGuid = component.videoGuid;
  let displayComponent: JSX.Element | null = null;

  if (videoGuid) {
    displayComponent = (
      <iframe
        className="embed"
        title={videoGuid}
        src={videoGuid}
        allowFullScreen
      ></iframe>
    );
  }
  else {
    displayComponent = (
      <div
        className="embed empty"
      >
        <span>
          {'No video specified'}
        </span>
      </div>
    );
  }

  return (
    <div>
      {displayComponent}
    </div>
  );
}

export default MicrosoftStreamVideo;