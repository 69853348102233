import { createReducer } from "@reduxjs/toolkit";
import { IManageSWState, ManageSWTabs, ReusableCompType } from "./manageSWTypes";
import {
  SWTypes,
  TaskAlignments,
  ISW,
  IStep,
  IStepComponent,
  IReferenceDoc,
  StepComponentTypes,
  INoticeComponent,
  NoticeTypes,
  ImageType,
  RCTypes,
  StepTypes,
  ShowCLSignatureBlock,
  TemplateTypes,
  DataClassificationTypes,
  Usage,
  ServiceLevel,
  ContentTypes
} from "interfaces/sw/SWInterfaces";
import {
  setStringAttribute,
  setType,
  setTaskAlignment,
  setLanguage,
  setCurrentTab,
  addPPE,
  removePPE,
  setStepBooleanValue,
  setStepStringValue,
  addNewStep,
  deleteStep,
  setFileUploadOperation,
  setEquivalentSW,
  setOwningOrg,
  addMetaDataItem,
  removeMetaDataItem,
  reset,
  setCreateOperation,
  setUpdateOperation,
  finishCreating,
  setLoadOperation,
  addNewReferenceDoc,
  setRefDocLabel,
  setRefDocFilename,
  finishUpdating,
  removeReferenceDoc,
  finishLoading,
  updateStepSortOrder,
  updateComponentSortOrder,
  setActiveApprovalRequestId,
  setActiveApprovalLevel,
  setStepVisibility,
  expandAndScrollToStep,
  finishScrollingToStep,
  updateComponentSortOrders,
  updateRefDocSortOrders,
  finishUnlockSWCurrentEditor,
  duplicateStep,
  setComments,
  setRefDocTableData,
  updateRefDocTableCell,
  addNewReferenceDocTable,
  setStepComments,
  updateStepCommentsInfo,
  updateNoticeImageBlobData,
  setTLMSWIType,
  updateVideoBlobData,
  setCurrentExecutionPreviewStep,
  updatePublishRCData,
  setPublishOperation,
  addRCSteps,
  updateRCLinkBreak,
  removeRCContainerFromSteps,
  addRCContainerInSteps,
  setRevisionHistories,
  updateAudioBlobData,
  setReusableCompSortable,
  addReusableCompItemChecked,
  removeReusableCompItemChecked,
  updateReusableCompSteps,
  setResuableFromTLMSWStringAttribute,
  setReusableCompFromTLMSWIOwningOrg,
  finishRCFromTLMSWICreating,
  finishRCFromTLMSWIUpdating,
  setReusableCompMode,
  setCreateReusableContentPopup,
  finishRCFromBlobCopying,
  addReusableMetaDataItem,
  removeReusableMetaDataItem,
  setActiveApprovalUserName,
  setRefDocTableImageFilename,
  updateRefDocTableCellBlobData,
  setTimeImageData,
  setTimeMediaWhereUsedFiles,
  cancelTimeMediaMappingModal,
  disableRcContent,
  setSWLock,
  redirectToSearchFromTimeRC,
  setOwningPlant,
  setReusableMetaDataLanguage,
  setNoticeLevelHazardCategory,
  addRCNotices,
  updateRCNoticeLinkBreak,
  setNoticeVisibility,
  disableRcNoticeContent,
  setCreateRCNoticePopup,
  setServiceLevel,
  setUsage,
  addRCSubSteps,
  setVersionChanges,
  setSubmitForReviewFlag,
  setActiveApprovalType,
  setReusableCompFromTLMSWIRcType,
  setRCType,
  setDataClassification,
  setTCC,
  setTemplateType,
  setContentType,
  setGroup,
  setGroupCounter,
  setOOS,
  setConfidentialEnabledUserList,
  setConfidentialAccessChangeOperation,
  setConfidentialUsersLoadOperation,
  setAllSiteLocations,
  setSiteLocation,
  setIsSearchRCNoticeOpen,
  setRCNoticeStep,
  updateTaskStepsMetadata,
  setLevelStringAttribute,
  setHazardCategory,
  setConfigParaMeterFlag,
  setSetConnectedSWIs,
  setConnectedSWIGroup,
  setConnectedSWIGroupCounter,
  setConnectedSWIMaterial,
  setAddConnectedSWILoadOperation,
  setActiveStepGuid,
  setUploadConnectedSWIsErrors,
  setReusableCompFromMFGSWIOwningPlant,
  setReusableCompFromMFGSWIGemsRevision,
  setBackgroundUpdateOperation,
  finishBackgroundUpdate,
  setPendingCompatibilityFetchRCStepsAction,
  setSWNotices,
  setSWStepNotices,
  setConnectedSWIEnabled
} from "./manageSWActions";
import { v4 as uuidv4 } from "uuid";
import { findStep, findAndDeleteStep, findAndDeleteAllRCStep, updateAllImportedRC, updateImportedRCStep, updateRCLinkBreakSteps, findNextSortOrder, applyStepSortOrder, removeRCContainer, AddContainerForRCSteps, updateImportedRCSubSteps, findNotice } from "./manageSWHelpers";
import addStepComponentReducerCases from "./stepComponentCases";
import { cloneDeep } from "lodash";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { showErrorToast } from "store/toast/toastActions";

const initialState: IManageSWState = {
  SW: {
    id: 0,
    guid: "",
    type: SWTypes.SWI,
    version: 0,
    taskAlignment: TaskAlignments.At,
    title: "",
    description: "",
    activityWorkflows: [],
    performingOrgs: [],
    subBusinessLines: [],
    serviceTypes: [],
    operatingEnvironments: [],
    equipment: [],
    epicEquipment: [],
    tasks: [],
    countries: [],
    geoUnits: [],
    competencyElements: [],
    ppe: [],
    notices: [],
    steps: [],
    safetyCategories: [],
    businessRoles: [],
    productCenters: [],
    customers: [],
    referenceDocs: [],
    createdBy: "",
    isLocked: false,
    currentEditor: null,
    currentEditorLockedDate: undefined,
    hasComments: false,
    introduction: undefined,
    maintenanceTaskIds: undefined,
    ceMarking: undefined,
    foreward: undefined,
    versionChanges: "Initial Version",
    harc: undefined,
    performingPlants: [],
    workCenters: [],
    materials: [],
    gemsRevisionNumber: undefined,
    rcType: undefined,
    showCLSignatureBlock: undefined,
    dataClassification: DataClassificationTypes.Private,
    tcc: false,
    events: [],
    level: undefined,
    hasPublishedVersion: false,
    plantSource: undefined,
    connectedSWIs: [],
    compatibleSWTypes: [],
    showMFGUsageWarning: false,
  },
  ReusableCompFromTLMSWI: {
    id: 0,
    guid: "",
    type: SWTypes.TLMRC,
    version: 0,
    taskAlignment: TaskAlignments.At,
    title: "",
    description: "",
    activityWorkflows: [],
    performingOrgs: [],
    subBusinessLines: [],
    serviceTypes: [],
    operatingEnvironments: [],
    equipment: [],
    epicEquipment: [],
    tasks: [],
    countries: [],
    geoUnits: [],
    competencyElements: [],
    ppe: [],
    notices: [],
    steps: [],
    safetyCategories: [],
    businessRoles: [],
    productCenters: [],
    customers: [],
    referenceDocs: [],
    createdBy: "",
    isLocked: false,
    currentEditor: null,
    currentEditorLockedDate: undefined,
    hasComments: false,
    introduction: undefined,
    maintenanceTaskIds: undefined,
    ceMarking: undefined,
    foreward: undefined,
    versionChanges: "Initial Version",
    harc: undefined,
    performingPlants: [],
    workCenters: [],
    materials: [],
    gemsRevisionNumber: undefined,
    rcType: undefined,
    showCLSignatureBlock: undefined,
    dataClassification: DataClassificationTypes.Private,
    tcc: false,
    contentType: undefined,
    events: [],
    level: undefined,
    hasPublishedVersion: false,
    plantSource: undefined,
    connectedSWIs: [],
    compatibleSWTypes: [],
    showMFGUsageWarning: false,
  },
  ReusableCompSortable: [],
  reusableCompSelectedType: undefined,
  isCreateReusableContentOpen: false,
  isCreateRCNoticeOpen: false,
  isSearchRCNoticeOpen: false,
  rcNoticeStep: "",
  createOperation: undefined,
  loadOperation: undefined,
  publishOperation: undefined,
  updateOperation: undefined,
  fileUploadOperation: undefined,
  currentTab: ManageSWTabs.Attributes,
  isDirty: false,
  stepVisibilities: [],
  noticeVisibilities: [],
  commentData: {
    comments: undefined,
    loadOperation: undefined,
  },
  stepCommentData: {
    comments: undefined,
    loadOperation: undefined,
    },
  swNoticeData: {
      swNotices: undefined
  },
  swStepNoticeData: {
      swStepNotices: undefined
  },
  timeImageData: {
    imageNumber: 0,
    link: "",
    label: "",
  },
  timeMediaFilesMappingData: {
    timeMediaFileMapping: [],
    loadTimeImagesOperation: undefined,
  },
  currentLocation: null,
  rcPublishData: {
    dependentSWsCount: 0,
    loadOperation: undefined,
  },
  revisionHistories: [],
  redirectToSearchPageFromTimeRC: false,
  submitForReviewFlag: false,
  confidentialSWData: {
    changeOperation: {
      isWorking: false,
    },
    loadOperation: {
      isWorking: false,
    },
    confidentialEnabledUsers: [],
  },
  siteLocations: [],
  connectedSWIData: {
    draft: {
      material: undefined,
      group: undefined,
      groupCounter: undefined
    }
  },
  connectedSWIsUploadErrors: [],
  enableMetersValue: false,
  enableFormulaComponentValue: false,
  enablePhotoInputComponentMFGValue: false,
  enableTimeImageComponentMFGValue: false,
  enableNoticeComponentMFGValue: false,
  enableTableComponentMFGValue: false,
  enableSingleListComponentMFGValue: false,
  enableMultiListComponentMFGValue: false,
  disableMFGInputComponentValues: [],
  disableMFGContentComponentValues: [],
  enableConfiguredMFGPlantsInTLMValue: false,
  pendingCompatibilityFetchRCStepsAction: undefined,
  copiedComponents: [],
  enableConnectedSWI: false,
};

const manageSWReducer = createReducer(initialState, builder => {
  // Add step component cases.
  addStepComponentReducerCases(builder);

  // Reset SW
  builder.addCase(reset, (state) => {
    state.isDirty = false;
    Object.assign(state, cloneDeep(initialState));
  });

  // Set String Attribute
  builder.addCase(setStringAttribute, (state, action) => {
    state.isDirty = Math.random();

    switch (action.payload.attributeName) {
      case "title": {
        state.SW.title = action.payload.value;
        break;
      }
      case "description": {
        state.SW.description =  action.payload.value;
        break;
      }
      case "maintenanceTaskIds": {
        state.SW.maintenanceTaskIds = action.payload.value;
        break;
      }
      case "introduction": {
        state.SW.introduction = action.payload.value;
        break;
      }
      case "ceMarking": {
        state.SW.ceMarking = action.payload.value;
        break;
      }
      case "foreward": {
        state.SW.foreward = action.payload.value;
        break;
      }
      case "versionChanges": {
        state.SW.versionChanges = action.payload.value;
        break;
      }
      case "harc": {
        state.SW.harc = action.payload.value;
        break;
      }
      case "showclsignatureblock": {
        state.SW.showCLSignatureBlock = action.payload.value as ShowCLSignatureBlock;
        break;
      }
    }
  });
  builder.addCase(setLevelStringAttribute, (state, action) => {
    state.isDirty = Math.random();

    switch (action.payload.attributeName) {
      case "level": {
        state.SW.level = action.payload.value;
        break;
      }
    }
  });

  // set Resuable From TLMSW StringAttribute
  builder.addCase(setResuableFromTLMSWStringAttribute, (state, action) => {
    switch (action.payload.attributeName) {
      case "title": {
        state.ReusableCompFromTLMSWI.title = action.payload.value;
        break;
      }
      case "description": {
        state.ReusableCompFromTLMSWI.description = action.payload.value;
        break;
      }
      case "versionChanges": {
        state.ReusableCompFromTLMSWI.versionChanges = action.payload.value;
        break;
      }
    }
  });

  // Set Type
  builder.addCase(setType, (state, action) => {
    state.isDirty = Math.random();
    state.SW.type = action.payload;

    updateSWTitle(state.SW);

    if (action.payload === SWTypes.SWI || action.payload === SWTypes.TLMSWI) {
      state.SW.taskAlignment = TaskAlignments.At;
    } else if ((action.payload === SWTypes.CL
      || action.payload === SWTypes.MFGCL)
      && state.SW.taskAlignment === TaskAlignments.At) {
      state.SW.taskAlignment = TaskAlignments.Before;
    }

    if (action.payload === SWTypes.TLMSWI) {
      if (state.SW.activityWorkflows.length) {
        state.SW.activityWorkflows.splice(0);
      }
    }
    else {
      if (action.payload !== SWTypes.ECL
        && state.SW.tasks.length > 1) {
        state.SW.tasks.splice(1);
      }

      if (action.payload == SWTypes.CL) {
        state.SW.showCLSignatureBlock = ShowCLSignatureBlock.No;
      }

      if (action.payload !== SWTypes.ECL
        && state.SW.activityWorkflows.length) {
        state.SW.activityWorkflows.splice(0);
      }

      if (state.SW.TLMSWIType) {
        state.SW.TLMSWIType = undefined;
      }

      if (state.SW.templateType) {
        state.SW.templateType = undefined;
      }

      if (state.SW.contentType) {
        state.SW.contentType = undefined;
      }

      if (state.SW.maintenanceTaskIds
        || state.SW.maintenanceTaskIds === "") {
        state.SW.maintenanceTaskIds = undefined;
      }

      if (state.SW.productCenters.length) {
        state.SW.productCenters.splice(0);
      }

      if (state.SW.businessRoles.length) {
        state.SW.businessRoles.splice(0);
      }

      if (state.SW.epicEquipment.length) {
        state.SW.epicEquipment.splice(0);
      }

      if (state.SW.introduction
        || state.SW.introduction === "") {
        state.SW.introduction = undefined;
      }

      if (state.SW.ceMarking
        || state.SW.ceMarking === "") {
        state.SW.ceMarking = undefined;
      }

      if (state.SW.foreward
        || state.SW.foreward === "") {
        state.SW.foreward = undefined;
      }

      if (state.SW.versionChanges
        || state.SW.versionChanges === "") {
        state.SW.versionChanges = "Initial Version";
      }

      if (state.SW.harc
        || state.SW.harc === "") {
        state.SW.harc = undefined;
      }

      if (state.SW.geoUnits.length) {
        state.SW.geoUnits.splice(0);
      }

      if ((action.payload === SWTypes.SWI
        || action.payload === SWTypes.ECL
        || action.payload === SWTypes.CL)
        && state.SW.countries.length === 0
        && state.SW.customers.length) {
        state.SW.customers.splice(0);
      }
    }
  });

  builder.addCase(setTLMSWIType, (state, action) => {
    state.isDirty = Math.random();
    state.SW.TLMSWIType = action.payload;
  });

  builder.addCase(setTemplateType, (state, action) => {
    state.isDirty = Math.random();
    state.SW.templateType = action.payload;
  });

  builder.addCase(setDataClassification, (state, action) => {
    state.isDirty = Math.random();
    state.SW.dataClassification = action.payload;
  });

  builder.addCase(setTCC, (state, action) => {
    state.isDirty = Math.random();
    state.SW.tcc = action.payload;
  });

  builder.addCase(setContentType, (state, action) => {
    state.isDirty = Math.random();
    state.SW.contentType = action.payload;
  });

  builder.addCase(setSWLock, (state, action) => {
    state.SW.isLocked = action.payload.islock;
  });

  builder.addCase(redirectToSearchFromTimeRC, (state, action) => {
    state.redirectToSearchPageFromTimeRC = action.payload;
  });

  builder.addCase(setSubmitForReviewFlag, (state, action) => {
    state.submitForReviewFlag = action.payload.submitForReviewFlag;
  });

  builder.addCase(setConfigParaMeterFlag, (state , action) => {
    switch(action.payload.keyName){
      case "enableMeters":
        state.enableMetersValue = action.payload.value.toLowerCase() === "true" ? true : false;
        break;
      case "enableFormulaComponent":
            state.enableFormulaComponentValue = action.payload.value.toLowerCase() === "true" ? true : false;
        break;
      case "enablePhotoInputComponentMFG":
            state.enablePhotoInputComponentMFGValue = action.payload.value.toLowerCase() === "true" ? true : false;
        break;
      case "enableTimeImageComponentMFG":
            state.enableTimeImageComponentMFGValue = action.payload.value.toLowerCase() === "true" ? true : false;
        break;
      case "enableNoticeComponentMFG":
            state.enableNoticeComponentMFGValue = action.payload.value.toLowerCase() === "true" ? true : false;
        break;
      case "enableTableComponentMFG":
            state.enableTableComponentMFGValue = action.payload.value.toLowerCase() === "true" ? true : false;
        break;
      case "enableSingleListComponentMFG":
            state.enableSingleListComponentMFGValue = action.payload.value.toLowerCase() === "true" ? true : false;
        break;
      case "enableMultiListComponentMFG":
            state.enableMultiListComponentMFGValue = action.payload.value.toLowerCase() === "true" ? true : false;
        break;
        case "disableMFGInputComponent":
            state.disableMFGInputComponentValues = action.payload.value !== "" ? action.payload.value.slice(1, -1).split(',') : [];
        break;
        case "disableMFGContentComponent":
            state.disableMFGContentComponentValues = action.payload.value !== "" ? action.payload.value.slice(1, -1).split(',') : [];
            break;
        case "enableConfiguredMFGPlantsInTLM":
            state.enableConfiguredMFGPlantsInTLMValue = action.payload.value.toLowerCase() === "true" ? true : false;
            break;
    }
  })

  // Set Task Alignment
  builder.addCase(setTaskAlignment, (state, action) => {
    state.isDirty = Math.random();
    state.SW.taskAlignment = action.payload;

    updateSWTitle(state.SW);
  });

  // Set Language
  builder.addCase(setLanguage, (state, action) => {
    state.isDirty = Math.random();
    state.SW.language = action.payload.masterDataItem;

    if (action.payload?.masterDataItem?.value === "English"
      && !action.payload?.countrySelected
      && !action.payload?.geoUnitSelected) {
      state.SW.equivalentSW = undefined;
    }

    updateSWTitle(state.SW);
  });

  //Set RC Metada from TLMSWI
  builder.addCase(setReusableMetaDataLanguage, (state, action) => {
    state.ReusableCompFromTLMSWI.language = action.payload.masterDataItem;
  });

  // Set Equivalent SW
  builder.addCase(setEquivalentSW, (state, action) => {
    state.isDirty = Math.random();
    state.SW.equivalentSW = action.payload;
  });

  // Set Owning Org
  builder.addCase(setOwningOrg, (state, action) => {
    state.isDirty = Math.random();
    state.SW.owningOrg = action.payload.owningOrg;

    if (action.payload.swType !== SWTypes.TLMSWI) {
      state.SW.owningPlant = undefined;
    }
  });

  // Set Owning Plant
  builder.addCase(setOwningPlant, (state, action) => {
    state.isDirty = Math.random();
    state.SW.owningPlant = action.payload.owningPlant;

    if (action.payload.swType === SWTypes.MFGSWI
      || action.payload.swType === SWTypes.MFGCL
      || action.payload.swType === SWTypes.MFGRC) {
      let item: IMasterDataItem = {
        guid: "3a6ad77d-41f4-47d2-95e9-399675b24151",
        value: "",
      };

      state.SW.owningOrg = item;
      state.SW.plantSource = action.payload.owningPlant?.source;
    }
    if(action.payload.swType === SWTypes.TLMSWI)
    {
      state.SW.plantSource = action.payload.owningPlant?.source;
    }

    if (action.payload.owningPlant === undefined) {
      state.SW.workCenters = [];
      state.SW.materials = [];
      state.SW.performingPlants = [];
    }
  });

   // Set Owning Plant for RC from MFGSWI option
   builder.addCase(setReusableCompFromMFGSWIOwningPlant, (state, action) => {
    state.ReusableCompFromTLMSWI.owningPlant = action.payload.owningPlant;

    if (action.payload.swType === SWTypes.MFGSWI
      || action.payload.swType === SWTypes.MFGCL) {
      let item: IMasterDataItem = {
        guid: "3a6ad77d-41f4-47d2-95e9-399675b24151",
        value: "",
      };

      state.ReusableCompFromTLMSWI.owningOrg = item;
      state.ReusableCompFromTLMSWI.plantSource = action.payload.owningPlant?.source;
      state.ReusableCompFromTLMSWI.type = SWTypes.MFGRC;
    }

    if (action.payload.owningPlant === undefined) {
      state.ReusableCompFromTLMSWI.workCenters = [];
      state.ReusableCompFromTLMSWI.materials = [];
      state.ReusableCompFromTLMSWI.performingPlants = [];
      state.ReusableCompFromTLMSWI.gemsRevisionNumber = undefined;
      state.ReusableCompFromTLMSWI.plantSource = undefined;
    }
  });
  
  // set Reusable Comp From TLMSWI OwningOrg
  builder.addCase(setReusableCompFromMFGSWIGemsRevision, (state, action) => {
      state.ReusableCompFromTLMSWI.gemsRevisionNumber = action.payload;
  });
  

  // set Reusable Comp From TLMSWI OwningOrg
  builder.addCase(setReusableCompFromTLMSWIOwningOrg, (state, action) => {
    state.ReusableCompFromTLMSWI.owningOrg = action.payload;
  });

  builder.addCase(setAllSiteLocations, (state, action) => {
    state.siteLocations = action.payload;
  });

  // set Reusable Comp From TLMSWI RcType
  builder.addCase(setReusableCompFromTLMSWIRcType, (state, action) => {
    state.ReusableCompFromTLMSWI.rcType = action.payload;
  });

  // Set Active Approval Request Id
  builder.addCase(setActiveApprovalRequestId, (state, action) => {
    state.SW.activeApprovalRequestId = action.payload;
  });

  // Set Active Approval Level
  builder.addCase(setActiveApprovalLevel, (state, action) => {
    state.SW.activeApprovalLevel = action.payload;
  });

  // Set Active Approval User Name Level
  builder.addCase(setActiveApprovalUserName, (state, action) => {
    state.SW.activeApprovalUserName = action.payload;
  });

  // Set Active Approval Type
  builder.addCase(setActiveApprovalType, (state, action) => {
    state.SW.approvalType = action.payload;
  });


  // Set Hazard Category
  builder.addCase(setHazardCategory, (state, action) => {
    state.isDirty = Math.random();
    state.SW.hazardCategory = action.payload;
  });
  
  //Set Notice Level Hazard Category
  builder.addCase(setNoticeLevelHazardCategory, (state, action) => {
    state.isDirty = Math.random();
    let notice = findNotice(action.payload.noticeGuid,action.payload.stepGuid, state);
    if(notice !== undefined){
      notice.hazardCategory = action.payload.hazardCategory;
    }
  });

  // Set Version Changes
  builder.addCase(setVersionChanges, (state, action) => {
    state.SW.versionChanges = action.payload;
    state.isDirty = Math.random();
  });

  builder.addCase(setOOS, (state, action) => {
    let [step,] = findStep(action.payload.stepGuid, state.SW.steps, 0);
    let arr: IMasterDataItem[] = [];

    if (!step) {
      return;
    }

    arr = step.OOS;

    if (action.payload.isAdding && action.payload.item !== undefined) {
      arr.push(action.payload.item);
    } else if (!action.payload.isAdding && action.payload.item !== undefined) {
      arr = arr.filter(o => o.code != action.payload.item?.code);
    }
    step.OOS = arr;
    state.isDirty = Math.random();
  });

  // Add Meta Data Value
  builder.addCase(addMetaDataItem, (state, action) => {
    state.isDirty = Math.random();
    let arr: IMasterDataItem[] = [];

    switch (action.payload.metaDataName) {
      case "activityWorkflows": {
        arr = state.SW.activityWorkflows;
        break;
      }
      case "competencyElements": {
        arr = state.SW.competencyElements;
        break;
      }
      case "countries": {
        arr = state.SW.countries;
        break;
      }
      case "geoUnits": {
        arr = state.SW.geoUnits;
        break;
      }
      case "equipment": {
        arr = state.SW.equipment;
        break;
      }
      case "epicEquipment": {
        arr = state.SW.epicEquipment;
        break;
      }
      case "businessRoles": {
        arr = state.SW.businessRoles;
        break;
      }
      case "productCenters": {
        arr = state.SW.productCenters;
        break;
      }
      case "customers": {
        arr = state.SW.customers;
        break;
      }
      case "operatingEnvironments": {
        arr = state.SW.operatingEnvironments;
        break;
      }
      case "performingOrgs": {
        arr = state.SW.performingOrgs;
        break;
      }
      case "subBusinessLines": {
        arr = state.SW.subBusinessLines;
        break;
      }
      case "safetyCategories": {
        arr = state.SW.safetyCategories;
        break;
      }
      case "serviceTypes": {
        arr = state.SW.serviceTypes;
        break;
      }
      case "tasks": {
        arr = state.SW.tasks;
        break;
      }
      case "performingPlants": {
        arr = state.SW.performingPlants;
        break;
      }
      case "workCenters": {
        arr = state.SW.workCenters;
        break;
      }
      case "materials": {
        arr = state.SW.materials;
        break;
      }
      case "gemsRevisionNumber": {
        state.SW.gemsRevisionNumber = {
          ...action.payload.item,
          isOutdated: false,
        };
        return;
      }
      case "events": {
        arr = state.SW.events;
        break;
      }
    }

    if (action.payload.metaDataName === "tasks"
      && state.SW.type !== SWTypes.ECL) {
      arr.length = 0;
    }

    if (!arr.find(x => x.guid === action.payload.item.guid)) {
      arr.push({
        ...action.payload.item,
      });
    }

    if (action.payload.metaDataName === "tasks") {
      updateSWTitle(state.SW);
    }
  });

  // Remove Meta Data Value
  builder.addCase(removeMetaDataItem, (state, action) => {
    state.isDirty = Math.random();

    switch (action.payload.metaDataName) {
      case "activityWorkflows": {
        state.SW.activityWorkflows =
          state.SW.activityWorkflows
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "competencyElements": {
        state.SW.competencyElements =
          state.SW.competencyElements
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "countries": {
        state.SW.countries =
          state.SW.countries
            .filter(x => x.guid !== action.payload.item.guid);

        if (state.SW.type !== SWTypes.TLMSWI
          && state.SW.countries.length === 0) {
          state.SW.customers = [];
        }
        break;
      }
      case "geoUnits": {
        state.SW.geoUnits =
          state.SW.geoUnits
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "equipment": {
        state.SW.equipment =
          state.SW.equipment
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "epicEquipment": {
        state.SW.epicEquipment =
          state.SW.epicEquipment
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "productCenters": {
        state.SW.productCenters =
          state.SW.productCenters
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "businessRoles": {
        state.SW.businessRoles =
          state.SW.businessRoles
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "customers": {
        state.SW.customers =
          state.SW.customers
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "operatingEnvironments": {
        state.SW.operatingEnvironments =
          state.SW.operatingEnvironments
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "performingOrgs": {
        state.SW.performingOrgs =
          state.SW.performingOrgs
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "subBusinessLines": {
        state.SW.subBusinessLines =
          state.SW.subBusinessLines
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "safetyCategories": {
        state.SW.safetyCategories =
          state.SW.safetyCategories
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "serviceTypes": {
        state.SW.serviceTypes =
          state.SW.serviceTypes
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "tasks": {
        state.SW.tasks =
          state.SW.tasks
            .filter(x => x.guid !== action.payload.item.guid);
        updateSWTitle(state.SW);
        break;
      }
      case "performingPlants": {
        state.SW.performingPlants =
          state.SW.performingPlants
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "workCenters": {
        state.SW.workCenters =
          state.SW.workCenters
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "materials": {
        state.SW.materials =
          state.SW.materials
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "gemsRevisionNumber": {
        state.SW.gemsRevisionNumber = undefined;
        return;
      }
      case "events": {
        state.SW.events =
          state.SW.events
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
    }
  });


  // Add RC Meta Data Value
  builder.addCase(addReusableMetaDataItem, (state, action) => {
    let arr: IMasterDataItem[] = [];

    switch (action.payload.metaDataName) {
      case "epicEquipment": {
        arr = state.ReusableCompFromTLMSWI.epicEquipment;
        break;
      }
      case "productCenters": {
        arr = state.ReusableCompFromTLMSWI.productCenters;
        break;
      }
      case "performingPlants": {
        arr = state.ReusableCompFromTLMSWI.performingPlants;
        break;
      }
      case "workCenters": {
        arr = state.ReusableCompFromTLMSWI.workCenters;
        break;
      }
      case "materials": {
        arr = state.ReusableCompFromTLMSWI.materials;
        break;
      }
      case "gemsRevisionNumber": {
        state.ReusableCompFromTLMSWI.gemsRevisionNumber = {
          ...action.payload.item,
          isOutdated: false,
        };
        break;
      }
    }
    if (!arr.find(x => x.guid === action.payload.item.guid)) {
      arr.push({
        ...action.payload.item,
      });
    }
  });

  // Remove Reusable Meta Data Value
  builder.addCase(removeReusableMetaDataItem, (state, action) => {
    switch (action.payload.metaDataName) {
      case "epicEquipment": {
        state.ReusableCompFromTLMSWI.epicEquipment =
          state.ReusableCompFromTLMSWI.epicEquipment
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "productCenters": {
        state.ReusableCompFromTLMSWI.productCenters =
          state.ReusableCompFromTLMSWI.productCenters
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "performingPlants": {
        state.ReusableCompFromTLMSWI.performingPlants =
          state.ReusableCompFromTLMSWI.performingPlants
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "workCenters": {
        state.ReusableCompFromTLMSWI.workCenters =
          state.ReusableCompFromTLMSWI.workCenters
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "materials": {
        state.ReusableCompFromTLMSWI.materials =
          state.ReusableCompFromTLMSWI.materials
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "gemsRevisionNumber": {
        state.ReusableCompFromTLMSWI.gemsRevisionNumber = undefined;
        return;
      }
    }
  });

  // Set Current Tab
  builder.addCase(setCurrentTab, (state, action) => {
    state.currentTab = action.payload;
  });

  // Add PPE
  builder.addCase(addPPE, (state, action) => {
    state.isDirty = Math.random();

    if (state.SW.ppe.indexOf(action.payload) === -1) {
      state.SW.ppe.push(action.payload);
    }
  });

  // Add PPE
  builder.addCase(removePPE, (state, action) => {
    state.isDirty = Math.random();
    state.SW.ppe = state.SW.ppe.filter(x => x !== action.payload);
  });

  // Set Step Boolean Value
  builder.addCase(setStepBooleanValue, (state, action) => {
    let [step,] = findStep(action.payload.stepGuid, state.SW.steps, 0);
    if (!step) {
      return;
    }

    state.isDirty = Math.random();
    step[action.payload.propName] = action.payload.value;
  });

  // Set Step String Value
  builder.addCase(setStepStringValue, (state, action) => {
    let [step,] = findStep(action.payload.stepGuid, state.SW.steps, 0);

    if (!step) {
      return;
    }

    state.isDirty = Math.random();
    step[action.payload.propName] = action.payload.value;
  });

  //set Service Level

  builder.addCase(setServiceLevel, (state, action) => {
    let [step,] = findStep(action.payload.stepGuid, state.SW.steps, 0);

    if (!step) {
      return;
    }

    state.isDirty = Math.random();
    step.serviceLevel = action.payload.value;
  })

  //set Usage
  builder.addCase(setUsage, (state, action) => {
    let [step,] = findStep(action.payload.stepGuid, state.SW.steps, 0);

    if (!step) {
      return;
    }

    state.isDirty = Math.random();
    step.usage = action.payload.value;
  })

  //set Site Location Level
  builder.addCase(setSiteLocation, (state, action) => {
    let [step,] = findStep(action.payload.stepGuid, state.SW.steps, 0);

    if (!step) {
      return;
    }

    state.isDirty = Math.random();
    step.siteLocation = action.payload.value;
  })

  // Update Step Sort Order
  builder.addCase(updateStepSortOrder, (state, action) => {
    state.SW.steps = action.payload;
    state.isDirty = Math.random();
  });

  builder.addCase(updateRCLinkBreak, (state, action) => {
    let modifiedSteps = updateRCLinkBreakSteps(state.SW.steps, action.payload.rcID);
    state.SW.steps = modifiedSteps;
    state.isDirty = Math.random();
  });

  builder.addCase(updateRCNoticeLinkBreak, (state, action) => {
    var notice: INoticeComponent | undefined;

    if (!action.payload.stepGuid) {
      notice = state.SW.notices.find(x => x.guid === action.payload.componentGuid);
    } else {
      let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);

      if (!step) {
        return;
      }
      var component = step.components.find(x => x.guid === action.payload.componentGuid);
      notice = component as INoticeComponent;
    }
    if (notice) {
      notice.rcID = undefined;
      notice.isRCComponent = false;
      notice.rc = undefined;
      notice.rcTitle = undefined;
      notice.immutableGuid = undefined;
    }
    state.isDirty = Math.random();
  });

  // Disable RCNotice content
  builder.addCase(disableRcNoticeContent, (state, action) => {
    var notice: INoticeComponent | undefined;

    if (!action.payload.stepGuid) {
      notice = state.SW.notices.find(x => x.guid === action.payload.componentGuid);
    } else {
      let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);

      if (!step) {
        return;
      }
      var component = step.components.find(x => x.guid === action.payload.componentGuid);
      notice = component as INoticeComponent;
    }

    if (notice) {
      notice.isDisabled = action.payload.disabled;
    }

    state.isDirty = Math.random();
    return state;
  });

  // Remove RC Container before Saving to Server
  builder.addCase(removeRCContainerFromSteps, (state, action) => {

    let modifiedSteps = removeRCContainer(state.SW.steps);
    state.SW.steps = modifiedSteps;
  })

  builder.addCase(addRCContainerInSteps, (state, action) => {
    state.SW.steps = AddContainerForRCSteps(state.SW.steps);
  });

  const updateNoticeData: any = (isUpdate: boolean, selectedNotice: INoticeComponent, payload: any, swNotices: INoticeComponent[]) => {
    if (!isUpdate) {
      return ({
        guid: uuidv4(),
        type: StepComponentTypes.Notice,
        noticeType: payload.notice.noticeType || NoticeTypes.Info,
        label: payload.notice.label,
        sortOrder: swNotices.length
          ? Math.max.apply(Math, swNotices.map(x => x.sortOrder)) + 1
          : 1,
        image: {
          filename: payload.notice.image.fileName || "",
          blobData: payload.notice.image.blobData,
          imageType: payload.notice.image.imageType || ImageType.Both,
        },
        hazardCategory: payload.notice.hazardCategory,
        isRCComponent: true,
        rcTitle: payload.rcTitle,
        rcID: payload.rcID,
        rc: {
          rcNoticeSWGuid: payload.rcGuid,
          rcNoticeSWVersion: payload.rcVersion,
        }
      });
    }
    else {
      selectedNotice.image = {
        filename: payload.notice.image.filename || "",
        blobData: payload.notice.image.blobData,
        imageType: payload.notice.image.imageType || ImageType.Both,
      };
      selectedNotice.label = payload.notice.label;
      selectedNotice.isRCComponent = true;
      selectedNotice.noticeType = payload.notice.noticeType || NoticeTypes.Info;
      selectedNotice.rcTitle = payload.rcTitle;
      selectedNotice.rcID = payload.rcID;
      selectedNotice.hazardCategory = payload.notice.hazardCategory;
      selectedNotice.rc = {
        swGuid: payload.rcGuid,
        swVersion: payload.rcVersion,
      }
    }
  };

  // Append RC Notice to SW
  builder.addCase(addRCNotices, (state, action) => {
    let payload = action.payload;

    if (!payload.stepGuid) {
      let selectedNotice: INoticeComponent | undefined = state.SW.notices.find(x => x.guid === payload.currentNoticeGuid);

      if (!selectedNotice) {
        state.SW.notices.push(updateNoticeData(false, selectedNotice, payload, state.SW.notices));
      }
      else {
        updateNoticeData(true, selectedNotice, payload, state.SW.notices);
      }

    } else {
      let [step] = findStep(payload.stepGuid, state.SW.steps, 0);

      if (!step) {
        return;
      }
      let existingComponent = step.components.find(x => x.guid === payload.currentNoticeGuid);

      if (existingComponent) {
        Object.assign(existingComponent, updateNoticeData(true, existingComponent, payload, state.SW.notices));
      } else {
        step.components.push(updateNoticeData(false, existingComponent, payload, state.SW.notices));
      }
    }

    state.isDirty = Math.random();
  });

  // Append RC Steps to SW
  builder.addCase(addRCSteps, (state, action) => {
    let step = updateAllImportedRC(action.payload.rcSteps, action.payload.rcID, action.payload.rcTitle);
    if (!action.payload.isRCTask && action.payload.stepGuid) {
      let updatedSteps = updateImportedRCStep(step.children, action.payload.stepGuid, state.SW.steps, action.payload.rcID, action.payload.rcTitle);
      state.SW.steps = updatedSteps;
    } else {
      state.SW.steps.push(step);
    }
    state.SW.steps = applyStepSortOrder(state.SW.steps);
    state.isDirty = Math.random();
  });

  // Update Reusable comp Step sort order
  builder.addCase(updateReusableCompSteps, (state, action) => {
    state.ReusableCompFromTLMSWI.steps = action.payload;
  });

  // Append RC Sub Steps to SW
  builder.addCase(addRCSubSteps, (state, action) => {
    let data = action.payload;

    if (data.parentStepGuid) {
      let [step] = findStep(data.parentStepGuid, state.SW.steps, 0);
      if (step) {
        updateImportedRCSubSteps(data.rcSteps, step, data.rcType, data.rcID, data.rcTitle,);
      }
      else {
        showErrorToast("Parent Step is not found.");
      }
    } else { //this is RCSubStep added to RC
      let step = updateAllImportedRC(action.payload.rcSteps, action.payload.rcID, action.payload.rcTitle);
      state.SW.steps.push(step);
    }

    state.SW.steps = applyStepSortOrder(state.SW.steps);
    state.isDirty = Math.random();
  });

  // Update Component Sort Order
  builder.addCase(updateComponentSortOrder, (state, action) => {
    let componentList: IStepComponent[] = [];

    if (action.payload.parentStepGuid) {
      let [step,] = findStep(action.payload.parentStepGuid,
        state.SW.steps,
        0);

      if (!step
        || !step.components.length) {
        return;
      }

      componentList = step.components;
    } else {
      componentList = state.SW.notices;
    }

    if (action.payload.newIndex >= componentList.length
      || action.payload.oldIndex >= componentList.length
      || action.payload.newIndex < 0
      || action.payload.oldIndex < 0) {
      return;
    }

    const item = componentList[action.payload.oldIndex];

    componentList.splice(action.payload.oldIndex, 1);
    componentList.splice(action.payload.newIndex, 0, item);

    componentList.forEach((st, ix) => st.sortOrder = ix + 1);

    state.isDirty = Math.random();
  });

  // Update Component Sort Orders
  builder.addCase(updateComponentSortOrders, (state, action) => {
    let componentList: IStepComponent[] = [];

    if (action.payload.stepGuid) {
      let [step,] = findStep(action.payload.stepGuid,
        state.SW.steps,
        0);

      if (!step
        || !step.components.length) {
        return;
      }

      componentList = step.components;
    } else {
      componentList = state.SW.notices;
    }

    action.payload.items.forEach(sortData => {
      const oldIndex = componentList.findIndex(comp => comp.guid === sortData.guid);
      const item = componentList[oldIndex];

      componentList.splice(oldIndex, 1);
      componentList.splice(sortData.newIndex, 0, item);
    });

    componentList.forEach((st, ix) => st.sortOrder = ix + 1);

    state.isDirty = Math.random();
  });

  // Update Ref Doc Sort Order
  builder.addCase(updateRefDocSortOrders, (state, action) => {
    let refDocList: IReferenceDoc[] = state.SW.referenceDocs;

    action.payload.forEach(sortData => {
      const oldIndex = refDocList.findIndex(item => item.guid === sortData.guid);
      const item = refDocList[oldIndex];

      refDocList.splice(oldIndex, 1);
      refDocList.splice(sortData.newIndex, 0, item);
    });

    refDocList.forEach((st, ix) => st.sortOrder = ix + 1);

    state.isDirty = Math.random();
  });

  builder.addCase(setRCType, (state, action) => {
    state.SW.rcType = action.payload;
  });

  // Add New Step
  builder.addCase(addNewStep, (state, action) => {
    const newGuid = uuidv4();
    const stepType = action.payload.stepType;
    if (action.payload.parentStepGuid === null || action.payload.parentStepGuid === 'rcSubStep') {
      state.SW.steps.push({
        guid: newGuid,
        parentGuid: null,
        title: "",
        sortOrder: state.SW.steps.filter(a => a.rcContainer).length > 0 ? findNextSortOrder(state.SW.steps)
          : state.SW.steps.length
            ? Math.max.apply(Math, state.SW.steps.map(x => x.sortOrder)) + 1
            : 1,
        components: [],
        children: [],
        isMasterStep: false,
        isMemoryStep: false,
        isComprehensionRequired: false,
        isConditional: false,
        isCritComp: false,
        isCritStep: false,
        depth: 0,
        hasUnResolvedComments: false,
        stepComments: [],
        isTask: false,
        isRCStep: false,
        isDisabled: false,
        stepType: stepType,
        isStale: false,
        OOS: []
      });
    } else if (action.payload.parentStepGuid === 'task') {
      // this is a task step
      let step: IStep = {
        guid: newGuid,
        parentGuid: null,
        title: "",
        sortOrder: state.SW.steps.filter(a => a.rcContainer).length > 0 ? findNextSortOrder(state.SW.steps)
            : state.SW.steps.length
                ? Math.max.apply(Math, state.SW.steps.map(x => x.sortOrder)) + 1
                : 1,
        components: [],
        children: [],
        isMasterStep: false,
        isMemoryStep: false,
        isComprehensionRequired: false,
        isConditional: false,
        isCritComp: false,
        isCritStep: false,
        depth: 0,
        hasUnResolvedComments: false,
        stepComments: [],
        isTask: true,
        isRCStep: false,
        isDisabled: false,
        stepType: stepType,
        isStale: false,
        OOS: []
      };

      if(state.SW.templateType == TemplateTypes.Consolidated){
        step.serviceLevel = ServiceLevel.SL_0;
      }
      
      state.SW.steps.push(step);
    } else {
      let [step, depth] = findStep(action.payload.parentStepGuid, state.SW.steps, 0);

      if (!step) {
        return;
      }

      if (depth === 2) {
        let parentGuid = step?.parentGuid;
        if (!parentGuid) {
          return;
        }

        let [parentStep] = findStep(parentGuid, state.SW.steps, 0);
        let grandParentGuid = parentStep?.parentGuid;
        if (!grandParentGuid) {
          return;
        }

        let [grandParentStep] = findStep(grandParentGuid, state.SW.steps, 0);
        if (!grandParentStep) {
          return;
        }

        if (!grandParentStep.isTask) {
          return;
        }
      }

      // Clear out the metadata for the step. IF ITS NOT TLM SWI
      if (action.payload.swType !== SWTypes.TLMSWI) {
        step.isCritComp = false;
        step.isComprehensionRequired = false;
        step.isCritStep = false;
        step.critCompText = undefined;
        step.critCompAnsSufficient = undefined;
        step.critCompAnsNeedsImprov = undefined;
        step.OOS = [];
      }

      // Add the child step.
      step.children.push({
        guid: newGuid,
        parentGuid: step.guid,
        title: "",
        sortOrder: (step.children.length && step.children.filter(a => a.rcContainer).length > 0) ? findNextSortOrder(step.children)
          : step.children.length
            ? Math.max.apply(Math, step.children.map(x => x.sortOrder)) + 1
            : 1,
        components: [],
        children: [],
        isMasterStep: false,
        isMemoryStep: false,
        isComprehensionRequired: false,
        isConditional: false,
        isCritComp: false,
        isCritStep: false,
        depth: depth + 1,
        hasUnResolvedComments: false,
        stepComments: [],
        isTask: false,
        isRCStep: false,
        isDisabled: false,
        stepType: stepType,
        isStale: false,
        OOS: [],
        usage: action.payload.swType === SWTypes.TLMSWI ? (step.depth === 0 ? Usage.Internal : undefined) : undefined, //if it is a TLMSWI step, set default to Internal
      });
    }

    state.isDirty = Math.random();

    state.stepVisibilities.push({
      stepGuid: newGuid,
      isOpen: true,
    });

    applyStepSortOrder(state.SW.steps)

    //set the guid here for active step guid.
    state.SW.activeStepGuid = newGuid;
  });

  // Duplicate Step
  builder.addCase(duplicateStep, (state, action) => {
    let [step, depth] = findStep(action.payload.stepGuid,
      state.SW.steps,
      0);

    if (!step) {
      return;
    }

    state.isDirty = Math.random();
    let newStep = cloneDeep(step);
    newStep.id = undefined;
    newStep.guid = uuidv4();
    newStep.immutableGuid = uuidv4();

    newStep.components.forEach(component => {
      component.immutableGuid = uuidv4();
    });

    newStep.sortOrder++;

    const recursivelyUpdateChildren = (step: IStep, resetImmutableGuids: boolean) => {
      step.children.forEach(child => {
        child.guid = uuidv4();
        child.id = undefined;
        child.parentGuid = step.guid;

        const shouldResetImmutableGuids =
          resetImmutableGuids && !child.isRCStep && !child.rcContainer;

        if (shouldResetImmutableGuids) {
          child.immutableGuid = uuidv4();

          child.components.forEach(component => {
            component.immutableGuid = uuidv4();
          });
        }

        recursivelyUpdateChildren(child, shouldResetImmutableGuids);
      });
    };

    recursivelyUpdateChildren(newStep, true);

    if (depth > 0
      && newStep.parentGuid) {
      let [pStep] = findStep(newStep.parentGuid,
        state.SW.steps,
        0);

      if (pStep) {
        pStep.children.splice(step?.sortOrder, 0, newStep);
        pStep.children.forEach((x: IStep, ix: number) => x.sortOrder = ix + 1);
      }
    } else {
      state.SW.steps.splice(step.sortOrder, 0, newStep);
      state.SW.steps.forEach((x: IStep, ix: number) => x.sortOrder = ix + 1);
    }
    // Apply new Order
    state.SW.steps = applyStepSortOrder(state.SW.steps);

    state.stepVisibilities.push({
      stepGuid: newStep.guid,
      isOpen: true,
    });
  });

  // Update Step Comment Info
  builder.addCase(updateStepCommentsInfo, (state, action) => {

    state.SW.steps.forEach(a => {
      if (a.id === action.payload.stepID) {
        a.hasUnResolvedComments = action.payload.hasUnresolvedComments
      }
      // child level
      a.children.forEach(b => {
        if (b.id === action.payload.stepID) {
          b.hasUnResolvedComments = action.payload.hasUnresolvedComments
        }

        // grand child level
        b.children.forEach(c => {
          if (c.id === action.payload.stepID) {
            c.hasUnResolvedComments = action.payload.hasUnresolvedComments
          }

          //great grand-child
          c.children.forEach(d => {
            if (d.id === action.payload.stepID) {
              d.hasUnResolvedComments = action.payload.hasUnresolvedComments
            }
          })
        })
      });
    });

    state.SW.totalStepComments = action.payload.totalStepComments;
    state.SW.unResolvedStepComments = action.payload.unResolvedStepComments;

  });

  // Delete Step
  builder.addCase(deleteStep, (state, action) => {
    // Find the step that is to be removed
    let [step] = findStep(action.payload.stepGuid,
      state.SW.steps,
      0);

    if (!step) {
      return;
    }

    state.isDirty = Math.random();

    if (step.isRCStep
      && step.rcID) {
      findAndDeleteAllRCStep(step.rcID, state.SW.steps);
    } else {
      // Remove the step from the SW.
      findAndDeleteStep(action.payload.stepGuid, state.SW.steps);

      if (state.SW.steps.length === 0) {
        state.SW.rcType = undefined;
      }
    }
    // Now, depending on that step's location, renumber all sibling steps.
    state.SW.steps = applyStepSortOrder(state.SW.steps)
  });
  
  // Update Steps Metadata
  builder.addCase(updateTaskStepsMetadata, (state, action) => {
    if(action.payload.metadataName == "serviceLevel"){
      state.SW.steps.forEach(step => { 
        if(!step.rcContainer){
          step.serviceLevel = action.payload.value;
        }else{
          step?.children.forEach(x => x.serviceLevel == null);
        }
      });
    }
  });

  // Set File Upload Operation
  builder.addCase(setFileUploadOperation, (state, action) => {
    state.fileUploadOperation = action.payload;
  });

  // Set Load Operation
  builder.addCase(setLoadOperation, (state, action) => {
    state.loadOperation = action.payload;
  });

  // Set Create Operation
  builder.addCase(setCreateOperation, (state, action) => {
    state.createOperation = action.payload;
  });

  builder.addCase(setPublishOperation, (state, action) => {
    state.publishOperation = action.payload;
  });

  // Set Update Operation
  builder.addCase(setUpdateOperation, (state, action) => {
    state.updateOperation = action.payload;
  });

  // Set Background Update Operation
  builder.addCase(setBackgroundUpdateOperation, (state, action) => {
    state.backgroundUpdateOperation = action.payload;
  });

  // Finish Creating
  builder.addCase(finishCreating, (state, action) => {
    state.SW.guid = action.payload.swGuid;
    state.SW.serverLanguage = state.SW.language;
    state.createOperation = {
      isWorking: false,
      wasSuccessful: true,
    };
    state.currentTab = ManageSWTabs.Steps;
    state.isDirty = false;
  });

  // Finish Creating
  builder.addCase(finishRCFromTLMSWICreating, (state, action) => {
    state.ReusableCompFromTLMSWI.guid = action.payload.swGuid;
  });

  // Finish Updating
  builder.addCase(finishRCFromTLMSWIUpdating, (state, action) => {
    state.ReusableCompFromTLMSWI = action.payload;
  });

  // Finish RC from blob copying
  builder.addCase(finishRCFromBlobCopying, (state, action) => {
    state.loadOperation = undefined;
  });

  // Finish Updating
  builder.addCase(finishUpdating, (state, action) => {
    state.SW = action.payload;
    state.isDirty = false;
  });

  // Finish background update
  builder.addCase(finishBackgroundUpdate, (state, action) => {
    state.isDirty = false;
  });

  // Finish Loading
  builder.addCase(finishLoading, (state, action) => {
    state.SW = action.payload;
    state.loadOperation = undefined;
    state.isDirty = false;
    state.currentTab = ManageSWTabs.Steps;

    if(state.SW.templateType == null &&
        state.SW.type == SWTypes.TLMSWI){
      state.SW.templateType = "Standard";
      state.SW.contentType = ContentTypes.Executable;
    }
  });

  // Finish Unlocking SW current editor.
  builder.addCase(finishUnlockSWCurrentEditor, (state, action) => {
    state.SW.currentEditor = action.payload.currentEditor;
    state.SW.currentEditorLockedDate = action.payload.currentEditorLockedDate;
    state.updateOperation = undefined;
    state.isDirty = false;
  });

  // Set Comments
  builder.addCase(setComments, (state, action) => {
    state.commentData = action.payload;
  });

  // Set Publish RC Data
  builder.addCase(updatePublishRCData, (state, action) => {
    state.rcPublishData.dependentSWsCount = action.payload;
  })

  // Set TimeImage Data
  builder.addCase(setTimeImageData, (state, action) => {
    state.timeImageData = action.payload;
  })


  // Set TimeImage Data
  builder.addCase(setTimeMediaWhereUsedFiles, (state, action) => {
    state.timeMediaFilesMappingData = action.payload;
  })

  // Close TimeImage Mapping Popup
  builder.addCase(cancelTimeMediaMappingModal, (state) => {
    state.timeMediaFilesMappingData = Object.assign({}, initialState.timeMediaFilesMappingData);
  })

  // Set revision histories
  builder.addCase(setRevisionHistories, (state, action) => {
    state.revisionHistories = action.payload.revisionHistories;
  });

  // set Step Comments
  builder.addCase(setStepComments, (state, action) => {
    state.stepCommentData = action.payload;
  })

  //set sw notices
  builder.addCase(setSWNotices, (state, action) => {
      state.swNoticeData = action.payload;
  })

  //set swStep notices
  builder.addCase(setSWStepNotices, (state, action) => {
      state.swStepNoticeData = action.payload;
  })


  // Add New Reference Doc
  builder.addCase(addNewReferenceDoc, (state, action) => {
    state.isDirty = Math.random();
    state.SW.referenceDocs.push({
      id: undefined,
      guid: uuidv4(),
      refDocData: "",
      label: "",
      sortOrder: 0,
      type: action.payload.type,
      lcl: state.SW.type === SWTypes.LCL ? true : false,
    });

    state.SW.referenceDocs.forEach((x, ix) => x.sortOrder = ix + 1);
  });

  // Add New Reference Doc Table
  builder.addCase(addNewReferenceDocTable, (state) => {
    state.isDirty = Math.random();
    state.SW.referenceDocs.push({
      id: undefined,
      guid: uuidv4(),
      refDocData: "",
      label: "",
      sortOrder: 0,
      type: 'Table',
      lcl: state.SW.type === SWTypes.LCL ? true : false,
      tableData: {
        rowCount: 2,
        colCount: 2,
        cells: []
      }
    });

    state.SW.referenceDocs.forEach((x, ix) => x.sortOrder = ix + 1);
  });

  // Remove Reference Doc
  builder.addCase(removeReferenceDoc, (state, action) => {
    state.isDirty = Math.random();
    state.SW.referenceDocs = state.SW.referenceDocs
      .filter(x => x.guid !== action.payload.refDocGuid);

    state.SW.referenceDocs.forEach((x, ix) => x.sortOrder = ix + 1);
  });

  // Set Ref Doc Label
  builder.addCase(setRefDocLabel, (state, action) => {
    state.isDirty = Math.random();
    const refDoc = state.SW.referenceDocs.find(x => x.guid === action.payload.refDocGuid);

    if (refDoc) {
      refDoc.label = action.payload.label;
    }
  });

  // Set Ref Doc Filename
  builder.addCase(setRefDocFilename, (state, action) => {
    state.isDirty = Math.random();
    const refDoc = state.SW.referenceDocs.find(x => x.guid === action.payload.refDocGuid);

    if (refDoc) {
      refDoc.refDocData = action.payload.filename;
    }
  });

  // Update Ref Doc Table
  builder.addCase(setRefDocTableData, (state, action) => {
    state.isDirty = Math.random();
    const refDoc = state.SW.referenceDocs.find(x => x.guid === action.payload.refDocGuid);

    if (refDoc) {
      refDoc.tableData = action.payload.component;
    }
  });

  // Update Ref Doc Table Cell
  builder.addCase(updateRefDocTableCell, (state, action) => {
    state.isDirty = Math.random();
    const refDoc = state.SW.referenceDocs.find(x => x.guid === action.payload.refDocGuid);

    if (refDoc && refDoc.tableData) {
      let existingComponent = refDoc.tableData;
      let newCell = action.payload.cell;
      // Find existing cell
      let existingCell = existingComponent.cells.find(x => x.colIndex === newCell.colIndex && x.rowIndex === newCell.rowIndex);
      if (!existingCell) {
        // No cell found, just add it if its not blank.
        if ((newCell.value !== undefined && newCell.value !== '' && newCell.value !== '<p></p>\n')
          || (newCell.colSpan > 1 || newCell.rowSpan > 1)) {
          existingComponent.cells.push(newCell);
        }
      } else {
        // Delete existing cell.
        let existingCellIx = existingComponent.cells.indexOf(existingCell);
        existingComponent.cells.splice(existingCellIx, 1);

        // If cell value is not blank, add it.
        if ((newCell.value !== undefined && newCell.value !== '' && newCell.value !== '<p></p>\n')
          || (newCell.colSpan > 1 || newCell.rowSpan > 1)) {
          existingComponent.cells.push(newCell);
        }
      }
    }
  });

  // Set ref doc table image filename
  builder.addCase(setRefDocTableImageFilename, (state, action) => {
    state.isDirty = Math.random();

    const refDoc = state.SW.referenceDocs.find(x => x.guid === action.payload.refDocGuid);
    if (!refDoc) {
      return;
    }

    let existingComponent = refDoc.tableData;
    if (!existingComponent) {
      return;
    }

    // Find existing cell
    let existingCell = existingComponent.cells.find(x => x.colIndex === action.payload.colIndex && x.rowIndex === action.payload.rowIndex);
    if (!existingCell) {
      existingComponent.cells.push(
        {
          rowIndex: action.payload.rowIndex,
          colIndex: action.payload.colIndex,
          value: "",
          rowSpan: 1,
          colSpan: 1,
        });

      existingCell = existingComponent.cells.find(x => x.colIndex === action.payload.colIndex && x.rowIndex === action.payload.rowIndex);
    }

    if (!existingCell) {
      return;
    }

    existingCell.imageFileName = action.payload.serverFilename;
  });

  // Update Table Cell Image Blob Data
  builder.addCase(updateRefDocTableCellBlobData, (state, action) => {
    let fileName = action.payload.fileName;

    // Find Component.
    const refDoc = state.SW.referenceDocs.find(x => x.guid === action.payload.refDocGuid);
    if (!refDoc) {
      return;
    }

    let existingComponent = refDoc.tableData;
    if (!existingComponent) {
      return;
    }

    // Find existing cell
    let existingCell = existingComponent.cells.find(x => x.colIndex === action.payload.colIndex && x.rowIndex === action.payload.rowIndex);

    if (!existingCell) {
      return;
    }

    existingCell.value = existingCell.value.replace("src=\"\"", "src=\"" + fileName + "\"");
    existingCell.imageFileName = fileName;
  });

  // Set Step Visibility
  builder.addCase(setStepVisibility, (state, action) => {
    let existing = state.stepVisibilities.find(x => x.stepGuid === action.payload.stepGuid);

    if (existing) {
      existing.isOpen = action.payload.isOpen;
    } else {
      state.stepVisibilities.push(action.payload);
    }
  });

  // Set Notice Visibility
  builder.addCase(setNoticeVisibility, (state, action) => {
    let existing = state.noticeVisibilities.find(x => x.noticeGuid === action.payload.noticeGuid);

    if (existing) {
      existing.isOpen = action.payload.isOpen;
    } else {
      state.noticeVisibilities.push(action.payload);
    }
  });

  builder.addCase(setReusableCompSortable, (state, action) => {
    state.ReusableCompSortable = action.payload;
  });

  builder.addCase(setIsSearchRCNoticeOpen, (state, action) => {
    state.isSearchRCNoticeOpen = action.payload;
  });

  builder.addCase(setRCNoticeStep, (state, action) => {
    state.rcNoticeStep = action.payload;
  });

  builder.addCase(setCreateRCNoticePopup, (state, action) => {
    state.isCreateRCNoticeOpen = action.payload.rcNoticePopupOpen;
    if (action.payload.rcNoticePopupOpen === false) {
      state.ReusableCompFromTLMSWI.title = "";
      state.ReusableCompFromTLMSWI.description = "";
      state.ReusableCompFromTLMSWI.owningOrg = undefined;
      state.ReusableCompFromTLMSWI.productCenters = [];
      state.ReusableCompFromTLMSWI.epicEquipment = [];
      state.ReusableCompFromTLMSWI.steps = [];
      state.ReusableCompFromTLMSWI.notices = [];
      state.reusableCompSelectedType = undefined;
    }
    else {
      state.ReusableCompFromTLMSWI.language = state.SW.language;
      state.ReusableCompFromTLMSWI.owningOrg = state.SW.owningOrg;
      state.ReusableCompFromTLMSWI.productCenters = state.SW.productCenters;
      state.ReusableCompFromTLMSWI.epicEquipment = state.SW.epicEquipment;
      let data = action.payload.notice;
      if (data) {
        let notice: INoticeComponent = {
          guid: uuidv4(),
          type: data.type,
          noticeType: data.noticeType,
          sortOrder: data.sortOrder,
          image: data.image,
          rcID: undefined,
          isRCComponent: false,
          rc: undefined,
          rcTitle: undefined,
          isDisabled: false,
          label: data.label,
          hazardCategory: data.hazardCategory,
        };
        state.ReusableCompFromTLMSWI.notices.push(notice);
        state.ReusableCompFromTLMSWI.rcType = RCTypes.Notice;
        state.reusableCompSelectedType = ReusableCompType.Notice;
      }
    }
  });

  builder.addCase(setCreateReusableContentPopup, (state, action) => {
    state.isCreateReusableContentOpen = action.payload.isCreateRCPopup;
    if (action.payload.isCreateRCPopup === false) {
      state.ReusableCompFromTLMSWI.title = "";
      state.ReusableCompFromTLMSWI.description = "";
      state.ReusableCompFromTLMSWI.owningOrg = undefined;
      state.ReusableCompFromTLMSWI.productCenters = [];
      state.ReusableCompFromTLMSWI.epicEquipment = [];
      state.ReusableCompFromTLMSWI.steps = [];
      state.ReusableCompFromTLMSWI.owningPlant = undefined;
      state.ReusableCompFromTLMSWI.performingPlants = [];
      state.ReusableCompFromTLMSWI.workCenters = [];
      state.ReusableCompFromTLMSWI.materials = [];
      state.ReusableCompFromTLMSWI.gemsRevisionNumber = undefined;
      state.ReusableCompFromTLMSWI.plantSource = undefined;
      state.reusableCompSelectedType = undefined;
    }
    else {
      state.ReusableCompFromTLMSWI.language = state.SW.language;
      state.ReusableCompFromTLMSWI.owningOrg = state.SW.owningOrg;
      state.ReusableCompFromTLMSWI.productCenters = state.SW.productCenters;
      state.ReusableCompFromTLMSWI.epicEquipment = state.SW.epicEquipment;
      if(action.payload.swType === SWTypes.MFGSWI 
        ||  action.payload.swType === SWTypes.MFGCL)
      {
        state.ReusableCompFromTLMSWI.owningPlant = state.SW.owningPlant;
        state.ReusableCompFromTLMSWI.performingPlants = state.SW.performingPlants;
        state.ReusableCompFromTLMSWI.workCenters = state.SW.workCenters;
        state.ReusableCompFromTLMSWI.materials = state.SW.materials;
        state.ReusableCompFromTLMSWI.gemsRevisionNumber = state.SW.gemsRevisionNumber;
        state.ReusableCompFromTLMSWI.plantSource = state.SW.plantSource;
        state.ReusableCompFromTLMSWI.type = SWTypes.MFGRC;
      }
    }
  });

  builder.addCase(setReusableCompMode, (state, action) => {
    var reusableTaskItem = state.ReusableCompSortable.find(r => r.checked);
    var reusableStepCount = 0;
    var reusableSubStepCount = 0;
    state.ReusableCompSortable.forEach(reusableCompItem => {
      reusableCompItem.children?.filter((r: any) => r.checked).every(() => {
        reusableStepCount++;
      });
      reusableCompItem.children?.forEach((reusableChildCompItem: any) => {
        reusableChildCompItem.children?.filter((r: any) => r.checked).every(() => {
          reusableSubStepCount++;
        });
      });
    });

    const items = state.ReusableCompSortable;
    if (reusableTaskItem) {
      state.reusableCompSelectedType = ReusableCompType.Task;
      items.forEach((item) => {
        item.isEnabled = true;
        item.children?.forEach((citem: any) => {
          citem.isEnabled = false;
          citem.children?.forEach((gcitem: any) => {
            gcitem.isEnabled = false;
          });
        });
      });
    }
    else if (reusableStepCount === 0 && reusableSubStepCount === 0) {
      state.reusableCompSelectedType = undefined;
      items.forEach((item) => {
        item.isEnabled = action.payload !== SWTypes.MFGSWI;
        item.children?.forEach((citem: any) => {
          citem.isEnabled = true;
          citem.children?.forEach((gcitem: any) => {
            gcitem.isEnabled = true;
          });
        });
      });
    }
    else if (reusableStepCount > 0) {
      state.reusableCompSelectedType = ReusableCompType.Step;
      items.forEach((item) => {
        item.isEnabled = false;
        item.children?.forEach((citem: any) => {
          citem.isEnabled = true;
          citem.children?.forEach((gcitem: any) => {
            gcitem.isEnabled = false;
          });
        });
      });
    }
    else {
      state.reusableCompSelectedType = ReusableCompType.SubStep;
      items.forEach((item) => {
        item.isEnabled = false;
        item.children?.forEach((citem: any) => {
          citem.isEnabled = false;
          citem.children?.forEach((gcitem: any) => {
            gcitem.isEnabled = true;
          });
        });
      });
    }
  });

  builder.addCase(addReusableCompItemChecked, (state, action) => {
    var reusableCompItem = state.ReusableCompSortable.find(r => r.id === action.payload.id);
    if (reusableCompItem !== undefined) {
      reusableCompItem.checked = true;
      reusableCompItem.children?.forEach((childElement: any) => {
        childElement.checked = true;
        childElement.children?.forEach((gchildElement: any) => {
          gchildElement.checked = true;
          gchildElement.children?.forEach((ggchildElement: any) => {
            ggchildElement.checked = true;
          });
        });
      });
    }
    else {
      let itemfound = false;
      for (let i = 0; i < state.ReusableCompSortable.length; i++) {
        let reusableCompChildItem = state.ReusableCompSortable[i].children?.find((r: any) => r.id === action.payload.id);
        if (reusableCompChildItem !== undefined) {
          reusableCompChildItem.checked = true;
          reusableCompChildItem.children?.forEach((gchildElement: any) => {
            gchildElement.checked = true;
            gchildElement.children?.forEach((ggchildElement: any) => {
              ggchildElement.checked = true;
            });
          });
          itemfound = true;
          break;
        }
        else {
          let children = state.ReusableCompSortable[i].children;
          if (children) {
            for (let j = 0; j < children.length; j++) {
              let childElement = children[j];
              let reusableCompGrandChildItem = childElement.children?.find((r: any) => r.id === action.payload.id);
              if (reusableCompGrandChildItem) {
                reusableCompGrandChildItem.checked = true;
                itemfound = true;
                break;
              } else {
                let grandChildren = childElement.children;
                if (grandChildren) {
                  for (let k = 0; k < grandChildren.length; k++) {
                    let grandChildElement = grandChildren[k];
                    let reusableCompGreatGrandChildItem = grandChildElement.children?.find((r: any) => r.id === action.payload.id);
                    if (reusableCompGreatGrandChildItem) {
                      reusableCompGreatGrandChildItem.checked = true;
                      itemfound = true;
                      break;
                    }
                  }
                }

                if (itemfound) {
                  break;
                }
              }
            }
          }

          if (itemfound) {
            break;
          }
        }
      }
    }
  });

  builder.addCase(removeReusableCompItemChecked, (state, action) => {
    var reusableCompItem = state.ReusableCompSortable.find(r => r.id === action.payload.id);
    if (reusableCompItem !== undefined) {
      reusableCompItem.checked = false;
      reusableCompItem.children?.forEach((childElement: any) => {
        childElement.checked = false;
        childElement.children?.forEach((gchildElement: any) => {
          gchildElement.checked = false;
          gchildElement.children?.forEach((ggchildElement: any) => {
            ggchildElement.checked = false;
          });
        });
      });
    }
    else {
      let itemfound = false;
      for (let i = 0; i < state.ReusableCompSortable.length; i++) {
        let reusableCompChildItem = state.ReusableCompSortable[i].children?.find((r: any) => r.id === action.payload.id);
        if (reusableCompChildItem !== undefined) {
          reusableCompChildItem.checked = false;
          let reusableCompParentItem = state.ReusableCompSortable.find(r => r.id === reusableCompChildItem?.nestedIn);
          if (reusableCompParentItem !== undefined) {
            reusableCompParentItem.checked = false;
          }
          reusableCompChildItem.children?.forEach((gchildElement: any) => {
            gchildElement.checked = false;
            gchildElement.children?.forEach((ggchildElement: any) => {
              ggchildElement.checked = false;
            });
          });
          itemfound = true;
          break;
        }
        else {
          let children = state.ReusableCompSortable[i].children;
          if (children) {
            for (let j = 0; j < children.length; j++) {
              let childElement = children[j];
              let reusableCompGrandChildItem = childElement.children?.find((r: any) => r.id === action.payload.id);
              if (reusableCompGrandChildItem) {
                reusableCompGrandChildItem.checked = false;
                itemfound = true;
                break;
              } else {
                let grandChildren = childElement.children;
                if (grandChildren) {
                  for (let k = 0; k < grandChildren.length; k++) {
                    let grandChildElement = grandChildren[k];
                    let reusableCompGreatGrandChildItem = grandChildElement.children?.find((r: any) => r.id === action.payload.id);
                    if (reusableCompGreatGrandChildItem) {
                      reusableCompGreatGrandChildItem.checked = false;
                      itemfound = true;
                      break;
                    }
                  }
                }

                if (itemfound) {
                  break;
                }
              }
            }
          }

          if (itemfound) {
            break;
          }
        }
      }
    }
  });

  // Scroll To Step
  builder.addCase(expandAndScrollToStep, (state, action) => {
    let toExpand = action.payload.ancestorGuids
      .concat(action.payload.stepGuid);

    // Expand all the steps.
    toExpand.forEach(guid => {
      let existing = state.stepVisibilities.find(x => x.stepGuid === guid);

      if (existing) {
        existing.isOpen = true;
      } else {
        state.stepVisibilities.push({
          stepGuid: guid,
          isOpen: true,
        });
      }
    });

    // Set the step guid to scroll to.
    state.scrollToStepGuid = action.payload.stepGuid;
    //set the guid here for active step guid.
    state.SW.activeStepGuid = action.payload.stepGuid;
  });

  // Finish Scrolling To Step
  builder.addCase(finishScrollingToStep, state => {
    state.scrollToStepGuid = undefined;
  });

  // Set Active STep Guid
  builder.addCase(setActiveStepGuid, (state, action) => {
    state.SW.activeStepGuid = action.payload;
  });

  // Update notice image blob data
  builder.addCase(updateNoticeImageBlobData, (state, action) => {
    const notice = state.SW.notices.find(x => x.guid === action.payload.noticeGuid);

    if (notice) {
      notice.image.blobData = action.payload.blob;
      notice.image.filename = action.payload.filename;
      notice.image.imageType = action.payload.imageType;
    }
    else {
      let [step,] = findStep(action.payload.stepGuid!, state.SW.steps, 0);
      if (!step) {
        return;
      }

      let existingComponent = step.components.find(x => x.guid === action.payload.noticeGuid);

      if (!existingComponent ||
        existingComponent.type !== StepComponentTypes.Notice) {
        return;
      }

      existingComponent.image.blobData = action.payload.blob;
      existingComponent.image.filename = action.payload.filename;
      existingComponent.image.imageType = action.payload.imageType;
    }

    if (action.payload.isDirty !== undefined) {
      state.isDirty = action.payload.isDirty ? Math.random() : false;
    }
  });

  // Update video blob data
  builder.addCase(updateVideoBlobData, (state, action) => {
    let [step,] = findStep(action.payload.stepGuid!, state.SW.steps, 0);
    if (!step) {
      return;
    }

    let existingComponent = step.components.find(x => x.guid === action.payload.componentGuid);

    if (!existingComponent ||
      existingComponent.type !== StepComponentTypes.Video) {
      return;
    }

    existingComponent.blobData = action.payload.blob;
    existingComponent.filename = action.payload.filename;

    if (action.payload.isDirty !== undefined) {
      state.isDirty = action.payload.isDirty ? Math.random() : false;
    }
  });

  // Update Audio blob data
  builder.addCase(updateAudioBlobData, (state, action) => {
    let [step,] = findStep(action.payload.stepGuid!, state.SW.steps, 0);
    if (!step) {
      return;
    }

    let existingComponent = step.components.find(x => x.guid === action.payload.componentGuid);

    if (!existingComponent ||
      existingComponent.type !== StepComponentTypes.Audio) {
      return;
    }

    existingComponent.blobData = action.payload.blob;
    existingComponent.filename = action.payload.filename;

    if (action.payload.isDirty !== undefined) {
      state.isDirty = action.payload.isDirty ? Math.random() : false;
    }
  });

  // Set Current Execution Step
  builder.addCase(setCurrentExecutionPreviewStep, (state, action) => {
    state.currentLocation = action.payload;
    return state;
  });

  // Disable RC content
  builder.addCase(disableRcContent, (state, action) => {
    let [step,] = findStep(action.payload.stepGuid, state.SW.steps, 0);
    if (!step) {
      return;
    }

    step.isDisabled = action.payload.disabled;

    const recursivelyUpdateChildren = (step: IStep) => {
      step.children.forEach(child => {
        child.isDisabled = action.payload.disabled;
        recursivelyUpdateChildren(child);
      });
    };

    recursivelyUpdateChildren(step);

    state.isDirty = Math.random();
    return state;
  });

  // Set Group
  builder.addCase(setGroup, (state, action) => {
    state.isDirty = Math.random();
    state.SW.group = action.payload;
  });

  // Set Group Counter
  builder.addCase(setGroupCounter, (state, action) => {
    state.isDirty = Math.random();
    state.SW.groupCounter = action.payload;
  });

  // Set Confidential TCC Users
  builder.addCase(setConfidentialEnabledUserList, (state, action) => {
    state.confidentialSWData.confidentialEnabledUsers = action.payload;
  });

  builder.addCase(setConfidentialAccessChangeOperation, (state, action) => {
    state.confidentialSWData.changeOperation = action.payload;
  });

  builder.addCase(setConfidentialUsersLoadOperation, (state, action) => {
    state.confidentialSWData.loadOperation = action.payload;
  });

  builder.addCase(setSetConnectedSWIs, (state, action) => {
    state.SW.connectedSWIs = action.payload;
  });

  builder.addCase(setUploadConnectedSWIsErrors, (state, action) => {
    state.connectedSWIsUploadErrors = action.payload;
  });

  builder.addCase(setConnectedSWIGroup, (state, action) => {
    state.connectedSWIData.draft.group = action.payload;
  });

  builder.addCase(setConnectedSWIGroupCounter, (state, action) => {
    state.connectedSWIData.draft.groupCounter = action.payload;
  });

  builder.addCase(setConnectedSWIMaterial, (state, action) => {
    state.connectedSWIData.draft.material = action.payload;
  });

  builder.addCase(setAddConnectedSWILoadOperation, (state, action) => {
    state.connectedSWIData.addOperation = action.payload;
  });

  builder.addCase(setPendingCompatibilityFetchRCStepsAction, (state, action) => {
    state.pendingCompatibilityFetchRCStepsAction = action.payload;
  });

  builder.addCase(setConnectedSWIEnabled, (state, action) => {
    state.enableConnectedSWI = action.payload;
  });
});

export default manageSWReducer;

function updateSWTitle(sw: ISW) {
  if (sw.type === SWTypes.ECL
    || sw.type === SWTypes.TLMSWI
    || sw.type === SWTypes.TLMRC
    || sw.type === SWTypes.MFGSWI
    || sw.type === SWTypes.MFGCL
    || sw.type === SWTypes.Unspecified
    || sw.type === SWTypes.TIMERC) {
    return;
  }

  if (sw.language?.value !== "English") {
    return;
  }

  let taskName = sw.tasks.length
    ? sw.tasks[0].value
    : "";

  if (sw.type === SWTypes.CL || sw.type === SWTypes.LCL) {
    sw.title = `${sw.taskAlignment.toString()} - ${taskName}`;
  } else if (sw.type === SWTypes.SWI) {
    sw.title = taskName;
  }
}