import React from "react";
import PPEImage from "./PPEImage";
import "./PPEList.scoped.scss";

interface IPPEListProps {
    ppe: string[],
}

const PPEList: React.FC<IPPEListProps> = ({ ppe }) => {
    return (
        <div
            className="ppe-section"
        >
            <span
                className="text"
            >
                {'Necessary Equipment'}
            </span>
            <span
                className="images"
            >
                {ppe.map((ppe, ix) => (
                    <PPEImage
                        key={ix}
                        ppe={ppe}
                    />
                ))}
            </span>
        </div>);
};

export default PPEList;