
import React, { useState } from "react";
import FeedbackSaeModal from "components/feedback/FeedbackSaveModal";
import './TitleModalProps.scoped.scss';
interface ITitleModalProps {
    modal: string;
    /** The title description for the modal */
    title: string;
    /** The display text for the link */
    text?: string;
    /** The link icon's src location */
    iconImg?: string;
    /** The link icon's title description */
    iconTitle?: string;
  }

const TitleModalProps: React.FC<ITitleModalProps> = (props) => {
  const [showModalFeedback, setShowModalFeedback] = useState(false);

  const showModal = () => {
    setShowModalFeedback((prevState) => !prevState);
  };

  return (
    <>
      {props.modal == "feedback" && (
        <>
          <span onClick={showModal} className="title-modal">
            {props.text || props.title}
          </span>
          {props.iconImg!.length > 0 && (
            <img
              className="icon-medium black-to-white-filter"
              src={props.iconImg}
              alt={props.iconTitle || props.title}
              onClick={showModal}
            />
          )}
          <FeedbackSaeModal
            onClose={() => setShowModalFeedback(false)}
            isOpen={showModalFeedback}
            title={props.title}
          ></FeedbackSaeModal>
        </>
      )}
    </>
  );
};

export default TitleModalProps;
