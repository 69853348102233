import { takeLatest, put, all, call, select, takeEvery } from "redux-saga/effects";
import { Action } from "@reduxjs/toolkit";
import { addSWs, resetAll, setSubmissionErrorResponse, setSubmitOperation, submitBatchUpdates, tryAddSWs } from "./batchUpdatesActions";
import BatchUpdatesApi from "apis/batchUpdates/BatchUpdatesApi";
import { RootState } from "store/rootStore";
import { IBatchUpdatesState } from "./batchUpdatesTypes";
import { showErrorToast, showInfoToast, showSuccessToast } from "store/toast/toastActions";
import { ISWSummaryItem } from "interfaces/sw/SWInterfaces";
import { IBatchUpdateResponse } from "interfaces/batchUpdates/batchUpdatesInterfaces";

export default function* watchBatchUpdatesSagas() {
  yield all([
    watchSubmitBatchUpdates(),
    watchTryAddSWs(),
  ]);
}

function* watchSubmitBatchUpdates() {
  yield takeLatest(submitBatchUpdates, submitBatchUpdatesAsync);
}

function* submitBatchUpdatesAsync(action: Action) {
  if (!submitBatchUpdates.match(action)) {
    return;
  }

  const batchUpdatesState: IBatchUpdatesState = yield select((store: RootState) => store.batchUpdates);

  if (batchUpdatesState.addedSWs.length === 0) {
    yield put(showErrorToast("There are no Standard Works added to this batch update."));
    return;
  }

  if (batchUpdatesState.commands.length === 0) {
    yield put(showErrorToast("There are no updates added to this batch update."));
    return;
  }

  yield put(setSubmitOperation({
    isWorking: true,
  }));

  try {
    const result: IBatchUpdateResponse = yield call(BatchUpdatesApi.submitBatchUpdates,
      batchUpdatesState.addedSWs,
      batchUpdatesState.commands);

    if (!result.success) {
      // If the server returns a 400 error,
      // put the details into the store.
      yield put(setSubmitOperation({
        isWorking: false,
      }));

      yield put(setSubmissionErrorResponse(result));
      return;
    }

    yield put(resetAll());
    yield put(showSuccessToast("Batch updates have been submitted for approval."));
  } catch (err: any) {
    yield put(setSubmissionErrorResponse(undefined));
    yield put(showErrorToast("Batch updates submission failed: \n"
      + (err.message || err)));
    yield put(setSubmitOperation({
      isWorking: false,
    }));
  }
}

function* watchTryAddSWs() {
  yield takeEvery(tryAddSWs, tryAddSWsAsync);
}

function* tryAddSWsAsync(action: Action) {
  if (!tryAddSWs.match(action)) {
    return;
  }

  const addedSWs: ISWSummaryItem[] = yield select(
    (store: RootState) => store.batchUpdates.addedSWs);

  // Validate the list to be added.
  const swsToAdd: ISWSummaryItem[] = yield call(validateAndRemoveInvalidSWs,
    action.payload,
    addedSWs);

  if (swsToAdd.length === 0) {
    return;
  }

  yield put(addSWs(swsToAdd));

  if (swsToAdd.length > 1) {
    yield put(showInfoToast(
      `Added ${swsToAdd.length} Standard Works.`));
  } else {
    yield put(showInfoToast(
      `Added ${swsToAdd[0].title}.`));
  }
}

function* validateAndRemoveInvalidSWs(sws: ISWSummaryItem[], addedSWs: ISWSummaryItem[]) {
  const orgId = addedSWs.length > 0
    ? addedSWs[0].owningOrgId
    : sws[0].owningOrgId;

  const badOrgItems = sws.filter(x => x.owningOrgId !== orgId);
  const alreadyAddedItems: ISWSummaryItem[] = [];
  const okItems = sws.filter(x => x.owningOrgId === orgId);

  for (let i = okItems.length - 1; i >= 0; i--) {
    if (addedSWs.find(x => x.guid === okItems[i].guid)) {
      alreadyAddedItems.push(okItems[i]);
      okItems.splice(i, 1);
    }
  }

  if (alreadyAddedItems.length > 0) {
    if (alreadyAddedItems.length > 1) {
      yield put(showInfoToast(
        `${alreadyAddedItems.length} Standard Works are already included in the batch update.`));
    } else if (alreadyAddedItems.length === 1) {
      yield put(showInfoToast(
        `${alreadyAddedItems[0].title} is already included in the batch update.`));
    }
  }

  if (badOrgItems.length > 0) {
    if (badOrgItems.length > 1) {
      yield put(showErrorToast(
        `Unable to add ${badOrgItems.length} Standard Works as they are owned by a different org.`));
    } else {
      yield put(showErrorToast(
        `Unable to add ${badOrgItems[0].title} as it is owned by a different org.`));
    }
  }

  return okItems;
}