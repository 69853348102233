import React, { useEffect, useState } from "react";
import { ITextInputComponent, SWTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { updateTextInput } from "store/manageSW/manageSWActions";
import "./TextInputEditor.scoped.scss";
import useSelector from "store/useSelector";
import { loadMeters } from "store/masterData/masterDataActions";
import SelectMeter from "./SelectMeter";

interface ITextInputEditorProps {
  textInput: ITextInputComponent,
  allowEdit: boolean,
  stepGuid: string,
  labelText: string,
  swType: SWTypes,
  stepLocation?: string,
}

const TextInputEditor: React.FC<ITextInputEditorProps> = ({
  textInput,
  allowEdit,
  stepGuid,
  labelText,
  swType,
  stepLocation,
}) => {
  const {
    masterData: {
      meters,
    },
    manageSW: {
      enableMetersValue,
      SW: {
        epicEquipment,
      }
    },

  } = useSelector((store) => store);
  const dispatch = useDispatch();

  useEffect(() => {
    if (epicEquipment.length > 0) {
      dispatch(loadMeters({ onlyActive: true, equipmentCodes: epicEquipment, location: "1:Global" }));
    }
  }, [epicEquipment]);

  const filterMeters = stepLocation == undefined || stepLocation == null || stepLocation === "" || stepLocation === "1:Global" ? meters.items : meters.items.filter(t => t.parentGuid === stepLocation);

  return (
    <div className="component-editor">
      <div className="row">
        <label className="mandatory block">{labelText}</label>
        {(enableMetersValue && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC)) &&
          <label className="meter">Meter</label>}
      </div>
      <div className="row">
        {allowEdit &&
          <>
            <input
              type="text"
              defaultValue={textInput.label}
              onBlur={(e) => dispatch(updateTextInput({
                stepGuid,
                component: {
                  ...textInput,
                  label: e.target.value,
                }
              }))}
            />
            {(enableMetersValue && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC)) &&
              <div className="meter">
                <SelectMeter
                  inputComponent={textInput}
                  stepGuid={stepGuid}
                  meters={filterMeters} />
              </div>
            }
          </>
        }
        {!allowEdit &&
          <>
            <span>{textInput.label}</span>
            {(enableMetersValue && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC)) &&
              <span className="meter">{textInput.meterCode}</span>}
          </>
        }
      </div>
    </div>
  );
}

export default TextInputEditor;