import Modal from "components/common/Modal";
import SearchUser from "components/user/management/SearchUser";

import {
  ApprovalStatuses,
  IRCUpdateApprovalDetails,
} from "interfaces/approvals/approvalInterfaces";

import "./RCUpdateApprovalDetailsTable.scoped.scss";

import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import SearchTechContentUser from "components/user/management/SearchTechContentUser";

const shortenDescription = (description: string | undefined) => {
  if (!description) {
    return "";
  }

  if (description.length >= 200) {
    return description.substring(0, 197) + "...";
  } else {
    return description;
  }
};

interface ApprovalStatusTableProps {
  approvalDetailsList: IRCUpdateApprovalDetails[];
  readOnlyApprovalStatus: boolean;
  onApprovalStatusChanged(index: number, status: ApprovalStatuses): void;
  onTechContRevChanged(index: number, techContentReviewer?: string): void;
  readOnlyTechContRev: boolean;
  onSMEChanged(index: number, techContentReviewer?: string): void;
  readOnlySME: boolean;
  showSMEColumn: boolean;
  showTechContRevColumn: boolean;
}

const RCUpdateApprovalDetailsTable: FC<ApprovalStatusTableProps> = function ({
  approvalDetailsList,
  readOnlyApprovalStatus,
  onApprovalStatusChanged,
  readOnlyTechContRev,
  onTechContRevChanged,
  readOnlySME,
  onSMEChanged,
  showSMEColumn,
  showTechContRevColumn,
}) {
  const [techConRevTargetIndex, setTechConRevTargetIndex] = useState<number>();
  const [smeTargetIndex, setSMETargetIndex] = useState<number>();

  const renderTechContRevModal = () => (
    <Modal
      isOpen={typeof techConRevTargetIndex === "number"}
      header="Set Tech Content Reviewer"
      controls={
        <>
          <button
            className="secondary-button"
            onClick={() => setTechConRevTargetIndex(undefined)}
          >
            Cancel
          </button>
          <button
            className="primary-button"
            onClick={() => {
              if (typeof techConRevTargetIndex !== "number") {
                return;
              }

              onTechContRevChanged(techConRevTargetIndex);
              setTechConRevTargetIndex(undefined);
            }}
          >
            Clear
          </button>
        </>
      }
    >
      <SearchTechContentUser
        setSelectedUserFunc={(techContentReviewer) => {
          if (typeof techConRevTargetIndex !== "number") {
            return;
          }

          onTechContRevChanged(techConRevTargetIndex, techContentReviewer);
          setTechConRevTargetIndex(undefined);
        } } 
        optional={false}
        isFilteredList={false}
        isLDAPSearch={true}
      />
    </Modal>
  );

  const renderSMESelectorModal = () => (
    <Modal
      isOpen={typeof smeTargetIndex === "number"}
      header="Set SME"
      controls={
        <>
          <button
            className="secondary-button"
            onClick={() => setSMETargetIndex(undefined)}
          >
            Cancel
          </button>
          <button
            className="primary-button"
            onClick={() => {
              if (typeof smeTargetIndex !== "number") {
                return;
              }

              onSMEChanged(smeTargetIndex);
              setSMETargetIndex(undefined);
            }}
          >
            Clear
          </button>
        </>
      }
    >
      <SearchUser
        setSelectedUserFunc={(smeApprover) => {
          if (typeof smeTargetIndex !== "number") {
            return;
          }

          onSMEChanged(smeTargetIndex, smeApprover);
          setSMETargetIndex(undefined);
        }}
      />
    </Modal>
  );

  return (
    <>
      <table className="max-width">
        <thead>
          <tr>
            <th>SW Name & Description</th>
            <th>Status</th>
            <th>Decision</th>
            {showTechContRevColumn && <th>Tech Content Approver (Optional)</th>}
            {showSMEColumn && <th>SME (Optional)</th>}
          </tr>
        </thead>
        <tbody>
          {approvalDetailsList.map((approvalStatus, index) => (
            <tr key={index}>
              <td>
                <Link
                  className="sw-link"
                  target = "_blank"
                  to={Routes.ViewStandardWork.replace(
                    ":guid",
                    approvalStatus.swGuid
                  ).replace(":version", "0")}//always go to version 0 since its the draft.
                >
                  <p>{approvalStatus.swName}</p>
                  <p className="description">
                    {shortenDescription(approvalStatus.swDescription)}
                  </p>
                </Link>
              </td>
              <td>
                Last Edited {approvalStatus.modifiedOn.toLocaleDateString()} by{" "}
                {approvalStatus.modifiedBy}
              </td>
              <td>
                <select
                  className="max-width"
                  disabled={readOnlyApprovalStatus}
                  value={approvalStatus.status}
                  onChange={(e) =>
                    onApprovalStatusChanged(
                      index,
                      e.target.value as ApprovalStatuses
                    )
                  }
                >
                  <option value={ApprovalStatuses.Active}>----------</option>
                  <option value={ApprovalStatuses.Approved}>
                    {ApprovalStatuses.Approved}
                  </option>
                  <option value={ApprovalStatuses.Rejected}>
                    {ApprovalStatuses.Rejected}
                  </option>
                </select>
              </td>
              {showTechContRevColumn && (
                <td>
                  {approvalStatus.status !== ApprovalStatuses.Rejected && (
                    <input
                      type="search"
                      placeholder="No Tech Content Approver (Click to Change)"
                      className="max-width"
                      value={approvalStatus.techContRev || ""}
                      onClick={() => setTechConRevTargetIndex(index)}
                      readOnly
                      disabled={readOnlyTechContRev}
                    />
                  )}
                </td>
              )}
              {showSMEColumn && (
                <td>
                  {approvalStatus.status !== ApprovalStatuses.Rejected && (
                    <input
                      type="search"
                      placeholder="No SME (Click to Change)"
                      className="max-width"
                      value={approvalStatus.sMEApprover || ""}
                      onClick={() => setSMETargetIndex(index)}
                      readOnly
                      disabled={readOnlySME}
                    />
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {renderTechContRevModal()}
      {renderSMESelectorModal()}
    </>
  );
};

export default RCUpdateApprovalDetailsTable;
