import CommandsList from "components/batchUpdates/Commands/CommandsList";
import SWList, { SWListModes } from "components/batchUpdates/SWSearch/SWList";
import Banner, { BannerType } from "components/common/Banner";
import BlockSpinner from "components/common/BlockSpinner";
import FlowLayout from "components/layout/FlowLayout";
import { Routes } from "components/routing/Routing";
import { ApprovalResponseTypes } from "interfaces/approvals/approvalInterfaces";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { loadBatchApproval, resetCurrentBatchApproval, setSubmitResponseOperation, submitBatchResponse } from "store/approvals/approvalsActions";
import useSelector from "store/useSelector";
import ApprovalModal from "../ApprovalModal";
import RejectionModal from "../RejectionModal";
import "./ViewBatchApproval.scoped.scss";

interface IViewBatchApprovalProps {
  id: string,
}

const ViewBatchApproval: React.FC<RouteComponentProps<IViewBatchApprovalProps>> = (props) => {
  const urlId = props.match.params.id;
  const {
    approvals: {
      currentBatchApproval,
      loadApprovalOperation,
      submitResponseOperation,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const [isRejectionOpen, setIsRejectionOpen] = useState<boolean>(false);
  const [isApprovalOpen, setIsApprovalOpen] = useState<boolean>(false);
  const submitResponseSuccessful = submitResponseOperation?.wasSuccessful;

  useEffect(() => {
    const id = parseInt(urlId, 10);

    if (urlId
      && !isNaN(id)
      && id.toString() === urlId) {
      dispatch(loadBatchApproval({
        approvalId: id,
      }));
    }

    return () => {
      dispatch(resetCurrentBatchApproval());
    };
  }, [dispatch, urlId]);

  useEffect(() => {
    dispatch(setSubmitResponseOperation(undefined));
  }, [submitResponseSuccessful, dispatch]);

  if (loadApprovalOperation?.isWorking) {
    return <BlockSpinner />;
  } else if (loadApprovalOperation?.errorMessage) {
    return (
      <Banner
        type={BannerType.Error}
      >
        {loadApprovalOperation.errorMessage}
      </Banner>
    )
  } else if (!currentBatchApproval) {
    return (
      <Banner
        type={BannerType.Error}
      >
        No approval is currently loaded. Please refresh the page.
      </Banner>
    );
  }

  const onSubmitRejection = (approvalId: number,
    comment: string,
    attachment: File | null) => {
    dispatch(submitBatchResponse({
      approvalRequestId: approvalId,
      comment: comment,
      responseType: ApprovalResponseTypes.Rejected,
      attachment,
      tecConRev: null,
      secondTecConRev: null,
      smeRevUser: null,
      swType: SWTypes.Unspecified,
      techContetApproval: null,
      techCommApproval: null,
      smeApproval: null,
    }));
  }

  const onSubmitApproval = (approvalId: number,
    comment: string) => {
    dispatch(submitBatchResponse({
      approvalRequestId: approvalId,
      comment: comment,
      responseType: ApprovalResponseTypes.Approved,
      attachment: null,
      tecConRev: null,
      secondTecConRev: null,
      smeRevUser: null,
      techContetApproval: null,
      techCommApproval: null,
      smeApproval: null,
    }));
  }

  return (
    <FlowLayout
      header={(
        <div
          className="toolbar"
        >
          <h2>Batch Update Approval</h2>
          <div
            className="buttons"
          >
            <button
              className="primary-button"
              onClick={() => setIsApprovalOpen(true)}
            >
              Approve
            </button>
            <button
              className="secondary-button"
              onClick={() => setIsRejectionOpen(true)}
            >
              Reject
            </button>
          </div>
        </div>
      )}
    >
      <div
        className="section"
      >
        <label>Batch Updates</label>
        <CommandsList
          commands={currentBatchApproval.commands}
          isReadOnly
        />
      </div>
      <div
        className="section"
      >
        <SWList
          mode={SWListModes.ReadOnly}
          swList={currentBatchApproval.standardWorks.map(x => ({
            sw: x,
          }))}
          approvalId={parseInt(props.match.params.id, 10)}
        />
      </div>
      {isRejectionOpen &&
        <RejectionModal
          onClose={() => setIsRejectionOpen(false)}
          onSubmit={onSubmitRejection}
          allowAttachment={false}
          approvalId={currentBatchApproval.id}
        />
      }
      {isApprovalOpen &&
        <ApprovalModal
          onClose={() => setIsApprovalOpen(false)}
          approvalId={currentBatchApproval.id}
          onSubmit={onSubmitApproval}
          techContetApproval={null}
          techCommApproval={null}
          smeApproval={null}
          approvalHistories={undefined}
          isRevertModal = {false}
        />
      }
      {submitResponseSuccessful &&
        <Redirect
          to={Routes.MyApprovals}
        />
      }
    </FlowLayout>
  );
};

export default ViewBatchApproval;