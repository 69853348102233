import React, { Component, useEffect, useState } from "react";
import "./PPEComponentEditor.scoped.scss";
import M001 from "media/ppe/M001.png";
import M002 from "media/ppe/M002.png";
import M003 from "media/ppe/M003.png";
import M004 from "media/ppe/M004.png";
import M007 from "media/ppe/M007.png";
import M008 from "media/ppe/M008.png";
import M009 from "media/ppe/M009.png";
import M010 from "media/ppe/M010.png";
import M011 from "media/ppe/M011.png";
import M013 from "media/ppe/M013.png";
import M014 from "media/ppe/M014.png";
import M015 from "media/ppe/M015.png";
import M016 from "media/ppe/M016.png";
import M017 from "media/ppe/M017.png";
import M018 from "media/ppe/M018.png";
import M019 from "media/ppe/M019.png";
import M020 from "media/ppe/M020.png";
import M022 from "media/ppe/M022.png";
import M026 from "media/ppe/M026.png";
import M030 from "media/ppe/M030.png";
import M032 from "media/ppe/M032.png";
import M047 from "media/ppe/M047.png";
import M048 from "media/ppe/M048.png";
import WSM001 from "media/ppe/WSM001.png";
import { IPPEComponent, SWTypes, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import {uncheckAllOtherApplyToAll, updatePPEComponent } from "store/manageSW/manageSWActions";
import ppeToolTipDictionary from "components/common/PPEToolTipDictionary";
import useSelector from "store/useSelector";

interface IPPEEditorProps {
  ppeComponent: IPPEComponent,
  allowEdit: boolean,
  stepGuid: string,
}

const PPEComponentEditor: React.FC<IPPEEditorProps> = ({ ppeComponent, allowEdit, stepGuid }) => {
const { steps } = useSelector(store => store.manageSW.SW);
const {type} = useSelector(store => store.manageSW.SW);
const dispatch = useDispatch();

const [applyToAllCheckbox, setApplyToAllCheckbox] = useState<boolean>(ppeComponent.applyPPEToAllSelected || false);
useEffect(() => {
  const currentStep = steps.find(step => step.guid === stepGuid);
  if (currentStep) {
    const currentComponent = currentStep.components.find(component => component.type === StepComponentTypes.PPE) as IPPEComponent;
    if (currentComponent) {
      setApplyToAllCheckbox(currentComponent.applyPPEToAllSelected || false);
    }
  }
}, [steps, stepGuid]);

useEffect(() => {
  if (applyToAllCheckbox) {
    dispatch(uncheckAllOtherApplyToAll({ stepGuid }));
  }
  updateComponent();
}, [applyToAllCheckbox]);

const handleApplyToAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  event.stopPropagation();
  const checked = event.target.checked;
  setApplyToAllCheckbox(checked);
};
function updateComponent(){ 
  let ppeList = [...ppeComponent.ppe];
 var copyComponent = {
  ...ppeComponent,
  applyPPEToAllSelected : applyToAllCheckbox
 } as IPPEComponent;
 
  dispatch(updatePPEComponent({
    stepGuid,
    component : {
      ...copyComponent,
      ppe: ppeList,
    },
      applyAll:applyToAllCheckbox
  }));
}

  const onPPEClick = (ppeId: string, e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();

    let ppeList = [...ppeComponent.ppe];
    const index = ppeList.indexOf(ppeId);

    if (index > -1) {
      ppeList.splice(index, 1); 
    } else {
      ppeList.push(ppeId); 
    }
   var copyComponent = {
    ...ppeComponent,
    applyPPEToAllSelected : applyToAllCheckbox
   } as IPPEComponent;
   
    dispatch(updatePPEComponent({
      stepGuid,
      component : {
        ...copyComponent,
        ppe: ppeList,
      },
        applyAll:applyToAllCheckbox
    }));
  }

  return (
    <div className="ppeRow">
      {Object
        .keys(ppeImageDictionary)
        .map((ppeId) => {
          const isSelected = ppeComponent.ppe.indexOf(ppeId) > -1;

          if (!allowEdit
            && ppeComponent.ppe.indexOf(ppeId) === -1) {
            return null;
          }

          return (
            <img
              key={ppeId}
              src={ppeImageDictionary[ppeId]}
              title={ppeToolTipDictionary[ppeId]}
              onClick={(e) => allowEdit
                ? onPPEClick(ppeId, e)
                : undefined}
              className={`${isSelected
                ? "selected"
                : ""}
                ${!allowEdit
                  ? "disabled"
                  : ""}`
              }
              alt={ppeId}
            />
          );
        }
        )}
        {type === SWTypes.MFGRC && allowEdit &&
        <div className="ppeRow">
          <div>
            Use this PPE in all RC steps
            <input
            checked={applyToAllCheckbox}
            onChange={handleApplyToAllChange}
            type="checkbox"/>
          </div>
        </div>
        }
    </div>
  )
};

const ppeImageDictionary: Indexer = {
  "M001": M001,
  "M002": M002,
  "M003": M003,
  "M004": M004,
  "M007": M007,
  "M008": M008,
  "M009": M009,
  "M010": M010,
  "M011": M011,
  "M013": M013,
  "M014": M014,
  "M015": M015,
  "M016": M016,
  "M017": M017,
  "M018": M018,
  "M019": M019,
  "M020": M020,
  "M022": M022,
  "M026": M026,
  "M030": M030,
  "M032": M032,
  "M047": M047,
  "M048": M048,
  "WSM001": WSM001,
};

interface Indexer {
  [key: string]: string,
}

export default PPEComponentEditor;