import React, { useEffect, useState } from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadApproval, setSubmitResponseOperation, resetCurrentApproval, loadMoreSteps } from "store/approvals/approvalsActions";
import useSelector from "store/useSelector";
import BlockSpinner from "components/common/BlockSpinner";
import Banner, { BannerType } from "components/common/Banner";
import "./ViewApproval.scoped.scss";
import Toolbar from "./Toolbar";
import Metadata from "./sections/metadata/Metadata";
import PPEEditor from "components/sw/manage/steps/ppe/PPEEditor";
import Notices from "./sections/notices/Notices";
import RefDocs from "./sections/refDocs/RefDocs";
import StepEditor from "components/sw/manage/steps/StepEditor";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import { Routes } from "components/routing/Routing";
import Approvals from "./sections/approvalHistory/Approvals";
import FlowLayout from "components/layout/FlowLayout";
import StepCommentList from "components/sw/manage/steps/StepCommentList";
import { RCTypes, SWTypes } from "interfaces/sw/SWInterfaces";

interface IViewApprovalProps {
  id: string,
}

enum ApprovalSections {
  Approval = "APPROVAL HISTORY",
  Metadata = "METADATA",
  PPE = "PPE",
  Notices = "NOTICES",
  RefDocs = "REFERENCE DOCUMENTS",
  Steps = "STEPS",
  SubSteps = "SUBSTEPS",
}

interface IViewApprovalState {
  expandedSections: ApprovalSections[],
}

const ViewApproval: React.FC<RouteComponentProps<IViewApprovalProps>> = (props) => {
  const LIMIT = 50;
  const urlId = props.match.params.id;
  const {
    approvals: {
      currentApproval,
      loadApprovalOperation,
      submitResponseOperation,
    }
  } = useSelector(store => store);
  const sw = currentApproval?.sw;
  const approvals = currentApproval?.approvalHistories;
  const dispatch = useDispatch();
  const [state, setState] = useState<IViewApprovalState>({
    expandedSections: [
      ApprovalSections.Approval,
      ApprovalSections.Metadata,
      ApprovalSections.PPE,
      ApprovalSections.Notices,
      ApprovalSections.RefDocs,
      ApprovalSections.Steps,
      ApprovalSections.SubSteps,
    ],
  });
  const submitResponseSuccessful = submitResponseOperation?.wasSuccessful;

  useEffect(() => {
    const id = parseInt(urlId, 10);

    if (urlId
      && !isNaN(id)
      && id.toString() === urlId) {
      dispatch(loadApproval({
        approvalId: id,
        stepIndex: 0,
      }));
    }

    return () => {
      dispatch(resetCurrentApproval());
    };
  }, [dispatch, urlId]);

  useEffect(() => {
    dispatch(setSubmitResponseOperation(undefined));
  }, [submitResponseSuccessful, dispatch]);

  if (loadApprovalOperation?.isWorking) {
    return <BlockSpinner />;
  } else if (loadApprovalOperation?.errorMessage) {
    return (
      <Banner
        type={BannerType.Error}
      >
        {loadApprovalOperation.errorMessage}
      </Banner>
    )
  } else if (!currentApproval) {
    return (
      <Banner
        type={BannerType.Error}
      >
        No approval is currently loaded. Please refresh the page.
      </Banner>
    );
  } else if (!sw) {
    return (
      <Banner
        type={BannerType.Error}
      >
        The approval is missing a standard work. Please refresh the page.
      </Banner>
    );
  }

  const isSectionExpanded = (section: ApprovalSections) => {
    return state
      .expandedSections
      .indexOf(section) > -1;
  }

  const toggleSection = (section: ApprovalSections) => {
    if (isSectionExpanded(section)) {
      setState({
        expandedSections: state
          .expandedSections
          .filter(x => x !== section),
      });
    } else {
      setState({
        expandedSections: state
          .expandedSections
          .concat([section]),
      });
    }
  }

  const loadMore = () => {
    const newIndex = currentApproval.skipStepIndex !== undefined
      ? (currentApproval.skipStepIndex + LIMIT)
      : 0;
    const newShowMore = newIndex < (currentApproval.totalSteps - 1);
    if (newShowMore) {
      dispatch(loadMoreSteps(
        {
          approvalId: currentApproval.approval.id,
          stepIndex: newIndex,
        }));
    }
  }

  function findStepTypeDisplay(): String {
    if (sw?.type === SWTypes.TLMSWI
      || sw?.type === SWTypes.MFGSWI
      || (sw?.type === SWTypes.TLMRC && sw.steps.find(x => x.isTask))) {
      return "TASKS";
    }

    if (sw?.rcType === RCTypes.SubStep) {
      return "SUBSTEPS";
    }
    else {
      return "STEPS";
    }
  }

  function ApprovalSectionStepType(): ApprovalSections {
    if (sw?.rcType === RCTypes.SubStep)
      return ApprovalSections.SubSteps;
    else {
      return ApprovalSections.Steps;
    }
  }

  return (
    <>
      <FlowLayout
        header={(
          <Toolbar
            sw={sw}
            currentApproval={currentApproval}
          />
        )}
      >
        {approvals && approvals?.length > 0 &&
          <div
            className={`section ${isSectionExpanded(ApprovalSections.Approval)
              ? "expanded"
              : "collapsed"}`
            }
          >
            <h2
              className={isSectionExpanded(ApprovalSections.Approval)
                ? "expanded"
                : "collapsed"
              }
              onClick={() => toggleSection(ApprovalSections.Approval)}
            >
              APPROVAL HISTORY
            </h2>
            <div className="body">
              <Approvals approvalHistories={approvals} />
            </div>
          </div>
        }
        <div
          className={`section ${isSectionExpanded(ApprovalSections.Metadata)
            ? "expanded"
            : "collapsed"}`
          }
        >
          <h2
            className={isSectionExpanded(ApprovalSections.Metadata)
              ? "expanded"
              : "collapsed"
            }
            onClick={() => toggleSection(ApprovalSections.Metadata)}
          >
            METADATA
          </h2>
          <div className="body">
            <Metadata
              sw={sw}
              isRCUpdate={currentApproval.approval.isRCUpdate}
            />
          </div>
        </div>
        {sw.ppe.length > 0 &&
          <div
            className={`section ${isSectionExpanded(ApprovalSections.PPE)
              ? "expanded"
              : "collapsed"}`
            }
          >
            <h2
              className={isSectionExpanded(ApprovalSections.PPE)
                ? "expanded"
                : "collapsed"
              }
              onClick={() => toggleSection(ApprovalSections.PPE)}
            >
              PPE
            </h2>
            <div className="body">
              <PPEEditor
                ppe={sw.ppe}
                isDisabled={true}
              />
            </div>
          </div>
        }
        {sw.notices.length > 0 &&
          <div
            className={`section ${isSectionExpanded(ApprovalSections.Notices)
              ? "expanded"
              : "collapsed"}`
            }
          >
            <h2
              className={isSectionExpanded(ApprovalSections.Notices)
                ? "expanded"
                : "collapsed"
              }
              onClick={() => toggleSection(ApprovalSections.Notices)}
            >
              NOTICES
            </h2>
            <div className="body">
              {sw.notices.length > 0 &&
                <Notices
                  notices={sw.notices}
                  swGuid={sw.guid}
                  swVersion={sw.version}
                  swType={sw.type}
                  isDraftPage={false}
                  sw={sw}
                />
              }
            </div>
          </div>
        }
        {sw.referenceDocs.length > 0 &&
          <div
            className={`section ${isSectionExpanded(ApprovalSections.RefDocs)
              ? "expanded"
              : "collapsed"}`
            }
          >
            <h2
              className={isSectionExpanded(ApprovalSections.RefDocs)
                ? "expanded"
                : "collapsed"
              }
              onClick={() => toggleSection(ApprovalSections.RefDocs)}
            >
              REFERENCE DOCUMENTS
            </h2>
            <div className="body">
              <RefDocs
                refDocs={sw.referenceDocs}
                swGuid={sw.guid}
                version={sw.version}
              />
            </div>
          </div>
        }
        {sw.steps.length > 0 &&
          <div
            className={`section ${isSectionExpanded(ApprovalSectionStepType())
              ? "expanded"
              : "collapsed"}`
            }
          >
            <h2
              className={isSectionExpanded(ApprovalSectionStepType())
                ? "expanded"
                : "collapsed"
              }
              onClick={() => toggleSection(ApprovalSectionStepType())}
            >
              {findStepTypeDisplay()}
            </h2>
            <div className="body">
              {sw.steps.map(step =>
                <StepEditor
                  step={step}
                  isDisabled={true}
                  isSWEditable={false}
                  isInTask={false}
                  swGuid={sw.guid}
                  swVersion={sw.version}
                  parentOrders={[]}
                  key={step.guid}
                  imageDataDestination={ImageDataDestinations.Approval}
                  swType={sw.type}
                  steps={sw.steps}
                  scrollToStepGuid={undefined}
                  isDraftPage={false}
                  rcParentOrder={[]}
                  plantCode={sw.owningPlant?.code}
                  materialCode={sw.materials[0]?.code}
                  group={sw.group?.code}
                  groupCounter={
                    isNaN(parseInt(sw.groupCounter?.code || "NaN"))
                      ? undefined
                      : parseInt(sw.groupCounter?.code || "NaN")
                  }
                  sw={sw}
                />
              )}
            </div>
            <StepCommentList
              isDraftPage={false}
            />
          </div>
        }
        {submitResponseSuccessful &&
          <Redirect
            to={Routes.MyApprovals}
          />
        }
      </FlowLayout>
      {sw.steps.length > 0 && (currentApproval.currenTotalSteps < currentApproval.totalSteps) &&
        <button className="primary-button" onClick={loadMore}>
          Load More {sw.type === SWTypes.TLMSWI ? "Tasks" : "Steps"}
        </button>
      }
    </>
  );
}

export default ViewApproval;