import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import Modal from "components/common/Modal";
import "./RejectionModal.scoped.scss";

interface IRejectionModalProps {
  approvalId: number,
  onSubmit(approvalId: number,
    comment: string,
    attachment: File | null): void,
  onClose(): void,
  allowAttachment: boolean,
}

const RejectionModal: React.FC<IRejectionModalProps> = ({
  approvalId,
  onClose,
  onSubmit,
  allowAttachment,
}) => {
  const [comment, setComment] = useState<string>("");
  const dispatch = useDispatch();
  const [attachment, setAttachment] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const inputFormRef = useRef<HTMLFormElement>(null);

  const submitRejection = () => {
    if (!comment.trim()) {
      dispatch(showErrorToast("Rejection comment is required."));
      return;
    }

    if (attachment
      && !validateFileSize(attachment)) {
      return;
    }

    onSubmit(approvalId,
      comment,
      attachment);

    onClose();
  }

  const onFilesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) {
      return;
    }

    if (!validateFileSize(files[0])) {
      return;
    }

    setAttachment(files[0]);
  }

  const validateFileSize = (file: File) => {
    if (file.size > 5242880) {
      dispatch(showErrorToast("Attachments must be <= 5 MB."));
      return false;
    }

    return true;
  }

  const resetForm = () => {
    setAttachment(null);
    inputFormRef.current?.reset();
  }

  return (
    <Modal
      isOpen={true}
      header="Rejection Comment"
      controls={(
        <>
          <button
            className="primary-button"
            onClick={submitRejection}
          >
            Reject
          </button>
          <button
            className="secondary-button"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </>
      )}
    >
      <textarea
        maxLength={500}
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        placeholder="Enter rejection comment..."
        className="rejection-comment"
      />
      <span className="charLimit">(500 character limit)</span>
      {allowAttachment &&
        <div
          className="attachment-row"
        >
          <label>
            Attachment:
          </label>
          <span>
            {attachment &&
              <>
                {attachment.name}
                <button
                  className="tertiary-button"
                  onClick={resetForm}
                >
                  x Remove
              </button>
              </>
            }
            {!attachment &&
              "none"
            }
          </span>
          <button
            className="secondary-button"
            onClick={() => fileInputRef.current?.click()}
          >
            Choose File
          </button>
          <form
            ref={inputFormRef}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={onFilesSelected}
            />
          </form>
        </div>
      }
    </Modal>
  );
}

export default RejectionModal;