import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IManageSWState } from "./manageSWTypes";
import {
  addNewComponent,
  updateNotice,
  removeComponent,
  updateTextInput,
  updateNumberInput,
  updateDateInput,
  updateDateTimeInput,
  updatePhotoInput,
  updateSignatureInput,
  updateYesNoInput,
  updateImage,
  updateSelectInput,
  updateMultiSelectInput,
  updateImageBlobData,
  updateMSSVideo,
  updateLink,
  updateTable,
  updateTableCell,
  updatePPEComponent,
  updateVideo,
  updateRichTextInput,
  updateQualityControlPoint,
  updateVideoInput,
  setTableLabel,
  updateAudioInput,
  setTableImageFilename,
  updateTableCellBlobData,
  updateTIMEImage,
  setTableTimeImageFilename,
  addRcNoticeComponent,
  addRoutingOperationComponent,
  updateFormulaInput,
  updatePassFailInput,
  uncheckAllOtherApplyToAll, 
  copyComponent, 
  pasteComponent
} from "./manageSWActions";
import { findStep } from "./manageSWHelpers";
import {
  StepComponentTypes,
  NoticeTypes,
  IDateInputComponent,
  ITextInputComponent,
  IDateTimeInputComponent,
  IPhotoInputComponent,
  ISignatureInputComponent,
  IYesNoInputComponent,
  INumberInputComponent,
  IImageComponent,
  ISelectInputComponent,
  IMultiSelectInputComponent,
  ImageAlignments,
  IMicrosoftStreamVideoComponent,
  ILinkComponent,
  ITableComponent,
  IPPEComponent,
  IVideoComponent,
  IRichTextInputComponent,
  IQualityControlPointComponent,
  StopType,
  IVideoInputComponent,
  IAudioComponent,
  IAudioInputComponent,
  ITIMEImageComponent,
  ImageType,
  RCTypes,
  SWTypes,
  IRoutingOperationComponent,
  IFormulaInputComponent,
  IPassFailInputComponent,
} from "interfaces/sw/SWInterfaces";
import { v4 as uuidv4 } from "uuid";
import useSelector from "store/useSelector";
import { act } from "react-dom/test-utils";
import { stat } from "fs";
import InputComponentTypeModal from "components/sw/manage/steps/components/InputComponentTypeModal";
import PPEComponent from "components/sw/executionPreview/components/PPEComponent";

export default function addStepComponentReducerCases(builder: ActionReducerMapBuilder<IManageSWState>) {
  // Add New Component
  builder.addCase(addNewComponent, (state, action) => {
    if (!action.payload.stepGuid) {
      // Notices can be added to either the SW itself or a step.
      if (action.payload.type === StepComponentTypes.Notice) {
        state.SW.notices.push({
          guid: uuidv4(),
          type: StepComponentTypes.Notice,
          noticeType: NoticeTypes.Info,
          sortOrder: state.SW.notices.length
            ? Math.max.apply(Math, state.SW.notices.map(x => x.sortOrder)) + 1
            : 1,
          image: {
            filename: "",
            blobData: undefined,
            imageType: state.SW.type === SWTypes.MFGSWI ? ImageType.Normal : ImageType.Both,
          },
          isRCComponent: false,
          rcID: undefined,
          isDisabled: false,
        });
        if (state.SW.type === SWTypes.TLMRC ||
          state.SW.type === SWTypes.MFGRC) {
          state.SW.rcType = RCTypes.Notice;
        }
        state.isDirty = Math.random();
      }
      return;
    }

    let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);
    if (!step) {
      return;
    }

    if (state.enableFormulaComponentValue) {
      const hasFormulaComponent = step.components.some(
        (x) => x.type === StepComponentTypes.FormulaNumerical
      );

      if (
        hasFormulaComponent &&
        action.payload.type.toString().includes("Input")
      ) {
        return;
      }

    }

    const compGuid = uuidv4();
    const sortOrder = step.components.length
      ? Math.max.apply(Math, step.components.map(x => x.sortOrder)) + 1
      : 1;

    switch (action.payload.type) {
      case StepComponentTypes.Notice: {
        step.components.push({
          guid: compGuid,
          type: StepComponentTypes.Notice,
          noticeType: NoticeTypes.Info,
          sortOrder,
          image: {
            filename: "",
            blobData: undefined,
            imageType: state.SW.type === SWTypes.MFGSWI ? ImageType.Normal : ImageType.Both,
          },
          isRCComponent: false,
          rcID: undefined,
          isDisabled: false,
        });
        break;
      }
      case StepComponentTypes.Image: {
        step.components.push({
          guid: compGuid,
          type: StepComponentTypes.Image,
          filename: "",
          alignment: ImageAlignments.Bottom,
          sortOrder,
        });
        break;
      }
      case StepComponentTypes.TimeImage: {
        step.components.push({
          guid: compGuid,
          type: StepComponentTypes.TimeImage,
          filename: "",
          alignment: ImageAlignments.Bottom,
          sortOrder,
        });
        break;
      }
      case StepComponentTypes.MicrosoftStreamVideo: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          sortOrder,
          videoGuid: "",
        })
        break;
      }
      case StepComponentTypes.SelectInput:
      case StepComponentTypes.MultiSelectInput: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          options: [],
          sortOrder,
        });
        break;
      }
      case StepComponentTypes.PhotoInput:
      case StepComponentTypes.VideoInput:
      case StepComponentTypes.AudioInput:
      case StepComponentTypes.SignatureInput:
      case StepComponentTypes.YesNoInput:
      case StepComponentTypes.PassFailInput:
      case StepComponentTypes.DateInput:
      case StepComponentTypes.DateTimeInput:
      case StepComponentTypes.TextInput:
      case StepComponentTypes.RichTextParagraph: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          sortOrder,
          html: "",
        });
        break;
      }
      case StepComponentTypes.NumberInput: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          sortOrder,
          lowerLimit: undefined,
          expectedValue: undefined,
          upperLimit: undefined,
          nonConform: undefined,
        });
        break;
      }
      case StepComponentTypes.Link: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          sortOrder,
          url: "",
        });
        break;
      }
      case StepComponentTypes.Table: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          sortOrder,
          rowCount: 2,
          colCount: 2,
          cells: [],
        });
        break;
      }
      case StepComponentTypes.PPE: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          sortOrder,
          ppe: [],
        });
        break;
      }
      case StepComponentTypes.Video: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          sortOrder,
          filename: "",
          blobData: undefined,
        })
        break;
      }
      case StepComponentTypes.Audio: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          sortOrder,
          filename: "",
          blobData: undefined,
        })
        break;
      }
      case StepComponentTypes.QualityControlPoint: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          sortOrder,
          stopType: StopType.HardStop,
        })
        break;
      }
      case StepComponentTypes.FormulaNumerical: {
        step.components.push({
          guid: compGuid,
          type: action.payload.type,
          sortOrder,
          lowerLimit: undefined,
          upperLimit: undefined,
          nonConform: undefined,
          formula: undefined,
          uom: "",
          uomCode: "",
          meterId: ""
        })
        break;
      }
    }

    state.isDirty = Math.random();
  });

  // Remove Component
  builder.addCase(removeComponent, (state, action) => {
    if (!action.payload.stepGuid) {
      state.SW.notices = state.SW.notices.filter(x => x.guid !== action.payload.componentGuid);
      if (state.SW.notices.length === 0 && state.SW.type === SWTypes.TLMRC) {
        state.SW.rcType = undefined;
      }
    } else {
      let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);

      if (!step) {
        return;
      }

      step.components = step.components.filter(x => x.guid !== action.payload.componentGuid);
    }

    state.isDirty = Math.random();
  });

  builder.addCase(addRoutingOperationComponent, (state, action) => {
    const [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);

    if (!step) {
      return;
    }

    const compGuid = uuidv4();

    const sortOrder =
      step.components.length ??
      Math.max.apply(
        Math,
        step.components.map((x) => x.sortOrder)
      ) + 1;

    const currentComponentIndex = step.components.findIndex(
      (x) => x.type === StepComponentTypes.RoutingOperation
    );

    const routingOperationComponent: IRoutingOperationComponent = {
      guid: compGuid,
      type: StepComponentTypes.RoutingOperation,
      operation: action.payload.operation,
      sortOrder,
    };

    if (currentComponentIndex === -1) {
      step.components.push(routingOperationComponent);
    } else {
      step.components[currentComponentIndex] = routingOperationComponent;
    }
  });

  builder.addCase(addRcNoticeComponent, (state, action) => {
    if (!action.payload.stepGuid) {
      // Notices can be added to either the SW itself or a step.
      if (action.payload.type === StepComponentTypes.Notice) {
        state.SW.notices.push({
          guid: uuidv4(),
          type: StepComponentTypes.Notice,
          noticeType: NoticeTypes.Info,
          sortOrder: state.SW.notices.length
            ? Math.max.apply(Math, state.SW.notices.map(x => x.sortOrder)) + 1
            : 1,
          image: {
            filename: "",
            blobData: undefined,
            imageType: ImageType.Both,
          },
          isRCComponent: false,
          rcID: undefined,
          isDisabled: false,
        });
        state.isDirty = Math.random();
      }
    }
    else {
      let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);
      if (!step) {
        return;
      }

      const compGuid = uuidv4();
      const sortOrder = step.components.length
        ? Math.max.apply(Math, step.components.map(x => x.sortOrder)) + 1
        : 1;

      step.components.push({
        guid: compGuid,
        type: StepComponentTypes.Notice,
        noticeType: NoticeTypes.Info,
        sortOrder,
        image: {
          filename: "",
          blobData: undefined,
          imageType: ImageType.Both,
        },
        isRCComponent: false,
        rcID: undefined,
        isDisabled: false,
      });

    }
    state.isDirty = Math.random();
  });

  // Update Notice
  builder.addCase(updateNotice, (state, action) => {
    if (!action.payload.stepGuid) {
      let ix = state.SW.notices.findIndex(x => x.guid === action.payload.component.guid);

      if (ix === -1) {
        state.SW.notices.push({ ...action.payload.component });
      } else {
        state.SW.notices[ix] = { ...action.payload.component };
      }
    } else {
      let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);

      if (!step) {
        return;
      }

      let existingComponent = step.components.find(x => x.guid === action.payload.component.guid);

      if (existingComponent) {
        Object.assign(existingComponent, {
          ...action.payload.component,
        });
      } else {
        step.components.push({
          ...action.payload.component,
        });
      }
    }

    state.isDirty = Math.random();
  });

  // Update Number Input
  builder.addCase(updateNumberInput, (state, action) => {
    // Validate the limits.
    const {
      lowerLimit,
      expectedValue,
      upperLimit,
    } = action.payload.component;

    if ((lowerLimit !== undefined
      && expectedValue !== undefined)
      && lowerLimit > expectedValue) {
      return;
    }

    if ((upperLimit !== undefined
      && expectedValue !== undefined)
      && upperLimit < expectedValue) {
      return;
    }

    if ((upperLimit !== undefined
      && lowerLimit !== undefined)
      && lowerLimit > upperLimit) {
      return;
    }

    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Formula Input
  builder.addCase(updateFormulaInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Text Input
  builder.addCase(updateTextInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Rich Text Input
  builder.addCase(updateRichTextInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Date Input
  builder.addCase(updateDateInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Date Time Input
  builder.addCase(updateDateTimeInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Photo Input
  builder.addCase(updatePhotoInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Video Input
  builder.addCase(updateVideoInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Audio Input
  builder.addCase(updateAudioInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });


  // Update Signature Input
  builder.addCase(updateSignatureInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Yes/No Input
  builder.addCase(updateYesNoInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  //Update Pass/Fail Input
  builder.addCase(updatePassFailInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Quality Control Input
  builder.addCase(updateQualityControlPoint, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Image
  builder.addCase(updateImage, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update TIME Image
  builder.addCase(updateTIMEImage, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Image Blob Data
  builder.addCase(updateImageBlobData, (state, action) => {
    let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);
    if (!step) {
      return;
    }

    let existingComponent = step.components.find(x => x.guid === action.payload.componentGuid);

    if (!existingComponent
      || (existingComponent.type !== StepComponentTypes.Image
        && existingComponent.type !== StepComponentTypes.TimeImage)) {
      return;
    }

    existingComponent.blobData = action.payload.blobData;
  });

  // Update Select Input
  builder.addCase(updateSelectInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update MultiSelect Input
  builder.addCase(updateMultiSelectInput, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Microsoft Stream Video
  builder.addCase(updateMSSVideo, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Link
  builder.addCase(updateLink, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update PPE Component
  builder.addCase(updatePPEComponent, (state, action) => {
      state.isDirty = Math.random();
      if (action.payload.applyAll === true) {
          updateAllStepPPEComponent(state, action.payload.stepGuid, action.payload.component, action.payload.applyAll);
      }
      else {
          updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
      }
  });

  // Update Table
  builder.addCase(updateTable, (state, action) => {
    state.isDirty = Math.random();
    // Remove cells that fall outside bounds of row and column count.
    action.payload.component.cells = action.payload.component.cells
      .filter(x => x.rowIndex + 1 <= action.payload.component.rowCount
        && x.colIndex + 1 <= action.payload.component.colCount);
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  // Update Table Label
  builder.addCase(setTableLabel, (state, action) => {
    state.isDirty = Math.random();

    // Find the step.
    let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);
    if (!step) {
      return;
    }

    // Find Component.
    let existingComponent = step.components.find(x => x.guid === action.payload.componenGuid);
    if (!existingComponent || existingComponent.type !== StepComponentTypes.Table) {
      return;
    }

    if (existingComponent) {
      existingComponent.label = action.payload.label;
    }
  });

  // Update Table Cell Image Blob Data
  builder.addCase(updateTableCellBlobData, (state, action) => {
    let fileName = action.payload.fileName;
    // Find the step.
    let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);
    if (!step) {
      return;
    }

    // Find Component.
    let existingComponent = step.components.find(x => x.guid === action.payload.componentGuid);
    if (!existingComponent || existingComponent.type !== StepComponentTypes.Table) {
      return;
    }

    // Find existing cell
    let existingCell = existingComponent.cells.find(x => x.colIndex === action.payload.colIndex && x.rowIndex === action.payload.rowIndex);

    if (!existingCell) {
      return;
    }

    existingCell.value = existingCell.value.replace("src=\"\"", "src=\"" + fileName + "\"");
    existingCell.imageFileName = fileName;
  });

  // Set table timeimage filename
  builder.addCase(setTableTimeImageFilename, (state, action) => {
    state.isDirty = Math.random();

    // Find the step.
    let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);
    if (!step) {
      return;
    }

    // Find Component.
    let existingComponent = step.components.find(x => x.guid === action.payload.componentGuid);
    if (!existingComponent || existingComponent.type !== StepComponentTypes.Table) {
      return;
    }

    // Find existing cell
    let existingCell = existingComponent.cells.find(x => x.colIndex === action.payload.colIndex && x.rowIndex === action.payload.rowIndex);
    if (!existingCell) {
      existingComponent.cells.push(
        {
          rowIndex: action.payload.rowIndex,
          colIndex: action.payload.colIndex,
          value: "",
          rowSpan: 1,
          colSpan: 1,
        });

      existingCell = existingComponent.cells.find(x => x.colIndex === action.payload.colIndex && x.rowIndex === action.payload.rowIndex);
    }

    if (!existingCell) {
      return;
    }

    existingCell.value = action.payload.value.replaceAll(action.payload.altTagValue, action.payload.serverFilename);
    existingCell.imageFileName = action.payload.serverFilename;
  });

  // Set table image filename
  builder.addCase(setTableImageFilename, (state, action) => {
    state.isDirty = Math.random();

    // Find the step.
    let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);
    if (!step) {
      return;
    }

    // Find Component.
    let existingComponent = step.components.find(x => x.guid === action.payload.componentGuid);
    if (!existingComponent || existingComponent.type !== StepComponentTypes.Table) {
      return;
    }

    // Find existing cell
    let existingCell = existingComponent.cells.find(x => x.colIndex === action.payload.colIndex && x.rowIndex === action.payload.rowIndex);
    if (!existingCell) {
      existingComponent.cells.push(
        {
          rowIndex: action.payload.rowIndex,
          colIndex: action.payload.colIndex,
          value: "",
          rowSpan: 1,
          colSpan: 1,
        });

      existingCell = existingComponent.cells.find(x => x.colIndex === action.payload.colIndex && x.rowIndex === action.payload.rowIndex);
    }

    if (!existingCell) {
      return;
    }

    existingCell.imageFileName = action.payload.serverFilename;
  });

  // Update Table Cell
  builder.addCase(updateTableCell, (state, action) => {
    state.isDirty = Math.random();

    // Find the step.
    let [step] = findStep(action.payload.stepGuid, state.SW.steps, 0);
    if (!step) {
      return;
    }

    // Find Component.
    let existingComponent = step.components.find(x => x.guid === action.payload.componentGuid);
    if (!existingComponent || existingComponent.type !== StepComponentTypes.Table) {
      return;
    }

    let newCell = action.payload.cell;

    // Find existing cell
    let existingCell = existingComponent.cells.find(x => x.colIndex === newCell.colIndex && x.rowIndex === newCell.rowIndex);
    if (!existingCell) {
      // No cell found, just add it if its not blank.
      if ((newCell.value !== undefined && newCell.value !== '' && newCell.value !== '<p></p>\n')
        || (newCell.colSpan > 1 || newCell.rowSpan > 1)) {
        existingComponent.cells.push(newCell);
      }
    } else {
      // Delete existing cell.
      let existingCellIx = existingComponent.cells.indexOf(existingCell);
      existingComponent.cells.splice(existingCellIx, 1);

      // If cell value is not blank, add it.
      if ((newCell.value !== undefined && newCell.value !== '' && newCell.value !== '<p></p>\n')
        || (newCell.colSpan > 1 || newCell.rowSpan > 1)) {
        existingComponent.cells.push(newCell);
      }
    }
  });

  // Update Video
  builder.addCase(updateVideo, (state, action) => {
    state.isDirty = Math.random();
    updateStepOnlyComponent(state, action.payload.stepGuid, action.payload.component);
  });

  builder.addCase(uncheckAllOtherApplyToAll, (state, action) => {
    const stepGuid = action.payload.stepGuid;
    state.SW.steps.forEach((step) => {
        step.components.forEach((component) => {
            if (
                component.type === StepComponentTypes.PPE &&
                step.guid !== stepGuid
            ) {
                component.applyPPEToAllSelected = false;
            }
        });
    });
  });

  builder.addCase(copyComponent, (state, action) => {
    state.copiedComponents = [action.payload];
  });

  builder.addCase(pasteComponent, (state, action) => {
    let [step] = findStep(action.payload.stepGuid, state.SW.steps)
    if (!step) {
      return;
    }

    if(!action.payload.components ||
      action.payload.components.length == 0){
      return;
    }

    const sortOrder = step.components.length
      ? Math.max.apply(Math, step.components.map(x => x.sortOrder)) + 1
      : 1;

    let components = action.payload.components;
    components = components.map(x => {
      let writableComponent = Object.assign({}, x);
      writableComponent.id = undefined;
      writableComponent.immutableGuid = undefined;
      writableComponent.guid = uuidv4();
      writableComponent.sortOrder = sortOrder
      return writableComponent;
    });

    if(action.payload.stepGuid != undefined &&
      action.payload.componentGuid == undefined) {
      step.components.push(...components);
      state.isDirty = Math.random();
      return;
    }
  });
}

type StepOnlyComponentType =
  ITextInputComponent |
  IRichTextInputComponent |
  IDateInputComponent |
  IDateTimeInputComponent |
  IPhotoInputComponent |
  ISignatureInputComponent |
  IYesNoInputComponent |
  INumberInputComponent |
  IImageComponent |
  ITIMEImageComponent |
  ISelectInputComponent |
  IMultiSelectInputComponent |
  IMicrosoftStreamVideoComponent |
  ILinkComponent |
  ITableComponent |
  IPPEComponent |
  IVideoComponent |
  IAudioComponent |
  IQualityControlPointComponent |
  IVideoInputComponent |
  IAudioInputComponent |
  IFormulaInputComponent |
  IPassFailInputComponent;

function updateAllStepPPEComponent(state: IManageSWState, stepGuid: string, component: IPPEComponent, applyAll: boolean): void {
    let stepList = state.SW.steps;
    if (stepList !== undefined) {
        for (let i = 0; i < stepList.length; i++) {
            let step = stepList[i];
            let componentItemFromStore = step.components.find(x => x.type == StepComponentTypes.PPE) as IPPEComponent;
            let replacedComponent: IPPEComponent;

            if (componentItemFromStore) {
                replacedComponent = { ...componentItemFromStore, ...component };

                if (step.guid !== stepGuid) {
                    replacedComponent.applyPPEToAllSelected = false;
                } else {
                    replacedComponent.applyPPEToAllSelected = applyAll;
                }

                Object.assign(componentItemFromStore, replacedComponent);
            } else {
                replacedComponent = { ...component, applyPPEToAllSelected: step.guid === stepGuid ? applyAll : false };
                step.components.push(replacedComponent);
            }
        }
    }
}
function updateStepOnlyComponent(state: IManageSWState,
  stepGuid: string,
  component: StepOnlyComponentType): void {
  let [step] = findStep(stepGuid, state.SW.steps, 0);
  if (!step) {
    return;
  }

  let existingComponent = step.components.find(x => x.guid === component.guid);

  if (existingComponent) {
    Object.assign(existingComponent, {
      ...component,
    });
  } else {
    step.components.push({
      ...component,
    });
  }
}