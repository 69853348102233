import React, { useEffect, useRef, useState } from "react";
import FileDropzone from "components/common/FileDropzone";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import {
  getFileSizeError,
  isFilenameImage,
  isFilenamePreviewable,
} from "utilities/fileUtilities";
import "./FeedbackDocuments.scoped.scss";
import {
  IAttachDocument,
  IFeedbackDocuments,
} from "store/feedback/feedbackTypes";
import { attachDocument } from "store/feedback/feedbackActions";

interface IFeedbackDocumentsProps {
  document: IFeedbackDocuments;
  index: number;
}

const FeedbackDocuments: React.FC<IFeedbackDocumentsProps> = ({
  document,
  index,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const [fileChosen, setFileChose] = useState<IAttachDocument>(
    {} as IAttachDocument
  );

  useEffect(() => {
    {
      if (!fileChosen.file) {
        return;
      }
      handleFile(fileChosen.file);
    }
  }, [fileChosen]);

  const getShowNameDocument = (name: string) => {
    if (name.length > 20) return `${name.substring(0, 20)}..`;
    return name;
  };

  const renderFileUpload = () => {
    return (
      <>
        <input
          type="file"
          accept="image/*, application/pdf"
          ref={fileInputRef}
          onChange={(e) => {
            if (!e.target.files) {
              return;
            }
            setFileChose({
              file: e.target.files[0],
              index,
            });
          }}
        />
        <div
          className={
            "controls " +
            (document.hasAttach
              ? "border-uploaded-documented"
              : "border-upload-component")
          }
        >
          <FileDropzone onFilesDropped={([file]) => handleFile(file)}>
            <button
              className="secondary-button choose-image"
              onClick={() => fileInputRef.current?.click()}
            >
              <p>
                {document.hasAttach
                  ? `${index}--${getShowNameDocument(
                      document.name
                    )} drop Image here or click to replace`
                  : `Drop Image here or click to upload`}
              </p>
            </button>
          </FileDropzone>
          <div className="align-holder"></div>
        </div>
      </>
    );
  };

  const handleFile = (fileFeedback: File) => {
    const fileSizeError = getFileSizeError(fileFeedback);
    if (fileSizeError) {
      dispatch(showErrorToast(fileSizeError));
      return;
    }

    const isImage = isFilenameImage(fileFeedback.name);
    const isPdf = isFilenamePreviewable(fileFeedback.name);
    if (!isImage && !isPdf) {
      dispatch(showErrorToast("The field should be pdf or image"));
      return;
    }

    try {
      dispatch(attachDocument({ file: fileFeedback, index }));
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
    }
  };

  return renderFileUpload();
};

export default FeedbackDocuments;
