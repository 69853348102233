import { setCurrentUser, setEnableReportsTabFlag, setLoadOperation, setTCCInfo, setTCCInfoOperation } from "./authActions";
import { createReducer } from "@reduxjs/toolkit";
import { IAuthState } from "./authTypes";

const initialState: IAuthState = {
  currentUser: {
    email: "",
    name: "Unknown User",
    roles: [],
    orgs: [],
    plants: [],
    confidentialSWAllocations: [],
  },
  enableReportsTab: false,
};

const authReducer = createReducer(initialState, builder => {
  // Set Load Operation
  builder.addCase(setLoadOperation, (state, action) => {
    state.loadOperation = action.payload;
    return state;
  });

  // Set Current User
  builder.addCase(setCurrentUser, (state, action) => {
    state.currentUser = action.payload;
    return state;
  });

  // Set TCC Info
  builder.addCase(setTCCInfo, (state, action) => {
    state.currentUser.tccInfo = action.payload;
  });

  // Set TCC Info Operation
  builder.addCase(setTCCInfoOperation, (state, action) => {
    if (state.currentUser.tccInfo) {
      state.currentUser.tccInfo.loadOperation = action.payload
    }
  });

  builder.addCase(setEnableReportsTabFlag, (state, action) => {
    state.enableReportsTab = action.payload;
  });
});

export default authReducer;