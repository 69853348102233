import { acquireAccessToken } from "msalConfig";

export const authFetch = async (url: string, method: string, accept: string, contentType: string, body?: any): Promise<Response> => {
  const token = await acquireAccessToken();

  return fetch(url, {
    method: method,
    headers: {
      'Accept': accept,
      'Content-Type': contentType,
      'Authorization': `Bearer ${token}`,
    },
    body,
  });
}

export const authPostFormData = async (url: string, formData: FormData): Promise<Response> => {
  const token = await acquireAccessToken();

  return fetch(
    url,
    {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    });
}

// Shortcut methods:
export const authGetJson = (url: string) => authFetch(url, 'GET', 'application/json', 'application/json');
export const authPostJson = (url: string, data: any) => authFetch(url, 'POST', 'application/json', 'application/json', data);
export const authPutJson = (url: string, data: any) => authFetch(url, 'PUT', 'application/json', 'application/json', data);
export const authDeleteJson = (url: string, data: any) => authFetch(url, 'DELETE', 'application/json', 'application/json', data);