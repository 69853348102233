import { IGemsRevisionMasterDataItem, IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { ApprovalLevels, HistoricalApprovalTypes, LockReasonTypes } from "interfaces/approvals/approvalInterfaces";
import { IOperationTreeNode } from "store/masterData/masterDataTypes";

export interface ISW {
  id: number,
  guid: string,
  type: SWTypes,
  version: number,
  title: string,
  taskAlignment: TaskAlignments,
  description: string,
  ppe: string[],
  notices: INoticeComponent[],
  steps: IStep[],
  language?: IMasterDataItem,
  serverLanguage?: IMasterDataItem,
  equivalentSW?: IMasterDataItem,
  owningOrg?: IMasterDataItem,
  owningPlant?: IMasterDataItem,
  customers: IMasterDataItem[],
  tasks: IMasterDataItem[],
  activityWorkflows: IMasterDataItem[],
  performingOrgs: IMasterDataItem[],
  subBusinessLines: IMasterDataItem[],
  serviceTypes: IMasterDataItem[],
  operatingEnvironments: IMasterDataItem[],
  equipment: IMasterDataItem[],
  epicEquipment: IMasterDataItem[],
  countries: IMasterDataItem[],
  geoUnits: IMasterDataItem[],
  competencyElements: IMasterDataItem[],
  safetyCategories: IMasterDataItem[],
  businessRoles: IMasterDataItem[],
  productCenters: IMasterDataItem[],
  referenceDocs: IReferenceDoc[],
  createdBy: string,
  activeApprovalRequestId?: number | null,
  activeApprovalLevel?: ApprovalLevels,
  approvalType?: HistoricalApprovalTypes,
  isLocked: boolean,
  lockReasonType?: LockReasonTypes | null,
  currentEditor?: string | null,
  currentEditorLockedDate?: Date | undefined,
  hasComments: boolean,
  totalStepComments?: number,
  unResolvedStepComments?: number,
  TLMSWIType?: string | undefined,
  introduction?: string | undefined,
  maintenanceTaskIds?: string | undefined,
  ceMarking?: string | undefined,
  foreward?: string | undefined,
  versionChanges?: string | undefined,
  harc?: string | undefined,
  versions?: number[],
  clGuid?: string,
  clVersion?: number,
  clTitle?: string,
  clDescription?: string,
  activeApprovalUserName?: string,
  performingPlants: IMasterDataItem[],
  workCenters: IMasterDataItem[],
  materials: IMasterDataItem[],
  gemsRevisionNumber: IGemsRevisionMasterDataItem | undefined,
  rcType: RCTypes | undefined,
  hazardCategory?: IMasterDataItem,
  showCLSignatureBlock?: ShowCLSignatureBlock | undefined,
  dataClassification: DataClassificationTypes,
  tcc: boolean,
  templateType?: string | undefined,
  contentType?: ContentTypes | undefined,
  group?: IMasterDataItem,
  groupCounter?: IMasterDataItem,
  events: IMasterDataItem[],
  level: string | undefined,
  hasPublishedVersion: boolean,
  plantSource?: PlantSource,
  activeStepGuid?: string,
  activeStepHierarchy?: string[],
  connectedSWIs: IConnectedSWI[],
  compatibleSWTypes: SWTypes[],
  showMFGUsageWarning: boolean,
}

export enum TemplateTypes {
  Standard = "Standard",
  Consolidated = "Consolidated"
}

export enum ShowCLSignatureBlock {
  No = "No",
  Yes = "Yes"
}

export enum ContentTypes {
  Executable = "Executable",
  Reference = "Reference"
}

export enum RCTypes {
  Notice = "Notice",
  SubStep = "SubStep",
  Step = "Step",
  Task = "Task",
}

export enum StepTypes {
  Step = "Step",
  SubStep = "SubStep",
  Task = "Task",
}

export enum ServiceLevel {
  SL_0 = "SL_0",
  SL_1 = "SL_1",
  SL_2 = "SL_2",
  SL_3 = "SL_3",
  SL_4 = "SL_4",
  SL_5 = "SL_5",
  SL_6 = "SL_6",
  SL_7 = "SL_7",
  SL_8 = "SL_8",
  SL_9 = "SL_9",
  SL_10 = "SL_10",
  SL_11 = "SL_11",
  SL_12 = "SL_12",
  SL_13 = "SL_13",
  SL_14 = "SL_14",
  SL_15 = "SL_15"
}

export interface ISiteLocation {
  id: string,
  code: string,
  description: string,
}

export interface ISiteLocationsData {
  siteLocations: ISiteLocation[]
}

export enum SWTypes {
  CL = "CL",
  ECL = "ECL",
  SWI = "SWI",
  TLMSWI = "TLMSWI",
  Unspecified = "Unspecified",
  LCL = "LCL",
  TLMRC = "TLMRC",
  MFGSWI = "MFGSWI",
  MFGCL = "MFGCL",
  MFGRC = "MFGRC",
  TIMERC = "TIMERC",
}

export enum PlantSource{
  OptimusSAP= "OptimusSAP",
  ITTSAP = "ITTSAP"
}

export enum SWStatus {
  Incomplete = "Incomplete",
  Completed = "Completed",
  NA = "N/A",
}

export enum TLMSWIType {
  Global = "Global",
  Local = "Local"
}

export enum DataClassificationTypes {
  Private = "Private",
  Confidential = "Confidential"
}

export enum TaskAlignments {
  After = "After",
  At = "At",
  Before = "Before",
  Unspecified = "Unspecified",
}

export interface IStepComponent {
  id?: number,
  guid: string,
  immutableGuid?: string,
  type: StepComponentTypes,
  label?: string,
  sortOrder: number,
}

export enum NoticeTypes {
  Warning = "Warning",
  Caution = "Caution",
  Info = "Info",
}

export enum Usage {
  Internal = "Internal",
  External = "External",
}

export interface INoticeComponent extends IStepComponent {
  type: StepComponentTypes.Notice,
  noticeType: NoticeTypes,
  image: INoticeImage,
  isRCComponent: boolean,
  rcTitle?: string,
  rcID?: number,
  rc?: RCNotice,
  isDisabled: boolean,
  hazardCategory?: IMasterDataItem | undefined,
}

export interface RCNotice {
  swGuid: string,
  swVersion: number,
}

export interface INumberInputComponent extends IStepComponent {
  type: StepComponentTypes.NumberInput,
  meterID?: string,
  meterCode?: string,
  uom?: string,
  lowerLimit?: number,
  upperLimit?: number,
  expectedValue?: number,
  nonConform?: string,
  uomCode?: string,
}

export interface IFormulaInputComponent extends IStepComponent {
  type: StepComponentTypes.FormulaNumerical,
  uom?: string,
  uomCode?: string,
  lowerLimit?: number,
  upperLimit?: number,
  nonConform?: string,
  formula?: string,
  meterId?: string,
  meterCode?: string,
}

export interface ITextInputComponent extends IStepComponent {
  type: StepComponentTypes.TextInput,
  meterId?: string,
  meterCode?: string,
}

export interface IRichTextInputComponent extends IStepComponent {
  type: StepComponentTypes.RichTextParagraph,
  html: string,
}

export interface ISelectInputComponent extends IStepComponent {
  type: StepComponentTypes.SelectInput,
  options: string[],
  expectedValue?: string,
  meterId?: string,
  meterCode?: string,
  nonConform?: string,
}

export interface IMultiSelectInputComponent extends IStepComponent {
  type: StepComponentTypes.MultiSelectInput,
  options: string[],
  expectedValue?: string[],
  meterId?: string,
  meterCode?: string,
  nonConform?: string,
}

export interface IDateInputComponent extends IStepComponent {
  type: StepComponentTypes.DateInput,
  meterId?: string,
  meterCode?: string,
}

export interface IDateTimeInputComponent extends IStepComponent {
  type: StepComponentTypes.DateTimeInput,
}

export interface IPhotoInputComponent extends IStepComponent {
  type: StepComponentTypes.PhotoInput,
}

export interface IVideoInputComponent extends IStepComponent {
  type: StepComponentTypes.VideoInput,
}

export interface IAudioInputComponent extends IStepComponent {
  type: StepComponentTypes.AudioInput,
}

export interface IRoutingOperationComponent extends IStepComponent {
  type: StepComponentTypes.RoutingOperation;
  operation: IOperationTreeNode;
}

export interface ISignatureInputComponent extends IStepComponent {
  type: StepComponentTypes.SignatureInput,
}

export interface IYesNoInputComponent extends IStepComponent {
  type: StepComponentTypes.YesNoInput,
  nonConform?: string,
  expectedValue?: string,
}

export interface IPassFailInputComponent extends IStepComponent {
  type: StepComponentTypes.PassFailInput,
  nonConform?: string,
  expectedValue?: string,
  meterId?: string,
  meterCode?: string,
}

export interface IImageComponent extends IStepComponent {
  type: StepComponentTypes.Image,
  filename: string,
  blobData?: IImageBlobData,
  alignment: ImageAlignments,
  scale?: ImageScale,
}

export interface ITIMEImageComponent extends IStepComponent {
  type: StepComponentTypes.TimeImage,
  filename: string,
  blobData?: IImageBlobData,
  alignment: ImageAlignments,
  label?: string,
  scale?: ImageScale,
}

export interface INoticeImage {
  filename: string,
  blobData?: IImageBlobData,
  imageType: ImageType,
}

export enum ImageAlignments {
  Right = "Right",
  Bottom = "Bottom",
}

export enum ImageType {
  Time = "Time",
  Normal = "Normal",
  Both = "Both",
}

export enum ImageScale {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
}

export enum StopType {
  HardStop = "Hard Stop",
  SoftStop = "Soft Stop",
  Confirmation = "Confirmation",
}

export interface IImageBlobData {
  filename: string,
  blobUrl: string,
  isLoading: boolean,
  errorMessage?: string,
}

export interface IMicrosoftStreamVideoComponent extends IStepComponent {
  type: StepComponentTypes.MicrosoftStreamVideo,
  videoGuid: string,
}

export interface IVideoComponent extends IStepComponent {
  type: StepComponentTypes.Video,
  filename: string,
  blobData?: IImageBlobData,
}

export interface IAudioComponent extends IStepComponent {
  type: StepComponentTypes.Audio,
  filename: string,
  blobData?: IImageBlobData,
}

export interface IAudioComponent extends IStepComponent {
  type: StepComponentTypes.Audio,
  filename: string,
  blobData?: IImageBlobData,
}

export interface ILinkComponent extends IStepComponent {
  type: StepComponentTypes.Link,
  url: string,
}

export interface IPPEComponent extends IStepComponent {
  type: StepComponentTypes.PPE,
    ppe: string[],
    applyPPEToAllSelected?: boolean,
}

export interface IQualityControlPointComponent extends IStepComponent {
  type: StepComponentTypes.QualityControlPoint,
  stopType: StopType,
}

export interface IRefDocTableComponent {
  rowCount: number,
  colCount: number,
  cells: ITableComponentCell[],
}

export interface ITableComponent extends IStepComponent {
  type: StepComponentTypes.Table,
  rowCount: number,
  colCount: number,
  cells: ITableComponentCell[],
}

export interface ITableComponentCell {
  rowIndex: number,
  colIndex: number,
  value: string,
  rowSpan: number,
  colSpan: number,
  imageFileName?: string,
}

export type StepComponentType = INoticeComponent |
  INumberInputComponent |
  ITextInputComponent |
  IRichTextInputComponent |
  ISelectInputComponent |
  IMultiSelectInputComponent |
  IDateInputComponent |
  IDateTimeInputComponent |
  IPhotoInputComponent |
  ISignatureInputComponent |
  IYesNoInputComponent |
  IImageComponent |
  ITIMEImageComponent |
  IMicrosoftStreamVideoComponent |
  ILinkComponent |
  ITableComponent |
  IPPEComponent |
  IVideoComponent |
  IAudioComponent |
  IQualityControlPointComponent |
  IVideoInputComponent |
  IAudioInputComponent |
  IRoutingOperationComponent |
  IFormulaInputComponent |
  IPassFailInputComponent;

export interface IStep {
  id?: number,
  guid: string,
  parentGuid: string | null,
  title: string,
  components: StepComponentType[],
  children: IStep[],
  isMasterStep: boolean,
  masterStepId?: number,
  isConditional: boolean,
  conditionalText?: string,
  isMemoryStep: boolean,
  isComprehensionRequired: boolean,
  isCritComp: boolean,
  critCompText?: string,
  critCompAnsNeedsImprov?: string,
  critCompAnsSufficient?: string,
  sortOrder: number,
  depth: number,
  isCritStep: boolean,
  stepComments: IStepComment[],
  hasUnResolvedComments?: boolean,
  isTask: boolean,
  isRCTask?: boolean,
  isRC?: RC,
  isRCStep: boolean,
  rcID?: number,
  rcContainer?: boolean,
  stepNumber?: string,
  rcTitle?: string,
  isDisabled: boolean,
  stepType?: StepTypes,
  serviceLevel?: ServiceLevel,
  siteLocation?: string,
  isStale: boolean,
  OOS: IMasterDataItem[],
  usage?: Usage,
  immutableGuid?: string,
  clientReport?: boolean
}

export interface RC {
  swGuid: string,
  swVersion: number,
  isTask: boolean,
}

export type StepBooleanMetaPropNames =
  "isMasterStep" |
  "isConditional" |
  "isMemoryStep" |
  "isComprehensionRequired" |
  "isCritComp" |
  "isCritStep" |
  "clientReport";

export type StepStringMetaPropNames =
  "conditionalText" |
  "critCompText" |
  "critCompAnsNeedsImprov" |
  "critCompAnsSufficient" |
  "title";

export enum StepComponentTypes {
  NumberInput = "NumberInput",
  TextInput = "TextInput",
  RichTextParagraph = "RichTextParagraph",
  SelectInput = "SelectInput",
  MultiSelectInput = "MultiSelectInput",
  DateInput = "DateInput",
  DateTimeInput = "DateTimeInput",
  PhotoInput = "PhotoInput",
  SignatureInput = "SignatureInput",
  YesNoInput = "YesNoInput",
  Image = "Image",
  TimeImage = "TimeImage",
  Notice = "Notice",
  MicrosoftStreamVideo = "MicrosoftStreamVideo",
  Link = "Link",
  Table = "Table",
  PPE = "PPE",
  Video = "Video",
  QualityControlPoint = "QualityControlPoint",
  VideoInput = "VideoInput",
  Audio = "Audio",
  AudioInput = "AudioInput",
  RoutingOperation = "RoutingOperation",
  FormulaNumerical = "FormulaNumerical",
  PassFailInput = "PassFailInput",
}

export interface IUploadSWAttachmentResponse {
  swGuid: string,
  swVersion: number,
  filename: string,
}

export interface IReferenceDoc {
  id: number | undefined,
  guid: string,
  refDocData: string,
  label: string,
  sortOrder?: number,
  type: string,
  tableData?: IRefDocTableComponent,
  lcl: boolean,
}

export interface IUnlockSWCurrentEditor {
  guid: string,
  currentEditor: string,
  currentEditorLockedDate: Date,
}

export interface ISWSummaryItem {
  id: number,
  guid: string,
  type: SWTypes,
  version: number,
  title: string,
  description: string,
  owningOrgId: string,
  owningPlantId: string,
  modifiedBy: string,
  modifiedOn: Date,
  latestPublishedCreatedOn: Date | undefined,
  latestPublishedVersion: number | undefined,
  inReview: boolean,
  currentEditor: string | undefined,
  currentEditorLockedDate: Date | undefined,
  thumbsUpCount: number | undefined,
  thumbsDownCount: number | undefined,
  hasActiveComments: boolean,
  executionCount: number | undefined,
  approvalLevel: string | undefined,
  smeApprover: string | undefined,
  techContRev: string | undefined
  isRCUsed: boolean,
  sourceVersion: string | undefined,
  sourceXml: string | undefined,
  rcType: RCTypes | undefined,
  dataClassification: DataClassificationTypes,
  templateType: TemplateTypes,
  outdatedGemsRevisionNumber: boolean,
}

export enum SWSortFields {
  Title = "Title",
  Rating = "Rating",
  ModifiedOn = "ModifiedOn",
  Comments = "Comments",
  ExecutionCount = "ExecutionCount",
  LatestPublishedCreatedOn = "LatestPublishedCreatedOn",
}

export interface IComment {
  id: number,
  swId: number,
  createdBy: string,
  createdOn: Date,
  comment: string,
  resolved: boolean,
}

export interface ISWUserFeedback {
  id: number,
  swId: string,
  version: string,
  feedback: string | undefined,
  vote: boolean,
  createdBy: string,
  createdOn: Date,
  acknowledged: boolean,
  response?: string | undefined | null,
  responseBy?: string | undefined | null,
  responseOn?: Date | undefined | null,
  isUpdated: boolean,
}

export interface ISWUserFeedbackExport {
  Id: number,
  SwId: string,
  Version: string,
  Feedback: string | undefined,
  Vote: string,
  CreatedBy: string,
  CreatedOn: string,
  Acknowledged: boolean,
  Response?: string | undefined | null,
  ResponseBy?: string | undefined | null,
  ResponseOn?: string | undefined | null,
}

export interface ISWUserFeedbackDetails {
  title: number | undefined,
  swGuid: string | undefined,
  currentVersion: number | undefined,
  userFeedbacks: ISWUserFeedback[],
  userFeedbacksExport: ISWUserFeedbackExport[],
}

export interface ISWUserFeedbackAck {
  id: number,
  swId: string,
  version: string,
  acknowledged: boolean,
  response?: string | undefined | null,
}

export interface ITimeRCProcessing {
  timeRCProcessingRequestsStatus: ITimeRCProcessingRequestsStatus[]
}

export interface ITimeRCProcessingRequestsStatus {
  requestId: string,
  status: string,
  log: string | undefined,
}

export interface ISWUserFeedbackDetails {
  title: number | undefined,
  swGuid: string | undefined,
  currentVersion: number | undefined,
  userFeedbacks: ISWUserFeedback[],
  userFeedbacksExport: ISWUserFeedbackExport[],
}

export interface IStepsCommentInfo {
  stepId: number,
  hasUnresolvedComments: boolean,
  totalStepComments: number,
  unResolvedStepComments: number,
}

export interface IStepComment {
  id: number,
  stepId: number,
  comment: string,
  createdBy: string,
  createdOn: Date,
  resolved: boolean,
}

export interface IStepComment {
  id: number,
  stepId: number,
  comment: string,
  createdBy: string,
  createdOn: Date,
  resolved: boolean,
}

export interface IArchiveResponse {
  ErrorType: string,
  Message: string,
  Success: boolean,
}

export interface IAllSWComments {
  swComments: IComment[],
  swStepComments: IStepComment[],
}

export interface IGetRCMapping {
  swId: number,
  title: string,
  type: SWTypes,
  guid: string,
  version: number,
  description: string,
  owningOrg: string,
  owningPlant: string,
  sourceSystem: string,
  active: boolean,
}

export interface IGetTimeMediaFileMapping {
  mediaId: string,
  swId: number,
  guid: string,
  version: number,
  title: string,
  sourceSystem: string,
  type: SWTypes,
}

export interface IGetRCSW {
  rcId: number,
  guid: string,
  version: number,
  draftExists: boolean,
}

export interface ISWRevisionHistory {
  id: number,
  version: string,
  publishDate: Date | undefined | null,
  description: string | undefined | null,
  approvalResponses: IUserApprovalResponseHistory[],
}

export interface IUserApprovalResponseHistory {
  level: string,
  userEmail: string,
  userName: string,
}

export interface IAllowedRCControlsConfig {
  whereUsed: boolean,
  createDraft: boolean,
  delink: boolean,
  canDisableStep: boolean,
  canDisableNotice?: boolean,
}

export interface IConnectedSWIData {
  connectedSWIs: IConnectedSWI[]
}

export interface IUploadConnectedSWIsResponse {
  swGuid: string,
  swVersion: number,
  connectedSWIs?: IConnectedSWI[],
  errors?: IConnectedSWIsUploadError[],
}

export interface IConnectedSWIsUploadError {
  materialCode: string,
  errorMsg: string,
}

export interface IConnectedSWI {
  id: number,
  dupeGuid: string | undefined,
  plantCode: string,
  materialCode: string,
  group: string,
  groupCounter: string
}

export interface IAddConnectedSWIPayload {
  swGuid: string,
  swVersion: number,
  plantCode: string,
  plantSource: string,
  materialCode: string,
  group: string,
  groupCounter: string
}

export interface IDeleteConnectedSWIPayload {
  swGuid: string,
  swVersion: number,
  id: number
}
export interface ISWNoticeSummary {
    swNotices: INotice[],
    swStepNotices: IStepNotice[],
}

export interface INotice {
    id: number,
    guid: string,
    swId: number,
    text: string,
    type: string,
    sortOrder: number,
}
export interface IStepNotice {
    id: number,
    guid: string,
    immutableGuid?: string,
    type: StepComponentTypes,
    label?: string,
    sortOrder: number,
    stepId: number
}

export interface IPasteComponents {
    stepGuid: string,
    componentGuid?: string,
    operation: "PasteAtStepEnd",
    components: StepComponentType[]
}