import Banner, { BannerType } from "components/common/Banner";
import BlockSpinner from "components/common/BlockSpinner";
import SearchBar from "components/common/SearchBar";
import FlowLayout from "components/layout/FlowLayout";
import { ISWWithBatchError } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addFilterItem, applyFilter, clearFilter, removeFilterItem, setSearchText, setStringFilter, setRCType } from "store/batchUpdates/batchUpdatesActions";
import { clearSearchedMasterDataItems } from "store/masterData/masterDataActions";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import useSelector from "store/useSelector";
import { getSWFilterCount } from "utilities/sw/filterUtils";
import SWList, { SWListModes } from "./SWList";
import "./SWSearch.scoped.scss";

const SWSearch: React.FC = () => {
  const {
    allowSearching,
    filterData,
    swListData: {
      loadOperation,
      swList,
    }
  } = useSelector(store => store.batchUpdates);
  const dispatch = useDispatch();

  let sortDirSelectedCol = filterData.sortDir;
  let sortBySelectedCol = filterData.sortBy;
  let onSetSearchRef = useRef((value: string) => dispatch(setSearchText(value)));

  const inputBoxRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputBoxRef.current){
        inputBoxRef.current.focus(); // set focus to search input box after rendering results
    }
  }, [swList]);

  const renderComponent = () => {
    if (loadOperation?.isWorking) {
      return (
        <BlockSpinner />
      );
    } else if (loadOperation?.errorMessage) {
      return (
        <Banner
          type={BannerType.Error}
        >
          {loadOperation.errorMessage}
        </Banner>
      );
    } else if (!allowSearching
      || (!filterData.filterFields.searchText
        && getSWFilterCount(filterData.filterFields, 0) === 0)) {
      return null;
    } else if (!swList.length) {
      return (
        <label>No StandardWorks found.</label>
      );
    } else {
      return (
        <SWList
          mode={SWListModes.Add}
          swList={swList.map((x): ISWWithBatchError => ({
            sw: x,
          }))}
          sortDirSelectedCol={sortDirSelectedCol}
          sortBySelectedCol={sortBySelectedCol}
        />
      );
    }
  }

  const applyDipatchFilter = () => {
    dispatch(applyFilter());
    dispatch(clearSearchedMasterDataItems());
  }

  return (
    <FlowLayout
      header={(
        <>
          <SearchBar
            swFilterFields={filterData.filterFields}
            setSearchText={onSetSearchRef.current}
            onAddSWItem={(item: IMasterDataItem, name: MetaDataNames) => dispatch(
              addFilterItem({
                metaDataName: name,
                item,
              })
            )}
            onRemoveSWItem={(item: IMasterDataItem, name: MetaDataNames) => dispatch(
              removeFilterItem({
                metaDataName: name,
                item,
              })
            )}
            applyFilter={applyDipatchFilter}
            clearFilter={() => dispatch(clearFilter())}
            setSWStringFilter={(name: "maintenanceTaskIds", value: string) => dispatch(
              setStringFilter({
                attributeName: name,
                value: value,
              })
            )}
            extraFilterCount={0}
            isDisabled={loadOperation?.isWorking === true}
            showStatusFilter={true}
            placeholder="Search by title, description, or guid..."
            setRCType={(value) => dispatch(setRCType(value))}
            inputBoxRef={inputBoxRef}
          />
          <span
            className="note"
          >
            Note: Search results will automatically be filtered by your assigned orgs.
          </span>
        </>
      )}
    >
      {renderComponent()}
    </FlowLayout>
  )
};

export default SWSearch;