import React from "react";
import "./MetadataTab.scoped.scss";

interface IMetadataTab {
    isActive: boolean,
    onClick(): void,
    label: string,
    icon: string,
}

const MetadataTab: React.FC<IMetadataTab> = (props) => {
    return (
        <div
            className={"tab " + (props.isActive ? "active" : "")}
            onClick={props.onClick}
        >
            <img
                src={props.icon}
                title={props.label + " metadata"}
                alt={props.label + " metadata"}
                className="icon-large"
            />
            <span>
                {props.label}
            </span>
        </div>);
}

export default MetadataTab;