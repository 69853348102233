import React from "react";
import "./PageContent.scoped.scss";

interface IPageContentProps {
  children: any,
}

const PageContent: React.FC<IPageContentProps> = (props) =>
  <div className="content">
    {props.children}
  </div>;

export default PageContent;