import Banner, { BannerType } from "components/common/Banner";
import BlockSpinner from "components/common/BlockSpinner";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import React, { useEffect, useState, useRef } from "react";
import { IMasterDataSection } from "store/masterData/masterDataTypes";
import useDebounce from "utilities/useDebounce";
import "./PickerModal.scoped.scss";
import AddIcon from "media/icons/dls/add.svg";
import Modal from "components/common/Modal";

interface IPickerModalProps {
  label: string,
  masterDataSection: IMasterDataSection,
  selectedItems: IMasterDataItem[],
  allowMultiple?: boolean,
  isAsyncSearch?: boolean,
  loadOnStart?: boolean,
  onAddItem(item: IMasterDataItem): void,
  onLoad(searchTerm?: string): void,
  onClose(): void,
  itemFormatter(item: IMasterDataItem): string | JSX.Element,
  itemFilter?(item: IMasterDataItem): boolean,
}

const PickerModal: React.FC<IPickerModalProps> = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const searchBoxRef = useRef<HTMLInputElement | null>(null);
  const {
    masterDataSection,
    isAsyncSearch,
    onLoad,
  } = props;

  useEffect(() => {
    if (!masterDataSection.loadOperation
      && (!masterDataSection.items.length || (props.loadOnStart && !hasLoaded))
      && !isAsyncSearch) {
      onLoad();
      setHasLoaded(true);
    }

    if (isAsyncSearch
      && searchBoxRef.current) {
      searchBoxRef.current.focus();
    }
  }, [masterDataSection, isAsyncSearch, onLoad, hasLoaded, props.loadOnStart]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      onLoad(debouncedSearchTerm);
    }

    // Can't include onLoad dependency since it changes each rerender
    // because it is an anonymous function.
    // However, the only way it would change would be if the modal
    // is closed and reopened, which would mean it doesn't need to
    // be passed anyway.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  let component: JSX.Element | undefined;

  if (props.masterDataSection.loadOperation?.isWorking) {
    component = (
      <BlockSpinner />
    );
  } else if (props.masterDataSection.loadOperation?.errorMessage) {
    component = (
      <>
        <Banner
          type={BannerType.Error}
        >
          {'Load error'} {props.masterDataSection.loadOperation.errorMessage}
        </Banner>
        <button
          className="primary-button"
          onClick={() => props.onLoad(props.isAsyncSearch ? searchTerm : undefined)}
        >
          {'Retry Loading'}
        </button>
      </>
    );
  } else {
    component = (
      <ul>
        {props.masterDataSection.items
          .filter(i => !props.selectedItems.find(x => x.guid === i.guid))
          .filter(i => props.itemFilter
            ? props.itemFilter(i)
            : true)
          .slice()
          .sort((a, b) => props.itemFormatter(a) < props.itemFormatter(b) ? -1 : 1)
          .map(i =>
            <li
              key={i.guid}
            >
              <span>
                {props.itemFormatter(i)}
              </span>
              <img
                className="hover-gray-bg icon-small"
                alt={"Add"}
                onClick={() => {
                  props.onAddItem(i);
                  if (!props.allowMultiple) {
                    props.onClose();
                  }
                }}
                src={AddIcon}
              />
            </li>
          )}
      </ul>
    );
  }

  return (
    <Modal
      isOpen={true}
      header={props.label}
      controls={(
        <button
          className="primary-button"
          onClick={props.onClose}
        >
          {'Done'}
        </button>
      )}
    >
      {props.isAsyncSearch &&
        <input
          type="text"
          placeholder={`Search for ${props.label.toLowerCase()}...`}
          className="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          ref={searchBoxRef}
        />
      }
      <div
        className="body"
      >
        {component}
      </div>
    </Modal>
  );
}

export default PickerModal;