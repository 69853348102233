import SWTab from "components/sw/manage/toolbar/SWTab";
import React from "react";
import documentsIcon from "media/icons/dls/documents.svg";
import controlsIcon from "media/icons/dls/controls.svg";
import checklistIcon from "media/icons/dls/checklist.svg";
import { useDispatch } from "react-redux";
import { BatchUpdatesTab } from "store/batchUpdates/batchUpdatesTypes";
import { setCurrentTab } from "store/batchUpdates/batchUpdatesActions";
import "./BatchUpdatesToolbar.scoped.scss";

interface IBatchUpdatesToolbarProps {
  currentTab: BatchUpdatesTab,
}

const BatchUpdatesToolbar: React.FC<IBatchUpdatesToolbarProps> = ({
  currentTab,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      className="tabs"
    >
      <SWTab
        isActive={currentTab === BatchUpdatesTab.SW}
        label="Add Standard Work"
        icon={documentsIcon}
        onClick={() => dispatch(setCurrentTab(BatchUpdatesTab.SW))}
      />
      <SWTab
        isActive={currentTab === BatchUpdatesTab.Commands}
        label="Add Updates"
        icon={controlsIcon}
        onClick={() => dispatch(setCurrentTab(BatchUpdatesTab.Commands))}
      />
      <SWTab
        isActive={currentTab === BatchUpdatesTab.Review}
        label="Review"
        icon={checklistIcon}
        onClick={() => dispatch(setCurrentTab(BatchUpdatesTab.Review))}
      />
    </div>
  );
};

export default BatchUpdatesToolbar;