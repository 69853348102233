import { IBatchUpdateCommand } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import React from "react";
import { useDispatch } from "react-redux";
import { setCommandManagerData } from "store/batchUpdates/batchUpdatesActions";
import CommandItem from "./CommandItem";
import CommandManager from "./CommandManager";
import "./CommandsList.scoped.scss";

interface ICommandsListProps {
  isReadOnly: boolean,
  commands: IBatchUpdateCommand[],
}

const CommandsList: React.FC<ICommandsListProps> = ({
  isReadOnly,
  commands,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {commands.length > 0 &&
        <ol>
          {commands.map(cmd => (
            <CommandItem
              key={cmd.sortOrder}
              command={cmd}
              showRemove={!isReadOnly}
            />
          ))}
        </ol>
      }
      {commands.length === 0 &&
        <span>
          No batch updates will be applied to the list of standard work.
          {!isReadOnly
            ? " Use the button below to add batch update operations."
            : ""
          }
        </span>
      }
      {!isReadOnly &&
        <>
          <div
            className="button-row"
          >
            <button
              className="primary-button"
              onClick={() => dispatch(setCommandManagerData({
                isOpen: true,
                managedCommandSortOrder: null,
              }))}
            >
              Add Update
            </button>
          </div>
          <CommandManager />
        </>
      }
    </>
  );
};

export default CommandsList;