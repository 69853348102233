import React from "react";
import Modal from "components/common/Modal";
import { StepComponentTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import "./ComponentTypeModal.scoped.scss";
import TextIcon from "media/icons/dls/text.svg";
import HashTagIcon from "media/icons/dls/hashtag.svg";
import CalendarIcon from "media/icons/dls/calendar.svg";
import DateTimeIcon from "media/icons/dls/datetime.svg";
import SignatureIcon from "media/icons/dls/signature.svg";
import ListIcon from "media/icons/dls/list.svg";
import ChecklistIcon from "media/icons/dls/checklist.svg";
import HelpIcon from "media/icons/dls/help.svg";
import SnapshotIcon from "media/icons/dls/snapshot.svg";
import PlayIcon from "media/icons/dls/play.svg";
import CalculatorIcon from "media/icons/dls/calculator.svg";
import PassFailIcon from "media/icons/dls/passfail.svg";
import StepComponent from "./StepComponent";

interface IComponentTypeModalProps {
  swType: SWTypes,
  onCancel(): void,
  onTypeChosen(componentType: StepComponentTypes): void,
  disabledTypes?: IDisabledComponentType[],
}

export interface IDisabledComponentType {
  type: StepComponentTypes,
  reason?: string,
}

const InputComponentTypeModal: React.FC<IComponentTypeModalProps> = ({ swType, onCancel, onTypeChosen, disabledTypes = [] }) => {
  const isBelongsToMFG: boolean = (swType === SWTypes.MFGSWI || swType === SWTypes.MFGCL || swType === SWTypes.MFGRC);
  return (
   <>
    <Modal
    isOpen={true}
    header={"Component Type"}
    controls={(
      <button
        className="secondary-button"
        onClick={onCancel}
      >
        Cancel
      </button>
    )}
    >
     <p>
      Choose the type of component to add to the step.
    </p>
     <div className="type-list">
      {Array.from(typeDisplays)
        .filter(key => filterBySWType(key[0], swType))
        .map(([type, display]) =>
          <button
            key={type}
            className={disabledTypes.some(t => t.type === type) && isBelongsToMFG ? "primary-button-hide" : "primary-button"}
            onClick={() => onTypeChosen(type)}
            disabled={disabledTypes.some(t => t.type === type)}
            title={disabledTypes.find(t => t.type === type)?.reason}
          >
            <img
              src={typeIcons.get(type)}
              alt=""
              className="black-to-white-filter icon-medium"
            />
            {display}
          </button>
        )}
     </div>
    </Modal>
   </>
  )
};
export default InputComponentTypeModal;

const swSupport: Map<SWTypes, StepComponentTypes[]> = new Map();
swSupport.set(SWTypes.ECL, [
]);
swSupport.set(SWTypes.CL, [
  StepComponentTypes.NumberInput,
  StepComponentTypes.TextInput,
  StepComponentTypes.SelectInput,
  StepComponentTypes.MultiSelectInput,
  StepComponentTypes.DateInput,
  StepComponentTypes.DateTimeInput,
  StepComponentTypes.PhotoInput,
  StepComponentTypes.SignatureInput,
  StepComponentTypes.YesNoInput,
]);
swSupport.set(SWTypes.LCL, [
  StepComponentTypes.NumberInput,
  StepComponentTypes.TextInput,
  StepComponentTypes.SelectInput,
  StepComponentTypes.MultiSelectInput,
  StepComponentTypes.DateInput,
  StepComponentTypes.DateTimeInput,
  StepComponentTypes.PhotoInput,
  StepComponentTypes.SignatureInput,
  StepComponentTypes.YesNoInput,
]);
swSupport.set(SWTypes.SWI, [
  StepComponentTypes.NumberInput,
  StepComponentTypes.TextInput,
  StepComponentTypes.SelectInput,
  StepComponentTypes.MultiSelectInput,
  StepComponentTypes.DateInput,
  StepComponentTypes.PhotoInput,
  StepComponentTypes.SignatureInput,
  StepComponentTypes.YesNoInput,
]);
swSupport.set(SWTypes.TLMSWI, [
  StepComponentTypes.NumberInput,
  StepComponentTypes.TextInput,
  StepComponentTypes.SelectInput,
  StepComponentTypes.MultiSelectInput,
  StepComponentTypes.DateInput,
  StepComponentTypes.PhotoInput,
  StepComponentTypes.SignatureInput,
  StepComponentTypes.YesNoInput,
  StepComponentTypes.VideoInput,
  StepComponentTypes.AudioInput,
  StepComponentTypes.FormulaNumerical,
  StepComponentTypes.PassFailInput,
]);
swSupport.set(SWTypes.TLMRC, [
  StepComponentTypes.NumberInput,
  StepComponentTypes.TextInput,
  StepComponentTypes.SelectInput,
  StepComponentTypes.MultiSelectInput,
  StepComponentTypes.DateInput,
  StepComponentTypes.PhotoInput,
  StepComponentTypes.SignatureInput,
  StepComponentTypes.YesNoInput,
  StepComponentTypes.VideoInput,
  StepComponentTypes.AudioInput,
  StepComponentTypes.PassFailInput,
]);
swSupport.set(SWTypes.MFGSWI, [
  StepComponentTypes.YesNoInput,
  StepComponentTypes.NumberInput,
  StepComponentTypes.TextInput,
  StepComponentTypes.SelectInput,
  StepComponentTypes.MultiSelectInput,
  StepComponentTypes.PhotoInput,
  StepComponentTypes.DateInput,
  StepComponentTypes.PassFailInput,
  StepComponentTypes.SignatureInput,
  StepComponentTypes.VideoInput,

]);
swSupport.set(SWTypes.MFGCL, [
  StepComponentTypes.YesNoInput,
  StepComponentTypes.NumberInput,
  StepComponentTypes.TextInput,
  StepComponentTypes.DateInput,
  StepComponentTypes.PassFailInput,
  StepComponentTypes.PhotoInput,
  StepComponentTypes.SignatureInput,
  StepComponentTypes.VideoInput,
]);
swSupport.set(SWTypes.MFGRC, [
  StepComponentTypes.YesNoInput,
  StepComponentTypes.NumberInput,
  StepComponentTypes.TextInput,
  StepComponentTypes.SelectInput,
  StepComponentTypes.MultiSelectInput,
  StepComponentTypes.PhotoInput,
  StepComponentTypes.DateInput,
  StepComponentTypes.PassFailInput,
  StepComponentTypes.SignatureInput,
  StepComponentTypes.VideoInput,
]);

const typeDisplays: Map<StepComponentTypes, string> = new Map();
typeDisplays.set(StepComponentTypes.YesNoInput, "Yes/No Input");
typeDisplays.set(StepComponentTypes.PhotoInput, "Photo Input");
typeDisplays.set(StepComponentTypes.NumberInput, "Number Input");
typeDisplays.set(StepComponentTypes.TextInput, "Text Input");
typeDisplays.set(StepComponentTypes.SelectInput, "Select from List Input");
typeDisplays.set(StepComponentTypes.MultiSelectInput, "Multi-Select from List Input");
typeDisplays.set(StepComponentTypes.DateInput, "Date Input");
typeDisplays.set(StepComponentTypes.DateTimeInput, "Date/Time Input");
typeDisplays.set(StepComponentTypes.SignatureInput, "Signature Input");
typeDisplays.set(StepComponentTypes.VideoInput, "Video Input");
typeDisplays.set(StepComponentTypes.AudioInput, "Audio Input");
typeDisplays.set(StepComponentTypes.FormulaNumerical, "Formula Input");
typeDisplays.set(StepComponentTypes.PassFailInput, "Pass/Fail Input");

const typeIcons: Map<StepComponentTypes, string> = new Map();
typeIcons.set(StepComponentTypes.YesNoInput, HelpIcon);
typeIcons.set(StepComponentTypes.PhotoInput, SnapshotIcon);
typeIcons.set(StepComponentTypes.NumberInput, HashTagIcon);
typeIcons.set(StepComponentTypes.TextInput, TextIcon);
typeIcons.set(StepComponentTypes.SelectInput, ListIcon);
typeIcons.set(StepComponentTypes.MultiSelectInput, ChecklistIcon);
typeIcons.set(StepComponentTypes.DateInput, CalendarIcon);
typeIcons.set(StepComponentTypes.DateTimeInput, DateTimeIcon);
typeIcons.set(StepComponentTypes.SignatureInput, SignatureIcon);
typeIcons.set(StepComponentTypes.VideoInput, PlayIcon);
typeIcons.set(StepComponentTypes.AudioInput, PlayIcon);
typeIcons.set(StepComponentTypes.FormulaNumerical, CalculatorIcon);
typeIcons.set(StepComponentTypes.PassFailInput, PassFailIcon);

const filterBySWType = (key: StepComponentTypes, swType: SWTypes) => {
  const support = swSupport.get(swType);

  const ix = support
    ?.indexOf(key);

  return ix !== undefined && ix > -1;
}