import React from "react";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import "./SWTypeIcon.scoped.scss";

interface ISWTypeIconProps {
  type: SWTypes,
  isRCUpdate?: boolean,
}

const SWTypeIcon: React.FC<ISWTypeIconProps> = ({ type, isRCUpdate }) => {
  let labelText = ""

  if (type === SWTypes.TLMSWI || type === SWTypes.Unspecified) {
    labelText = "TLM";
  } else if (type === SWTypes.TLMRC || type === SWTypes.TIMERC) {
    labelText = "RC";
  } else if (type === SWTypes.MFGSWI || type === SWTypes.MFGCL || type === SWTypes.MFGRC) {
    labelText = "MFG";
  } else {
    labelText = type;
  }

  return (
    <div className={`hexagon ${type}`}>
      <label>{labelText}</label>
      {/* {isRCUpdate && type !== SWTypes.TLMRC && <span className="rc-update-orb">RC</span>} */}
    </div>);
}

export default SWTypeIcon;