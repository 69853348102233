import React from "react";
import { IPhotoInputComponent } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { updatePhotoInput } from "store/manageSW/manageSWActions";
import "./ComponentEditor.scss";

interface IPhotoInputEditorProps {
  photoInput: IPhotoInputComponent,
  allowEdit: boolean,
  stepGuid: string,
  labelText: string,
}

const PhotoInputEditor: React.FC<IPhotoInputEditorProps> = ({
  photoInput,
  allowEdit,
  stepGuid,
  labelText,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="component-editor">
      <label className="mandatory block">{labelText}</label>
      {allowEdit &&
        <input
          type="text"
          defaultValue={photoInput.label}
          onBlur={(e) => dispatch(updatePhotoInput({
            stepGuid,
            component: {
              ...photoInput,
              label: e.target.value,
            },
          }))}
        />
      }
      {!allowEdit &&
        <span>{photoInput.label}</span>
      }
    </div>
  );
}

export default PhotoInputEditor;