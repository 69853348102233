import React from 'react';
import './ApprovalHistoryModal.scss'; // Import the CSS file
import { ApprovalResponseTypes, IApprovalWorkFlowResponse } from 'interfaces/approvals/approvalInterfaces';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import HistoryIcon from '@mui/icons-material/History';
import CloseIcon from '@mui/icons-material/Close';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

const ApprovalHistoryModal: React.FC<IApprovalWorkFlowResponse> = ({ level, responseType, optional }) => {

  const imageClassName = "filter " + responseType;
  const optionalText = optional ? "(optional)" : "";

  function GetLockIcon() {
    return (
      <LockIcon className={`icon ${responseType}`} />
    )
  }

  function GetDoneIcon() {
    return (
      <DoneIcon className={`icon ${responseType}`} />
    )
  }

  function PendingApprovalIcon() {
    return (
      <AccessTimeFilledIcon className={`icon ${responseType}`} />
    )
  }

  function SkipIcon() {
    return (
      <NotInterestedIcon className={`icon ${responseType}`} />
    )
  }

  function GetRevertIcon() {
    return (
      <HistoryIcon className={`icon ${responseType}`} />
    )
  }

  function GetRejectIcon() {
    return (
      <CloseIcon className={`icon ${responseType}`} />
    )
  }

  function GetRetractIcon() {
    return (
      <SettingsBackupRestoreIcon className={`icon ${responseType}`} />
    )
  }

  return (

    <div className="image-container">
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="311px" height="50px" viewBox="-0.5 -0.5 311 61" className='image'>
        <g>
          <path d="M 0 0 L 280 0 L 280 0 L 310 30 L 280 60 L 280 60 L 0 60 L 30 30 Z"
            className={imageClassName}
          />
        </g>
      </svg>
      <div className="overlay-text">
        {(responseType === ApprovalResponseTypes.Approved || responseType === ApprovalResponseTypes.AutoApproved) && GetDoneIcon()}
        {responseType === ApprovalResponseTypes.Active && PendingApprovalIcon()}
        {responseType === ApprovalResponseTypes.Revert && GetRevertIcon()}
        {responseType === ApprovalResponseTypes.Rejected && GetRejectIcon()}
        {responseType === ApprovalResponseTypes.NotCompleted && GetLockIcon()}
        {responseType === ApprovalResponseTypes.Skip && SkipIcon()}
        {responseType === ApprovalResponseTypes.Retracted && GetRetractIcon()}
        {responseType !== ApprovalResponseTypes.AutoApproved &&  <>
          <p className={`para ${responseType}`}>{level + optionalText}</p>
        </>}
        {responseType === ApprovalResponseTypes.AutoApproved &&  <>
          <p className={`para ${responseType}`}>Auto Approved</p>
        </>}
      </div>
    </div>


  );
};

export default ApprovalHistoryModal;
