import React from "react";
import { IStep } from "../../../../interfaces/sw/SWInterfaces";
import useSelector from "../../../../store/useSelector";

const SWStepNotices: React.FC<IStep> = (step) => {
    const { swStepNoticeData } = useSelector(store => store.manageSW);
    const stepNotices = swStepNoticeData.swStepNotices?.filter(x => x.stepId === step.id);
    const hasTaskNotices = stepNotices === undefined ? false : stepNotices.length > 0 ? true : false;
    const hasSteporSubStepNotices = hasStepNotices(step.children);
    function hasStepNotices(steps: IStep[]): boolean | undefined {
        let isNoticePresent = false;
        for (let i = 0; i < steps.length; i++) {
            const notices = swStepNoticeData.swStepNotices?.filter(x => x.stepId === steps[i].id);
            if (notices !== undefined && notices.length > 0) {
                isNoticePresent = true;
                return isNoticePresent;
            }
            else {
                if (steps[i].children.length > 0) {
                    hasStepNotices(steps[i].children);
                }
            }
        }
        return isNoticePresent;
    }

    return (
        <div className="step-notices">
            {(hasTaskNotices || hasSteporSubStepNotices) &&
                <ul>
                    <li>
                        <div>
                            <div className="title">
                                <label>{step.title}</label>
                            </div>
                            <div className="notice-list">
                                <ul>{hasTaskNotices &&
                                    stepNotices?.map((notice, index) => (
                                        <li key={index}>{notice.label?.replace(/<\/?p>/g, '')}</li>
                                    ))}
                                    {hasSteporSubStepNotices &&
                                        step.children
                                            .map((item, index) => (
                                                <SWStepNotices key={index.toString()} {...item} />
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            }
        </div>)
}

export default SWStepNotices;