import React from "react";
import { INoticeComponent, ISW, SWTypes } from "interfaces/sw/SWInterfaces";
import StepComponent from "components/sw/manage/steps/components/StepComponent";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";

interface INoticesProps {
  notices: INoticeComponent[],
  swGuid: string,
  swType: SWTypes,
  swVersion: number,
  isDraftPage?: boolean,
  sw: ISW,
}

const Notices: React.FC<INoticesProps> = ({ notices, swGuid, swVersion, swType, isDraftPage,sw }) => {
  return (
    <>
      {notices
        .slice()
        .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1)
        .map(notice =>
          <StepComponent
            key={notice.guid}
            component={notice}
            showWrapper={false}
            isDisabled={true}
            swGuid={swGuid}
            swVersion={swVersion}
            imageDataDestination={ImageDataDestinations.Approval}
            swType={swType}
            isDraftPage={isDraftPage}
            sw={sw}
          />
        )}
    </>
  )
}

export default Notices;