import { throwIfResponseError } from "apis/apiUtilities";
import config from "config";
import { ISwRepoSummaryItem } from "store/ManageSwReports/ManageSwReportsTypes";
import { authGetJson, authPostJson } from "utilities/msalFetches";
import { formatGetSwRepoListResponse } from "./swReportsFormatter";

class SwReportsApi {
    public async getSwRepoList(uid: string): Promise<ISwRepoSummaryItem[]> {
        let endpoint = config.endpoints.reports.getSwRepoList
            .replace("{uid}", uid);
        let response = await authGetJson(endpoint);

        await throwIfResponseError(response);

        let json = await response.json();
        let sws = formatGetSwRepoListResponse(json);

        return sws;
    }

    public async downloadSwRepoPdf(uid: string, version: string, sourceSystem: string): Promise<Blob> {

        const response = await authPostJson(
            config.endpoints.reports.downloadSwRepoPdf,
            JSON.stringify(
                {
                    Uid: uid,
                    Version: version,
                    SourceSystem: sourceSystem,
                }
            )
        );

        await throwIfResponseError(response);
        return response.blob();
    }
};

export default new SwReportsApi();