import { takeLatest, put, select, call, all, takeEvery } from "redux-saga/effects";
import { Action } from "@reduxjs/toolkit";
import {
  getData,
  setData,
  setSearchText,
  confirmCreateDraft,
  confirmCreateLCL,
  setCreateDraftData,
  confirmArchive,
  setArchiveData,
  applyFilter,
  getPDF,
  setPDFData,
  confirmDeleteDraft,
  setDeleteDraftData,
  loadSWUserFeedbacks,
  setSWUserFeedbacks,
  saveSWUserFeedbackAcks,
  setSWUserFeedbackAcks,
  setCreateLCLData,
  setSort,
  getRCWhereUsedItems,
  setRCWhereUsedItems,
  getDownloadZip,
  addDownloadZipData,
  removeDownloadZipData,
  getArchiveRequest,
  addArchiveData,
  removeArchiveData,
  getRefreshSearchData,
  setNewIndex,
  confirmBulkDeleteDraft,
  clearDownloadZip,
  setBulkDeleteDraftData,
  getTimeRCProcessingRequestStatus,
  removeTimeRCRequestData,
  searchConnectedSWIsByChildGuid,
  setConnectedSWIs,
} from "./swListActions";
import { RootState } from "../rootStore";
import { ISWListData, ISWFilterFields, ISWFilterData } from "./swListTypes";
import SWApi, { IPDFDownloadResponse } from "apis/sw/SWApi";
import { IArchiveResponse, IGetRCMapping, ISWSummaryItem, ISWUserFeedbackDetails, ITimeRCProcessing, RCTypes } from "interfaces/sw/SWInterfaces";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import { showErrorToast, showInfoToast, showSuccessToast } from "store/toast/toastActions";
import {
  setSearchText as setBatchUpdatesSearchText,
  applyFilter as applyBatchUpdatesFilter,
  setData as setBatchUpdatesData,
  setSort as setBatchSort,
  setAllowSearching
} from "store/batchUpdates/batchUpdatesActions";
import { setSearchText as setRCSearchText, setSearchRCNotice } from "store/manageSW/manageSWActions"
import { getSWFilterCount } from "utilities/sw/filterUtils";
import { cloneDeep } from "lodash";
import { Roles } from "interfaces/user/UserInterfaces";
import { downloadData } from "utilities/fileUtilities";
import { ResponseError } from "../../apis/responseError";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

export default function* watchSWListSagas() {
  yield all([
    watchGetData(),
    watchCreateDraft(),
    watchCreateLCL(),
    watchArchive(),
    watchDownloadPDF(),
    watchDownloadZipData(),
    watchArchiveRequest(),
    watchDeleteDraft(),
    watchLoadSWUserFeedbacks(),
    watchSaveSWUserFeedbackAcks(),
    watchRCWhereUsedItems(),
    watchRefreshSearchData(),
    watchBulkDeleteDraft(),
    watchLoadTimeRCProcessingRequestStatus(),
    watchSearchConnectedSWIsByChildGuid(),
  ]);
}

function* watchGetData() {
  yield takeLatest([
    getData,
    setSearchText,
    setNewIndex,
    setSort,
    setBatchSort,
    applyFilter,
    setBatchUpdatesSearchText,
    applyBatchUpdatesFilter,
    setRCSearchText,
    setSearchRCNotice,
  ], refreshSWListAsync);
}

function* watchCreateDraft() {
  yield takeLatest(confirmCreateDraft, createSWDraftAsync);
}

function* watchCreateLCL() {
  yield takeLatest(confirmCreateLCL, createLCLAsync);
}

function* watchRCWhereUsedItems() {
  yield takeLatest(getRCWhereUsedItems, getRCWhereUsedItemsAsync)
}

function* watchArchive() {
  yield takeLatest(confirmArchive, archiveSWAsync);
}

function* watchLoadSWUserFeedbacks() {
  yield takeLatest(loadSWUserFeedbacks, getSWUserFeedbacksAsync);
}

function* watchLoadTimeRCProcessingRequestStatus() {
  yield takeLatest(getTimeRCProcessingRequestStatus, getTimeRCProcessingRequestStatusAsync);
}

function* watchSearchConnectedSWIsByChildGuid() {
  yield takeLatest(searchConnectedSWIsByChildGuid, searchConnectedSWIsByChildGuidAsync);
}

function* watchSaveSWUserFeedbackAcks() {
  yield takeLatest(saveSWUserFeedbackAcks, saveSWUserFeedbackAcksAsync);
}

function* watchDeleteDraft() {
  yield takeLatest(confirmDeleteDraft, deleteSWDraftAsync);
}

function* watchBulkDeleteDraft() {
  yield takeLatest(confirmBulkDeleteDraft, bulkDeleteSWDraftAsync);
}

function* watchDownloadPDF() {
  yield takeLatest(getPDF, getPDFAsync);
}

function* watchDownloadZipData() {
  yield takeEvery(getDownloadZip, downloadZipAsync);
}

function* watchArchiveRequest() {
  yield takeEvery(getArchiveRequest, archiveRequestAsync);
}

function* watchRefreshSearchData() {
  yield takeLatest(getRefreshSearchData, refreshSearchAsync);
}

function* archiveRequestAsync(action: Action) {
  const state: RootState = yield select((store: RootState) => store);
  const swListZip = state.swList.getArchiveRequest.swListZip;
  const requestID = state.swList.getArchiveRequest.requestID;

  try {
    yield put(
      addArchiveData({
        swListZip: swListZip,
        requestID: requestID,
      })
    );
    let data: IArchiveResponse = yield call(
      SWApi.archiveRequestData,
      swListZip,
      requestID,
    );
    if (data.Success) {
      yield put(
        removeArchiveData({
          requestID: requestID,
        })
      );
      yield put(
        getRefreshSearchData()
      );
    }
  }
  catch (err: any) {
    yield put(removeArchiveData({
      requestID: requestID,
    })
    );
    yield put(
      getRefreshSearchData()
    );
    yield put(
      showErrorToast("Failed to archive Request: " + getResponseErrorMessage(err))
    );
  }
}

function* downloadZipAsync(action: Action) {
  const state: RootState = yield select((store: RootState) => store);
  const swListZip = state.swList.getDownloadZip.swListZip;
  const requestID = state.swList.getDownloadZip.requestID;

  try {
    yield put(
      addDownloadZipData({
        swListZip: swListZip,
        requestID: requestID,
      })
    );
    let data: Blob = yield call(
      SWApi.downloadZipFile,
      swListZip,
      requestID,
    );
    if (data.size > 0) {
      yield call(downloadData, data, 'SwPDFs.zip');
      yield put(
        removeDownloadZipData({
          requestID: requestID,
        })
      );
    }
  }
  catch (err: any) {
    yield put(
      showErrorToast("Failed to generate Zip File: " + getResponseErrorMessage(err))
    );
    yield put(removeDownloadZipData({
      requestID: requestID,
    })
    );
  }
}

function* refreshSearchAsync(action: Action) {
  let destination = "swList";
  let filterDataList: ISWFilterData;
  let newItems: ISWSummaryItem[] = [];
  let index: number = 0;
  const state: RootState = yield select((store: RootState) => store);
  filterDataList = state.swList.filterData
  const { sortBy, sortDir } = filterDataList;

  const swListData = destination === "swList"
    ? state.swList.swListData
    : state.batchUpdates.swListData;

  let filterFields: ISWFilterFields = state.swList.filterData.filterFields

  let result: ISWListData;
  try {
    result = yield call(SWApi.getSWList,
      filterFields,
      destination === "batchUpdates",
      destination !== "batchUpdates",
      sortBy,
      sortDir,
      index,
      false,
      false,
      "ListSearch");
  } catch (err: any) {
    const errData: ISWListData = {
      ...swListData,
    };

    if (destination === "swList") {
      yield put(setData(errData));
    }
    return;
  }

  if (destination === "swList") {
    newItems = result.swList;
  }

  const updatedData: ISWListData = {
    ...swListData,
    swList: newItems,
    totalCount: result.totalCount,
    loadOperation: undefined,
  };

  if (destination === "swList") {
    yield put(setData(updatedData));
  }
}

function* refreshSWListAsync(action: Action) {
  let destination: "swList" | "batchUpdates" = "swList";
  let filterDataList: ISWFilterData;
  let index: number = 0;
  let isSearchforRC: boolean = false;
  let isSearchRCForNotice: boolean = false;
  let rcSearchString: string = "";
  let rcNoticeSearchString: string = "";
  let rcType: RCTypes | undefined;
  let newItems: ISWSummaryItem[] = [];
  let searchSource: string = "";
  let isSourceSystemTIME: boolean = false;

  if (setBatchUpdatesSearchText.match(action)
    || applyBatchUpdatesFilter.match(action)
    || setBatchSort.match(action)) {
    destination = "batchUpdates";
  }

  if (setSearchRCNotice.match(action)) {
    isSearchRCForNotice = true;
    rcType = action.payload.rcType;
    rcNoticeSearchString = action.payload.searchText;
  }

  if (setRCSearchText.match(action)
    || (applyFilter.match(action)
      && action.payload.isRCFilter)) {
    isSearchforRC = true;
    isSourceSystemTIME = action.payload.isSourceSystemTIME;
    rcType = action.payload.rcTypeButton;
    if (setRCSearchText.match(action)) {
      rcSearchString = action.payload.searchText;
    }
    searchSource = action.payload.searchSource;
  }

  const state: RootState = yield select((store: RootState) => store);

  if (destination === "batchUpdates") {
    if (!state.batchUpdates.allowSearching) {
      yield put(setAllowSearching(true));
      return;
    }
    filterDataList = state.batchUpdates.filterData;

    // If the Batch Updates store has no filters,
    // don't search.
    if (getSWFilterCount(state
      .batchUpdates
      .filterData
      .filterFields, 0) === 0
      && !state.batchUpdates.filterData.filterFields.searchText) {
      return;
    }
  }
  else {
    filterDataList = state.swList.filterData
    index = yield select((store: RootState) => store.swList.swListData.skipIndex);
  }

  const { sortBy, sortDir } = filterDataList;

  const swListData = destination === "swList"
    ? state.swList.swListData
    : state.batchUpdates.swListData;

  const newData: ISWListData = {
    ...swListData,
    loadOperation: {
      isWorking: true,
      errorMessage: "",
      wasSuccessful: false,
    },
  };

  if (destination === "swList") {
    yield put(setData(newData));
  } else if (destination === "batchUpdates") {
    yield put(setBatchUpdatesData(newData));
  }

  let filterFields: ISWFilterFields = destination === "swList"
    ? state.swList.filterData.filterFields
    : state.batchUpdates.filterData.filterFields;

  if (isSearchforRC || isSearchRCForNotice) {
    filterFields = cloneDeep(filterFields);
    filterFields.searchText = rcSearchString || rcNoticeSearchString;
    filterFields.rcType = rcType;
  }

  if (destination === "batchUpdates"
    && state.auth.currentUser.roles.indexOf(Roles.SysAdmin) === -1) {
    // User is not a sys admin. Fake-add their assigned orgs
    // as org filters.
    filterFields = cloneDeep(filterFields);
  }

  let result: ISWListData;
  let oldResults = swListData.swList;
  try {
    if (!isSourceSystemTIME) {
      result = yield call(SWApi.getSWList,
        filterFields,
        (destination === "batchUpdates" || isSearchforRC || isSearchRCForNotice),
        destination !== "batchUpdates",
        sortBy,
        sortDir,
        index,
        isSearchforRC,
        isSearchRCForNotice,
        searchSource);
    }
    else {
      result = yield call(SWApi.getTIMESWList,
        filterFields);
    }

  } catch (err: any) {
    let errData: ISWListData = {
      ...swListData,
      loadOperation: {
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
        wasSuccessful: false,
      },
    };
    if (isSourceSystemTIME) {
      errData = {
        swList: [],
        totalCount: 0,
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
          wasSuccessful: false,
        },
      };

      if (err instanceof ResponseError && err.isUnauthorized()) {
        yield put(
          showErrorToast(err.message)
        );
      } else {
        yield put(
          showErrorToast("An error occurred while getting data from TIME search Api. Please contact Administrator.")
        )
      }
    }
    if (destination === "swList") {
      yield put(setData(errData));
    } else if (destination === "batchUpdates") {
      yield put(setBatchUpdatesData(errData));
    }
    return;
  }

  if (destination === "swList") {
    if (index !== 0) {
      newItems = [...oldResults, ...result.swList];
    }
    else {
      newItems = result.swList;
    }
  }
  else {
    newItems = result.swList;
  }

  const updatedData: ISWListData = {
    ...swListData,
    swList: newItems,
    totalCount: result.totalCount,
    loadOperation: undefined,
  };

  if (destination === "swList") {
    yield put(setData(updatedData));
  } else if (destination === "batchUpdates") {
    yield put(setBatchUpdatesData(updatedData));
  }
}

function* createLCLAsync(action: Action) {
  if (!confirmCreateLCL.match(action)) {
    return;
  }

  yield put(
    setCreateLCLData({
      guid: action.payload.swGuid,
      version: action.payload.swVersion,
      isWaitingForConfirm: false,
      createLCLOperation: {
        isWorking: true,
      },
    })
  );

  try {
    let swGuid: string = yield call(
      SWApi.createLCL,
      action.payload.swGuid,
      action.payload.swVersion,
    );

    yield put(
      setCreateLCLData({
        guid: swGuid,
        version: 0,
        isWaitingForConfirm: false,
        createLCLOperation: {
          isWorking: false,
          wasSuccessful: true,
        },
      })
    );
  } catch (err: any) {
    yield put(
      showErrorToast("Failed to create Local Checklist: " + getResponseErrorMessage(err))
    );

    yield put(
      setCreateLCLData({
        guid: action.payload.swGuid,
        version: action.payload.swVersion,
        isWaitingForConfirm: false,
        createLCLOperation: undefined,
      })
    );
  }
}

function* createSWDraftAsync(action: Action) {
  if (!confirmCreateDraft.match(action)) {
    return;
  }

  yield put(
    setCreateDraftData({
      ...action.payload,
      isWaitingForConfirm: false,
      createDraftOperation: {
        isWorking: true,
      },
    })
  );

  try {
    let swGuid: string = yield call(
      SWApi.createSWDraft,
      action.payload,
    );

    yield put(
      setCreateDraftData({
        ...action.payload,
        guid: swGuid,
        version: 0,
        isWaitingForConfirm: false,
        createDraftOperation: {
          isWorking: false,
          wasSuccessful: true,
        },
      })
    );
  } catch (err: any) {
    const errorMessage = getResponseErrorMessage(err);

    if (errorMessage.includes("[STALE_RC_WARNING]")) {
      yield put(
        setCreateDraftData({
          ...action.payload,
          isWaitingForConfirm: "staleReusableContent",
          createDraftOperation: {
            isWorking: false,
            wasSuccessful: false,
          },
        })
      );

      return;
    }

    yield put(showErrorToast("Failed to create draft: " + errorMessage));

    yield put(
      setCreateDraftData({
        ...action.payload,
        isWaitingForConfirm: false,
        createDraftOperation: undefined,
      })
    );
  }
}

function* bulkDeleteSWDraftAsync(action: Action) {
  if (!confirmBulkDeleteDraft.match(action)) {
    return;
  }

  yield put(
    setBulkDeleteDraftData({ bulkDeleteOperation: { isWorking: true } })
  );

  try {
    yield call(SWApi.bulkDeleteSWDraft, action.payload);
    yield put(showSuccessToast("Draft deleted successfully."));
    yield put(clearDownloadZip());
  } catch (error) {
    yield put(showErrorToast(`${getResponseErrorMessage(error)}`));
  } finally {
    yield put(
      setBulkDeleteDraftData({ bulkDeleteOperation: { isWorking: false } })
    );
    yield put(getRefreshSearchData());
  }
}

function* deleteSWDraftAsync(action: Action) {
  if (!confirmDeleteDraft.match(action)) {
    return;
  }

  yield put(
    setDeleteDraftData({
      guid: action.payload.swGuid,
      currentEditor: action.payload.currentEditor,
      currentEditorLockedDate: action.payload.currentEditorLockedDate,
      isWaitingForConfirm: false,
      deleteOperation: {
        isWorking: true,
      },
    })
  );

  try {
    yield call(
      SWApi.deleteSWDraft,
      action.payload.swGuid,
    );

    yield put(
      setDeleteDraftData({
        guid: action.payload.swGuid,
        currentEditor: action.payload.currentEditor,
        currentEditorLockedDate: action.payload.currentEditorLockedDate,
        isWaitingForConfirm: false,
        deleteOperation: {
          isWorking: false,
          wasSuccessful: true,
        },
      })
    );
    yield put(showSuccessToast("Draft deleted successfully."));
  } catch (err: any) {
    yield put(
      showErrorToast("Failed to delete draft: " + getResponseErrorMessage(err))
    );

    yield put(
      setDeleteDraftData({
        guid: action.payload.swGuid,
        currentEditor: action.payload.currentEditor,
        currentEditorLockedDate: action.payload.currentEditorLockedDate,
        isWaitingForConfirm: false,
        deleteOperation: undefined,
      })
    );
  }
}

function* archiveSWAsync(action: Action) {
  if (!confirmArchive.match(action)) {
    return;
  }

  yield put(
    setArchiveData({
      guid: action.payload.swGuid,
      isWaitingForConfirm: false,
      archiveOperation: {
        isWorking: true,
      },
    })
  );

  try {
    yield call(
      SWApi.archiveSW,
      action.payload.swGuid,
    );

    yield put(
      setArchiveData({
        guid: action.payload.swGuid,
        isWaitingForConfirm: false,
        archiveOperation: {
          isWorking: false,
          wasSuccessful: true,
        },
      })
    );
    yield put(showSuccessToast("Archive successful."));
  } catch (err: any) {
    yield put(
      showErrorToast("Failed to archive Standard Work: " + getResponseErrorMessage(err))
    );

    yield put(
      setArchiveData({
        guid: action.payload.swGuid,
        isWaitingForConfirm: false,
        archiveOperation: undefined,
      })
    );
  }
}

function* getRCWhereUsedItemsAsync(action: Action) {
  if (!getRCWhereUsedItems.match(action)) {
    return;
  }

  yield put(
    setRCWhereUsedItems({
      rcMapping: [],
      loadRCMappingData: {
        isWorking: true,
      }
    })
  );

  try {
    let response: IGetRCMapping[] = yield call(SWApi.getRCWhereUsed, action.payload.rcID);
    yield put(
      setRCWhereUsedItems({
        rcMapping: response,
        loadRCMappingData: {
          isWorking: false,
          wasSuccessful: true,
        }
      })
    );
  } catch (err: any) {
    yield put(
      showErrorToast("Failed to get Where used data: " + getResponseErrorMessage(err))
    );
  }
}

function* getPDFAsync(action: Action) {
  if (!getPDF.match(action)) {
    return;
  }

  try {
    const pdfResponse: IPDFDownloadResponse = yield call(
      SWApi.downloadPDF,
      action.payload.swId,
      action.payload.pdfType,
      action.payload.generateDefault,
      action.payload.getLatestVersion,
    );

    yield put(
      setPDFData({
        pdfBlob: pdfResponse.blob,
        filename: pdfResponse.filename,
        getPdfOperation: {
          isWorking: false,
          wasSuccessful: true
        },
      })
    );
  } catch (err: any) {
    yield put(
      showErrorToast("Failed to generate PDF for Standard Work: " + getResponseErrorMessage(err))
    );

    yield put(
      setPDFData({
        pdfBlob: undefined,
        filename: undefined,
        getPdfOperation: {
          isWorking: false,
          wasSuccessful: false,
        },
      })
    );
  }
}

function* getSWUserFeedbacksAsync(action: Action) {
  if (!loadSWUserFeedbacks.match(action)) {
    return;
  }

  yield put(
    setSWUserFeedbacks({
      swUserFeedbackDetails: {
        title: undefined,
        swGuid: undefined,
        currentVersion: undefined,
        userFeedbacks: [],
        userFeedbacksExport: [],
      },
      loadUserFeedbacksOperation: {
        isWorking: true,
      },
    })
  );

  try {
    let userFeedbackDetails: ISWUserFeedbackDetails = yield call(
      SWApi.getSWUserFeedbacks,
      action.payload.swGuid,
    );

    yield put(
      setSWUserFeedbacks({
        swUserFeedbackDetails: userFeedbackDetails,
        loadUserFeedbacksOperation: {
          isWorking: false,
          wasSuccessful: true,
        },
      })
    );
    if (userFeedbackDetails.userFeedbacks
      && userFeedbackDetails.userFeedbacks.length === 0) {
      yield put(
        showInfoToast("User feedbacks are not found for this Standard Work.")
      );
    }
  } catch (err: any) {
    yield put(
      showErrorToast("Failed to load Standard Work user feedbacks: " + getResponseErrorMessage(err))
    );
    yield put(
      setSWUserFeedbacks({
        swUserFeedbackDetails: {
          title: undefined,
          swGuid: undefined,
          currentVersion: undefined,
          userFeedbacks: [],
          userFeedbacksExport: [],
        },
        loadUserFeedbacksOperation: undefined,
      })
    );
  }
}

function* getTimeRCProcessingRequestStatusAsync(action: Action) {
  if (!getTimeRCProcessingRequestStatus.match(action)) {
    return;
  }
  try {
    let timeRCProcessing: ITimeRCProcessing = yield call(
      SWApi.getTimeRCProcessingRequestStatus,
      action.payload.requestIds,
    );

    for (const element of timeRCProcessing.timeRCProcessingRequestsStatus) {
      if (element.status.toLocaleLowerCase() === 'completed') {
        yield put(removeTimeRCRequestData({
          requestId: element.requestId
        }));
      }
      if (element.status.toLocaleLowerCase() === 'failed') {
        yield put(removeTimeRCRequestData({
          requestId: element.requestId
        }));
        yield put(
          showErrorToast("An error occured while adding the TIME RC to TLM SWI: " + element.log)
        );
      }
    }
  } catch (err: any) {
    yield put(
      showErrorToast("Failed to get TimeRCProcessingRequestStatus: " + getResponseErrorMessage(err))
    );

  }
}

function* searchConnectedSWIsByChildGuidAsync(action: Action) {
  if (!searchConnectedSWIsByChildGuid.match(action)) {
    return;
  }

  yield put(
    setConnectedSWIs({
      items: [],
      loadOperation: {
        isWorking: true,
      },
    }));

  try {
    let connectedSWIs: IMasterDataItem[] = yield call(
      SWApi.getConnectedSWIsByChildGuid,
      action.payload.searchTerm,
    );
    
    yield put(
      setConnectedSWIs({
        items: connectedSWIs,
        loadOperation: {
          isWorking: false,
          wasSuccessful: true,
        },
      })
    );
  } catch (err: any) {
    yield put(
      showErrorToast("Failed to get connected SWIs: " + getResponseErrorMessage(err))
    );

    yield put(
      setConnectedSWIs({
        items: [],
        loadOperation: {
          isWorking: false,
          wasSuccessful: false,
        },
      })
    );
  }
}

function* saveSWUserFeedbackAcksAsync(action: Action) {
  if (!saveSWUserFeedbackAcks.match(action)) {
    return;
  }

  yield put(
    setSWUserFeedbackAcks({
      swUserFeedbackAcks: undefined,
      saveUserFeedbacksOperation: {
        isWorking: true,
      },
    })
  );

  try {
    let feedbackAckSaved: boolean = yield call(
      SWApi.saveSWUserFeedbackAcks,
      action.payload.swGuid,
      action.payload.swUserFeedbackAcks,
    );

    if (!feedbackAckSaved) {
      yield put(
        showInfoToast("An error occurred while saving user feedback responses.")
      );
    }
    else {
      let userFeedbackDetails: ISWUserFeedbackDetails = yield call(
        SWApi.getSWUserFeedbacks,
        action.payload.swGuid,
      );

      yield put(
        setSWUserFeedbacks({
          swUserFeedbackDetails: userFeedbackDetails,
          loadUserFeedbacksOperation: {
            isWorking: false,
            wasSuccessful: true,
          },
        })
      );

      yield put(
        setSWUserFeedbackAcks({
          swUserFeedbackAcks: feedbackAckSaved,
          saveUserFeedbacksOperation: {
            isWorking: false,
            wasSuccessful: true,
          },
        })
      );

      yield put(
        showSuccessToast("The feedback responses are submitted successfully.")
      );
    }
  } catch (err: any) {
    yield put(
      showInfoToast("An error occurred while saving user feedback responses. " + getResponseErrorMessage(err))
    );
    yield put(
      setSWUserFeedbackAcks({
        swUserFeedbackAcks: false,
        saveUserFeedbacksOperation: undefined,
      })
    );
  }
}