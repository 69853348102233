import React, { useState } from "react";
import "./ManageImagesModal.scoped.scss";
import 'react-html5-camera-photo/build/css/index.css';
import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import AddImageButton from "./AddImageButton";
import { validateAndReadFile } from "utilities/fileUtilities";
import { cloneDeep } from "lodash";
import ImagePreview from "./ImagePreview";
import CameraModal from "./CameraModal";
import { IFileData } from "interfaces/files/fileInterfaces";

interface IManageImagesModalProps {
  onClose(): void,
  disabled: boolean,
  imageCount(count: number): void,
}

interface IImagesProps {
  fileName: string,
  dataUri: string,
}

const ManageImagesModal: React.FC<IManageImagesModalProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [imageFilenames, setImageFilenames] = useState<IImagesProps[]>([]);

  let mainComponent: JSX.Element | undefined = undefined;
  let modalControls: JSX.Element | undefined = undefined;

  const onRemoveImage = (filename: string) => {
    if (!imageFilenames) {
      return;
    }

    let images = cloneDeep(imageFilenames
      .filter(x => x.fileName !== filename));
    setImageFilenames(images);
    props.imageCount(images.length);
  }

  const onPhotoTaken = (fileData: IFileData) => {
    const image: IImagesProps = {
      fileName: fileData.filename,
      dataUri: fileData.dataUri,
    };
    let images = cloneDeep(imageFilenames);
    images.push(image);
    setImageFilenames(images);
    setIsCameraVisible(false);
    props.imageCount(images.length);
  }

  const onFileChosen = async (event: React.FormEvent<HTMLInputElement>) => {
    let tgt = event.target as HTMLInputElement;

    if (!tgt || !tgt.files || !tgt.files.length) {
      return;
    }

    try {
      const fileData = await validateAndReadFile(tgt.files[0]);
      const image: IImagesProps = {
        fileName: fileData.filename,
        dataUri: fileData.dataUri,
      };
      let images = cloneDeep(imageFilenames);
      images.push(image);
      setImageFilenames(images);
      props.imageCount(images.length);
    } catch (err: any) {
      setErrorMessage(err.message || err);
    }
  }

  const onClose = () => {
    props.onClose();
    props.imageCount(0);
  }

  if (!isCameraVisible) {
    mainComponent = (
      <>
        <div className="image-list">
          {imageFilenames
            && imageFilenames.map(image =>
              <ImagePreview
                key={image.fileName}
                filename={image.fileName}
                dataUri={image.dataUri}
                onRemove={() => onRemoveImage(image.fileName)}
                disabled={props.disabled}
              />
            )}
          {!props.disabled && (!imageFilenames || imageFilenames?.length < 5) &&
            <AddImageButton
              onShowCamera={() => setIsCameraVisible(true)}
              onFileChosen={onFileChosen}
            />
          }
        </div>
        {errorMessage &&
          <Banner type={BannerType.Error}>
            {errorMessage}
          </Banner>
        }
        <div>
          {imageFilenames
            && imageFilenames.length >= 5
            && <span className="restrict-message">Only 5 images can be uploaded.</span>
          }
        </div>
      </>
    );

    modalControls = (
      <button
        className="primary-button"
        onClick={onClose}
      >
        OK
      </button>
    );
  } else {
    mainComponent = (
      <CameraModal
        onPhotoConfirmed={onPhotoTaken}
        onClose={() => setIsCameraVisible(false)}
      />
    )
  }

  return (
    <Modal
      isOpen={true}
      header="Picture Input"
      controls={modalControls}
    >
      {mainComponent}
    </Modal>
  )
}

export default ManageImagesModal;