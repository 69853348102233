import React from "react";
import "./ExecutionToolbarButton.scoped.scss";

interface IExecutionToolbarButtonProps {
  iconUrl: string,
  text: string,
  isActive: boolean,
  onClick(): void,
}

const ExecutionToolbarButton: React.FC<IExecutionToolbarButtonProps> = ({ iconUrl, text, isActive, onClick }) =>
  <div
    className={"item tab " + (isActive ? "active" : "")}
    onClick={onClick}
  >
    <img
      src={iconUrl}
      alt={text}
      className="icon-medium"
    />
    <span>
      {text}
    </span>
  </div>;

export default ExecutionToolbarButton;