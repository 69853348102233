import { IPhotoInputComponent } from "interfaces/sw/SWInterfaces";
import React, { useState } from "react";
import "./PhotoInput.scoped.scss";
import cameraIcon from "media/icons/custom/camera.svg";
import ManageImagesModal from "./ManageImagesModal";

interface IPhotoInputProps {
  component: IPhotoInputComponent,
  isDisabled: boolean,
}

const PhotoInput: React.FC<IPhotoInputProps> = ({ component, isDisabled }) => {
  const [isChoosePicModalVisible, setChoosePicModalVisible] = useState(false);
  const [compResponseValues, setCompResponseValues] = useState(0);

  const showChoose = () => setChoosePicModalVisible(true);
  const hideChoose = () => setChoosePicModalVisible(false);

  let previewBarContent: JSX.Element | undefined;

  if (compResponseValues > 0) {
    previewBarContent = (
      <span>
        {`${compResponseValues} ${compResponseValues > 1 ? 'images' : 'image'}`}
      </span>
    );
  } else {
    previewBarContent = (
      <span>
        {'No images selected'}
      </span>
    );
  }

  return (
    <>
      {component.label &&
        <div className="input-header">
          <label>{component.label}</label>
        </div>
      }
      <div className="row">
        <div
          className="icon-holder"
        >
          <img
            src={cameraIcon}
            alt=""
          />
        </div>
        <div
          onClick={showChoose}
          className="preview-bar">
          {previewBarContent}
        </div>
        {
          <button
            onClick={!isDisabled ? showChoose : undefined}
            disabled={isDisabled}
            className="primary-button choose-button">
            {'Choose'}
          </button>
        }
      </div>
      {isChoosePicModalVisible &&
        <ManageImagesModal
          onClose={hideChoose}
          disabled={isDisabled}
          imageCount={setCompResponseValues}
        />
      }
    </>
  );
}

export default PhotoInput;