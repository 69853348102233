import Modal from "components/common/Modal";
import React, { FC } from "react";
import RoutingDiagramViewer from "./RoutingDiagramViewer";

import {
  IOperationTreeModel,
  IOperationTreeNode,
} from "store/masterData/masterDataTypes";

interface SelectRoutingOperationModalProps {
  isOpen: boolean;
  operationTree: IOperationTreeModel;
  onCanceled?(): void;
  onOperationClicked?(operation: IOperationTreeNode): void;
  title?: string;
  cancelText?: string;
}

const SelectRoutingOperationModal: FC<SelectRoutingOperationModalProps> = ({
  isOpen,
  operationTree,
  onCanceled,
  onOperationClicked,
  title,
  cancelText
}) => {
  return (
    <Modal
      isOpen={isOpen}
      controls={[
        <button className="primary-button" onClick={onCanceled} key={0}>
          {cancelText ?? "Cancel"}
        </button>,
      ]}
      top={title ?? "Select Operation"}
    >
      <RoutingDiagramViewer
        operationTree={operationTree}
        onOperationClicked={onOperationClicked}
      />
    </Modal>
  );
};

export default SelectRoutingOperationModal;
