import React from "react";
import "./ToastContainer.scoped.scss";
import useSelector from "store/useSelector";
import ToastMessage from "./ToastMessage";

const ToastContainer: React.FC = () => {
  const { toasts } = useSelector(store => store.toasts);

  if (!toasts.length) {
    return null;
  }

  return (
    <div className="toast-container">
      {toasts.map(t => 
        <ToastMessage
          {...t}
          key={t.id}
        />
      )}
    </div>
  );
}

export default ToastContainer;