import React from "react";
import { useDispatch } from "react-redux";
import Modal from "components/common/Modal";
import {
  cancelCreateLCL,
  confirmCreateLCL,
} from "store/swList/swListActions";

interface ICreateLCLModal {
  swGuid: string,
  swVersion: number,
}

const CreateLCLModal: React.FC<ICreateLCLModal> = (props) => {
  const dispatch = useDispatch();

  return (
    <Modal
      header="Create Local Checklist"
      controls={
        <>
          <button
            className="primary-button"
            onClick={() =>
              dispatch(
                confirmCreateLCL({
                  swGuid: props.swGuid,
                  swVersion: props.swVersion,
                })
              )
            }
          >
            Create Local Checklist
          </button>
          <button
            className="secondary-button"
            onClick={() => dispatch(cancelCreateLCL())}
          >
            Cancel
          </button>
        </>
      }
      isOpen={true}
    >
      <p>Are you sure you want to create a new Local Checklist?</p>
    </Modal>
  );
};

export default CreateLCLModal;
