import React from "react";
import { UserGroups } from "interfaces/user/UserInterfaces";
import "./UserGroupIcon.scoped.scss";

interface IUserGroupIconProps {
    userGroup: UserGroups,
}

const UserGroupIcon: React.FC<IUserGroupIconProps> = ({ userGroup }) => {
    return (<div className={`hexagon ${userGroup}`}>
        <label>{userGroup.toString()}</label>
    </div>);
}

export default UserGroupIcon;