import React from "react";
import { IVideoInputComponent } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { updateVideoInput } from "store/manageSW/manageSWActions";
import "./ComponentEditor.scss";

interface IVideoInputEditorProps {
  videoInput: IVideoInputComponent,
  allowEdit: boolean,
  stepGuid: string,
  labelText: string,
}

const VideoInputEditor: React.FC<IVideoInputEditorProps> = ({
  videoInput,
  allowEdit,
  stepGuid,
  labelText,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="component-editor">
      <label className="mandatory block">{labelText}</label>
      {allowEdit &&
        <input
          type="text"
          defaultValue={videoInput.label}
          onBlur={(e) => dispatch(updateVideoInput({
            stepGuid,
            component: {
              ...videoInput,
              label: e.target.value,
            },
          }))}
        />
      }
      {!allowEdit &&
        <span>{videoInput.label}</span>
      }
    </div>
  );
}

export default VideoInputEditor;