import React, { useCallback, useEffect, useState } from "react";
import "./StepList.scoped.scss";
import { IStep, SWTypes, StepComponentTypes, StepTypes } from "interfaces/sw/SWInterfaces";
import Nestable, { Item } from "react-nestable";
import { cloneDeep } from "lodash";
import dragIcon from "media/icons/dls/drag.svg";
import arrowRight from "media/icons/dls/arrow-right-3.svg";
import { findStep } from "store/manageSW/manageSWHelpers";
import { useDispatch } from "react-redux";
import { updateStepSortOrder } from "store/manageSW/manageSWActions";
import useSelector from "store/useSelector";
import { RCTypes } from "interfaces/sw/SWInterfaces";
import { showErrorToast } from "store/toast/toastActions";

interface IStepListProps {
  steps: IStep[],
  ancestors: IStep[],
  isDisabled: boolean,
  maxDepth: number,
  onStepClick(ancestorGuids: string[], stepGuid: string): void,
  onStepToggle(stepGuid: string, isOpen: boolean): void,
}

const StepList: React.FC<IStepListProps> = ({
  steps,
  ancestors,
  isDisabled,
  maxDepth,
  onStepToggle,
  onStepClick,
}) => {
  const dispatch = useDispatch();
  const parentOrders = ancestors.map(st => st.sortOrder);
  const [sortables, setSortables] = useState<Item[]>([]);
  const [prevItems, setPrevItems] = useState<Item[]>([]);
  const {
    manageSW: {
      stepVisibilities,
      enableFormulaComponentValue,
      SW: {
        type: swType,
        rcType,
      },
    },
  } = useSelector(store => store);

  useEffect(() => {
    setSortables(cloneDeep(steps.map((item, pIndex) => ({
      id: item.guid,
      text: item.title,
      hasUnResolvedComments: item.hasUnResolvedComments,
      isTask: item.isTask,
      stepType: item.stepType,
      isInTask: item.isTask,
      isRCTask: item.isRCTask,
      stepNumber: item.stepNumber,
      rcContainer: item.rcContainer,
      isRCStep: item.isRCStep,
      hasMasterStep: item.masterStepId != null,
      children: item.children.map((child, cIndex) => ({
        id: child.guid,
        text: child.title,
        nestedIn: item.guid,
        hasUnResolvedComments: child.hasUnResolvedComments,
        isTask: false,
        stepType: child.stepType,
        isInTask: item.isTask,
        hasMasterStep: child.masterStepId != null,
        parentIndex: (pIndex + 1).toString() + ".",
        rcContainer: child.rcContainer,
        stepNumber: child.stepNumber,
        isRCStep: child.isRCStep,
        children: child.children.map((grandchild, gIndex) => ({
          id: grandchild.guid,
          text: grandchild.title,
          nestedIn: child.guid,
          hasUnResolvedComments: grandchild.hasUnResolvedComments,
          isTask: false,
          isInTask: item.isTask,
          stepType: grandchild.stepType,
          hasMasterStep: grandchild.masterStepId != null,
          parentIndex: (pIndex + 1).toString() + "." + (cIndex + 1).toString() + ".",
          stepNumber: grandchild.stepNumber,
          rcContainer: grandchild.rcContainer,
          isRCStep: grandchild.isRCStep,
          children: grandchild.children.map((gGrandChild, ggIndex) => ({
            id: gGrandChild.guid,
            text: gGrandChild.title,
            nestedIn: grandchild.guid,
            hasUnResolvedComments: gGrandChild.hasUnResolvedComments,
            isTask: false,
            isInTask: item.isTask,
            stepType: gGrandChild.stepType,
            hasMasterStep: gGrandChild.masterStepId != null,
            parentIndex: (pIndex + 1).toString() + "." + (cIndex + 1).toString() + "." + (gIndex + 1).toString() + ".",
            stepNumber: gGrandChild.stepNumber,
            isRCStep: gGrandChild.isRCStep,
            rcContainer: gGrandChild.rcContainer,
            children: gGrandChild.children.map((ggGrandChild) => ({
              id: ggGrandChild.guid,
              text: ggGrandChild.title,
              nestedIn: gGrandChild.guid,
              hasUnResolvedComments: ggGrandChild.hasUnResolvedComments,
              isTask: false,
              stepType: ggGrandChild.stepType,
              isInTask: item.isTask,
              hasMasterStep: ggGrandChild.masterStepId != null,
              parentIndex: (pIndex + 1).toString() + "." + (cIndex + 1).toString() + "." + (gIndex + 1).toString() + "." + (ggIndex + 1).toString() + ".",
              stepNumber: ggGrandChild.stepNumber,
              isRCStep: ggGrandChild.isRCStep,
              rcContainer: gGrandChild.rcContainer,
            })),
            isConditional: gGrandChild.isConditional,
          })),
          isConditional: grandchild.isConditional,
        })),
        isConditional: child.isConditional,
      })),
      nestedIn: "",
      isConditional: item.isConditional,
    }))));
  }, [steps]);

  const hasFormulas = useCallback(() => {
    return steps.some((step) =>
      step.components.some(
        (component) => component.type === StepComponentTypes.FormulaNumerical
      )
    );
  }, [steps]);

  function isNonTaskAtRoot(items: Item[], draggedItem: Item) {
    let nestedIn = draggedItem.nestedIn;
    let isAtRoot = (nestedIn ?? "").length === 0;
    return items.some(item => (item.id === draggedItem.id) && (!isAtRoot));
  }
  const onReorder = (items: Item[], dragItem: Item) => {
    const isTaskLevelStep = swType === SWTypes.TLMRC
      || swType === SWTypes.TLMSWI
      || swType === SWTypes.MFGSWI;
    
    const revertOrdering = isTaskLevelStep
                          && isNonTaskAtRoot(items, dragItem);
    let isReverted = false;

    if (revertOrdering) {
      isReverted = true;
      if(prevItems.length > 0){
        items = prevItems;
      }
      else if(sortables.length > 0){
        items = cloneDeep(sortables);
      }
    }
    // Apply the sort order
    let trcCounter: number = 0;
    for (let pIndex = 0; pIndex < items.length; pIndex++) {
      const parent = items[pIndex];
      if (parent.rcContainer && parent.children) {

        for (let prcIndex = 0; prcIndex < parent.children.length; prcIndex++) {
          trcCounter++;
          //parent.sortOrder = trcCounter;
          const prc = parent.children[prcIndex];
          prc.stepNumber = trcCounter.toString();

          // child
          if (prc.children && prc.children.length) {
            for (let l = 0; l < prc.children.length; l++) {
              const ggChild = prc.children[l];
              const ggstepNo = (prc.stepNumber + "." + (l + 1)).toString();
              ggChild.stepNumber = ggstepNo;

              if (ggChild.children && ggChild.children.length) {

                for (let m = 0; m < ggChild.children.length; m++) {
                  const gggChild = ggChild.children[m];
                  const gggstepNo = (ggstepNo + "." + (m + 1)).toString();
                  gggChild.stepNumber = gggstepNo;

                  if (gggChild.children && gggChild.children.length) {

                    for (let n = 0; n < gggChild.children.length; n++) {
                      const ggggChild = gggChild.children[n];
                      const ggggstepNo = (gggstepNo + "." + (n + 1)).toString();
                      ggggChild.stepNumber = ggggstepNo;

                      if (ggggChild.children && ggggChild.children.length) {

                        for (let o = 0; o < ggggChild.children.length; o++) {
                          const gggggChild = ggggChild.children[o];
                          const gggggstepNo = (ggggstepNo + "." + (0 + 1)).toString();
                          gggggChild.stepNumber = gggggstepNo;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      else {
        trcCounter++;
        parent.stepNumber = (trcCounter).toString();
        let rcCounter: number = 0;
        if (parent.children) {
          for (let cIndex = 0; cIndex < parent.children.length; cIndex++) {
            const child = parent.children[cIndex];
            if (child.rcContainer && child.children) {
              for (let gcIndex = 0; gcIndex < child.children.length; gcIndex++) {
                rcCounter++;
                const gChild = child.children[gcIndex];
                gChild.stepNumber = parent.stepNumber + '.' + (rcCounter).toString();

                // grand child and gg child
                if (gChild.children) {
                  for (let l = 0; l < gChild.children.length; l++) {
                    const ggChild = gChild.children[l];
                    const ggstepNo = (gChild.stepNumber + "." + (l + 1)).toString();
                    ggChild.stepNumber = ggstepNo;

                    if (ggChild.children && ggChild.children.length) {

                      for (let m = 0; m < ggChild.children.length; m++) {
                        const gggChild = ggChild.children[m];
                        const gggstepNo = (ggstepNo + "." + (m + 1)).toString();
                        gggChild.stepNumber = gggstepNo;
                      }
                    }
                  }
                }
              }
            } else {
              rcCounter++;
              child.stepNumber = parent.stepNumber + '.' + (rcCounter).toString();
              if (child.children) {
                for (let l = 0; l < child.children.length; l++) {
                  const ggChild = child.children[l];
                  const ggstepNo = (child.stepNumber + "." + (l + 1)).toString();
                  ggChild.stepNumber = ggstepNo;

                  if (ggChild.children && ggChild.children.length) {

                    for (let m = 0; m < ggChild.children.length; m++) {
                      const gggChild = ggChild.children[m];
                      const gggstepNo = (ggstepNo + "." + (m + 1)).toString();
                      gggChild.stepNumber = gggstepNo;
                    }
                  }
                }
              }

            }

          }
        }
      }

    }
    const clonedItems = cloneDeep(items.map((item, pIndex) => ({
      id: item.id,
      text: item.text,
      nestedIn: "",
      isConditional: item.isConditional,
      hasUnResolvedComments: item.hasUnResolvedComments,
      isTask: item.isTask,
      stepType: item.stepType,
      isInTask: item.isInTask,
      isRCTask: item.isRCTask,
      stepNumber: item.stepNumber,
      rcContainer: item.rcContainer,
      isRCStep: item.isRCStep,
      children: item.children?.map((child: any, cIndex: number) => ({
        id: child.id,
        text: child.text,
        nestedIn: item.id,
        isConditional: child.isConditional,
        hasUnResolvedComments: child.hasUnResolvedComments,
        isTask: child.isInTask,
        stepType: child.stepType,
        isInTask: child.isInTask,
        parentIndex: (pIndex + 1).toString() + ".",
        stepNumber: child.stepNumber,
        rcContainer: child.rcContainer,
        isRCStep: child.isRCStep,
        children: child.children?.map((grandchild: any, gIndex: number) => ({
          id: grandchild.id,
          text: grandchild.text,
          nestedIn: child.id,
          isConditional: grandchild.isConditional,
          hasUnResolvedComments: grandchild.hasUnResolvedComments,
          isTask: grandchild.isTask,
          stepType: grandchild.stepType,
          isInTask: grandchild.isInTask,
          isRCStep: grandchild.isRCStep,
          parentIndex: (pIndex + 1).toString() + "." + (cIndex + 1).toString() + ".",
          stepNumber: grandchild.stepNumber,
          children: grandchild.children?.map((gGrandchild: any) => ({
            id: gGrandchild.id,
            text: gGrandchild.text,
            nestedIn: grandchild.id,
            hasUnResolvedComments: gGrandchild.hasUnResolvedComments,
            isTask: gGrandchild.isTask,
            stepType: gGrandchild.stepType,
            isInTask: gGrandchild.isInTask,
            isRCStep: gGrandchild.isRCStep,
            parentIndex: (pIndex + 1).toString() + "." + (cIndex + 1).toString() + "." + (gIndex + 1).toString() + ".",
            stepNumber: gGrandchild.stepNumber,
            isConditional: gGrandchild.isConditional
          })),
        })),
      })),
    })));
    setPrevItems(clonedItems);
    newStepsFunction(clonedItems, isReverted);
  }

  const newStepsFunction = (items: Item[],isReverted:boolean) => {
    let stepTypeFlag = false;
    const newSteps: IStep[] = [];
    items.forEach((item, index) => {
      let [step] = findStep(item.id, steps, 0);
      const newStep = cloneDeep(step);

      if (newStep) {
        const newChildrenList: IStep[] = [];
        newStep.sortOrder = index + 1;
        newStep.parentGuid = null;
        newStep.hasUnResolvedComments = item.hasUnResolvedComments;
        newStep.stepNumber = item.stepNumber;

        //to set first level on reordering in tlmrc and tlmswi 
        if (swType == SWTypes.TLMRC) {
          if (rcType == RCTypes.Task) {
            newStep.stepType = StepTypes.Task;
          }
          else if (rcType == RCTypes.Step) {
            newStep.stepType = StepTypes.Step;
          }
          else {
            newStep.stepType = StepTypes.SubStep;
          }
          stepTypeFlag = true;
        }
        else if (swType == SWTypes.TLMSWI) {
          newStep.stepType = StepTypes.Task;
          stepTypeFlag = true;
        }

        item.children?.forEach((citem: any, cindex: number) => {
          let [son] = findStep(citem.id, steps, 0);
          const newSon = cloneDeep(son);
          if (newSon) {
            newSon.sortOrder = cindex + 1;
            newSon.parentGuid = citem.nestedIn || null;
            newSon.hasUnResolvedComments = citem.hasUnResolvedComments;
            newSon.stepNumber = citem.stepNumber;
            //if flag is set then update the child level accordingly on reordering
            if (stepTypeFlag) {
              if (newStep.rcContainer) {
                newSon.stepType = newStep.stepType;
              } else {
                newSon.stepType = newStep.stepType == StepTypes.Task ? StepTypes.Step : StepTypes.SubStep;
              }
            }

            const newGrandchildren: IStep[] = [];

            citem.children?.forEach((gitem: any, gindex: number) => {
              let [grandSon] = findStep(gitem.id, steps, 0);
              const newgrandSon = cloneDeep(grandSon);
              if (newgrandSon) {
                newgrandSon.sortOrder = gindex + 1;
                newgrandSon.parentGuid = gitem.nestedIn || null;
                newgrandSon.hasUnResolvedComments = gitem.hasUnResolvedComments;
                newgrandSon.stepNumber = gitem.stepNumber;
                if (stepTypeFlag) {
                  newgrandSon.stepType = newStep.stepType == StepTypes.Task ? StepTypes.SubStep : undefined;
                }
                const newGreatGrandchildren: IStep[] = [];
                gitem.children?.forEach((gGItem: any, gGIndex: number) => {
                  let [greatGrandSon] = findStep(gGItem.id, steps, 0);
                  const newgreatGrandSon = cloneDeep(greatGrandSon);
                  if (newgreatGrandSon) {
                    newgreatGrandSon.sortOrder = gGIndex + 1;
                    newgreatGrandSon.parentGuid = gGItem.nestedIn || null;
                    newgreatGrandSon.hasUnResolvedComments = gGItem.hasUnResolvedComments;
                    newgreatGrandSon.stepNumber = gGItem.stepNumber;
                    newGreatGrandchildren.push(newgreatGrandSon);
                  }
                })

                newgrandSon.children = newGreatGrandchildren;
                newGrandchildren.push(newgrandSon);
              }
            })
            newSon.children = newGrandchildren;
            newChildrenList.push(newSon);
          }
        })

        newStep.children = newChildrenList;
        newSteps.push(newStep);
      }
    });
    if(!isReverted){
    dispatch(updateStepSortOrder(newSteps));
    }
  };

  const confirmChange = (dragItem: Item, destinationParent: Item | null) => {
    if (isDisabled) {
      return false;
    }

    if (dragItem.hasMasterStep || (destinationParent && destinationParent.hasMasterStep)) {
      return false;
    }

    if (dragItem.isRCTask) {
      return false;
    }

    if (dragItem.isTask && destinationParent) {
      return false;
    }

    if (destinationParent && destinationParent.rcContainer !== undefined && destinationParent.rcContainer) {
      return false;
    }

    if (enableFormulaComponentValue && hasFormulas()) {
      dispatch(
        showErrorToast(
          "Cannot reorder steps when Formula (Numerical) Input components are present in the SW."
        )
      );
      return false;
    }
    // check all RC Steps.

    if (dragItem.rcContainer && !dragItem.parentIndex && destinationParent !== null && destinationParent.rcContainer === undefined) {
      return false;
    }
    else if (dragItem.rcContainer && dragItem.parentIndex && destinationParent !== null && destinationParent.rcContainer === undefined
      && dragItem.nestedIn && dragItem.nestedIn !== destinationParent.id) {
      return false;
    }
    else if (!dragItem.isTask && dragItem.rcContainer && dragItem.parentIndex && (destinationParent === undefined || destinationParent === null)) {
      return false;
    }
    else if (destinationParent === undefined || destinationParent === null) {
      if (((swType === SWTypes.TLMSWI
        || swType === SWTypes.MFGSWI
        || swType === SWTypes.TLMRC)
        && !dragItem.isTask
        && !dragItem.rcContainer
        && !dragItem.isInTask
      )) {
          return false;
      } else {
        return true;
      }
    } else if (destinationParent.nestedIn === undefined || destinationParent.nestedIn === null) {
      return true;
    } else if (destinationParent.nestedIn.length) {
      if (destinationParent.isConditional || dragItem.nestedIn === destinationParent.id) {
        return true;
      }
    }
    else {
      return true;
    }
    return true;
  };

  const openStep = (stepGuid: string) => {

    let isOpen = stepVisibilities.find(x => x.stepGuid === stepGuid)?.isOpen;

    if (isOpen === undefined || isOpen === null) {
      isOpen = true;
    }
    else {
      isOpen = !isOpen;
    }

    onStepToggle(stepGuid, isOpen);
    onStepClick(ancestors.map(st => st.guid), stepGuid);
  }

  return (
    <ol
      className={!parentOrders.length
        ? "top-level"
        : ""
      }
    >
      <Nestable
        className="Nestable"
        items={sortables}
        renderItem={({ item, index, collapseIcon }) => (
          <div
            className={`Nestable ${item.hasUnResolvedComments ? "unresolvedComments" : undefined} ${item.hasMasterStep ? "hasMasterStep" : undefined}`}
            onClick={e => openStep(item.id)}
          >
            {collapseIcon === null ?
              <img
                className="icon-small"
                alt="drag"
                src={dragIcon}
              /> :
              collapseIcon}
            {item.stepNumber ? item.stepNumber + ". " + item.text : item.text}

          </div>
        )}
        maxDepth={maxDepth}
        onChange={(arg) => onReorder(arg.items, arg.dragItem)}
        collapsed={true}
        renderCollapseIcon={({ isCollapsed }) =>
          isCollapsed ? (
            <img
              className="icon-small"
              alt="drag"
              src={arrowRight}
            />
          ) : (
            <img
              className="icon-small"
              alt="drag"
              src={dragIcon}
            />
          )
        }
        confirmChange={(arg) => confirmChange(arg.dragItem, arg.destinationParent)}
      />
    </ol>
  );
}

export default StepList;