import SWMetadata from "components/common/SWMetadata";
import "./RCAttributes.scoped.scss";
import React, { useEffect } from "react";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { setEquivalentSW } from "store/manageSW/manageSWActions";

interface IRCAttributesProps {
    isDisabled: boolean,
}

const RCAttributes: React.FC<IRCAttributesProps> = ({ isDisabled }) => {
    const {
      manageSW: { SW },
    } = useSelector((store) => store);

    const dispatch = useDispatch();
    const showEquivalentSW = SW?.language && SW.language.value !== "English";

    useEffect(() => {
      if (!showEquivalentSW) {
        dispatch(setEquivalentSW(undefined));
      }
    }, [showEquivalentSW]);

    return (
        <div className="inputs">
            <div className="type-row" >
                <SWMetadata
                    metadataName="dataClassification"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="languages"
                    isDisabled={isDisabled}
                />
                {showEquivalentSW && (
                    <SWMetadata
                        metadataName="equivalentSWs"
                        isDisabled={isDisabled}
                    />
                )}
            </div>
            <SWMetadata
                metadataName="title"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="description"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="organizations"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="productCenters"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="epicEquipment"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="versionChanges"
                isDisabled={isDisabled}
            />
            {/* <SWMetadata
                metadataName="hazardCategory"
                isDisabled={isDisabled} /> */}
        </div>
    );
}

export default RCAttributes;