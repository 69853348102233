import React from "react";
import useSelector from "store/useSelector";
import CommandsList from "../Commands/CommandsList";
import SWList, { SWListModes } from "../SWSearch/SWList";
import "./Review.scoped.scss";

const Review: React.FC = () => {
  const {
    addedSWs,
    commands,
  } = useSelector(store => store.batchUpdates);

  return (
    <>
      <div
        className="section"
      >
        <label>Batch Updates</label>
        <CommandsList
          commands={commands}
          isReadOnly
        />
      </div>
      {addedSWs.length > 0 &&
        <div
          className="section"
        >
          <SWList
            mode={SWListModes.Review}
            swList={addedSWs.map(x => ({
              sw: x,
            }))}
          />
        </div>
      }
      {addedSWs.length === 0 &&
        <span>
          No Standard Works will be updated. Use the Add Standard Work screen to search for and add Standard Work to this batch operation.
        </span>
      }
    </>
  );
};

export default Review;