import { IComment } from "interfaces/sw/SWInterfaces";
import React from "react";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import { resolveSWComment } from "store/manageSW/manageSWActions";
import formatDate from "utilities/formatDate";
import "./SWComment.scoped.scss";
import { Roles } from "interfaces/user/UserInterfaces";

const SWComment: React.FC<IComment> = (comment) => {
  let commentParts = comment.comment.split(/(@\[.+?\])/g);
  const {
    SW,
  } = useSelector(store => store.manageSW);
  const {
    roles,
  } = useSelector(store => store.auth.currentUser);
  const dispatch = useDispatch();
  const resolveComment = (commentId: number) => {
    dispatch(resolveSWComment({ commentId, swId: SW.id, resolved: true }))
  }
  // If the user is a Author/ApproverCOE/ApproverTechContent/ApproverOwner/OrgAdmin/sysadmin then can resolve
  let canResolve = roles.indexOf(Roles.Author) > -1
    || roles.indexOf(Roles.ApproverCOE) > -1
    || roles.indexOf(Roles.ApproverTechContent) > -1
    || roles.indexOf(Roles.ApproverOwner) > -1
    || roles.indexOf(Roles.OrgAdmin) > -1
    || roles.indexOf(Roles.OrgAdmin) > -1
    || roles.indexOf(Roles.SysAdmin) > -1
    || roles.indexOf(Roles.TechComm) > -1;

  return (
    <div className="comment">
      <div className="commentHeader">
        <div>{comment.createdBy}</div>
        <div>{formatDate(comment.createdOn, true)}</div>
      </div>
      <div className="commentBody">
        {
          commentParts.map((part, index) => (
            part.indexOf("@[") > -1
              ? <span key={index}><b>{part.substring(2, part.lastIndexOf("]"))}</b></span>
              : <span key={index}>{part}</span>
          ))
        }
        {(canResolve) &&
          <button
            className="primary-button btnResolve"
            disabled={comment.resolved ? true : false}
            onClick={() => resolveComment(comment.id)}
          >
            {comment.resolved ? "Resolved" : "Resolve Comment"}
          </button>
        }
      </div>
    </div>
  );
}

export default SWComment;