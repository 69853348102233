import "./Tooltip.scoped.scss";

import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

interface TooltipProps {
  children: ReactNode;
  tooltipContent: ReactNode;
}

interface TooltipData {
  content: ReactNode;
  position: {
    x: number;
    y: number;
  };
}

interface TooltipContextProps {
  tooltipData: TooltipData | undefined;
  setTooltipData(tooltipData: TooltipData | undefined): void;
}

const TooltipContext = createContext<TooltipContextProps>({
  tooltipData: undefined,
  setTooltipData: () => {},
});

export const TooltipTrigger: FC<TooltipProps> = ({ children, tooltipContent }) => {
  const { setTooltipData } = useContext(TooltipContext);

  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setTooltipData({
      content: tooltipContent,
      position: {
        x: e.clientX,
        y: e.clientY,
      },
    });
  };

  useEffect(() => {
    return () => {
      setTooltipData(undefined);
    };
  }, []);

  return (
    <>
      <div
        className="tooltip-trigger-wrapper"
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setTooltipData(undefined)}
      >
        {children}
      </div>
    </>
  );
};

export const TooltipContainer: FC = () => {
  const { tooltipData } = useContext(TooltipContext);
  const tooltipRef = React.createRef<HTMLDivElement>();
  const [tooltipWidth, setTooltipWidth] = useState<number>(0);
  const [tooltipHeight, setTooltipHeight] = useState<number>(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);

  useLayoutEffect(() => {
    const updateViewportDimensions = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateViewportDimensions);

    return () => {
      window.removeEventListener("resize", updateViewportDimensions);
    };
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (tooltipRef.current) {
        const { offsetWidth, offsetHeight } = tooltipRef.current;
        if (offsetWidth !== 0) {
          setTooltipWidth(offsetWidth);
        }

        if (offsetHeight !== 0) {
          setTooltipHeight(offsetHeight);
        }
      }
    }, 5);
  }, [tooltipRef]);

  useEffect(() => {
    if (tooltipData) {
      const { position } = tooltipData;

      const { x, y } = position;

      setX(Math.min(x - tooltipWidth / 2, viewportWidth - tooltipWidth));
      setY(y > viewportHeight / 2 ? y - tooltipHeight - 25 : y + 25);
    }
  }, [tooltipData, tooltipWidth, tooltipHeight, viewportWidth, viewportHeight]);

  return (
    <div className={tooltipData ? "tooltip" : "tooltip-hidden"} style={{ top: y, left: x }} ref={tooltipRef}>
      {tooltipData && tooltipData.content}
    </div>
  );
};

export const TooltipArea: FC<{ children: ReactNode }> = ({ children }) => {
  const [tooltipData, setTooltipData] = useState<TooltipData>();

  const value = useMemo(
    () => ({ tooltipData, setTooltipData }),
    [tooltipData, setTooltipData]
  );

  return (
    <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>
  );
};
