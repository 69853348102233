import React, { useEffect, useState } from "react";
import "./CustomMergeOption.scoped.scss";
import mergeIcon from "media/icons/dls/dashboard-1.svg";
import { EditorState } from "draft-js";
import MergeMenuItem from "./MergeMenuItem";
import { IMergeMenuItem } from "store/manageSW/manageSWTypes";

interface ICustomMergeOptionProps {
  onChange?: (editorState: EditorState) => void,
  editorState: EditorState,
  onMergeClick: (value: string) => void,
  colIndex: number,
  rowIndex: number,
  tableColLen: number,
  tableRowLen: number,
  modalHandler?: any,
}

const CustomMergeOption: React.FC<ICustomMergeOptionProps> = (props) => {
  //const {onChange, editorState, onMergeClick, colIndex, rowIndex, tableColLen, tableRowLen, modalHandler} = props;
  const {onMergeClick, colIndex, rowIndex, tableColLen, tableRowLen} = props;
  const [open, setOpen] = useState<boolean>(false);
  //console.log(modalHandler);
  // const modalHandlerCallback = () => {
  //   setOpen(!open);
  // }

  useEffect(() => {
    //modalHandler.registerCallBack(modalHandlerCallback);

    return () => {
      //modalHandler.deregisterCallBack(modalHandlerCallback);
      if (open) {
        setOpen(false);
        let dropdowns = document.getElementsByClassName("dropdown");
        for (var i = 0; i < dropdowns.length; i++) {
          dropdowns[i].className = dropdowns[i].className.replace(" show", "");
        }
      }
    }
  });

  let menuItems: IMergeMenuItem[] = [
    {
     title: "Merge",
     submenu: [
       { 
         title: "Right",
         submenu: [],
       },
       { 
         title: "Down",
         submenu: [],
       },
     ],
    },
    {
     title: "Unmerge",
    },
   ];

  const menuDepth = 0;
  const menuItemParentName = "";

  let rightSubmenu = menuItems[0].submenu ? menuItems[0].submenu[0] : undefined;
  let downSubmenu = menuItems[0].submenu ? menuItems[0].submenu[1] : undefined;

  if (rightSubmenu) {
    for (let r = 2; r <= tableColLen - colIndex; r++) {
      rightSubmenu.submenu?.push({title: r.toString()});
    }
  }

  if (downSubmenu) {
    for (let c = 2; c <= tableRowLen - rowIndex; c++) {
      downSubmenu.submenu?.push({title: c.toString()});
    }
  }

  const onClick = (value: string) => {
    onMergeClick(value);
    setOpen(false);
  }

  return (
    <>
      <div 
        className="rdw-option-wrapper"
        onClick={() => setOpen(!open)}
      >
        <img
          src={mergeIcon}
          alt="Merge/Unmerge Cells"
          className="icon-small"
          title="Merge/Unmerge Cells"
        />
        &#11206;
      </div>
      <div className={`rdw-dropdown-wrapper rdw-inline-dropdown ${open ? '' : 'dropdown-closed'}`} aria-label="rdw-dropdown" style={{width: 100}}>
        <ul className="rh-dropdown-optionwrapper">
          {menuItems.map((menu, index) => {
            return (
              <MergeMenuItem 
                item={menu} 
                key={index}
                onMergeNumberClick={onClick}
                depthLevel={menuDepth}
                parentName={menuItemParentName}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default CustomMergeOption;