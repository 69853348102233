import React from "react";
import "./Logo.scoped.scss";
import logo from "media/logo.svg";

const Logo: React.FC = () =>
  <div className="logo-holder">
    <span>
      <img
        src={logo}
        alt="WorkRight Logo"
        title="WorkRight Logo"
      />
      WorkRight
    </span>
  </div>;

export default Logo;