import config from "config";
import { authGetJson, authPostJson } from "utilities/msalFetches";
import { throwIfResponseError } from "apis/apiUtilities";
import {
  formatGetManageUsersList,
  formatGetUserResponse,
  formatUpdateUserRequest,
  formatCreateUserRequest,
  formatSearchAADUsersResponse,
  formatGetUserDetailResponse,
  formatGetEditUserDetail,
  formatConfirmTCCRequest,
  formatTCCInfo,
  formatGetFilteredUsersList,
  formatSaveNewUserRoleAssignmentsRequest
} from "./userFormatters";
import { IManageUserUser, IAADUser, IManageUserDetail, ITCCInfo, TCCComplianceStatuses } from "interfaces/user/UserInterfaces";
import { IEditUserData, IUserDetail, IUserFilterFields } from "store/userManagement/userManagementTypes";

class UsersApi {
  public async getManageUsersList(searchTerm: string): Promise<IManageUserUser[]> {
    let endpoint = `${config.endpoints.users.getUsers}?searchTerm=${window.encodeURIComponent(searchTerm) || ""}`;
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();
    let searchResults = formatGetManageUsersList(json);
    return searchResults;
  }

  public async getUser(email: string, name: string): Promise<IManageUserUser> {
    if (!email) {
      throw new Error("Email must be supplied when loading a user for edit.");
    }

    let endpoint = config.endpoints.users.getUser
      .replace("{email}", window.encodeURIComponent(email))
      .replace("{name}", window.encodeURIComponent(name));
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();
    let user = formatGetUserResponse(json);
    return user;
  }

  public async getUserDetail(email: string): Promise<IUserDetail> {
    if (!email) {
      throw new Error("Email must be supplied when loading a user for edit.");
    }
    let response = await authPostJson(
      config.endpoints.users.getUserInfo, JSON.stringify({
        Email: email
      })
    );
    await throwIfResponseError(response);

    let json = await response.json();
    let user = formatGetUserDetailResponse(json);
    return user;
  }

  public async deleteUserRole(email: string, role: string, isTechUser: boolean, isPsdUser: boolean, isMfgUser: boolean): Promise<IUserDetail> {

    if (!email) {
      throw new Error("Email must be supplied when loading a user for edit.");
    }

    let response = await authPostJson(
      config.endpoints.users.deleteUserRole, JSON.stringify({
        Email: email,
        Role: role,
        IsTechUser: isTechUser,
        IsPsdUser: isPsdUser,
        IsMfgUser: isMfgUser,
      })
    );

    await throwIfResponseError(response);

    let json = await response.json();
    return formatGetUserDetailResponse(json);
  }

  public async getUserEditInfo(email: string, role: string, isTechUser: boolean, isPsdUser: boolean, isMfgUser: boolean): Promise<IEditUserData> {
    if (!email) {
      throw new Error("Email must be supplied when loading a user for edit.");
    }

    let response = await authPostJson(
      config.endpoints.users.editUser, JSON.stringify({
        Email: email,
        Role: role,
        IsTechUser: isTechUser,
        IsPsdUser: isPsdUser,
        IsMfgUser: isMfgUser
      })
    );

    await throwIfResponseError(response);

    let json = await response.json();
    return formatGetEditUserDetail(json);
  }

  public async searchAADUsers(searchTerm: string): Promise<IAADUser[]> {
    if (!searchTerm) {
      throw new Error("Search term must be supplied when searching users.");
    }

    let endpoint = `${config.endpoints.users.getAADUsers}?searchTerm=${window.encodeURIComponent(searchTerm)}`;
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();
    return formatSearchAADUsersResponse(json);
  }

  public async updateUser(user: IManageUserDetail, email: string, name: string): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.users.updateUser.replace("{email}", window.encodeURIComponent(email)),
      JSON.stringify(formatUpdateUserRequest(user, email, name)),
    );

    await throwIfResponseError(response);

    return true;
  }

  public async createUser(user: IAADUser): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.users.createUser,
      JSON.stringify(formatCreateUserRequest(user)),
    );

    await throwIfResponseError(response);

    return true;
  }

  public async submitTCCResponse(tccId: number, status: TCCComplianceStatuses): Promise<ITCCInfo> {
    let response = await authPostJson(
      config.endpoints.users.confirmTCC,
      JSON.stringify(formatConfirmTCCRequest(tccId, status)),
    );

    await throwIfResponseError(response);

    let json = await response.json();
    return formatTCCInfo(json.TCCInfo);
  }

  public async getFilteredUsersList(searchText: string, filterFields: IUserFilterFields): Promise<IManageUserUser[]> {
    const response = await authPostJson(
      config.endpoints.users.getFilteredUsers,
      JSON.stringify(
        formatGetFilteredUsersList(
          searchText,
          filterFields,
        )
      )
    );

    await throwIfResponseError(response);

    let json = await response.json();
    let searchResults = formatGetManageUsersList(json);
    return searchResults;
  }

  public async saveNewUserRoleAssignments(users: string[], roleAssignments: IManageUserDetail[]): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.users.saveNewUserRoleAssignments,
      JSON.stringify(formatSaveNewUserRoleAssignmentsRequest(users, roleAssignments)),
    );

    await throwIfResponseError(response);

    return true;
  }
}

export default new UsersApi();