import { createReducer } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  attachDocument,
  removeDocument,
  resetFeedback,
  saveFeedback,
  setCreatedSuccesfull,
  setDescription,
  setErrorProccess,
  setGroup,
  setInitializeProccess,
  setPriority,
  setTitle,
} from "./feedbackActions";
import { IFeedbackState } from "./feedbackTypes";

const initialState: IFeedbackState = {
  feedback: {
    title: "",
    description: "",
    group: "",
    priority: "Low",
    documents:[
      {
        name: "",
        guid: uuidv4(),
        hasAttach: false,
      }
    ]
  },
  saveOperation: {
    wasSuccessful: false,
    isWorking: false,
    errorMessage: "",
  }, maxDocuments: 5,
};

const feedbackReducer = createReducer(initialState, (builder) => {
  builder.addCase(resetFeedback, (state) => {
    state.feedback = cloneDeep(initialState.feedback);
  });

  builder.addCase(saveFeedback, (state, action) => {
    state.feedback = action.payload;
  });

  builder.addCase(setCreatedSuccesfull, (state) => {
    state.saveOperation.wasSuccessful = true;
    state.saveOperation.isWorking = false;
    state.saveOperation.errorMessage = "";
  });
  builder.addCase(setInitializeProccess, (state) => {
    state.saveOperation.wasSuccessful = false;
    state.saveOperation.isWorking = true;
    state.saveOperation.errorMessage = "";
  });

  builder.addCase(setErrorProccess, (state, action) => {
    state.saveOperation.wasSuccessful = false;
    state.saveOperation.isWorking = false;
    state.saveOperation.errorMessage = action.payload;
  });

  builder.addCase(setTitle, (state, action) => {
    state.feedback.title = action.payload;
  });

  builder.addCase(setDescription, (state, action) => {
    state.feedback.description = action.payload;
  });

  builder.addCase(setPriority, (state, action) => {
    state.feedback.priority = action.payload;
  });

  builder.addCase(setGroup, (state, action) => {
    state.feedback.group = action.payload;
  });

  builder.addCase(attachDocument, (state, action) => {
    var index = action.payload.index;
    var hasAttach= state.feedback.documents[index].hasAttach;
    state.feedback.documents[index].name = action.payload.file.name;
    state.feedback.documents[index].file = action.payload.file;
    state.feedback.documents[index].hasAttach = true;

    if (state.feedback.documents.length < state.maxDocuments && !hasAttach ) {
      state.feedback.documents.push({
        name: "",
        guid: uuidv4(),
        hasAttach: false,
      });
    }
  });

  builder.addCase(removeDocument, (state, action) => {
    var array = state.feedback.documents;
    var index = array.indexOf(action.payload);
    delete array[index];
    state.feedback.documents = array;
  });
});

export default feedbackReducer;
