import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadSWNoticeSummary } from "../../../../store/manageSW/manageSWActions";
import useSelector from "../../../../store/useSelector";
import SWStepNotices from "./SWStepNotices";

interface IManageSWNoticeSummaryProps {
    swGuid: string,
    swVersion: number
}

const ManageSWNoticeSummary: React.FC<IManageSWNoticeSummaryProps> = ({ swGuid, swVersion }) => {
    const {
        swNoticeData,
        swStepNoticeData,
        SW: {
            steps
        },
    } = useSelector(store => store.manageSW);
    const dispatch = useDispatch();
    const isPresentSWNotices = swNoticeData.swNotices === undefined ? false : swNoticeData.swNotices?.length > 0 ? true : false;
    const isPresentStepNotices = swStepNoticeData.swStepNotices === undefined ? false : swStepNoticeData.swStepNotices?.length > 0 ? true : false;

    useEffect(() => {
        dispatch(loadSWNoticeSummary({ swGuid, swVersion }));
    }, [dispatch, swGuid, swVersion])
    return (
        <div className="notice-container">
            <div className="sw-notices">
                <div>
                    {(isPresentSWNotices || isPresentStepNotices) &&
                        <label className="title">StandardWork Notices</label>}
                </div>
                <div>
                    <ul>{swNoticeData?.swNotices?.map((x, index) => (
                        <li key={index}>{x.text.replace(/<\/?p>/g, '')}</li>
                    ))}
                    </ul>
                </div>
            </div>
            {steps
                .map((step, index) => (
                    <SWStepNotices key={index} {...step} />
                ))}
        </div>)
}

export default ManageSWNoticeSummary;