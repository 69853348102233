import React, { useEffect, useRef } from "react";
import SWRowPDF from "./SWRowPDF";
import SWSort from "components/sw/search/SWSort";
import "./SWSearchPDF.scoped.scss";
import useSelector from "store/useSelector";
import Banner, { BannerType } from "components/common/Banner";
import { useDispatch } from "react-redux";

import {
    setSort,
    resetAllExceptFilters,
    addFilterItem,
    removeFilterItem,
    applyFilter,
    clearFilter,
    setStringFilter,
    setSearchText,
    getDownloadZip,
    getArchiveRequest,
    setNewIndex,
    confirmBulkDeleteDraft,
    setRCType,
} from "store/swList/swListActions";

import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import SearchBar from "components/common/SearchBar";
import FlowLayout from "components/layout/FlowLayout";
import { clearSearchedMasterDataItems } from "store/masterData/masterDataActions";
import ModalSpinner from "components/common/ModalSpinner";
import { SWSortFields } from "interfaces/sw/SWInterfaces";
import { SortDirection } from "interfaces/misc/miscInterfaces";
import { showErrorToast } from "store/toast/toastActions";

const SWSearchPDF: React.FC = () => {
    const {
        swListData: {
            loadOperation,
            swList,
            totalCount,
            skipIndex,
        },
        filterData,
        swListForZip: {
            swListZip,
        },
        setDownloadZipData,
        setArchiveRequestData,
    } = useSelector(store => store.swList);

    const dispatch = useDispatch();
    let sortDirSelectedCol = filterData.sortDir;
    let sortBySelectedCol = filterData.sortBy;
    const LENGTH = totalCount;
    const LIMIT = 100;

    const loadMore = () => {
        const newIndex = skipIndex !== undefined ? (skipIndex + LIMIT) : 0;
        const newShowMore = newIndex < (LENGTH - 1);
        if (newShowMore) {
            dispatch(setNewIndex(newIndex));
        }
    }
    const inputBoxRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (inputBoxRef.current){
            inputBoxRef.current.focus(); // set focus to search input box after rendering results
        }
    }, [swList]);

    const sortByColumn = (sortBy: SWSortFields) => {
        let sortDir;
        if (sortBySelectedCol !== sortBy) {
            sortDir = SortDirection.Asc;
        }
        else {
            if (sortDirSelectedCol === SortDirection.Asc) {
                sortDir = SortDirection.Desc;
            }
            else {
                sortDir = SortDirection.Asc;
            }
        }
        dispatch(
            setSort({
                sortBy: sortBy,
                sortDir: sortDir,
            })
        )
    }

    const onAddItem = (item: IMasterDataItem, name: MetaDataNames) => {
        dispatch(
            addFilterItem({
                metaDataName: name,
                item,
            })
        );
    }

    const onRemoveItem = (item: IMasterDataItem, name: MetaDataNames) => {
        dispatch(
            removeFilterItem({
                metaDataName: name,
                item,
            })
        );
    }

    const setSWStringFilter = (name: "maintenanceTaskIds", value: string) => {
        dispatch(setStringFilter({
            attributeName: name,
            value: value,
        }));
    }

    const applyDipatchFilter = () => {
        dispatch(applyFilter({ isRCFilter: false, onlyTask: false, isSourceSystemTIME: false, searchSource: "SearchSWPdf" }));
        dispatch(clearSearchedMasterDataItems());
    }

    useEffect(() => {
        return () => {
            dispatch(resetAllExceptFilters());
        };
    }, [dispatch]);


    let onSetSearchRef = useRef((value: string) => dispatch(setSearchText(value)));

    let component: JSX.Element | undefined;

    if (loadOperation?.errorMessage) {
        component = (
            <Banner
                type={BannerType.Error}
            >
                {loadOperation.errorMessage}
            </Banner>
        );
    } else if (!swList.length) {
        component = (
            <label>No StandardWorks found.</label>
        );
    } else {
        component = (
            <>
                <div className="row header">
                    <div className="cell sw-name swname"
                        onClick={() => sortByColumn(SWSortFields.Title)}
                    >
                        <span>Standard Work Name</span>
                        <SWSort
                            colName="Title"
                        />
                    </div>
                    <div className="cell swname">
                        <span>Guid</span>
                    </div>
                    <div className="cell pub swname">
                        <span>Last Published</span>
                    </div>
                    <div className="cell pub">
                        <span>Download PDF</span>
                    </div>
                    <div className="cell pub">
                        <span>Share PDF</span>
                    </div>
                </div>
                <div className="list-body">
                    {swList.map(sw =>
                        <SWRowPDF
                            sw={sw}
                            key={sw.id}
                            checkedSW={swListZip.findIndex(x => x.swid === sw.id) > -1}
                        />
                    )}
                </div>
            </>
        );
    }



    const downloadZipFile = () => {
        if (setDownloadZipData.length > 0) {
            dispatch(showErrorToast("Already one request is going on so please wait..."));
            return;
        }

        if (swListZip.length === 0) {
            dispatch(showErrorToast("Please select atleast one standard work."));
            return;
        }

        if (window.confirm("Are you sure you want to continue?")) {
            dispatch(getDownloadZip(
                swListZip,
            ));
        }
    }

    const archiveRequest = () => {
        if (setArchiveRequestData.length > 0) {
            dispatch(showErrorToast("Already one request is going on so please wait..."));
            return;
        }

        if (swListZip.length === 0) {
            dispatch(showErrorToast("Please select atleast one standard work."));
            return;
        }

        if (window.confirm("Are you sure you want to continue?")) {
            dispatch(getArchiveRequest(
                swListZip,
            ));
        }
    }

    const bulkDelete = () => {
        if (swListZip.length === 0) {
            dispatch(showErrorToast("Please select atleast one standard work."));
            return;
        }

        if (window.confirm("Are you sure you want to delete the selected standard works?")) {
            dispatch(confirmBulkDeleteDraft(
                swListZip,
            ));
        }
    }

    return (
        <>
            <FlowLayout
                header={
                    <SearchBar
                        swFilterFields={filterData.filterFields}
                        setSearchText={onSetSearchRef.current}
                        onAddSWItem={onAddItem}
                        onRemoveSWItem={onRemoveItem}
                        applyFilter={applyDipatchFilter}
                        clearFilter={() => dispatch(clearFilter())}
                        setSWStringFilter={setSWStringFilter}
                        extraFilterCount={0}
                        isDisabled={loadOperation?.isWorking === true}
                        showStatusFilter={true}
                        placeholder="Search by title, description, or guid..."
                        downloadZipFile={downloadZipFile}
                        getArchiveRequest={archiveRequest}
                        bulkDelete={bulkDelete}
                        showZipArchiveButton={false}
                        setRCType={(value) => dispatch(setRCType(value))}
                        inputBoxRef={inputBoxRef}
                    />
                }
            >
                {component}
                {loadOperation?.isWorking &&
                    <ModalSpinner />
                }
            </FlowLayout>
            {swList.length > 0 && (swList.length !== totalCount) && <button className="primary-button" onClick={loadMore}> Load More </button>}
        </>
    );
}

export default SWSearchPDF;