import React, {useEffect, useRef, useState} from "react";
import useSelector from "store/useSelector";
import "./MFGManageConnectedSWIs.scoped.scss";
import { useDispatch } from "react-redux";
import {
  getConnectedSWIs,
  setConnectedSWIGroup,
  setConnectedSWIGroupCounter,
  setConnectedSWIMaterial,
  setUploadConnectedSWIsErrors
} from "store/manageSW/manageSWActions";
import FlowLayout from "components/layout/FlowLayout";
import MFGAddConnectedSWIModal from "./MFGAddConnectedSWIModal";
import deleteIcon from "../../../../media/icons/dls/delete.svg";
import MFGDeleteConnectedSWIModal from "./MFGDeleteConnectedSWIModal";
import { IConnectedSWI } from "interfaces/sw/SWInterfaces";
import { Link } from "react-router-dom";
import MFGUploadConnectedSWIsErrorModal from "./MFGUploadConnectedSWIsErrorModal";

interface IManageConnectedSWIsProps {
  isDisabled: boolean,
}

const MFGManageConnectedSWIs: React.FC<IManageConnectedSWIsProps> = ({ isDisabled }) => {
  const {
    manageSW: {
      SW:{
        connectedSWIs,
        guid,
        version
      },
      connectedSWIData:{
        addOperation
      },
      loadOperation,
      submitForReviewFlag
    },
    } = useSelector(store => store);

  const dispatch = useDispatch();
  const [ addConnectedSWIPopup, setAddConnectedSWIPopup] = 
    useState<{closed: boolean, addedItem: boolean}>({closed: true, addedItem: false});
  const [ deleteConnectedSWIPopup, setDeleteConnectedSWIPopup] =
    useState<{ closed: boolean, connectedSWI: IConnectedSWI | undefined }>({ closed: true, connectedSWI: undefined });
  const [isSubmitForReview, setIsSubmitForReview] = useState<boolean>(false);

  useEffect(() => {
      if (submitForReviewFlag) {
          setIsSubmitForReview(true);
      }
  });

  useEffect(() => {
      dispatch(getConnectedSWIs({ swGuid: guid, swVersion: version }));
      }, [guid, version, isSubmitForReview]);

  useEffect(() => {
    if(loadOperation && loadOperation.wasSuccessful){
      setDeleteConnectedSWIPopup({closed: true, connectedSWI: undefined})
    }
  }, [loadOperation]);

  useEffect(() => {
    if(addOperation && addOperation.wasSuccessful){
      setAddConnectedSWIPopup({closed: true, addedItem: false});
      dispatch(setConnectedSWIMaterial(undefined));
      dispatch(setConnectedSWIGroup(undefined));
      dispatch(setConnectedSWIGroupCounter(undefined));
    }
  }, [loadOperation]);

  const clearUploadErrors = () => {
    dispatch(setUploadConnectedSWIsErrors([]));
  }
  
  return (
    <>
      <FlowLayout
        footer={
            <div className="manage-doc-variations">
              <div className="buttons">
                <>
                  <div className="excelDownloadButton">
                    <Link 
                      to="/files/ChildMaterialTemplate.xlsx"
                      target="_blank"
                      download
                    >
                      Download Excel Template
                    </Link>
                  </div>
                  <button
                      className="primary-button"
                      title={"Add Connected SWI"}
                      onClick={() => setAddConnectedSWIPopup({closed: false, addedItem: false})}
                  >
                      + Add Connected Child SWI
                  </button>
                </>
              </div>
              {!addConnectedSWIPopup.closed &&
                  <MFGAddConnectedSWIModal
                      onClose={(addedItem: boolean) => {
                        setAddConnectedSWIPopup({closed: true, addedItem: addedItem});
                        dispatch(setConnectedSWIMaterial(undefined));
                        dispatch(setConnectedSWIGroup(undefined));
                        dispatch(setConnectedSWIGroupCounter(undefined));
                      }}
                  />
              }
              {!deleteConnectedSWIPopup.closed &&
                <MFGDeleteConnectedSWIModal 
                    connectedSWI={deleteConnectedSWIPopup.connectedSWI!!} 
                    swGuid={guid} 
                    swVersion={version} 
                    onCancel={() => setDeleteConnectedSWIPopup({
                      closed: true,
                      connectedSWI: undefined
                    })}
                />
              }
            </div>
        }
      >
        {!loadOperation?.isWorking &&
          connectedSWIs.length == 0 && 
            <div className="row">This document does not contain any Connected SWIs.</div>
        }
        {connectedSWIs.length > 0 && 
          <>
            <div className="row header">
              <div className="cell material-code">
                Material
              </div>
              <div className="cell group">
                Group
              </div>
              <div className="cell group-counter" >
                Group Counter
              </div>
              <div className="cell guid">
                Child SWI Guid
              </div>
              <div className="cell buttons" >
                {!isDisabled && <span>Delete</span> }
              </div>
            </div>
            <div className="list-body">
              {
                connectedSWIs?.map((x, ix: number) => (
                <div className="row-value row" key={ix.toString()}>
                  <div className="cell material-code">
                    {x.materialCode}
                  </div>
                  <div className="cell group">
                    {x.group}
                  </div>
                  <div className="cell group-counter">
                    {x.groupCounter}
                  </div>
                  <div className="cell guid">
                    {x.dupeGuid}
                  </div>
                    <div className="cell buttons">
                      {!isDisabled &&
                        <img 
                          src={deleteIcon} 
                          className="icon-medium hover-image" 
                          alt="Delete Material" 
                          onClick={() => setDeleteConnectedSWIPopup({
                            closed: false,
                            connectedSWI: x
                          })}
                        />
                      }
                    </div>
                </div>
              ))}
            </div>
          </>
        }
      </FlowLayout>
      <MFGUploadConnectedSWIsErrorModal 
        onClose={() => clearUploadErrors()}
      />
    </>
  );
}

export default MFGManageConnectedSWIs;