import React from "react";
import "./ToggleButton.scoped.scss";

interface ToggleButtonProps {
    checked: boolean,
    onChange(checked: boolean): void,
    tooltip: string,
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ checked, onChange, tooltip }) => {

    return (
        <label className="toggle-container">
            <input
                type="checkbox"
                className="toogle-check"
                onChange={(e) => onChange(e.target.checked)}
                checked={checked} />
            <span className="slider" title={tooltip} />
        </label>
    );

}

export default ToggleButton;