import Banner, { BannerType } from "components/common/Banner";
import React, { useRef } from "react";
import useSelector from "store/useSelector";
import SwRepoRow from "./SwRepoRow";
import FlowLayout from "components/layout/FlowLayout";
import SearchSwRepoBar from "./SearchSwRepoBar";
import "./SearchSwRepo.scoped.scss";
import { useDispatch } from "react-redux";
import { setSearchText } from "store/ManageSwReports/manageSwReportsActions";
import ModalSpinner from "components/common/ModalSpinner";

const SearchSwRepo: React.FC = () => {
    const {
        manageSwReports: {
            loadOperation,
            swList,
        }
    } = useSelector(store => store);
    const dispatch = useDispatch();

    let onSetSearchRef = useRef((value: string) => dispatch(setSearchText(value)));
    let component: JSX.Element | undefined;

    if (loadOperation?.errorMessage) {
        component = (
            <Banner
                type={BannerType.Error}
            >
                {loadOperation.errorMessage}
            </Banner>
        );
    } else if (!swList.length) {
        component = (
            <label>No StandardWorks found.</label>
        );
    } else {
        component = (
            <>
                <div className="row header">
                    <div>
                        <span>Standard Work Name</span>
                    </div>
                    <div className="cell sw-name swname">
                        <span>UID</span>
                    </div>
                    <div className="cell pub">
                        <span>Version</span>
                    </div>
                    <div className="cell pub">
                        <span>Published Date</span>
                    </div>
                    <div className="cell pub">
                        <span></span>
                    </div>
                </div>
                <div className="list-body">
                    {swList.map(sw =>
                        <SwRepoRow
                            sw={sw}
                            key={sw.id}
                        />
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <br />
            <FlowLayout
                header={
                    <SearchSwRepoBar
                        setSearchText={onSetSearchRef.current}
                    />
                }
            >
                {component}
                {loadOperation?.isWorking &&
                    <ModalSpinner />
                }
            </FlowLayout>
        </>
    );
};

export default SearchSwRepo;