import React, { useState, useRef, useEffect } from "react";
import { ISelectInputComponent, IMultiSelectInputComponent, StepComponentTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { updateSelectInput, updateMultiSelectInput } from "store/manageSW/manageSWActions";
import "./ComponentEditor.scss";
import "./SelectInputEditor.scoped.scss";
import { showErrorToast } from "store/toast/toastActions";
import DeleteIcon from "media/icons/dls/delete.svg";
import EditIcon from "media/icons/dls/edit.svg";
import CancelIcon from "media/icons/dls/close.svg";
import DoneIcon from "media/icons/dls/check.svg";
import useSelector from "store/useSelector";
import { loadMeters } from "store/masterData/masterDataActions";
import SelectMeter from "./SelectMeter";

interface ISelectInputEditorProps {
  selectInput: ISelectInputComponent | IMultiSelectInputComponent,
  allowEdit: boolean,
  stepGuid: string,
  labelText: string,
  swType: SWTypes,
  stepLocation?: string,
}

const SelectInputEditor: React.FC<ISelectInputEditorProps> = ({
  selectInput,
  allowEdit,
  stepGuid,
  labelText,
  swType,
  stepLocation,
}) => {
  const {
    masterData: {
      meters,
    },
    manageSW: {
      enableMetersValue,
      SW: {
        epicEquipment,
      }
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();

  useEffect(() => {
    if (epicEquipment.length > 0) {
      dispatch(loadMeters({ onlyActive: true, equipmentCodes: epicEquipment, location: "1:Global" }));
    }
  }, [epicEquipment]);
  const filterMeters = stepLocation == undefined || stepLocation == null || stepLocation === "" || stepLocation === "1:Global" ? meters.items : meters.items.filter(t => t.parentGuid === stepLocation);

  const isTLMType = (swType === SWTypes.TLMSWI
    || swType === SWTypes.TLMRC);

  const [newOptionText, setNewOptionText] = useState("");
  const textboxRef = useRef<HTMLInputElement>(null);
  const nonConformInstructionRef = useRef<HTMLInputElement>(null);

  const [updatingOptionData, setUpdatingOptionData] = useState<{
    index: number;
    optionText: string;
  }>();

  const optionCompare = (a?: string, b?: string) =>
    a?.toLowerCase().trim() === b?.toLowerCase().trim();

  const dispatchDuplicateErrorToast = () =>
    dispatch(
      showErrorToast("The specified option already exists in the list.")
    );

  const addNewOption = (event: React.FormEvent<HTMLFormElement>
    | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    textboxRef.current?.focus();

    if (!newOptionText.trim()) {
      return;
    }

    if (selectInput.options.some(opt => optionCompare(opt, newOptionText))) {
      dispatchDuplicateErrorToast();
      return;
    }

    if (selectInput.type === StepComponentTypes.SelectInput) {
      dispatch(updateSelectInput({
        stepGuid,
        component: {
          ...selectInput,
          options: selectInput.options.concat(newOptionText.trim()),
        },
      }));
    } else if (selectInput.type === StepComponentTypes.MultiSelectInput) {
      dispatch(updateMultiSelectInput({
        stepGuid,
        component: {
          ...selectInput,
          options: selectInput.options.concat(newOptionText.trim()),
        },
      }));
    }

    setNewOptionText("");
  }

  const removeOption = (option: string) => {
    if (selectInput.type === StepComponentTypes.SelectInput) {
      dispatch(updateSelectInput({
        stepGuid,
        component: {
          ...selectInput,
          options: selectInput.options.filter(x => x !== option),
          expectedValue: selectInput.expectedValue ? (selectInput.expectedValue === option ? "" : selectInput.expectedValue) : selectInput.expectedValue,
        },
      }));
    } else if (selectInput.type === StepComponentTypes.MultiSelectInput) {
      let expectedValue: string[] = [];
      selectInput.expectedValue
        && selectInput.expectedValue.forEach(a => {
          if (a !== option) {
            expectedValue.push(a);
          }
        });
      dispatch(updateMultiSelectInput({
        stepGuid,
        component: {
          ...selectInput,
          options: selectInput.options.filter(x => x !== option),
          expectedValue: expectedValue,
        },
      }));
      // remove deleted value from expected value.
    }
  }

  const updateOption = (updatedIndex: number, updatedOption: string) => {
    updatedOption = updatedOption.trim();

    if (!updatedOption) {
      return;
    }

    const foundDuplicates = selectInput.options.some(
      (option, index) =>
        optionCompare(option, updatedOption) && index !== updatedIndex
    );

    if (foundDuplicates) {
      dispatchDuplicateErrorToast();
      return;
    }

    const oldOption = selectInput.options[updatedIndex];
    const newOptions = [...selectInput.options];
    newOptions[updatedIndex] = updatedOption;

    if (selectInput.type === StepComponentTypes.SelectInput) {
      const expectedValue = optionCompare(
        oldOption,
        selectInput.expectedValue
      )
        ? updatedOption
        : selectInput.expectedValue;

      dispatch(
        updateSelectInput({
          stepGuid,
          component: {
            ...selectInput,
            options: newOptions,
            expectedValue,
          },
        })
      );
    } else if (selectInput.type === StepComponentTypes.MultiSelectInput) {
      const expectedValue = selectInput.expectedValue?.map((option) =>
        optionCompare(option, oldOption) ? updatedOption : option
      );

      dispatch(
        updateMultiSelectInput({
          stepGuid,
          component: {
            ...selectInput,
            options: newOptions,
            expectedValue,
          },
        })
      );
    }

    setUpdatingOptionData(undefined);
  };

  const onLabelBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (selectInput.type === StepComponentTypes.SelectInput) {
      dispatch(updateSelectInput({
        stepGuid,
        component: {
          ...selectInput,
          label: event.target.value,
        },
      }));
    } else if (selectInput.type === StepComponentTypes.MultiSelectInput) {
      dispatch(updateMultiSelectInput({
        stepGuid,
        component: {
          ...selectInput,
          label: event.target.value,
        },
      }));
    }
  }

  const onNonConformBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (selectInput.type === StepComponentTypes.SelectInput) {
      dispatch(updateSelectInput({
        stepGuid,
        component: {
          ...selectInput,
          nonConform: event.target.value,
        },
      }));
    } else if (selectInput.type === StepComponentTypes.MultiSelectInput) {
      dispatch(updateMultiSelectInput({
        stepGuid,
        component: {
          ...selectInput,
          nonConform: event.target.value,
        },
      }));
    }
  }

  const onExpectedValueChange = (event: React.FocusEvent<HTMLSelectElement>) => {
    let isNonConformValueReset = false;
    if (isTLMType
      && event.target.selectedOptions.length === 1
      && event.target.value === '') {
      const nonConformInstruction = nonConformInstructionRef.current;
      if (nonConformInstruction) {
        nonConformInstruction.value = "";
        isNonConformValueReset = true;
      }
    }

    if (selectInput.type === StepComponentTypes.SelectInput) {
      dispatch(updateSelectInput({
        stepGuid,
        component: {
          ...selectInput,
          expectedValue: event.target.value,
          nonConform: isNonConformValueReset
            ? ''
            : selectInput.nonConform,
        },
      }));
    } else if (selectInput.type === StepComponentTypes.MultiSelectInput) {
      let selectedItems: string[] = [];
      for (let index = 0; index < event.target.options.length; index++) {
        const element: boolean = event.target.options[index].selected;
        if (element) {
          selectedItems.push(event.target.options[index].value);
        }
      }
      dispatch(updateMultiSelectInput({
        stepGuid,
        component: {
          ...selectInput,
          expectedValue: selectedItems,
          nonConform: isNonConformValueReset ? '' : selectInput.nonConform,
        },
      }));
    }
  }

  const shouldConformInstructionDisabled = () => {
    let blankSelected = false;
    if (selectInput.type === StepComponentTypes.MultiSelectInput) {
      blankSelected = selectInput?.expectedValue === undefined
        || (selectInput?.expectedValue?.length === 1
          && selectInput?.expectedValue[0] === "");
    }
    else {
      blankSelected = selectInput?.expectedValue === undefined
        || selectInput?.expectedValue === "";
    }

    return (isTLMType)
      &&
      selectInput
      && blankSelected
  }

  return (
    <div className="component-editor">
      <div className="row">
        <label className="mandatory block">{labelText}</label>
        {(enableMetersValue && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC)) &&
          <label className="meter">Meter</label>}
      </div>
      <div className="row">
        {allowEdit &&
          <>
            <input
              type="text"
              defaultValue={selectInput.label}
              onBlur={onLabelBlur}
            />
            {(enableMetersValue && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC)) &&
              <div className="meter">
                <SelectMeter
                  inputComponent={selectInput}
                  stepGuid={stepGuid} 
                  meters={filterMeters}/>
              </div>
            }
          </>
        }
        {!allowEdit &&
          <>
            <span>{selectInput.label}</span>
            {(enableMetersValue && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC)) &&
              <span className="meter">{selectInput.meterCode}</span>}
          </>
        }
      </div>
      <label className="block">Options</label>
      {allowEdit &&
        <form
          className="new-option-form"
          onSubmit={addNewOption}
        >
          <input
            type="text"
            value={newOptionText}
            onChange={(e) => setNewOptionText(e.target.value)}
            placeholder="New option..."
            ref={textboxRef}
          />
          <button
            className="primary-button"
            onClick={addNewOption}
          >
            + Add Option
          </button>
        </form>
      }
      <div className="options-list">
        {selectInput.options.map((optionText, index) =>
          <div key={optionText} className="option no-text-select">
            {allowEdit && updatingOptionData?.index === index ? (
              <>
                <input
                  type="text"
                  value={updatingOptionData.optionText}
                  onChange={(e) =>
                    setUpdatingOptionData({
                      index,
                      optionText: e.target.value,
                    })
                  }
                  onKeyUp={(e) => {
                    if (e.key === "Escape") setUpdatingOptionData(undefined);
                    if (e.key === "Enter")
                      updateOption(index, updatingOptionData.optionText);
                  }}
                  autoFocus
                />
                <img
                  src={DoneIcon}
                  title="Done"
                  alt="Done"
                  className="delete-option-button icon-small hover-gray-bg"
                  onClick={() =>
                    updateOption(index, updatingOptionData.optionText)
                  }
                />
                <img
                  src={CancelIcon}
                  title="Cancel"
                  alt="Cancel"
                  className="delete-option-button icon-small hover-gray-bg"
                  onClick={() => setUpdatingOptionData(undefined)}
                />
              </>
            ) : (
              <span
                onDoubleClick={() =>
                  allowEdit
                    ? setUpdatingOptionData({ index, optionText })
                    : {}
                }
              >
                {optionText}
              </span>
            )}

            {allowEdit && updatingOptionData?.index !== index &&
              <>
                <img
                  src={EditIcon}
                  title="Edit"
                  alt="Edit"
                  className="delete-option-button icon-small hover-gray-bg"
                  onClick={() => setUpdatingOptionData({ index, optionText })}
                />
                <img
                  src={DeleteIcon}
                  title="Delete"
                  alt="Delete"
                  className="delete-option-button icon-small hover-gray-bg"
                  onClick={() => removeOption(optionText)}
                />
              </>
            }
          </div>
        )}
        {!selectInput.options.length &&
          <span className="no-options">No options have been added</span>
        }
      </div>
      <fieldset>
        <legend>Conformance Options (optional)</legend>
        <div className="row">
          <label>Expected Value</label>
        </div>
        <div className="row component-editor">
          {allowEdit &&
            <select
              multiple={selectInput.type === StepComponentTypes.SelectInput ? false : true}
              onChange={onExpectedValueChange}
              defaultValue={selectInput.type === StepComponentTypes.SelectInput ? selectInput.expectedValue : selectInput.expectedValue}
            >
              <option></option>
              {selectInput && selectInput.options.length &&
                selectInput.options.map((opt, index) =>
                  <option key={index}>{opt}</option>
                )}
            </select>
          }
          {!allowEdit &&
            <>
              {selectInput.type === StepComponentTypes.SelectInput ?
                <span className="expected-value">{selectInput.expectedValue}</span>
                :
                <span className="expected-value">{selectInput.expectedValue?.join(", ")}</span>
              }
            </>
          }
        </div>
        <div className="row">
          <label>Non-Conformance Instructions</label>
        </div>
        <div className="row component-editor">
          {allowEdit &&
            <input
              ref={nonConformInstructionRef}
              type="text"
              defaultValue={selectInput.nonConform || ""}
              onBlur={onNonConformBlur}
              disabled={shouldConformInstructionDisabled()}
            />
          }
          {!allowEdit &&
            <span>{selectInput.nonConform}</span>
          }
        </div>
      </fieldset>
    </div>
  );
}

export default SelectInputEditor;