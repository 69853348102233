import React from "react";
import "./MainMenu.scoped.scss";
import { useRouteMatch, Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import searchIcon from "media/icons/dls/search.svg";
import newDocIcon from "media/icons/dls/new-doc.svg";
import teamIcon from "media/icons/dls/team.svg";
import documentsIcon from "media/icons/dls/documents.svg";
import approvedDocumentIcon from "media/icons/circled-check.svg";
import reportsIcon from "media/icons/dls/report.svg";
import { AuthCheck, AuthFilter } from "auth/AuthFilter";
import { Roles } from "interfaces/user/UserInterfaces";
import useSelector from "store/useSelector";

const MainMenu: React.FC = () => {
  const {
    enableReportsTab
  } = useSelector(store => store.auth)

  return (
    <div className="menu">
      <AuthCheck
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.ApproverCOE,
            Roles.ApproverTechContent,
            Roles.ApproverOwner,
            Roles.OrgAdmin,
            Roles.TechComm,
            Roles.ProxyOwner,
          ])
          .includeSysAdmins()
        }
      >
        <Link
          to={Routes.SearchStandardWork}
          className={"menu-item " + (useRouteMatch(Routes.SearchStandardWork)?.isExact ? "selected" : "")}
          title="Standard Work"
        >
          <img
            className="icon-medium black-to-slb-blue-filter"
            src={searchIcon}
            alt=""
          />
          <span>Search</span>
        </Link>
      </AuthCheck>
      <AuthCheck
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.OrgAdmin,
            Roles.TechComm,
          ])
          .includeSysAdmins()
        }
      >
        <Link
          to={Routes.NewStandardWork.replace(":mode", "create")}
          className={"menu-item " + (useRouteMatch(Routes.NewStandardWork)?.isExact ? "selected" : "")}
          title="New Standard Work"
        >
          <img
            className="icon-medium black-to-slb-blue-filter"
            src={newDocIcon}
            alt=""
          />
          <span>New SW</span>
        </Link>
      </AuthCheck>
      <AuthCheck
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.ApproverCOE,
            Roles.ApproverTechContent,
            Roles.ApproverOwner,
            Roles.OrgAdmin,
            Roles.TechComm,
            Roles.TechConRev,
            Roles.SMEApprover,
            Roles.ProxyOwner,
          ])
          .includeSysAdmins()
        }
      >
        <Link
          to={Routes.MyApprovals}
          className={"menu-item " + (useRouteMatch(Routes.MyApprovals)?.isExact ? "selected" : "")}
          title="Approvals"
        >
          <img
            className="icon-medium black-to-slb-blue-filter"
            src={approvedDocumentIcon}
            alt=""
          />
          <span>Active Approvals</span>
        </Link>
      </AuthCheck>
      <AuthCheck
        authFilter={AuthFilter.isLoggedIn()
          .hasRole(Roles.OrgAdmin)
          .includeSysAdmins()
        }
      >
        <Link
          to={Routes.UserManagement}
          className={"menu-item " + (useRouteMatch(Routes.UserManagement)?.isExact ? "selected" : "")}
          title="Manage Users"
        >
          <img
            className="icon-medium black-to-slb-blue-filter"
            src={teamIcon}
            alt=""
          />
          <span>Manage Users</span>
        </Link>
      </AuthCheck>
      <AuthCheck
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.OrgAdmin,
          ]).includeSysAdmins()
        }
      >
        <Link
          to={Routes.BatchUpdatesCreator}
          className={"menu-item " + (useRouteMatch(Routes.BatchUpdatesCreator)?.isExact ? "selected" : "")}
          title="Batch Updates"
        >
          <img
            className="icon-medium black-to-slb-blue-filter"
            src={documentsIcon}
            alt=""
          />
          <span>Batch Updates</span>
        </Link>
      </AuthCheck>
      <AuthCheck
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.ReportViewer,
          ]).excludeSysAdmins()
        }
      >
        <Link
          to={Routes.Reports}
          className={"menu-item " + (useRouteMatch(Routes.Reports)?.isExact ? "selected" : "") + (!enableReportsTab ? "hidden" : "")}
          title="PDFs"
        >
          <img
            className="icon-medium black-to-slb-blue-filter"
            src={reportsIcon}
            alt=""
          />
          <span>PDFs</span>
        </Link>
      </AuthCheck>
    </div>
  );
}

export default MainMenu;