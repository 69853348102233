const ppeToolTipDictionary: IndexerToolTip = {
  "M001": "General mandatory action sign",
  "M002": "Refer to instruction manual/booklet",
  "M003": "Wear ear protection",
  "M004": "Wear eye protection",
  "M007": "Opaque eye protection must be worn",
  "M008": "Wear foot protection",
  "M009": "Wear protective gloves",
  "M010": "Wear protective clothing",
  "M011": "Wash your hands",
  "M013": "Wear a face shield",
  "M014": "Wear head protection",
  "M015": "Wear high visibility clothing",
  "M016": "Wear a mask",
  "M017": "Wear respiratory protection",
  "M018": "Wear a safety harness",
  "M019": "Wear a welding mask",
  "M020": "Wear safety belts",
  "M022": "Use barrier cream",
  "M026": "Use protective apron",
  "M030": "Use litter bin",
  "M032": "Use footwear with anti-static or anti-spark features",
  "M047": "Use breathing equipment (SCBA)",
  "M048": "Use gas detector",
  "WSM001": "Wear personal floatation device (PFD) (lifejacket)",
}
interface IndexerToolTip {
  [key: string]: string,
}
export default ppeToolTipDictionary;