import FlowLayout from "components/layout/FlowLayout";
import { Routes } from "components/routing/Routing";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { deleteUserRole, getUserDetails, resetEditUserData } from "store/userManagement/userManagementActions";
import useSelector from "store/useSelector";
import DeleteIcon from "media/icons/dls/delete.svg";
import "./UserDetail.scoped.scss";
import BlockSpinner from "components/common/BlockSpinner";
import { Roles, UserGroups } from "interfaces/user/UserInterfaces";
import UserGroupIcon from "./UserGroupIcon";

const UserDetail: React.FC<RouteComponentProps<{ email: string }>> = (props) => {
  const userEmail = window.decodeURIComponent(props.match.params.email);
  const {
    userManagement: {
      userDetail: {
        email,
        name,
        userDetails,
        getUserDetailOperation
      }
    }
  } = useSelector(store => store);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDetails({ email: userEmail }));

    return () => {
      dispatch(resetEditUserData());
    };
  }, [userEmail, dispatch]);

  let component: JSX.Element | undefined;
  if (getUserDetailOperation && getUserDetailOperation?.isWorking) {
    component = <BlockSpinner />;
  }
  else if (email
    && userDetails
    && userDetails.length > 0) {
    component = (
      <>
        <div className="row header linkbutton">
          <div className="cell roles">
            Roles
          </div>
          <div className="cell orgs">
            Group
          </div>
          <div className="cell orgs">
            RC Editor
          </div>
          <div className="cell orgs">
            Orgs/Plants
          </div>
          <div className="cell orgs">
            Service Types
          </div>
          <div className="cell orgs">
            Equipment
          </div>
          <div className="cell orgs">
            Countries
          </div>
          <div className="cell orgs">
            Epic Equipment
          </div>
          <div className="cell orgs">
            Geo Units
          </div>
          <div className="cell orgs">
            Product Centers
          </div>
          <div>
            Action
          </div>
        </div>
        <div className="flow-body">
          {userDetails
            && userDetails.map(a =>
            (
              a.role !== Roles.ReportViewer &&
              <Link
                className="user row hover-gray-bg"
                key={a.role.concat(a.isRCEditor.toString() + a.isTechUser.toString() + a.isPsdUser.toString() + a.isMfgUser.toString())}
                to={a.role === Roles.TechConRev || a.role === Roles.SMEApprover
                  ? Routes.UserDetail
                    .replace(":email", window.encodeURIComponent(userEmail))
                  : Routes.EditUser
                    .replace(":email", window.encodeURIComponent(userEmail))
                    .replace(":role", window.encodeURIComponent(a.role))
                    .replace(":isTechUser", window.encodeURIComponent(a.isTechUser ? "S" : "N"))
                    .replace(":isPsdUser", window.encodeURIComponent(a.isPsdUser ? "S" : "N"))
                    .replace(":isMfgUser", window.encodeURIComponent(a.isMfgUser ? "S" : "N"))
                    .replace(":isRCEditor", window.encodeURIComponent(a.isRCEditor ? "S" : "N"))}
              >
                <div className="cell roles">
                  <span>{a.role}</span>
                </div>

                <div className="cell orgs">
                  {a.isPsdUser &&
                    <span>
                      <UserGroupIcon
                        userGroup={UserGroups.PSD}
                      />
                    </span>
                  }
                  {a.isTechUser &&
                    <span>
                      <UserGroupIcon
                        userGroup={UserGroups.TLM}
                      />
                    </span>
                  }
                  {a.isMfgUser &&
                    <span>
                      <UserGroupIcon
                        userGroup={UserGroups.MFG}
                      />
                    </span>
                  }
                </div>

                <div className="cell orgs">
                  <span>{a.isRCEditor ? "Yes" : "No"}</span>
                </div>

                <div className="cell orgs">
                  <span>{a.orgs.map(x => x.code).join(", ")}</span>
                </div>

                <div className="cell orgs">
                  <span>{a.serviceType.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell orgs">
                  <span>{a.equipment.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell orgs">
                  <span>{a.country.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell orgs">
                  <span>{a.epicEquipment.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell orgs">
                  <span>{a.geoUnit.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell orgs">
                  <span>{a.productCenter.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell orgs">
                  {a.role !== Roles.TechConRev && a.role !== Roles.SMEApprover &&
                    <img
                      src={DeleteIcon}
                      alt="Delete"
                      title="Delete"
                      className="icon-small"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(deleteUserRole({ email: userEmail, role: a.role, isTechUser: a.isTechUser, isPsdUser: a.isPsdUser, isMfgUser: a.isMfgUser }))
                      }}
                    />
                  }
                </div>
              </Link>
            ))}
        </div>
      </>
    )
  }
  else {
    component = <h4>No Roles Assigned to the user</h4>
  }
  return (
    <FlowLayout
      header={(
        <div>
          <h2 className="page-title">
            <span>{name} ({userEmail})</span>
          </h2>
          <div className="linkbutton">
            <Link
              className="primary-button linkbutton"
              to={Routes.EditUser
                .replace(":email", window.encodeURIComponent(userEmail))
                .replace(":role", window.encodeURIComponent("NoRole"))
                .replace(":isTechUser", window.encodeURIComponent("N"))
                .replace(":isPsdUser", window.encodeURIComponent("S"))
                .replace(":isMfgUser", window.encodeURIComponent("N"))
                .replace(":isRCEditor", window.encodeURIComponent("N"))}
            >
              Assign Role
            </Link>
          </div>
        </div>
      )}
    >
      {component}
    </FlowLayout>
  );

}

export default UserDetail;