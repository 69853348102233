import React from "react";
import Modal from "components/common/Modal";
import "./MFGUploadConnectedSWIsErrorModal.scoped.scss";
import useSelector from "store/useSelector";

interface IMFGUploadConnectedSWIsErrorModalProps {
  onClose(): void,
}

const MFGUploadConnectedSWIsErrorModal: React.FC<IMFGUploadConnectedSWIsErrorModalProps> = ({
  onClose,
}) => {
  const {
    manageSW: {
      connectedSWIsUploadErrors
    },
  } = useSelector(store => store);

  if (connectedSWIsUploadErrors.length === 0) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      header="Upload Errors"
      controls={(
        <button
          className="primary-button"
          onClick={() => onClose()}
        >
          OK
        </button>
      )}
    >
      <div className="gridTable">
        <table>
          <tbody>
            <tr>
              <th>Material Code</th>
              <th>Error</th>
            </tr>
          
            {connectedSWIsUploadErrors.map((x, ix) => 
              <tr key={ix}>
                <td>{x.materialCode}</td>
                <td>{x.errorMsg}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}

export default MFGUploadConnectedSWIsErrorModal;