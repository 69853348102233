import React from "react";
import "./ApprovalTypeTab.scoped.scss";

interface IApprovalTypeTabProps {
  label: string,
  isActive: boolean,
  onClick(): void,
}

const ApprovalTypeTab: React.FC<IApprovalTypeTabProps> = ({
  label,
  isActive,
  onClick,
}) =>
  <span
    onClick={onClick}
    className={`tab ${isActive ? "active" : ""}`}
    title={label}
  >
    <label className={`label ${isActive ? "active" : ""}`}>{label}</label>
  </span>;

export default ApprovalTypeTab;