import React, { useState } from "react";
import useSelector from "store/useSelector";
import "./ManageSWRefDocs.scoped.scss";
import RefDoc from "./RefDoc";
import { useDispatch } from "react-redux";
import { addNewReferenceDoc, addNewReferenceDocTable, updateRefDocSortOrders } from "store/manageSW/manageSWActions";
import sortIcon from "media/icons/dls/sort.svg";
import ReorderModal, { ISortableItem } from "components/common/reorderModal/ReorderModal";
import FlowLayout from "components/layout/FlowLayout";
import { SWTypes } from "interfaces/sw/SWInterfaces";

interface IManageSWRefDocsProps {
  isDisabled: boolean,
}

const ManageSWRefDocs: React.FC<IManageSWRefDocsProps> = ({ isDisabled }) => {
  const {
    manageSW: {
      SW: {
        referenceDocs,
        type: swType,
      }
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const [isReorderVisible, setIsReorderVisible] = useState(false);

  return (
    <FlowLayout
      footer={!isDisabled
        ? (
          <div className="manage-ref-docs">
            <div className="buttons">
              {!isDisabled
                && referenceDocs.length > 0 &&
                <button
                  className="secondary-button"
                  title={"Reorder"}
                  onClick={() => setIsReorderVisible(true)}
                >
                  <img
                    alt="Sort"
                    title="Sort"
                    className="icon-small sort-icon"
                    src={sortIcon}
                  />
                  Reorder
                </button>
              }
              {!isDisabled &&
                <button
                  className="primary-button"
                  title={"Add Ref Doc"}
                  onClick={() => dispatch(addNewReferenceDoc({
                    type: "File",
                  }))}
                >
                  + Add Ref Doc
                </button>
              }
              {!isDisabled &&
                <button
                  className="primary-button"
                  title={"Add Table"}
                  onClick={() => dispatch(addNewReferenceDocTable())}
                >
                  + Add Table
                </button>
              }
              {!isDisabled &&
                <button
                  className="primary-button"
                  title={"Add Link"}
                  onClick={() => dispatch(addNewReferenceDoc({
                    type: "Link",
                  }))}
                >
                  + Add Link
                </button>
              }
            </div>
            {isReorderVisible &&
              <ReorderModal
                title="Reorder Ref Docs"
                items={referenceDocs
                  .slice()
                  .sort((a, b) => {
                    const aSort = a.sortOrder || a.id;
                    const bSort = b.sortOrder || b.id;

                    if (!aSort
                      || !bSort) {
                      return 0;
                    }

                    return aSort < bSort ? -1 : 1;
                  }).map(item => ({
                    label: item.label || item.refDocData,
                    sortOrder: item.sortOrder || item.id || 0,
                    guid: item.guid,
                  }))
                }
                onCancel={() => setIsReorderVisible(false)}
                onSave={(sortData: ISortableItem[]) => {
                  dispatch(updateRefDocSortOrders(sortData.map((x, ix) => ({
                    guid: x.guid,
                    newIndex: ix,
                  }))));
                  setIsReorderVisible(false);
                }}
              />
            }
          </div>
        ) : undefined
      }
    >
      <div
        className="holder"
      >
        <div className="ref-docs">
          {referenceDocs.map(refDoc =>
            <RefDoc
              key={refDoc.guid}
              refDoc={refDoc}
              disabled={isDisabled || (swType === SWTypes.LCL && !refDoc.lcl)}
            />
          )}
        </div>
      </div>
    </FlowLayout>
  );
}

export default ManageSWRefDocs;