import {
  IFormulaInputComponent,
  INumberInputComponent,
  IStep,
  SWTypes,
} from "interfaces/sw/SWInterfaces";

import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateFormulaInput } from "store/manageSW/manageSWActions";
import "./FormulaInputEditor.scoped.scss";
import useSelector from "store/useSelector";

import { loadMeters, loadUoms } from "store/masterData/masterDataActions";

import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

interface IFormulaInputEditorProps {
  formulaComponent: IFormulaInputComponent;
  allowEdit: boolean;
  stepGuid: string;
  labelText: string;
  swType: SWTypes;
  availableNumberInputs: INumberInputComponentWithStep[];
  epicEquipment: IMasterDataItem[];
  stepLocation?: string;
}

export interface INumberInputComponentWithStep {
  step: IStep;
  component: INumberInputComponent;
  stepIndex: number;
}

const FormulaInputEditor: FC<IFormulaInputEditorProps> = ({
  allowEdit,
  formulaComponent,
  stepGuid,
  labelText,
  swType,
  availableNumberInputs,
  epicEquipment,
  stepLocation
}) => {
  const dispatch = useDispatch();

  const {
    masterData: { uoms, meters },
  } = useSelector((store) => store);



  useEffect(() => {
    dispatch(loadUoms({ onlyActive: true }));
  }, []);

  useEffect(() => {
    if (epicEquipment.length > 0) {
      dispatch(loadMeters({ onlyActive: true, equipmentCodes: epicEquipment, location: "1:Global" }));
    }
  }, [epicEquipment]);

  const filterMeters = stepLocation == undefined || stepLocation == null || stepLocation === "" || stepLocation === "1:Global" ? meters.items : meters.items.filter(t => t.parentGuid === stepLocation);

  const operators = ["+", "-", "*", "/", "(", ")"];

  const appendToFormula = (value: string) => {
    dispatch(
      updateFormulaInput({
        stepGuid,
        component: {
          ...formulaComponent,
          formula: (formulaComponent.formula ?? "")
            .concat(" " + value)
            .trimStart(),
        },
      })
    );
  };

  const [selectedNumberInputGuid, setSelectedNumberInputGuid] =
    useState<string>();

  const addNumberInputToFormula = () => {
    const numberInput = availableNumberInputs.find(
      (n) => n.component.guid === selectedNumberInputGuid
    );

    if (numberInput) {
      const appendString = `<<${numberInput.stepIndex}>>`;
      appendToFormula(appendString);
      setSelectedNumberInputGuid("");
    }
  };

  const [enteredNumber, setEnteredNumber] = useState<string>("");

  const enterNumber = () => {
    if (!isNaN(Number(enteredNumber))) {
      appendToFormula(Number(enteredNumber).toString());
      setEnteredNumber("");
    }
  };

  const clearFormula = () => {
    dispatch(
      updateFormulaInput({
        stepGuid,
        component: {
          ...formulaComponent,
          formula: "",
        },
      })
    );
  };

  const backspace = () => {
    if (!formulaComponent.formula?.trim()) {
      return;
    }

    if (formulaComponent.formula.endsWith(">>")) {
      const lastNumberInputIndex = formulaComponent.formula.lastIndexOf("<<");

      const newFormula = formulaComponent.formula
        .substring(0, lastNumberInputIndex)
        .trimEnd();

      dispatch(
        updateFormulaInput({
          stepGuid,
          component: {
            ...formulaComponent,
            formula: newFormula,
          },
        })
      );

      return;
    }

    let lastNumberIndex = -1;

    for (let i = formulaComponent.formula.length - 1; i >= 0; i--) {
      if (!isNaN(Number(formulaComponent.formula.substring(i)))) {
        lastNumberIndex = i;
      } else {
        break;
      }
    }

    if (lastNumberIndex >= 0) {
      const newFormula = formulaComponent.formula
        .substring(0, lastNumberIndex)
        .trimEnd();

      dispatch(
        updateFormulaInput({
          stepGuid,
          component: {
            ...formulaComponent,
            formula: newFormula,
          },
        })
      );

      return;
    }

    dispatch(
      updateFormulaInput({
        stepGuid,
        component: {
          ...formulaComponent,
          formula: formulaComponent.formula
            .substring(0, formulaComponent.formula.length - 1)
            .trimEnd(),
        },
      })
    );
  };

  const readOnlyUom: boolean = useMemo(() => {
    const meterItem = filterMeters.find(
      (m) => m.guid === formulaComponent.meterId
    );

    const uomItem = uoms.items.find((u) => u.value === meterItem?.value);

    return !!uomItem;
  }, [uoms.items, filterMeters, formulaComponent.meterId]);

  const renderUom = () => {
    if (!allowEdit) {
      return <span>{formulaComponent.uom}</span>;
    }

    if (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC) {
      return (
        <select
          className="uom"
          onChange={(e) => {
            const uomItem = uoms.items.find(
              (u) => u.value === e.target.value
            ) ?? {
              code: "",
              guid: "",
              value: e.target.value,
              active: true,
            };

            return dispatch(
              updateFormulaInput({
                stepGuid,
                component: {
                  ...formulaComponent,
                  uom: uomItem.value,
                  uomCode: uomItem.code ?? "",
                },
              })
            );
          }}
          value={formulaComponent.uom ?? ""}
          disabled={!allowEdit || readOnlyUom}
        >
          <option value="" />
          {uoms.items.map((t) => (
            <option key={t.guid} value={t.value}>
              {t.value}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <input
          type="text"
          className="uom"
          value={formulaComponent.uom ?? ""}
          onChange={(e) =>
            dispatch(
              updateFormulaInput({
                stepGuid,
                component: {
                  ...formulaComponent,
                  uom: e.target.value,
                  uomCode: "",
                },
              })
            )
          }
          disabled={!allowEdit}
        />
      );
    }
  };

  const renderMeter = () => {
    if (!allowEdit) {
      return <span>{formulaComponent.meterCode}</span>;
    }

    if (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC) {
      return (
        <select
          className="meter"
          onChange={(e) => {
            const meterItem = filterMeters.find(
              (m) => m.guid === e.target.value
            ) ?? {
              guid: e.target.value,
              value: e.target.value,
              active: true,
            };

            const uomItem = uoms.items.find(
              (u) => u.value === meterItem?.value
            );

            return dispatch(
              updateFormulaInput({
                stepGuid,
                component: {
                  ...formulaComponent,
                  meterId: meterItem.guid,
                  meterCode: meterItem.code,
                  uom: uomItem?.value ?? formulaComponent.uom,
                  uomCode: uomItem?.code ?? formulaComponent.uomCode,
                },
              })
            );
          }}
          value={formulaComponent.meterId ?? ""}
        >
          <option value="" key=""></option>
          {filterMeters.map((t) => (
            <option key={t.guid + "-" + t.code} value={t.guid}>
              {t.code}
            </option>
          ))}
        </select>
      );
    }
  };

  return (
    <>
      <div className="flex-row">
        <div className="full-width">
          <div>
            <label className="mandatory">{labelText}</label>
          </div>
          <div className="flex-row-no-padding">
            {allowEdit ? (
              <input
                type="text"
                defaultValue={formulaComponent.label}
                onBlur={(e) =>
                  dispatch(
                    updateFormulaInput({
                      stepGuid,
                      component: {
                        ...formulaComponent,
                        label: e.target.value,
                      },
                    })
                  )
                }
              />
            ) : (
              <span>{formulaComponent.label}</span>
            )}
          </div>
        </div>
      </div>
      <div className="flex-row">
        <div>
          <div>
            <label>Meter</label>
          </div>
          <div>{renderMeter()}</div>
        </div>
        <div>
          <div>
            <label>UOM</label>
          </div>
          <div>{renderUom()}</div>
        </div>
      </div>
      {allowEdit && (
        <>
          <div className="flex-row">
            <div>
              <div>
                <label>Select Input</label>
              </div>
              <div>
                <select
                  onChange={(e) => setSelectedNumberInputGuid(e.target.value)}
                  value={selectedNumberInputGuid}
                >
                  <option value=""></option>
                  {availableNumberInputs.map((numberInputComponent) => (
                    <option
                      key={numberInputComponent.component.guid}
                      value={numberInputComponent.component.guid}
                    >
                      {numberInputComponent.step.stepNumber}.{" "}
                      {numberInputComponent.step.title}:{" "}
                      {numberInputComponent.component.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="add-to-formula">
                <button
                  className="primary-button"
                  onClick={addNumberInputToFormula}
                  disabled={!selectedNumberInputGuid}
                >
                  Add to Formula
                </button>
              </div>
            </div>
            <div>
              <div>
                <label>Select Operation</label>
              </div>
              <div className="operator-row">
                {operators.map((operator) => (
                  <button
                    key={operator}
                    className="secondary-button operator-button"
                    onClick={() => appendToFormula(operator)}
                  >
                    {operator}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <div>
                <label>Enter Number</label>
              </div>
              <div>
                <input
                  type="text"
                  value={enteredNumber}
                  onChange={(e) => setEnteredNumber(e.target.value)}
                />
              </div>
              <div className="add-to-formula">
                <button
                  className="primary-button"
                  onClick={enterNumber}
                  disabled={!enteredNumber || isNaN(Number(enteredNumber))}
                >
                  Add to Formula
                </button>
              </div>
            </div>
          </div>
          <div className="flex-row button-row">
            <button className="primary-button" onClick={clearFormula}>
              Clear Formula
            </button>
            <button className="primary-button" onClick={backspace}>
              Backspace
            </button>
          </div>
        </>
      )}

      <div className="flex-row">
        <div className="full-width">
          <div>
            <label>Formula</label>
          </div>
          <div className="flex-row-no-padding">
            {allowEdit ? (
              <input
                type="text"
                value={formulaComponent.formula ?? ""}
                readOnly
                disabled={!allowEdit}
              />
            ) : (
              <span>{formulaComponent.formula}</span>
            )}
          </div>
        </div>
      </div>

      <div className="flex-row">
        <div>
          <div>
            <label>Lower Limit</label>
          </div>
          <div>
            {allowEdit ? (
              <input
                type="number"
                defaultValue={formulaComponent.lowerLimit?.toString()}
                onBlur={(e) =>
                  dispatch(
                    updateFormulaInput({
                      stepGuid,
                      component: {
                        ...formulaComponent,
                        lowerLimit:
                          !e.target.value || isNaN(Number(e.target.value))
                            ? undefined
                            : Number(e.target.value),
                      },
                    })
                  )
                }
                disabled={!allowEdit}
              />
            ) : (
              <span>{formulaComponent.lowerLimit}</span>
            )}
          </div>
        </div>
        <div>
          <div>
            <label>Upper Limit</label>
          </div>
          <div>
            {allowEdit ? (
              <input
                type="number"
                defaultValue={formulaComponent.upperLimit?.toString()}
                onBlur={(e) =>
                  dispatch(
                    updateFormulaInput({
                      stepGuid,
                      component: {
                        ...formulaComponent,
                        upperLimit:
                          !e.target.value || isNaN(Number(e.target.value))
                            ? undefined
                            : Number(e.target.value),
                      },
                    })
                  )
                }
                disabled={!allowEdit}
              />
            ) : (
              <span>{formulaComponent.upperLimit}</span>
            )}
          </div>
        </div>
      </div>
      <div className="flex-row">
        <div className="full-width">
          <div>
            <label>Non-Conformance Instructions</label>
          </div>
          <div className="flex-row-no-padding">
            {allowEdit ? (
              <input
                type="text"
                value={formulaComponent.nonConform ?? ""}
                disabled={!allowEdit}
                onChange={(e) =>
                  dispatch(
                    updateFormulaInput({
                      stepGuid,
                      component: {
                        ...formulaComponent,
                        nonConform: e.target.value,
                      },
                    })
                  )
                }
              />
            ) : (
              <span>{formulaComponent.nonConform}</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormulaInputEditor;
