import React, { useEffect, useState } from "react";
import searchIcon from "media/icons/dls/search.svg";
import addIcon from "media/icons/dls/add.svg";
import "./SearchUser.scoped.scss";
import { useDispatch } from "react-redux";
import { setSearchLDAPManageUsersText } from "store/userManagement/userManagementActions";
import useSelector from "store/useSelector";
import useDebounce from "utilities/useDebounce";
import BlockSpinner from "components/common/BlockSpinner";

interface ISearchUserProps {
    setSelectedUserFunc(email: string): void;
}

const SearchUser: React.FC<ISearchUserProps> = ({ setSelectedUserFunc }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 750);
    const manageUsersData = useSelector(store => store.userManagement.manageUsersData);
    const { getUsersOperation, ldapUserList } = useSelector(store => store
        .userManagement
        .manageUsersData);
    const currentUser = useSelector(store => store.auth.currentUser.email);
    const dispatch = useDispatch();
    const [selectedUser, setSelectedUser] = useState<string>("");

    useEffect(() => {
        //setSelectedUser(""); - commented out to fix bug: 4527102
        dispatch(setSearchLDAPManageUsersText(debouncedSearchTerm.trim()));

    }, [debouncedSearchTerm, dispatch]);

    let component: JSX.Element | undefined;
    let titleArea: JSX.Element = (
        <label className="tech-label">
            {"SME (Optional)"}{selectedUser}
        </label>);

    if (getUsersOperation?.isWorking) {
        component = (
            <BlockSpinner />
        );
    } else if (getUsersOperation?.errorMessage) {
        component = (
            <>
                {titleArea}
                <br />
                <label className="error">
                    {getUsersOperation.errorMessage}
                </label>
            </>
        );
    } else if (!ldapUserList.length) {
        component = (
            <>
                {titleArea}
                <br />
                <label>No users found.</label>
            </>
        );
    } else {
        component = (
            <>
                {titleArea}
                {ldapUserList.filter(x => x.email !== currentUser).map(r =>
                    <div
                        className="user"
                        key={r.email}
                    >
                        <span>
                            {r.name} ({r.email})
                        </span>
                        <img
                            src={addIcon}
                            className="add-button icon-medium hover-gray-bg"
                            onClick={() => onUserSelect(r.email, r.name)}
                            alt="Add"
                        />
                    </div>
                )}
            </>
        );
    }

    const onUserSelect = (email: string, name: string) => {
        setSelectedUserFunc(email);
        setSelectedUser("(" + name + ")");
    }

    return (
        <>
            <br />
            <div className="search-user-bar">
                <div className="input-holder">
                    <input
                        type="text"
                        id="search-user"
                        placeholder="Search by name or email..."
                        disabled={manageUsersData.getUsersOperation?.isWorking}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <img
                        src={searchIcon}
                        alt="Search"
                        className="icon-small"
                    />
                </div>
            </div>
            <div className="results">
                {component}
            </div>
        </>
    );
}

export default SearchUser;