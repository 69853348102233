import gojs from "gojs";
import { ReactDiagram } from "gojs-react";
import React, { FC, useCallback } from "react";
import {
  IOperationTreeModel,
  IOperationTreeNode,
} from "store/masterData/masterDataTypes";
import "./RoutingDiagramViewer.css";

export interface RoutingDiagramViewerProps {
  operationTree: IOperationTreeModel;
  onOperationClicked?(operation: IOperationTreeNode): void;
}

const RoutingDiagramViewer: FC<RoutingDiagramViewerProps> = ({
  operationTree,
  onOperationClicked,
}) => {
  const handleOperationClicked = useCallback(
    (operation: IOperationTreeNode) => {
      if (onOperationClicked) {
        onOperationClicked(operation);
      }
    },
    [onOperationClicked]
  );

  const initializeDiagram = useCallback(function (): gojs.Diagram {
    const $ = gojs.GraphObject.make;

    const diagram = $(gojs.Diagram, {
      isReadOnly: true,
      "clickCreatingTool.archetypeNodeData": {
        text: "new node",
        color: "lightblue",
      },
      model: new gojs.GraphLinksModel({
        linkKeyProperty: "key",
        nodeKeyProperty: "taskListNode",
      }),
      layout: $(gojs.LayeredDigraphLayout),
    });

    diagram.nodeTemplate = $(
      gojs.Node,
      "Auto",
      {
        fromSpot: gojs.Spot.Right,
        toSpot: gojs.Spot.Left,
      },
      new gojs.Binding("location", "location", gojs.Point.parse).makeTwoWay(
        gojs.Point.stringify
      ),
      $(
        gojs.Shape,
        "RoundedRectangle",
        { name: "SHAPE", fill: "#0114DC", strokeWidth: 0 },
        new gojs.Binding("fill", "color")
      ),
      $(
        gojs.TextBlock,
        { margin: 8, editable: true, stroke: "white" },
        new gojs.Binding("text", "text").makeTwoWay()
      )
    );

    diagram.linkTemplate = $(
      gojs.Link,
      { routing: gojs.Link.AvoidsNodes, curve: gojs.Link.JumpOver, corner: 5 },
      $(gojs.Shape),
      $(gojs.Shape, { toArrow: "Standard" })
    );

    diagram.addDiagramListener("ObjectSingleClicked", (e) => {
      const originalData = e.subject.part.data as IOperationTreeNode;
      
      const operationNode: IOperationTreeNode = {
        materialCode: originalData.materialCode,
        plantCode: originalData.plantCode,
        group: originalData.group,
        groupCounter: originalData.groupCounter,
        sequence: originalData.sequence,
        taskListNode: originalData.taskListNode,
        operation: originalData.operation,
        operationText: originalData.operationText,
        text: originalData.text,
      };

      if(operationNode.taskListNode >= 0)
      {
        handleOperationClicked(operationNode);
      }
    });

    diagram.padding = 100;

    return diagram;
  }, []);

  return (
      <ReactDiagram
        initDiagram={initializeDiagram}
        divClassName="routing-diagram-viewer"
        nodeDataArray={operationTree.nodes}
        linkDataArray={operationTree.links}
      />
  );
};

export default RoutingDiagramViewer;
