export enum SortDirection {
  Asc = "Asc",
  Desc = "Desc",
}

export enum PdfTypes {
  Normal= "Normal",
  Reference = "Reference",
  Standard = "Standard",
  Consolidated = "Consolidated",
  FullTraining = "FullTraining",
  Full = "Full",
  Field = "Field",
  CCQ = "CCQ",
  CompetencyCPA = "CompetencyCPA",
  Executable = "Executable"
}