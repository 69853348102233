import React from "react";
import { IMicrosoftStreamVideoComponent } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import "./ComponentEditor.scss";
import { updateMSSVideo } from "store/manageSW/manageSWActions";
import "./MicrosoftStreamVideoEditor.scoped.scss";
import { showErrorToast } from "store/toast/toastActions";

interface IMicrosoftStreamVideoEditorProps {
  msVideo: IMicrosoftStreamVideoComponent,
  allowEdit: boolean,
  stepGuid: string,
}

const MicrosoftStreamVideoEditor: React.FC<IMicrosoftStreamVideoEditorProps> = ({
  msVideo,
  allowEdit,
  stepGuid,
}) => {
  const dispatch = useDispatch();

  const onUpdateGuid = (videoGuid: string) => {
    dispatch(updateMSSVideo({
      stepGuid,
      component: {
        ...msVideo,
        videoGuid,
      },
    }));
  };

  const askForGuid = () => {
    const newGuid = window.prompt("Paste the video Embed Code:",
      msVideo.videoGuid);

    if (newGuid === null) {
      return;
    }

    if (!newGuid?.trim()) {
      dispatch(showErrorToast("Invalid Microsoft Stream Video entered."));
      return;
    }

    // Search the entered value for a guid.
    const match = newGuid.match(/(?<=src=").*?(?=[\*"])/);

    if (!match
      || !match.length) {
      dispatch(showErrorToast("Invalid Microsoft Stream Video entered."));
      return;
    }
    alert(match[0]);
    onUpdateGuid(match[0]);
  }

  return (
    <div className="component-editor">
      <label className="block">Video</label>
      {!!msVideo.videoGuid && (
        <>
          <iframe
            className="embed"
            title={msVideo.videoGuid}
            src={msVideo.videoGuid}
            allowFullScreen
          ></iframe>
          {allowEdit &&
            <button
              className="primary-button"
              onClick={askForGuid}
            >
              Change Video
            </button>
          }
        </>
      )}
      {!msVideo.videoGuid && (
        <div
          className="embed empty"
        >
          {allowEdit &&
            <button
              className="primary-button"
              onClick={askForGuid}
            >
              Set Video
            </button>
          }
          {!allowEdit &&
            <span>No video specified</span>
          }
        </div>
      )}
    </div>
  );
}

export default MicrosoftStreamVideoEditor;