import React from "react";
import { useDispatch } from "react-redux";
import { IAudioInputComponent } from "../../../../../interfaces/sw/SWInterfaces";
import { updateAudioInput } from "../../../../../store/manageSW/manageSWActions";
import "./ComponentEditor.scss";

interface IAudioInputEditorProps {
  audioInput: IAudioInputComponent,
  allowEdit: boolean,
  stepGuid: string,
  labelText: string,
}

const AudioInputEditor: React.FC<IAudioInputEditorProps> = ({
    audioInput,
    allowEdit,
    stepGuid,
    labelText,
  }) => {
  const dispatch = useDispatch();

  return (
    <div className="component-editor">
      <label className="mandatory block">{labelText}</label>
      {allowEdit &&
        <input
          type="text"
          defaultValue={audioInput.label}
          onBlur={(e) => dispatch(updateAudioInput({
            stepGuid,
            component: {
              ...audioInput,
              label: e.target.value,
            },
          }))}
        />
      }
      {!allowEdit &&
        <span>{audioInput.label}</span>
      }
    </div>
  );
}

export default AudioInputEditor;