import { throwIfResponseError } from "apis/apiUtilities";
import config from "config";
import { IProxyApprover } from "store/proxyApprovers/proxyApproversTypes";

import {
  authDeleteJson,
  authGetJson,
  authPostJson,
} from "utilities/msalFetches";

import {
  formatProxyApproverRequest,
  formatProxyApproverResponse,
} from "./proxyApproversFormatters";

class ProxyApproversApi {
  public async getProxyApprover() {
    const response = await authGetJson(
      config.endpoints.proxyApprovers.getProxyApprover
    );

    await throwIfResponseError(response);
    return await formatProxyApproverResponse(response);
  }

  public async postProxyApprover(status: IProxyApprover) {
    const response = await authPostJson(
      config.endpoints.proxyApprovers.postProxyApprover,
      JSON.stringify(formatProxyApproverRequest(status))
    );

    await throwIfResponseError(response);
  }

  public async deleteProxyApprover() {
    const response = await authDeleteJson(
      config.endpoints.proxyApprovers.deleteProxyApprover,
      {}
    );

    await throwIfResponseError(response);
  }
}

export default new ProxyApproversApi();