import Banner, { BannerType } from "components/common/Banner";
import BlockSpinner from "components/common/BlockSpinner";
import FlowLayout from "components/layout/FlowLayout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  loadRCUpdateApproval,
  setSubmitRCUpdateApprovalOperation,
  submitRCUpdateApproval,
} from "store/approvals/approvalsActions";
import useSelector from "store/useSelector";
import ViewRCUpdateApprovalDetails from "./ViewRCUpdateApprovalDetails";
import "./ViewRCUpdateApproval.scoped.scss";
import {
  IRCUpdateApproval,
  IRCUpdateApprovalResponse,
} from "store/approvals/approvalsTypes";
import { ApprovalStatuses } from "interfaces/approvals/approvalInterfaces";
import { Routes } from "components/routing/Routing";
import Modal from "components/common/Modal";
import { showErrorToast } from "store/toast/toastActions";

interface IViewBatchApprovalProps {
  id: string;
}

const ViewBatchApproval: React.FC<
  RouteComponentProps<IViewBatchApprovalProps>
> = (props) => {
  const history = useHistory();
  const urlId = props.match.params.id;

  const {
    approvals: {
      currentRCUpdateApproval,
      loadApprovalOperation,
      submitRCUpdateApprovalOperation,
    },
  } = useSelector((store) => store);

  const dispatch = useDispatch();

  useEffect(() => {
    const id = parseInt(urlId, 10);

    if (urlId && !isNaN(id) && id.toString() === urlId) {
      dispatch(
        loadRCUpdateApproval({
          approvalId: id,
        })
      );
    }
  }, [dispatch, urlId]);

  useEffect(() => {
    if (
      submitRCUpdateApprovalOperation &&
      !submitRCUpdateApprovalOperation.isWorking &&
      submitRCUpdateApprovalOperation.wasSuccessful
    ) {
      history.push(Routes.MyApprovals);
    }
  }, [submitRCUpdateApprovalOperation]);

  useEffect(() => {
    return () => {
      dispatch(setSubmitRCUpdateApprovalOperation(undefined));
    };
  }, []);

  const [rejectReason, setRejectReason] = useState<string>();
  
  const [approvalWithRejections, setApprovalWithRejections] = 
    useState<IRCUpdateApproval>();
  
  useEffect(() => {
    setRejectReason(undefined);
  }, [approvalWithRejections]);

  if (loadApprovalOperation?.isWorking) {
    return <BlockSpinner />;
  } else if (loadApprovalOperation?.errorMessage) {
    return (
      <Banner type={BannerType.Error}>
        {loadApprovalOperation.errorMessage}
      </Banner>
    );
  } else if (!currentRCUpdateApproval) {
    return (
      <Banner type={BannerType.Error}>
        No approval is currently loaded. Please refresh the page.
      </Banner>
    );
  }

  const handleSubmit = (rcUpdateApproval: IRCUpdateApproval) => {
    const allAreApprovedOrRejected = !rcUpdateApproval.details.find(
      (d) =>
        d.status !== ApprovalStatuses.Approved &&
        d.status !== ApprovalStatuses.Rejected
    );

    if (!allAreApprovedOrRejected) {
      return;
    }

    setApprovalWithRejections(undefined);

    const hasRejectedItems = !!rcUpdateApproval.details.find(
      (d) => d.status !== ApprovalStatuses.Approved
    );

    if (hasRejectedItems && !rejectReason?.trim()) {
      setApprovalWithRejections(rcUpdateApproval);
      return;
    }

    const approvalResponses: IRCUpdateApprovalResponse[] =
      rcUpdateApproval.details.map((detailsItem) => ({
        approved: detailsItem.status === ApprovalStatuses.Approved,
        swId: detailsItem.swId,
        smeApprover: detailsItem.sMEApprover,
        techContRev: detailsItem.techContRev,

        rejectReason:
          detailsItem.status === ApprovalStatuses.Approved
            ? undefined
            : rejectReason,
      }));

    // validate if all additonal approvers are same person.
    let uniqueTechContentRevs = Array.from(new Set(approvalResponses.map((item) => item.techContRev)));
    let uniqueSMEs = Array.from(new Set(approvalResponses.map((item) => item.smeApprover)));

    if (uniqueTechContentRevs.filter(x => x !== "").length > 1 || uniqueSMEs.filter(x => x !== "").length > 1) {
      dispatch(showErrorToast("All approvers must be the same person."));
      return;
    }

    dispatch(
      submitRCUpdateApproval({ approvalId: parseInt(urlId), approvalResponses })
    );
  };

  return (
    <FlowLayout
      header={
        <div className="toolbar">
          <h2>RC Update Approval</h2>
        </div>
      }
    >
      <ViewRCUpdateApprovalDetails
        rcUpdateApproval={currentRCUpdateApproval}
        onSubmit={handleSubmit}
      />

      {!!approvalWithRejections && (
        <Modal
          isOpen={true}
          header="Specify reason for rejection:"
          controls={
            <>
              <button
                className="primary-button"
                onClick={() => handleSubmit(approvalWithRejections)}
                disabled={!rejectReason?.trim()}
              >
                Continue
              </button>
              <button
                className="secondary-button"
                onClick={() => setApprovalWithRejections(undefined)}
              >
                Cancel
              </button>
            </>
          }
        >
          <textarea
            maxLength={500}
            onChange={(e) => setRejectReason(e.target.value)}
            value={rejectReason}
            placeholder="Enter rejection comment..."
            className="rejection-comment"
          />
          <span className="charLimit">(500 character limit)</span>
        </Modal>
      )}
    </FlowLayout>
  );
};

export default ViewBatchApproval;