import React from "react";
import SWTypeIcon from "components/sw/search/SWTypeIcon";
import pdfIcon from "media/icons/dls/pdf.svg";
import AddIcon from "media/icons/dls/add.svg";
import { ISWSummaryItem } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { getPDF } from "store/swList/swListActions";
import "./RCNoticeList.scoped.scss";

interface IRCNoticeList {
    rcNoticeSWs: ISWSummaryItem[],
    AddRCNoticeToSw?(rcNoticeSWId: number, rcNoticeSWGuid: string, rcNoticeSWVersion: number, source: string): void,
}

const RCNoticeList: React.FC<IRCNoticeList> = (props) => {
    const WRSystem: string = "WR";
    const dispatch = useDispatch();
    const generatePDF = (swid: number) => {
        dispatch(
            getPDF({
                swId: swid,
                generateDefault: true
            })
        );
    }
    return (
        <div>
            <div className="row header">
                <div className="cell sw-name">
                    Standard Work Name
                </div>
                <div className="cell width20">
                    Guid
                </div>
                <div className="cell width10">
                    Version
                </div>
                <div className="cell buttons">
                </div>
            </div>
            <div className="list-body">
                {
                    props.rcNoticeSWs.map((row, index) => (
                        <div className="sw-header" key={index}>
                            <div id={"testttchc" + index} className="cell sw-name" key={"swname" + index}>
                                <div className="title-holder" key={"tholder" + index}>
                                    <SWTypeIcon type={row.type} key={"swtype" + index} />
                                    <div key={"swtitle" + index} className="sw-title">
                                        {row.title}
                                        <span key={"desc" + index} className="description">
                                            {row.description}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id={row.guid} className="cell width19">{row.guid}</div>
                            <div id={row.version.toString() + index} className="cell width10">{row.version}</div>
                            <div className="cell buttons icon-small">
                                <img
                                    src={pdfIcon}
                                    alt="PDF"
                                    title="PDF"
                                    className="icon-small"
                                    onClick={() => generatePDF(row.id)}
                                />
                            </div>
                            <div className="cell buttons icon-small">
                                <img
                                    src={AddIcon}
                                    alt="Add to SW"
                                    title="Add to SW"
                                    className="icon-small"
                                    onClick={() => props.AddRCNoticeToSw !== undefined ? props.AddRCNoticeToSw(row.id, row.guid, row.version, WRSystem) : null}
                                />

                            </div>
                        </div>
                    ))}
            </div>
        </div>);

}

export default RCNoticeList;


