import Banner, { BannerType } from "components/common/Banner";
import { INumberInputComponent } from "interfaces/sw/SWInterfaces";
import React, { useState } from "react";
import TextOrNumberBox, { TextInputTypes } from "../controls/TextOrNumberBox";
import "./NumberInput.scoped.scss";

interface INumberInputProps {
  component: INumberInputComponent,
  isDisabled: boolean,
}

const NumberInput: React.FC<INumberInputProps> = ({ component, isDisabled }) => {
  const [value, setValue] = useState("");
  const [isOutOfRange, setIsOutOfRange] = useState(false);

  const onValueChange = (value: string) => {
    setValue(value);
    setIsOutOfRange(false);

    if (isDisabled) {
      return;
    }

    let valueAsFloat = parseFloat(value);

    if (expectedValue !== undefined) {
      if (valueAsFloat !== expectedValue) {
        setIsOutOfRange(true);
      }
    }

    if (lowerLimit !== undefined) {
      if (valueAsFloat < lowerLimit) {
        setIsOutOfRange(true);
      }
    }

    if (upperLimit !== undefined) {
      if (valueAsFloat > upperLimit) {
        setIsOutOfRange(true);
      }
    }

  }

  let uom = component.uom === undefined ? "" : component.uom;
  let meter = component.meterCode === undefined ? "" : component.meterCode;
  let lowerLimit: number | undefined = component.lowerLimit;
  let expectedValue: number | undefined = component.expectedValue;
  let upperLimit: number | undefined = component.upperLimit;
  let nonConform = component.nonConform === undefined ? "" : component.nonConform;
  let shouldShowConformInfo = lowerLimit !== undefined
    || expectedValue !== undefined
    || upperLimit !== undefined;
  let existingResponseNonConform = false;

  if (value && (value !== "" && value !== undefined)) {
    let valueAsFloat = parseFloat(value);

    if ((expectedValue !== undefined
      && valueAsFloat !== expectedValue)
      || (lowerLimit !== undefined
        && valueAsFloat < lowerLimit)
      || (upperLimit !== undefined
        && valueAsFloat > upperLimit)) {
      existingResponseNonConform = true;
    }
  }

  return (
    <div>
      {shouldShowConformInfo &&
        <Banner
          type={BannerType.Info}
        >
          <div className="conformInfo">
            <label>Lower Limit:&nbsp;</label>
            <span>{lowerLimit}</span>
            <label>Expected Value:&nbsp;</label>
            <span>{expectedValue}</span>
            <label>Upper Limit:&nbsp;</label>
            <span>{upperLimit}</span>
          </div>
        </Banner>
      }
      {shouldShowConformInfo
        && (isOutOfRange
          || existingResponseNonConform) &&
        <Banner
          type={BannerType.Warn}
        >
          {nonConform}
        </Banner>
      }
      <label>{component.label}{meter ? ` (${meter})` : undefined}{uom ? ` (${uom})` : undefined}</label>
      <TextOrNumberBox
        disabled={isDisabled}
        value={value}
        onResponseChange={val => onValueChange(val)}
        inputType={TextInputTypes.Number}
      />
    </div>
  );
}

export default NumberInput;