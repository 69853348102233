import { ApprovalLevels, ApprovalResponseTypes, IApprovalWorkFlowResponse } from "interfaces/approvals/approvalInterfaces";

//for SWI, CL
export const psdApprovalWorkFlow: IApprovalWorkFlowResponse[] = [
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Author,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  },
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.TechContent,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  },
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.SME,
    optional: true,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  },
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.COE,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  },
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Owner,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  }
]


export const LCLapprovalWorkFlow: IApprovalWorkFlowResponse[] = [
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Author,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  },
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.LocalTechConApp,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  },
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.SME,
    optional: true,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  }
]

export const MFGApprovalWorkflow: IApprovalWorkFlowResponse[] = [
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Author,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  },
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.SME,
    optional: true,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  },
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.TechContent,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  },
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.TechContent,
    optional: true,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  }
]

export const MFGRCApprovalWorkFlow: IApprovalWorkFlowResponse[] = [
  {
    id: 0,
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Author,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
    comment : "",
    attachmentFilename : null
  },
    {
        id: 0,
        responseType: ApprovalResponseTypes.NotCompleted,
        level: ApprovalLevels.SME,
        optional: true,
        createdBy: "",
        createdOn: new Date(),
        comment: "",
        attachmentFilename: null
    },
    {
        id: 0,
        responseType: ApprovalResponseTypes.NotCompleted,
        level: ApprovalLevels.TechContent,
        optional: false,
        createdBy: "",
        createdOn: new Date(),
        comment: "",
        attachmentFilename: null
    },
    {
        id: 0,
        responseType: ApprovalResponseTypes.NotCompleted,
        level: ApprovalLevels.TechContent,
        optional: true,
        createdBy: "",
        createdOn: new Date(),
        comment: "",
        attachmentFilename: null
    }
]
