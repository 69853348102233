import React, { useEffect } from "react";
import "./Image.scoped.scss";
import { IImageComponent } from "interfaces/sw/SWInterfaces";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { getStepComponentImageUrl } from "store/manageSW/manageSWActions";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import BlockSpinner from "components/common/BlockSpinner";

interface IImageProps {
  component: IImageComponent,
  stepGuid: string,
  swGuid: string,
  swVersion: number,
  style?: React.CSSProperties,
}

const Image: React.FC<IImageProps> = ({ component, stepGuid, swGuid, swVersion, style }) => {
  const { fileUploadOperation } = useSelector(store => store.manageSW);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!component.filename
      || component.blobData?.isLoading
      || component.filename === component.blobData?.filename
      || fileUploadOperation?.isWorking) {
      // Blob url already loaded. Ignore.
      return;
    }

    dispatch(getStepComponentImageUrl({
      stepGuid,
      imageComponent: component,
      swGuid,
      swVersion,
      filename: component.filename,
      destination: ImageDataDestinations.ManageSW,
    }));
  }, [component, stepGuid, swGuid, swVersion, fileUploadOperation, dispatch]);

  if (component.blobData?.isLoading) {
    return (
      <div
        className="image-holder"
      >
        <BlockSpinner />
      </div>
    );
  } else if (component.blobData?.blobUrl) {
    return (
      <div
        className="image-holder"
      >
        <img
          src={component.blobData.blobUrl}
          alt=""
          style={style}
        />
      </div>
    );
  } else if (component.blobData?.errorMessage) {
    return (
      <div
        className="image-holder"
      >
        <span>
          {component.blobData?.errorMessage}
        </span>
      </div>
    );
  } else {
    return null;
  }
}

export default Image;