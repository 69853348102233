import React from "react";
import { IQualityControlPointComponent, StopType } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import "./ComponentEditor.scss";
import "./QualityControlPointEditor.scoped.scss";
import { updateQualityControlPoint } from "store/manageSW/manageSWActions";

interface IQualityControlPointEditorProps {
    labelText: string,
    allowEdit: boolean,
    swGuid: string,
    swVersion: number,
    stepGuid: string,
    qualityControlPoint: IQualityControlPointComponent,
}
const QualityControlPointEditor: React.FC<IQualityControlPointEditorProps> = ({
    labelText,
    allowEdit,
    stepGuid,
    qualityControlPoint,
}) => {
    const dispatch = useDispatch();

    const stopTypeChange = (stopType: StopType) => {
        dispatch(updateQualityControlPoint({
            stepGuid,
            component: {
                ...qualityControlPoint,
                stopType,
            },
        }));
    }

    return (
        <div className="component-editor">
            <label className="mandatory block">{labelText}</label>
            {allowEdit &&
                <div
                    className="controls"
                >
                    <input
                        type="text"
                        className="controllabel"
                        defaultValue={qualityControlPoint.label}
                        onBlur={(e) => dispatch(updateQualityControlPoint({
                            stepGuid,
                            component: {
                                ...qualityControlPoint,
                                label: e.target.value,
                            },
                        }))} />
                    <label className="block">Stop Type</label>
                    <select
                        value={qualityControlPoint.stopType}
                        onChange={(e) => stopTypeChange(e.target.value as StopType)}
                    >
                        <option
                            value={StopType.HardStop}
                        >
                            {StopType.HardStop}
                        </option>
                        <option
                            value={StopType.SoftStop}
                        >
                            {StopType.SoftStop}
                        </option>
                        <option
                            value={StopType.Confirmation}
                        >
                            {StopType.Confirmation}
                        </option>
                    </select>
                </ div>
            }
            {!allowEdit &&
                <>
                    <span>{qualityControlPoint.label}</span>
                    <label className="block">Stop Type</label>
                    <span>{qualityControlPoint.stopType}</span>
                </>
            }
        </div>
    );
}

export default QualityControlPointEditor;

