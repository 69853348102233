import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import { IFileData } from "interfaces/files/fileInterfaces";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import { v4 as uuidv4 } from "uuid";
import Camera, { IMAGE_TYPES, FACING_MODES } from "react-html5-camera-photo";

interface ICameraModalProps {
  onPhotoConfirmed(fileData: IFileData): void,
  onClose(): void,
}

const CameraModal: React.FC<ICameraModalProps> = ({
  onPhotoConfirmed,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [cameraError, setCameraError] = useState("");
  const [imageData, setImageData] = useState<string | undefined>();

  const onPhotoTaken = (dataUri: string) => {
    setImageData(dataUri);
  }

  const onConfirm = () => {
    if (!imageData) {
      dispatch(showErrorToast("Please take a photo first."));
      return;
    }

    onPhotoConfirmed({
      dataUri: imageData,
      filename: `${uuidv4()}.jpg`
    });
  }

  return (
    <Modal
      header="Take Photo"
      isOpen={true}
      controls={(
        <>
          {imageData &&
            <button
              className="tertiary-button"
              onClick={() => setImageData(undefined)}
            >
              Retake photo
            </button>
          }
          <button
            className="secondary-button"
            onClick={onClose}
          >
            Cancel
          </button>
          {imageData &&
            <button
              className="primary-button"
              onClick={onConfirm}
            >
              Confirm
            </button>
          }
        </>
      )}
    >
      {!imageData &&
        getCameraView(cameraError,
          setCameraError,
          onPhotoTaken)
      }
      {imageData &&
        <img
          src={imageData}
          alt=""
        />
      }
    </Modal>
  );
}

function getCameraView(cameraError: string,
  setCameraError: React.Dispatch<React.SetStateAction<string>>,
  onPhotoTaken: (dataUri: string) => void): JSX.Element {
  if (cameraError) {
    return (
      <Banner
        type={BannerType.Error}
      >
        {cameraError}
      </Banner>
    );
  } else {
    return (
      <Camera
        onTakePhotoAnimationDone={(dataUri: any) => { onPhotoTaken(dataUri) }}
        onCameraError={(error: any) => { setCameraError(error.Message || error.toString()); }}
        isDisplayStartCameraError={false}
        idealResolution={{ width: 640, height: 480 }}
        isSilentMode={true}
        imageType={IMAGE_TYPES.JPG}
        imageCompression={0.92}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
      />
    );
  }
}

export default CameraModal;