import React from "react";
import MainMenu from "./MainMenu";
import PageContent from "./PageContent";
import Routing from "components/routing/Routing";
import FlowLayout from "./FlowLayout";
import "./Layout.scoped.scss";
import TitleBar from "./TitleBar";

const Layout: React.FC = () =>
<div
    className="site"
  >
    <TitleBar />
    <div
      className="lower-section"
    >
      <MainMenu />
      <FlowLayout>
        <PageContent>
          <Routing />
        </PageContent>
      </FlowLayout>
    </div>
  </div>;

export default Layout;