import { IBatchUpdateCommand, IBatchUpdateResponse } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import { IOperation } from "interfaces/operations/Operations";
import { ISWSummaryItem } from "interfaces/sw/SWInterfaces";
import { ISWFilterData, ISWListData } from "store/swList/swListTypes";

export interface IBatchUpdatesState {
  currentTab: BatchUpdatesTab,
  swListData: ISWListData,
  filterData: ISWFilterData,
  allowSearching: boolean,
  addedSWs: ISWSummaryItem[],
  commands: IBatchUpdateCommand[],
  commandManagerData: ICommandManagerData,
  submitOperation?: IOperation,
  submitErrorResponse?: IBatchUpdateResponse,
}

export enum BatchUpdatesTab {
  SW,
  Commands,
  Review,
}

export interface ICommandManagerData {
  isOpen: boolean,
  managedCommandSortOrder: number | null,
}