import React, { useEffect } from "react";
import useSelector from "store/useSelector";
import { setPDFData } from "store/swList/swListActions";
import { useDispatch } from "react-redux";

const GeneratePDF: React.FC = () => {
  const {
    swList: {
      getPDFData: {
        getPdfOperation,
        pdfBlob,
        filename,
      }
    }
  } = useSelector(store => store);

  const dispatch = useDispatch();

  useEffect(() => {
    if (getPdfOperation?.wasSuccessful && pdfBlob) {
      const url = window.URL.createObjectURL(pdfBlob);
      const linkElement = document.createElement("a");
      
      linkElement.href = url;
      linkElement.download = filename || "workright.pdf";
      document.body.appendChild(linkElement);
      linkElement.click();
      
      dispatch(setPDFData({
        pdfBlob: undefined,
        filename: undefined,
      }));
    }
  }, [getPdfOperation, pdfBlob, dispatch]);

  return null;
}

export default GeneratePDF