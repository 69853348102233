import React, { useEffect, useState } from "react";
import Modal from "components/common/Modal";
import "./ApprovalModal.scoped.scss";
import SearchUser from "components/user/management/SearchUser";
import { ISW, SWTypes } from "interfaces/sw/SWInterfaces";
import { ApprovalLevels, ApprovalResponseTypes, IHistoricalApprovalResponse, Status } from "interfaces/approvals/approvalInterfaces";
import SearchTechContentUser from "components/user/management/SearchTechContentUser";
import { useDispatch } from "react-redux";
import { addFilterItem, setUserGroupFilter } from "store/userManagement/userManagementActions";
import { Roles } from "interfaces/user/UserInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

interface IApprovalModalProps {
  approvalId: number,
  onSubmit(approvalId: number,
    comment: string,
    tecConRev: string,
    secondTecConRev: string | null,
    smeRevUser: string | null,
    responseType: ApprovalResponseTypes,
    techContetApproval: Status | null,
    techCommApproval: Status | null,
    smeApproval: Status | null): void,
  onClose(): void,
  sw?: ISW,
  approvalLevel?: ApprovalLevels,
  techContetApproval: Status | null,
  techCommApproval: Status | null,
  smeApproval: Status | null
  approvalHistories: IHistoricalApprovalResponse[] | undefined,
  isRevertModal: boolean
}

const ApprovalModal: React.FC<IApprovalModalProps> = ({
  approvalId,
  onClose,
  onSubmit,
  sw,
  approvalLevel,
  techContetApproval,
  techCommApproval,
  smeApproval,
  approvalHistories,
  isRevertModal
}) => {
  const [comment, setComment] = useState<string>("");
  let userSearch = (sw === undefined
    || approvalLevel === undefined)
    ? false
    : (approvalLevel === ApprovalLevels.TechComm
      && sw.type === SWTypes.TLMRC)
    || (approvalLevel === ApprovalLevels.Author &&
      (sw.type === SWTypes.MFGSWI ||
       sw.type === SWTypes.MFGCL ||
       sw.type === SWTypes.MFGRC
      ));
  let userSearchSME = (sw === undefined
    || approvalLevel === undefined)
    ? false
    : (approvalLevel === ApprovalLevels.TechContent
      && (sw.type === SWTypes.SWI || sw.type === SWTypes.CL || sw.type === SWTypes.ECL))
    || (approvalLevel === ApprovalLevels.LocalTechConApp
      && sw.type === SWTypes.LCL)
    || (approvalLevel === ApprovalLevels.TechComm
      && sw.type === SWTypes.TLMRC)
    || (approvalLevel === ApprovalLevels.Author
      && (sw.type === SWTypes.MFGSWI ||
          sw.type === SWTypes.MFGCL ||
          sw.type === SWTypes.MFGRC
      ));

  const checkAprrovalCompleted = (level: ApprovalLevels): boolean => {
    let approval = approvalHistories?.filter(res => res.level === level && res.responseType === ApprovalResponseTypes.Approved);
    return approval?.length !== undefined && approval?.length > 0;
  }

  let userSearchForTLMGloabl = (sw === undefined
    || approvalLevel === undefined)
    ? false
    : (sw?.type === SWTypes.TLMSWI
      && (techContetApproval === Status.NotSelected || techContetApproval === null)
      && (approvalLevel === ApprovalLevels.Author || (approvalLevel === ApprovalLevels.Owner && isRevertModal)))

  let userSMESearchForTLMGlobal = (sw === undefined
    || approvalLevel === undefined)
    ? false
    : (sw?.type === SWTypes.TLMSWI
      && (smeApproval === Status.NotSelected || techContetApproval === null)
      && (approvalLevel === ApprovalLevels.TechComm || (approvalLevel === ApprovalLevels.TechConRev
        && (techCommApproval === Status.NotSelected || (techCommApproval === Status.Selected && checkAprrovalCompleted(ApprovalLevels.TechComm))))))

  let showIncludeTechCommCheckBox = sw?.type === SWTypes.TLMSWI &&
    (techCommApproval === Status.NotSelected || techCommApproval === null) &&
    (approvalLevel === ApprovalLevels.Author || (approvalLevel === ApprovalLevels.Owner && isRevertModal))



  const [tecConRevEmail, setTecConRevEmail] = useState<string>("");
  const [secondTecConRevEmail, setSecondTecConRevEmail] = useState<string>("");
  const [smeEmail, setSMEEmail] = useState<string>("");
  const [includeTechcommState, setincludeTechcommState] = useState<boolean>(false);
  const [techContetApprovalState, settechContetApprovalState] = useState<Status>(techContetApproval === null ? Status.NotSelected : techContetApproval);
  const [techCommApprovalState, settechCommApprovalState] = useState<Status>(techCommApproval === null ? Status.NotSelected : techCommApproval);
  const [smeApprovalState, setsmeApprovalApprovalState] = useState<Status>(smeApproval === null ? Status.NotSelected : smeApproval);

  const dispatch = useDispatch();

  const submitApproval = (responseType: ApprovalResponseTypes) => {
    onSubmit(approvalId, comment, tecConRevEmail, secondTecConRevEmail, smeEmail, responseType, techContetApprovalState, techCommApprovalState, smeApprovalState);
    onClose();
  }

  const setSelectedTechConUser = (email: string) => {
    setTecConRevEmail(email);
    settechContetApprovalState(Status.Selected);
  }

  const setSelectedSecondTechConUser = (email: string) => {
    setSecondTecConRevEmail(email);
  }

  const setSelectedSMEUser = (email: string) => {
    setSMEEmail(email);
    setsmeApprovalApprovalState(Status.Selected);
  }
    useEffect(() => {
        if (showIncludeTechCommCheckBox) {
            setIncludeTechcomm(true);
        }
  }, []);
  
  const setIncludeTechcomm = (include: boolean) => {
    if (include) {
      settechCommApprovalState(Status.Selected);
    } else {
      settechCommApprovalState(Status.NotSelected);
    }
    setincludeTechcommState(include);
  }

  let searchSMEuser: JSX.Element | undefined;
  let searchTechContentUser: JSX.Element | undefined;
  let searchSecondTechContentUser: JSX.Element | undefined;
  let approverComponent: JSX.Element | undefined;
  let checkBoxForTechcomm: JSX.Element | undefined;

  if (userSearchSME) {
    searchSMEuser = (
      <SearchUser
        setSelectedUserFunc={setSelectedSMEUser}
      />
    );
  }

  if (showIncludeTechCommCheckBox) {
    checkBoxForTechcomm = (
      <>
        <label className="tech-label">Techcomm Approval (Optional)</label>
        <div className="entery-row">
          <input
            type="checkbox"
            className="conditional-check"
            checked={includeTechcommState}
            onChange={() => setIncludeTechcomm(!includeTechcommState)}
          />
          <label>
            Include Techcomm in Approval workflow
          </label>
        </div>
      </>
    )
  }

  if (userSearchForTLMGloabl) {
    searchTechContentUser = (<SearchTechContentUser
      setSelectedUserFunc={setSelectedTechConUser}
      optional={true}
      isFilteredList={isFilteredList()}
      isLDAPSearch={true}
      sw={sw}
    />)
  }

  if (userSMESearchForTLMGlobal) {
    searchSMEuser = (
      <SearchUser
        setSelectedUserFunc={setSelectedSMEUser}
      />
    );
  }

  if (userSearch) {
    searchTechContentUser = (
      <SearchTechContentUser
        setSelectedUserFunc={setSelectedTechConUser}
        optional={sw?.type === SWTypes.MFGSWI || sw?.type === SWTypes.MFGCL || sw?.type === SWTypes.MFGRC ? false : true}
        isFilteredList={isFilteredList()}
        isLDAPSearch={sw?.type === SWTypes.TLMSWI || sw?.type === SWTypes.TLMRC ? true : false}
        sw={sw}
      />
    );
    searchSecondTechContentUser = (
      <SearchTechContentUser
        setSelectedUserFunc={setSelectedSecondTechConUser}
        optional={true}
        isFilteredList={isFilteredList()}
        isLDAPSearch={false}
        sw={sw}
      />
    );
  }
  //for RC and MFG we show both special approvers at the same time
  if (sw?.type === SWTypes.TLMRC) {
    approverComponent = (<>
      {searchTechContentUser}
      {searchSMEuser}
    </>)
  }
  else if (sw?.type === SWTypes.MFGSWI || sw?.type === SWTypes.MFGCL || sw?.type === SWTypes.MFGRC) {
    approverComponent = (<>
      {searchSMEuser}
      {searchTechContentUser}
      {searchSecondTechContentUser}
    </>)
  }
  else {// for all other types we show them one at a time based on current level.
    if (userSearchSME || userSMESearchForTLMGlobal) {
      approverComponent = (<>
        {searchSMEuser}
      </>)
    }
    if (userSearch || userSearchForTLMGloabl || checkBoxForTechcomm) {
      approverComponent = (<>
        {searchTechContentUser}
        {checkBoxForTechcomm}
      </>)
    }
  }

  const showRevertMessage = isRevertModal && !userSearchForTLMGloabl && !showIncludeTechCommCheckBox
  const revertMessage = "Author already selected TechconRev and Techcomm reviewers, you can't revert it"

  function isFilteredList(): boolean {
      if ((sw?.type === SWTypes.MFGSWI || sw?.type === SWTypes.MFGCL || sw?.type === SWTypes.MFGRC ) && sw.owningPlant) {
      let role: IMasterDataItem = {
        guid: Roles.ApproverTechContent,
        value: Roles.ApproverTechContent,
        active: true,
        code: Roles.ApproverTechContent,
      };
      dispatch(addFilterItem({ filterName: "owningPlant", filterItem: sw.owningPlant }));
      dispatch(addFilterItem({ filterName: "userRole", filterItem: role }));
      dispatch(setUserGroupFilter({ isMfg: true }));
      return true;
    }
    else {
      return false;
    }
  }

  const responseStatus = isRevertModal ? ApprovalResponseTypes.Revert : ApprovalResponseTypes.Approved;

  const enableRevert = isRevertModal ? !(includeTechcommState || tecConRevEmail !== "") : false;


  return (
    <Modal
      isOpen={true}
      header="Approval Comment"
      controls={(
        <>
          <button
            className="primary-button"
            disabled={showRevertMessage || enableRevert}
            onClick={() => submitApproval(responseStatus)}
          >
            {isRevertModal ? "Revert" : "Approve"}
          </button>
          <button
            className="secondary-button"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </>
      )}
    >
      {showRevertMessage ?
        <>
          <p>{revertMessage}</p>
        </>
        :
        <textarea
          maxLength={500}
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          placeholder="Enter Approval comment..."
          className="approval-comment"
        />
      }
      {approverComponent}
    </Modal>
  );
}

export default ApprovalModal;