import React from "react";
import Banner, { BannerType } from "components/common/Banner";
import "./StartupError.scoped.scss";
import Logo from "./Logo";

interface IStartupErrorProps {
  messages: string[],
}

const StartupError: React.FC<IStartupErrorProps> = ({ messages }) =>
  <div className="centered">
    <Logo />
    <Banner
      type={BannerType.Error}
    >
      {messages.map((m, ix) => (
        <span
          key={ix}
          className="message"
        >
          {m}
        </span>
      ))}
    </Banner>
  </div>;

export default StartupError;