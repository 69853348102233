import { IAudioInputComponent } from "../../../../interfaces/sw/SWInterfaces";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorToast } from "../../../../store/toast/toastActions";
import { validateAndReadAudioFile } from "../../../../utilities/fileUtilities";
import "./AudioInput.scoped.scss";

interface IAudioInputProps {
  component: IAudioInputComponent,
  isDisabled: boolean,
}

interface IAudioProps {
  fileName: string,
  dataUri: string,
}

const AudioInput: React.FC<IAudioInputProps> = ({ component, isDisabled }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [AudioData, setAudioData] = useState<IAudioProps>();

  const onRemoveAudio = () => {
    setAudioData(undefined);
  }

  const onFileChosen = async (event: React.FormEvent<HTMLInputElement>) => {
    let tgt = event.target as HTMLInputElement;

    if (!tgt || !tgt.files || !tgt.files.length) {
      return;
    }

    let file = tgt.files[0];

    try {
      const fileData = await validateAndReadAudioFile(file);
      const Audio: IAudioProps = {
        fileName: fileData.filename,
        dataUri: fileData.dataUri,
      };
      setAudioData(Audio);
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
    }
  }

  let aud: JSX.Element | undefined;

  if (AudioData) {
    aud = (
      <audio controls className="embed">
        <source src={AudioData.dataUri} />
      </audio>
    );
  }

  return (
    <>
      <div>
        <div>{component.label}</div>
        {aud}
        <input
          type="file"
          accept="audio/mp4,audio/mp3,audio/wav"
          ref={fileInputRef}
          onChange={onFileChosen}
          disabled={isDisabled}
        />
        <div
          className="controls"
        >
          {!AudioData &&
            <button
              className="primary-button choose-audio"
              onClick={() => fileInputRef.current?.click()}
              disabled={isDisabled}
            >
              {"Choose Audio"}
            </button>
          }

          {AudioData &&
            <button
              className="primary-button choose-audio"
              onClick={() => onRemoveAudio()}
              disabled={isDisabled}
            >
              {"Remove Audio"}
            </button>
          }
        </div>
      </div>
    </>
  );
}

export default AudioInput;