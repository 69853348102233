import { createAction } from "@reduxjs/toolkit";
import { IOperation } from "interfaces/operations/Operations";
import { ApprovalSortFields, IApprovalResponseSubmission, IMyApprovals, IRCErrorResponse } from "interfaces/approvals/approvalInterfaces";

import {
  ICurrentApproval,
  IApprovalHistory,
  IRCUpdateApproval,
  ISubmitRCUpdateApprovalRequest,
} from "./approvalsTypes";

import { IImageBlobData, RCTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IBatchUpdateApproval, IBatchUpdateSWMetaData } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import { SortDirection } from "interfaces/misc/miscInterfaces";

export const submitDraftForApproval = createAction<{
  swGuid: string,
  swType: SWTypes
}>("approvals/submitDraftForApproval");
export const setSubmitDraftOperation = createAction<IOperation | undefined>("approvals/setSubmitDraftOperation");

export const retractSwFromApprovalFlow = createAction<{
  swId: number,
  approvalRequestId: number | null | undefined
}>("approvals/retractSwFromApprovalFlow");
export const setSort = createAction<{
  sortBy: ApprovalSortFields,
  sortDir: SortDirection,
}>("approvals/setSort")

export const setRetractSwOperation = createAction<IOperation | undefined>("approvals/setRetractSwOperation");

export const resetMyApprovals = createAction("approvals/resetMyApprovals");
export const loadMyApprovals = createAction("approvals/loadMyApprovals");
export const setMyApprovals = createAction<IMyApprovals>("approvals/setMyApprovals");
export const setLoadMyApprovalsOperation = createAction<IOperation | undefined>("approvals/setLoadMyApprovalsOperation");

export const resetCurrentApproval = createAction("approvals/resetCurrentApproval");
export const loadApproval = createAction<{ approvalId: number, stepIndex: number | undefined }>("approvals/loadApproval");
export const resetCurrentBatchApproval = createAction("approvals/resetCurrentBatchApproval");
export const loadBatchApproval = createAction<{ approvalId: number }>("approvals/loadBatchApproval");
export const loadBatchUpdateSWMetaData = createAction<{ approvalId: number, swId: number }>("approvals/loadBatchUpdateSWMetaData");
export const closeBatchUpdateSWMetaData = createAction("swList/closeBatchUpdateSWMetaData");

export const setCurrentApproval = createAction<ICurrentApproval | undefined>("approvals/setCurrentApproval");
export const setCurrentBatchApproval = createAction<IBatchUpdateApproval | undefined>("approvals/setCurrentBatchApproval");
export const setCurrentBatchUpdateSWMetaData = createAction<IBatchUpdateSWMetaData | undefined>("approvals/setCurrentBatchUpdateSWMetaData");
export const setLoadApprovalOperation = createAction<IOperation | undefined>("approvals/setLoadApprovalOperation");
export const setLoadBatchUpdateSWMetaDataOperation = createAction<IOperation | undefined>("approvals/setLoadBatchUpdateSWMetaDataOperation");

export const loadRCUpdateApproval = createAction<{ approvalId: number }>("approvals/loadRCUpdateApproval");

export const setSubmitRCUpdateApprovalOperation = createAction<
  IOperation | undefined
>("approvals/setSubmitRCUpdateApprovalOperation");

export const submitRCUpdateApproval =
  createAction<ISubmitRCUpdateApprovalRequest>(
    "approvals/submitRCUpdateApproval"
  );

export const setCurrentRCUpdateApproval = createAction<IRCUpdateApproval | undefined>("approvals/setCurrentRCUpdateApproval");

export const updateImageBlobData = createAction<{
  blobData: IImageBlobData,
  stepGuid: string,
  componentGuid: string
}>("approvals/updateImageBlobData");

export const updateTableCellBlobData = createAction<{
  stepGuid: string,
  componentGuid: string,
  fileName: string,
  rowIndex: number,
  colIndex: number
}>("approvals/updateTableCellBlobData");

export const updateRefDocTableCellBlobData = createAction<{
  refDocGuid: string,
  fileName: string,
  rowIndex: number,
  colIndex: number
}>("approvals/updateRefDocTableCellBlobData");

export const submitResponse = createAction<IApprovalResponseSubmission>("approvals/submitResponse");
export const submitBatchResponse = createAction<IApprovalResponseSubmission>("approvals/submitBatchResponse");
export const setSubmitResponseOperation = createAction<IOperation | undefined>("approvals/setSubmitResponseOperation");

export const loadApprovalHistory = createAction<{ swId: number }>("approvals/loadApprovalHistory");
export const setApprovalHistory = createAction<IApprovalHistory>("approvals/setApprovalHistory");

export const updateStepCommentsInfoApproval = createAction<{ stepID: number, hasUnresolvedComments: boolean, totalStepComments: number, unResolvedStepComments: number }>("approvals/updateStepCommentsInfoApproval");
export const saveSWStepCommentApproval = createAction<{ stepID: number, comment: string }>("approvals/saveSWStepCommentApproval");
export const resolveStepCommentApproval = createAction<{ commentID: number, stepID: number }>("approvals/resolveStepCommentApproval");

// Filtering
export const setSearchText = createAction<string>("approvals/setSearchText");
export const toggleFilterModal = createAction<boolean>("approvals/toggleFilterModal");
export const applyFilter = createAction("approvals/applyFilter");
export const clearFilter = createAction("approvals/clearFilter");
export const addFilterItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("approvals/addFilterItem");
export const addFilterItems = createAction<{
  metaDataName: MetaDataNames,
  items: IMasterDataItem[],
}>("approvals/addFilterItems");
export const removeFilterItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("approvals/removeFilterItem");
export const setStringFilter = createAction<{
  attributeName: "maintenanceTaskIds",
  value: string
}>("approvals/setStringFilter");

export const setRCType = createAction<RCTypes | undefined>(
  "approvals/setRCType"
);
export const updateApprovalNoticeImageBlobData = createAction<{
  noticeGuid: string,
  stepGuid?: string,
  isDirty?: boolean,
  filename: string,
  blob: IImageBlobData
}>("approvals/updateApprovalNoticeImageBlobData");
export const updateApprovalVideoBlobData = createAction<{
  stepGuid: string,
  componentGuid: string,
  isDirty?: boolean,
  filename: string,
  blob: IImageBlobData
}>("approvals/updateApprovalVideoBlobData");
export const updateApprovalAudioBlobData = createAction<{
  stepGuid: string,
  componentGuid: string,
  isDirty?: boolean,
  filename: string,
  blob: IImageBlobData
}>("approvals/updateApprovalAudioBlobData");
export const launchUserSentimentSurvey = createAction<{ currentUser: string }>("approvals/LaunchUserSentimentSurvey");
export const loadMoreSteps = createAction<{ approvalId: number, stepIndex: number }>("approvals/loadMoreSteps");
export const showErrorPopupMessage = createAction<IRCErrorResponse>("approvals/showErrorPopupMessage");