import { createAction } from "@reduxjs/toolkit";
import {
  IAddUserModalData,
  IManageUsersData,
  IEditUserData,
  IUserDetail,
  FilterNames
} from "./userManagementTypes";
import {UserApprovalLevels, IAADUser, IManageUserDetail, IBulkEditUser} from "interfaces/user/UserInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import {IOperation} from "../../interfaces/operations/Operations";

export const resetManageUsersData = createAction("userManagement/resetManageUsersData");
export const resetAddUserModalData = createAction("userManagement/resetAddUserModalData");
export const resetEditUserData = createAction("userManagement/resetEditUserData");

export const toggleAddUserModal = createAction<boolean>("userManagement/toggleAddUserModal");
export const getManageUsersList = createAction("userManagement/getManageUsersList");
export const getManageTechContentUsersList = createAction("userManagement/getManageTechContentUsersList");
export const setAddUserSearchText = createAction<string>("userManagement/setAddUserSearchText");
export const setAddUserModalData = createAction<IAddUserModalData>("userManagement/setAddUserModalData");
export const setManageUsersData = createAction<IManageUsersData>("userManagement/setManageUsersData");
export const setManageTechContentUsersData = createAction<IManageUsersData>("userManagement/setManageTechContentUsersData");
export const setSearchManageUsersText = createAction<string>("userManagement/setSearchManageUsersText");
export const setSearchLDAPManageUsersText = createAction<string>("userManagement/setSearchLDAPManageUsersText");
export const setSearchManageTechContentUsersText = createAction<string>("userManagement/setSearchManageTechContentUsersText");
export const setSearchLDAPManageTechContentUsersText = createAction<string>("userManagement/setSearchLDAPManageTechContentUsersText");

export const getUserDetails = createAction<{ email: string }>("UserManagement/getUserDetails");
export const setUserDetails = createAction<IUserDetail>("UserManagement/setUserDetails")
export const getUserForEdit = createAction<string>("userManagement/getUserForEdit");
export const loadUserForEdit = createAction<string>("userManagement/loadUserForEdit");
export const getUserForEditWithRole = createAction<{ email: string, role: string, isTechUser: boolean, isPsdUser: boolean, isMfgUser: boolean }>("userManagement/getUserForEditWithRole");
export const deleteUserRole = createAction<{ email: string, role: string, isTechUser: boolean, isPsdUser: boolean, isMfgUser: boolean }>("userManagement/deleteUserRole");
export const setEditUserData = createAction<IEditUserData>("userManagement/setEditUserData");
export const toggleRole = createAction<{ email: string, role: string, isTechUser: boolean, isPsdUser: boolean, isMfgUser: boolean }>("userManagement/toggleRole");
export const toggleApprovalLevel = createAction<UserApprovalLevels>("userManagement/toggleApprovalLevel");
export const toggleOrg = createAction<IMasterDataItem>("userManagement/toggleOrg");
export const setUserDetailEditUserData = createAction<IManageUserDetail>("userManagement/setUserDetailEditUserData");
export const setIsRcEditor = createAction<boolean>("userManagement/setIsRcEditor");
export const addMetaDataItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("userManagement/addMetaDataValue");

export const removeMetaDataItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("userManagement/removeMetaDataValue");

export const createUser = createAction<{
  user: IAADUser,
  refreshManageUsersList: boolean,
}>("userManagement/createUser");
export const updateUser = createAction<{ user: IManageUserDetail, email: string, name: string }>("userManagement/updateUser");
export const addFilterItem = createAction<{ filterName: FilterNames, filterItem?: IMasterDataItem }>("userManagement/addFilterItem");
export const removeFilterItem = createAction<{ filterName: FilterNames, filterItem?: IMasterDataItem }>("userManagement/removeFilterItem");
export const setUserGroupFilter = createAction<{ isPsd?: boolean, isTech?: boolean, isMfg?: boolean }>("userManagement/setUserGroupFilter");
export const getFilteredUsersList = createAction<{ searchTerm: string, isListUserSearch?: boolean }>("userManagement/getFilteredUsersList");
export const clearUserFilters = createAction("userManagement/clearUserFilters");

// New user role assignment
export const resetRoleAssignmentsData = createAction("userManagement/resetRoleAssignmentsData");
export const resetEditRoleAssignmentData = createAction("userManagement/resetEditRoleAssignmentData");
export const setRoleAssignmentUsers = createAction<IAADUser[]>("userManagement/setRoleAssignmentUsers");
export const addRoleAssignment = createAction<IManageUserDetail>("userManagement/addRoleAssignment");
export const removeRoleAssignment = createAction<{role: string, isTechUser: boolean, isPsdUser: boolean, isMfgUser: boolean, isRCEditor: boolean}>("userManagement/removeRoleAssignment");
export const roleAssignmentSetRole = createAction<string>("userManagement/roleAssignmentSetRole");
export const roleAssignmentSetGroup = createAction<string>("userManagement/roleAssignmentSetGroup");
export const roleAssignmentSetIsRCEditor = createAction<boolean>("userManagement/roleAssignmentSetIsRCEditor");
export const roleAssignmentSetIsDirty = createAction<boolean>("userManagement/roleAssignmentSetIsDirty");
export const setRoleAssignmentEditingData = createAction<IManageUserDetail>("userManagement/setRoleAssignmentEditingData");
export const roleAssignmentAddMetaDataItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("userManagement/roleAssignmentAddMetaDataValue");

export const roleAssignmentRemoveMetaDataItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("userManagement/roleAssignmentRemoveMetaDataValue");
export const roleAssignmentClearMetadata = createAction("userManagement/roleAssignmentRemoveMetaDataItems");
export const roleAssignmentToggleOrg = createAction<IMasterDataItem>("userManagement/roleAssignmentSetOrg");
export const setIsEditRoleAssignment = createAction<boolean>("userManagement/setIsEditRoleAssignment");
export const editRoleAssignment = createAction<IManageUserDetail>("userManagement/editRoleAssignment");
export const saveNewUserRoleAssignments = createAction<{users: string[], roleAssignments: IManageUserDetail[], history: any}>("userManagement/saveNewUserRoleAssignments");
export const roleAssignmentSetSaveOperation = createAction<IOperation | undefined>("userManagement/roleAssignmentSetSaveOperation");