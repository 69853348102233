import { authPostJson } from "utilities/msalFetches";
import config from "config";
import { throwIfResponseError } from "apis/apiUtilities";
import { ISWSummaryItem } from "interfaces/sw/SWInterfaces";
import { IBatchUpdateCommand, IBatchUpdateResponse } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import { formatSubmitBatchUpdatesRequest, formatSubmitBatchUpdatesRequestResponse } from "./batchUpdatesFormatters";

class BatchUpdatesApi {
  public async submitBatchUpdates(sws: ISWSummaryItem[],
    commands: IBatchUpdateCommand[]): Promise<IBatchUpdateResponse> {
    let response = await authPostJson(
      config
        .endpoints
        .batchUpdates
        .submitForApproval,
      JSON.stringify(formatSubmitBatchUpdatesRequest(sws, commands))
    );

    if (response.status !== 400) {
      await throwIfResponseError(response);
    }

    return formatSubmitBatchUpdatesRequestResponse(
      response.status,
      await response.json());
  }
}

export default new BatchUpdatesApi();