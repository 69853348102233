import React from "react";
import ToastContainer from "components/toasts/ToastContainer";
import ModalSpinnerContainer from "../layout/ModalSpinnerContainer";
import GeneratePDF from "../sw/GeneratePDF";

const GlobalComponents: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <ModalSpinnerContainer />
      <GeneratePDF />
    </>
  );
};

export default GlobalComponents;
