import React, { useEffect } from "react";
import { ITableComponentCell, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import "./TableComponentCell.scoped.scss";
import { useDispatch } from "react-redux";
import { getTableCellImageUrls } from "store/manageSW/manageSWActions";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import useSelector from "store/useSelector";

interface ITableComponentCellProps {
  rowKey: number,
  colKey: number,
  cells: ITableComponentCell[],
  stepGuid: string,
  componentGuid: string,
  swGuid: string,
  swVersion: number,
}

const TableComponentCell: React.FC<ITableComponentCellProps> = ({
  rowKey,
  colKey,
  cells,
  stepGuid,
  componentGuid,
  swGuid,
  swVersion,
}) => {
  const dispatch = useDispatch();

  const {
    manageSW: {
        SW,
    }
  } = useSelector(store => store);

  let existingCell: ITableComponentCell | undefined;
  let existingComponent = SW.steps.find(x => x.guid === stepGuid)?.components.find(x => x.guid === componentGuid);
  if (existingComponent) {
    if(existingComponent.type === StepComponentTypes.Table) {
      existingCell = existingComponent.cells.find(x => x.rowIndex === rowKey && x.colIndex === colKey);
    }
  } else {
    existingCell = cells.find(x => x.rowIndex === rowKey && x.colIndex === colKey);
  }

  useEffect(() => {
    if (existingCell?.value) {
      //if cell value has image tag hit azure to get signed url
      let cellImage = getImage(existingCell);

      if (cellImage) {
        dispatch(getTableCellImageUrls({
          stepGuid,
          componentGuid: componentGuid,
          rowIndex: rowKey,
          colIndex: colKey,
          swGuid,
          swVersion,
          filename: cellImage,
          destination: ImageDataDestinations.ManageSW,
        }));
      }
    }

  }, [existingCell, colKey, dispatch, rowKey, stepGuid, swGuid, swVersion, componentGuid]);

  if (!existingCell) {
    return null;
  }
  
  return (
    <div
      className="execution-preview-editor"
      dangerouslySetInnerHTML={createMarkup(existingCell.value)}
    />
  ); 
}

export default TableComponentCell;

function createMarkup(data: string) {
  return { __html: data };
}

const getImage = (cell: ITableComponentCell): string | undefined => {
  if (cell.imageFileName && cell.imageFileName.includes('blob.core.windows.net/authoring')) {
    return undefined;
  }

  const imgRex = /<img.*?alt="(.*?)"[^>]+>/g;
    let img;
    while ((img = imgRex.exec(cell.value))) {
      let val = img[1];
      // dont add it if its local image still
      if (val !== "undefined") {
        return img[1];
      }
    }
  return undefined;
}