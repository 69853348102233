import { IStepComment } from "interfaces/sw/SWInterfaces";
import React from "react";
import { useDispatch } from "react-redux";
import { resolveStepCommentApproval } from "store/approvals/approvalsActions";
import { resolveStepComment } from "store/manageSW/manageSWActions";
import "./SWStepComment.scoped.scss";

interface IStepCommentProps {
  comment: IStepComment,
  isDraftPage: boolean,
}

const SWStepComment: React.FC<IStepCommentProps> = ({
  comment,
  isDraftPage }) => {
  let commentParts = comment.comment.split(/(@\[.+?\])/g);
  const dispatch = useDispatch();
  const resolveComment = (commentID: number, stepID: number) => {
    if (isDraftPage) {
      dispatch(resolveStepComment({ commentID, stepID }));
    } else {
      dispatch(resolveStepCommentApproval({ commentID, stepID }));
    }
  }
  return (
    <div className="comment">
      <div className="commentHeader">
        <div>{comment.createdBy}</div>

      </div>
      <div className="commentBody">
        {
          commentParts.map((part, index) => (
            part.indexOf("@[") > -1
              ? <span key={index}><b>{part.substring(2, part.lastIndexOf("]"))}</b></span>
              : <span key={index}>{part}</span>
          ))
        }
        <button
          className="primary-button btnResolve"
          disabled={comment.resolved ? true : false}
          onClick={() => resolveComment(comment.id, comment.stepId)}
        >
          {comment.resolved ? "Resolved" : "Resolve Comment"}</button>
      </div>
    </div>
  );
}

export default SWStepComment;