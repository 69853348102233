import { IRCAffectedSWDetails } from "../interfaces/approvals/approvalInterfaces";

export class ResponseError extends Error {
  constructor(message: string, public errorType: string) {
    super(message);
    this.name = "ResponseError";
  }

  isUnauthorized(): boolean {
    return (this.message != undefined &&
      this.errorType != undefined &&
      this.errorType == "Unauthorized")
  }
}

export class ResponseErrorforRC extends Error {
    affectedSws: any;
    constructor(message: string, public errorType: string, affectedSws: IRCAffectedSWDetails) {
        super(message);
        this.name = "ResponseError";
        this.affectedSws = affectedSws;
    }
}