import React, { useEffect } from "react";
import FlowLayout from "components/layout/FlowLayout";
import { useMsal } from "@azure/msal-react";
import "./UserProfile.scoped.scss";
import ProxySettings from "./management/ProxySettings";
import { useDispatch } from "react-redux";

import {
  loadProxyApprover,
  submitDeleteProxyApprover,
  submitProxyApprover,
} from "store/proxyApprovers/proxyApproversActions";

import useSelector from "store/useSelector";
import UserRolesDetail from "./management/UserRolesDetail";

const UserProfile: React.FC = () => {
  const dispatch = useDispatch();

  const { proxyApprover, proxyOperation } = useSelector(
    (s) => s.proxyApprovers
  );

  useEffect(() => {
    dispatch(loadProxyApprover());
  }, []);

  const confirmProxyDelete = () => {
    if (window.confirm("Do you want to revoke Proxy Provileges?")) {
      dispatch(submitDeleteProxyApprover());
    }
  };

  let name: string | undefined = "";
  let email: string | undefined = "";

  const { accounts, instance } = useMsal();

  const user = accounts[0];
  if (user) {
    email = user.username;
    name = (user as any).name;
  } else {
    return (
      <>
        <h2>Unknown User</h2>
        <p>
          Your current application state is corrupted. Please log out of the
          website and log back in.
        </p>
      </>
    );
  }

  const onLogoutClick = () => {
    instance.logoutRedirect();
  };

  return (
    <FlowLayout header={<h2 className="page-title">{name}</h2>}>
      <p>{email}</p>
      <div>
        <h3>Assigned Roles:</h3>
        <UserRolesDetail userEmail={email} />
      </div>

      <p>
        <button className="primary-button" onClick={onLogoutClick}>
          Log Out
        </button>
      </p>

      <h3>Proxy Approver Status</h3>

      <ProxySettings
        proxyApprover={proxyApprover}
        onSubmitApprover={(v) => dispatch(submitProxyApprover(v))}
        onDeleteApprover={confirmProxyDelete}
        disabled={proxyOperation.isWorking}
      />
    </FlowLayout>
  );
};

export default UserProfile;
