import BlockSpinner from "components/common/BlockSpinner";
import { IVideoComponent } from "interfaces/sw/SWInterfaces";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getVideoUrl } from "store/manageSW/manageSWActions";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import useSelector from "store/useSelector";
import "./Video.scoped.scss";

interface IVideoProps {
    swGuid: string,
    swVersion: number,
    component: IVideoComponent,
    stepGuid: string,
}

const Video: React.FC<IVideoProps> = ({ swGuid, swVersion, component, stepGuid }) => {
    const { fileUploadOperation } = useSelector(store => store.manageSW);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!component.filename
            || component.blobData?.isLoading
            || component.filename === component.blobData?.filename
            || fileUploadOperation?.isWorking) {
            // blobl url already loaded. ignore.
            return;
        }

        dispatch(getVideoUrl({
            swGuid,
            swVersion,
            stepGuid,
            componentGuid: component.guid,
            filename: component.filename,
            destination: ImageDataDestinations.ManageSW,
        }));
    }, [component, stepGuid, swGuid, swVersion, fileUploadOperation, dispatch]);

    if (component.blobData?.isLoading) {
        return (
            <div
                className="image-holder"
            >
                <BlockSpinner />
            </div>
        );
    } else if (component.blobData?.blobUrl) {
        return (
            <video controls className="embed">
                <source type="video/mp4" src={component.blobData?.blobUrl} />
            </video>
        );
    } else if (component.blobData?.errorMessage) {
        return (
            <div
                className="embed empty"
            >
                <span>
                    {component.blobData?.errorMessage}
                </span>
            </div>
        );
    } else {
        return null;
    }
}

export default Video;