import React from "react";
import { filesFilter, imagesFilter } from "utilities/fileUtilities";
import "./AddImageButton.scoped.css";

interface IAddImageButtonProps {
  onFileChosen(event: React.FormEvent<HTMLInputElement>): void,
  onShowCamera(): void,
}

const AddImageButton: React.FC<IAddImageButtonProps> = ({ onFileChosen, onShowCamera }) => {
  let fileInputElement: HTMLInputElement | null = null;
  let pdfFileInputElement: HTMLInputElement | null = null;
  const allowsMedia = navigator.mediaDevices
    && navigator.mediaDevices.getSupportedConstraints;
  const allowsCapture = (document.createElement("input") as any).capture !== undefined;
  const dontShowCam = allowsCapture || !allowsMedia;

  const showChooseFile = () => {
    fileInputElement?.click();
  }

  const showChoosePDFFile = () => {
    pdfFileInputElement?.click();
  }

  return (
    <div className="add-img img-holder">
      <input
        type="file"
        accept={imagesFilter}
        ref={(input) => { fileInputElement = input }}
        onChange={onFileChosen}
      />
      <button
        className="tertiary-button"
        title="Upload Image"
        onClick={showChooseFile}
      >
        + <span role="img" aria-label="image">🖼️{dontShowCam && "/📷"}</span>
      </button>
      {!dontShowCam &&
        <button
          className="tertiary-button"
          title="Take Photo"
          onClick={onShowCamera}
        >
          + <span role="img" aria-label="camera">📷</span>
        </button>
      }
      <input
        type="file"
        accept={filesFilter}
        ref={(input) => { pdfFileInputElement = input }}
        onChange={onFileChosen}
      />
      {!dontShowCam &&
        <button
          className="tertiary-button"
          title="Upload File"
          onClick={showChoosePDFFile}
        >
          + <span role="img" aria-label="camera">📋</span>
        </button>
      }
    </div>
  );
}

export default AddImageButton;