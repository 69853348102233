import React from "react";
import { useEffect } from "react";
import { ManageSwReportsTabs } from "store/ManageSwReports/ManageSwReportsTypes";
import useSelector from "store/useSelector";
import SWTab from "../manage/toolbar/SWTab";
import settingsIcon from "media/icons/dls/pdf.svg";
import { useDispatch } from "react-redux";
import { setCurrentTab } from "store/ManageSwReports/manageSwReportsActions";
import { AuthFilter } from "auth/AuthFilter";
import { Roles } from "interfaces/user/UserInterfaces";
import { getUserDetails, resetEditUserData } from "../../../store/userManagement/userManagementActions";

const SwReportsToolbar: React.FC = () => {
    const currentUser = useSelector(store => store.auth.currentUser);
    const userDetails = useSelector(store => store.userManagement.userDetail.userDetails);
    const dispatch = useDispatch();
    const {
        manageSwReports: {
            currentTab,
        },
    } = useSelector(store => store);

    useEffect(() => {
        dispatch(getUserDetails({ email: currentUser.email }));
        return () => {
            dispatch(setCurrentTab(ManageSwReportsTabs.FullPdf));
            dispatch(resetEditUserData());
        };
    }, [])

    const isAuth = AuthFilter.isLoggedIn()
        .hasAnyRoleIn([
            Roles.Author,
            Roles.ReportViewer,
            Roles.ApproverTechContent,
            Roles.ApproverOwner,
            Roles.OrgAdmin,
            Roles.TechComm,
        ]).includeSysAdmins()
        .isMatch(currentUser);

    const isWCMorWCD = userDetails?.find(x => x.isTechUser && x.orgs.find(z => z.code == 'WCM' || z.code == 'WCD')) !== undefined ? true : false;

    return (
        <>

            <SWTab
                isActive={currentTab === ManageSwReportsTabs.FullPdf}
                label="Full PDFs"
                icon={settingsIcon}
                onClick={() => dispatch(setCurrentTab(ManageSwReportsTabs.FullPdf))}
            />

            {isAuth && isWCMorWCD &&
                <SWTab
                    isActive={currentTab === ManageSwReportsTabs.qtracPdf}
                    label="Qtrac PDFs"
                    icon={settingsIcon}
                    onClick={() => dispatch(setCurrentTab(ManageSwReportsTabs.qtracPdf))}
                />
            }
        </>
    );
};

export default SwReportsToolbar;