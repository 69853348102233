import React, { useEffect, useState } from "react";
import { ImageType, INoticeComponent } from "interfaces/sw/SWInterfaces";
import BlockSpinner from "components/common/BlockSpinner";
import "./NoticeImageEditor.scoped.scss";
import { useDispatch } from "react-redux";
import { downloadNoticeTimeImage, getNoticeImageUrl, setTimeImageData } from "store/manageSW/manageSWActions";
import { ImageDataDestinations, ITimeImageData } from "store/manageSW/manageSWTypes";
import DeleteIcon from "media/icons/dls/delete.svg";
import useSelector from "store/useSelector";
import AddTimeImageModal from "../AddTimeImageModal";

interface INoticeTimeImageEditorProps {
  notice: INoticeComponent,
  stepGuid?: string,
  allowEdit?: boolean,
  swGuid: string,
  swVersion: number,
  imageDataDestination: ImageDataDestinations,
}

const NoticeTimeImageEditor: React.FC<INoticeTimeImageEditorProps> = ({
  notice,
  stepGuid,
  allowEdit,
  swGuid,
  swVersion,
  imageDataDestination,
}) => {
  const { fileUploadOperation, timeImageData } = useSelector(store => store.manageSW);
  const dispatch = useDispatch();
  const [openTimeModal, setOpenTimeModal] = useState(false);

  useEffect(() => {
    if (!notice.image.filename
      || notice.image.imageType === ImageType.Normal
      || notice.image.blobData?.isLoading
      || notice.image.filename === notice.image.blobData?.filename
      || fileUploadOperation?.isWorking) {
      // Blob url already loaded. Ignore.
      return;
    }

    dispatch(getNoticeImageUrl({
      swGuid,
      swVersion,
      noticeGuid: notice.guid,
      stepGuid,
      filename: notice.image.filename,
      destination: imageDataDestination,
      imageType: notice.image.imageType,
    }));
  }, [notice.image, notice.guid, stepGuid, swGuid, swVersion, imageDataDestination, fileUploadOperation, dispatch]);

  let img: JSX.Element | undefined;

  if (notice.image.blobData?.isLoading) {
    img = (
      <BlockSpinner />
    );
  } else if (notice.image.blobData?.errorMessage) {
    img = (
      <label className="error">
        {notice.image.blobData?.errorMessage}
      </label>
    );
  } else if (notice.image.blobData?.blobUrl && notice.image.imageType === ImageType.Time) {
    img = (
      <>
        <img
          src={notice.image.blobData?.blobUrl}
          alt="Selected"
          className="chosen-image"
        />
        {allowEdit &&
          <img
            src={DeleteIcon}
            alt="Delete"
            title="Delete Image"
            className="delete-button icon-small"
            onClick={(e) => onDeleteImage(e)}
          />
        }
      </>
    );
  }

  const openCloseModal = () => {
    setOpenTimeModal(prevState => !prevState);
    dispatch(setTimeImageData({
      imageNumber: 0,
      link: "",
      label: "",
    }));
  }

  const onDeleteImage = (_event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    dispatch(downloadNoticeTimeImage({
      swGuid,
      swVersion: 0,
      imageNumber: 0,
      link: "",
      label: "",
      noticeGuid: notice.guid,
      stepGuid
    }));
  }

  const downloadImage = (data: ITimeImageData) => {
    dispatch(downloadNoticeTimeImage({
      swGuid,
      swVersion: 0,
      label: data.label,
      link: data.link,
      imageNumber: data.imageNumber,
      noticeGuid: notice.guid,
      stepGuid
    }));
  }

  return (
    <>
      <div className="image-wrapper">
        {img}
      </div>
      {
        openTimeModal &&
        <AddTimeImageModal
          onModalClick={openCloseModal}
          timeImageData={timeImageData}
          uploadDownloadImage={downloadImage}
        />
      }

      {allowEdit &&
        <>
          <div
            className="controls"
          >
            <button
              className="primary-button choose-image"
              onClick={openCloseModal}
            >
              {notice.image.filename ? "Change" : "Choose"} TIME Image
            </button>
          </div>
        </>
      }
    </>
  );
}

export default NoticeTimeImageEditor;
