import React, { useRef } from "react";
import { IYesNoInputComponent,SWTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { updateYesNoInput } from "store/manageSW/manageSWActions";
import "./ComponentEditor.scss";

interface IYesNoInputEditorProps {
  yesNoInput: IYesNoInputComponent,
  allowEdit: boolean,
  stepGuid: string,
  labelText: string,
  swType: SWTypes,
}

const YesNoInputEditor: React.FC<IYesNoInputEditorProps> = ({
  yesNoInput,
  allowEdit,
  stepGuid,
  labelText,
  swType,
}) => {
  const nonConformInstructionRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const isTLMType = (swType === SWTypes.TLMSWI
    || swType === SWTypes.TLMRC);
  const onNonConformBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    dispatch(updateYesNoInput({
      stepGuid,
      component: {
        ...yesNoInput,
        nonConform: event.target.value,
      }
    }))
  }

  const onExpectedValueChange = (event: React.FocusEvent<HTMLSelectElement>) => {
    let isNonConformValueReset = false;
    if (isTLMType
        && (event.target.value === undefined
        || event.target.value === '')) {
      const nonConformInstruction = nonConformInstructionRef.current;
      if (nonConformInstruction) {
        nonConformInstruction.value = "";
        isNonConformValueReset = true;
      }
    }

    dispatch(updateYesNoInput({
      stepGuid,
      component: {
        ...yesNoInput,
        expectedValue: event.target.value,
        nonConform: isNonConformValueReset
        ? ""
        : yesNoInput.nonConform,
      },
    }));
  }

  const shouldConformInstructionDisabled = () => {
    return isTLMType
      && (yesNoInput.expectedValue === undefined
          || yesNoInput.expectedValue === "");
  }

  return (
    <div className="component-editor">
      <label className="mandatory block">{labelText}</label>

      <div className="component-editor">
        {allowEdit &&
          <input
            type="text"
            defaultValue={yesNoInput.label}
            onBlur={(e) => dispatch(updateYesNoInput({
              stepGuid,
              component: {
                ...yesNoInput,
                label: e.target.value,
              },
            }))}
          />
        }
        {!allowEdit &&
          <span>{yesNoInput.label}</span>
        }
        <fieldset>
          <legend>Conformance Options (optional)</legend>
          <div className="row">
            <label>Expected Value</label>
          </div>
          <div className="row component-editor">
            {allowEdit &&
              <select
                onChange={onExpectedValueChange}
                defaultValue={yesNoInput.expectedValue}>
                <option key="none">

                </option>
                <option key="yes">
                  Yes
                </option>
                <option key="no">
                  No
                </option>
              </select>
            }
            {!allowEdit &&
              <span className="expected-value">{yesNoInput.expectedValue}</span>
            }
          </div>
          <div className="row">
            <label>Non-Conformance Instructions</label>
          </div>
          <div className="row component-editor">
            {allowEdit &&
              <input
                type="text"
                ref={nonConformInstructionRef}
                defaultValue={yesNoInput.nonConform || ""}
                onBlur={onNonConformBlur}
                disabled={shouldConformInstructionDisabled()}
              />
            }
            {!allowEdit &&
              <span className="expected-value">{yesNoInput.nonConform}</span>
            }
          </div>
        </fieldset>
      </div>
    </div>
  );
}
export default YesNoInputEditor;