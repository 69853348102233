import React, { useEffect } from "react";
import "./NoticeImage.scoped.scss";
import { INoticeImage } from "interfaces/sw/SWInterfaces";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import { useDispatch } from "react-redux";
import { getNoticeImageUrl } from "store/manageSW/manageSWActions";
import { findNoticeImageType } from "utilities/fileUtilities";

interface INoticeImageProps {
    image: INoticeImage,
    noticeGuid: string,
    swGuid: string,
    swVersion: number,
    stepGuid: string,
    imageDataDestination: ImageDataDestinations,
}

const NoticeImage: React.FC<INoticeImageProps> = ({
    image,
    noticeGuid,
    swGuid,
    swVersion,
    stepGuid,
    imageDataDestination,
}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        if (!image.filename
            || image.blobData?.isLoading
            || image.filename === image.blobData?.filename) {
            // Blob url already loaded. Ignore.
            return;
        }

        dispatch(getNoticeImageUrl({
            swGuid,
            swVersion,
            noticeGuid: noticeGuid,
            stepGuid,
            filename: image.filename,
            destination: imageDataDestination,
            imageType: findNoticeImageType(image.filename),
        }));
    }, [image, noticeGuid, stepGuid, swGuid, swVersion, imageDataDestination, dispatch]);

    if (image.blobData) {
        if (image.blobData.errorMessage) {
            return (
                <div
                    className="image-holder"
                >
                    <span>
                        {image.blobData.errorMessage}
                    </span>
                </div>
            );
        } else {
            return (
                <div
                    className="image-holder"
                >
                    <img
                        src={image.blobData.blobUrl}
                        alt=""
                    />
                </div>
            );
        }
    } else {
        return null;
    }
}

export default NoticeImage;