import React, { useEffect, useRef, useState } from "react";
import Modal from "components/common/Modal";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { saveSWComment, setComments } from "store/manageSW/manageSWActions";
import SWComment from "./SWComment";
import { MentionsInput, Mention } from "react-mentions";
import "./SWCommentList.scoped.scss";
import defaultMentionSuggestionStyle from './defaultMentionSuggestionStyle'
import defaultMentionStyle from './defaultMentionStyle'
import UserApi from 'apis/user/UserApi';
import { DataClassificationTypes, SWTypes } from "interfaces/sw/SWInterfaces";

const SWCommentList: React.FC = () => {
  const {
    manageSW: {
      commentData: {
        comments,
        loadOperation,
      },
      SW,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const [commentsText, setCommentsText] = useState('');
  const endOfCommentsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endOfCommentsRef.current?.scrollIntoView();
  });

  const onClickSend = () => {
    dispatch(saveSWComment(commentsText));
    setCommentsText('');
  }

  const fetchUsers = (query: string,
    callback: (users: Array<{ display: string, id: string }>) => void) => {
    if (!query) {
      return
    }

    // Limit search results to 5 users.
    UserApi.getManageUsersList(query)
      .then(res => res.slice(0, 5).map(user =>
      ({
        display: user.name,
        id: user.email.substring(0, user.email.lastIndexOf("@"))
      })))
      .then(callback);
  }

  if (loadOperation?.wasSuccessful) {
    return (
      <Modal
        header="Comments"
        isOpen={true}
        width={"10em"}
        maxHeight={"40em"}
        controls={(
          <>
            <MentionsInput
              className="comments-textarea"
              value={commentsText}
              onChange={event => setCommentsText(event.target.value)}
              placeholder="Mention any WorkRight user by typing `@` followed by at least one character"
              style={defaultMentionStyle}
              allowSpaceInQuery={true}
              allowSuggestionsAboveCursor={true}
            >
              <Mention
                trigger="@"
                data={fetchUsers}
                style={defaultMentionSuggestionStyle}
                appendSpaceOnAdd={true}
                markup='@[__id__]'
              />
            </MentionsInput>
            <button
              className="primary-button comment-button"
              onClick={onClickSend}
            >
              Send
            </button>
            <button
              className="secondary-button"
              onClick={() => dispatch(setComments({
                loadOperation: {
                  isWorking: false,
                  wasSuccessful: false,
                },
                comments: comments,
              }))}
            >
              Close
            </button>
          </>
        )}
      >
        {(SW.dataClassification === DataClassificationTypes.Confidential &&
          SW.tcc &&
          (SW.type === SWTypes.MFGCL || SW.type === SWTypes.MFGSWI || SW.type === SWTypes.MFGRC)) &&
          <span style={{ color: "rgb(177, 0, 0)", fontSize: "small" }}>
            TCC sensitive content is about to be shared with the user.
          </span>
        }
        {comments
          ?.map(entry => (
            <SWComment
              key={entry.id}
              {...entry}
            />)
          )}
        <div ref={endOfCommentsRef}></div>
      </Modal>
    );
  }
  else {
    return null;
  }
}

export default SWCommentList;