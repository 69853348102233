import { createAction } from "@reduxjs/toolkit";
import { IFeedbackSave } from "interfaces/feedbacks/feedbackInterfaces";
import { IAttachDocument, IFeedbackDocuments } from "./feedbackTypes";
export const saveFeedback= createAction<IFeedbackSave>("feedback/saveFeedback");
export const resetFeedback = createAction("feedback/resetFeedback");
export const setCreatedSuccesfull= createAction("feedback/setCreatedSuccesfull");
export const setInitializeProccess= createAction("feedback/setInitializeProccess");
export const setErrorProccess= createAction<string>("feedback/setErrorProccess");
export const setTitle= createAction<string>("feedback/setTitle");
export const setDescription= createAction<string>("feedback/setDescription");
export const setPriority= createAction<string>("feedback/setPriority");
export const setGroup= createAction<string>("feedback/setGroup");

// Documents
export const attachDocument= createAction<IAttachDocument>("feedback/attachDocument");
export const removeDocument= createAction<IFeedbackDocuments>("feedback/removeDocument"); 




