import { createReducer } from "@reduxjs/toolkit";
import { IManageSwReportsState, ManageSwReportsTabs } from "./ManageSwReportsTypes";
import { setLoadOperation, setSwRepoListData, setCurrentTab } from "./manageSwReportsActions";

const initialState: IManageSwReportsState = {
    currentTab: ManageSwReportsTabs.FullPdf,
    swList: [],
    loadOperation: undefined,
};

const manageSwReportsReducer = createReducer(initialState, builder => {
    //load operation
    builder.addCase(setLoadOperation, (state, action) => {
        state.loadOperation = action.payload.loadOperation;
    });

    //sw repo list data
    builder.addCase(setSwRepoListData, (state, action) => {
        state.swList = action.payload;
    });

    // Set Current Tab
    builder.addCase(setCurrentTab, (state, { payload }) => {
        state.currentTab = payload;
    });
});

export default manageSwReportsReducer;