import React, { FC, ReactNode } from "react";

interface FileDropzoneProps {
  onFilesDropped(files: File[]): void;
  children?: ReactNode;
}

const FileDropzone: FC<FileDropzoneProps> = ({ children, onFilesDropped }) => {
  const onDragOver: React.DragEventHandler = (ev) => {
    ev.preventDefault();
  };

  const onDrop: React.DragEventHandler = (ev) => {
    ev.preventDefault();
    const files: File[] = [];

    for (let index = 0; index < ev.dataTransfer.items.length; index++) {
      const item = ev.dataTransfer.items[index];

      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) files.push(file);
      }
    }

    if (files.length > 0) {
      onFilesDropped(files);
    }
  };

  return (
    <div onDragOver={onDragOver} onDrop={onDrop}>
      {children}
    </div>
  );
};

export default FileDropzone;
