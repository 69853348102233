import React from "react";
import { useDispatch } from "react-redux";
import Modal from "components/common/Modal";
import { cancelRCMappingModal, setRCWhereUsedItems } from "store/swList/swListActions";
import useSelector from "store/useSelector";
import "./RCMappingModal.scoped.scss";
import SWTypeIcon from "./SWTypeIcon";
import { useHistory } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import { SWTypes } from "interfaces/sw/SWInterfaces";

const RCMappingModal: React.FC = () => {
  const {
    rcMappingData
  } = useSelector(store => store.swList);
  const dispatch = useDispatch();
  let history = useHistory();

  const onVersionClick = (guid: string, version: number) => {
    dispatch(setRCWhereUsedItems({
      rcMapping: [],
      loadRCMappingData: {
        isWorking: false,
        wasSuccessful: false,
      }
    }));
    history.push(Routes.ViewStandardWork
      .replace(":guid", guid)
      .replace(":version", version.toString()));
  }
  return (
    <Modal
      header="Reusable Content Mapping"
      controls={(
        <>
          <button
            className="secondary-button"
            onClick={() => dispatch(cancelRCMappingModal())}
          >
            Close
          </button>
        </>
      )}
      isOpen={true}
    >
      {rcMappingData
        && rcMappingData.rcMapping
        && rcMappingData.rcMapping.length > 0
        && <div>
          <div className="row header">
            <div className="cell sw-name"
            >
              Standard Work Name

            </div>
            <div className="cell width20"
            >
              Guid

            </div><div className="cell width10"
            >
              Version

            </div>
            <div className="cell width15"
            >
              Org/Plant
            </div>

            <div className="cell status width15"
            >
              Source System
            </div>
            <div className="cell buttons width10">

            </div>
          </div>

          <div className="list-body">
            {
              rcMappingData.rcMapping.map((row, index) => (
                <div className="sw-header" key={index}>
                  <div id={"testttchc" + index} className="cell sw-name" key={"swname" + index}>
                    <div className="title-holder" key={"tholder" + index}>
                      <SWTypeIcon type={row.type} key={"swtype" + index} />
                      <div key={"swtitle" + index} className="sw-title">
                        {row.title}
                        <span key={"desc" + index} className="description">
                          {row.description}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div id={row.guid} className="cell width20">{row.guid}</div>
                  <div id={row.version.toString() + index} className="cell width10">{row.version}</div>
                  <div key={"oo" + index} className="cell width15">
                          {(row.type === SWTypes.MFGSWI || row.type === SWTypes.MFGCL || row.type === SWTypes.MFGRC) ? row.owningPlant : row.owningOrg}
                  </div>
                  <div key={"sc" + index} className="cell status width15">
                    {row.sourceSystem}
                  </div>
                  <div className="cell buttons width10">
                    <button
                      onClick={() => onVersionClick(row.guid, row.version)}> Open</button>
                  </div>
                </div>
              ))}
          </div>
        </div>

      }
      {rcMappingData.rcMapping.length <= 0
        && <h2> No SW is using this content</h2>}
    </Modal>
  )
}
export default RCMappingModal;