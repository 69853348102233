import React from "react";
import { useDispatch } from "react-redux";
import Modal from "components/common/Modal";
import { IConnectedSWI } from "../../../../interfaces/sw/SWInterfaces";
import { deleteConnectedSWI } from "../../../../store/manageSW/manageSWActions";

interface IMFGDeleteConnectedSWIModalProps {
  connectedSWI: IConnectedSWI,
  swGuid: string,
  swVersion: number,
  onCancel: () => void
}

const MFGDeleteConnectedSWIModal: React.FC<IMFGDeleteConnectedSWIModalProps> = 
  ({ connectedSWI, swGuid, swVersion, onCancel}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      header="Delete Connected Child SWI"
      controls={(
        <>
          <button
            className="primary-button"
            onClick={() => dispatch(deleteConnectedSWI({
              swGuid: swGuid,
              swVersion: swVersion,
              id: connectedSWI.id
            }))}
          >
            Delete
          </button>
          <button
            className="secondary-button"
            onClick={() => onCancel()}
          >
            Cancel
          </button>
        </>
      )}
      isOpen={true}
    >
      <p>
        Are you sure you want to remove the connected child SWI?
        This will delete the data permanently from the system.
      </p>
    </Modal>
  );
};

export default MFGDeleteConnectedSWIModal;