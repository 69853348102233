import FlowLayout from "components/layout/FlowLayout";
import SWTypeIcon from "components/sw/search/SWTypeIcon";
import { BatchUpdateErrorTypes, IBatchUpdateError, ISWWithBatchError } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import React from "react";
import { useDispatch } from "react-redux";
import { removeSWs, setSort, tryAddSWs } from "store/batchUpdates/batchUpdatesActions";
import { showInfoToast } from "store/toast/toastActions";
import useSelector from "store/useSelector";
import formatDate from "utilities/formatDate";
import "./SWList.scoped.scss";
import ViewSWMetaData from '../SWSearch/ViewSWMetaData';
import { loadBatchUpdateSWMetaData } from "store/approvals/approvalsActions";
import ModalSpinner from "components/common/ModalSpinner";
import BatchSort from "./BatchSort";
import { SortDirection } from "interfaces/misc/miscInterfaces";
import { SWSortFields } from "interfaces/sw/SWInterfaces";

export enum SWListModes {
  Add,
  Review,
  Errors,
  ReadOnly,
}

interface ISWListProps {
  mode: SWListModes,
  swList: ISWWithBatchError[],
  approvalId?: number,
  sortBySelectedCol?: SWSortFields | null,
  sortDirSelectedCol?: SortDirection | null,
}

const SWList: React.FC<ISWListProps> = ({
  swList,
  mode,
  approvalId,
  sortBySelectedCol,
  sortDirSelectedCol,
}) => {

  const {
    currentBatchUpdateSWMetaData,
    loadBatchUpdateSWMetaDataOperation,
  } = useSelector(store => store.approvals);

  const addedSWs = useSelector(store => store.batchUpdates.addedSWs);
  const dispatch = useDispatch();
  const approvalIdValue = approvalId ?? 0;

  const sortByColumn = (sortBy: SWSortFields) => {
    let sortDir;
    if (sortBySelectedCol !== sortBy) {
      sortDir = SortDirection.Asc;
    }
    else {
      if (sortDirSelectedCol === SortDirection.Asc) {
        sortDir = SortDirection.Desc;
      }
      else {
        sortDir = SortDirection.Asc;
      }
    }
    dispatch(
      setSort({
        sortBy: sortBy,
        sortDir: sortDir,
      })
    )
  }

  const onAdd = (sws: ISWWithBatchError[]) => {
    if (sws.length === 0) {
      return;
    }

    dispatch(tryAddSWs(sws.map(x => x.sw)));
  }

  const onRemove = (sws: ISWWithBatchError[]) => {
    dispatch(removeSWs({
      guids: sws.map(x => x.sw.guid),
    }));

    if (sws.length > 1) {
      dispatch(showInfoToast(`${addedSWs.length} Standard Works removed from batch update.`))
    } else if (sws.length === 1) {
      dispatch(showInfoToast(`${sws[0].sw.title} removed from batch update.`));
    }
  }

  const renderError = (error: IBatchUpdateError) => {
    let msg = "";

    if (error.errorType === BatchUpdateErrorTypes.HasDraft) {
      msg += "A current draft exists.";
    } else if (error.errorType === BatchUpdateErrorTypes.HasLock) {
      msg += "Part of another pending batch update.";
    }

    if (error.message) {
      if (msg) {
        msg += ` ${error.message}`;
      } else {
        msg += error.message;
      }
    }

    return msg;
  }

  return (
    <FlowLayout
      header={(
        <div className="row header">
          <div className="cell sw-name"
            onClick={() => sortByColumn(SWSortFields.Title)}>
            Standard Work Name
            <BatchSort
              colName="Title"
            />
          </div>
          {mode === SWListModes.Errors
            ? (
              <div className="cell error">
                Error
              </div>
            ) : (
              <>
                <div className="cell pub"
                  onClick={() => sortByColumn(SWSortFields.LatestPublishedCreatedOn)}>
                  Last Published
                  <BatchSort
                    colName="LatestPublishedCreatedOn"
                  />
                </div>
                <div className="cell status"
                  onClick={() => sortByColumn(SWSortFields.ModifiedOn)}>
                  Status
                  <BatchSort
                    colName="ModifiedOn"
                  />
                </div>
                {mode === SWListModes.ReadOnly &&
                  <div className="cell metadata">
                    &nbsp;
                  </div>
                }
              </>
            )
          }
          <div className="cell buttons">
            {mode === SWListModes.Add &&
              <button
                className="secondary-button"
                onClick={() => onAdd(swList)}
              >
                +All
              </button>
            }
            {((mode === SWListModes.Add ||
              mode === SWListModes.Review) &&
              addedSWs.length > 0) &&
              <button
                className="secondary-button"
                onClick={() => onRemove(swList)}
              >
                -All
              </button>
            }
          </div>
        </div>
      )}
    >
      <div className="list-body">
        {swList.map(swData =>
          <div
            key={swData.sw.guid}
            className="sw-header row"
          >
            <div className="cell sw-name">
              <div className="title-holder">
                <SWTypeIcon type={swData.sw.type} />
                <div className="sw-title">
                  {swData.sw.title}
                  <span className="description">
                    {swData.sw.description}
                  </span>
                </div>
              </div>
            </div>
            {mode === SWListModes.Errors
              ? (
                <div className="cell error">
                  {swData.error &&
                    <div className="error-message">
                      {renderError(swData.error)}
                    </div>
                  }
                </div>
              ) : (
                <>
                  <div className="cell pub">
                    {swData.sw.latestPublishedCreatedOn && (
                      <>
                        {formatDate(swData.sw.latestPublishedCreatedOn, false)}
                        <br />(
                        {swData.sw.latestPublishedVersion
                          ? `version ${swData.sw.latestPublishedVersion}`
                          : ""}
                        )
                      </>
                    )}
                  </div>
                  <div className="cell status">
                    {swData.sw.version === 0 &&
                      <span className="draft-label">DRAFT </span>
                    }
                    Last Edited: {formatDate(swData.sw.modifiedOn, false)}
                    <br />
                    by {swData.sw.modifiedBy}
                  </div>
                  {mode === SWListModes.ReadOnly &&
                    <div className="cell metadata">
                      <button
                        className="primary-button"
                        onClick={() => dispatch(loadBatchUpdateSWMetaData({ approvalId: approvalIdValue, swId: swData.sw.id }))}
                      >
                        View Metadata
                      </button>
                    </div>
                  }
                </>
              )}

            <div className="cell buttons">
              {mode === SWListModes.Add &&
                <button
                  className="secondary-button"
                  onClick={() => onAdd([swData])}
                  disabled={!!addedSWs.find(x => x.guid === swData.sw.guid)}
                >
                  +
                </button>
              }
              {(mode === SWListModes.Review
                || mode === SWListModes.Errors) &&
                <button
                  className="secondary-button"
                  onClick={() => onRemove([swData])}
                >
                  -
                </button>
              }
            </div>
          </div>
        )
        }
        {loadBatchUpdateSWMetaDataOperation?.isWorking &&
          <ModalSpinner />
        }
        {loadBatchUpdateSWMetaDataOperation?.wasSuccessful &&
          (!currentBatchUpdateSWMetaData
            || !currentBatchUpdateSWMetaData.approvalId
            || !currentBatchUpdateSWMetaData.swId
            || !currentBatchUpdateSWMetaData.commands
            || currentBatchUpdateSWMetaData.commands.length === 0) &&
          <label>Standard work Metadata not found for corresponding Batch update Approval</label>
        }
        {loadBatchUpdateSWMetaDataOperation?.wasSuccessful &&
          <ViewSWMetaData />
        }
      </div >
    </FlowLayout >
  );
};

export default SWList;