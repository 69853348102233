import React, { useState } from "react";
import './ApprovalHistoryList.scss'
import useSelector from "store/useSelector";
import _ from 'lodash';
import formatDate from "utilities/formatDate";
import { ApprovalResponseTypes, ApprovalStatuses, IHistoricalApproval, IApprovalWorkFlowResponse, ApprovalLevels } from "interfaces/approvals/approvalInterfaces";
import { LCLapprovalWorkFlow, MFGApprovalWorkflow, MFGRCApprovalWorkFlow, psdApprovalWorkFlow } from "./ApprovalWorkflowList";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ApprovalHistoryModal from "./ApprovalHistoryModal";
import { showErrorToast } from "store/toast/toastActions";
import ApprovalApi from "apis/approval/ApprovalApi";
import ModalSpinner from "components/common/ModalSpinner";
import { ApprovalTooltipTrigger } from "./ApprovalTooltip";

const ApprovalHistoryList: React.FC<IHistoricalApproval> = (approval) => {
  const {
    SW
  } = useSelector(store => store.manageSW)

  const [isLoading, setIsLoading] = useState(false);

  var responses = approval.responses
    .slice()
    .sort((a, b) => a.createdOn < b.createdOn ? -1 : 1);

  const lastResponse = responses.length > 0
    ? responses[responses.length - 1]
    : null;

  var seenFirstTechContent: boolean = false;

  const historicalResponsesAddList: IApprovalWorkFlowResponse[] = responses.map(response => {
    var optional: boolean = false;
    if (response.level === ApprovalLevels.SME) {
      optional = true;
    }
    if (seenFirstTechContent && (SW.type === SWTypes.MFGCL || SW.type === SWTypes.MFGSWI || SW.type === SWTypes.MFGRC) && response.level === ApprovalLevels.TechContent) {
      optional = true;
    }
    if ((SW.type === SWTypes.MFGCL || SW.type === SWTypes.MFGSWI || SW.type === SWTypes.MFGRC) && response.level === ApprovalLevels.TechContent) {
      seenFirstTechContent = true;
    }
    const { id, responseType, comment, attachmentFilename, level, createdBy, createdOn } = response;
    return { id, responseType, comment, attachmentFilename, level, optional, createdBy, createdOn };
  });


  function getApprovalWorkflow() {
    var approvalList: IApprovalWorkFlowResponse[] = []
    if (SW.type === SWTypes.SWI || SW.type === SWTypes.CL || SW.type === SWTypes.ECL) {
      approvalList = _.cloneDeep(psdApprovalWorkFlow);
    } else if (SW.type === SWTypes.LCL) {
      approvalList = _.cloneDeep(LCLapprovalWorkFlow);
    } else if (SW.type === SWTypes.TLMSWI || SW.type === SWTypes.TLMRC) {
      approvalList = _.cloneDeep(historicalResponsesAddList);
    } else if (SW.type === SWTypes.MFGSWI || SW.type === SWTypes.MFGCL) {
      approvalList = _.cloneDeep(MFGApprovalWorkflow);
    } else if (SW.type === SWTypes.MFGRC) {
      approvalList = _.cloneDeep(MFGRCApprovalWorkFlow);
    }
    return approvalList;
  }

  function markOptionalReviewerAsSkip(approvalWorkFlow: IApprovalWorkFlowResponse[]) {
    approvalWorkFlow.forEach((singleApproval, index) => {
      if (singleApproval.optional && singleApproval.responseType === ApprovalResponseTypes.NotCompleted) {
        if (approval.status === ApprovalStatuses.Approved) {
          singleApproval.responseType = ApprovalResponseTypes.Skip;
        } else if (index < approvalWorkFlow.length - 1
          && approvalWorkFlow[index + 1].responseType !== ApprovalResponseTypes.NotCompleted) {
          singleApproval.responseType = ApprovalResponseTypes.Skip;
        }
      }
    })
  }

  function getAllLevelsInApprovalHistory() {
    const approvalList = getApprovalWorkflow();
    var setActiveApprovalOnlyOnce: boolean = true;
    var updatedApprovalList: IApprovalWorkFlowResponse[] = [];
    if ((SW.type === SWTypes.TLMSWI || SW.type === SWTypes.TLMRC)) {
      const activeApproval: IApprovalWorkFlowResponse = {
        id: 0,
        responseType: ApprovalResponseTypes.Active,
        comment: "",
        attachmentFilename: null,
        level: approval.currentLevel,
        optional: false,
        createdBy: "",
        createdOn: new Date(),
      };
      if (approval.status === ApprovalStatuses.Active) {
        approvalList.push(activeApproval);
      }
      updatedApprovalList = approvalList;
    } else {
      if (approval.status !== ApprovalStatuses.Retracted) {
        approvalList.forEach((app, i) => {
          const index = historicalResponsesAddList.findIndex(res => res.level === app.level && res.optional === app.optional);
          if (index != -1) {
            updatedApprovalList.push(historicalResponsesAddList[index]);
          } else {
            updatedApprovalList.push(app);
          }
          //set the current level as active
          if (approval.status === ApprovalStatuses.Active
            && updatedApprovalList[i].level === approval.currentLevel
            && updatedApprovalList[i].responseType === ApprovalResponseTypes.NotCompleted
            && setActiveApprovalOnlyOnce) {
            setActiveApprovalOnlyOnce = false;
            updatedApprovalList[i].responseType = ApprovalResponseTypes.Active;
          }
        })
      } else {
        updatedApprovalList = historicalResponsesAddList;
      }

      markOptionalReviewerAsSkip(updatedApprovalList);
    }
    return updatedApprovalList;
  }

  const updatedApprovalList = getAllLevelsInApprovalHistory();

  const viewAttachment = async (response: IApprovalWorkFlowResponse) => {
    if (!response.attachmentFilename) {
      showErrorToast("The selected response has no attachment.");
      return;
    }

    setIsLoading(true);

    try {
      const absoluteUri = await ApprovalApi.getApprovalAttachmentUrl(response.id);

      const link = document.createElement("a");
      link.download = response.attachmentFilename;
      link.href = absoluteUri;
      link.target = "_appr_att_download"
      link.click();
    } catch (err: any) {
      showErrorToast(err?.message || err.toString())
    } finally {
      setIsLoading(false);
    }
  }

  const getCommentsAndAttachment = (data: IApprovalWorkFlowResponse) => {

    if (data.comment == null && data.attachmentFilename == null) {
      return null;
    }
    const commentSection = data.comment != null &&
      data.comment.split('\n').map((line, ix, arr) => (
        <React.Fragment
          key={ix}
        >
          {ix == 0 &&
            <>
              <b>{data.responseType} Comment :</b>
              <br />
            </>
          }
          {line}
          {ix < arr.length - 1 &&
            <br />
          }
        </React.Fragment>
      ))

    const attachmentSection = data.attachmentFilename &&
      <button
        className="tertiary-button attachment"
        onClick={() => viewAttachment(data)}
      >
        View attachment
      </button>

    const combinedSection =
      <>
        {commentSection}
        <br />
        <br />
        {attachmentSection}
      </>

    return combinedSection;
  }


  return (
    <div
      className={`approval ${approval.status}`}
    >
      <div
        className="header"
      >
        Submitted for review by {approval.createdBy} on {formatDate(approval.createdOn)}
      </div>
      <div className="approvalHistoryContainer">
        {updatedApprovalList.map((data, index) => (
          <div className='history-container'>
            <ApprovalHistoryModal key={index} {...data} />
            {(data.responseType === ApprovalResponseTypes.Approved ||
              data.responseType === ApprovalResponseTypes.Retracted ||
              data.responseType === ApprovalResponseTypes.Revert ||
              data.responseType === ApprovalResponseTypes.Rejected) &&
              <>
                <ApprovalTooltipTrigger
                  tooltipContent={
                    getCommentsAndAttachment(data)
                  }
                >
                  <div className={`detail-container approval-history-text ${data.responseType}`}>
                    {data.responseType} by : {data.createdBy}
                  </div>
                </ApprovalTooltipTrigger>
                <div className={`date-container approval-history-text ${data.responseType}`}>
                  <CalendarMonthIcon className={`approval-history-icon ${data.responseType}`} />
                  {formatDate(data.createdOn)}
                </div>
              </>
            }
            {data.responseType === ApprovalResponseTypes.Active &&
              <>
                <div className={`detail-container approval-history-text ${data.responseType}`}>Pending review at {data.level} </div>
              </>
            }
            {data.responseType === ApprovalResponseTypes.Skip &&
              <>
                <div className={`detail-container approval-history-text ${data.responseType}`}>Skipped Approval </div>
              </>
            }
          </div>

        ))}
      </div>
      {isLoading &&
        <ModalSpinner />
      }
    </div>
  );
}

export default ApprovalHistoryList;