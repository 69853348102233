import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { addReusableMetaDataItem, removeReusableMetaDataItem, saveReusableCompFromTLMSWI, setCreateRCNoticePopup, setCreateReusableContentPopup, setOwningPlant, setResuableFromTLMSWStringAttribute, setReusableCompFromMFGSWIGemsRevision, setReusableCompFromMFGSWIOwningPlant, setReusableCompFromTLMSWIOwningOrg, setReusableMetaDataLanguage } from "store/manageSW/manageSWActions";
import useSelector from "store/useSelector";
import MasterDataInput from "../manage/attributes/MasterDataInput";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { AuthFilter } from "auth/AuthFilter";
import { Roles } from "interfaces/user/UserInterfaces";
import {loadGemsRevisionNumbers, loadOrganizations, searchEpicEquipment, searchMaterials, searchOwningPlants, searchPerformingPlants, searchProductCenters, searchWorkCenters, setSection } from "store/masterData/masterDataActions";
import "./SelectMetadata.scoped.scss";
import validateSW from "utilities/sw/validateSW";
import { showErrorToast, showSuccessToast } from "store/toast/toastActions";
import {loadLanguages} from "store/masterData/masterDataActions";
import { RCTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import { ReusableCompType } from "store/manageSW/manageSWTypes";

interface ISelectMetadataProps {
    setPreviousTab?(): void,
    updateReusableSteps?(): void,
    rcType?: RCTypes,
}

const SelectMetadata: React.FC<ISelectMetadataProps> = ({ setPreviousTab, updateReusableSteps, rcType }) => {
    const {
        manageSW: {
            ReusableCompFromTLMSWI,
            reusableCompSelectedType,
            SW
        },
        masterData: {
            organizations,
            productCenters,
            epicEquipment,
            languages,
            owningPlants,
            performingPlants,
            workCenters,
            materials,
            gemsRevisionNumbers
        },
        auth: {
            currentUser,
        },
    } = useSelector(store => store);
    const dispatch = useDispatch();
    const userOrgGuids = currentUser.orgs.map(o => o.guid);
    const owningOrgFilter = AuthFilter
        .isLoggedIn()
        .hasRole(Roles.SysAdmin)
        .hasRole(Roles.TechComm)
        .isMatch(currentUser)
        ? undefined
        : (item: IMasterDataItem) => userOrgGuids.indexOf(item.guid) > -1;

    const saveReusableComp = async () => {
        if (reusableCompSelectedType === undefined && setPreviousTab) {
            (SW.type === SWTypes.MFGSWI) 
                ? dispatch(showErrorToast("Atleast one Step or Sub step must be selected to create Reusable Content."))
                : dispatch(showErrorToast("Atleast one Task, Step or Sub step must be selected to create Reusable Content."))
             return;
        }
        if (updateReusableSteps) {
            await updateReusableSteps();
        }
        const validationResult = validateSW(ReusableCompFromTLMSWI);
        if (!validationResult.isValid) {
            dispatch(showErrorToast(validationResult.errorMessage));
            return;
        }
        let swRcType:RCTypes = RCTypes.Task;
        switch (reusableCompSelectedType) {  
            case ReusableCompType.Task: {
                swRcType = RCTypes.Task;
                break;
            }         
            case ReusableCompType.Step: {
                swRcType = RCTypes.Step;
                break;
            }
            case ReusableCompType.SubStep: {
                swRcType = RCTypes.SubStep;
                break;
            }
            case ReusableCompType.Notice: {
                swRcType = RCTypes.Notice;
                break;
            }
        }
        dispatch(saveReusableCompFromTLMSWI(swRcType));
    }

    const closeRCpopup = () => {
        // if called from Notice of TLM edit page
        if (rcType === RCTypes.Notice) {
            dispatch(setCreateRCNoticePopup(
                {
                    rcNoticePopupOpen: false,
                    notice: null,
                }));
        }
         // if called from ellipsis of TLM edit page
        else {
            dispatch(setCreateReusableContentPopup({
                isCreateRCPopup: false,
                swType : SW.type,
              }));
              dispatch(setSection({ name: "owningPlants", section: { items: [] } }));
              dispatch(setSection({ name: "workCenters", section: { items: [] } }));
              dispatch(setSection({ name: "performingPlants", section: { items: [] } }));
              dispatch(setSection({ name: "materials", section: { items: [] } }));
              dispatch(setSection({ name: "gemsRevisionNumbers", section: { items: [] } }));
        }
    }

    const onRemovePlant = () => {
        dispatch(setReusableCompFromMFGSWIOwningPlant({ owningPlant: undefined, swType: SW.type }));
        dispatch(setSection({ name: "workCenters", section: { items: [] } }));
        dispatch(setSection({ name: "performingPlants", section: { items: [] } }));
        dispatch(setSection({ name: "materials", section: { items: [] } }));
        dispatch(setSection({ name: "gemsRevisionNumbers", section: { items: [] } }));
    }

    const onRemoveMaterial = (item: IMasterDataItem) =>{
        dispatch(removeReusableMetaDataItem({
            metaDataName: "materials",
            item,
        }));
        dispatch(setReusableCompFromMFGSWIGemsRevision(undefined)); 
        dispatch(setSection({ name: "gemsRevisionNumbers", section: { items: [] } }));
    }

    useEffect(() => {
        if (
          SW.type === SWTypes.MFGSWI &&
          gemsRevisionNumbers.items.length === 1 &&
          ReusableCompFromTLMSWI.materials.length > 0 &&
          !ReusableCompFromTLMSWI.gemsRevisionNumber &&
          !!ReusableCompFromTLMSWI.materials[0]?.guid
        ) {
          dispatch(
            addReusableMetaDataItem({
              metaDataName: "gemsRevisionNumber",
              item: gemsRevisionNumbers.items[0],
            })
          );
    
          dispatch(showSuccessToast("Revision Number selected automatically."));
        }
      }, [ReusableCompFromTLMSWI.materials[0]?.guid, gemsRevisionNumbers]);

    return (
        <div
            className="inputs"
        >
            <div
                className="type-row"
            >
                <div
                    className="input-container"
                >
                    <label className="mandatory">Title</label>
                    <input
                        type="text"
                        value={ReusableCompFromTLMSWI.title}
                        disabled={false}
                        onChange={(e) => dispatch(setResuableFromTLMSWStringAttribute({ attributeName: "title", value: e.target.value }))} />
                </div>
                <div
                    className="input-container"
                >
                    <label>Description</label>
                    <input
                        type="text"
                        value={ReusableCompFromTLMSWI.description}
                        onChange={(e) => dispatch(setResuableFromTLMSWStringAttribute({ attributeName: "description", value: e.target.value }))}
                        disabled={false} />
                </div>
                <div
                    className="input-container"
                >
                    <MasterDataInput
                        label="Language"
                        isMandatory="submitOnly"
                        selectedItems={ReusableCompFromTLMSWI.language ? [ReusableCompFromTLMSWI.language] : []}
                        allowMultiple={false}
                        onAddItem={(item: IMasterDataItem) => dispatch(setReusableMetaDataLanguage({ masterDataItem: item, countrySelected: ReusableCompFromTLMSWI.countries.length > 0, geoUnitSelected: ReusableCompFromTLMSWI.geoUnits.length > 0 }))}
                        onRemoveItem={() => dispatch(setReusableMetaDataLanguage({ masterDataItem: undefined, countrySelected: ReusableCompFromTLMSWI.countries.length > 0, geoUnitSelected: ReusableCompFromTLMSWI.geoUnits.length > 0 }))}
                        loadItems={() => dispatch(loadLanguages({ onlyActive: true }))}
                        masterDataSection={languages}
                        itemFormatter={(item: IMasterDataItem) => item.value}
                        disabled={false}
                    />
                </div>
                { SW.type === SWTypes.TLMSWI &&
                <div>
                    <div
                        className="input-container"
                    >
                        <MasterDataInput
                            label="Owning Organization"
                            isMandatory={true}
                            disabled={false}
                            selectedItems={ReusableCompFromTLMSWI.owningOrg ? [ReusableCompFromTLMSWI.owningOrg] : []}
                            allowMultiple={false}
                            onAddItem={(item: IMasterDataItem) => dispatch(setReusableCompFromTLMSWIOwningOrg(item))}
                            onRemoveItem={() => dispatch(setReusableCompFromTLMSWIOwningOrg(undefined))}
                            loadItems={() => dispatch(loadOrganizations({ onlyActive: true }))}
                            masterDataSection={organizations}
                            itemFormatter={(item: IMasterDataItem) => `${item.code} - ${item.value}`}
                            itemFilter={owningOrgFilter}
                            isAsyncSearch={false} />
                    </div>
                <div
                    className="input-container"
                >
                    <MasterDataInput
                        label={"EPIC Equipment"}
                        isMandatory={false}
                        disabled={false}
                        isAsyncSearch={true}
                        selectedItems={ReusableCompFromTLMSWI.epicEquipment}
                        allowMultiple={true}
                        onAddItem={(item: IMasterDataItem) => dispatch(addReusableMetaDataItem({
                            metaDataName: "epicEquipment",
                            item,
                        }))}
                        onRemoveItem={(item: IMasterDataItem) => dispatch(removeReusableMetaDataItem({
                            metaDataName: "epicEquipment",
                            item,
                        }))}
                        loadItems={(searchTerm: string) => dispatch(searchEpicEquipment({ searchTerm, onlyActive: true }))}
                        masterDataSection={epicEquipment}
                        itemFormatter={(item: IMasterDataItem) => item.value}
                    />
                </div>

                <div
                    className="input-container"
                >
                    <MasterDataInput
                        label="Product Center"
                        isMandatory={false}
                        isAsyncSearch={true}
                        disabled={false}
                        selectedItems={ReusableCompFromTLMSWI.productCenters}
                        allowMultiple={true}
                        onAddItem={(item: IMasterDataItem) => dispatch(addReusableMetaDataItem({
                            metaDataName: "productCenters",
                            item,
                        }))}
                        onRemoveItem={(item: IMasterDataItem) => dispatch(removeReusableMetaDataItem({
                            metaDataName: "productCenters",
                            item,
                        }))}
                        loadItems={(searchTerm: string) => dispatch(searchProductCenters({ searchTerm: searchTerm, onlyActive: true }))}
                        masterDataSection={productCenters}
                        itemFormatter={(item: IMasterDataItem) => item.value}
                    />
                </div>
                </div>
                }
                { SW.type === SWTypes.MFGSWI &&

                <div>
                    <div 
                        className="input-container"
                    >
                    <MasterDataInput
                        label="Owning Plant"
                        isMandatory={true}
                        disabled={false}
                        selectedItems={ReusableCompFromTLMSWI.owningPlant ? [ReusableCompFromTLMSWI.owningPlant] : []}
                        allowMultiple={false}
                        onAddItem={(item: IMasterDataItem) => dispatch(setReusableCompFromMFGSWIOwningPlant({ owningPlant: item, swType: SW.type }))}
                        onRemoveItem={() => onRemovePlant()}
                        loadItems={(searchTerm: string) => dispatch(searchOwningPlants({
                                searchTerm: searchTerm,
                                onlyActive: true
                        }))}
                        masterDataSection={owningPlants}
                        itemFormatter={(item: IMasterDataItem) => (
                            <span>
                                {item.code}{"-"}{item.value}
                            </span>
                        )}
                        isAsyncSearch={true}  />
                    </div>
                    <div 
                        className="input-container"
                    >
                    <MasterDataInput
                        label="Performing Plants"
                        isMandatory={false}
                        disabled={false}
                        selectedItems={ReusableCompFromTLMSWI.performingPlants}
                        allowMultiple={true}
                        onAddItem={(item: IMasterDataItem) => dispatch(addReusableMetaDataItem({
                            metaDataName: "performingPlants",
                            item,
                        }))}
                        onRemoveItem={(item: IMasterDataItem) => dispatch(removeReusableMetaDataItem({
                            metaDataName: "performingPlants",
                            item,
                        }))}
                        loadItems={(searchTerm: string) => dispatch(searchPerformingPlants({
                            searchTerm: searchTerm,
                            onlyActive: true,
                            plantSource: ReusableCompFromTLMSWI.plantSource,
                        }))}
                        masterDataSection={performingPlants}
                        itemFormatter={(item: IMasterDataItem) => (
                            <span>
                                {item.code}{"-"}{item.value}
                            </span>
                        )}
                        isAsyncSearch={true}
                    />
                    </div>
                    <div
                        className="input-container"
                    >
                    <MasterDataInput
                        label="Work Centers"
                        isMandatory={false}
                        isAsyncSearch={true}
                        disabled={false}
                        selectedItems={ReusableCompFromTLMSWI.workCenters}
                        allowMultiple={true}
                        onAddItem={(item: IMasterDataItem) => dispatch(addReusableMetaDataItem({
                            metaDataName: "workCenters",
                            item,
                        }))}
                        onRemoveItem={(item: IMasterDataItem) => dispatch(removeReusableMetaDataItem({
                            metaDataName: "workCenters",
                            item,
                        }))}
                        loadItems={(searchTerm: string) => dispatch(searchWorkCenters({
                            searchTerm: searchTerm,
                            plantCode: ReusableCompFromTLMSWI.owningPlant?.code ? ReusableCompFromTLMSWI.owningPlant?.code : "",
                            plantSource: ReusableCompFromTLMSWI.owningPlant?.source ? ReusableCompFromTLMSWI.owningPlant?.source : undefined,
                            onlyActive: true
                        }))}
                        masterDataSection={workCenters}
                        itemFormatter={(item: IMasterDataItem) => (
                            <span>
                                {item.code}{"-"}{item.value}
                            </span>
                        )}
                    />
                    </div>
                    <div
                        className="input-container"
                    >
                    <MasterDataInput
                        label="Material"
                        isMandatory={false}
                        isAsyncSearch={true}
                        disabled={false}
                        selectedItems={ReusableCompFromTLMSWI.materials}
                        allowMultiple={false}
                        onAddItem={(item: IMasterDataItem) => dispatch(addReusableMetaDataItem({
                            metaDataName: "materials",
                            item,
                        }))}
                        onRemoveItem={(item: IMasterDataItem) => onRemoveMaterial(item)}
                        loadItems={(searchTerm: string) => dispatch(searchMaterials({
                            searchTerm: searchTerm,
                            plantCode: ReusableCompFromTLMSWI.owningPlant?.code ? ReusableCompFromTLMSWI.owningPlant?.code : "",
                            plantSource: ReusableCompFromTLMSWI.owningPlant?.source ? ReusableCompFromTLMSWI.owningPlant?.source : undefined,
                            onlyActive: true
                        }))}
                        masterDataSection={materials}
                        itemFormatter={(item: IMasterDataItem) => (
                            <span>
                                {item.code}{"-"}{item.value}
                            </span>
                        )}
                    />
                    </div>
                    <div 
                        className="input-container"
                    >
                    <MasterDataInput
                        label="Revision Number"
                        isMandatory={(ReusableCompFromTLMSWI.materials.length > 0)}
                        isAsyncSearch={false}
                        disabled={false}
                        selectedItems={ReusableCompFromTLMSWI.gemsRevisionNumber ? [ReusableCompFromTLMSWI.gemsRevisionNumber] : []}
                        allowMultiple={false}
                        onAddItem={(item: IMasterDataItem) => dispatch(addReusableMetaDataItem({
                            metaDataName: "gemsRevisionNumber",
                            item,
                        }))
                        }
                        onRemoveItem={(item) => dispatch(removeReusableMetaDataItem({
                            metaDataName: "gemsRevisionNumber",
                            item,
                        }))}
                        loadItems={() => dispatch(loadGemsRevisionNumbers({
                            plantCode: ReusableCompFromTLMSWI.owningPlant?.guid,
                            materialCode: ReusableCompFromTLMSWI.materials[0]?.code,
                            plantSource: ReusableCompFromTLMSWI.owningPlant?.source,
                            onlyActive: true
                        }))}
                        masterDataSection={gemsRevisionNumbers}
                        itemFormatter={(item: IMasterDataItem) => (
                            <span>
                                {item.value}
                            </span>
                        )}
                    />
                    </div>
                </div>
                }

                <div
                    className="input-container">
                    <label className="mandatory-alternative">
                        Version Changes
                    </label>
                    <input
                        type="text"
                        value={ReusableCompFromTLMSWI.versionChanges}
                        onChange={(e) => dispatch(setResuableFromTLMSWStringAttribute({ attributeName: "versionChanges", value: e.target.value }))}
                        disabled={false} />
                </div>
            </div>
            <div className="buttonDiv">
                {setPreviousTab &&
                    <button
                        className="primary-button"
                        onClick={setPreviousTab}
                    >
                        Back
                    </button>
                }
                <button
                    className="primary-button"
                    onClick={saveReusableComp}
                >
                    Save
                </button>
                <button
                    className="primary-button lastButton"
                    onClick={closeRCpopup}
                >
                    Close
                </button>
            </div>
        </ div>
    );
}

export default SelectMetadata;


