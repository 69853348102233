import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  IStep,
  SWTypes,
  StepStringMetaPropNames,
  StepComponentTypes,
  IImageComponent,
  IAllowedRCControlsConfig,
  StepTypes,
  RCTypes,
  ISW,
  TemplateTypes
} from "interfaces/sw/SWInterfaces"
import "./StepEditor.scoped.scss";
import ManageStepMetadata from "./metadata/ManageStepMetadata";
import InputComponentTypeModal, { IDisabledComponentType } from "./components/InputComponentTypeModal";
import ContentComponentTypeModal from "./components/ContentComponentTypeModal";
import StepComponent from "./components/StepComponent"
import Collapsible from "components/common/Collapsible";
import { ImageDataDestinations, INoticeVisibility, IStepVisibility } from "store/manageSW/manageSWTypes";
import {
  disableRcContent,
  duplicateStep,
  fetchRCNotice,
  loadStepComments,
  pasteComponent,
  setIsSearchRCNoticeOpen,
  setRCNoticeStep,
  updateComponentSortOrders
} from "store/manageSW/manageSWActions";
import ReorderModal, { ISortableItem } from "components/common/reorderModal/ReorderModal";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import sortIcon from "media/icons/dls/sort.svg";
import { showErrorToast, showInfoToast} from "store/toast/toastActions";
import AddSWModal from './AddSWModal'
import SelectRoutingOperationModal from "./SelectRoutingOperationModal";
import { loadOperationTree } from "store/masterData/masterDataActions";
import { IOperationTreeNode } from "store/masterData/masterDataTypes";
import { TooltipTrigger } from "components/common/Tooltip";
import mfgDiagramOperations from "media/mfg/mfg-diagram-operations.png";
import mfgDiagramStepGroup from "media/mfg/mfg-diagram-step-group.png"
import mfgDiagramSteps from "media/mfg/mfg-diagram-steps.png"
import AddRCNoticeModal from "./notices/rcNotice/AddRCNoticeModal";
import { clearFilter } from "store/swList/swListActions";
import EllipsesDropdown from "../../../common/EllipsesDropdown";

const mfgSwTypes = [
  SWTypes.MFGRC,
  SWTypes.MFGSWI,
  SWTypes.MFGCL
];

const childStepComponentEnabledTypes = [
  SWTypes.TLMRC,
  SWTypes.TLMSWI,
  SWTypes.MFGRC,
  SWTypes.MFGSWI,
];

interface IStepEditorProps {
  step: IStep,
  parentOrders: number[],
  isInTask: boolean,
  isDisabled: boolean,
  isSWEditable: boolean,
  swGuid: string,
  swVersion: number,
  imageDataDestination: ImageDataDestinations,
  swType: SWTypes,
  steps: IStep[],
  onDeleteStep?(stepGuid: string, isRCStep: boolean): void,
  onRCDelinkStep?(rcID?: number): void,
  onConditionalChange?(stepGuid: string, isConditional: boolean): void,
  onClientReportChange?(stepGuid: string, clientReport: boolean): void,
  onStepStringValueChange?(stepGuid: string, propName: StepStringMetaPropNames, value: string): void,
  onAddChildStep?(step: IStep): void,
  onAddNewComponent?(stepGuid: string, type: StepComponentTypes): void,
  onAddRoutingOperationComponent?(stepGuid: string, operation: IOperationTreeNode): void,
  isVisibilityExternal?: boolean,
  stepVisibilities?: IStepVisibility[],
  noticeVisibilities?: INoticeVisibility[],
  setStepVisibility?(stepGuid: string, isOpen: boolean): void,
  scrollToStepGuid: string | undefined,
  isDraftPage: boolean,
  stepsCountCalculate?(): void,
  isRCStep?: boolean,
  onSearchRCSteps?(step?: IStep): void,
  onSearchRcSubSteps?(step?: IStep): void,
  rcParentOrder: number[],
  rcType?: RCTypes,
  plantCode?: string,
  materialCode?: string,
  group?: string,
  groupCounter?: number,
  sw?: ISW,
}

const StepEditor: React.FC<IStepEditorProps> = ({
  step,
  parentOrders,
  isInTask,
  isDisabled,
  isSWEditable,
  swGuid,
  swVersion,
  imageDataDestination,
  swType,
  steps,
  onDeleteStep,
  onRCDelinkStep,
  onConditionalChange,
  onClientReportChange,
  onStepStringValueChange,
  onAddChildStep,
  onAddNewComponent,
  onAddRoutingOperationComponent,
  isVisibilityExternal,
  stepVisibilities,
  noticeVisibilities,
  setStepVisibility,
  scrollToStepGuid,
  isDraftPage,
  stepsCountCalculate,
  onSearchRCSteps,
  onSearchRcSubSteps,
  rcParentOrder,
  rcType,
  plantCode,
  materialCode,
  group,
  groupCounter,
  sw
}) => {
  const [isInputCompTypeOpen, setIsInputCompTypeOpen] = useState(false);
  const [isContentCompTypeOpen, setIsContentCompTypeOpen] = useState(false);
  const [isComponentSortModalOpen, setIsComponentSortModalOpen] = useState(false);
  const [IsComponentWarningOpen, setIsComponentWarningOpen] = useState(false);
  const titleHolderRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const {
    manageSW: {
      isSearchRCNoticeOpen,
      rcNoticeStep,
      enableFormulaComponentValue,
      enablePhotoInputComponentMFGValue,
      enableMultiListComponentMFGValue,
      enableSingleListComponentMFGValue,
      enableTableComponentMFGValue,
      enableNoticeComponentMFGValue,
      enableTimeImageComponentMFGValue,
      disableMFGInputComponentValues,
      disableMFGContentComponentValues,
      copiedComponents
    },
  } = useSelector(store => store)


  const {
    plantSource
  } = useSelector(store => store.manageSW.SW)

  const {
    guid,
    title,
  } = step;

  useEffect(() => {
    if (scrollToStepGuid === guid) {
      titleHolderRef.current?.scrollIntoView(true);
    }
  }, [scrollToStepGuid, titleHolderRef, guid, title]);

  const draftPage: boolean = swVersion === 0 ? true : false;
  const stepVisibility = isVisibilityExternal
    ? stepVisibilities
      ?.find(x => x.stepGuid === step.guid)
    : undefined;

  const warnOnConditionalChange = (stepGuid: string,
    isConditional: boolean,
    stepsToDeleteOnChange: IStep[]) => {
    if (!onConditionalChange) {
      return;
    }

    if (stepsToDeleteOnChange.length
      && !window.confirm("Making this step not conditional will delete all its child steps. Are you sure you want to continue?")) {
      return;
    }

    onConditionalChange(stepGuid, isConditional);
    if (onDeleteStep) {
      stepsToDeleteOnChange.forEach(st => onDeleteStep(st.guid, false));
    }
  }

  const conditionalControls = getConditionalControls(swType,
    step,
    steps,
    isInTask,
    isDisabled,
    warnOnConditionalChange,
    onStepStringValueChange);

  const addChildStepButton = getAddChildStepButton(swType,
    step,
    isInTask,
    isDisabled,
    onAddChildStep);

  const dispatchOnClientReportChange = (clientReport: boolean) => {
    if (!onClientReportChange) {
      return;
    }

    onClientReportChange(step.guid, clientReport);
  };

  const showClientReport: boolean = swType === SWTypes.TLMSWI && !step.isTask;

  const AddRCNoticeToSw = (rcNoticeSWId: number, rcNoticeSWGuid: string, rcNoticeSWVersion: number, source: string) => {
    dispatch(fetchRCNotice({
      swGuid: swGuid,
      rcID: rcNoticeSWId,
      rcGuid: rcNoticeSWGuid,
      rcVersion: rcNoticeSWVersion,
      stepGuid: step.guid,
      source: source,
      currentNoticeGuid: "",
      isRCComponent: true,
    }));
    dispatch(setIsSearchRCNoticeOpen(false));
    dispatch(clearFilter())
  }

  const onNewComponentTypeChosen = (stepGuid: string, type: StepComponentTypes) => {
    if (type === StepComponentTypes.Image
      && step
        .components
        .filter(x => x.type === StepComponentTypes.Image)
        .length >= 2) {
      dispatch(showErrorToast("A " + (step.isTask ? "task" : "step") + " cannot contain more than two image components."));
      return false;
    }

    if (type === StepComponentTypes.TimeImage
      && step
        .components
        .filter(x => x.type === StepComponentTypes.TimeImage)
        .length >= 2) {
      dispatch(showErrorToast("A " + (step.isTask ? "task" : "step") + " cannot contain more than two time image components."));
      return false;
    }

    if (type === StepComponentTypes.PPE) {
      if (swType !== SWTypes.TLMSWI && swType !== SWTypes.TLMRC && swType !== SWTypes.MFGSWI && swType !== SWTypes.MFGRC) {
        dispatch(showErrorToast("PPE can only be added to a TLMSWI document."));
        return false;
      } else if (step
        .components
        .filter(x => x.type === StepComponentTypes.PPE)
        .length >= 1) {
        dispatch(showErrorToast("A task cannot contain more than one PPE component."));
        return false;
      }
    }

    if (onAddNewComponent) {
      onAddNewComponent(stepGuid, type);
    }

    return true;
  }

  const duplicateSteps = (stepGuid: string) => {
    if (stepsCountCalculate) {
      stepsCountCalculate();
    }
    dispatch(duplicateStep({ stepGuid: stepGuid }));
  }

  const putComment = (stepID?: number) => {
    if (stepID) {
      dispatch(loadStepComments({ stepID: stepID }));
    }
    else {
      dispatch(showErrorToast("No ID Found"));
    }
  }

  const addStepInputComponent = () => {
    if ((swType === SWTypes.CL || swType === SWTypes.LCL || swType === SWTypes.MFGCL)
      && step.components.length === 3) {
      setIsInputCompTypeOpen(false);
      setIsComponentWarningOpen(true);
      return;
    }
    setIsInputCompTypeOpen(true);
  }

  const addStepContentComponent = () => {
    dispatch(setRCNoticeStep(step.guid));
    setIsContentCompTypeOpen(true);
  }

  const disableRcTaskStep = (checked: boolean) => {
    dispatch(disableRcContent({ stepGuid: step.guid, disabled: !checked }));
  }

  const getAllowedRCControls = () => {
    let rcControl: IAllowedRCControlsConfig = {
      createDraft: true,
      whereUsed: true,
      delink: true,
      canDisableStep: true,
    }
    if (swType === SWTypes.CL) {
      rcControl.createDraft = false;
      return rcControl;
    }
    else if (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC || swType === SWTypes.MFGSWI) {
      return rcControl;
    }
    else {
      return;
    }
  }

  const [showMetadata, setShowMetadata] = useState(true);
  const metadataStyle = !isDisabled || showMetadata ? {} : { display: "none" };

  const hasFormulaInput = useMemo(() => {
    return step.components.some(
      (x) => x.type === StepComponentTypes.FormulaNumerical
    );
  }, [step.components]);

  const getDisabledInputComponentsReason = (): string | undefined => {
    if (enableFormulaComponentValue && hasFormulaInput) {
      return "Formula (Numerical) Inputs cannot be combined with other Input Components.";
    }

    if (step.isConditional) {
      return "Components cannot be combined with child steps or conditional steps.";
    }

    if (
      !childStepComponentEnabledTypes.includes(swType) &&
      step.children.length > 0 &&
      !step.isTask
    ) {
      return "Components cannot be combined with child steps or conditional steps.";
    }

    return undefined;
  };

  const hasInputComponents = useMemo(() => {
    return step.components.some((c) => c.type.toString().includes("Input"));
  }, [step.components]);

  const allowAddRoutingOperation = swType === SWTypes.MFGSWI && step.isTask;

  const tree = useSelector(store => store.masterData.operationTree);
  const [showSelectRoutingOperationModal, setShowSelectRoutingOperationModal] = useState(false);

  const addRoutingOperationClicked = () => {
    setShowSelectRoutingOperationModal(true);

    if (!plantCode || !group || !groupCounter || !materialCode) {
      dispatch(showErrorToast("Plant code, group, group counter and material must be set in the Metadata section."));

      return;
    }

    dispatch(loadOperationTree({ plantCode, materialCode, group, groupCounter, plantSource  }));
  }

  const [stepTitle, setStepTitle] = useState(step.title);

  const isMappedToRoutingOperation =
    !!step.components.find((x) => x.type === StepComponentTypes.RoutingOperation);

  useEffect(() => {
    if (stepTitle !== step.title) {
      setStepTitle(step.title);
    }
  }, [step.title]);

  const titleIsDisabled = isDisabled || isMappedToRoutingOperation;

  var disabledInputComponentTypes: IDisabledComponentType[] = [];

  if (!enableFormulaComponentValue) {
    disabledInputComponentTypes.push({
      type: StepComponentTypes.FormulaNumerical,
      reason: "Formula (Numerical) Input is diabled.",
    });
  }

  if (enableFormulaComponentValue && swType !== SWTypes.TLMSWI) {
    disabledInputComponentTypes.push({
      type: StepComponentTypes.FormulaNumerical,
      reason: "Formula (Numerical) Inputs cannot be added to this type of SW.",
    });
  }

  if (enableFormulaComponentValue && step.parentGuid) {
    disabledInputComponentTypes.push({
      type: StepComponentTypes.FormulaNumerical,
      reason: "Formula (Numerical) Inputs cannot be added to Substeps.",
    });
  }

  if (enableFormulaComponentValue && hasInputComponents) {
    disabledInputComponentTypes.push({
      type: StepComponentTypes.FormulaNumerical,
      reason:
        "Formula (Numerical) Inputs cannot be combined with other Input Components.",
    });
    }

    var disabledContentComponentTypes: IDisabledComponentType[] = [];

    if (swType === SWTypes.MFGSWI || swType === SWTypes.MFGCL || swType === SWTypes.MFGRC) {
        //input Component
        if (!enablePhotoInputComponentMFGValue) {
            disabledInputComponentTypes.push({
                type: StepComponentTypes.PhotoInput,
                reason: "PhotoInput is diabled.",
            }); 
        }
        if (!enableMultiListComponentMFGValue) {
            disabledInputComponentTypes.push({
                type: StepComponentTypes.MultiSelectInput,
                reason: "MultiSelectInput is diabled.",
            });
        }
        if (!enableSingleListComponentMFGValue) {
            disabledInputComponentTypes.push({
                type: StepComponentTypes.SelectInput,
                reason: "SelectInput is diabled.",
            });
        }
        //Content Component
        if (!enableTimeImageComponentMFGValue) {
            disabledContentComponentTypes.push({
                type: StepComponentTypes.TimeImage,
                reason: "TimeImage is diabled.",
            });
        }
        if (!enableNoticeComponentMFGValue) {
            disabledContentComponentTypes.push({
                type: StepComponentTypes.Notice,
                reason: "Notice is diabled.",
            });
        }
        if (!enableTableComponentMFGValue) {
            disabledContentComponentTypes.push({
                type: StepComponentTypes.Table,
                reason: "Table is diabled.",
            });
        }
        // Disable input and Content components for MFG 2.2
        if (disableMFGInputComponentValues.length > 0) {
            disabledInputComponentTypes = disableMFGInputComponents(disableMFGInputComponentValues, disabledInputComponentTypes);
        }

        if (disableMFGContentComponentValues.length > 0) {
            disabledContentComponentTypes = disableMFGContentComponents(disableMFGContentComponentValues, disabledContentComponentTypes);
        }
    }
   
  return (
    <>
      <div
        ref={titleHolderRef}
      >
      </div>
      <Collapsible
        header={`${!step.rcContainer ? ((step.stepNumber) ? step.stepNumber + "." : parentOrders.concat(step.sortOrder).join('.')) : ''} ${step.title || (step.isTask ? "New Task" :
          (step.stepType === StepTypes.SubStep ? "New Sub Step" : "New Step"))}`}
        isInitiallyOpen={isDisabled}
        isExternallyControlled={isVisibilityExternal}
        externalIsOpen={stepVisibility?.isOpen}
        allowDuplicate={true}
        stepGuid={step.guid}
        stepId={step.id}
        hasUnresolvedComments={step.hasUnResolvedComments}
        swType={swType}
        rcID={step.rcID}
        isRCStep={step.isRCStep}
        isRCContainer={step.rcContainer}
        isTask={step.isTask}
        stepType={step.stepType}
        externalOnToggle={setStepVisibility
          ? (isOpen: boolean) => setStepVisibility(step.guid, isOpen)
          : undefined
        }
        clickDeleteStep={() => onDeleteStep
          ? step.isRCStep ? (window.confirm(`Deleting the Resuable task will also delete all its dependent steps. Continue?`)) && onDeleteStep(step.guid, step.isRCStep) :
            (window.confirm(`Deleting the ${step.isTask ? "Task" : "Step"} will also delete all child steps and components. Continue?`)
              && onDeleteStep(step.guid, step.isRCStep))
          : undefined}
        clickRCDelinkStep={onRCDelinkStep ? () => onRCDelinkStep(step.rcID) : undefined}
        clickDuplicateSteps={() => duplicateSteps(step.guid)}
        clickPutComment={() => putComment(step.id)}
        draftPage={draftPage}
        imageDataDestination={imageDataDestination}
        isStepDisabled={step.isDisabled}
        disableRcTaskStep={disableRcTaskStep}
        hideRCControls={isDisabled}
        allowedRCControlsConfig={getAllowedRCControls()}
        isStale={step.isStale}
      >
        {!step.rcContainer &&
          <div className="entry-row">
            <label className={`block ${(swType === SWTypes.CL || swType === SWTypes.LCL || swType === SWTypes.MFGCL) ? "" : "mandatory"}`}>
              {(swType === SWTypes.CL || swType === SWTypes.LCL || swType === SWTypes.MFGCL) && !step.isTask
                ? "Challenge"
                : `${(swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC || swType === SWTypes.MFGSWI || swType === SWTypes.MFGRC) && step.isTask === true ? "Task" : "Step"}`
              }
            </label>

            <input
              type="text"
              disabled={titleIsDisabled}
              value={stepTitle}
              onChange={(e) => setStepTitle(e.target.value)}
              onBlur={(e) => {
                if (onStepStringValueChange)
                  onStepStringValueChange(step.guid, "title", e.target.value);
              }}
            />
          </div>}

        {IsComponentWarningOpen
          && <AddSWModal
            onModalClick={() => setIsComponentWarningOpen(false)}
            header="Response Warning"
            message="CL steps should contain a maximum of 3 responses" />
        }
        {isSearchRCNoticeOpen && rcNoticeStep === step.guid &&
          <AddRCNoticeModal
            onModalClick={() => dispatch(setIsSearchRCNoticeOpen(false))}
            stepGuid={step.guid}
            AddRCNoticeToSw={AddRCNoticeToSw}
            swType={swType}
          />
        }
        <div className="entry-row">
          {!step.isTask && swType !== SWTypes.MFGSWI && swType !== SWTypes.MFGCL && swType !== SWTypes.MFGRC &&
            conditionalControls
          }
        </div>
        {!isDisabled && !step.rcContainer &&
          <div className="entry-row centered">
            {allowAddRoutingOperation && (
              <TooltipTrigger
                tooltipContent={
                  <img className="tooltip-image" src={mfgDiagramOperations} />
                }
              >
                <div className="tooltip-trigger-wrapper">
                  <button
                    className="primary-button"
                    title="Add Routing Operation"
                    onClick={addRoutingOperationClicked}
                  >
                    + {isMappedToRoutingOperation ? "Change" : "Add"} Routing
                    Operation
                  </button>
                </div>
              </TooltipTrigger>
            )}
            {(swType !== SWTypes.CL &&
              swType !== SWTypes.LCL) &&
              <button
                className="primary-button"
                disabled={swType !== SWTypes.TLMSWI &&
                  swType !== SWTypes.TLMRC &&
                  swType !== SWTypes.MFGSWI &&
                  swType !== SWTypes.MFGRC &&
                  swType !== SWTypes.MFGCL &&
                  (step.isConditional || (step.children.length > 0 && !step.isTask))}
                title={step.children.length > 0
                  ? "Components cannot be combined with child steps or conditional steps."
                  : undefined}
                onClick={addStepContentComponent}
              >
                + Add Content Component
              </button>
            }
            {(swType !== SWTypes.ECL) &&
              <button
                className="primary-button"
                disabled={!!getDisabledInputComponentsReason()}
                title={getDisabledInputComponentsReason()}
                onClick={addStepInputComponent}
              >
                {mfgSwTypes.includes(swType) ? "+ Add Data Collection Components" : "+ Add Input Component"}
              </button>
            }
            {step.components.length > 0 &&
              <button
                className="secondary-button"
                title={"Reorder components"}
                onClick={() => setIsComponentSortModalOpen(true)}
              >
                <img
                  alt="Sort"
                  title="Sort"
                  className="icon-small sort-icon"
                  src={sortIcon}
                />
                Reorder Components
              </button>
            }
            {addChildStepButton}
            {((swType === SWTypes.TLMSWI || swType === SWTypes.MFGSWI) || ((swType === SWTypes.TLMRC || swType === SWTypes.MFGRC) && rcType === RCTypes.Task)) &&
              onSearchRCSteps &&
              <button
                className="primary-button"
                onClick={() => { onSearchRCSteps(step) }}
              >
                + Add Reusable Step
              </button>
            }
            {((swType === SWTypes.TLMSWI || swType === SWTypes.MFGSWI) || ((swType === SWTypes.TLMRC || swType === SWTypes.MFGRC) && rcType === RCTypes.Task)) &&
              !step.isTask &&
              step.depth === 1 &&
              onSearchRcSubSteps &&
              <button
                className="primary-button"
                onClick={() => { onSearchRcSubSteps(step) }}
              >
                + Add Reusable SubStep
              </button>
            }
            {((swType === SWTypes.TLMSWI || swType === SWTypes.MFGSWI || swType === SWTypes.CL) || ((swType === SWTypes.TLMRC || swType === SWTypes.MFGRC) && rcType === RCTypes.Step)) &&
              !step.isTask &&
              step.depth === 0 &&
              onSearchRcSubSteps &&
              <button
                disabled={(swType === SWTypes.TLMSWI) ? (!step.isConditional || step.components.length > 0) : (false)}
                className="primary-button"
                onClick={() => { onSearchRcSubSteps(step) }}
              >
                + Add Reusable SubStep
              </button>
            }
          </div>
        }
        {enableFormulaComponentValue ? step.components.map((comp) =>
          <StepComponent
            key={comp.guid}
            stepGuid={step.guid}
            component={comp}
            isDisabled={isDisabled}
            swGuid={!step.isRC ? swGuid : step.isRC.swGuid}
            swVersion={!step.isRC ? swVersion : step.isRC.swVersion}
            swType={swType}
            imageDataDestination={imageDataDestination}
            noticeVisibilities={noticeVisibilities}
            isDraftPage={isDraftPage}
            sw={sw}
            stepLocation={getStepLocation(step, steps)}
          />
        ) : step.components.filter(comp => comp.type !== StepComponentTypes.FormulaNumerical).map((comp) =>
          <StepComponent
            key={comp.guid}
            stepGuid={step.guid}
            component={comp}
            isDisabled={isDisabled}
            swGuid={!step.isRC ? swGuid : step.isRC.swGuid}
            swVersion={!step.isRC ? swVersion : step.isRC.swVersion}
            swType={swType}
            imageDataDestination={imageDataDestination}
            noticeVisibilities={noticeVisibilities}
            isDraftPage={isDraftPage}
            sw={sw}
            stepLocation={getStepLocation(step, steps)}
          />
        )}
        {step.children.map((child) =>
          <StepEditor
            key={child.guid}
            step={child}
            parentOrders={!step.rcContainer ? parentOrders.concat(step.sortOrder) : rcParentOrder.concat(step.sortOrder)}
            isInTask={step.depth === 0 ? step.isTask : isInTask}
            isDisabled={isDisabled || child.isRCStep}
            isSWEditable={isSWEditable}
            swGuid={swGuid}
            swVersion={swVersion}
            imageDataDestination={imageDataDestination}
            swType={swType}
            steps={steps}
            onDeleteStep={onDeleteStep}
            onConditionalChange={onConditionalChange}
            onClientReportChange={onClientReportChange}
            onStepStringValueChange={onStepStringValueChange}
            onAddChildStep={onAddChildStep}
            onAddNewComponent={onAddNewComponent}
            isVisibilityExternal={isVisibilityExternal}
            stepVisibilities={stepVisibilities}
            setStepVisibility={setStepVisibility}
            scrollToStepGuid={scrollToStepGuid}
            isDraftPage={isDraftPage}
            stepsCountCalculate={stepsCountCalculate}
            isRCStep={child.isRCStep}
            onRCDelinkStep={onRCDelinkStep}
            rcParentOrder={step.rcContainer ? rcParentOrder.concat(step.sortOrder) : []}
            onSearchRcSubSteps={onSearchRcSubSteps}
            rcType={rcType}
            plantCode={plantCode}
            materialCode={materialCode}
            group={group}
            groupCounter={groupCounter}
            sw={sw}
          />
        )}

        {!step.rcContainer &&
          <div style={metadataStyle}>
            <Collapsible
              header={`${(step.isTask === true ? "Task" : "Step")} Metadata`}
              isInitiallyOpen={isDisabled}
              allowDuplicate={false}
              stepGuid={step.guid}
              isRCStep={step.isRCStep}
              swType={swType}
              rcID={step.rcID}
              isStepDisabled={step.isDisabled}
              disableRcTaskStep={disableRcTaskStep}
            >
              <ManageStepMetadata
                swType={swType}
                step={step}
                isDisabled={isDisabled}
                isSWEditable={isSWEditable}
                templateType={sw?.templateType as TemplateTypes}
                onVisibleMetadataChanged={setShowMetadata}
                showClientReport={showClientReport}
                onClientReportChanged={dispatchOnClientReportChange}
                epicEquipment={sw?.epicEquipment ?? []}
              />
            </Collapsible>
          </div>
        }
      </Collapsible>
      {isInputCompTypeOpen &&
        <InputComponentTypeModal
          swType={swType}
          onCancel={() => setIsInputCompTypeOpen(false)}
          onTypeChosen={(type) => {
            if (onNewComponentTypeChosen(step.guid, type)) {
              setIsInputCompTypeOpen(false);
            }
          }}
          disabledTypes={disabledInputComponentTypes}
        />
      }
      {isContentCompTypeOpen &&
        <ContentComponentTypeModal
          step = {step}
          swType={swType}
          isTask={step.isTask}
          onCancel={() => setIsContentCompTypeOpen(false)}
          onTypeChosen={(type) => {
            if (onNewComponentTypeChosen(step.guid, type)) {
              setIsContentCompTypeOpen(false);
            }
              }}
          disabledTypes={disabledContentComponentTypes}
        />
      }
      {isComponentSortModalOpen &&
        <ReorderModal
          title="Reorder Components"
          items={step.components
            .map((item, index) => ({
              key: index,
              label: item.type === StepComponentTypes.Image
                ? `Image (${(item as IImageComponent).filename})`
                : (item.label?.replaceAll(/(<([^>]+)>)/ig, '') || item.type.toString()),//this regex removes html tags from display text.
              sortOrder: item.sortOrder,
              guid: item.guid,
            }))
          }
          onCancel={() => setIsComponentSortModalOpen(false)}
          onSave={(sortData: ISortableItem[]) => {
            dispatch(updateComponentSortOrders({
              stepGuid: step.guid,
              items: sortData.map((x, ix) => ({
                key: ix,
                guid: x.guid,
                newIndex: ix,
              })),
            }));
            setIsComponentSortModalOpen(false);
          }}
        />
      }
      {showSelectRoutingOperationModal && tree &&
        <SelectRoutingOperationModal
          isOpen={showSelectRoutingOperationModal}
          operationTree={tree}
          onCanceled={() => setShowSelectRoutingOperationModal(false)}
          onOperationClicked={(operation) => {
            if (onAddRoutingOperationComponent) {
              onAddRoutingOperationComponent(step.guid, operation);
            }

            setShowSelectRoutingOperationModal(false);
          }}
        />
      }
    </>
  );
}

export default StepEditor;

function getConditionalControls(swType: SWTypes,
  step: IStep,
  steps: IStep[],
  isInTask: boolean,
  isDisabled: boolean,
  onConditionalChange: (stepGuid: string,
    isConditional: boolean,
    stepsToDeleteOnUncheck: IStep[]) => void,
  onStepStringValueChange?: (stepGuid: string,
    propName: StepStringMetaPropNames,
    value: string) => void): JSX.Element | undefined {
  if (step.depth === 3) {
    return;
  }

  if (step.depth === 2) {
    return;
  }

  //Not showing isConditional for substep in RC
  if ((swType === SWTypes.TLMRC ||
    swType === SWTypes.MFGRC) &&
    step.stepType === StepTypes.SubStep) {
    return;
  }

  //Not showing isConditional checkbox for 2 level step in RC
  if ((step.depth === 1) &&
    (swType === SWTypes.TLMRC && !isInTask)) {
    return;
  }

  if (swType === SWTypes.CL
    || swType === SWTypes.ECL
    || swType === SWTypes.LCL) {
    if (step.depth === 1) {
      return;
    }
  }

  let isConditionalDisabled: boolean = false;
  let disabledText: string = "";
  let shouldWarnBeforeChange = false;

  if (step.depth === 0) {
    // Has any children that are conditional?
    isConditionalDisabled = step.children
      .filter(child => child.isConditional).length > 0;

    if (isConditionalDisabled) {
      disabledText = "One or more child steps are already conditional.";
    }
  } else if (step.depth === 1) {
    // Has any ancestors that are conditional?
    isConditionalDisabled = steps
      .find(s => s.guid === step.parentGuid)?.isConditional
      || false;

    if (isConditionalDisabled) {
      disabledText = "This step's parent is already conditional.";
    }
  } else if (step.depth === 2 && isInTask) {
    // Has any ancestors that are conditional?
    isConditionalDisabled = steps
      .find(s => s.guid === step.parentGuid)?.isConditional
      || false;

    if (isConditionalDisabled) {
      disabledText = "This step's parent is already conditional.";
    }
  }

  isConditionalDisabled = isConditionalDisabled || isDisabled;

  if (isDisabled
    && !step.isConditional) {
    return undefined;
  }

  shouldWarnBeforeChange = step.isConditional
    && step.children.length > 0
    && (
      (
        (swType === SWTypes.CL
          || swType === SWTypes.ECL
          || swType === SWTypes.LCL)
        && step.depth === 0
      )
      ||
      ((swType === SWTypes.SWI || swType === SWTypes.TLMSWI)
        && step.depth === 1)
    );

  return (
    <>
      {!step.rcContainer &&
        <label
          className={isConditionalDisabled ? "disabled disabled-cursor" : ""}
          title={disabledText ? disabledText : undefined}
        >
          Is Conditional?
          <input
            type="checkbox"
            className="conditional-check"
            checked={step.isConditional}
            onChange={(e) => onConditionalChange(step.guid,
              e.target.checked,
              shouldWarnBeforeChange
                ? step.children
                : []
            )}
            disabled={isConditionalDisabled}
          />
        </label>}
    </>
  );
}

function getStepLocation(step: IStep, steps: IStep[]): string | undefined {
  switch (step.depth) {
    case 0:
      if (step.isTask) {
        return step.siteLocation;
      }
      break;
    case 1:
      let parentStep = steps.find(x => x.guid === step.parentGuid);
      if (parentStep?.isTask) {
        return parentStep.siteLocation;
      }
      break;
    case 2:
      for (var i = 0; i < steps.length; i++) {
        let foundStep = steps[i].children.find(x => x.guid === step.parentGuid);
        if (foundStep) {
          let parentStep = steps[i];
          if (parentStep?.isTask) {
            return parentStep.siteLocation;
          }
        }
      }
      break;
    default:
  }
}

function getAddChildStepButton(swType: SWTypes,
  step: IStep,
  isInTask: boolean,
  isDisabled: boolean,
  onAddChildStep?: (parentStep: IStep) => void): JSX.Element | undefined {

  if (step.depth === 3
    || isDisabled) {
    return undefined;
  }

  if (step.depth === 2 && !isInTask) {
    return undefined;
  }

  let showButton = false;
  let isButtonDisabled = false;
  let disabledReason = "";

  if (step.depth === 0
    && (swType === SWTypes.CL
      || swType === SWTypes.ECL
      || swType === SWTypes.LCL)) {
    showButton = true;
    if (!step.isConditional) {
      isButtonDisabled = true;
      disabledReason = `${swType} steps can only have children if the step is conditional.`;
    }
  } else if (swType === SWTypes.SWI || swType === SWTypes.TLMSWI || swType === SWTypes.MFGSWI) {
    if (step.depth === 0) {
      showButton = true;
    } else if (step.depth === 1) {
      showButton = true;

      if (!step.isConditional && swType === SWTypes.SWI) {
        isButtonDisabled = true;
        disabledReason = `${swType} child steps can only have children if the child step is conditional.`;
      }
    }
  }
  // showing childstep button if it is not RC Substep
  else if (swType === SWTypes.TLMRC || swType === SWTypes.MFGRC) {
    if ((step.depth === 0 &&
      step.stepType !== StepTypes.SubStep)
      || (step.depth === 1 && isInTask)) {
      showButton = true;
    }
  }

  if (!showButton) {
    return undefined;
  }

  if (!isButtonDisabled
    && step.components.length
    && !step.isTask) {
    isButtonDisabled = true;
    disabledReason = "Child steps cannot be combined with components.";
  }

  if ((swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC || swType === SWTypes.MFGSWI || swType === SWTypes.MFGRC)
    && disabledReason !== `${swType} child steps can only have children if the child step is conditional.`) {
    isButtonDisabled = false;
    disabledReason = "";
  }

  const button = (
    <button
      className="primary-button"
      disabled={isButtonDisabled}
      title={disabledReason || undefined}
      onClick={() =>
        onAddChildStep && !isButtonDisabled ? onAddChildStep(step) : undefined
      }
    >
      {`+ Add ${step.isTask ? "" : "Sub"}Step`}
    </button>
  );

  if (swType === SWTypes.MFGSWI || swType === SWTypes.MFGRC) {
    return (
      <TooltipTrigger
        tooltipContent={
          step.isTask ? (
            <img className="tooltip-image" src={mfgDiagramStepGroup} />
          ) : (
            <img className="tooltip-image" src={mfgDiagramSteps} />
          )
        }
      >
        <div className="tooltip-trigger-wrapper">{button}</div>
      </TooltipTrigger>
    );
  }

  return button;
}

function containsEnumValue(string: string, enumValue: StepComponentTypes): boolean {
    return string.includes(enumValue);
}

function getStepComponentTypesEnumValueFromString(value: string): StepComponentTypes {
    switch (value) {
        case "NumberInput":
            return StepComponentTypes.NumberInput;
        case "TextInput":
            return StepComponentTypes.TextInput;
        case "RichTextParagraph":
            return StepComponentTypes.RichTextParagraph;
        case "SelectInput":
            return StepComponentTypes.SelectInput;
        case "MultiSelectInput":
            return StepComponentTypes.MultiSelectInput;
        case "SignatureInput":
            return StepComponentTypes.SignatureInput;
        case "DateInput":
            return StepComponentTypes.DateInput;
        case "DateTimeInput":
            return StepComponentTypes.DateTimeInput;
        case "PhotoInput":
            return StepComponentTypes.PhotoInput;
        case "YesNoInput":
            return StepComponentTypes.YesNoInput;
        case "Image":
            return StepComponentTypes.Image;
        case "TimeImage":
            return StepComponentTypes.TimeImage;
        case "Notice":
            return StepComponentTypes.Notice;
        case "MicrosoftStreamVideo":
            return StepComponentTypes.MicrosoftStreamVideo;
        case "Link":
            return StepComponentTypes.Link;
        case "Table":
            return StepComponentTypes.Table;
        case "PPE":
            return StepComponentTypes.PPE;
        case "Video":
            return StepComponentTypes.Video;
        case "QualityControlPoint":
            return StepComponentTypes.QualityControlPoint;
        case "VideoInput":
            return StepComponentTypes.VideoInput;
        case "Audio":
            return StepComponentTypes.Audio;
        case "AudioInput":
            return StepComponentTypes.AudioInput;
        case "RoutingOperation":
            return StepComponentTypes.RoutingOperation;
        case "FormulaNumerical":
            return StepComponentTypes.FormulaNumerical;
        case "PassFailInput":
            return StepComponentTypes.PassFailInput;
        default:
            return StepComponentTypes.PhotoInput;
    }
}
function disableMFGInputComponents(disableMFGInputComponentValues: string[], disabledInputComponentTypes: IDisabledComponentType[]): IDisabledComponentType[] {
    disableMFGInputComponentValues.forEach(component => {
        for (const key in StepComponentTypes) {
            if (containsEnumValue(component, StepComponentTypes[key as keyof typeof StepComponentTypes])) {
                disabledInputComponentTypes.push({
                    type: getStepComponentTypesEnumValueFromString(component.trim()),
                    reason: component + " is diabled.",
                });
                break;
            }
        }
    });
    return disabledInputComponentTypes;
}

function disableMFGContentComponents(disableMFGContentComponentValues: string[], disabledContentComponentTypes: IDisabledComponentType[]) : IDisabledComponentType[] {
    disableMFGContentComponentValues.forEach(component => {
        for (const key in StepComponentTypes) {
            if (containsEnumValue(component, StepComponentTypes[key as keyof typeof StepComponentTypes])) {
                disabledContentComponentTypes.push({
                    type: getStepComponentTypesEnumValueFromString(component.trim()),
                    reason: component + " is diabled.",
                });
                break;
            }
        }
    });
    return disabledContentComponentTypes;
}

