import React, { useEffect, useState } from "react";
import searchIcon from "media/icons/dls/search.svg";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import useDebounce from "utilities/useDebounce";
import { setSearchRCNotice } from "store/manageSW/manageSWActions";
import Modal from "components/common/Modal";
import RCNoticeList from "./RCNoticeList";
import { RCTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import "./AddRCNoticeModal.scoped.scss";
import { addFilterItem, clearFilter, removeFilterItem, setRCType } from "store/swList/swListActions";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import filterIcon from "media/icons/dls/filter.svg";
import FilterModal from "components/common/FilterModal";
import { clearSearchedMasterDataItems } from "store/masterData/masterDataActions";
import { MetaDataNames } from "store/masterData/masterDataTypes";

interface IAddRCNoticeModal {
    onModalClick?(): void,
    stepGuid?: string,
    swType: string,
    AddRCNoticeToSw(rcNoticeSWId: number, rcNoticeSWGuid: string, rcNoticeSWVersion: number, source: string): void,
}

const AddRCNoticeModal: React.FC<IAddRCNoticeModal> = (props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 750);
    const {
        swList: {
            swListData: {
                swList,
                loadOperation,
            },
            filterData,
        },
    } = useSelector(store => store);
    const [isFilterModalOpen, setFilterIsModalOpen] = useState(false);
    const rcNoticeSWs = swList.filter(x => x.type === SWTypes.TLMRC || x.type === SWTypes.MFGRC);
    const dispatch = useDispatch();
    const [filterStatus, setFilterStatus] = useState(false);

    useEffect(() => {
        if (filterStatus || (searchTerm && searchTerm.trim() !== "")) {
            if (props.swType === SWTypes.TLMSWI || props.swType === SWTypes.TLMRC) {
                let item: IMasterDataItem = {
                    guid: "TLMRC",
                    code: "TLMRC",
                    value: "",
                };
                dispatch(
                    addFilterItem({
                        metaDataName: "types",
                        item
                    })
                );
            }

            if (props.swType === SWTypes.MFGSWI || props.swType === SWTypes.MFGRC) {
                let item: IMasterDataItem = {
                    guid: "MFGRC",
                    code: "MFGRC",
                    value: "",
                };
                dispatch(
                    addFilterItem({
                        metaDataName: "types",
                        item
                    })
                );
            }

            dispatch(setSearchRCNotice({
                searchText: debouncedSearchTerm.trim(),
                IsSearchforRCNotice: true,
                rcType: RCTypes.Notice,
            }));
        }
    }, [debouncedSearchTerm, dispatch, filterStatus]);

    const setIsModelOpenOnClick = () => {
        setFilterIsModalOpen(true);
        dispatch(clearSearchedMasterDataItems());

        if (props.swType === SWTypes.TLMSWI || props.swType === SWTypes.TLMRC) {
            let item: IMasterDataItem = {
                guid: "TLMRC",
                code: "TLMRC",
                value: "",
            };
            dispatch(
                addFilterItem({
                    metaDataName: "types",
                    item
                })
            );
        }

        if (props.swType === SWTypes.MFGSWI || props.swType === SWTypes.MFGRC) {
            let item: IMasterDataItem = {
                guid: "MFGRC",
                code: "MFGRC",
                value: "",
            };
            dispatch(
                addFilterItem({
                    metaDataName: "types",
                    item
                })
            );
        }
    }

    const applyDispatchFilter = () => {
        if (checkFilter()) {
            setFilterStatus(true);
            dispatch(setSearchRCNotice({
                searchText: debouncedSearchTerm.trim(),
                IsSearchforRCNotice: true,
                rcType: RCTypes.Notice,
            }));
            dispatch(clearSearchedMasterDataItems());
        }
    }

    const checkFilter = () => {
        if (filterData.filterFields.typesFilter.length !== 0
            || filterData.filterFields.owningOrgsFilter.length !== 0
            || filterData.filterFields.productCentersFilter.length !== 0
            || filterData.filterFields.epicEquipmentFilter.length !== 0
            || filterData.filterFields.hazardCategoriesFilter.length !== 0) {
            return true;
        }
        return false;
    }

    return (
        <Modal
            header="Search Reusable Notice to be added"
            width="70%"
            top="50%"
            controls={(
                <>
                    <button
                        className="primary-button ok-button"
                        onClick={props.onModalClick}
                    >
                        Close
                    </button>
                </>
            )}
            isOpen={true}
        >
            <div className="input-holder">
                <input
                    type="text"
                    className="searchBar"
                    id="sw-search"
                    placeholder={"Begin typing to search..."}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    disabled={loadOperation?.isWorking}
                />
                {(props.swType === SWTypes.TLMSWI || props.swType === SWTypes.TLMRC || props.swType === SWTypes.MFGSWI) &&
                    <img
                        src={filterIcon}
                        onClick={setIsModelOpenOnClick}
                        alt="Filters"
                        className="icon-small filter-button"
                        title="Filters"
                    />
                }
                <img
                    src={searchIcon}
                    alt="Search"
                    title="Search"
                    className="icon-small search-button"
                />
            </div>
            {(filterStatus || (debouncedSearchTerm
                && debouncedSearchTerm.trim() !== ""))
                && rcNoticeSWs
                && rcNoticeSWs.length > 0
                && <RCNoticeList rcNoticeSWs={rcNoticeSWs} AddRCNoticeToSw={props.AddRCNoticeToSw}></RCNoticeList>
            }
            {rcNoticeSWs.length === 0 &&
                <h2>No Reusable Notice found.</h2>}
            {isFilterModalOpen &&
                <FilterModal
                    currentSWFilterValues={filterData.filterFields}
                    onAddItem={(item: IMasterDataItem, name: MetaDataNames) => dispatch(
                        addFilterItem({
                            metaDataName: name,
                            item,
                        })
                    )}
                    onRemoveItem={(item: IMasterDataItem, name: MetaDataNames) => dispatch(
                        removeFilterItem({
                            metaDataName: name,
                            item,
                        })
                    )}
                    applyFilter={applyDispatchFilter}
                    clearFilter={() => (setFilterStatus(false), dispatch(clearFilter()))}
                    setSWStringFilter={(e) => void (e)}
                    setIsModalOpen={setFilterIsModalOpen}
                    showStatusFilter={true}
                    isRCFilters={true}
                    setRCType={(value) => dispatch(setRCType(value))}
                    rcTypeButton={RCTypes.Notice}
                />
            }
        </Modal>
    );
};

export default AddRCNoticeModal;