import FlowLayout from "components/layout/FlowLayout";
import "./NewUserRoleAssignments.scss";
import { Routes } from "components/routing/Routing";
import React from "react";
import { useDispatch } from "react-redux";
import {Prompt, Redirect, RouteComponentProps, useHistory} from "react-router-dom";
import {
  removeRoleAssignment,
  resetEditRoleAssignmentData, 
  saveNewUserRoleAssignments, 
  setIsEditRoleAssignment, 
  setRoleAssignmentEditingData
} from "store/userManagement/userManagementActions";
import useSelector from "store/useSelector";
import DeleteIcon from "media/icons/dls/delete.svg";
import "./UserDetail.scoped.scss";
import {IManageUserDetail, Roles, UserGroups} from "interfaces/user/UserInterfaces";
import UserGroupIcon from "./UserGroupIcon";
import ModalSpinner from "components/common/ModalSpinner";
import * as H from 'history';

const NewUserRoleAssignments: React.FC<RouteComponentProps<{ email: string }>> = (props) => {
  const {
    userManagement: {
      bulkEditUserData: {
        selectedUsers,
        roleAssignments,
        saveOperation,
        isDirty
      }
    }
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const history = useHistory();
  
  if(selectedUsers.length == 0){
    return (<Redirect to={Routes.UserManagement}></Redirect>)
  }
  const onAddUserRoleAssignment = () => {
    dispatch(resetEditRoleAssignmentData());
    dispatch(setIsEditRoleAssignment(false));
    history.push(Routes.EditRoleAssignment);
  }

  const onRoleAssignmentRowClick = (roleAssignment: IManageUserDetail) => {
    dispatch(resetEditRoleAssignmentData());
    dispatch(setIsEditRoleAssignment(true));
    dispatch(setRoleAssignmentEditingData(roleAssignment));
    history.push(Routes.EditRoleAssignment);
  }

  const shouldDisplayPrompt = (location: H.Location, action: H.Action) => {
    if(!location.pathname.includes(Routes.EditRoleAssignment) && isDirty){
      return "You have unsaved changes that will be lost if you leave. Leave anyway?";
    }
    return true;
  };
  
  let roleAssignmentsDetail: JSX.Element | undefined;
  if (selectedUsers
    && roleAssignments
    && roleAssignments.length > 0) {
    roleAssignmentsDetail = (
      <>
        <div className="row header linkbutton">
          <div className="cell roles">
            Roles
          </div>
          <div className="cell">
            Group
          </div>
          <div className="cell">
            RC Editor
          </div>
          <div className="cell">
            Orgs/Plants
          </div>
          <div className="cell">
            Service Types
          </div>
          <div className="cell">
            Equipment
          </div>
          <div className="cell">
            Countries
          </div>
          <div className="cell">
            Epic Equipment
          </div>
          <div className="cell">
            Geo Units
          </div>
          <div className="cell">
            Product Centers
          </div>
          <div>
            Action
          </div>
        </div>
        <div className="flow-body">
          {roleAssignments
            && roleAssignments.map(a => (
              <div className="row hover-gray-bg" key={a.role.concat(a.isRCEditor.toString() + a.isTechUser.toString() + a.isPsdUser.toString() + a.isMfgUser.toString())} onClick={() => onRoleAssignmentRowClick(a)}>
                <div className="cell roles">
                  <span>{a.role}</span>
                </div>
                <div className="cell">
                  {a.isPsdUser &&
                      <span>
                    <UserGroupIcon
                        userGroup={UserGroups.PSD}
                    />
                  </span>
                  }
                  {a.isTechUser &&
                      <span>
                    <UserGroupIcon
                        userGroup={UserGroups.TLM}
                    />
                  </span>
                  }
                  {a.isMfgUser &&
                      <span>
                    <UserGroupIcon
                        userGroup={UserGroups.MFG}
                    />
                  </span>
                  }
                </div> 

                <div className="cell">
                  <span>{a.isRCEditor ? "Yes" : "No"}</span>
                </div>

                <div className="cell">
                  <span>{a.orgs.map(x => x.code).join(", ")}</span>
                </div>

                <div className="cell">
                  <span>{a.serviceType.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell">
                  <span>{a.equipment.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell">
                  <span>{a.country.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell">
                  <span>{a.epicEquipment.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell">
                  <span>{a.geoUnit.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell">
                  <span>{a.productCenter.map(x => x.value).join(", ")}</span>
                </div>

                <div className="cell">
                  {a.role !== Roles.TechConRev && a.role !== Roles.SMEApprover &&
                      <img
                          src={DeleteIcon}
                          alt="Delete"
                          title="Delete"
                          className="icon-small"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(removeRoleAssignment({ role: a.role, isTechUser: a.isTechUser, isPsdUser: a.isPsdUser, isMfgUser: a.isMfgUser, isRCEditor: a.isRCEditor }))
                          }}
                      />
                  }
                </div>
              </div>))}
        </div>
      </>
    )
  }

  let selectedUsersComponent: JSX.Element | undefined;
  selectedUsersComponent = (
    <>
      <div>Selected users:</div> 
      <div className="selected-users-list">
        {selectedUsers.map(a =>
        (
          <div className="selected-user-list-item">
            {a.name} ({a.email})
          </div>
        ))}
      </div>
      </>
  );
  
  return (
    <FlowLayout
      header={(
        <div>
          <div className="toolbar">
            <h2 className="page-title">
              <span>User Role Assignments</span>
            </h2>
            <div
              className="primary-button save-button"
              onClick={() => dispatch(saveNewUserRoleAssignments(
                {users: selectedUsers.map(x => x.email), roleAssignments: roleAssignments ?? [], history: history}))}
            >
              Save Role Assignments
            </div>
          </div>
          {selectedUsersComponent}
          <p className="instructions">Click "Add Role Assignment" to add role assignments to the list. Click 'Save' when completed.</p>
        </div>
      )}
      footer={
      <div className="footer-wrapper">
        <div className="add-role-assignment-button-wrapper">
          <div
            className="primary-button add-role-assignment-button"
            onClick={onAddUserRoleAssignment}
          >
            Add Role Assignment
          </div>
        </div>
      </div>
      }
    >
      {saveOperation?.isWorking && 
          <ModalSpinner />
      }
      {roleAssignmentsDetail}
      <Prompt
        when={isDirty}
        message={shouldDisplayPrompt}
      />
    </FlowLayout>
  );

}

export default NewUserRoleAssignments;