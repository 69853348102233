import BlockSpinner from "components/common/BlockSpinner";
import { UserGroups } from "interfaces/user/UserInterfaces";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadOrganizations } from "store/masterData/masterDataActions";

import {
  getUserDetails,
  resetEditUserData,
} from "store/userManagement/userManagementActions";

import useSelector from "store/useSelector";
import { getUtcDateString } from "utilities/formatDate";
import UserGroupIcon from "./UserGroupIcon";
import "./UserRolesDetail.scoped.scss";

const UserRolesDetail: React.FC<{ userEmail: string }> = ({ userEmail }) => {
  const {
    userManagement: {
      userDetail: { email, userDetails, getUserDetailOperation },
    },
  } = useSelector((store) => store);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDetails({ email: userEmail }));
    dispatch(loadOrganizations({ onlyActive: true }));

    return () => {
      dispatch(resetEditUserData());
    };
  }, [userEmail, dispatch]);

  let component: JSX.Element | undefined;
  if (getUserDetailOperation && getUserDetailOperation?.isWorking) {
    component = <BlockSpinner />;
  } else if (email && userDetails && userDetails.length > 0) {
    component = (
      <div className="">
        <div className="row header linkbutton">
          <div className="cell roles">Role</div>
          <div className="cell roles">Group</div>
          <div className="cell roles">RC Editor</div>          
          <div className="cell orgs">Orgs/Plants</div>
          <div className="cell orgs">Service Types</div>
          <div className="cell orgs">Equipment</div>
          <div className="cell orgs">Countries</div>
          <div className="cell orgs">EPIC Equipment</div>
          <div className="cell orgs">Geo Units</div>
          <div className="cell orgs">Product Centers</div>
          <div className="cell orgs">Users</div>
        </div>
        <div className="flow-body">
          {userDetails.map((a, index) => (
            <div className="user row" key={index}>
              <div className="cell roles">
                <span>{a.role}</span>
              </div>

              <div className="cell roles">
                {a.isPsdUser &&
                  <span>
                    <UserGroupIcon
                      userGroup={UserGroups.PSD}
                    />
                  </span>
                }
                {a.isTechUser &&
                  <span>
                    <UserGroupIcon
                      userGroup={UserGroups.TLM}
                    />
                  </span>
                }
                {a.isMfgUser &&
                  <span>
                    <UserGroupIcon
                      userGroup={UserGroups.MFG}
                    />
                  </span>
                }
              </div>

              <div className="cell roles">
                <span>{a.isRCEditor ? "Yes" : "No"}</span>
              </div>

              <div className="cell orgs">
                <span>{a.orgs.map((x) => x.code).join(", ")}</span>
              </div>

              <div className="cell orgs">
                <span>
                  {a.isTechUser
                    ? ""
                    : a.serviceType.map((x) => x.value).join(", ")}
                </span>
              </div>

              <div className="cell orgs">
                <span>
                  {a.isTechUser
                    ? ""
                    : a.equipment.map((x) => x.value).join(", ")}
                </span>
              </div>

              <div className="cell orgs">
                <span>
                  {a.isTechUser ? "" : a.country.map((x) => x.value).join(", ")}
                </span>
              </div>

              <div className="cell orgs">
                <span>
                  {a.isTechUser
                    ? a.epicEquipment.map((x) => x.value).join(", ")
                    : ""}
                </span>
              </div>

              <div className="cell orgs">
                <span>
                  {a.isTechUser ? a.geoUnit.map((x) => x.value).join(", ") : ""}
                </span>
              </div>

              <div className="cell orgs">
                <span>
                  {a.isTechUser
                    ? a.productCenter.map((x) => x.value).join(", ")
                    : ""}
                </span>
              </div>

              <div className="cell orgs">
                <ul>
                  {a.proxiedUsers.map((pu) => (
                    <li key={pu.userEmail}>
                      {pu.userEmail} (until {getUtcDateString(pu.validUntil)})
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    component = <h4>No Roles Assigned to the user</h4>;
  }

  return component;
};

export default UserRolesDetail;
