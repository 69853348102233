import { createAction } from "@reduxjs/toolkit";
import { IOperation } from "interfaces/operations/Operations";
import { IProxyApprover } from "./proxyApproversTypes";

export const setProxyApprover = createAction<IProxyApprover | undefined>(
  "proxyApprovers/setProxyApprover"
);

export const submitProxyApprover = createAction<IProxyApprover>(
  "proxyApprovers/submitProxyApprover"
);

export const submitDeleteProxyApprover = createAction(
  "proxyApprovers/submitDeleteProxyApprover"
);

export const loadProxyApprover = createAction(
  "proxyApprovers/loadProxyApprover"
);

export const setProxyOperation = createAction<IOperation>(
  "proxyApprovers/setProxyOperationIsWorking"
);
