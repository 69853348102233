import { Action } from "@reduxjs/toolkit";
import FeedbackApi from "apis/feedback/FeedbackApi";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "store/toast/toastActions";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import {
  resetFeedback,
  saveFeedback,
  setCreatedSuccesfull,
  setErrorProccess,
  setInitializeProccess,
} from "./feedbackActions";

export default function* watchFeedbackSagas() {
  yield all([watchSaveFeedback()]);
}

function* saveFeedbackAsync(action: Action) {
  if (!saveFeedback.match(action)) {
    return;
  }
  try {
    yield put(setInitializeProccess());
    yield call(FeedbackApi.createFeedback, action.payload);
    yield put(showSuccessToast(`Feedback created successfully`));
    yield put(setCreatedSuccesfull());
    yield put(resetFeedback());
  } catch (err: any) {
    yield put(
      showErrorToast(
        `Failed to add ${action.payload.title}. ${getResponseErrorMessage(err)}`
      )
    );
    yield put(setErrorProccess(err.message));
    return false;
  }
  return true;
}

function* watchSaveFeedback() {
  yield takeLatest(saveFeedback, saveFeedbackAsync);
}
