import { IVideoInputComponent } from "interfaces/sw/SWInterfaces";
import React, { useRef } from "react";
import "./VideoInput.scoped.scss";

interface IVideoInputProps {
  component: IVideoInputComponent,
  isDisabled: boolean,
}

const VideoInput: React.FC<IVideoInputProps> = ({ component, isDisabled }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  let vid: JSX.Element | undefined;

  return (
    <>
      <div>
        <div>{component.label}</div>
        {vid}
        <input
          type="file"
          accept="video/mp4,video/avi,video/quicktime"
          ref={fileInputRef}
          disabled={isDisabled}
        />
        <div
          className="controls"
        >
          <button
            className="primary-button choose-video"
            onClick={() => fileInputRef.current?.click()}
            disabled={isDisabled}
          >
            {"Choose Video"}
          </button>
        </div>
      </div>
    </>
  );
}

export default VideoInput;