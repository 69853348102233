import FlowLayout from "components/layout/FlowLayout";
import React, { useState } from "react";
import "./Execution.scoped.scss";
import ExecutionJobHeader from "./ExecutionJobHeader";
import ExecutionSWList from "./ExecutionSWList";
import JobRefDocsList from "./JobRefDocsList";

export enum ExecutionViews {
  Steps = "Steps",
  RefDocs = "RefDocs",
}

const Execution: React.FC = () => {
  const [currView, setCurrView] = useState<ExecutionViews>(ExecutionViews.Steps);

  let mainComponent: JSX.Element | undefined;

  if (currView === ExecutionViews.Steps) {
    mainComponent = <ExecutionSWList />;
  } else if (currView === ExecutionViews.RefDocs) {
    mainComponent = (
      <JobRefDocsList />
    );
  }

  return (
    <>
      <FlowLayout
        header={
          <ExecutionJobHeader
            currentView={currView}
            setCurrView={(view: ExecutionViews) => setCurrView(view)}
          />
        }
      >
        {mainComponent}
      </FlowLayout>
    </>
  );
}

export default Execution;