import React, { useEffect, useState } from "react";
import "./MetadataRow.scoped.scss";
import {
  ServiceLevel, Usage, ISiteLocation,
} from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { loadQTracOOS } from "store/masterData/masterDataActions";
import MasterDataInput from "../../attributes/MasterDataInput";
import { getAllSiteLocations } from "store/manageSW/manageSWActions";

export enum MetadataRowTypes {
  YesNo,
  Text,
  MultiLineText,
  ServiceLevel,
  OOS,
  Usage,
  SiteLocation
}

interface IMetadataRowProps {
  label: string,
  value: string | string[] | undefined,
  type: MetadataRowTypes,
  onChange(value: string, item?: IMasterDataItem, isAdding?: boolean): void,
  isDisabled: boolean,
  items?: IMasterDataItem[],
}
const serviceLevels = (Object.keys(ServiceLevel) as (keyof typeof ServiceLevel)[]).map(
  (key) => {
    return ServiceLevel[key];
  },
);

const usage = (Object.keys(Usage) as (keyof typeof Usage)[]).map(
  (key) => {
    return Usage[key];
  },
);

function serviceLevelToString(serviceLevel: ServiceLevel): string {
  return serviceLevel.toString();
}

const MetadataRow: React.FC<IMetadataRowProps> = ({ label, value, type, onChange, isDisabled, items }) => {
  const dispatch = useDispatch();
  const {
    masterData: {
      qTracOOS
    },
  } = useSelector(store => store);

  const {
    siteLocations
  } = useSelector((store: any) => store.manageSW);

  const [selectedOOS, setSelectedOOS] = useState<IMasterDataItem[]>([]);

  useEffect(() => {
    if (type === MetadataRowTypes.OOS) {
      dispatch(loadQTracOOS({ onlyActive: true }));
    }
    if (type === MetadataRowTypes.SiteLocation) {
      dispatch(getAllSiteLocations())
    }
  }, []);

  useEffect(() => {
    let oos: IMasterDataItem[] | undefined = items;

    if (oos !== undefined) {
      let selectedItems: IMasterDataItem[] = [];

      for (let item of oos) {
        var code = item.code ?? -1;
        (qTracOOS.items.filter(x => {
          if (x.code === code)
            selectedItems.push({
              guid: x.guid,
              code: x.code,
              value: x.value,
              active: true,
            })
        }));
      }

      setSelectedOOS(selectedItems);
    }
  }, [qTracOOS, items]);

  return (
    <div className="entry-row">
      <label>
        {label}
      </label>
      {type === MetadataRowTypes.YesNo &&
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={isDisabled}
        >
          <option value={"true"}>Yes</option>
          <option value={"false"}>No</option>
        </select>
      }
      {type === MetadataRowTypes.Text &&
        <input
          type="text"
          defaultValue={value}
          onBlur={(e) => onChange(e.target.value)}
          disabled={isDisabled}
        />
      }
      {type === MetadataRowTypes.MultiLineText &&
        <textarea
          defaultValue={value}
          onBlur={(e) => onChange(e.target.value)}
          disabled={isDisabled}
        />
      }
      {type === MetadataRowTypes.ServiceLevel &&
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={isDisabled}
        >
          {
            serviceLevels.map((servicelevel, index) =>
            (<option
              key={servicelevel}
              value={serviceLevelToString(servicelevel)}>
              {(index === 16) ? " " : index}
            </option>))
          }
        </select>
      }
      {type === MetadataRowTypes.OOS &&
        <MasterDataInput
          label={""}
          disabled={isDisabled}
          allowMultiple={true}
          masterDataSection={qTracOOS}
          itemFormatter={(item: IMasterDataItem) => item.value}
          selectedItems={selectedOOS}
          onAddItem={(item: IMasterDataItem) => onChange(item.guid, item, true)}
          onRemoveItem={item => onChange(item.guid, item, false)}
          loadItems={() => dispatch(loadQTracOOS({ onlyActive: true }))}
          hideLabel={true}
        />
      }
      {type === MetadataRowTypes.Usage &&
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={isDisabled}
        >
          {
            usage.map((usage) =>
            (<option
              key={usage}
              value={usage.toString()}>
              {usage.toString()}
            </option>))
          }
        </select>
      }
      {type === MetadataRowTypes.SiteLocation &&
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={isDisabled}
        >
          {
            siteLocations.map((siteLocation: ISiteLocation) =>
            (<option
              key={siteLocation.id}
              value={siteLocation.id}>
              {siteLocation.code}
            </option>))
          }
        </select>
      }
    </div>
  );
}

export default MetadataRow;