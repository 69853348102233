import "./ManageRevisionHistory.scoped.scss";
import pdfIcon from "media/icons/dls/pdf.svg";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadSWRevisionHistories } from "store/manageSW/manageSWActions";
import useSelector from "store/useSelector";
import { ISWRevisionHistory, IUserApprovalResponseHistory, SWTypes } from "interfaces/sw/SWInterfaces";
import formatDate from "utilities/formatDate";
import { getPDF } from "store/swList/swListActions";

interface IManageSWRevHistoryProps {
    swGuid: string,
    swType: SWTypes,
}

const ManageRevisionHistory: React.FC<IManageSWRevHistoryProps> = ({ swGuid, swType }) => {
    const {
        manageSW: {
            revisionHistories
        },
    } = useSelector(store => store);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadSWRevisionHistories({ swGuid }));
    }, [dispatch, swGuid]);

    const PdfClick = (id: number) => {
        dispatch(getPDF({
            swId: id,
            generateDefault: true
        }))
    }

    return (
        <div>
            <div className="row header">
                <div className="cell version">
                    Version
                </div>
                <div className="cell publishdate">
                    Publish Date
                </div>
                <div className="cell description" >
                    Description
                </div>
                {(swType === SWTypes.MFGSWI || swType === SWTypes.MFGRC) &&
                    <div className="cell author" >
                        Approver(s)
                    </div>
                }
                {(swType !== SWTypes.MFGSWI && swType !== SWTypes.MFGRC) &&
                    <div className="cell author" >
                        Author
                    </div>
                }
                <div className="cell buttons" >
                    Download PDF
                </div>
            </div>
            <div className="list-body">
                {
                    revisionHistories?.map((revisionHistoryItem: ISWRevisionHistory, ix: number) => (
                        <div className="row-value row" key={ix.toString()}>
                            <div className="cell version">
                                {revisionHistoryItem.version}
                            </div>
                            <div className="cell publishdate">
                                {revisionHistoryItem.publishDate ? formatDate(revisionHistoryItem.publishDate) : "Draft"}
                            </div>
                            <div className="cell description">
                                {revisionHistoryItem.description}
                            </div>
                            <div className="cell author">
                                {revisionHistoryItem.approvalResponses.map((approvalResponse: IUserApprovalResponseHistory) => (
                                    <span>
                                        {(swType === SWTypes.MFGSWI || swType === SWTypes.MFGCL || swType === SWTypes.MFGRC) &&
                                          (
                                            ((approvalResponse.level !== "") &&
                                                (approvalResponse.userName !== null) &&
                                                <><b>{approvalResponse.level}: </b>{approvalResponse.userName}<br /></>) ||
                                            ((approvalResponse.level !== "") &&
                                                (approvalResponse.userName == null) &&
                                                <><b>{approvalResponse.level}: </b>{approvalResponse.userEmail}<br /></>) ||
                                            ((approvalResponse.level === "") &&
                                                (approvalResponse.userName != null) &&
                                                <>{approvalResponse.userName}<br /></>) ||
                                            ((approvalResponse.level === "") &&
                                                (approvalResponse.userName == null) &&
                                                <>{approvalResponse.userEmail}<br /></>)
                                          )
                                        }
                                        {(swType !== SWTypes.MFGSWI && swType !== SWTypes.MFGCL && swType !== SWTypes.MFGRC) &&
                                            <>{approvalResponse.userEmail}</>
                                        }
                                    </span>
                                ))}
                            </div>
                            <div className="cell buttons">
                                <img src={pdfIcon} className="icon-medium hover-image" alt="Download PDF" onClick={() => PdfClick(revisionHistoryItem.id)} />
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>);
}

export default ManageRevisionHistory;