import React, { useState } from "react";
import "./StepComponent.scoped.scss";

import {
  IStepComponent,
  StepComponentTypes,
  INoticeComponent,
  ITextInputComponent,
  INumberInputComponent,
  IDateInputComponent,
  IDateTimeInputComponent,
  IPhotoInputComponent,
  ISignatureInputComponent,
  IYesNoInputComponent,
  IImageComponent,
  ISelectInputComponent,
  IMultiSelectInputComponent,
  SWTypes,
  IMicrosoftStreamVideoComponent,
  ILinkComponent,
  ITableComponent,
  IPPEComponent,
  IVideoComponent,
  IRichTextInputComponent,
  IQualityControlPointComponent,
  IVideoInputComponent,
  IAudioComponent,
  IAudioInputComponent,
  ITIMEImageComponent,
  IRoutingOperationComponent,
  IFormulaInputComponent,
  ISW,
  IPassFailInputComponent, 
  StepComponentType
} from "interfaces/sw/SWInterfaces";
import NoticeEditor from "./NoticeEditor";
import TextInputEditor from "./TextInputEditor";
import NumberInputEditor from "./NumberInputEditor";
import DeleteIcon from "media/icons/dls/delete.svg";
import CopyIcon from "media/icons/custom/copy-document.svg";
import DownloadIcon from "media/icons/dls/download.svg";
import ExpandIcon from "media/icons/dls/arrow-right-3.svg";
import CollapseIcon from "media/icons/dls/arrow-down-3.svg";
import { useDispatch } from "react-redux";
import {
  copyComponent,
  removeComponent,
  setCreateRCNoticePopup
} from "store/manageSW/manageSWActions";
import DateInputEditor from "./DateInputEditor";
import PhotoInputEditor from "./PhotoInputEditor";
import SignatureInputEditor from "./SignatureInputEditor";
import YesNoInputEditor from "./YesNoInputEditor";
import ImageEditor from "./ImageEditor";
import SelectInputEditor from "./SelectInputEditor";
import { ImageDataDestinations, INoticeVisibility } from "store/manageSW/manageSWTypes";
import MicrosoftStreamVideoEditor from "./MicrosoftStreamVideoEditor";
import LinkEditor from "./LinkEditor";
import TableEditor from "./TableEditor";
import PPEComponentEditor from "./PPEComponentEditor";
import VideoEditor from "./VideoEditor";
import AudioEditor from "./AudioEditor";
import RichTextEditor from "./RichTextEditor";
import QualityControlPointEditor from "./QualityControlPointEditor";
import VideoInputEditor from "./VideoInputEditor";
import AudioInputEditor from "./AudioInputEditor";
import TIMEImageEditor from "./TIMEImageEditor";
import {showErrorToast, showInfoToast} from "store/toast/toastActions";
import createRCIcon from "media/icons/custom/createRC.svg";
import RoutingOperationViewer from "./RoutingOperationViewer";
import FormulaInputEditor, { INumberInputComponentWithStep } from "./FormulaInputEditor";
import PassFailInputEditor from "./PassFailInputEditor";
import DateTimeInputEditor from "./DateTimeInputEditor";
import useSelector from "store/useSelector";
import Banner, { BannerType } from "components/common/Banner";

interface IStepComponentProps {
  stepGuid?: string,
  component: IStepComponent,
  showWrapper?: boolean,
  isDisabled: boolean,
  swGuid: string,
  swVersion: number,
  swType: SWTypes,
  imageDataDestination: ImageDataDestinations,
  hideRCNoticeControls?: boolean,
  noticeVisibilities?: INoticeVisibility[],
  isDraftPage?: boolean,
  isVisibilityExternal?: boolean,
  isDirty?: boolean,
  sw?: ISW,
  stepLocation?: string,
}

const collapsibleComponentTypes = [
  StepComponentTypes.Table,
];

const StepComponent: React.FC<IStepComponentProps> = ({ component,
  stepGuid,
  showWrapper = true,
  isDisabled = false,
  swGuid,
  swVersion,
  swType,
  imageDataDestination,
  hideRCNoticeControls,
  noticeVisibilities,
  isDraftPage,
  isVisibilityExternal,
  isDirty,
  sw,
  stepLocation
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    if (collapsibleComponentTypes.includes(component.type)) {
      setIsCollapsed(!isCollapsed);
    } else {
      setIsCollapsed(false);
    }
  };

  const dispatch = useDispatch();
  const {
    manageSW: {
      enableFormulaComponentValue
    },
  } = useSelector(store => store)

  const labelText = (swType === SWTypes.CL || swType === SWTypes.LCL)
    ? "Response"
    : "Label";
  let renderComponent: JSX.Element | null = null;

  let validInputTypes: Map<string, StepComponentTypes[]> = new Map();
  validInputTypes.set("", [
    StepComponentTypes.YesNoInput,
    StepComponentTypes.PhotoInput,
    StepComponentTypes.TextInput,
    StepComponentTypes.SelectInput,
    StepComponentTypes.MultiSelectInput,
    StepComponentTypes.DateInput,
    StepComponentTypes.DateTimeInput,
    StepComponentTypes.SignatureInput,
    StepComponentTypes.VideoInput,
    StepComponentTypes.AudioInput,
    StepComponentTypes.FormulaNumerical,
    StepComponentTypes.PassFailInput
  ]);

  function validateStepComponentsForFormula(stepComponents: IStepComponent[]): boolean {

    if (stepComponents.filter((c) => c.type === StepComponentTypes.NumberInput).length !== 1) {
      return false;
    }

    if (stepComponents.filter((c) => findOtherInputs(c.type)).length > 0) {
      return false;
    }

    return true;
  }

  const findOtherInputs = (key: StepComponentTypes) => {
    const support = validInputTypes.get("");

    const ix = support
      ?.indexOf(key);

    return ix !== undefined && ix > -1;
  }

  if (!stepGuid) {
    if (component.type === StepComponentTypes.Notice) {
      renderComponent = (
        <NoticeEditor
          allowEdit={!isDisabled}
          notice={component as INoticeComponent}
          stepGuid={stepGuid}
          swGuid={swGuid}
          swVersion={swVersion}
          swType={swType}
          imageDataDestination={imageDataDestination}
          hideRCNoticeControls={hideRCNoticeControls}
          noticeVisibilities={noticeVisibilities}
          isDraftPage={isDraftPage}
          isVisibilityExternal={isVisibilityExternal}
          sw={sw}
        />
      );
    } else {
      return null;
    }
  } else {
    switch (component.type) {
      case StepComponentTypes.Notice: {
        renderComponent = (
          <NoticeEditor
            allowEdit={!isDisabled}
            notice={component as INoticeComponent}
            stepGuid={stepGuid}
            swGuid={swGuid}
            swVersion={swVersion}
            swType={swType}
            imageDataDestination={imageDataDestination}
            hideRCNoticeControls={hideRCNoticeControls}
            noticeVisibilities={noticeVisibilities}
            isDraftPage={isDraftPage}
            isVisibilityExternal={isVisibilityExternal}
            sw={sw}
          />
        );
        break;
      }
      case StepComponentTypes.TextInput: {
        renderComponent = (
          <TextInputEditor
            allowEdit={!isDisabled}
            textInput={component as ITextInputComponent}
            stepGuid={stepGuid}
            labelText={labelText}
            swType={swType}
            stepLocation={stepLocation}
          />
        );
        break;
      }
      case StepComponentTypes.RichTextParagraph: {
        renderComponent = (
          <RichTextEditor
            allowEdit={!isDisabled}
            textComponent={component as IRichTextInputComponent}
            stepGuid={stepGuid}
          />
        );
        break;
      }
      case StepComponentTypes.NumberInput: {
        renderComponent = (
          <NumberInputEditor
            allowEdit={!isDisabled}
            numberInput={component as INumberInputComponent}
            stepGuid={stepGuid}
            labelText={labelText}
            swType={swType}
            stepLocation={stepLocation}
          />
        );
        break;
      }
      case StepComponentTypes.DateInput:
        renderComponent = (
          <DateInputEditor
            allowEdit={!isDisabled}
            dateInput={
              component as IDateInputComponent
            }
            stepGuid={stepGuid}
            labelText={labelText}
            swType={swType}
            stepLocation={stepLocation}
          />
        );
        break;
      case StepComponentTypes.DateTimeInput: {
        renderComponent = (
          <DateTimeInputEditor
            allowEdit={!isDisabled}
            dateInput={
              component as IDateTimeInputComponent
            }
            stepGuid={stepGuid}
            labelText={labelText}
          />
        );
        break;
      }
      case StepComponentTypes.PhotoInput: {
        renderComponent = (
          <PhotoInputEditor
            allowEdit={!isDisabled}
            photoInput={component as IPhotoInputComponent}
            stepGuid={stepGuid}
            labelText={labelText}
          />
        );
        break;
      }
      case StepComponentTypes.SignatureInput: {
        renderComponent = (
          <SignatureInputEditor
            allowEdit={!isDisabled}
            signatureInput={component as ISignatureInputComponent}
            stepGuid={stepGuid}
            labelText={labelText}
          />
        );
        break;
      }
      case StepComponentTypes.YesNoInput: {
        renderComponent = (
          <YesNoInputEditor
            allowEdit={!isDisabled}
            yesNoInput={component as IYesNoInputComponent}
            stepGuid={stepGuid}
            labelText={labelText}
            swType={swType}
          />
        );
        break;
      }

      case StepComponentTypes.PassFailInput: {
        renderComponent = (
          <PassFailInputEditor
            allowEdit={!isDisabled}
            passFailInput={component as IPassFailInputComponent}
            stepGuid={stepGuid}
            componentTypeLabel={labelText}
            swType={swType}
            stepLocation={stepLocation}
          />
        );
        break;
      }
      case StepComponentTypes.Image: {
        renderComponent = (
          <ImageEditor
            allowEdit={!isDisabled}
            image={component as IImageComponent}
            stepGuid={stepGuid}
            swGuid={swGuid}
            swVersion={swVersion}
            imageDataDestination={imageDataDestination}
          />
        );
        break;
      }
      case StepComponentTypes.TimeImage: {
        renderComponent = (
          <TIMEImageEditor
            allowEdit={!isDisabled}
            timeImage={component as ITIMEImageComponent}
            stepGuid={stepGuid}
            swGuid={swGuid}
            swVersion={swVersion}
            imageDataDestination={imageDataDestination}
          />
        );
        break;
      }
      case StepComponentTypes.MultiSelectInput:
      case StepComponentTypes.SelectInput: {
        renderComponent = (
          <SelectInputEditor
            allowEdit={!isDisabled}
            selectInput={
              component as ISelectInputComponent | IMultiSelectInputComponent
            }
            stepGuid={stepGuid}
            labelText={labelText}
            swType={swType}
            stepLocation={stepLocation}
          />
        );
        break;
      }
      case StepComponentTypes.MicrosoftStreamVideo: {
        renderComponent = (
          <MicrosoftStreamVideoEditor
            allowEdit={!isDisabled}
            msVideo={component as IMicrosoftStreamVideoComponent}
            stepGuid={stepGuid}
          />
        );
        break;
      }
      case StepComponentTypes.Link: {
        renderComponent = (
          <LinkEditor
            allowEdit={!isDisabled}
            linkComponent={component as ILinkComponent}
            stepGuid={stepGuid}
          />
        );
        break;
      }
      case StepComponentTypes.Table: {
        renderComponent = (
          <TableEditor
            allowEdit={!isDisabled}
            tableComponent={component as ITableComponent}
            stepGuid={stepGuid}
            swGuid={swGuid}
            swVersion={swVersion}
            imageDataDestination={imageDataDestination}
            swType={swType}
          />
        );
        break;
      }
      case StepComponentTypes.PPE: {
        renderComponent = (
          <PPEComponentEditor
            allowEdit={!isDisabled}
            ppeComponent={component as IPPEComponent}
            stepGuid={stepGuid}
          />
        );
        break;
      }
      case StepComponentTypes.Video: {
        renderComponent = (
          <VideoEditor
            allowEdit={!isDisabled}
            video={component as IVideoComponent}
            swGuid={swGuid}
            swVersion={swVersion}
            stepGuid={stepGuid}
            imageDataDestination={imageDataDestination}
          />
        );
        break;
      }
      case StepComponentTypes.Audio: {
        renderComponent = (
          <AudioEditor
            allowEdit={!isDisabled}
            audio={component as IAudioComponent}
            swGuid={swGuid}
            swVersion={swVersion}
            stepGuid={stepGuid}
            imageDataDestination={imageDataDestination}
          />
        );
        break;
      }
      case StepComponentTypes.QualityControlPoint: {
        renderComponent = (
          <QualityControlPointEditor
            allowEdit={!isDisabled}
            qualityControlPoint={component as IQualityControlPointComponent}
            swGuid={swGuid}
            swVersion={swVersion}
            stepGuid={stepGuid}
            labelText={labelText}
          />
        );
        break;
      }
      case StepComponentTypes.VideoInput: {
        renderComponent = (
          <VideoInputEditor
            allowEdit={!isDisabled}
            videoInput={component as IVideoInputComponent}
            stepGuid={stepGuid}
            labelText={labelText}
          />
        );
        break;
      }
      case StepComponentTypes.AudioInput: {
        renderComponent = (
          <AudioInputEditor
            allowEdit={!isDisabled}
            audioInput={component as IAudioInputComponent}
            stepGuid={stepGuid}
            labelText={labelText}
          />
        );
        break;
      }
      case StepComponentTypes.RoutingOperation: {
        const operationComponent = component as IRoutingOperationComponent;

        renderComponent = sw ? (
          <RoutingOperationViewer component={operationComponent} sw={sw} />
        ) : null;

        break;
      }
      case StepComponentTypes.FormulaNumerical: {
        const formulaComponent = component as IFormulaInputComponent;
        let availableNumberInputs: INumberInputComponentWithStep[] = [];

        if (sw) {
          const indexOfThisStep =
            sw.steps.findIndex((s) => s.guid === stepGuid) + 1;

          availableNumberInputs = sw.steps
            .map((s, i) => ({ step: s, stepIndex: i + 1 }))
            .filter((s) => s.stepIndex < indexOfThisStep)
            .filter(
              (s) =>
                validateStepComponentsForFormula(s.step.components)
            )
            .map((s) => ({
              ...s,
              component: s.step.components.find((x) => x.type === StepComponentTypes.NumberInput) as INumberInputComponent,
            }))
            ;
        }

        renderComponent = (
          <FormulaInputEditor
            formulaComponent={formulaComponent}
            allowEdit={!isDisabled}
            stepGuid={stepGuid}
            labelText={labelText}
            swType={swType}
            availableNumberInputs={availableNumberInputs}
            epicEquipment={sw?.epicEquipment ?? []}
            stepLocation={stepLocation}
          />
        );

        break;
      }
    }
  }

  const downloadImage = async () => {
    let imageComp = component as IImageComponent;
    const image = await fetch(imageComp.blobData?.blobUrl!);
    const imageBlob = await image.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(imageBlob);
    link.download = imageComp.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const dispatchCreateRCNoticeClick = () => {
    if (isDirty) {
      dispatch(showErrorToast('Please save the SW content before creating RC notice.'));
    }
    else {
      const notice = component as INoticeComponent;
      dispatch(setCreateRCNoticePopup(
        {
          rcNoticePopupOpen: true,
          notice,
        }));
    }
  }

  const copyButton = (
    <img
      src={CopyIcon}
      className="icon-small tool hover-gray-bg"
      alt="Copy"
      title="Copy"
      onClick={() => {
        dispatch(copyComponent(component as StepComponentType))
        dispatch(showInfoToast("Component copied!"));
      }}
    />
  );

  const deleteButton = (
    <img
      src={DeleteIcon}
      className="icon-small tool hover-gray-bg"
      alt="Delete"
      title="Delete"
      onClick={() => dispatch(removeComponent({
        stepGuid,
        componentGuid: component.guid,
      }))}
    />
  );

  const downloadButton = (
    component.type === StepComponentTypes.Image && (component as IImageComponent).filename.length > 0 &&
    <img
      src={DownloadIcon}
      className="icon-small tool hover-gray-bg"
      alt="Download"
      title="Download"
      onClick={() => downloadImage()}
    />
  );

  const createRCNoticeButton = (swType === SWTypes.TLMSWI &&
    component.type === StepComponentTypes.Notice &&
    <img
      src={createRCIcon}
      alt={`Create RC Notice`}
      title={`Create RC Notice`}
      className="rc-button tool icon-small"
      onClick={dispatchCreateRCNoticeClick}
    />
  );

  let isRCNotice = false;
  if (component.type === StepComponentTypes.Notice) {
    const notice = component as INoticeComponent;
    isRCNotice = notice.isRCComponent;
  }

  const componentTools  = (
    <>
      {!isDisabled && !isRCNotice &&
          <div className="component-tools">
            {downloadButton}
            {createRCNoticeButton}
            {deleteButton}
          </div>
      }
    </>
  );

  if (!showWrapper) {
    return (
      <div className="component-editor">
        {componentTools}
        {renderComponent}
      </div>
    );
  }

  var displayFormulaComponent = true;

  if (!enableFormulaComponentValue) {
    displayFormulaComponent = component.type != StepComponentTypes.FormulaNumerical;
  }

  const legend = StepComponentTypes[component.type]
    .toString()
    .replace("Input", " Input")
    .replace("YesNo", "Yes/No")
    .replace("Paragraph", " Paragraph")
    .replace("QualityControlPoint", "Quality Control Point");

  const isCollapsedClass = isCollapsed ? "collapsed" : "";

  return (
    <>
      {displayFormulaComponent &&
        <fieldset className={component.type === "Image" ? "image-editor" : "component-editor"}>
          <legend onClick={toggleCollapse}>{legend}</legend>
          {componentTools}
          {collapsibleComponentTypes.includes(component.type) &&
            <img
              src={isCollapsed ? ExpandIcon : CollapseIcon}
              className="icon-small collapse-button hover-gray-bg"
              alt={isCollapsed ? "Expand" : "Collapse"}
              title={isCollapsed ? "Expand Component" : "Collapse Component"}
              onClick={toggleCollapse}
            />
          }
          {
            isCollapsed &&
            <Banner type={BannerType.Info}>
              <p onClick={toggleCollapse}>
                {legend} is collapsed. Click <b>here</b> to expand.
              </p>
            </Banner>
          }
          <div className={isCollapsedClass}>
            {renderComponent}
          </div>
        </fieldset>
      }
    </>
  );
}

export default StepComponent;