import { IStep } from "interfaces/sw/SWInterfaces";

export default function doesStepHaveMetadata(step: IStep) {
  return step.critCompAnsNeedsImprov
    || step.critCompAnsSufficient
    || step.critCompText
    || step.isComprehensionRequired
    || step.isCritComp
    || step.isCritStep
    || step.OOS;
}