import React, { useEffect, useRef } from "react";
import "./Step.scoped.scss";
import { IStep } from "interfaces/sw/SWInterfaces";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { setCurrentExecutionPreviewStep } from "store/manageSW/manageSWActions";
import StepBody from "./StepBody";

interface IStepProps {
  step: IStep,
  focusSWHeader?(): void,
}

const Step: React.FC<IStepProps> = ({
  step,
  focusSWHeader,
}) => {
  const {
    currentLocation,
    SW: {
      steps,
    }
  } = useSelector(store => store.manageSW);
  const dispatch = useDispatch();
  const onStepClick = () => {
    dispatch(setCurrentExecutionPreviewStep({
      stepGuid: step.guid,
    }));
  }
  const headerRef = useRef<HTMLLIElement>(null);
  const currStepGuid = currentLocation?.stepGuid;
  const isCurrStep = currStepGuid === step.guid;
  const titleClassName = "step-title " + (isCurrStep ? "current" : "");
  const isFirstStep = currStepGuid === steps[0].guid;

  useEffect(() => {
    if (isFirstStep
      && focusSWHeader) {
      focusSWHeader();
    } else if (headerRef.current
      && isCurrStep) {
      headerRef.current.scrollIntoView();
    }
  }, [isCurrStep, isFirstStep, focusSWHeader]);

  let subSteps = null;

  if (step.children.length) {
    subSteps = (
      <ul className="">
        {step.children.map(subStep =>
          <Step
            step={subStep}
            key={subStep.id}
          />
        )}
      </ul>
    );
  }

  return (
    <li
      ref={headerRef}
    >
      <span
        onClick={onStepClick}
        className={titleClassName}>
        <span
          className="text"
        >
          {step.stepNumber}{!step.rcContainer ? "." : undefined}{step.isMemoryStep ? "*" : undefined} {step.title}
        </span>
      </span>
      <StepBody
        step={step}
      />
      {subSteps}
    </li>
  );
}

export default Step;