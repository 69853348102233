import Banner, { BannerType } from "components/common/Banner";
import { IPassFailInputComponent } from "interfaces/sw/SWInterfaces";
import React, { useState } from "react";
import ToggleButtonInput from "../controls/ToggleButtonInput";

interface IPassFailInputProps {
  component: IPassFailInputComponent,
  isDisabled: boolean,
}

const PassFailInput: React.FC<IPassFailInputProps> = ({ component, isDisabled }) => {
  const [value, setValue] = useState("");
  const [isExpectedValue, setIsExpectedValue] = useState(true);

  const onValueChange = (value: string) => {
    setValue(value);
    setIsExpectedValue(true);
    if (isDisabled) {
      return;
    }

    if (expectedValue !== undefined) {
      if (value !== expectedValue) {
        setIsExpectedValue(false);
      }
    }
  }

  let nonConform: string = component.nonConform || "";
  let expectedValue: string | undefined = component.expectedValue;
  let shouldShowConformInfo = expectedValue !== undefined;
  let existingResponseNonConform = false;

  if (value && (value !== "" && value !== undefined)) {
    let selectedValue = value;

    if ((expectedValue !== undefined
      && selectedValue !== expectedValue)) {
      existingResponseNonConform = true;
    }
  }

  return (
    <div>
      {shouldShowConformInfo &&
        <Banner
          type={BannerType.Info}
        >
          <div className="conformInfo">
            <label>Expected Value:&nbsp;</label>
            <span>{expectedValue}</span>
          </div>
        </Banner>
      }
      {shouldShowConformInfo
        && (!isExpectedValue
          || existingResponseNonConform) &&
        <Banner
          type={BannerType.Warn}
        >
          {nonConform}
        </Banner>
      }
      <label>{component.label}</label>
      <ToggleButtonInput
        options={["Pass", "Fail"]}
        onAnswerChosen={value => onValueChange(value)}
        disabled={isDisabled}
        currentValue={value}
      />
    </div>
  );
}

export default PassFailInput;