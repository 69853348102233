import { authPostJson, authGetJson, authPostFormData } from "utilities/msalFetches";
import config from "config";
import { throwIfResponseError } from "apis/apiUtilities";
import {
  formatGetMyApprovalsResponse,
  formatGetApprovalResponse,
  formatApprovalResponseSubmission,
  formatApprovalLevel,
  formatGetApprovalHistoryResponse,
  formatSearchApprovalsRequest,
  formatGetBatchApprovalResponse,
  formatBatchApprovalResponseSubmission,
  formatSwRetractSubmission,
  formatGetBatchUpdateSWMetaDataResponse,
  formatGetRCUpdateApprovalResponse,
} from "./approvalFormatters";
import {
  IApprovalRequest,
  IApprovalResponseSubmission,
  ApprovalLevels,
  IHistoricalApprovalResponse,
  IMyApprovals,
  IApprovalHistoryData,
  ApprovalSortFields,
} from "interfaces/approvals/approvalInterfaces";
import { ISW, SWTypes } from "interfaces/sw/SWInterfaces";

import {
  IApprovalFilterFields,
  IRCUpdateApproval,
  ISubmitRCUpdateApprovalRequest,
} from "store/approvals/approvalsTypes";

import { ISWFilterFields } from "store/swList/swListTypes";
import {
  IBatchUpdateApproval,
  IBatchUpdateSWMetaData,
} from "interfaces/batchUpdates/batchUpdatesInterfaces";
import { SortDirection } from "interfaces/misc/miscInterfaces";

class ApprovalApi {
  public async submitDraftForApproval(
    swGuid: string,
    swType: SWTypes
  ): Promise<[number, ApprovalLevels, string]> {
    let response = await authPostJson(
      config.endpoints.sw.submitDraftForApproval
        .replace("{guid}", swGuid)
        .replace("{type}", swType.toString()),
      JSON.stringify({})
    );

    await throwIfResponseError(response);

    let json = await response.json();

    return [
      parseInt(json.ApprovalRequestId, 10),
      formatApprovalLevel(json.ApprovalLevel),
      json.ActiveApprovalUserName,
    ];
  }

  public async getMyApprovals(
    swFilterFields: ISWFilterFields,
    approvalFilterFields: IApprovalFilterFields,
    sortBy: ApprovalSortFields,
    sortDir: SortDirection
  ): Promise<IMyApprovals> {
    const response = await authPostJson(
      config.endpoints.approvals.getMyApprovals,
      JSON.stringify(
        formatSearchApprovalsRequest(
          swFilterFields,
          approvalFilterFields,
          sortBy,
          sortDir
        )
      )
    );

    await throwIfResponseError(response);

    return formatGetMyApprovalsResponse(await response.json());
  }

  public async retractSwFromApproval(
    swId: number,
    approvalRequestId: number
  ): Promise<boolean> {
    let response = await authPostFormData(
      config.endpoints.sw.retractSwFromApproval.replace(
        "{swid}",
        swId.toString()
      ),
      formatSwRetractSubmission(swId, approvalRequestId)
    );

    await throwIfResponseError(response);

    return true;
  }

  public async getApproval(
    approvalId: number,
    stepIndex: number | undefined
  ): Promise<[IApprovalRequest, ISW, IHistoricalApprovalResponse[], number, number]> {
    let response = await authPostJson(
      config.endpoints.approvals.getApproval.replace(
        "{id}",
        approvalId.toString()
      ),
      JSON.stringify({
        ApprovalId: approvalId.toString(),
        StepIndex: stepIndex === undefined ? 0 : stepIndex,
      })
    );

    await throwIfResponseError(response);

    return formatGetApprovalResponse(await response.json());
  }

  public async getBatchApproval(
    approvalId: number
  ): Promise<IBatchUpdateApproval> {
    let response = await authGetJson(
      config.endpoints.batchUpdates.getBatchApproval.replace(
        "{id}",
        approvalId.toString()
      )
    );

    await throwIfResponseError(response);

    return formatGetBatchApprovalResponse(await response.json(), approvalId);
  }

  public async getRCUpdateApproval(
    approvalId: number
  ): Promise<IRCUpdateApproval> {
    let response = await authGetJson(
      config.endpoints.approvals.getRCUpdateApproval.replace(
        "{id}",
        approvalId.toString()
      )
    );

    await throwIfResponseError(response);

    return formatGetRCUpdateApprovalResponse(await response.json());
  }

  public async submitRCUpdateApproval(
    submitRCUpdateApprovalRequest: ISubmitRCUpdateApprovalRequest
  ): Promise<void> {
    let response = await authPostJson(
      config.endpoints.approvals.submitRCUpdateApproval,
      JSON.stringify(submitRCUpdateApprovalRequest)
    );

    await throwIfResponseError(response);
  }

  public async getBatchUpdateSWMetaData(
    approvalId: number,
    swId: number
  ): Promise<IBatchUpdateSWMetaData> {
    let response = await authGetJson(
      config.endpoints.batchUpdates.getBatchUpdateSWMetaData
        .replace("{id}", approvalId.toString())
        .replace("{swid}", swId.toString())
    );

    await throwIfResponseError(response);

    return formatGetBatchUpdateSWMetaDataResponse(await response.json());
  }

  public async submitResponse(
    approvalResponse: IApprovalResponseSubmission
  ): Promise<boolean> {
    let response = await authPostFormData(
      config.endpoints.approvals.submitApprovalResponse.replace(
        "{id}",
        approvalResponse.approvalRequestId.toString()
      ),
      formatApprovalResponseSubmission(approvalResponse)
    );

    await throwIfResponseError(response);

    return true;
  }

  public async submitBatchResponse(
    approvalResponse: IApprovalResponseSubmission
  ): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.batchUpdates.submitApprovalResponse.replace(
        "{id}",
        approvalResponse.approvalRequestId.toString()
      ),
      JSON.stringify(formatBatchApprovalResponseSubmission(approvalResponse))
    );

    await throwIfResponseError(response);

    return true;
  }

  public async getApprovalHistory(swId: number): Promise<IApprovalHistoryData> {
    let response = await authGetJson(
      config.endpoints.approvals.getApprovalHistory.replace(
        "{id}",
        swId.toString()
      )
    );

    await throwIfResponseError(response);

    return formatGetApprovalHistoryResponse(await response.json());
  }

  public async getApprovalAttachmentUrl(responseId: number): Promise<string> {
    let response = await authGetJson(
      config.endpoints.approvals.getApprovalAttachmentUrl.replace(
        "{responseId}",
        responseId.toString()
      )
    );

    await throwIfResponseError(response);

    return (await response.json()).AbsoluteUri;
  }
}

export default new ApprovalApi();
