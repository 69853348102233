import React from "react";
import { ExecutionViews } from "./Execution";
import "./ExecutionJobHeader.scoped.scss";
import ExecutionToolbar from "./ExecutionToolbar";

interface IJobHeaderProps {
  currentView: ExecutionViews,
  setCurrView(view: ExecutionViews): void,
}

const JobHeader: React.FC<IJobHeaderProps> = ({ currentView, setCurrView }) => {
  return (
    <>
      <div className="controls">
        <div className="buttons">
          <ExecutionToolbar
            currentView={currentView}
            onViewButtonClick={(view: ExecutionViews) => setCurrView(view)}
          />
        </div>
      </div>
    </>
  );
}

export default JobHeader;