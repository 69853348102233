import { BatchUpdateErrorTypes, IBatchUpdateCommand, IBatchUpdateError, IBatchUpdateResponse } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import { ISWSummaryItem } from "interfaces/sw/SWInterfaces";

export function formatSubmitBatchUpdatesRequest(sws: ISWSummaryItem[],
  commands: IBatchUpdateCommand[]): any {
  return {
    StandardWorks: sws.map(x => ({
      Id: x.id,
        Guid: x.guid,
        Type: x.type,
    })),
    Commands: commands.map(x => ({
      MasterDataId: x.masterDataItem.guid,
      MasterDataType: x.metaType,
      Command: x.command,
      SortOrder: x.sortOrder,
    })),
  };
}

export function formatSubmitBatchUpdatesRequestResponse(responseStatus: number,
  response: any): IBatchUpdateResponse {
  return {
    success: responseStatus !== 400,
    approvalRequestId: response.ApprovalRequestId,
    errors: response.Errors
      ? response.Errors.map((err: any): IBatchUpdateError => ({
        swId: err.SWId,
        errorType: err.ErrorType as BatchUpdateErrorTypes,
        message: err.Message || undefined,
      }))
      : [],
  };
}