import Modal from "components/common/Modal";
import { DataClassificationTypes, IStep, SWTypes } from "interfaces/sw/SWInterfaces";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { saveSWStepCommentApproval } from "store/approvals/approvalsActions";
import { saveSWStepComment, setStepComments } from "store/manageSW/manageSWActions";
import useSelector from "store/useSelector";
import './StepCommentList.scoped.scss';
import SWStepComment from "./SWStepComment";
interface IStepCommentListProps {
  isDraftPage: boolean
}

const StepCommentList: React.FC<IStepCommentListProps> = ({
  isDraftPage
}) => {
  const {
    manageSW: {
      stepCommentData: {
        stepID,
        comments,
        loadOperation,
      },
      SW: {
        type,
        steps,
        tcc,
        dataClassification,
      }
    },
    approvals: {
      currentApproval
    }
  } = useSelector(store => store);

  const endOfCommentsRef = useRef<HTMLDivElement>(null);
  let stepsData: IStep[] = [];
  let swType;
  let tccVal;
  let dataClasVal;;

  if (currentApproval) {
    stepsData = currentApproval?.sw.steps || [];
    swType = currentApproval?.sw.type;
    tccVal = currentApproval?.sw.tcc;
    dataClasVal = currentApproval?.sw.dataClassification;
  }
  else {
    stepsData = steps;
    swType = type;
    tccVal = tcc;
    dataClasVal = dataClassification;
  }

  const [commentsText, setCommentsText] = useState('');
  useEffect(() => {
    endOfCommentsRef.current?.scrollIntoView();
  });

  const dispatch = useDispatch();

  const saveComment = (stepID: number) => {
    if (isDraftPage) {
      dispatch(saveSWStepComment({ stepID: stepID, comment: commentsText }));
      dispatch(setStepComments({
        comments: [],
        stepID: undefined,
      }));
      setCommentsText('');
    } else {
      dispatch(saveSWStepCommentApproval({ stepID: stepID, comment: commentsText }));
      dispatch(setStepComments({
        comments: [],
        stepID: undefined,
      }));
      setCommentsText('');
    }
  }

  if (!comments) {
    return null;
  }

  if (loadOperation?.wasSuccessful) {
    return (
      <Modal
        header={`${(swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC || swType === SWTypes.MFGSWI) &&
          stepsData.some(x => x.id === stepID &&
            x.isTask === true) ? "Task" :
          stepsData.filter(a => a.rcContainer).some(({ children }) => children.some(x => x.id === stepID &&
            x.isTask === true)) ? "Task" :
            "Step"} Comments`}
        isOpen={true}
        width={"10em"}
        maxHeight={"40em"}
        controls={(
          <>
            <div>
              <textarea
                value={commentsText}
                onChange={event => setCommentsText(event.target.value)}
              ></textarea>
            </div>
            <button
              className="primary-button comment-button"
              onClick={() => saveComment(stepID ? stepID : 0)}
            >
              Save
            </button>
            <button
              className="secondary-button"
              onClick={() => dispatch(setStepComments({
                comments: [],
              }))}
            >
              Close
            </button>
          </>
        )}
      >
        {(dataClasVal === DataClassificationTypes.Confidential &&
          tccVal &&
          (swType === SWTypes.MFGCL || swType === SWTypes.MFGSWI || swType === SWTypes.MFGRC)) &&
          <span style={{ color: "rgb(177, 0, 0)", fontSize: "small" }}>
            TCC sensitive content is about to be shared with the user.
          </span>
        }
        {comments
          .map(entry => (
            <SWStepComment
              key={entry.id}
              comment={entry}
              isDraftPage={isDraftPage}
            />)
          )}
        <div ref={endOfCommentsRef}></div>
      </Modal>
    );
  }
  return null;
}


export default StepCommentList;