import React from "react";
import { IDateInputComponent, IDateTimeInputComponent, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { updateDateInput, updateDateTimeInput } from "store/manageSW/manageSWActions";
import "./ComponentEditor.scss";

interface IDateTimeInputEditorProps {
  dateInput: IDateTimeInputComponent,
  allowEdit: boolean,
  stepGuid: string,
  labelText: string,
}

const DateTimeInputEditor: React.FC<IDateTimeInputEditorProps> = ({
  dateInput,
  allowEdit,
  stepGuid,
  labelText,
}) => {
  const dispatch = useDispatch();

  const onLabelBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      dispatch(updateDateTimeInput({
        stepGuid,
        component: {
          ...dateInput,
          label: event.target.value,
        },
      }));
  };

  return (
    <div className="component-editor">
      <label className="mandatory block">{labelText}</label>
      {allowEdit &&
        <input
          type="text"
          defaultValue={dateInput.label}
          onBlur={onLabelBlur}
        />
      }
      {!allowEdit &&
        <span>{dateInput.label}</span>
      }
    </div>
  );
}

export default DateTimeInputEditor;