import React from "react";
import BlockSpinner from "./BlockSpinner";
import "./ModalSpinner.scoped.scss";

const ModalSpinner: React.FC = () =>
  <div className="spinner-area">
    <div className="fade"></div>
    <div className="spinner-holder">
      <BlockSpinner />
    </div>
  </div>;

export default ModalSpinner;