import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setTimeImageData } from "store/manageSW/manageSWActions";
import AddTimeImageModal from "./steps/AddTimeImageModal";
import { ITimeImageData } from "store/manageSW/manageSWTypes";
import timeImageIcon from "media/icons/custom/timeimage.svg";
import { SWTypes } from "interfaces/sw/SWInterfaces";

interface ITableTimeImageComponentProps {
    allowEdit?: boolean
    uploadDownloadTimeImage(data: ITimeImageData): void,
    timeImageData: ITimeImageData,
    swType: SWTypes,
}

const TableTimeImageComponent: React.FC<ITableTimeImageComponentProps> = (props) => {

    const dispatch = useDispatch();
    const [openTimeModal, setOpenTimeModal] = useState(false);
    const { allowEdit, timeImageData, uploadDownloadTimeImage, swType } = props;

    const openCloseModal = () => {
        setOpenTimeModal(prevState => !prevState);
        dispatch(setTimeImageData({
            imageNumber: 0,
            link: "",
            label: "",
        }));
    }

    if (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC) {
        return (
            <>
                {openTimeModal &&
                    <AddTimeImageModal
                        onModalClick={openCloseModal}
                        timeImageData={timeImageData}
                        uploadDownloadImage={uploadDownloadTimeImage}
                    />
                }
    
                {allowEdit &&
                    <>
                        <div
                            className="rdw-option-wrapper"
                            onClick={openCloseModal}
                        >
                            <img
                                src={timeImageIcon}
                                title="Time Image"
                                className="icon-small"
                                alt="Time Image"
                            />
                        </div>
                    </>
                }
            </>
        );
    } else {
        return null;
    }

}

export default TableTimeImageComponent;