import { IRefDocTableComponent, ITableComponentCell } from "interfaces/sw/SWInterfaces";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getRefDocTableCellImageUrls, updateRefDocTableCell, uploadTableImage } from "store/manageSW/manageSWActions";
import "./RefDocTableCell.scoped.scss";
import CustomMergeOption from "../CustomMergeOption";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, RichUtils } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { getFileSizeError, isFilenameImage } from "utilities/fileUtilities";
import { showErrorToast } from "store/toast/toastActions";
import { ImageDataDestinations, ITableImageFile } from "store/manageSW/manageSWTypes";

interface IRefDocTableCellProps {
  rowKey: number,
  colKey: number,
  refDocGuid: string,
  tableComponent: IRefDocTableComponent,
  disabled: boolean,
  swGuid: string,
  swVersion: number,
  imageDataDestination: ImageDataDestinations,
}

const RefDocTableCellCell: React.FC<IRefDocTableCellProps> = ({
  rowKey,
  colKey,
  refDocGuid,
  tableComponent,
  disabled,
  swGuid,
  swVersion,
  imageDataDestination,
}) => {
  const dispatch = useDispatch();

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  let existingCell = tableComponent.cells.find(x => x.rowIndex === rowKey && x.colIndex === colKey);

  useEffect(() => {
    if (existingCell?.value) {
      //if cell value has image tag hit azure to get signed url
      let cellImage = getImage(existingCell);

      if (cellImage) {
        dispatch(getRefDocTableCellImageUrls({
          refDocGuid: refDocGuid,
          rowIndex: rowKey,
          colIndex: colKey,
          swGuid,
          swVersion,
          filename: cellImage,
          destination: imageDataDestination,
        }));
      }

      const contentBlock = htmlToDraft(existingCell.value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [existingCell, colKey, dispatch, rowKey, swGuid, swVersion, imageDataDestination, refDocGuid]);

  const handleEditorChange = (state: EditorState) => {  
    let contentAsHTML = draftToHtml(convertToRaw(state.getCurrentContent()));  
    if (contentAsHTML && contentAsHTML.indexOf('<img') > -1) {
      if (contentAsHTML.split("<img").length > 2) {
        const alreadyImageErr = "There is already an image.";
        dispatch(showErrorToast(alreadyImageErr));
        return;
      }
    }

    // if there is a new link, updates table cell data. This is needed because Draft Editor's onblur event does not fire when a link is added
    if (contentAsHTML && contentAsHTML.indexOf('</a>') > -1) {
      let count = contentAsHTML.split("</a>").length;
      let previousContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      let oldCount = previousContent.split("</a>").length;
      if(count > oldCount)
        updateTableCellData(contentAsHTML);
    }

    setEditorState(state);
  }

  const updateTableCellData = (contentAsHTML: string) => {
    dispatch(dispatch(updateRefDocTableCell({
      refDocGuid: refDocGuid,
      cell: {
        rowIndex: rowKey,
        colIndex: colKey,
        value: contentAsHTML,
        rowSpan: existingCell ? existingCell.rowSpan : 1,
        colSpan: existingCell ? existingCell.colSpan : 1,
        imageFileName: existingCell ? existingCell.imageFileName : undefined,
      }
    })));
  }

  const getImage = (cell: ITableComponentCell): string | undefined => {
    if (cell.imageFileName && cell.imageFileName.includes('blob.core.windows.net/authoring')) {
      return undefined;
    }
    
    const imgRex = /<img.*?alt="(.*?)"[^>]+>/g;
      let img;
      while ((img = imgRex.exec(cell.value))) {
        let val = img[1];
        // dont add it if its local image still
        if (val !== "undefined") {
          return img[1];
        }
      }
    return undefined;
  }

  const handleEditorBlur = () => {
    let contentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    updateTableCellData(contentAsHTML);
  }

  const uploadImageCallback = (file: File) => {
    return new Promise(
      (resolve, reject) => {
        if (!isFilenameImage(file.name)) {
          const validFileErr = "Please select a valid image.";
          dispatch(showErrorToast(validFileErr));
          reject(validFileErr);
        }
    
        const fileSizeError = getFileSizeError(file);
        if (fileSizeError) {
          dispatch(showErrorToast(fileSizeError));
          reject(fileSizeError);
        }

        if (existingCell && existingCell.value.indexOf('<img') > -1) {
          const alreadyImageErr = "There is already an image.";
          dispatch(showErrorToast(alreadyImageErr));
          reject(alreadyImageErr);
        }
        
        const imageObject: ITableImageFile = {
          file,
          localName: URL.createObjectURL(file),
        }

        dispatch(uploadTableImage({
          swGuid,
          swVersion: 0,
          imageObject: imageObject,
          componentGuid: refDocGuid,
          stepGuid: undefined,
          rowIndex: rowKey,
          colIndex: colKey,
        }));

        resolve({ data: { link: imageObject.localName } });
      }
    );
  }

  const onMergeClick = (value: string) => {
    let contentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let pathArray = value.split('|');
    let rSpan: number = 1;
    let cSpan: number = 1;

    if (pathArray.length === 3 && pathArray[0] === "Merge") {
      let direction = pathArray[1];
      let amount = parseInt(pathArray[2], 10);
      
      // figure out rowspan and colspan here
      if (existingCell) {
        rSpan = existingCell.rowSpan;
        cSpan = existingCell.colSpan;
      }

      if (direction === "Down") {
        rSpan = amount;
      } else {
        cSpan = amount;
      }
    } 

    dispatch(dispatch(updateRefDocTableCell({
      refDocGuid: refDocGuid,
      cell: {
        rowIndex: rowKey,
        colIndex: colKey,
        value: contentAsHTML,
        rowSpan: rSpan,
        colSpan: cSpan,
        imageFileName: existingCell ? existingCell.imageFileName : undefined,
      }
    })));
  }

  return (
    <>
      <Editor
          handlePastedText={() => false}
          stripPastedStyles={true}
          toolbarOnFocus
          toolbarClassName="rte-toolbar-class-table"
          editorClassName="rte-editor-class-table"
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          onBlur={handleEditorBlur}
          readOnly={disabled}
          placeholder="Enter some text..."
          toolbarCustomButtons={[
            <CustomMergeOption 
              onChange={handleEditorChange} 
              editorState={editorState} 
              onMergeClick={onMergeClick} 
              colIndex={colKey} 
              rowIndex={rowKey}
              tableColLen={tableComponent.colCount}
              tableRowLen={tableComponent.rowCount}
            />]}
          toolbar={{
            options: ['inline', 'list', 'image', 'link'],
            inline: {
              inDropdown: true,
              options: ['bold', 'italic', 'underline'],
            },
            list: {
              inDropdown: true,
            },
            image: {
              alignmentEnabled: false,
              previewImage: true,
              uploadEnabled: true,
              urlEnabled: false,
              uploadCallback: uploadImageCallback,
              inputAccept: 'image/gif, image/jpeg, image/jpg, image/png, image/svg+xml',
              defaultSize: {
                height: '160px',
                width: '208px',
              }
            }
          }}
          handleKeyCommand={(command) => {
            const newState = RichUtils.handleKeyCommand(editorState, command)
    
            if (newState) {
              handleEditorChange(newState)
              return "handled"
            }
    
            return "not-handled"
          }}
      />
    </>
  );
}

export default RefDocTableCellCell;