import React from "react";
import { useDispatch } from "react-redux";
import Modal from "components/common/Modal";
import { cancelDeleteDraft, confirmDeleteDraft } from "store/swList/swListActions";
import { useMsal } from "@azure/msal-react";
import formatDate from "utilities/formatDate";

interface IDeleteDraftModal {
  swGuid: string,
  currentEditor: string | undefined,
  currentEditorLockedDate: Date | undefined,
}

const DeleteDraftModal: React.FC<IDeleteDraftModal> = (props) => {
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const user = accounts[0];

  const isLoggedInUserNotSWCurrentEditor = (currentEditor: string) =>{
    if (user 
      && currentEditor !== null
      && user.username !== currentEditor) {
        return true;
    }  
    return false;  
  }

  return (
    <Modal
      header="Delete Standard Work Draft"
      controls={(
        <>
          <button
            className="primary-button"
            onClick={() => dispatch(confirmDeleteDraft({
              swGuid: props.swGuid,
              currentEditor: props.currentEditor,
              currentEditorLockedDate: props.currentEditorLockedDate,
            }))}
          >
            Delete
          </button>
          <button
            className="secondary-button"
            onClick={() => dispatch(cancelDeleteDraft())}
          >
            Cancel
          </button>
        </>
      )}
      isOpen={true}
    >
      <p>
        {props.currentEditor 
        &&  isLoggedInUserNotSWCurrentEditor (props.currentEditor)
        ? `${props.currentEditor} has locked this SW as of ${formatDate(props.currentEditorLockedDate)}. `: "" } 
        Are you sure you want to delete this draft?
        This will delete the data permanently from the system.
      </p>
    </Modal>
  );
};

export default DeleteDraftModal;