export interface IToastState {
  toasts: IToast[],
}

export interface IToast {
  id: string,
  message: string,
  style: ToastStyle
}

export enum ToastStyle {
  Info = "info",
  Success = "success",
  Error = "error",
  Warning = "warning",
}