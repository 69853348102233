import { all } from 'redux-saga/effects';
import watchMasterDataSagas from './masterData/masterDataSagas';
import watchUserManagementSagas from './userManagement/userManagementSagas';
import watchToastSagas from './toast/toastSagas';
import watchManageSWSagas from './manageSW/manageSWSagas';
import watchSWListSagas from './swList/swListSagas';
import watchApprovalSagas from './approvals/approvalsSagas';
import watchAuthSagas from './auth/authSagas';
import watchBatchUpdatesSagas from './batchUpdates/batchUpdatesSagas';
import watchProxyApproverSagas from './proxyApprovers/proxyApproversSagas';
import watchSwReportsSagas from './ManageSwReports/ManageSwReportsSagas';
import watchFeedbackSagas from './feedback/feedbackSagas';

export default function* rootSaga() {
  yield all([
    watchToastSagas(),
    watchUserManagementSagas(),
    watchManageSWSagas(),
    watchMasterDataSagas(),
    watchSWListSagas(),
    watchApprovalSagas(),
    watchAuthSagas(),
    watchBatchUpdatesSagas(),
    watchProxyApproverSagas(),
    watchSwReportsSagas(),
    watchFeedbackSagas()
  ]);
};