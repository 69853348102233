import React from "react";
import { IToast, ToastStyle } from "store/toast/toastTypes";
import "./ToastMessage.scoped.scss";
import Banner, { BannerType } from "components/common/Banner";
import { useDispatch } from "react-redux";
import { removeToast } from "store/toast/toastActions";

const ToastMessage: React.FC<IToast> = ({ id, message, style }) => {
  const dispatch = useDispatch();

  let bannerType: BannerType = BannerType.Info;

  switch (style) {
    case ToastStyle.Info: {
      bannerType = BannerType.Info;
      break;
    }
    case ToastStyle.Error: {
      bannerType = BannerType.Error;
      break;
    }
    case ToastStyle.Success: {
      bannerType = BannerType.Success;
      break;
    }
    case ToastStyle.Warning: {
      bannerType = BannerType.Warn;
      break;
    }
  }

  return (
    <div
      className="toast"
      onClick={() => dispatch(removeToast({ id }))}
    >
      <Banner
        type={bannerType}
      >
        {message}
      </Banner>
    </div>
  );
};

export default ToastMessage;