import React from "react";
import Banner, { BannerType } from "./Banner";

const Error403: React.FC = () =>
  <Banner
    type={BannerType.Error}
  >
    You do not have the necessary permissions to view this area.
  </Banner>;

export default Error403;