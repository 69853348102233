import { IBatchUpdateCommand } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import React from "react";
import { useDispatch } from "react-redux";
import { removeCommand } from "store/batchUpdates/batchUpdatesActions";
import "./CommandItem.scoped.scss";

interface ICommandItemProps {
  command: IBatchUpdateCommand,
  showRemove: boolean,
}

const CommandItem: React.FC<ICommandItemProps> = ({
  command,
  showRemove,
}) => {
  const dispatch = useDispatch();

  return (
    <li
      className="command-item"
    >
      <span
        className="row-data"
      >
        <span>
          {command.command} {command.metaType} <strong>{command.masterDataItem.value}</strong>
        </span>
        {showRemove &&
          <button
            className="secondary-button"
            onClick={() => dispatch(removeCommand({
              commandSortOrder: command.sortOrder,
            }))}
          >
            X
          </button>
        }
      </span>
    </li>
  );
};

export default CommandItem;