import React, { useEffect } from "react";
import "./AppStartup.scoped.scss";
import BlockSpinner from "components/common/BlockSpinner";
import { useDispatch } from "react-redux";
import { loadCurrentUserInfo, setLoadOperation, setCurrentUser } from "store/auth/authActions";
import { useMsal } from "@azure/msal-react";
import useSelector from "store/useSelector";
import Logo from "./Logo";
import StartupError from "./StartupError";

interface IAppStartupProps {
  children: any,
}

const AppStartup: React.FC<IAppStartupProps> = (props) => {
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const user = accounts[0];
  const userEmail = user.username;
  const userName = user.name ? user.name : "";
  const {
    currentUser,
    loadOperation,
  } = useSelector(store => store.auth);

  useEffect(() => {
    if (userEmail) {
      // Load this user's info from the backend.
      dispatch(loadCurrentUserInfo({
        email: userEmail,
        name: userName,
      }));
    } else {
      // No one is logged in?!
      dispatch(setCurrentUser({
        email: "",
        name: "",
        roles: [],
        groupAssignments: [],
        orgs: [],
        plants: [],
        tccInfo: undefined,
        confidentialSWAllocations: [],
      }));

      dispatch(setLoadOperation({
        isWorking: false,
        errorMessage: "Something went wrong with the login procedure. Please refresh the page.",
      }));
    }
  }, [dispatch, userEmail]);

  if (loadOperation?.errorMessage) {
    let isUserNotFound = loadOperation.errorMessage.indexOf('not found.') > -1
      && loadOperation.errorMessage.indexOf('User') === 0;
    let errorInstructions: string;

    if (isUserNotFound) {
      errorInstructions = "Please contact an administrator to request access.";
    } else {
      errorInstructions = "Please try refreshing the page.";
    }

    return (
      <StartupError
        messages={[
          loadOperation.errorMessage,
          errorInstructions,
        ]}
      />
    );
  } else if (loadOperation?.isWorking
    || !currentUser.email) {
    return (
      <div className="centered">
        <Logo />
        <div className="spinner-holder">
          <BlockSpinner />
        </div>
      </div>
    );
  } else if (currentUser.email) {
    if (!currentUser.roles.length) {
      return (
        <StartupError
          messages={[
            "Your user account has no roles assigned.",
            "Please contact an administrator to request access.",
          ]}
        />
      );
    }

    return (
      <>
        {props.children}
      </>
    );
  } else {
    return (
      <StartupError
        messages={[
          "You are not currently logged in. Please refresh the page.",
        ]}
      />
    );
  }
}

export default AppStartup;