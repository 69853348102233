import React from "react";
import "./PPEComponent.scoped.scss";
import { IPPEComponent } from "interfaces/sw/SWInterfaces";
import M001 from "media/ppe/M001.png";
import M002 from "media/ppe/M002.png";
import M003 from "media/ppe/M003.png";
import M004 from "media/ppe/M004.png";
import M007 from "media/ppe/M007.png";
import M008 from "media/ppe/M008.png";
import M009 from "media/ppe/M009.png";
import M010 from "media/ppe/M010.png";
import M011 from "media/ppe/M011.png";
import M013 from "media/ppe/M013.png";
import M014 from "media/ppe/M014.png";
import M015 from "media/ppe/M015.png";
import M016 from "media/ppe/M016.png";
import M017 from "media/ppe/M017.png";
import M018 from "media/ppe/M018.png";
import M019 from "media/ppe/M019.png";
import M020 from "media/ppe/M020.png";
import M022 from "media/ppe/M022.png";
import M026 from "media/ppe/M026.png";
import M030 from "media/ppe/M030.png";
import M032 from "media/ppe/M032.png";
import M047 from "media/ppe/M047.png";
import M048 from "media/ppe/M048.png";
import WSM001 from "media/ppe/WSM001.png";
import ppeToolTipDictionary from "components/common/PPEToolTipDictionary";

interface IPPEComponentProps {
  component: IPPEComponent,
}

const PPEComponent: React.FC<IPPEComponentProps> = ({component}) => {
  return (
    <div className="ppeRow">
      {component.ppe
        .map((ppeId) => {
          return (
            <img
              key={ppeId}
              src={ppeImageDictionary[ppeId]}
              title={ppeToolTipDictionary[ppeId]}
              alt={ppeId}
            />
          );
        }
        )}
    </div>
  )
}

const ppeImageDictionary: Indexer = {
  "M001": M001,
  "M002": M002,
  "M003": M003,
  "M004": M004,
  "M007": M007,
  "M008": M008,
  "M009": M009,
  "M010": M010,
  "M011": M011,
  "M013": M013,
  "M014": M014,
  "M015": M015,
  "M016": M016,
  "M017": M017,
  "M018": M018,
  "M019": M019,
  "M020": M020,
  "M022": M022,
  "M026": M026,
  "M030": M030,
  "M032": M032,
  "M047": M047,
  "M048": M048,
  "WSM001": WSM001,
};

interface Indexer {
  [key: string]: string,
}

export default PPEComponent;