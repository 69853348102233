import React from "react";
import "./SWTab.scoped.scss";

interface ISWTabProps {
  isActive: boolean,
  onClick(): void,
  label: string,
  icon: string,
}

const SWTab: React.FC<ISWTabProps> = (props) =>
  <div
    className={"tab " + (props.isActive ? "active" : "")}
    onClick={props.onClick}
  >
    <img
      src={props.icon}
      title={props.label}
      alt={props.label}
      className="icon-large"
    />
    <span>
      {props.label}
    </span>
  </div>;

export default SWTab;