import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "components/common/Modal";
import {
  cancelCreateDraft,
  confirmCreateDraft,
} from "store/swList/swListActions";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import "./CreateDraftModal.scoped.scss";
import useSelector from "store/useSelector";
import MasterDataInput from "../manage/attributes/MasterDataInput";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import {
  loadOrganizations,
  loadOwningPlants,
} from "store/masterData/masterDataActions";

interface ICreateDraftModal {
  guid: string;
  version: number | null;
  duplicate: boolean;
  thumbsDownCount: number | undefined;
  thumbsUpCount: number | undefined;
  isWaitingForConfirm: boolean | "staleReusableContent";
  swType: SWTypes;
  newOwningOrgId: string | undefined;
  newOwningPlantId: string | undefined;
  updateGemsRevision: boolean | undefined;
}

const CreateDraftModal: React.FC<ICreateDraftModal> = (props) => {
  const dispatch = useDispatch();
  const {
    masterData: { organizations, owningPlants },
  } = useSelector((store) => store);
  const [selectedSwType, setSelectedSwTypeIcon] = useState(props.swType);
  const [newOwningOrgId, setNewOwningOrgId] = useState(props.newOwningOrgId);
  const showOwningOrgSelector =
    props.swType === SWTypes.MFGRC && selectedSwType === SWTypes.TLMRC;

  useEffect(() => {
    if (showOwningOrgSelector) {
      dispatch(loadOrganizations({ onlyActive: true }));
    } else {
      setNewOwningOrgId(props.newOwningOrgId);
    }
  }, [showOwningOrgSelector, props.newOwningOrgId]);

  const [owningOrgItem, setOwningOrgItem] = useState<IMasterDataItem>();

  useEffect(() => {
    if (organizations && organizations.items.length > 0 && newOwningOrgId) {
      const owningOrgItem = organizations.items.find(
        (org) => org.guid === newOwningOrgId
      );

      setOwningOrgItem(owningOrgItem ?? organizations.items[0]);
    }
  }, [organizations, newOwningOrgId]);

  const [newOwningPlantId, setNewOwningPlantId] = useState(
    props.newOwningPlantId
  );

  const showOwningPlantSelector =
    props.swType === SWTypes.TLMSWI && selectedSwType === SWTypes.MFGSWI;

  useEffect(() => {
    if (showOwningPlantSelector) {
      dispatch(loadOwningPlants({ onlyActive: true }));
    } else {
      setNewOwningPlantId(props.newOwningPlantId);
    }
  }, [showOwningPlantSelector, props.newOwningPlantId]);

  const [owningPlantItem, setOwningPlantItem] = useState<IMasterDataItem>();

  useEffect(() => {
    if (owningPlants && owningPlants.items.length > 0 && newOwningPlantId) {
      const owningPlantItem = owningPlants.items.find(
        (plant) => plant.guid === newOwningPlantId
      );

      setOwningPlantItem(owningPlantItem ?? owningPlants.items[0]);
    }
  }, [owningPlants, newOwningPlantId]);

  if (props.isWaitingForConfirm === "staleReusableContent") {
    return (
      <Modal
        header="Old versions of Reusable Content found in Standard Work"
        controls={
          <>
            <button
              className="primary-button"
              onClick={() =>
                dispatch(
                  confirmCreateDraft({
                    guid: props.guid,
                    version: props.version,
                    duplicate: props.duplicate,
                    thumbsDownCount: props.thumbsDownCount,
                    thumbsUpCount: props.thumbsUpCount,
                    staleReusableContentAction: "Update",
                    swType: selectedSwType,
                    newOwningOrgId,
                    newOwningPlantId,
                    updateGemsRevision: props.updateGemsRevision,
                  })
                )
              }
            >
              Update to Latest Version
            </button>
            <button
              className="primary-button"
              onClick={() =>
                dispatch(
                  confirmCreateDraft({
                    guid: props.guid,
                    version: props.version,
                    duplicate: props.duplicate,
                    thumbsDownCount: props.thumbsDownCount,
                    thumbsUpCount: props.thumbsUpCount,
                    staleReusableContentAction: "Delink",
                    swType: selectedSwType,
                    newOwningOrgId,
                    newOwningPlantId,
                    updateGemsRevision: props.updateGemsRevision,
                  })
                )
              }
            >
              Delink Reusable Content
            </button>
            <button
              className="secondary-button"
              onClick={() => dispatch(cancelCreateDraft())}
            >
              Cancel
            </button>
          </>
        }
        isOpen={true}
      >
        <p>
          This Standard Work uses Reusable Content that has been updated since
          this Standard Work was created.
        </p>
        <p>
          Please select an action to perform with this Reusable Content in the
          new Draft.
        </p>
        <p>
          You can update this Standard Work to use the latest version of the
          Reusable Content, or you can delink the Reusable Content.
        </p>
      </Modal>
    );
  }

  return (
    <Modal
      header="Create Draft"
      controls={
        <>
          <button
            className="primary-button"
            onClick={() =>
              dispatch(
                confirmCreateDraft({
                  guid: props.guid,
                  version: props.version,
                  duplicate: props.duplicate,
                  thumbsDownCount: props.thumbsDownCount,
                  thumbsUpCount: props.thumbsUpCount,
                  swType: selectedSwType,
                  newOwningOrgId,
                  newOwningPlantId,
                  updateGemsRevision: props.updateGemsRevision,
                })
              )
            }
          >
            Create Draft
          </button>
          <button
            className="secondary-button"
            onClick={() => dispatch(cancelCreateDraft())}
          >
            Cancel
          </button>
        </>
      }
      isOpen={true}
    >
      <>
        <p>Are you sure you want to create a new draft?</p>
        {showOwningOrgSelector && (
          <fieldset>
            <legend>New Owning Organization</legend>
            <MasterDataInput
              label="Owning Organization"
              isMandatory={true}
              disabled={false}
              selectedItems={owningOrgItem ? [owningOrgItem] : []}
              allowMultiple={false}
              onAddItem={(item: IMasterDataItem) =>
                setNewOwningOrgId(item.guid)
              }
              onRemoveItem={() => void 0}
              loadItems={() =>
                dispatch(loadOrganizations({ onlyActive: true }))
              }
              masterDataSection={organizations}
              itemFormatter={(item: IMasterDataItem) =>
                `${item.code} - ${item.value}`
              }
              isAsyncSearch={false}
            />
          </fieldset>
        )}

        {showOwningPlantSelector && (
          <fieldset>
            <legend>New Owning Plant</legend>
            <MasterDataInput
              label="Owning Plant"
              isMandatory={true}
              disabled={false}
              selectedItems={owningPlantItem ? [owningPlantItem] : []}
              allowMultiple={false}
              onAddItem={(item: IMasterDataItem) =>
                setNewOwningPlantId(item.guid)
              }
              onRemoveItem={() => void 0}
              loadItems={() => dispatch(loadOwningPlants({ onlyActive: true }))}
              masterDataSection={owningPlants}
              itemFormatter={(item: IMasterDataItem) =>
                `${item.code} - ${item.value}`
              }
              isAsyncSearch={false}
            />
          </fieldset>
        )}
      </>
    </Modal>
  );
};

export default CreateDraftModal;
