import { IToastState } from "./toastTypes";
import { createReducer } from "@reduxjs/toolkit";
import { addToastToState, removeToast } from "./toastActions";

const initialState: IToastState = {
  toasts: [],
}

const toastReducer = createReducer(initialState, builder => {
  // Add Toast To State
  builder.addCase(addToastToState, (state, action) => {
    state.toasts.push(action.payload);
  });

  // Remove Toast
  builder.addCase(removeToast, (state, action) => {
    state.toasts = state.toasts.filter(t => t.id !== action.payload.id);
  });
});

export default toastReducer;