import React from "react";
import useSelector from "../../../store/useSelector";
import CommonSort from '../../common/CommonSort';

interface IBatchSort {
    colName: string,
}

const BatchSort: React.FC<IBatchSort> = (props) => {
    const {
        filterData,
    } = useSelector(store => store.batchUpdates);

    return (
        <CommonSort
            colName={props.colName}
            filterData={filterData}
        />
    )
}

export default BatchSort;