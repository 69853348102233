import React from "react";
import useSelector from "../../../store/useSelector";
import CommonSort from '../../common/CommonSort';

interface IApprovalSort {
    colName: string,
}

const ApprovalSort: React.FC<IApprovalSort> = (props) => {
    const {
        filterData,
    } = useSelector(store => store.approvals);

    return (
        <CommonSort
            colName={props.colName}
            filterData={filterData}
        />
    )
}

export default ApprovalSort;