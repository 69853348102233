import React, { useEffect, useState } from "react";
import searchIcon from "media/icons/dls/search.svg";
import "./SearchSwRepoBar.scoped.scss";
import useDebounce from "utilities/useDebounce";

export interface ISearchSwRepoBarProps {
    setSearchText(value: string): void,
}

const SearchSwRepoBar: React.FC<ISearchSwRepoBarProps> = (props) => {
    const {
        setSearchText,
    } = props;
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 750);

    useEffect(() => {
        setSearchText(debouncedSearchTerm.trim());
    }, [debouncedSearchTerm, setSearchText]);

    return (
        <>
            <div className="search-bar">
                <div className="input-holder">
                    <input
                        type="text"
                        id="sw-search"
                        placeholder={"Search by Title..."}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    <img
                        src={searchIcon}
                        alt="Search"
                        className="icon-small"
                    />
                </div>
            </div>
        </>
    );
};

export default SearchSwRepoBar;