import React from "react";
import useSelector from "store/useSelector";
import ModalSpinner from "components/common/ModalSpinner";

const ModalSpinnerContainer: React.FC = () => {
  const {
    manageSW: {
      createOperation,
      updateOperation,
      publishOperation,
      loadOperation: manageLoadOperation,
      fileUploadOperation,
      commentData: {
        loadOperation: commentsLoadOperation,
      },
      stepCommentData: {
        loadOperation: stepCommentsLoadOperation,
      },
      timeMediaFilesMappingData: {
        loadTimeImagesOperation
      }

    },
    approvals: {
      loadMyApprovalsOperation,
      submitResponseOperation,
      submitDraftOperation,
      retractSwOperation,
      approvalHistory: {
        loadOperation: approvalLoadOperation,
      }
    },
    swList: {
      getPDFData: {
        getPdfOperation: getUrlOperation,
      },
      createDraftData: {
        createDraftOperation,
      },
      archiveData: {
        archiveOperation,
      },
      deleteDraftData: {
        deleteOperation,
      },
      createLCLData: {
        createLCLOperation,
      },
      swListData: {
        loadOperation: searchLoadOperation,
      },
      bulkDeleteDraftData : {
        bulkDeleteOperation,
      }
    }
  } = useSelector(store => store);

  if (createOperation?.isWorking
    || updateOperation?.isWorking
    || manageLoadOperation?.isWorking
    || submitDraftOperation?.isWorking
    || retractSwOperation?.isWorking
    || createDraftOperation?.isWorking
    || createLCLOperation?.isWorking
    || archiveOperation?.isWorking
    || approvalLoadOperation?.isWorking
    || loadMyApprovalsOperation?.isWorking
    || getUrlOperation?.isWorking
    || submitResponseOperation?.isWorking
    || fileUploadOperation?.isWorking
    || deleteOperation?.isWorking
    || bulkDeleteOperation?.isWorking
    || commentsLoadOperation?.isWorking
    || stepCommentsLoadOperation?.isWorking
    || searchLoadOperation?.isWorking
    || publishOperation?.isWorking
    || loadTimeImagesOperation?.isWorking
  ) {
    return <ModalSpinner />
  }

  return null;
}

export default ModalSpinnerContainer;