import React, { useEffect, useMemo } from "react";
import { Redirect, useHistory } from "react-router-dom"
import "./EditRoleAssignment.scoped.scss";
import { IManageUserDetail, Roles, UserGroups } from "interfaces/user/UserInterfaces";
import { useDispatch } from "react-redux";
import {
  roleAssignmentAddMetaDataItem,
  roleAssignmentRemoveMetaDataItem,
  roleAssignmentSetIsRCEditor,
  roleAssignmentSetGroup,
  roleAssignmentSetRole,
  roleAssignmentToggleOrg,
  resetEditRoleAssignmentData,
  addRoleAssignment, 
  editRoleAssignment, 
  roleAssignmentClearMetadata
} from "store/userManagement/userManagementActions";
import useSelector from "store/useSelector";
import {
  loadCountries,
  loadGeoUnits,
  loadOrganizations,
  loadOwningPlants,
  searchEpicEquipment,
  searchEquipment,
  searchProductCenters,
  searchServiceTypes
} from "store/masterData/masterDataActions";
import FlowLayout from "components/layout/FlowLayout";
import MasterDataInput from "components/sw/manage/attributes/MasterDataInput";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { showErrorToast, showSuccessToast } from "store/toast/toastActions";
import UserGroupIcon from "./UserGroupIcon";
import { isEqual, sortBy } from "lodash";
import { Routes } from "../../routing/Routing";

// When assigning these roles, Save button should be enabled
// even if there are no changes in organizations.
const rolesWithoutOrgs: string[] = [
  Roles.SysAdmin,
  Roles.ApproverCOE,
];
const EditRoleAssignment: React.FC = () => {
  const history = useHistory();
  const {
    auth:{
      currentUser
    },
    userManagement: {
      bulkEditUserData: {
        loadOperation,
        saveOperation,
        isDirty,
        selectedUsers,
        editingDetail,
        roleAssignments,
        isEditRoleAssignment
      }
    },
    masterData: {
      organizations,
      serviceTypes,
      equipment,
      epicEquipment,
      geoUnits,
      productCenters,
      countries,
      owningPlants,
    }
  } = useSelector(store => store);
  const selectedGroup = editingDetail?.isTechUser ? UserGroups.TLM : (editingDetail?.isMfgUser ? UserGroups.MFG : UserGroups.PSD);
  const selectedRole  = editingDetail?.role ?? "";

  const enableSave = useMemo(
    () => isDirty || rolesWithoutOrgs.includes(selectedRole),
    [isDirty, selectedRole, selectedGroup]
  );

  const dispatch = useDispatch();
  const pageTitle = isEditRoleAssignment ? "Edit role assignment" : "Add role assignment";

  useEffect(() => {
    if (selectedGroup === UserGroups.MFG) {
      dispatch(loadOwningPlants({ onlyActive: true }));
    } else {
      dispatch(loadOrganizations({ onlyActive: true }));
    }
  }, [selectedRole, selectedGroup]);

  if(selectedUsers.length == 0){
    return (<Redirect to={Routes.UserManagement}></Redirect>)
  }
  
  const validateRoleAssignment = (): string => {
    let error: string = "";
    if(!editingDetail.role){
      error = "Role is required.";
    }
    if(!editingDetail.isMfgUser && !editingDetail.isPsdUser && !editingDetail.isTechUser){
      error = "Geo Unit or Product Center is required.";
    }
    if(editingDetail.role == Roles.ApproverOwner && editingDetail.isTechUser &&
      ((editingDetail.productCenter.length + editingDetail.geoUnit.length) == 0)){
      error = "Geo Unit or Product Center is required.";
    }
    if(editingDetail.role == Roles.TechComm && editingDetail.isTechUser &&
      editingDetail.productCenter.length == 0){
      error = "Product Center is required";
    }
    if(!isEditRoleAssignment && roleAssignments?.find(x=> 
      x.role == editingDetail.role &&
      x.isTechUser == editingDetail.isTechUser && 
      x.isMfgUser == editingDetail.isMfgUser && 
      x.isPsdUser == editingDetail.isPsdUser)){
      if(selectedGroup == UserGroups.TLM ||
        selectedGroup == UserGroups.MFG){
        error = "Invalid Group and Role selection. Role assignment cannot be added twice.";
      }
      else{
        error = "Invalid Group and Role selection. Role assignment cannot be added twice.";
      }
    }
    return error;
  }
  const onAddClicked = () => {
    const error = validateRoleAssignment();
    if(error){
      dispatch(showErrorToast(error));
      return;
    }
    dispatch(addRoleAssignment(editingDetail));
    dispatch(resetEditRoleAssignmentData());
    history.push(Routes.NewUserRoleAssignments);
  }

  const onEditClicked = () => {
    const error = validateRoleAssignment();
    if(error){
      dispatch(showErrorToast(error));
      return;
    }
    dispatch(editRoleAssignment(editingDetail));
    dispatch(resetEditRoleAssignmentData());
    history.push(Routes.NewUserRoleAssignments);
  }

  const onUserGroupChanged = (userGroup: UserGroups) => {
    if (userGroup === selectedGroup) {
      return;
    }
    if(isEditRoleAssignment){
      dispatch(showErrorToast('Cannot change the Group type.'));
      return;
    }
    
    dispatch(resetEditRoleAssignmentData());
    dispatch(roleAssignmentSetGroup(userGroup));
    dispatch(roleAssignmentSetRole(""));

    if (userGroup === UserGroups.MFG) {
      dispatch(loadOwningPlants({ onlyActive: true }));
    } 
    else {
      dispatch(loadOrganizations({ onlyActive: true }));
    }
  }

  const onRoleChanged = (role: string) => {
    if (role === selectedRole) {
      return;
    }
    if(isEditRoleAssignment){
      dispatch(showErrorToast('Cannot change the Role type.'));
      return;
    }
    dispatch(roleAssignmentSetIsRCEditor(false));
    dispatch(roleAssignmentSetRole(role));
    dispatch(roleAssignmentClearMetadata());
  }

  const onRCEditorChanged = (isChecked: boolean) => {
    if (selectedRole === Roles.Author) {
      console.log(isChecked);
      dispatch(roleAssignmentSetIsRCEditor(isChecked));
    }
    else {
      dispatch(roleAssignmentSetIsRCEditor(false));
      dispatch(showErrorToast("RC Editor must be an author."));
    }
  }

  const showMasterData = (role: string) => {
    return ((selectedGroup === UserGroups.TLM ||
          selectedGroup === UserGroups.PSD) &&
        role === Roles.ApproverTechContent) ||
      (selectedGroup === UserGroups.TLM &&
        role === Roles.TechComm) ||
      (selectedGroup === UserGroups.TLM &&
        role === Roles.ApproverOwner)
  }

  const userGroupDescriptions: Record<string, string> = {
    [UserGroups.PSD]: "PSD",
    [UserGroups.TLM]: "TLM",
    [UserGroups.MFG]: "MFG",
  };

  let component: JSX.Element | undefined;
  let footer: JSX.Element | undefined;

  component = (
    <div>
      <fieldset className="roles">
        <legend>Group Name</legend>
        <div className="userGroup-container">
          {Object.values(UserGroups)
            .map(r =>
              <div
                key={"div-" + r}
                className={`icon ${selectedGroup === r ? "selected" : ""}`}
                onClick={() => onUserGroupChanged(r)}
                title={userGroupDescriptions[r]}>
                <UserGroupIcon
                  key={r}
                  userGroup={r}
                />
              </div>
            )}
        </div>
      </fieldset>
      <fieldset className="roles">
        <legend>Role Type</legend>
        {Array.from(roleDisplays)
          .filter(key => filterRoleByUserGroup(key[0], selectedGroup))
          .map(([role, display]) =>
            <button
              key={role}
              className={`primary-button ${editingDetail.role
                .indexOf(display.get(role) as Roles) > -1
                ? "selected"
                : ""}`}
              onClick={() => onRoleChanged(role)}
              title={display.get(role)}
            >
              {editingDetail.role
                .indexOf(role as Roles) > -1
                ? "☒"
                : "☐"} {role}
            </button>
          )}
      </fieldset>
      {((selectedGroup === UserGroups.TLM || 
            selectedGroup === UserGroups.MFG) && 
          editingDetail.role === Roles.Author) &&
          <fieldset className="roles">
              <legend>Additional Permissions</legend>
              <label className="org btn" title="Can create and update reusable content for the assigned organization/plant">
                  <input
                      type="checkbox"
                      checked={editingDetail.isRCEditor}
                      onChange={e => onRCEditorChanged(e.target.checked)}
                  />
                  RC Editor
              </label>
          </fieldset>
      }
      <fieldset className="orgs">
        <legend>{selectedGroup === UserGroups.MFG ? "Plants" : "Organizations"}</legend>
        <div className="orgs-holder">
          {(selectedGroup === UserGroups.MFG ? owningPlants : organizations).items
            .slice()
            .sort((a, b) => (a.code || "") < (b.code || "") ? -1 : 1)
            .map(org =>
              <label
                key={org.guid}
                className="org"
              >
                <input
                  value={org.guid}
                  type="checkbox"
                  checked={!!editingDetail.orgs.find(o => o.guid === org.guid)}
                  onChange={() => dispatch(roleAssignmentToggleOrg(org))}
                />
                {selectedGroup === UserGroups.MFG ? `${org.code} - ${org.value}` : org.code}
              </label>
            )}
        </div>
      </fieldset>
      {showMasterData(editingDetail.role) &&
          <fieldset className="roles">
              <legend>Metadata Assignments</legend>
            {selectedGroup === UserGroups.PSD &&
              editingDetail.role === Roles.ApproverTechContent &&
                <>
                    <div
                        className="input-container"
                    >
                        <MasterDataInput
                            label={"Service Types"}
                            isMandatory={false}
                            disabled={false}
                            isAsyncSearch={true}
                            selectedItems={editingDetail.serviceType}
                            allowMultiple={true}
                            onAddItem={(item: IMasterDataItem) => dispatch(roleAssignmentAddMetaDataItem({
                              metaDataName: "serviceTypes",
                              item,
                            }))}
                            onRemoveItem={(item: IMasterDataItem) => dispatch(roleAssignmentRemoveMetaDataItem({
                              metaDataName: "serviceTypes",
                              item,
                            }))}
                            loadItems={(searchTerm: string) => dispatch(searchServiceTypes({ searchTerm: searchTerm, onlyActive: true }))}
                            masterDataSection={serviceTypes}
                            itemFormatter={(item: IMasterDataItem) => item.value}
                        />
                    </div>
                    <div
                        className="input-container"
                    >
                        <MasterDataInput
                            label={"Equipment"}
                            isMandatory={false}
                            disabled={false}
                            isAsyncSearch={true}
                            selectedItems={editingDetail.equipment}
                            allowMultiple={true}
                            onAddItem={(item: IMasterDataItem) => dispatch(roleAssignmentAddMetaDataItem({
                              metaDataName: "equipment",
                              item,
                            }))}
                            onRemoveItem={(item: IMasterDataItem) => dispatch(roleAssignmentRemoveMetaDataItem({
                              metaDataName: "equipment",
                              item,
                            }))}
                            loadItems={(searchTerm: string) => dispatch(searchEquipment({ searchTerm, onlyActive: true }))}
                            masterDataSection={equipment}
                            itemFormatter={(item: IMasterDataItem) => item.value}
                        />
                    </div>
                    <div
                        className="input-container"
                    >
                        <MasterDataInput
                            label={"Country"}
                            isMandatory={false}
                            disabled={false}
                            selectedItems={editingDetail.country}
                            allowMultiple={true}
                            onAddItem={(item: IMasterDataItem) => dispatch(roleAssignmentAddMetaDataItem({
                              metaDataName: "countries",
                              item,
                            }))}
                            onRemoveItem={(item: IMasterDataItem) => dispatch(roleAssignmentRemoveMetaDataItem({
                              metaDataName: "countries",
                              item,
                            }))}
                            loadItems={() => dispatch(loadCountries({ onlyActive: true }))}
                            masterDataSection={countries}
                            itemFormatter={(item: IMasterDataItem) => item.value}
                        />
                    </div>
                </>
            }
            {selectedGroup === UserGroups.TLM &&
                <>
                    <div
                        className="input-container"
                    >
                        <MasterDataInput
                            label="Product Center"
                            isMandatory={false}
                            isAsyncSearch={true}
                            disabled={false}
                            selectedItems={editingDetail.productCenter}
                            allowMultiple={true}
                            onAddItem={(item: IMasterDataItem) => dispatch(roleAssignmentAddMetaDataItem({
                              metaDataName: "productCenters",
                              item,
                            }))}
                            onRemoveItem={(item: IMasterDataItem) => dispatch(roleAssignmentRemoveMetaDataItem({
                              metaDataName: "productCenters",
                              item,
                            }))}
                            loadItems={(searchTerm: string) => dispatch(searchProductCenters({ searchTerm: searchTerm, onlyActive: true }))}
                            masterDataSection={productCenters}
                            itemFormatter={(item: IMasterDataItem) => item.value}
                        />
                    </div>
                  {editingDetail.role === Roles.ApproverTechContent &&
                      <div
                          className="input-container"
                      >
                          <MasterDataInput
                              label={"EPIC Equipment"}
                              isMandatory={false}
                              disabled={false}
                              isAsyncSearch={true}
                              selectedItems={editingDetail.epicEquipment}
                              allowMultiple={true}
                              onAddItem={(item: IMasterDataItem) => dispatch(roleAssignmentAddMetaDataItem({
                                metaDataName: "epicEquipment",
                                item,
                              }))}
                              onRemoveItem={(item: IMasterDataItem) => dispatch(roleAssignmentRemoveMetaDataItem({
                                metaDataName: "epicEquipment",
                                item,
                              }))}
                              loadItems={(searchTerm: string) => dispatch(searchEpicEquipment({ searchTerm, onlyActive: true }))}
                              masterDataSection={epicEquipment}
                              itemFormatter={(item: IMasterDataItem) => item.value}
                          />
                      </div>
                  }
                  {editingDetail.role === Roles.ApproverOwner &&
                      <div
                          className="input-container"
                      >
                          <MasterDataInput
                              label="GeoUnit"
                              isMandatory={false}
                              disabled={false}
                              selectedItems={editingDetail.geoUnit}
                              allowMultiple={true}
                              onAddItem={(item: IMasterDataItem) => dispatch(roleAssignmentAddMetaDataItem({
                                metaDataName: "geoUnits",
                                item,
                              }))}
                              onRemoveItem={(item: IMasterDataItem) => dispatch(roleAssignmentRemoveMetaDataItem({
                                metaDataName: "geoUnits",
                                item,
                              }))}
                              loadItems={() => dispatch(loadGeoUnits({ onlyActive: true }))}
                              masterDataSection={geoUnits}
                              itemFormatter={(item: IMasterDataItem) => item.value}
                          />
                      </div>
                  }
                </>
            }
          </fieldset>
      }
    </div>
  );

  footer = (
    <div className="buttons">
      <button
        className="tertiary-button cancel-button"
        onClick={() => {
          history.push(Routes.NewUserRoleAssignments);
        }}
      >
          {enableSave ? "Cancel" : "Back"}
      </button>
      {!isEditRoleAssignment &&
        <button
          className="primary-button"
          disabled={!enableSave
            || loadOperation?.isWorking
            || saveOperation?.isWorking}
          onClick={onAddClicked}
        >
          Add
        </button>
      }
      {isEditRoleAssignment &&
        <button
          className="primary-button"
          disabled={!enableSave
            || loadOperation?.isWorking
            || saveOperation?.isWorking}
          onClick={onEditClicked}
        >
          Edit
        </button>
      }
    </div>
  );

  return (
    <FlowLayout
      header={(
        <h2 className="page-title">
          {pageTitle}
        </h2>
      )}
      footer={footer}
    >
      {component}
    </FlowLayout>
  );
}

export default EditRoleAssignment;

const roleSupport: Map<UserGroups, Roles[]> = new Map();
roleSupport.set(UserGroups.PSD, [
  Roles.Author,
  Roles.ApproverTechContent,
  Roles.ApproverCOE,
  Roles.ApproverOwner,
  Roles.OrgAdmin,
  Roles.SysAdmin,
]);
roleSupport.set(UserGroups.TLM, [
  Roles.Author,
  Roles.ApproverTechContent,
  Roles.TechComm,
  Roles.ApproverOwner,
  Roles.OrgAdmin,
  Roles.SysAdmin,
]);
roleSupport.set(UserGroups.MFG, [
  Roles.Author,
  Roles.ApproverOwner,
  Roles.OrgAdmin,
  Roles.SysAdmin,
  Roles.ApproverTechContent,
]);

const roleDisplays: Map<Roles, Map<string, string>> = new Map();
roleDisplays.set(Roles.Author, new Map<string, string>([["Author", "Can create and update SW"]]));
roleDisplays.set(Roles.ApproverTechContent, new Map<string, string>([["ApproverTechContent", "Completes technical content review on the SW for the assigned organization/plant"]]));
roleDisplays.set(Roles.ApproverCOE, new Map<string, string>([["ApproverCOE", " Acts as SW Center of Excellence reviewer"]]));
roleDisplays.set(Roles.ApproverOwner, new Map<string, string>([["ApproverOwner", "Acts as a content owner and reviews SW for the assigned organization/plant"]]));
roleDisplays.set(Roles.OrgAdmin, new Map<string, string>([["OrgAdmin", "Provisions Authors, Owners, Technical Content reviewers, and other Org Admins for the assigned organization/plant"]]));
roleDisplays.set(Roles.SysAdmin, new Map<string, string>([["SysAdmin", "System administrator privileges"]]));
roleDisplays.set(Roles.TechComm, new Map<string, string>([["TechComm", "Acts as the Technical Communications reviewer"]]));

const filterRoleByUserGroup = (key: Roles, userGroup: UserGroups) => {
  const support = roleSupport.get(userGroup);

  const ix = support
    ?.indexOf(key);

  return ix !== undefined && ix > -1;
}

const metadataItemsAreEqual = (a: IMasterDataItem[], b: IMasterDataItem[]) => {
  const aGuids = a.map((i) => i.guid.toUpperCase());
  const bGuids = b.map((i) => i.guid.toUpperCase());

  return isEqual(sortBy(aGuids), sortBy(bGuids));
};

const userMetadataIsEqual = (
  user1: IManageUserDetail,
  user2: IManageUserDetail,
  selectors: ((u: IManageUserDetail) => IMasterDataItem[])[]
) => {
  for (const selector of selectors) {
    if (!metadataItemsAreEqual(selector(user1), selector(user2))) {
      return false;
    }
  }

  return true;
};