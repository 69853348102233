import React from "react";
import { IComment } from "interfaces/sw/SWInterfaces";
import formatDate from "utilities/formatDate";
import SWStepCommentStatusIndicator from "./SWStepCommentStatusIndicator";

const SWComment: React.FC<IComment> = (comment) => {
    let commentParts = comment.comment.split(/(@\[.+?\])/g);

    return (
        <div>
            {
                commentParts.map((part, index) => (
                    part.indexOf("@[") > -1
                        ? <span key={index}><b>{part.substring(2, part.lastIndexOf("]"))}</b></span>
                        : <span key={index}>{part}</span>
                ))
            }
            {
                " - "
            }
            {formatDate(comment.createdOn, true)}
            {
                <SWStepCommentStatusIndicator
                    resolved={comment.resolved}
                    showText={true}
                />
            }
        </div>
    );
}

export default SWComment;