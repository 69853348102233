import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IOperation } from "interfaces/operations/Operations";

export enum Roles {
  Author = "Author",
  ApproverCOE = "ApproverCOE",
  ApproverTechContent = "ApproverTechContent",
  ApproverOwner = "ApproverOwner",
  OrgAdmin = "OrgAdmin",
  SysAdmin = "SysAdmin",
  TechComm = "TechComm",
  TechConRev = "TechConRev",
  SMEApprover = "SMEApprover",
  ProxyOwner = "ProxyOwner",
  ReportViewer = "ReportViewer",
}

export enum UserApprovalLevels {
  COE = "COE",
  TechContent = "TechContent",
  Owner = "Owner",
}

export interface IManageUserUser {
  name: string,
  email: string,
  roles: Roles[],
  groupAssignments?: IGroupAssignment[],
  orgs: IMasterDataItem[],
  plants: IMasterDataItem[],
  tccInfo?: ITCCInfo,
  confidentialSWAllocations: IConfidentialSWAllocation[],
}

export interface IConfidentialSWUser {
  name: string;
  email: string;
  role: ConfidentialAccessRoles;
}

export interface IConfidentialSWAllocation {
  role: ConfidentialAccessRoles;
  swId: number;
}

export interface ITCCInfo {
  status: TCCComplianceStatuses,
  tccId: number,
  countries: ITCCCountry[],
  loadOperation?: IOperation,
}

export interface ITCCCountry {
  name: string,
  desc: string,
}

export interface IManageUserDetail {
  role: string,
  orgs: IMasterDataItem[],
  serviceType: IMasterDataItem[],
  equipment: IMasterDataItem[],
  epicEquipment: IMasterDataItem[],
  geoUnit: IMasterDataItem[],
  productCenter: IMasterDataItem[],
  country: IMasterDataItem[], 
  isTechUser: boolean,
  isPsdUser: boolean,
  isMfgUser: boolean,
  isRCEditor: boolean,
  proxiedUsers: IProxyApprover[]
}

export interface IBulkEditUser {
  permissions: IManageUserDetail,
  userDetail: IAADUser
}

export interface IAADUser {
  name: string,
  email: string,
}

interface IProxyApprover {
  userEmail: string,
  proxyUserEmail: string,
  validUntil: Date
}

export enum TCCComplianceStatuses {
  NotCompliant = "NotCompliant",
  Compliant = "Compliant",
}

export enum AdditionalRolesType {
  TechUser = "TechUser",
  RCEditor = "RCEditor",
}

export enum UserGroups {
  PSD = "PSD",
  TLM = "TLM",
  MFG = "MFG",
}

export enum ConfidentialAccessRoles {
  Viewer = "Viewer",
  Editor = "Editor",
}

export interface IGroupAssignment {
  group: UserGroups,
  roles: Roles[],
}