import React, { useEffect, useState } from "react";
import "./UserFilterModal.scoped.scss";
import Modal from "components/common/Modal";
import MasterDataInput from "components/sw/manage/attributes/MasterDataInput";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import {
    loadCountries,
    loadGeoUnits,
    loadOrganizations,
    loadOwningPlants,
    searchEpicEquipment,
    searchEquipment,
    searchProductCenters,
    searchServiceTypes
} from "store/masterData/masterDataActions";
import { addFilterItem, removeFilterItem, setUserGroupFilter } from "store/userManagement/userManagementActions";
import { Roles, UserGroups } from "interfaces/user/UserInterfaces";
import UserGroupIcon from "./UserGroupIcon";
import { IMasterDataSection } from "store/masterData/masterDataTypes";

export interface IUserFilterModalProps {
    setIsModalOpen(value: boolean): void,
    applyFilter(): void,
    clearFilter(): void,
}

const UserFilterModal: React.FC<IUserFilterModalProps> = (props) => {
    const dispatch = useDispatch();
    const {
        masterData: {
            countries,
            equipment,
            organizations,
            productCenters,
            serviceTypes,
            epicEquipment,
            geoUnits,
            owningPlants,
        },
        userManagement: {
            filterData,
        },
    } = useSelector((store) => store);
    const [selectedUserGroup, setSelectedUserGroup] = useState(UserGroups.PSD);
    const [userRoles, setUserRoles] = useState<IMasterDataSection>({
        items: [],
        loadOperation: undefined,
    });
    const [onlyActiveStatusOwningOrg, setOnlyActiveStatusOwningOrg] = useState(true);
    const [onlyActiveStatusST, setOnlyActiveStatusST] = useState(true);
    const [onlyActiveStatusEquipment, setOnlyActiveStatusEquipment] = useState(true);
    const [onlyActiveCountries, setOnlyActiveCountries] = useState(true);
    const [onlyActiveProductCenters, setOnlyActiveProductCenters] = useState(true);
    const [onlyActiveEpicEquipment, setOnlyActiveEpicEquipment] = useState(true);
    const [onlyActiveGeoUnits, setOnlyActiveGeoUnits] = useState(true);
    const [onlyActiveStatusOwningPlants, setOnlyActiveStatusOwningPlants] = useState(true);

    const onUserGroupChanged = (userGroup: UserGroups) => {
        setSelectedUserGroup(userGroup);
    };

    const renderRoles = () => {
        let roles: IMasterDataSection = {
            items: [],
            loadOperation: undefined,
        };

        Array.from(roleDisplays)
            .filter(key => filterRoleByUserGroup(key[0], selectedUserGroup))
            .forEach(([role]) =>
                roles.items.push({
                    guid: role,
                    value: role,
                    active: true,
                    code: role,
                })
            );

        setUserRoles(roles);

        let isPsd: boolean = false;
        let isTech: boolean = false;
        let isMfg: boolean = false;

        if (selectedUserGroup === UserGroups.PSD) {
            isPsd = true;
        }
        else if (selectedUserGroup === UserGroups.TLM) {
            isTech = true;
        }
        else if (selectedUserGroup === UserGroups.MFG) {
            isMfg = true;
        }

        dispatch(setUserGroupFilter({ isPsd, isTech, isMfg }));
    };

    useEffect(() => {
        renderRoles();
    }, [selectedUserGroup]);

    return (
        <Modal
            isOpen={true}
            header={"Filter Users"}
            controls={
                <>
                    <button
                        className="secondary-button"
                        onClick={() => {
                            props.clearFilter();
                            setSelectedUserGroup(UserGroups.PSD);
                        }}
                    >
                        Clear
                    </button>
                    <button
                        className="primary-button"
                        onClick={() => {
                            props.applyFilter();
                            props.setIsModalOpen(false);
                        }}
                    >
                        Apply
                    </button>
                </>
            }
        >
            <div>
                <fieldset className="roles">
                    <legend>Group Name</legend>
                    <div className="userGroup-container">
                        {Object.values(UserGroups)
                            .map(r =>
                                <div
                                    key={"div-" + r}
                                    className={`icon ${selectedUserGroup === r ? "selected" : ""}`}
                                    onClick={() => onUserGroupChanged(r)}
                                    title={userGroupDescriptions[r]}>
                                    <UserGroupIcon
                                        key={r}
                                        userGroup={r}
                                    />
                                </div>
                            )}
                    </div>
                </fieldset>
            </div>
            <div className="inputs">
                <div>
                    <MasterDataInput
                        label="Roles"
                        isMandatory={false}
                        selectedItems={filterData.userRolesFilter}
                        allowMultiple={true}
                        onAddItem={item => dispatch(addFilterItem({ filterName: "userRole", filterItem: item }))}
                        onRemoveItem={item => dispatch(removeFilterItem({ filterName: "userRole", filterItem: item }))}
                        loadItems={() => renderRoles}
                        masterDataSection={userRoles}
                        itemFormatter={item =>
                            `${item.value}`
                        }
                        isAsyncSearch={false}
                    />
                </div>
                <div>
                    <MasterDataInput
                        label="Owning Organization"
                        isMandatory={false}
                        selectedItems={filterData.owningOrgsFilter}
                        allowMultiple={true}
                        onAddItem={item => dispatch(addFilterItem({ filterName: "owningOrgs", filterItem: item }))}
                        onRemoveItem={item => dispatch(removeFilterItem({ filterName: "owningOrgs", filterItem: item }))}
                        loadItems={(_: undefined, onlyActive: boolean) => dispatch(loadOrganizations({ onlyActive }))}
                        masterDataSection={organizations}
                        itemFormatter={item =>
                            `${item.code} - ${item.value}`
                        }
                        isAsyncSearch={false}
                        onlyActiveState={onlyActiveStatusOwningOrg}
                        onlyActiveStateChange={val => setOnlyActiveStatusOwningOrg(val)}
                    />
                </div>
                <div>
                    <MasterDataInput
                        label="Owning Plant"
                        isMandatory={false}
                        selectedItems={filterData.owningPlantsFilter}
                        allowMultiple={false}
                        onAddItem={item => dispatch(addFilterItem({ filterName: "owningPlant", filterItem: item }))}
                        onRemoveItem={item => dispatch(removeFilterItem({ filterName: "owningPlant", filterItem: item }))}
                        loadItems={(_: undefined, onlyActive: boolean) => dispatch(loadOwningPlants({ onlyActive }))}
                        masterDataSection={owningPlants}
                        itemFormatter={item =>
                            `${item.guid} - ${item.value}`
                        }
                        isAsyncSearch={false}
                        onlyActiveState={onlyActiveStatusOwningPlants}
                        onlyActiveStateChange={val => setOnlyActiveStatusOwningPlants(val)}
                    />
                </div>
                <div>
                    <MasterDataInput
                        label={"Service Type"}
                        isMandatory={false}
                        isAsyncSearch={true}
                        selectedItems={filterData.serviceTypesFilter}
                        allowMultiple={true}
                        onAddItem={item => dispatch(addFilterItem({ filterName: "serviceTypes", filterItem: item }))}
                        onRemoveItem={item => dispatch(removeFilterItem({ filterName: "serviceTypes", filterItem: item }))}
                        loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchServiceTypes({ searchTerm, onlyActive }))}
                        masterDataSection={serviceTypes}
                        itemFormatter={item => item.value}
                        onlyActiveState={onlyActiveStatusST}
                        onlyActiveStateChange={val => setOnlyActiveStatusST(val)}
                    />
                </div>
                <div>
                    <MasterDataInput
                        label={"Equipment"}
                        isMandatory={false}
                        isAsyncSearch={true}
                        selectedItems={filterData.equipmentsFilter}
                        allowMultiple={true}
                        onAddItem={item => dispatch(addFilterItem({ filterName: "equipment", filterItem: item }))}
                        onRemoveItem={item => dispatch(removeFilterItem({ filterName: "equipment", filterItem: item }))}
                        loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchEquipment({ searchTerm, onlyActive }))}
                        masterDataSection={equipment}
                        itemFormatter={item => item.value}
                        onlyActiveState={onlyActiveStatusEquipment}
                        onlyActiveStateChange={val => setOnlyActiveStatusEquipment(val)}
                    />
                </div>
                <div>
                    <MasterDataInput
                        label={"EPIC Equipment"}
                        isMandatory={false}
                        isAsyncSearch={true}
                        selectedItems={filterData.epicEquipmentsFilter}
                        allowMultiple={true}
                        onAddItem={item => dispatch(addFilterItem({ filterName: "epicEquipment", filterItem: item }))}
                        onRemoveItem={item => dispatch(removeFilterItem({ filterName: "epicEquipment", filterItem: item }))}
                        loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchEpicEquipment({ searchTerm, onlyActive }))}
                        masterDataSection={epicEquipment}
                        itemFormatter={item => item.value}
                        onlyActiveState={onlyActiveEpicEquipment}
                        onlyActiveStateChange={val => setOnlyActiveEpicEquipment(val)}
                    />
                </div>
                <div>
                    <MasterDataInput
                        label={"Country"}
                        isMandatory={false}
                        selectedItems={filterData.countriesFilter}
                        allowMultiple={true}
                        onAddItem={item => dispatch(addFilterItem({ filterName: "countries", filterItem: item }))}
                        onRemoveItem={item => dispatch(removeFilterItem({ filterName: "countries", filterItem: item }))}
                        loadItems={(_: string, onlyActive: boolean) => dispatch(loadCountries({ onlyActive: onlyActive }))}
                        masterDataSection={countries}
                        itemFormatter={item => item.value}
                        onlyActiveState={onlyActiveCountries}
                        onlyActiveStateChange={val => setOnlyActiveCountries(val)}
                    />
                </div>
                <div>
                    <MasterDataInput
                        label={"GeoUnit"}
                        isMandatory={false}
                        selectedItems={filterData.geoUnitsFilter}
                        allowMultiple={true}
                        onAddItem={item => dispatch(addFilterItem({ filterName: "geoUnits", filterItem: item }))}
                        onRemoveItem={item => dispatch(removeFilterItem({ filterName: "geoUnits", filterItem: item }))}
                        loadItems={(_: string, onlyActive: boolean) => dispatch(loadGeoUnits({ onlyActive: onlyActive }))}
                        masterDataSection={geoUnits}
                        itemFormatter={item => item.value}
                        onlyActiveState={onlyActiveGeoUnits}
                        onlyActiveStateChange={val => setOnlyActiveGeoUnits(val)}
                    />
                </div>
                <div>
                    <MasterDataInput
                        label={"Product Centers"}
                        isMandatory={false}
                        isAsyncSearch={true}
                        selectedItems={filterData.productCentersFilter}
                        allowMultiple={true}
                        onAddItem={item => dispatch(addFilterItem({ filterName: "productCenters", filterItem: item }))}
                        onRemoveItem={item => dispatch(removeFilterItem({ filterName: "productCenters", filterItem: item }))}
                        loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchProductCenters({ searchTerm, onlyActive }))}
                        masterDataSection={productCenters}
                        itemFormatter={item => item.value}
                        onlyActiveState={onlyActiveProductCenters}
                        onlyActiveStateChange={val => setOnlyActiveProductCenters(val)}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default UserFilterModal;

const userGroupDescriptions: Record<string, string> = {
    [UserGroups.PSD]: "PSD",
    [UserGroups.TLM]: "TLM",
    [UserGroups.MFG]: "MFG",
};

const roleDisplays: Map<Roles, Map<string, string>> = new Map();
roleDisplays.set(Roles.Author, new Map<string, string>([["Author", "Can create and update SW"]]));
roleDisplays.set(Roles.ApproverTechContent, new Map<string, string>([["ApproverTechContent", "Completes technical content review on the SW for the assigned organization/plant"]]));
roleDisplays.set(Roles.ApproverCOE, new Map<string, string>([["ApproverCOE", " Acts as SW Center of Excellence reviewer"]]));
roleDisplays.set(Roles.ApproverOwner, new Map<string, string>([["ApproverOwner", "Acts as a content owner and reviews SW for the assigned organization/plant"]]));
roleDisplays.set(Roles.OrgAdmin, new Map<string, string>([["OrgAdmin", "Provisions Authors, Owners, Technical Content reviewers, and other Org Admins for the assigned organization/plant"]]));
roleDisplays.set(Roles.SysAdmin, new Map<string, string>([["SysAdmin", "System administrator privileges"]]));
roleDisplays.set(Roles.TechComm, new Map<string, string>([["TechComm", "Acts as the Technical Communications reviewer"]]));

const filterRoleByUserGroup = (key: Roles, userGroup: UserGroups) => {
    const support = roleSupport.get(userGroup);

    const ix = support
        ?.indexOf(key);

    return ix !== undefined && ix > -1;
}

const roleSupport: Map<UserGroups, Roles[]> = new Map();
roleSupport.set(UserGroups.PSD, [
    Roles.Author,
    Roles.ApproverTechContent,
    Roles.ApproverCOE,
    Roles.ApproverOwner,
    Roles.OrgAdmin,
    Roles.SysAdmin,
]);
roleSupport.set(UserGroups.TLM, [
    Roles.Author,
    Roles.ApproverTechContent,
    Roles.TechComm,
    Roles.ApproverOwner,
    Roles.OrgAdmin,
    Roles.SysAdmin,
]);
roleSupport.set(UserGroups.MFG, [
    Roles.Author,
    Roles.ApproverOwner,
    Roles.OrgAdmin,
    Roles.SysAdmin,
    Roles.ApproverTechContent,
]);