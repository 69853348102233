import React from "react";
import UserRow from "./UserRow";
import "./UserList.scoped.scss";
import useSelector from "store/useSelector";
import BlockSpinner from "components/common/BlockSpinner";

const UserList: React.FC = () => {
  const { getUsersOperation, userList, searchText } = useSelector(store => store
    .userManagement
    .manageUsersData);

  let component: JSX.Element | undefined;

  if (getUsersOperation?.isWorking) {
    component = (
      <BlockSpinner />
    );
  } else if (getUsersOperation?.errorMessage) {
    component = (
      <label className="error">
        {getUsersOperation.errorMessage}
      </label>
    );
  } else if (!userList.length && searchText == "") {
    component = (
        <></>
    );
  } else if (!userList.length) {
    component = (
      <label>No users found.</label>
    );
  } else {
    component = (
      <>
        <div className="row header">
          <div className="cell name">
            User
          </div>
          <div className="cell roles">
            Roles
          </div>
        </div>
        <div className="list-body">
          {userList.map(u =>
            <UserRow
              user={u}
              key={u.email}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <div className="user-list">
      {component}
    </div>
  );
}

export default UserList;