import React from "react";
import "./ToggleButtonInput.scoped.scss";

interface IToggleButtonInputProps {
  onAnswerChosen: (value: string) => void,
  currentValue?: string,
  disabled: boolean,
  options: string[],
}

const ToggleButtonInput: React.FC<IToggleButtonInputProps> =
  ({ currentValue, disabled, onAnswerChosen, options }) =>
    <div className="select-input">
      {options.map(opt =>
        <button
          key={opt}
          className={currentValue === opt
            ? "primary-button"
            : "secondary-button"
          }
          onClick={!disabled
            ? () => onAnswerChosen(opt)
            : undefined
          }
          disabled={disabled}
        >
          {opt}
        </button>
      )}
    </div>;

export default ToggleButtonInput;