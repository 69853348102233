import React from "react";
import "./SelectSteps.scoped.scss";
import useSelector from "store/useSelector";
import FlowLayout from "components/layout/FlowLayout";
import { cloneDeep } from "lodash";
import StepListSelections from "./StepListSelections";
import { useDispatch } from "react-redux";
import { setCreateReusableContentPopup } from "store/manageSW/manageSWActions";

interface ISelectStepsProps {
  isDisabled: boolean,
  setNextTab(): void,
}

const SelectSteps: React.FC<ISelectStepsProps> = ({ isDisabled, setNextTab }) => {
  const {
    SW: {
      steps,
      type
    }
  } = useSelector(store => store.manageSW);
  const stepsCopied = cloneDeep(steps)
  const dispatch = useDispatch();
  return (
    <FlowLayout>
      <div className="manage-steps">
        <div className="main-pane">
          {steps.length > 0 &&
            <StepListSelections
              steps={stepsCopied}
              ancestors={[]}
              isDisabled={isDisabled}
              swType={type}
            />
          }
          {stepsCopied.length === 0 &&
            <i>Tasks are not found in the standardwork</i>
          }
        </div>
      </div>
      <div className="buttonDiv">
        <button
          className="primary-button"
          onClick={setNextTab}
          disabled={stepsCopied.length === 0 }
        >
          Next
        </button>
        <button
          className="primary-button"
          onClick={() => dispatch(setCreateReusableContentPopup({
            isCreateRCPopup: false,
            swType : type,
          }))}
        >
          Close
        </button>
      </div>
    </FlowLayout>
  );
}

export default SelectSteps;