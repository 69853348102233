import SWMetadata from "components/common/SWMetadata";
import "./SWIAttributes.scoped.scss";
import useSelector from "store/useSelector";
import React from "react";

interface ISWIAttributesProps {
    isDisabled: boolean,
}

const SWIAttributes: React.FC<ISWIAttributesProps> = ({ isDisabled }) => {
    const {
        manageSW: {
            SW,
        },
    } = useSelector(store => store);

    return (
        <div className="inputs">
            <div className="type-row">
                <SWMetadata
                    metadataName="taskAlignment"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="languages"
                    isDisabled={isDisabled}
                />
            </div>
            {((SW.language
                && SW.language.value !== "English")
                || (SW.countries.length > 0)
                || (SW.geoUnits.length > 0)) &&
                <SWMetadata
                    metadataName="equivalentSWs"
                    isDisabled={isDisabled}
                />
            }
            <SWMetadata
                metadataName="title"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="description"
                isDisabled={isDisabled}
            />
            <div className="double-wide">
                <SWMetadata
                    metadataName="countries"
                    isDisabled={isDisabled}
                />
                {SW.countries.length > 0 &&
                    <SWMetadata
                        metadataName="customers"
                        isDisabled={isDisabled}
                    />
                }
                <SWMetadata
                    metadataName="organizations"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="subBusinessLines"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="tasks"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="performingOrganization"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="serviceTypes"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="operatingEnvironments"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="equipment"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="competencyElements"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="safetyCategories"
                    isDisabled={isDisabled}
                />
            </div>
            <SWMetadata
                metadataName="versionChanges"
                isDisabled={isDisabled}
            />
        </div>
    );
}

export default SWIAttributes;