import { IRoutingOperationComponent, ISW } from "interfaces/sw/SWInterfaces";
import React, { FC, useEffect, useState } from "react";
import "./RoutingOperationViewer.scoped.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootStore";
import SelectRoutingOperationModal from "../SelectRoutingOperationModal";
import { loadOperationTree } from "store/masterData/masterDataActions";

interface IRoutingOperationViewerProps {
  component: IRoutingOperationComponent;
  sw: ISW;
}

const RoutingOperationViewer: FC<IRoutingOperationViewerProps> = ({
  component,
  sw,
}) => {
  const dispatch = useDispatch();
  const [showDiagram, setShowDiagram] = useState(false);

  const operationTree = useSelector(
    (state: RootState) => state.masterData.operationTree
  );

  useEffect(() => {
    if (showDiagram) {
      dispatch(
        loadOperationTree({
          plantCode: component.operation.plantCode,
          group: component.operation.group,
          groupCounter: component.operation.groupCounter,
          materialCode: component.operation.materialCode,
          plantSource: sw.plantSource,
        })
      );
    }
  }, [showDiagram]);

  return (
    <>
      <p>
        <b>Operation: </b>
        {component.operation.operation} - {component.operation.operationText}
      </p>

      <p>
        <b>Group: </b>
        {component.operation.group}
      </p>
      <p>
        <b>Group Counter: </b>
        {component.operation.groupCounter}
      </p>
      <p>
        <b>Sequence: </b>
        {component.operation.sequence}
      </p>
      <p>
        <button className="primary-button" onClick={() => setShowDiagram(true)}>
          Show Diagram
        </button>
      </p>

      {showDiagram && operationTree && (
        <SelectRoutingOperationModal
          isOpen={showDiagram}
          operationTree={operationTree}
          onCanceled={() => setShowDiagram(false)}
          title="Routing Diagram"
          cancelText="Close"
        />
      )}
    </>
  );
};

export default RoutingOperationViewer;