import { SWStatus } from "interfaces/sw/SWInterfaces";
import React from "react";
import "./SWStatusIndicator.scoped.scss";

interface ISWStatusIndicatorProps {
    status: SWStatus,
}

const SWStatusIndicator: React.FC<ISWStatusIndicatorProps> = ({
    status,
}) => {
    return (
        <span
            className="sw-status"
        >
            <div
                className={`orb ${status === SWStatus.NA
                    ? "NA"
                    : status.toString()
                    }`
                }>
            </div>
            <span
                className="text"
            >
                {status.toString()}
            </span>
        </span>);
}
export default SWStatusIndicator;