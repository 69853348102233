import { takeLatest, put, all, call } from "redux-saga/effects";
import { loadCurrentUserInfo, setLoadOperation, setCurrentUser, confirmTCC, setTCCInfo, setTCCInfoOperation, setEnableReportsTabFlag } from "./authActions";
import { Action } from "@reduxjs/toolkit";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import { IManageUserUser, ITCCInfo } from "interfaces/user/UserInterfaces";
import UserApi from "apis/user/UserApi";
import { IConfigParameters } from "interfaces/masterData/masterDataInterfaces";
import MasterDataApi from "apis/masterData/MasterDataApi";

export default function* watchAuthSagas() {
  yield all([
    watchLoadCurrentUserInfo(),
    watchConfirmTCC(),
  ]);
}

function* watchLoadCurrentUserInfo() {
  yield takeLatest(loadCurrentUserInfo, loadCurrentUserInfoAsync);
}

function* loadCurrentUserInfoAsync(action: Action) {
  if (!loadCurrentUserInfo.match(action)) {
    return;
  }

  yield put(setLoadOperation({
    isWorking: true,
  }));

  try {
    let user: IManageUserUser = yield call(UserApi.getUser, action.payload.email, action.payload.name);
    yield put(setCurrentUser(user));

    let configParameter: IConfigParameters[] = yield call(MasterDataApi.getConfigParameters);
    var enableReportsTab = configParameter.find(x => x.key.toLowerCase() === "enablereportstab");
    let enableReportsTabValue = enableReportsTab !== undefined && enableReportsTab.value !== undefined && enableReportsTab.value.toLowerCase() === "true" ? true : false;
    yield put(setEnableReportsTabFlag(enableReportsTabValue));

    yield put(setLoadOperation(undefined));
  } catch (err: any) {
    yield put(setLoadOperation({
      isWorking: false,
      wasSuccessful: false,
      errorMessage: getResponseErrorMessage(err),
    }));
    return;
  }
}

function* watchConfirmTCC() {
  yield takeLatest(confirmTCC, confirmTCCAsync);
}

function* confirmTCCAsync(action: Action) {
  if (!confirmTCC.match(action)) {
    return;
  }

  yield put(setTCCInfoOperation({
    isWorking: true,
    wasSuccessful: false,
  }));

  try {
    let tccInfo: ITCCInfo = yield call(UserApi.submitTCCResponse, action.payload.tccId, action.payload.status);
    if (tccInfo) {
      yield put(setTCCInfo({
        status: tccInfo.status,
        tccId: tccInfo.tccId,
        countries: tccInfo.countries,
        loadOperation: {
          isWorking: false,
          wasSuccessful: true,
        }
      }));
    }
  } catch (err: any) {
    yield put(setTCCInfoOperation({
      isWorking: false,
      wasSuccessful: false,
      errorMessage: getResponseErrorMessage(err),
    }));
    return;
  }
}

