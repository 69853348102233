import React from "react";
import "./MetadataRow.scoped.scss";

interface IMetadataRowProps {
  visible: boolean,
  label: string,
  value: string | string[] | undefined,
}

const MetadataRow: React.FC<IMetadataRowProps> = ({ visible, label, value }) => {
  if (!visible
    || value === undefined
    || (Array.isArray(value)
      && value.length === 0)) {
    return null;
  }

  let values: string[] = [];

  if (!Array.isArray(value)) {
    values = [value];
  } else {
    values = value;
  }

  return (
    <div className="row">
      <label>
        {label}
      </label>
      <div className="value">
        {values.map(x => (
          <div
            className="tag"
            key={x}
          >
            {x}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MetadataRow;