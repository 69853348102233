import React from "react";
import useSelector from "../../../store/useSelector";
import CommonSort from '../../common/CommonSort';

interface ISWSort {
    colName: string,
}

const SWSort: React.FC<ISWSort> = (props) => {
    const {
        filterData,
    } = useSelector(store => store.swList);

    return (
        <CommonSort
            colName={props.colName}
            filterData={filterData}
        />
    )
}

export default SWSort;