import React from "react";
import { useDispatch } from "react-redux";
import Modal from "components/common/Modal";
import useSelector from "store/useSelector";
import "./TimeMediaMappingModal.scoped.scss";
import { useHistory } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import SWTypeIcon from "../search/SWTypeIcon";
import { cancelTimeMediaMappingModal, setTimeMediaWhereUsedFiles } from "store/manageSW/manageSWActions";

const TimeMediaMappingModal: React.FC = () => {
    const {
        timeMediaFilesMappingData
    } = useSelector(store => store.manageSW);
    const dispatch = useDispatch();
    let history = useHistory();

    const onSWClick = (guid: string, version: number) => {
        dispatch(setTimeMediaWhereUsedFiles({
            timeMediaFileMapping: [],
            loadTimeImagesOperation: {
                isWorking: false,
                wasSuccessful: false,
            }
        }));
        history.push(Routes.ViewStandardWork
            .replace(":guid", guid)
            .replace(":version", version.toString()));
    }

    return (
        <Modal
            header="TimeMedia Content Mapping in WorkRight"
            controls={(
                <>
                    <button
                        className="secondary-button"
                        onClick={() => dispatch(cancelTimeMediaMappingModal())}
                    >
                        Close
                    </button>
                </>
            )}
            isOpen={true}
        >
            {timeMediaFilesMappingData
                && timeMediaFilesMappingData.timeMediaFileMapping
                && timeMediaFilesMappingData.timeMediaFileMapping.length > 0
                && <div>
                    <div className="row header">
                        <div className="cell sw-name">
                            Standard Work Name
                        </div>
                        <div className="cell width30">
                            Guid
                        </div>
                        <div className="cell width10">
                            Version
                        </div>
                        <div className="cell buttons">
                        </div>
                    </div>

                    <div className="list-body">
                        {
                            timeMediaFilesMappingData.timeMediaFileMapping.map((row, index) => (
                                <div className="sw-header" key={index}>
                                    <div id={"testttchc" + index} className="cell sw-name" key={"swname" + index}>
                                        <div className="title-holder" key={"tholder" + index}>
                                            <SWTypeIcon type={row.type} key={"swtype" + index} />
                                            <div key={"swtitle" + index} className="sw-title">
                                                {row.title}
                                            </div>
                                        </div>
                                    </div>
                                    <div id={row.guid} className="cell width30">{row.guid}</div>
                                    <div id={row.version.toString() + index} className="cell width10">{row.version}</div>
                                    <div className="cell buttons">
                                        <button
                                            onClick={() => onSWClick(row.guid, row.version)}> Open</button>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

            }
            {timeMediaFilesMappingData.timeMediaFileMapping.length <= 0
                && <h2> No SW is using this image</h2>}
        </Modal>
    )
}
export default TimeMediaMappingModal;