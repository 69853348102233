import React from "react";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import NotFound from "components/NotFound";
import SWSearch from "components/sw/search/SWSearch";
import UserProfile from "components/user/UserProfile";
import ManageSW from "components/sw/manage/ManageSW";
import UserManagement from "components/user/management/UserManagement";
import EditUser from "components/user/management/EditUser";
import UserDetail from "components/user/management/UserDetail";
import MyApprovals from "components/approvals/mine/MyApprovals";
import ViewApproval from "components/approvals/view/ViewApproval";
import ViewBatchApproval from "components/approvals/view/batch/ViewBatchApproval";
import ViewRCUpdateApproval from "components/approvals/view/rcupdate/ViewRCUpdateApproval";
import { AuthFilter } from "auth/AuthFilter";
import { Roles } from "interfaces/user/UserInterfaces";
import AuthRoute from "./AuthRoute";
import BatchUpdatesCreator from "components/batchUpdates/BatchUpdatesCreator";
import ManageSwReports from "components/sw/reports/ManageSwReports";
import DownloadLatestPdfVersion from "components/sw/reports/DownloadLatestPdfVersion";
import NewUserRoleAssignments from "../user/management/NewUserRoleAssignments";
import EditRoleAssignment from "../user/management/EditRoleAssignment";
import useSelector from "store/useSelector";

export enum Routes {
  NewStandardWork = "/sw/new/:mode",
  EditStandardWork = "/sw/:guid/:version/edit",
  ViewStandardWork = "/sw/:guid/:version/view",
  MyProfile = "/user/profile",
  Root = "/",
  SearchStandardWork = "/sw/search",
  UserManagement = "/users/manage",
  EditUser = "/users/manage/getUserEditInfo/:email/:role/:isTechUser/:isPsdUser/:isMfgUser/:isRCEditor",
  EditRoleAssignment = "/users/manage/editRoleAssignment",
  NewUserRoleAssignments = "/users/manage/newUserRoleAssignments",
  UserDetail = "/users/detail/:email",
  MyApprovals = "/approvals",
  ViewApproval = "/approvals/:id",
  ViewBatchApproval = "/approvals/batch/:id",
  ViewRCUpdateApproval = "/approvals/rcupdate/:id",
  BatchUpdatesCreator = "/batchUpdates/new",
  Reports = "/Pdfs",
  DownloadLatestPdfVersion = "/Pdfs/:swId",
}

const Routing: React.FC = () => {
  const {
    currentUser: {
      roles,
    }
  } = useSelector(store => store.auth);

  const firstRouting = () => {
    if (roles.find(x => x !== Roles.ReportViewer)?.length) {
      return <Redirect to={Routes.SearchStandardWork} />;
    } else {
      return <Redirect to={Routes.Reports} />
    }
  }

  return (
    <Switch>
      <Route exact path={Routes.Root}>
        {firstRouting()}
      </Route>
      <AuthRoute
        path={Routes.SearchStandardWork}
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.ApproverCOE,
            Roles.ApproverTechContent,
            Roles.ApproverOwner,
            Roles.OrgAdmin,
            Roles.TechComm,
            Roles.ProxyOwner,
          ])
          .includeSysAdmins()
        }>
        <SWSearch />
      </AuthRoute>
      <AuthRoute
        path={Routes.NewStandardWork}
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.OrgAdmin,
            Roles.TechComm,
          ])
          .includeSysAdmins()
        } >
        <Route path={Routes.NewStandardWork} component={ManageSW} />
      </AuthRoute>
      <AuthRoute
        path={Routes.EditStandardWork}
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.OrgAdmin,
            Roles.TechComm,
            Roles.ApproverOwner,
            Roles.ApproverCOE,
            Roles.ApproverTechContent,
            Roles.ProxyOwner
          ])
          .includeSysAdmins()
        }>
        <Route path={Routes.EditStandardWork} component={ManageSW} />
      </AuthRoute>
      <AuthRoute
        path={Routes.ViewStandardWork}
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.OrgAdmin,
            Roles.TechComm,
            Roles.ApproverOwner,
            Roles.ApproverCOE,
            Roles.ApproverTechContent,
            Roles.ProxyOwner
          ])
          .includeSysAdmins()
        } >
        <Route path={Routes.ViewStandardWork} component={ManageSW} />
      </AuthRoute>
      <Route path={Routes.MyProfile} component={UserProfile} />
      <AuthRoute
        path={Routes.UserManagement}
        component={UserManagement}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasRole(Roles.OrgAdmin)
          .includeSysAdmins()
        }
      />
      <AuthRoute
        path={Routes.EditUser}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasRole(Roles.OrgAdmin)
          .includeSysAdmins()
        }>
        <Route path={Routes.EditUser} component={EditUser} exact />
      </AuthRoute>
      <AuthRoute
        path={Routes.NewUserRoleAssignments}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasRole(Roles.OrgAdmin)
          .includeSysAdmins()
        }>
        <Route path={Routes.NewUserRoleAssignments} component={NewUserRoleAssignments} exact />
      </AuthRoute>
      <AuthRoute
        path={Routes.EditRoleAssignment}
        component={EditRoleAssignment}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasRole(Roles.OrgAdmin)
          .includeSysAdmins()
        }
      />
      <AuthRoute
        path={Routes.UserDetail}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasRole(Roles.OrgAdmin)
          .includeSysAdmins()
        }>
        <Route path={Routes.UserDetail} component={UserDetail} exact />
      </AuthRoute>
      <AuthRoute
        path={Routes.MyApprovals}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.ApproverCOE,
            Roles.ApproverTechContent,
            Roles.ApproverOwner,
            Roles.OrgAdmin,
            Roles.TechComm,
            Roles.TechConRev,
            Roles.SMEApprover,
            Roles.ProxyOwner,
          ])
          .includeSysAdmins()
        }>
        <MyApprovals />
      </AuthRoute>
      <AuthRoute
        path={Routes.ViewApproval}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.ApproverCOE,
            Roles.ApproverTechContent,
            Roles.ApproverOwner,
            Roles.OrgAdmin,
            Roles.TechComm,
            Roles.TechConRev,
            Roles.SMEApprover,
            Roles.ProxyOwner,
          ])
          .includeSysAdmins()
        }>
        <Route path={Routes.ViewApproval} component={ViewApproval} exact />
      </AuthRoute>
      <AuthRoute
        path={Routes.ViewBatchApproval}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.ApproverCOE,
            Roles.ApproverTechContent,
            Roles.ApproverOwner,
            Roles.OrgAdmin,
            Roles.TechComm,
            Roles.TechConRev,
            Roles.SMEApprover,
            Roles.ProxyOwner,
          ])
          .includeSysAdmins()
        }>
        <Route path={Routes.ViewBatchApproval} component={ViewBatchApproval} exact />
      </AuthRoute>
      <AuthRoute
        path={Routes.ViewRCUpdateApproval}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.ApproverCOE,
            Roles.ApproverTechContent,
            Roles.ApproverOwner,
            Roles.OrgAdmin,
            Roles.TechComm,
            Roles.TechConRev,
            Roles.SMEApprover,
            Roles.ProxyOwner,
          ])
          .includeSysAdmins()
        }>
        <Route path={Routes.ViewRCUpdateApproval} component={ViewRCUpdateApproval} exact />
      </AuthRoute>
      <AuthRoute
        path={Routes.BatchUpdatesCreator}
        component={BatchUpdatesCreator}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.Author,
            Roles.OrgAdmin,
          ]).includeSysAdmins()
        }
      />
      <AuthRoute
        path={Routes.Reports}
        component={ManageSwReports}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.ReportViewer,
          ]).excludeSysAdmins()
        }
      />
      <AuthRoute
        path={Routes.DownloadLatestPdfVersion}
        exact
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([
            Roles.ReportViewer,
          ]).excludeSysAdmins()
        }>
        <Route path={Routes.DownloadLatestPdfVersion} component={DownloadLatestPdfVersion} exact />
      </AuthRoute>
      <Route component={NotFound} />
    </Switch>);
}

export default Routing;