import React from "react";
import SpinnerIcon from "media/Pulse-1s-131px.svg";
import "./BlockSpinner.scoped.scss";

interface BlockSpinnerProps {
  width?: number,
}

const BlockSpinner: React.FC<BlockSpinnerProps> = ({ width }) =>
  <img
    src={SpinnerIcon}
    alt="Working..."
    className="spinner"
    style={{ width: width }}
  />;

export default BlockSpinner;