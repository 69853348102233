import React, { useState } from "react";
import { IMergeMenuItem } from "store/manageSW/manageSWTypes";
import MergeMenuDropdown from "./MergeMenuDropdown";
import "./MergeMenu.scoped.scss";

interface IMergeMenuProps {
  item: IMergeMenuItem,
  onMergeNumberClick: (value: string) => void,
  depthLevel: number,
  parentName: string,
}

const MergeMenuItem: React.FC<IMergeMenuProps> = ({item, onMergeNumberClick, depthLevel, parentName}) => {
  const [dropdown, setDropdown] = useState(false);

  parentName = depthLevel > 0 ? `${parentName}|${item.title}` : item.title;
  return (
   <li className="menu-items">
    {item.submenu ? (
     <>
      <button 
        type="button" 
        aria-haspopup="menu"
        aria-expanded={dropdown ? "true" : "false"}
        onClick={() => setDropdown((prev) => !prev)}
      >
       {`${item.title} `}&raquo;
      </button>
      <MergeMenuDropdown 
        submenus={item.submenu} 
        dropdown={dropdown}
        onMergeNumberClick={onMergeNumberClick}
        depthLevel={depthLevel}
        parentName={parentName}
      />
     </>
    ) : (
     <span
      onClick={() => onMergeNumberClick(parentName)}
     >
       {item.title}
     </span>
    )}
   </li>
  );
 };
 
 export default MergeMenuItem;