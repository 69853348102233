import {
  BatchUpdateCommandTypes,
  BatchUpdateMetaTypes,
} from "interfaces/batchUpdates/batchUpdatesInterfaces";

import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { ShowCLSignatureBlock, TLMSWIType } from "interfaces/sw/SWInterfaces";
import React, { FC, useEffect } from "react";
import { IEditBatchUpdateCommand } from "./CommandManager";
import "./CommandManager.scoped.scss";

const SupportedMetadataTypes: BatchUpdateMetaTypes[] = [
  BatchUpdateMetaTypes.GlobalOrLocal,
  BatchUpdateMetaTypes.Introduction,
  BatchUpdateMetaTypes.MaintenanceTaskId,
  BatchUpdateMetaTypes.CEMarking,
  BatchUpdateMetaTypes.Foreword,
  BatchUpdateMetaTypes.HARC,
  BatchUpdateMetaTypes.VersionChanges,
  BatchUpdateMetaTypes.ShowCLSignatureBlock,
];

export function isNonMasterData(type: BatchUpdateMetaTypes) {
  return SupportedMetadataTypes.includes(type);
}

interface NonMasterDataFieldProps {
  command: IEditBatchUpdateCommand;
  onAddItem(item: IMasterDataItem): void;
  onRemoveItem(item: IMasterDataItem): void;
}

const TextInputWithNoRemove: FC<NonMasterDataFieldProps> = ({
  command,
  onAddItem,
  onRemoveItem,
}) => {
  const value =
    command.masterDataItems.length > 0 ? command.masterDataItems[0].value : "";

  const onChange = (_value: string) => {
    command.masterDataItems.forEach((i) => onRemoveItem(i));

    if (_value) {
      onAddItem({ guid: _value, value: _value });
    }
  };

  useEffect(() => {
    command.masterDataItems.forEach((i) => onRemoveItem(i));

    if (command.command === BatchUpdateCommandTypes.Remove) {
      onAddItem({ guid: "(ALL VALUES)", value: "(ALL VALUES)" });
    }
  }, [command.command]);

  if (command.command === BatchUpdateCommandTypes.Remove) {
    return (
      <p>All {command.metaType?.toString() || ""} values will be removed.</p>
    );
  } else {
    return (
      <>
        {command.metaType && (
          <label>{textInputNames[command.metaType] || command.metaType}</label>
        )}

        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          maxLength={36}
        />
      </>
    );
  }
};

const TextInput: FC<NonMasterDataFieldProps> = ({
  command,
  onAddItem,
  onRemoveItem,
}) => {
  const value = command.masterDataItems.length
    ? command.masterDataItems[0].value
    : "";

  const onChange = (_value: string) => {
    command.masterDataItems.forEach((i) => onRemoveItem(i));

    if (_value) {
      onAddItem({ guid: _value, value: _value });
    }
  };

  return (
    <>
      {command.metaType && (
        <label>{textInputNames[command.metaType] || command.metaType}</label>
      )}

      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        maxLength={36}
      />
    </>
  );
};

const GlobalOrLocalInput: FC<NonMasterDataFieldProps> = ({
  command,
  onAddItem,
  onRemoveItem,
}) => {
  let value: string | undefined = undefined;

  if (command.masterDataItems.length) {
    value = command.masterDataItems[0].value;
  }

  const onChange = (_value: string) => {
    command.masterDataItems.forEach((item) => onRemoveItem(item));

    if (Object.keys(TLMSWIType).includes(_value)) {
      onAddItem({ guid: _value, value: _value });
    }
  };

  useEffect(() => {
    command.masterDataItems.forEach((i) => onRemoveItem(i));

    if (command.command === BatchUpdateCommandTypes.Remove) {
      onAddItem({ guid: "(ALL VALUES)", value: "(ALL VALUES)" });
    } else {
      onAddItem({ guid: "Global", value: "Global" });
    }
  }, [command.command]);

  if (command.command === BatchUpdateCommandTypes.Remove) {
    return <p>All Global Or Local values will be removed.</p>;
  }

  return (
    <>
      <label>Global or Local?</label>
      <select value={value} onChange={(e) => onChange(e.target.value)}>
        {Object.keys(TLMSWIType).map((t) => (
          <option key={t} value={t}>
            {t}
          </option>
        ))}
      </select>
    </>
  );
};

const ShowCLSignatureBlockDropDown: FC<NonMasterDataFieldProps> = ({
  command,
  onAddItem,
  onRemoveItem,
}) => {
  let value: string | undefined = undefined;

  if (command.masterDataItems.length) {
    value = command.masterDataItems[0].value;
  }

  const onChange = (_value: string) => {
    command.masterDataItems.forEach((item) => onRemoveItem(item));

    if (Object.keys(ShowCLSignatureBlock).includes(_value)) {
      onAddItem({ guid: _value, value: _value });
    }
  };

  useEffect(() => {
    command.masterDataItems.forEach((i) => onRemoveItem(i));

    if (command.command === BatchUpdateCommandTypes.Remove) {
      onAddItem({ guid: "(ALL VALUES)", value: "(ALL VALUES)" });
    } else {
      onAddItem({ guid: "Yes", value: "Yes" });
    }
  }, [command.command]);

  if (command.command === BatchUpdateCommandTypes.Remove) {
    return <p>ShowCLSignatureBlock value will be removed.</p>;
  }

  return (
    <>
      <label>ShowCLSignature<br />Block?</label>
      <select value={value} onChange={(e) => onChange(e.target.value)}>
        {Object.keys(ShowCLSignatureBlock).map((t) => (
          <option key={t} value={t}>
            {t}
          </option>
        ))}
      </select>
    </>
  );
};

const textInputNames: { [id: string]: string } = {
  [BatchUpdateMetaTypes.Introduction]: "Introduction",
  [BatchUpdateMetaTypes.MaintenanceTaskId]: "Maintenance Task(s)",
  [BatchUpdateMetaTypes.CEMarking]: "CE Marking",
  [BatchUpdateMetaTypes.Foreword]: "Foreward",
  [BatchUpdateMetaTypes.VersionChanges]: "Version Changes",
  [BatchUpdateMetaTypes.HARC]: "HARC"
};

export const NonMasterDataField: FC<NonMasterDataFieldProps> = (props) => {
  if (!props.command.metaType) {
    return <></>;
  }

  if (props.command.metaType === BatchUpdateMetaTypes.GlobalOrLocal) {
    return <GlobalOrLocalInput {...props} />;
  }

  if (props.command.metaType === BatchUpdateMetaTypes.ShowCLSignatureBlock) {
    return <ShowCLSignatureBlockDropDown {...props} />;
  }

  if (props.command.metaType === BatchUpdateMetaTypes.MaintenanceTaskId) {
    return <TextInput {...props} />;
  }

  if (textInputNames[props.command.metaType]) {
    return <TextInputWithNoRemove {...props} />;
  }

  return <></>;
};
