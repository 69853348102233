import React from "react";
import './ApprovalHistoryList.scss'
import useSelector from "store/useSelector";
import formatDate from "utilities/formatDate";
import { ApprovalResponseTypes, IApprovalWorkFlowResponse, IHistoricalRCUpdateApproval, RCUpdateApprovalStatuses } from "interfaces/approvals/approvalInterfaces";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ApprovalHistoryModal from "./ApprovalHistoryModal";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { ApprovalTooltipTrigger } from "./ApprovalTooltip";


const ApprovalRCUpdateHistoryList: React.FC<IHistoricalRCUpdateApproval> = (approval) => {
  const {
    SW
  } = useSelector(store => store.manageSW)
  const { activeApprovalLevel } = SW;
  var responses = approval.responses
    .slice()
    .sort((a, b) => a.createdOn < b.createdOn ? -1 : 1);

  const lastResponse = responses.length > 0
    ? responses[responses.length - 1]
    : null;

  const historicalResponsesAddList: IApprovalWorkFlowResponse[] = responses.map(response => {
    var optional: boolean = false;
    const { id, responseType, level, comment, attachmentFilename, createdBy, createdOn } = response;
    return { id, responseType, attachmentFilename, comment, level, optional, createdBy, createdOn };
  });
  function combinedApprovalList() {
    const approvalList = historicalResponsesAddList;
    var updatedApprovalList: IApprovalWorkFlowResponse[] = [];
    const activeApproval: IApprovalWorkFlowResponse = {
      id: 0,
      responseType: ApprovalResponseTypes.Active,
      level: approval.currentLevel,
      optional: false,
      comment: "",
      attachmentFilename: null,
      createdBy: "",
      createdOn: new Date(),
    };
    if (approval.status === RCUpdateApprovalStatuses.Active) {
      approvalList.push(activeApproval);
    }
    updatedApprovalList = approvalList;
    return updatedApprovalList;
  }


  return (
    <div
      className={`approval ${approval.status}`}
    >
      <div
        className="header"
      >
        Submitted for review by {approval.createdBy} on {formatDate(approval.createdOn)}
      </div>
      <div className="approvalHistoryContainer">
        {combinedApprovalList().map((data, index) => (
          <div className='history-container'>
            <ApprovalHistoryModal key={index} {...data} />
            {(data.responseType === ApprovalResponseTypes.Approved ||
              data.responseType === ApprovalResponseTypes.AutoApproved ||
              data.responseType === ApprovalResponseTypes.Rejected
            ) &&
              <>
                {data.responseType !== ApprovalResponseTypes.AutoApproved &&
                  <ApprovalTooltipTrigger
                    tooltipContent={
                      data.comment != null && data.comment.split('\n').map((line, ix, arr) => (
                        <React.Fragment
                          key={ix}
                        >
                          {ix == 0 &&
                            <>
                              <b>{data.responseType} Comment :</b>
                              <br />
                            </>
                          }
                          {line}
                          {ix < arr.length - 1 &&
                            <br />
                          }
                        </React.Fragment>
                      ))
                    }
                  >
                    <div className={`detail-container approval-history-text ${data.responseType}`}>{data.responseType} by ({data.createdBy})
                    </div>
                  </ApprovalTooltipTrigger>
                }
                <div className={`date-container approval-history-text ${data.responseType}`}>
                  <CalendarMonthIcon className={`approval-history-icon ${data.responseType}`} />
                  {formatDate(data.createdOn)}
                </div>
              </>
            }
            {data.responseType === ApprovalResponseTypes.Active &&
              <>
                <div className={`detail-container approval-history-text ${data.responseType}`}>Pending review at {data.level} </div>
              </>
            }
          </div>
        ))}
      </div>
    </div>
  );
}

export default ApprovalRCUpdateHistoryList;