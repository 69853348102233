import React from "react";
import "./ExecutionSWList.scoped.scss";
import useSelector from "store/useSelector";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import ExecutionPreview from "./ExecutionPreview";

const ExecutionSWList: React.FC = () => {
  const { SW } = useSelector(store => store.manageSW);

  var eclAddedToJob = SW.type === SWTypes.ECL ? true : false;
  return (
    <>
      {
        eclAddedToJob &&
        <div className="showAllECLsDiv">
          <label>{'Showing All ECLs'}</label>
        </div>
      }
      <div
        className="step-list"
      >
        <div
          className="sw"
        >
          <ExecutionPreview />
        </div>
      </div>
    </>);
}

export default ExecutionSWList;