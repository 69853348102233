import Modal from "components/common/Modal";
import MasterDataInput from "components/sw/manage/attributes/MasterDataInput";
import { BatchUpdateMetaTypes, BatchUpdateCommandTypes } from "interfaces/batchUpdates/batchUpdatesInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addCommand, setCommandManagerData } from "store/batchUpdates/batchUpdatesActions";
import { loadCountries, loadGeoUnits, loadHazardCategories, loadOrganizations, loadSafetyCategories, loadSubBusinessLines, searchActivityWorkflows, searchCompetencyElements, searchEpicEquipment, searchEquipment, searchOperatingEnvironments, searchProductCenters, searchServiceTypes, searchTasks, setSection } from "store/masterData/masterDataActions";
import { IMasterDataSection, IMasterDataState, MasterDataSectionNames } from "store/masterData/masterDataTypes";
import { showErrorToast } from "store/toast/toastActions";
import useSelector from "store/useSelector";
import "./CommandManager.scoped.scss";
import { isNonMasterData, NonMasterDataField } from "./NonMasterDataField";



export interface IEditBatchUpdateCommand {
  sortOrder: number,
  command: BatchUpdateCommandTypes,
  metaType?: BatchUpdateMetaTypes,
  masterDataItems: IMasterDataItem[],
}

const initialEditItem: IEditBatchUpdateCommand = {
  command: BatchUpdateCommandTypes.Add,
  sortOrder: 0,
  masterDataItems: []
};

const CommandManager: React.FC = () => {
  const {
    batchUpdates: {
      commandManagerData: {
        isOpen,
      },
      commands,
    },
    masterData,
  } = useSelector(store => store);
  const dispatch = useDispatch();

  const [command, setCommand] = useState<IEditBatchUpdateCommand>({ ...initialEditItem });

  useEffect(() => {
    setCommand({ ...initialEditItem });
  }, [commands, setCommand]);

  useEffect(() => {
  }, [command]);

  if (!isOpen) {
    return null;
  }

  const dispatchCommandInput = (_command: IEditBatchUpdateCommand) => {
    if (!_command.metaType) {
      return;
    }

    if (!isNonMasterData(_command.metaType)) {
      dispatch(setSection({
        name: getMasterDataSectionName(_command.metaType),
        section: {
          items: [],
        },
      }));
    }
  }

  const onCommandChanged = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCommand = cloneDeep(command);
    newCommand.command = event.target.value as BatchUpdateCommandTypes;
    setCommand(newCommand);
    dispatchCommandInput(newCommand);
  };

  const onTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newType = event.target.value as BatchUpdateMetaTypes;

    if (newType === command.metaType) {
      return;
    }

    const newCommand = cloneDeep(command);
    newCommand.metaType = newType;
    newCommand.masterDataItems = [];
    setCommand(newCommand);
    dispatchCommandInput(newCommand);
  }

  const onAddMasterdataItem = (item: IMasterDataItem) => {
    setCommand((currentCommand) => {
      const currentItems = currentCommand.masterDataItems || [];

      const masterDataItems = getMasterDataAllowMultiple(currentCommand.metaType)
        ? [...currentItems, item]
        : [item];

      return {
        ...currentCommand,
        masterDataItems,
      };
    });
  };

  const onRemoveMasterdataItem = (item: IMasterDataItem) => {
    setCommand((currentCommand) => {
      const masterDataItems = (currentCommand.masterDataItems || []).filter(
        (_item) => _item.guid !== item.guid
      );

      return {
        ...currentCommand,
        masterDataItems,
      };
    });
  };

  const onLoadMasterDataItems = (_searchTerm: string | undefined) => {
    // Dispatch the correct event based on which type of master data is chosen.
    if (!command.metaType) {
      return;
    }

    let onlyActive = true;
    if (command.command === BatchUpdateCommandTypes.Remove) {
      onlyActive = false;
    }

    const searchTerm = _searchTerm || "";

    if (command.metaType === BatchUpdateMetaTypes.ActivityWorkflow) {
      dispatch(searchActivityWorkflows({ searchTerm, onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.CompetencyElement) {
      dispatch(searchCompetencyElements({ searchTerm, onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.Country) {
      dispatch(loadCountries({ onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.Equipment) {
      dispatch(searchEquipment({ searchTerm, onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.OperatingEnv) {
      dispatch(searchOperatingEnvironments({ searchTerm, onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.OwningOrg) {
      dispatch(loadOrganizations({ onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.PerformingOrg) {
      dispatch(loadOrganizations({ onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.SafetyCategory) {
      dispatch(loadSafetyCategories({ onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.ServiceType) {
      dispatch(searchServiceTypes({ searchTerm, onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.Task) {
      dispatch(searchTasks({ searchTerm, onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.SubBusinessLine) {
      dispatch(loadSubBusinessLines({ onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.ProductCenters) {
      dispatch(searchProductCenters({ searchTerm, onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.GeoUnit) {
      dispatch(loadGeoUnits({ onlyActive }));
    } else if (command.metaType === BatchUpdateMetaTypes.EpicEquipment) {
      dispatch(searchEpicEquipment({ searchTerm, onlyActive }));
    } 
    // else if (command.metaType === BatchUpdateMetaTypes.HazardCategory) {
    //   dispatch(loadHazardCategories());
    // }
  };

  const masterDataSections: { [id: string]: MasterDataSectionNames } = {
    [BatchUpdateMetaTypes.ActivityWorkflow]: "activityWorkflows",
    [BatchUpdateMetaTypes.CompetencyElement]: "competencyElements",
    [BatchUpdateMetaTypes.Country]: "countries",
    [BatchUpdateMetaTypes.Equipment]: "equipment",
    [BatchUpdateMetaTypes.OperatingEnv]: "operatingEnvironments",
    [BatchUpdateMetaTypes.OwningOrg]: "organizations",
    [BatchUpdateMetaTypes.PerformingOrg]: "organizations",
    [BatchUpdateMetaTypes.SafetyCategory]: "safetyCategories",
    [BatchUpdateMetaTypes.ServiceType]: "serviceTypes",
    [BatchUpdateMetaTypes.Task]: "tasks",
    [BatchUpdateMetaTypes.SubBusinessLine]: "subBusinessLines",
    [BatchUpdateMetaTypes.ProductCenters]: "productCenters",
    [BatchUpdateMetaTypes.GeoUnit]: "geoUnits",
    [BatchUpdateMetaTypes.EpicEquipment]: "epicEquipment",
    // [BatchUpdateMetaTypes.HazardCategory]: "hazardCategories"
  };

  const getMasterDataSectionName = function (
    type: BatchUpdateMetaTypes
  ): MasterDataSectionNames {
    if (masterDataSections[type]) {
      return masterDataSections[type];
    }

    const err = `Unhandled master data type while loading: ${type}`;
    dispatch(showErrorToast(err));
    throw new Error(err);
  };

  const masterDataSectionSelectors: {
    [id: string]: (md: IMasterDataState) => IMasterDataSection;
  } = {
    [BatchUpdateMetaTypes.ActivityWorkflow]: (md) => md.activityWorkflows,
    [BatchUpdateMetaTypes.CompetencyElement]: (md) => md.competencyElements,
    [BatchUpdateMetaTypes.Country]: (md) => md.countries,
    [BatchUpdateMetaTypes.Equipment]: (md) => md.equipment,
    [BatchUpdateMetaTypes.OperatingEnv]: (md) => md.operatingEnvironments,
    [BatchUpdateMetaTypes.OwningOrg]: (md) => md.organizations,
    [BatchUpdateMetaTypes.PerformingOrg]: (md) => md.organizations,
    [BatchUpdateMetaTypes.SafetyCategory]: (md) => md.safetyCategories,
    [BatchUpdateMetaTypes.ServiceType]: (md) => md.serviceTypes,
    [BatchUpdateMetaTypes.Task]: (md) => md.tasks,
    [BatchUpdateMetaTypes.SubBusinessLine]: (md) => md.subBusinessLines,
    [BatchUpdateMetaTypes.ProductCenters]: (md) => md.productCenters,
    [BatchUpdateMetaTypes.GeoUnit]: (md) => md.geoUnits,
    [BatchUpdateMetaTypes.EpicEquipment]: md => md.epicEquipment,
    // [BatchUpdateMetaTypes.HazardCategory]: (md) => md.hazardCategories,
  };

  const getMasterDataSection = () => {
    if (command.metaType && masterDataSectionSelectors[command.metaType]) {
      return masterDataSectionSelectors[command.metaType](masterData);
    }

    const err = `Unhandled master data type while loading: ${command.metaType}`;
    dispatch(showErrorToast(err));
    throw new Error(err);
  };

  const valueOnlyFormatter = (item: IMasterDataItem) => item.value;

  const codeAndValueFormatter = (item: IMasterDataItem) =>
    `${item.code} - ${item.value}`;

  const formatterInstances: {
    [id: string]: (item: IMasterDataItem) => JSX.Element | string;
  } = {
    [BatchUpdateMetaTypes.ActivityWorkflow]: (item) => (
      <span>
        {item.value}
        <br />
        {item.code}
      </span>
    ),
    [BatchUpdateMetaTypes.CompetencyElement]: valueOnlyFormatter,
    [BatchUpdateMetaTypes.Country]: valueOnlyFormatter,
    [BatchUpdateMetaTypes.Equipment]: valueOnlyFormatter,
    [BatchUpdateMetaTypes.OperatingEnv]: valueOnlyFormatter,
    [BatchUpdateMetaTypes.OwningOrg]: codeAndValueFormatter,
    [BatchUpdateMetaTypes.PerformingOrg]: codeAndValueFormatter,
    [BatchUpdateMetaTypes.SafetyCategory]: valueOnlyFormatter,
    [BatchUpdateMetaTypes.ServiceType]: valueOnlyFormatter,
    [BatchUpdateMetaTypes.Task]: valueOnlyFormatter,
    [BatchUpdateMetaTypes.SubBusinessLine]: valueOnlyFormatter,
    [BatchUpdateMetaTypes.ProductCenters]: valueOnlyFormatter,
    [BatchUpdateMetaTypes.GeoUnit]: valueOnlyFormatter,
    [BatchUpdateMetaTypes.EpicEquipment]: valueOnlyFormatter,
    // [BatchUpdateMetaTypes.HazardCategory]: valueOnlyFormatter,
  };

  const getMasterDataFormatter = () => {
    if (command.metaType && formatterInstances[command.metaType]) {
      return formatterInstances[command.metaType];
    } else {
      const err = `Unhandled master data type while getting formatter: ${command.metaType}`;
      dispatch(showErrorToast(err));
      throw new Error(err);
    }
  };

  const masterDataLabels: { [id: string]: string } = {
    [BatchUpdateMetaTypes.ActivityWorkflow]: "Activity Workflow",
    [BatchUpdateMetaTypes.CompetencyElement]: "Competency El.",
    [BatchUpdateMetaTypes.Country]: "Country",
    [BatchUpdateMetaTypes.Equipment]: "Equipment",
    [BatchUpdateMetaTypes.OperatingEnv]: "Operating Env.",
    [BatchUpdateMetaTypes.OwningOrg]: "Owning Org",
    [BatchUpdateMetaTypes.PerformingOrg]: "Performing Org",
    [BatchUpdateMetaTypes.SafetyCategory]: "Safety Cat.",
    [BatchUpdateMetaTypes.ServiceType]: "Service Type",
    [BatchUpdateMetaTypes.Task]: "Task",
    [BatchUpdateMetaTypes.SubBusinessLine]: "Sub Business Line",
    [BatchUpdateMetaTypes.ProductCenters]: "Product Center",
    [BatchUpdateMetaTypes.GeoUnit]: "GeoUnit",
    [BatchUpdateMetaTypes.EpicEquipment]: "EPIC Equipment",
    // [BatchUpdateMetaTypes.HazardCategory]: "Hazard Category"
  };

  const getMasterDataLabel = () => {
    if (command.metaType && masterDataLabels[command.metaType]) {
      return masterDataLabels[command.metaType];
    }

    const err = `Unhandled master data type while loading: ${command.metaType}`;
    dispatch(showErrorToast(err));
    throw new Error(err);
  }

  const getMasterDataAllowMultiple = (
    metaType: BatchUpdateMetaTypes | undefined
  ) => {
    if (!metaType) return false;

    if (
      metaType === BatchUpdateMetaTypes.OwningOrg ||
      metaType === BatchUpdateMetaTypes.PerformingOrg ||
      metaType === BatchUpdateMetaTypes.Task ||
      // metaType === BatchUpdateMetaTypes.HazardCategory ||
      (metaType && isNonMasterData(metaType))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const asyncSearchMetaTypes = [
    BatchUpdateMetaTypes.ActivityWorkflow,
    BatchUpdateMetaTypes.CompetencyElement,
    BatchUpdateMetaTypes.Equipment,
    BatchUpdateMetaTypes.OperatingEnv,
    BatchUpdateMetaTypes.ServiceType,
    BatchUpdateMetaTypes.Task,
    BatchUpdateMetaTypes.ProductCenters,
    BatchUpdateMetaTypes.EpicEquipment,
  ];

  const syncSearchMetaTypes = [
    BatchUpdateMetaTypes.Country,
    BatchUpdateMetaTypes.OwningOrg,
    BatchUpdateMetaTypes.PerformingOrg,
    BatchUpdateMetaTypes.SafetyCategory,
    BatchUpdateMetaTypes.SubBusinessLine,
    BatchUpdateMetaTypes.GeoUnit,
    // BatchUpdateMetaTypes.HazardCategory,
  ];

  const getMasterDataIsAsync = () => {
    if (!command.metaType) {
      return false;
    }

    if (asyncSearchMetaTypes.includes(command.metaType)) {
      return true;
    } else if (syncSearchMetaTypes.includes(command.metaType)) {
      return false;
    }

    const err = `Unhandled master data type while loading: ${command.metaType}`;
    dispatch(showErrorToast(err));
    throw new Error(err);
  };

  const onSave = () => {
    const missingFields = [];

    if (!command.command) {
      missingFields.push("Command");
    }

    if (!command.metaType) {
      missingFields.push("Metadata Type");
    } else if (!command.masterDataItems) {
      missingFields.push(getMasterDataLabel());
    }

    if (missingFields.length) {
      dispatch(showErrorToast(
        `${missingFields.join(", ")} ${missingFields.length > 1 ? "are" : "is"} required.`
      ));
      return;
    }

    if (command.metaType === null
      || command.masterDataItems === null) {
      return;
    } else {
      if (command.sortOrder === 0) {
        command.masterDataItems.forEach(x => {
          if (command.metaType) {
            dispatch(addCommand({
              sortOrder: 0,
              command: command.command,
              metaType: command.metaType,
              masterDataItem: x,
            }));
          }
        });

        dispatch(setCommandManagerData({
          isOpen: false,
          managedCommandSortOrder: null,
        }));
      } else {
        dispatch(showErrorToast(
          "Updating a batch command is not supported."));
      }
    }
  }



  return (
    <Modal
      isOpen={true}
      header="Batch Update Editor"
      controls={
        <>
          <button
            className="secondary-button"
            onClick={() =>
              dispatch(
                setCommandManagerData({
                  isOpen: false,
                  managedCommandSortOrder: null,
                })
              )
            }
          >
            Cancel
          </button>
          <button
            className="primary-button"
            onClick={onSave}
            disabled={
              !(
                command.metaType &&
                command.masterDataItems &&
                command.masterDataItems.length
              )
            }
          >
            Save
          </button>
        </>
      }
    >
      <div className="input-row">
        <label>Command</label>
        <div className="radio-row">
          {Object.keys(BatchUpdateCommandTypes).map((t) => (
            <div key={t}>
              <input
                className="me-1"
                type="radio"
                name="BatchUpdateCommandTypes"
                id={`BatchUpdateCommandTypes_${t}`}
                value={t}
                checked={command.command === t}
                onChange={onCommandChanged}
              />
              <label htmlFor={`BatchUpdateCommandTypes_${t}`}>{t}</label>
            </div>
          ))}
        </div>
      </div>

      <div className="input-row">
        <label>Metadata Type</label>
        <select onChange={onTypeChange} value={command.metaType || undefined}>
          {!command.metaType && <option value={""}></option>}
          {Object.keys(BatchUpdateMetaTypes)
            .sort((a, b) => (a < b ? -1 : 1))
            .map((t) => (
              <option key={t} value={t}>
                {t}
              </option>
            ))}
        </select>
      </div>
      {command.metaType && isNonMasterData(command.metaType) && (
        <div className="input-row">
          <NonMasterDataField
            command={command}
            onAddItem={onAddMasterdataItem}
            onRemoveItem={onRemoveMasterdataItem}
          />
        </div>
      )}

      {command.metaType !== null &&
        command.metaType &&
        !isNonMasterData(command.metaType) && (
          <div className="input-row">
            <MasterDataInput
              label={getMasterDataLabel() || ""}
              selectedItems={
                command.masterDataItems ? command.masterDataItems : []
              }
              isAsyncSearch={getMasterDataIsAsync()}
              allowMultiple={getMasterDataAllowMultiple(command.metaType)}
              onAddItem={onAddMasterdataItem}
              onRemoveItem={onRemoveMasterdataItem}
              loadItems={onLoadMasterDataItems}
              masterDataSection={getMasterDataSection()}
              itemFormatter={getMasterDataFormatter()}
              onlyActiveState={
                command.command !== BatchUpdateCommandTypes.Remove
              }
            />
          </div>
        )}
    </Modal>
  );
};

export default CommandManager;